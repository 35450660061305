import { Form, Formik } from 'formik'
import { Fragment, ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { IcValidator, RequestOptionsValidator } from '../../busLog/clientValidators'
import { getCountries } from '../../busLog/enums_gen'
import { reloadPayment, setPayment } from '../../busLog/paymentBL'
import { Container } from '../../components/Container'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Modal, useModal } from '../../components/Modal'
import { PageHeader } from '../../components/PageHeader'
import { ScrollToError } from '../../components/scroller'
import { StripeAlert } from '../../components/StripeAlert'
import { Title } from '../../components/Title'
import { useAppContext } from '../../contexts/AppContext'
import { LanguageType } from '../../enums/LanguageType'
import { LoadType } from '../../enums/LoadTypeEnum'
import { ReferenceType } from '../../enums/ReferenceType'
import { PaymentReqModel } from '../../models/paymentReqModel'
import { Translate } from '../../translations/CebiaTranslator'
import { redirectDotazLang } from '../../utils/redirect'
import './index.styl'
import { SectionCustomerCz } from './sections/SectionCustomer/indexCz'
import { SectionFooter } from './sections/SectionFooter'
import { SectionPayMethod } from './sections/SectionPayMethod'
import { EmailRegex } from '../../utils/validators'
import { SectionShape } from '../../components/SectionShape'
import { SectionPaymentOptions } from './sections/SectionPaymentOptions'
import { RequestOptions } from 'https'
import { RequestOptionsModel } from '../../models/requestOptionsModel'

export const PagePayment = (): ReactElement => {
    const { open: openPersonaly } = useModal('paymentPersonaly')
    const appContext = useAppContext()
    const { id } = useParams()
    const navigate = useNavigate()
    const [paymentReq, setPaymentReq] = useState<PaymentReqModel>(
        new PaymentReqModel(id!, appContext.appModel),
    )
    const [errorMessage, setErrorMessage] = useState('')
    const [invoiceOpened, setInvoiceOpened] = useState(false)
    const tran = new Translate()
    const phoneRegExp = /^(\+){0,1}(?:[0-9] ?){6,14}[0-9]$/
    const countries = getCountries(tran)
    useEffect(() => {
        if (paymentReq.price < 0 && id) {
            const loadType =
                (paymentReq?.make?.length ?? 0) <= 0 ? LoadType.WithImageData : LoadType.Normal
            reloadPayment(id!, paymentReq.validatedByVies, paymentReq.saleCoupon, loadType).then(
                payReq => {
                    if (payReq) {
                        if (payReq.isPayed > 0) {
                            if (payReq.isPayed === 3) {
                                navigate('/payed/detail/' + id)
                            } else {
                                setErrorMessage(
                                    tran.GetText('Platba neproběhla. Platbu prosím opakujte.'),
                                )
                            }
                        }
                        //musi tady byt protozr server vraci image data jenom kdyz neexistuje v appContext.
                        if (loadType === LoadType.Normal) payReq.imageData = paymentReq.imageData
                        const countryPhonePrfx =
                            countries.find(x => x.id == payReq?.countryId)?.countryPhonePrefix ??
                            '+420'
                        if (
                            (payReq?.customerCountry === LanguageType.RO ||
                                payReq?.customerCountry === LanguageType.SK) &&
                            payReq.customerPhone.length === 0
                        ) {
                            payReq.customerPhone = countryPhonePrfx
                        }
                        const isInvoiceFilled =
                            payReq.invoiceCompanyName?.length > 0 ||
                            payReq.invoiceIc?.length > 0 ||
                            payReq.invoiceDic?.length > 0
                        payReq.invoiceOpened = isInvoiceFilled
                        payReq.reloaded = true
                        setInvoiceOpened(isInvoiceFilled)
                        paymentReq.invoiceOpened = isInvoiceFilled
                        paymentReq.validatedByVies = payReq.validatedByVies
                        paymentReq.saleCoupon = payReq.saleCoupon
                        setPaymentReq(prevState => ({ ...prevState, ...payReq }))
                    } else setErrorMessage(tran.GetText('Chyba načtení dat.'))
                },
            )
        }
    }, [id])

    useEffect(() => {
        if (paymentReq !== undefined) paymentReq.customerCountry = paymentReq.countryId
        const countryPhonePrfx =
            countries.find(x => x.id == paymentReq?.countryId)?.countryPhonePrefix ?? '+420'
        if (
            (paymentReq?.customerCountry === LanguageType.RO ||
                paymentReq?.customerCountry === LanguageType.SK) &&
            paymentReq.customerPhone.length === 0
        ) {
            paymentReq.customerPhone = countryPhonePrfx
        }
        setPaymentReq(prevState => ({ ...prevState, ...paymentReq }))
    }, [paymentReq?.countryId])

    useEffect(() => {
        if (paymentReq?.sale?.utm_campaign) {
            paymentReq.saleCoupon = paymentReq?.sale?.utm_campaign
            setPaymentReq(prevState => ({ ...prevState, ...paymentReq }))
        }
    }, [paymentReq?.sale?.utm_campaign])

    useEffect(() => {
        if (paymentReq.countryId && paymentReq.vin) {
            redirectDotazLang(paymentReq.countryId)
        }
    }, [paymentReq?.countryId])

    const validationSchema = () => {
        return Yup.object().shape(
            {
                isGdpr: Yup.boolean().oneOf(
                    [true],
                    tran.GetText('Bez souhlasu s Všeobecnými podmínkami nelze pokračovat'),
                ),
                customerEmail: Yup.string()
                    .matches(EmailRegex, tran.GetText('Email nemá správný formát'))
                    .email(tran.GetText('Nesprávný formát Email-u.'))
                    .required(tran.GetText('Prosím zadejte Váš e-mail.')),
                customerPhone: Yup.string()
                    .matches(phoneRegExp, tran.GetText('Nesprávna hodnota Telefon'))
                    .max(14, tran.GetText('Maximální počet znaků je 14.'))
                    .min(12, tran.GetText('Minimální počet znaků je 12.'))
                    .when('countryId', {
                        is: (countryId: LanguageType) =>
                            (countryId && countryId === LanguageType.SK) ||
                            countryId === LanguageType.RO ||
                            countryId === LanguageType.IT ||
                            countryId === LanguageType.FR ||
                            countryId === LanguageType.AL,
                        then: Yup.string().required(
                            tran.GetText('Prosím zadejte položku Telefon.'),
                        ),
                    }),
                // customerPhone: Yup.string()
                //     .max(9, tran.GetText('Maximální počet znaků je 9.'))
                //     .matches(/^\d+$/, tran.GetText('Telefonní čislo ma nesprávny formát.'))
                //     .required(tran.GetText('Prosím zadejte položku Telefon.')),
                // customerFirstname: Yup.string()
                //     .required(tran.GetText('Prosím zadejte položku Jméno.'))
                //     .max(128, tran.GetText('Maximální počet znaků je 128.')),
                // customerSurname: Yup.string()
                //     .required(tran.GetText('Prosím zadejte položku Příjmení.'))
                //     .max(64, tran.GetText('Maximální počet znaků je 64.')),
                customerAddress: Yup.string()
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(
                            tran.GetText('Prosím zadejte položku Ulice a číslo.'),
                        ),
                    })
                    // .required(tran.GetText('Prosím zadejte položku Ulice a číslo.'))
                    .max(128, tran.GetText('Maximální počet znaků je 128.')),
                customerCity: Yup.string()
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(tran.GetText('Prosím zadejte položku Město.')),
                    })
                    // .required(tran.GetText('Prosím zadejte položku Město.'))
                    .max(64, tran.GetText('Maximální počet znaků je 64.')),
                customerPsc: Yup.string()
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(tran.GetText('Prosím zadejte položku PSČ.')),
                    })
                    // .required(tran.GetText('Prosím zadejte položku PSČ.'))
                    .max(
                        paymentReq?.countryId === LanguageType.RO ? 6 : 5,
                        paymentReq?.countryId === LanguageType.RO
                            ? tran.GetText('Maximální počet znaků je 6.')
                            : tran.GetText('Maximální počet znaků je 5.'),
                    )
                    .matches(/^\d+$/, tran.GetText('PSČ má nesprávný formát.')),
                invoiceCompanyName: Yup.string()
                    .max(128, tran.GetText('Maximální počet znaků je 128.'))
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(
                            tran.GetText('Prosím zadejte položku Název společnosti.'),
                        ),
                    }),
                // .when('invoiceDic', {
                //     is: (invoiceDic: string | any) => invoiceDic && invoiceDic.length > 0,
                //     then: Yup.string().required('Prosím zadejte položku Název společnosti.'),
                // })
                invoiceIc: Yup.string()
                    .max(16, tran.GetText('Maximální počet znaků je 16.'))
                    .test('icValTest', tran.GetText('Nesprávná hodnota IČ.'), function (value) {
                        const isValid = IcValidator(value, paymentReq.countryId)
                        return isValid
                    })
                    .when(['invoiceOpened'], {
                        is: (invoiceOpened: boolean) => {
                            return invoiceOpened === true
                        },
                        then: Yup.string().required(tran.GetText('Prosím zadejte IČ společnosti.')),
                    }),
                invoiceDic: Yup.string().max(18, tran.GetText('Maximální počet znaků je 18.')),
                requestOptionsModel: Yup.array()
                    .nullable()
                    .test(
                        'requestValTest',
                        tran.GetText('Prosím vyberte jednu z možností.'),
                        function (value) {
                            const isValid = RequestOptionsValidator(
                                paymentReq.requestOptionsModel,
                                paymentReq.countryId,
                            )
                            return isValid
                        },
                    ),
            },
            [
                ['invoiceIc', 'invoiceCompanyName'],
                ['invoiceCompanyName', 'invoiceIc'],
            ],
        )
    }
    const onSubmitAction = async (paymentReq: PaymentReqModel) => {
        appContext.setLoading(true)
        if (paymentReq.paymentMethod === 'CEBIACASH') {
            openPersonaly()
            appContext.setLoading(false)
            return
        }
        if (!paymentReq.invoiceOpened) {
            paymentReq.customerAddress = ''
            paymentReq.customerCity = ''
            paymentReq.customerPsc = ''
            paymentReq.invoiceDic = ''
            paymentReq.invoiceIc = ''
            paymentReq.invoiceCompanyName = ''
            paymentReq.validatedByVies = 0
        }
        const retVal = await setPayment(paymentReq, tran)

        if ((retVal?.length ?? 0) > 0) {
            appContext.setLoading(false)
            //zobrazit error message
            setErrorMessage(retVal)
        }
    }

    const onUpdatePaymentReq = (newPaymentReq: PaymentReqModel) => {
        setPaymentReq(prevState => ({ ...prevState, ...newPaymentReq }))
    }
    return (
        <div className="PagePayment" key={paymentReq?.vin}>
            <Header showLanguage={false} referenceType={ReferenceType.Default} />
            <PageHeader>
                <h1 className="Beta color-darkBlue smwMax:hidden">
                    {tran.GetText('Online platba')}
                </h1>
                <span className="ExaminationCar">
                    {tran.GetText('Prověřované vozidlo')}:{' '}
                    <span className="smwMax:hidden">
                        {paymentReq?.make?.split('|').map((item, index) => (
                            <Fragment key={index}>
                                {item && item.length > 0 ? <>{item + ' '}</> : <></>}
                            </Fragment>
                        ))}
                        {paymentReq?.model?.split('|').map((item, index) => (
                            <Fragment key={index}>
                                {item && item.length > 0 ? <>{item + ' '}</> : <></>}
                            </Fragment>
                        ))}{' '}
                        {tran.GetText('VIN')}:
                    </span>{' '}
                    {paymentReq?.vin ?? ''}
                </span>
                {errorMessage?.length > 0 && (
                    <Container variant="medium" className="mt-20">
                        <StripeAlert variant="error">
                            <span className=" Icon Icon--alert AlertIcon"></span> {errorMessage}
                        </StripeAlert>
                    </Container>
                )}
            </PageHeader>

            {/* <SectionHeader
                updatePaymentState={onUpdatePaymentReq}
                payment={paymentReq}
            ></SectionHeader> */}

            <Container className="box">
                <Container>
                    <SectionShape variant="rightBottomUp" />
                </Container>
                <Formik
                    key={paymentReq.invoiceOpened ? 'opened' : 'closed'}
                    initialValues={paymentReq}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        onSubmitAction(values)
                    }}
                >
                    <Form className="ajax" autoComplete="off">
                        <SectionCustomerCz
                            paymentReq={paymentReq}
                            updatePaymentState={onUpdatePaymentReq}
                        />
                        <SectionPaymentOptions
                            paymentReq={paymentReq}
                            updatePaymentState={onUpdatePaymentReq}
                        ></SectionPaymentOptions>
                        <SectionPayMethod isVisible={paymentReq?.price > 0} />

                        <SectionFooter
                            paymentReq={paymentReq}
                            updatePaymentState={onUpdatePaymentReq}
                        />
                        <ScrollToError />
                    </Form>
                </Formik>
                <Modal id="paymentPersonaly">
                    <Container>
                        <Title as="header" variantAlign="center">
                            <h1 className="color-darkBlue"> {tran.GetText('Platba hotově')}</h1>
                        </Title>
                        {tran.GetHtml(
                            'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
                        )}
                    </Container>
                </Modal>
            </Container>
            <Footer />
        </div>
    )
}
