import { ReactElement } from 'react'
import { PayedStateModel } from '../../models/payedResponseModel'
import { PagePayedReg } from '../../pages/payedReg'
import { Translate } from '../../translations/CebiaTranslator'
import { Button } from '../Button'
import { VisualIcon } from '../VisualIcon'

export type PagePayedRegType = {
    viewType: PagePayedReg
    carinfo: PayedStateModel | undefined
}

export const PayedRegInfoHeader = ({ viewType }: PagePayedRegType): ReactElement => {
    const tran = new Translate()
    const viewTypeConst = viewType.type ?? 'ov'
    if (viewTypeConst === 'ov') {
        return (
            <div className="Grid">
                <div className="Grid-cell smw:w-full md:w-full lg:w-12/12 PayedRegHeader">
                    <span className="font-bold">
                        {tran.GetHtml(
                            '<span className="green">Hotovo!</span> U zadaného vozidla lze prověřit následující informace: ',
                        )}
                    </span>
                </div>
            </div>
        )
    } else {
        return (
            <div className="Grid">
                <div className="Grid-cell smw:w-8/12 md:w-8/12 lg:w-8/12 PayedRegHeader">
                    <span className="font-bold">
                        {tran.GetText('Lze vystavit Cebia REPORT hodnotící následující kategorie:')}
                    </span>
                </div>
                <div className="Grid-cell smw:w-4/12 md:w-4/12 lg:w-4/12 PayedRegHeader">
                    <VisualIcon name={'clipboard'} />{' '}
                    <Button
                        as="a"
                        variantSize="small"
                        variant="link"
                        onClick={() => {
                            window.open(
                                `https://cz.${process.env.REACT_APP_DOMAIN}/docs/Cebia_REPORT_vzor_CZ.pdf?v=2`,
                            )
                        }}
                    >
                        {tran.GetText('Ukázka REPORTU')}
                    </Button>
                </div>
            </div>
        )
    }
}
