import { LanguageVariant } from 'typescript'
import { LanguageType } from '../enums/LanguageType'
import { LanguageEnum, LanguageSpecification } from '../translations/i18n'
import { AppModel } from './appModel'
import { boolean } from 'yup'
import { RequestOptionsModel } from './requestOptionsModel'

export class PaymentReqModel {
    constructor(viewId: string, appModel: AppModel | undefined) {
        this.viewId = viewId.split('&')[0]
        this.vin = appModel?.validateResponseModel?.carVin ?? appModel?.vin ?? ''
        this.price = appModel?.priceDashboardInfo?.totalPrice ?? -1
        this.priceWithoutVat = appModel?.priceDashboardInfo?.totalPriceWithoutVat ?? -1
        this.standardPrice = appModel?.priceDashboardInfo?.standardPrice ?? -1
        this.currency = appModel?.priceDashboardInfo.currency
        this.discnoutCaption = appModel?.priceDashboardInfo.discountName
        this.productId = appModel?.priceDashboardInfo.productId ?? 0
        // this.imageData = appModel?.carDashboardInfo.imageData
        this.make = appModel?.validateResponseModel?.carMake ?? ''
        this.model = appModel?.validateResponseModel?.carModel ?? ''
        this.imageData = appModel?.validateResponseModel?.imageData
        this.countryId = appModel?.priceDashboardInfo?.countryId ?? LanguageType.CZ
        this.sale = appModel?.priceDashboardInfo?.sale as Sale
        this.vat = appModel?.priceDashboardInfo?.vat ?? 0
        this.vatDeduction = appModel?.priceDashboardInfo?.vatDeduction ?? false
        this.standardPriceWithoutVat = appModel?.priceDashboardInfo?.standardPriceWithoutVat ?? -1
        this.requestOptionsModel = new Array<RequestOptionsModel>()
    }

    clientId = ''
    viewId = ''
    customerEmail = ''
    customerPhone = ''

    customerFirstname = ''
    customerSurname = ''
    customerAddress = ''
    customerCity = ''
    customerCountry = 0
    customerPsc = ''

    invoiceCompanyName = ''
    invoiceIc = ''
    invoiceDic = ''
    invoiceOpened = false
    paymentMethod = ''

    isSale = false
    saleCoupon = ''
    price = 0
    priceWithoutVat = 0
    standardPrice = 0
    standardPriceWithoutVat = 0
    salePrice = 0

    isGdpr = false

    isValid = 0
    vat = 0
    vin = ''
    make = ''
    model = ''
    sale = new Sale()
    isPayed = 0
    vatDeduction = false

    countryId: LanguageType = LanguageType.CZ

    currency: string | undefined = ''
    discnoutCaption: string | undefined = ''
    productId: number | undefined = 0
    imageData: [] | undefined
    reloaded = false
    validatedByVies = 0
    requestOptionsModel: RequestOptionsModel[]
}

export class Sale {
    decimalPlaces = 0
    nl_sleva_proc = 0
    nd_sleva_pevna = 0
    b_discount: boolean | undefined
    utm_campaign: string | undefined = ''
}

export class Countries {
    name = ''
    value = 0
}

export class CompanyInfo {
    companyName = ''
    companyAddress = ''
}
export class ViesInfo {
    countryCode = ''
    vatNumber = ''
    valid = false
    name = ''
    address = ''
    error = ''
}

export class PaymentPrice {
    standardPrice = 0
    standardPriceWithoutVat = 0
    //cena kterou regulerne zakaznik musi zaplatit.
    totalPrice = 0
    totalPriceWithoutVat = 0
    saleCouponDescription = ''
    isValid = 0
    message = ''
    clientId = ''
}

export class PaymentGate {
    isValid = 0
    paymentGateUrl = ''
    message = ''
    clientId = ''
}
