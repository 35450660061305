import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../components/Container'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { PageHeader } from '../../components/PageHeader'
import { Section } from '../../components/Section'
import { ToggleItem } from '../../components/ToggleItem'
import { ToggleOpenerFaqTab } from '../../components/ToggleOpenerFaqTab'
import { PageShape } from '../../components/PageShape'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import { useModal } from '../../components/Modal'
import { ContactForm } from './ContactForm'
import { ReferenceType } from '../../enums/ReferenceType'

export const PageFaq = (): ReactElement => {
    const tran = new Translate()
    const { open } = useModal('contactFormModal')

    return (
        <div className="PageFaq">
            <PageShape sectionShape={{ variant: 'radialLeftTop' }} />

            <Header showLanguage={false} referenceType={ReferenceType.Default} />

            <PageHeader>
                <h1 className="Beta color-darkBlue">{tran.GetText('Časté dotazy')}</h1>
                <p>
                    {tran.GetText('Nenašli jste odpověď?')}{' '}
                    <button className="Link" onClick={() => open()}>
                        {tran.GetText('Kontaktujte nás')}
                    </button>
                </p>
            </PageHeader>

            <Section variantPend="appended">
                <Container>
                    <div className="PageFaq-tabs mb-40 xl:mb-80">
                        <ToggleOpenerFaqTab
                            href="#pred-nakupem"
                            clickAction="open"
                            noFlash={true}
                            isOpened={true}
                        >
                            {tran.GetText('Před nákupem')}
                        </ToggleOpenerFaqTab>
                        <ToggleOpenerFaqTab
                            href="#ochrana-osobnich-udaju"
                            clickAction="open"
                            noFlash={true}
                            isOpened={false}
                        >
                            {tran.GetText('Nákup')}
                        </ToggleOpenerFaqTab>
                        <ToggleOpenerFaqTab
                            href="#zasady-pouzivani-cookies"
                            clickAction="open"
                            noFlash={true}
                            isOpened={false}
                        >
                            {tran.GetText('Po platbě')}
                        </ToggleOpenerFaqTab>
                    </div>

                    <ToggleItem id="pred-nakupem" cluster="tabs" active={true}>
                        <dl className="Grid gap-100 gmb-50">
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Co je VIN kód?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'VIN (Vehicle Identification Number) je unikátní 17místný kód, který jednoznačně a jedinečně identifikuje vozidlo. Vyražen je přímo na karoserii vozidla a pod tímto kódem jsou evidovány všechny záznamy o vozidle. Více o VIN kódu naleznete <a href="https://www.cebia.cz/pruvodce/vin-vehicle-identification-number" target="_blank">zde</a>.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Kde najdu VIN kód?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'VIN kód je vyražen přímo na vozidle, popřípadě jej naleznete v technickém průkazu a dalších dokladech k vozidlu. Pokud chcete prověřit vůz inzerovaný na internetu, požádejte o sdělení VIN kódu prodejce. Více o tom, kde hledat VIN naleznete <a href="https://www.cebia.cz/pruvodce/kde-najdu-vin-kod" target="_blank">zde</a>.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Odkud pochází záznamy o vozidlech?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Cebia shromažďuje záznamy o vozidlech z více než 32 zemí. Záznamy nejčastěji pocházejí z oficiálních registrů, autoservisů, policejních databází, databází poškozených vozidel, pojišťoven či z inzertních portálů.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Prověřuje Cebia vozidla i v zahraničí?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Ano. Každé vozidlo je prověřováno v databázích záznamů z více než 32 zemí (včetně Německa, Dánska či USA). Po zadání VIN kódu se v přehledu nalezených informací zdarma dozvíte, jaké záznamy a z jakého období byly nalezeny.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Jaké informace se o vozidle dozvím?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Rozsah informací se u každého vozidla liší v závislosti na jeho stáří a historii. Rozsah informací k vámi prověřovanému vozidlu se zdarma dozvíte ihned po zadání VIN kódu v přehledu nalezených informací.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Jak vypadá výsledek prověření?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Výsledek prověření obsahuje nalezené záznamy z historie vozidla. Nejčastěji to jsou záznamy tachometru, údaje o poškození vozidla, záznamy z technických kontrol, fotografie vozidla či záznamy o inzerci vozidla. Více se dozvíte v <a href="https://cz.cebia.com/payed/detail/example" target="_blank">Ukázce prověření</a>.',
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </ToggleItem>
                    <ToggleItem id="ochrana-osobnich-udaju" cluster="tabs">
                        <dl className="Grid gap-100 gmb-50">
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Kolik prověření stojí?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Cena prověření vozidla se odvíjí od množství a rozsahu informací dostupných k prověřovanému vozidlu. Rozsah informací k vámi prověřovanému vozidlu a cenu prověření se dozvíte ihned po zadání VIN kódu vozidla na přehledu nalezených informací.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Jak mohu zaplatit?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Zaplatit lze online platbou, v internetovém bankovnictví, bankovním převodem či prostřednictvím Google Pay a Apple Pay. Platby zajišťuje bezpečná platební brána GoPay.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Za jak dlouho obdržím výsledek prověření?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Výsledek prověření je vygenerován ihned po přijetí platby. Služba funguje nepřetržitě a celý proces je automatický. Záleží tedy na zvolené platební metodě. Při platbě kartou je výsledek dostupný okamžitě, při platbě bankovním převodem až po přijetí platby.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Lze při platbě zadat firemní údaje?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Při vyplňování vašich údajů lze zadat identifikační údaje vaší firmy. Po platbě je však vygenerován pouze zjednodušený daňový doklad. Pro zaslání faktury nás kontaktujte na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Jak si zobrazím výsledek prověření?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Výsledek prověření je vygenerován automaticky ihned po přijetí platby. Odkaz na výsledek prověření a číslo kuponu vám přijde také na emailovou adresu zadanou při nákupu. Výsledek prověření je možné zobrazovat po dobu 30 dní po jeho vygenerování.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Platba neproběhla úspěšně, co teď?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Pokud platba z nějakého důvodu neproběhla, vraťte se o krok zpět a proveďte platbu znovu. V případě, že vám byly z účtu strženy peníze, ale přesto vám nedorazil email o provedení platby, kontaktujte nás na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>.',
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </ToggleItem>
                    <ToggleItem id="zasady-pouzivani-cookies" cluster="tabs">
                        <dl className="Grid gap-100 gmb-50">
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText(
                                        'Nebyly nalezeny žádné záznamy o poškození, co to znamená?',
                                    )}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'V takovém případě jsme v našich databázích obsahujících více než 32 zemí čítající 200 milionů a USA nenalezli žádné záznamy o poškození prověřovaného vozidla. Tato skutečnost výrazně zvyšuje pravděpodobnost, že prověřovaný vůz nebyl v minulosti havarován.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Jak dlouho bude výsledek prověření dostupný?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Výsledek prověření je dostupný po dobu 30 dní po jeho vygenerování. Zobrazit si jej můžete pomocí odkazu zaslaného na emailovou adresu zadanou při nákupu prověření.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Nedorazil mi žádný email, co s tím?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Email po zaplacení je generován automaticky, pokud do vaší schránky nedorazil, kontaktujte nás na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>. Do zprávy prosím uveďte číslo kuponu, které naleznete ve výsledku prověření, případně vaše kontaktní údaje.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Zadal jsem špatný email, co teď?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Nic se neděje. Napište nám prosím správnou adresu na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a> a do zprávy uveďte číslo kuponu uvedené ve výsledku prověření. Přístupy vám následně zašleme na správný email.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Lze výsledek prověření reklamovat?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Výsledek prověření lze reklamovat. Máte-li podezření, že jsou ve výsledku prověření uvedeny nesprávné údaje, obraťte se prosím na naše zákaznické oddělení na <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>. Více informací o reklamaci naleznete <a href="https://www.cebia.cz/kontakty#reklamace" target="_blank">zde</a>.',
                                    )}
                                </dd>
                            </div>
                            <div className="Grid-cell lg:w-6/12">
                                <dt className="Gama color-darkBlue">
                                    {tran.GetText('Lze vystavit fakturu?')}
                                </dt>
                                <dd>
                                    {tran.GetHtml(
                                        'Vzhledem k ceně prověření je automaticky generován pouze zjednodušený daňový doklad. Pro vystavení faktury nás kontaktujte na <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>. Pro urychlení do zprávy prosím uveďte i číslo kuponu uvedené v emailu, který vám přišel po nákupu.',
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </ToggleItem>
                </Container>
            </Section>

            <Footer />
            <ContactForm />
        </div>
    )
}
