import moment from 'moment'
import { ReactElement } from 'react'
import { CouponSalesProps } from '..'
import { czCurrency } from '../../../../..'
import { Table } from '../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../components/ToggleOpenerGeneralVoucher'
import { i18n, LanguageEnum } from '../../../../../translations/i18n'

export const SectionNextCheck = ({ sourceData, tran }: CouponSalesProps): ReactElement => {
    if (!sourceData.showNextCheck) return <></>
    const lng = LanguageEnum[i18n.language as keyof typeof LanguageEnum]
    const titleRightText = (() => {
        switch (lng) {
            case LanguageEnum.cz:
            case LanguageEnum.sk:
            case LanguageEnum.en:
            case LanguageEnum.de:
            case LanguageEnum.ro:
            case LanguageEnum.nl:
            case LanguageEnum.fr:
            case LanguageEnum.it:
                return '- 50 %'
            case LanguageEnum.uk:
                return '- 35 %'
            default:
                return '- 20 %'
        }
    })()

    return (
        <>
            <ToggleOpenerGeneralVoucher
                href="#saleNextCheckToggle"
                titleLeft={tran.GetText('Sleva na další prověření')}
                preTitleRightCaption={tran.GetText('Platnost do')}
                preTitleRightValue={moment(sourceData.nextCheckValidTo).format('DD. MM. YYYY')}
                titleRight={titleRightText}
                button={tran.GetText('Detail')}
                isOpened={false}
            />
            <ToggleItemGeneral id="saleNextCheckToggle">
                <div className="toggleContent">
                    <p className="text-justify">
                        {tran.GetHtml(
                            'Zakoupením prověření vozidla získáváte 50% slevu na příští nákup prověření vozidla (není možné uplatnit u ceny prověření 199 Kč). Slevu uplatníte zadáním uvedeného čísla kuponu AUTOTRACER do pole pro vložení slevového kuponu v sekci „Platba“ při příštím nákupu.',
                        )}
                    </p>
                    <p className="text-justify">
                        {tran.GetText(
                            'Slevu je možné uplatnit do 30 dnů od nákupu prověření vozidla a nelze ji kombinovat s dalšími slevami.',
                        )}
                    </p>
                    <Table className="text-left mb-30">
                        <thead></thead>
                        <tbody>
                            <tr className="color-darkBlue">
                                <td className="text-middle">
                                    <strong>{tran.GetText('Číslo slevového kuponu:')}</strong>
                                </td>
                                <td className="text-middle text-left font-bold text-noWrap">
                                    {sourceData.couponNumber}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="ButtonList mb-10">
                        <a
                            href={`/?utm_campaign=${sourceData.couponNumber}`}
                            target="_blank"
                            className="Button Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">
                                {tran.GetText('Prověřit další vůz se slevou')}
                            </span>
                        </a>
                    </div>
                </div>
            </ToggleItemGeneral>
        </>
    )
}
