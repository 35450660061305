import { i18n } from '../../../../translations/i18n'
import moment from 'moment'
import { MouseEventHandler, ReactElement, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AtrCategoryCard } from '../../../../components/AtrCategoryCard'
import { AtrCategoryCardList } from '../../../../components/AtrCategoryCardList'
import { AtrOverview } from '../../../../components/AtrOverview'
import { Button } from '../../../../components/Button'
import { Container } from '../../../../components/Container'
import { Image } from '../../../../components/Image'
import { Loader } from '../../../../components/Loader'
import { Modal, useModal } from '../../../../components/Modal'
import { Section } from '../../../../components/Section'
import { useAppContext } from '../../../../contexts/AppContext'
import { useSignalRConnection } from '../../../../contexts/SignalR/SignalRHook'
import { AtrSection } from '../../../../enums/AtrSectionEnum'
import { LanguageType } from '../../../../enums/LanguageType'
import { SignalRUrlType } from '../../../../enums/SignalRUrlEnum'
import {
    DashBoardCarInfoJsonResult,
    DashBoardJsonResult,
    DashBoardOtherJsonResult,
} from '../../../../models/dashBoardJsonResult'
import { ValidationResponseModel } from '../../../../models/validationResponseModel'
import '../../../../utils/arrayExtensions'
import { getSection } from '../../../../utils/arrayExtensions'
import { scrollToTop } from '../../../../utils/redirect'
import { TextUtils } from '../../../../utils/textUtils'
import './index.styl'
import { ButtonList } from '../../../../components/ButtonList'
import { findVinUrl, getTechnickaImg } from '../..'
import { PageShape } from '../../../../components/PageShape'
import { SignalRState } from '../../../../contexts/SignalR/SignalRSetting'

type props = {
    id: string
}

export const SectionDashboard = ({ id }: props): ReactElement => {
    const tran = new TextUtils()
    const appContext = useAppContext()
    const navigate = useNavigate()
    const { open } = useModal('findVinModal')
    const { open: openNoData, close: closeModal } = useModal('noDataModal')
    const [searchParams] = useSearchParams()
    const pay = searchParams.get('pay') ?? ''

    const { bufferedData, hubState } = useSignalRConnection({
        url: SignalRUrlType.DashBoard,
    })
    const [carInfo, setCarInfo] = useState<DashBoardCarInfoJsonResult>(
        new DashBoardCarInfoJsonResult(appContext.appModel.validateResponseModel),
    )
    const priceDashboardInfo = appContext.appModel.priceDashboardInfo
    const [dashBoardOdometer, setDashBoardOdometer] = useState<DashBoardJsonResult | null>(null)
    const [dashBoardPhoto, setDashBoardPhoto] = useState<DashBoardJsonResult | null>(null)
    const [dashBoardService, setdashBoardService] = useState<DashBoardJsonResult | null>(null)
    const [dashBoardHistory, setdashBoardHistory] = useState<DashBoardJsonResult | null>(null)
    const [otherService, setdashBoardOther] = useState<DashBoardOtherJsonResult | null>(null)
    const [showVinCheck, setShowVinCheck] = useState(false)
    const [isNoData, setIsNoData] = useState(false)
    const [agreement, setAgreement] = useState(true)
    const [payCalled, setPayCalled] = useState(false)
    useEffect(() => {
        setDashBoardOdometer(getSection<DashBoardJsonResult>(bufferedData, AtrSection.Tachos))
        setDashBoardPhoto(getSection<DashBoardJsonResult>(bufferedData, AtrSection.CarPhoto))
        setdashBoardService(
            getSection<DashBoardJsonResult>(bufferedData, AtrSection.TachosServiceRecords),
        )
        setdashBoardHistory(getSection<DashBoardJsonResult>(bufferedData, AtrSection.CarHistory))
        const carInfoData = getSection<DashBoardCarInfoJsonResult>(bufferedData, AtrSection.CarInfo)
        if (carInfoData) {
            appContext.appModel.validateResponseModel = new ValidationResponseModel(carInfoData)
            appContext.setLoading(false)
            setCarInfo(carInfoData)
        }
        setdashBoardOther(getSection<DashBoardOtherJsonResult>(bufferedData, AtrSection.Other))
        const otherSection = getSection<DashBoardOtherJsonResult>(bufferedData, AtrSection.Other)
        if (otherSection?.showVinCheck ?? false) {
            setShowVinCheck(otherSection.showVinCheck)
        }
        if (otherSection?.isNoData ?? false) {
            setIsNoData(otherSection.isNoData)
        }
    }, [bufferedData])

    useEffect(() => {
        if (isNoData) {
            setAgreement(false)
            openNoData()
        }
    }, [isNoData])
    const handleButtonClick: MouseEventHandler = () => {
        scrollToTop()
        navigate('/carinfo/payment/' + id)
    }

    useEffect(() => {
        if (pay === '1' && hubState === SignalRState.HubClosed && !payCalled) {
            setPayCalled(true)
            navigate('/carinfo/payment/' + id)
        }
    }, [hubState, payCalled])

    return (
        <Section className="SectionDashboard" variant="bare">
            <PageShape sectionShape={{ variant: 'rightBottomUp' }} />
            <Container>
                <div className="Grid gap-50">
                    <div className="Grid-cell lg:w-4/12 sm-mb-30 lg:order-1">
                        <AtrOverview
                            disabled={otherService == null || !agreement}
                            key={`${carInfo?.vin}_default`}
                            variant="default"
                            logo={{
                                src: carInfo?.imageData
                                    ? `data:image/png;base64, ${carInfo?.imageData}`
                                    : '',
                            }}
                            mobileSnap={true}
                            title={`${carInfo?.carMake ?? ''}|${carInfo?.carModel ?? ''}`}
                            vin={carInfo?.vin ?? ''}
                            footerTitle={tran.GetText('Koupit prověření')}
                            showDiscount={priceDashboardInfo?.showDiscount}
                            priceBefore={`${priceDashboardInfo?.standardPrice
                                ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                .replace(
                                    '.',
                                    priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                )} ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            priceAfter={`${
                                priceDashboardInfo?.totalPrice
                                    ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                    .replace(
                                        '.',
                                        priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                    ) ?? ''
                            } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            button={tran.GetText('Pokračovat')}
                            onclick={handleButtonClick}
                            isLoading={priceDashboardInfo?.isLoading}
                            priceInfo={priceDashboardInfo}
                        />
                        <AtrOverview
                            disabled={otherService == null || !agreement}
                            key={`${carInfo?.vin}_fixed`}
                            variant="fixed"
                            className="lg:hidden"
                            title={`${carInfo?.carMake ?? ''}|${carInfo?.carModel ?? ''}`}
                            showDiscount={priceDashboardInfo?.showDiscount}
                            priceBefore={`${
                                priceDashboardInfo?.standardPrice
                                    ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                    .replace(
                                        '.',
                                        priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                    ) ?? ''
                            } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            priceAfter={`${
                                priceDashboardInfo?.totalPrice
                                    ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                    .replace(
                                        '.',
                                        priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                    ) ?? ''
                            } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            button={tran.GetText('Pokračovat')}
                            onclick={handleButtonClick}
                            isLoading={priceDashboardInfo?.isLoading}
                            priceInfo={priceDashboardInfo}
                        />
                        {/* <div className="SectionDashboard-overviewRibbon lg:hidden">
                            <Image src={AssetsImgRibbon} srcSet={AssetsImgRibbon2x} alt="" />
                        </div> */}
                    </div>
                    <div className="Grid-cell lg:w-8/12">
                        {showVinCheck && (
                            <h2 className="color-errorRed lg:font-xxl mb-20 text-center md:text-left d-flex">
                                <strong>{tran.GetText('Varování!')}</strong>
                                <span className="ml-5">
                                    {tran.GetText('Zadané VIN může být chybné!')}
                                </span>
                                <a
                                    className="vinFindLink Link--line color-errorRed"
                                    onClick={() => open()}
                                >
                                    {tran.GetText('Více informací?')}
                                </a>
                            </h2>
                        )}
                        <h2 className="color-darkBlue lg:font-xxl mb-20 text-center md:text-left d-flex">
                            {dashBoardOdometer == null ? (
                                <>
                                    {tran.GetText('Načítáme záznamy')}
                                    <Loader variant="bars" className="ml-10" />
                                </>
                            ) : (
                                <>
                                    <span className="color-green mr-5">
                                        {tran.GetText('Hotovo!')}
                                    </span>
                                    <span>
                                        {tran.GetText('Lze prověřit následující informace:')}
                                    </span>
                                </>
                            )}
                        </h2>
                        <div className="Grid gap-15 gmb-15 mb-10">
                            <div className="Grid-cell md:w-6/12">
                                <AtrCategoryCard
                                    variant="main"
                                    bubbleOpener={true}
                                    descriptionId={1}
                                    descriptionPositiv={(dashBoardOdometer?.recordsCount ?? 0) > 0}
                                    title={tran.GetText('Kontrola najetých kilometrů')}
                                    visualIcon={{ name: 'speedometerCheck' }}
                                    isLoading={dashBoardOdometer?.isLoading}
                                    textLabel={tran.GetText('Období:')}
                                    textStatus={
                                        (dashBoardOdometer?.recordsCount ?? 0) > 0
                                            ? tran.ZaznamyText(dashBoardOdometer?.recordsCount ?? 0)
                                            : tran.GetText('Není k dispozici')
                                    }
                                    textDate={
                                        dashBoardOdometer?.minDate && dashBoardOdometer.maxDate
                                            ? moment(dashBoardOdometer?.minDate).format('YYYY') +
                                              ' - ' +
                                              moment(dashBoardOdometer?.maxDate).format('YYYY')
                                            : ''
                                    }
                                    isVisible={true}
                                    isDisabled={
                                        dashBoardOdometer?.isLoading ||
                                        (dashBoardOdometer?.recordsCount ?? 0) > 0
                                            ? false
                                            : true
                                    }
                                />
                            </div>
                            <div className="Grid-cell md:w-6/12">
                                <AtrCategoryCard
                                    bubbleOpener={true}
                                    descriptionId={2}
                                    descriptionPositiv={!isNoData}
                                    variant="main"
                                    title={tran.GetText('Kontrola poškození')}
                                    visualIcon={{ name: 'carCrash' }}
                                    isLoading={otherService == null}
                                    textLabel={tran.GetText('více než')}
                                    textDate={tran.GetText('32 zemí')}
                                    isDisabled={isNoData}
                                    textStatus={
                                        otherService?.isNoData
                                            ? tran.GetText('Není k dispozici')
                                            : tran.GetText('K dispozici')
                                    }
                                    isVisible={true}
                                />
                            </div>
                            <div className="Grid-cell md:w-6/12">
                                <AtrCategoryCard
                                    bubbleOpener={true}
                                    variant="main"
                                    descriptionId={3}
                                    descriptionPositiv={(dashBoardPhoto?.recordsCount ?? 0) > 0}
                                    title={tran.GetText('Fotky a inzerce')}
                                    visualIcon={{ name: 'photoCamera' }}
                                    isLoading={dashBoardPhoto == null}
                                    textStatus={
                                        (dashBoardPhoto?.recordsCount ?? 0) > 0
                                            ? tran.FotografieText(dashBoardPhoto?.recordsCount ?? 0)
                                            : tran.GetText('Není k dispozici')
                                    }
                                    textDate={tran.GetText('K dispozici')}
                                    /*textLabel={tran.GetText('Období:')}
                                    textDate={
                                        dashBoardPhoto?.minDate && dashBoardPhoto.maxDate
                                            ? moment(dashBoardPhoto?.minDate).format('YYYY') +
                                              ' - ' +
                                              moment(dashBoardPhoto?.maxDate).format('YYYY')
                                            : ''
                                    }*/
                                    isDisabled={
                                        dashBoardPhoto == null ||
                                        (dashBoardPhoto?.recordsCount ?? 0) > 0
                                            ? false
                                            : true
                                    }
                                    isVisible={true}
                                />
                            </div>
                            <div className="Grid-cell md:w-6/12">
                                <AtrCategoryCard
                                    bubbleOpener={true}
                                    descriptionId={4}
                                    descriptionPositiv={(dashBoardHistory?.recordsCount ?? 0) > 0}
                                    variant="main"
                                    title={tran.GetText('Kontrola stáří a původu')}
                                    visualIcon={{ name: 'calendarCar' }}
                                    isLoading={dashBoardHistory == null}
                                    textLabel=""
                                    textDate=""
                                    isDisabled={
                                        dashBoardHistory === null ||
                                        (dashBoardHistory?.recordsCount ?? 0) > 0
                                            ? false
                                            : true
                                    }
                                    textStatus={
                                        (dashBoardHistory?.recordsCount ?? 0) > 0
                                            ? tran.GetText('K dispozici')
                                            : tran.GetText('Není k dispozici')
                                    }
                                    isVisible={true}
                                />
                            </div>
                            <div className="Grid-cell md:w-6/12">
                                <AtrCategoryCard
                                    bubbleOpener={true}
                                    variant="main"
                                    descriptionId={5}
                                    descriptionPositiv={true}
                                    title={tran.GetText('Kontrola odcizení')}
                                    visualIcon={{ name: 'thiefCar' }}
                                    isLoading={false}
                                    textStatus={tran.GetText('K dispozici')}
                                    textLabel={tran.GetText('až')}
                                    textDate={tran.GetText('12 registrů')}
                                    isDisabled={false}
                                    isVisible={true}
                                />
                            </div>
                            <div className="Grid-cell md:w-6/12">
                                <AtrCategoryCard
                                    bubbleOpener={true}
                                    variant="main"
                                    descriptionId={6}
                                    descriptionPositiv={true}
                                    title={tran.GetText('Kontrola taxi')}
                                    visualIcon={{ name: 'glassCar' }}
                                    isLoading={false}
                                    textStatus={tran.GetText('K dispozici')}
                                    textLabel=""
                                    textDate=""
                                    isDisabled={false}
                                    isVisible={true}
                                />
                            </div>
                        </div>

                        <AtrCategoryCardList>
                            <AtrCategoryCard
                                variant="simple"
                                title={tran.GetText('Kontrola financování')}
                                visualIcon={{ name: 'tabletMoney', className: 'VisualIcon-small' }}
                                isVisible={true}
                                textSimple={tran.GetText('K dispozici')}
                            />
                            <AtrCategoryCard
                                variant="simple"
                                title={tran.GetText('Svolávací akce')}
                                visualIcon={{
                                    name: 'megaphone',
                                    className: 'VisualIcon-small',
                                }}
                                isVisible={true}
                                textSimple={tran.GetText('K dispozici')}
                            />
                            <AtrCategoryCard
                                variant="simple"
                                title={tran.GetText('Kontrola zabezpečení')}
                                visualIcon={{ name: 'carLock', className: 'VisualIcon-small' }}
                                isVisible={true}
                                textSimple={tran.GetText('K dispozici')}
                            />
                            <AtrCategoryCard
                                variant="simple"
                                title={tran.GetText('Informace o vozidle')}
                                isVisible={true}
                                visualIcon={{ name: 'car', className: 'VisualIcon-small' }}
                                textSimple={tran.GetText('K dispozici')}
                            />
                            <AtrCategoryCard
                                variant="simple"
                                title={tran.GetText('Servisní historie')}
                                visualIcon={{
                                    name: 'tabletCar',
                                    className: 'VisualIcon-small',
                                }}
                                isVisible={(dashBoardService?.recordsCount ?? 0) > 0}
                                textSimple={
                                    (dashBoardService?.recordsCount ?? 0) > 0
                                        ? tran.ZaznamyText(dashBoardService?.recordsCount ?? 0)
                                        : tran.GetText('Není k dispozici')
                                }
                            />
                            <AtrCategoryCard
                                variant="simple"
                                title={tran.GetText('Ocenění vozidla')}
                                visualIcon={{
                                    name: 'carDollar',
                                    className: 'VisualIcon-small',
                                }}
                                textSimple={tran.GetText('K dispozici')}
                                isVisible={carInfo && [2, 3].includes(carInfo?.otherType ?? 0)}
                            />
                        </AtrCategoryCardList>
                        <div className="Grid gap-15 gmb-15 mb-10 mobile-show">
                            <div className="Grid-cell md:w-12/12">
                                <AtrOverview
                                    disabled={otherService == null}
                                    key={`${carInfo?.vin}_mobile`}
                                    className="atrOverViewMobile"
                                    variant="mobile"
                                    logo={{
                                        src: carInfo?.imageData
                                            ? `data:image/png;base64, ${carInfo?.imageData}`
                                            : '',
                                    }}
                                    mobileSnap={true}
                                    title={`${carInfo?.carMake ?? ''}|${carInfo?.carModel ?? ''}`}
                                    vin={carInfo?.vin ?? ''}
                                    footerTitle={tran.GetText('Koupit prověření')}
                                    showDiscount={priceDashboardInfo?.showDiscount}
                                    priceBefore={`${
                                        priceDashboardInfo?.standardPrice
                                            ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                            .replace(
                                                '.',
                                                priceDashboardInfo.countryId == LanguageType.SK
                                                    ? ','
                                                    : '.',
                                            ) ?? ''
                                    } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                                    priceAfter={`${
                                        priceDashboardInfo?.totalPrice
                                            ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                            .replace(
                                                '.',
                                                priceDashboardInfo.countryId == LanguageType.SK
                                                    ? ','
                                                    : '.',
                                            ) ?? ''
                                    } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                                    button={tran.GetText('Pokračovat')}
                                    onclick={handleButtonClick}
                                    isLoading={priceDashboardInfo?.isLoading}
                                    priceInfo={priceDashboardInfo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Modal id="findVinModal">
                <Container>
                    <div className="Grid gap-30 gmb-20 smw:gmb-50 Flex--justifyCenter">
                        <div className="Grid-cell md:w-1/2">
                            <h3 className="sameFontSize">
                                {tran.GetText('Zadané VIN vozidla může být chybné!')}
                            </h3>
                            <p className="sameFontSize">
                                {tran.GetText(
                                    'V zadaném čísle VIN byla detekována možná chyba. Zkontrolujte prosím, zda se zadané VIN schoduje s VIN číslem vyraženém na vozidle nebo uvedeném technické průkazu.',
                                )}
                            </p>
                            <a
                                className="Link Link--lightBlue"
                                href={findVinUrl()}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {tran.GetText('Kde hledat VIN?')}
                            </a>
                        </div>
                        <div className="Grid-cell md:w-1/2">
                            <Image
                                src={getTechnickaImg()}
                                alt={tran.GetText('technický průkaz - vin kód')}
                            />
                        </div>
                    </div>
                </Container>
            </Modal>

            <Modal id="noDataModal">
                <Container>
                    <div className="Grid gap-30 gmb-20 smw:gmb-50 Flex--justifyCenter">
                        <div className="Grid-cell md:w-2/2">
                            <h3 className="sameFontSize text-center bold">
                                {tran.GetText('Vámi zadané VIN může být chybné')}
                            </h3>
                            <p className="sameFontSize text-center">
                                {tran.GetText(
                                    'K tomuto VIN nelze dohledat záznamy z jeho historie.',
                                )}{' '}
                                <br></br>
                                <a
                                    className="Link Link--lightBlue text-center"
                                    href={findVinUrl()}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {tran.GetText('Doporučujeme zkontrolovat VIN')}
                                </a>
                                {'.'}
                            </p>
                        </div>
                    </div>
                    <div className="lg:w-auto mt-20">
                        <div className="ButtonList justifyCenter">
                            <Button
                                className="mr-80"
                                as="button"
                                variantColor="blue"
                                variantSize="small"
                                onClick={() => {
                                    window.location.assign('/')
                                }}
                            >
                                {tran.GetText('Prověřit jiné VIN')}
                            </Button>

                            <Button
                                className="ml-80"
                                as="button"
                                variantColor="green"
                                variantSize="small"
                                onClick={() => {
                                    setAgreement(true)
                                    closeModal()
                                }}
                            >
                                {tran.GetText('Přesto prověřit')}
                            </Button>
                        </div>
                    </div>
                </Container>
            </Modal>
        </Section>
    )
}
