import './index.styl'
import { Children, ReactChild, ReactChildren, ReactElement } from 'react'
import classNames from 'classnames'

type Props = {
    children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
}

export const AtrCategoryCardList = ({ children }: Props): ReactElement => {
    const pocet = Children.count(children)
    const className = classNames('AtrCategoryCardList', [[`AtrCategoryCardList--${pocet}`]])

    return (
        <div className={className}>
            {Children.map(children, child => (
                <>{child}</>
            ))}
        </div>
    )
}
