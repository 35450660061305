import { useQuery } from 'react-query'
import { Translate } from '../translations/CebiaTranslator'
import {
    CompanyInfo,
    PaymentGate,
    PaymentPrice,
    PaymentReqModel,
    ViesInfo,
} from '../models/paymentReqModel'
import http from './http-common'
import { IsValidEnum } from '../enums/IsValidEnum'
import { LoadType } from '../enums/LoadTypeEnum'

export const getCompanyInfo = async (dic: string) => {
    let retVal: CompanyInfo | null = null

    if (dic && dic.length > 0) {
        const tmpResponse = await http.get<CompanyInfo>('/Payment/GetCompany?dic=' + dic)

        if (tmpResponse.status === 200 && tmpResponse.data) {
            retVal = tmpResponse.data
        }
    }

    return retVal
}

export const getViesInfo = async (dic: string) => {
    let retVal: ViesInfo | null = null

    if (dic && dic.length > 0) {
        try {
            const tmpResponse = await http.get<ViesInfo>('/Payment/GetViesInfo?dic=' + dic, {
                timeout: 15000,
            })

            if (tmpResponse.status === 200 && tmpResponse.data) {
                retVal = tmpResponse.data
            }
        } catch (err) {
            retVal = new ViesInfo()
            retVal.error = err as string
            return retVal
        }
    }

    return retVal
}

export const getCouponInfo = (payment: PaymentReqModel) => {
    let retVal: PaymentPrice | undefined

    const { data, error, isError, refetch, isLoading } = useQuery(
        'getCoupon',
        () => http.post<PaymentPrice>('/Payment/GetCoupon', payment),
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    )

    if (!isError) {
        retVal = data?.data
    }

    if (isError) {
        retVal = new PaymentPrice()
        retVal.isValid = IsValidEnum.NotValid
    }

    return {
        couponData: retVal,
        couponRefetch: refetch,
        couponIsLoading: isLoading,
        couponError: isError,
    }
}

export const setPayment = async (payment: PaymentReqModel, tran: Translate) => {
    let retVal = ''
    //const tran = new Translate()

    if (payment) {
        const tmpResponse = await http.post<PaymentGate>('/Payment/CreatePayment', payment)

        if (tmpResponse.status === 200 && tmpResponse.data) {
            if (
                tmpResponse.data.isValid > 0 &&
                tmpResponse.data.paymentGateUrl &&
                tmpResponse.data.paymentGateUrl.length > 0
            ) {
                //redirect to paymentUrl
                window.location.assign(tmpResponse.data.paymentGateUrl)
            } else {
                retVal = tmpResponse.data.message
            }
        } else {
            retVal = tran.GetText('Nepovedlo se vygenerovat platební url.')
        }
    }

    return retVal
}

export const reloadPayment = async (
    id: string,
    validatedByVies: number,
    saleCoupon: string,
    loadType: LoadType,
) => {
    let retVal: PaymentReqModel | null = null

    if (id) {
        const tmpResponse = await http.get<PaymentReqModel>(
            `/Payment/ReloadData?requestId=${id}&loadType=${loadType}&validatedByVies=${validatedByVies}&salesCoupon=${saleCoupon}`,
        )

        if (tmpResponse.status === 200 && tmpResponse.data) {
            if (tmpResponse.data.isValid > 0) {
                retVal = tmpResponse.data
            }
        }
    }

    return retVal
}
