/* eslint-disable */
const TRANSLATIONS_CZ = {
'1901555939':'VIN',
'-971921755':'Tovární značka',
'1891129765':'Model',
'691501523':'Typ karosérie',
'-1026019663':'Druh vozidla',
'274952045':'Palivo',
'-348535418':'Výkon',
'1000727131':'Objem',
'-440181428':'Datum výroby',
'841597003':'Sdílet',
'-762872471':'Zobrazit',
'-1615835216':'Odkaz',
'-466013687':'Zavřít',
'-1655267775':'Kopírovat odkaz',
'-1448849529':'Lze vůz prověřit i bez znalosti VIN?',
'-1310016716':'Prověření vozidla',
'1410745983':'online',
'-1803530129':'Vygenerovat Cebia REPORT',
'934563996':'Výsledek kontroly',
'-1102433484':'Ukázka prověření',
'-1242381959':'Historie vozidla',
'417677035':'Kontrola závazků vozidla',
'-115937358':'Stav vozidla',
'457834942':'Časté dotazy',
'226942208':'Co je to VIN?',
'1739046820':'Kolik prověření stojí?',
'16204687':'Jak mohu zaplatit?',
'-1607701055':'Jak si zobrazím výsledek prověření?',
'-1969713941':'Lze výsledek prověření reklamovat?',
'1598751038':'Kde najdu číslo kuponu',
'-1206763576':'Více informací',
'-729900752':'Online platba',
'671435147':'Zadejte číslo slevového kuponu',
'-2004947080':'Zadejte číslo kuponu',
'-1083354004':'PŘEPOČÍTAT',
'-1589008349':'Jméno',
'-704955254':'Příjmení',
'-1631818401':'Ulice a číslo',
'1006101298':'Město',
'1063186093':'Země',
'287700219':'PSČ',
'-1411201334':'Daňové identifikační číslo',
'513366821':'DIČ',
'-102071178':'Název společnosti',
'-1882309597':'Identifikační číslo firmy',
'-1515081676':'IČ',
'1440993804':'Kontaktní email',
'-1405758046':'Kontaktní telefon',
'1366947192':'Vaše telefonní číslo',
'-490851561':'Sleva 100 Kč na další prověření',
'-108743580':'Kč',
'786987358':'Koupit',
'-1887658751':'Výsledky jsou k dispozici hned po zaplacení',
'-1018049108':'Výsledky jsou k dispozici obvykle do 60 minut',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Bankovním převodem',
'-1663277':'Výsledky budou k dispozici do 48 hodin',
'1626540224':'V hotovosti v sídle Cebia',
'72162446':'Prověřte si vozidlo',
'757687806':'Přehled informací zdarma',
'1954205527':'Kde najdu VIN kód?',
'1603459473':'Vstup po zaplacení',
'2038366987':'Zobrazit výsledek prověření',
'-374549211':'Kde najdu číslo kuponu?',
'1262814082':'Zadání VIN',
'-15600132':'Datum 1. registrace',
'1535680605':'Kód kuponu:',
'-1091217450':'Platnost kuponu do:',
'-1264594150':'Datum aktivace kuponu:',
'-701611594':'Litujeme, vozidlo bohužel nelze prověřit.',
'160166931':'Stávající majitel vozidla si nepřeje zobrazení údajů o vozidle.',
'-351255422':'Inzerát, který prohlížíte, nemá uvedený VIN vozidla.',
'1704462566':'V Inzerátu vozidla není uveden celý VIN.',
'366367808':'Jak dlouho trvá, než obdržím výsledek prověření?',
'2136729030':'VIN:',
'400669011':'Na slevu máte nárok v případě že:',
'-219050562':'Blog',
'1449193326':'O nás',
'1097675154':'Kontakt',
'2062322678':'Novinky',
'-1263831198':'Historie společnosti',
'1166836894':'Naši partneři',
'-1532347183':'Pro média',
'717698781':'Kariéra',
'1620687461':'Vozidlo je evidované jako odcizené. Cebia REPORT nelze vytvořit',
'1365729306':'Číslo CR:',
'-89669431':'Rok první registrace:',
'2130038984':'Stav tachometru:',
'2089870291':'Auto pod kontrolou s aplikací carolina',
'1333652846':'Stáhnout zdarma',
'1347577444':'Délka VIN musí být 17 znaků',
'622759132':'Odkaz zkopírován',
'1714373031':'Prověřovaného vozu se mohou týkat',
'1227286459':'Nalezené svolávací akce',
'-477335598':'SVOLÁVACÍ AKCE',
'-343698081':'K prověřovanému vozu nebyly nalezeny',
'-1536285860':'Žádné svolávací akce',
'-2052297753':'Datum vyhlášení',
'1522685090':'Detail',
'444715065':'Více info',
'-394230090':'Musíte odsouhlasit zadané údaje.',
'1224828864':'Nesprávna hodnota rok první registrace.',
'-470093943':'Prosím zadejte rok první registrace.',
'1035831822':'Nesprávna hodnota stav tachometru.',
'-1161922611':'Prosím zadejte položku stav tachometru.',
'-1383225585':'Zadejte rok první registrace',
'1111232857':'Rok první registrace',
'-1998715395':'Zadejte stav tachometru',
'-1962337257':'Stav tachometru',
'-1888253249':'Upřesnění modelu',
'-339477539':'- 300 Kč',
'917746804':'Jednodušší už to být nemůže:',
'1405508217':'Předběžná konzultace ZDARMA',
'127414665':'Jak uplatnit slevu?',
'-2081078087':'VYBRAT TECHNIKA',
'1899967331':'Maximalni počet znaků musi byt 20.',
'1699452695':'Chybný kód banky',
'1812875983':'Prosím zadejte položku Číslo účtu.',
'-670727524':'Prosím zadejte položku Číslo pojistné smlouvy.',
'1884544037':'ePojisteni.cz',
'138266606':'Platnost do',
'1881823014':'Podmínky pro vrácení peněz:',
'-1548724023':'Vozidlo musí být pojištěno minimálně 30 dní',
'1331666705':'Chyba uložení. Opakujte akci.',
'-1393210532':'Číslo účtu',
'439576396':'Vaše číslo účtu',
'773379637':'Číslo pojistné smlouvy',
'-1281605763':'Uplatnit vrácení peněz',
'-1670752753':'Sleva na prověření v zahraničí',
'1496813111':'- 550',
'-856872708':'Základní cena',
'616158592':'SLEVA',
'-581170701':'Cena po slevě',
'-437309148':'s DPH',
'-1731075532':'Video',
'-189892310':'Objednat',
'98918066':'Postup pro objednání:',
'-1944610133':'Sleva na další prověření',
'-621660650':'Číslo slevového kuponu:',
'-1845841489':'Prověřit další vůz se slevou',
'1171588656':'Sleva na zabezpečení vozidla',
'1440663595':'- 850',
'-1503110723':'Sleva na fyzickou prověrku',
'-675817237':'- 925',
'986037818':'Poškození nalezeno',
'873964955':'Datum',
'790922325':'Kalkulace nákladů na opravu',
'-1825735064':'Druh události',
'486919475':'Evidovaná místa poškození',
'1685506455':'Vůz je financován',
'2039551191':'Financování nenalezeno',
'-570695392':'v aktuálně dostupných databázích',
'-765744228':'Inzerce',
'659651744':'Datum pořízení inzerátu',
'-695638522':'Inzerovaný nájezd',
'772986188':'Inzerovaná cena',
'419477574':'Záznamy o stáří a původu vozidla',
'-109775782':'Datum výroby dle výrobce',
'-218677523':'První registrace',
'-340115089':'První registrace v ČR',
'1192563560':'První registrace v SK',
'1650211924':'Vyrobeno pro trh',
'1812132824':'Strana řízení',
'-1229728494':'Materiál (výčet)',
'-1147664160':'Práce (výčet)',
'708828581':'Provozováno jako taxi',
'284182756':'Vozidlo je evidováno jako odcizené',
'1244175337':'Není evidováno jako odcizené',
'791649880':'Zdroj',
'705052471':'Ke dni',
'1239149764':'Kontrola identifikátorů',
'-799257304':'Model:',
'-1964821919':'Datum první registrace:',
'-1530361098':'Cena nového vozidla',
'68348546':'Aktuální tržní cena',
'763547310':'Datum první registrace',
'1968050624':'Uložit',
'-1300982647':'Seznam výbavy dle výrobce',
'397739850':'Kontrola barvy vozidla',
'479427038':'K dispozici',
'2038192723':'Kontrola zabezpečení vozidla',
'-757741002':'Technický popis vozidla',
'278755414':'Návod na identifikaci vozu',
'1529692191':'Barva vozidla',
'-1171491320':'Identifikace vozidla',
'1549116932':'Soupis výbavy vozidla',
'-1985480381':'Ostatní',
'-1354462940':'ZKONTROLUJTE:',
'-690036295':'Datum registrace:',
'1506895356':'Typ zabezpečení vozidla:',
'-749715579':'Kód značení:',
'-329154043':'Barva vozidla:',
'1441236976':'Informace o provedeném značení skel',
'729768522':'Značení skel',
'1812345820':'NE',
'847948448':'MÁTE ZÁJEM O ZABEZPEČENÍ VOZIDLA?',
'-1899070354':'Technický popis - základní',
'-953303':'Technický popis - rozšířený',
'1237026086':'Nesprávna hodnota Typ vozidla.',
'-259360107':'Prosím zadejte Typ vozidla.',
'-1906046398':'Minimalni pocet znaků musi byt 1.',
'-69524395':'Maximalni počet znaků musi byt 30.',
'1663292305':'Prosím zadejte položku Značka.',
'814923836':'Prosím zadejte položku Model.',
'-1985695287':'Prosím zadejte položku Detail určení.',
'244480988':'Značka',
'1602844398':'Detailní určení',
'-1749998805':'Vyberte vozidlo',
'-1162182506':'Typ vozidla',
'1104651533':'technický průkaz - vin kód',
'-1898506589':'čelní sklo auta - vin kód',
'216861745':'Prosím odsouhlaste zadané údaje!',
'-1328097963':'Maximální počet znaků je 9.',
'1678639740':'Prosím zadejte položku Telefon.',
'1303052228':'Prosím zadejte položku Jméno.',
'1792286505':'Maximální počet znaků je 128.',
'2122707327':'Prosím zadejte položku Příjmení.',
'-938825602':'Maximální počet znaků je 64.',
'1683031395':'Prosím zadejte položku Ulice a číslo.',
'661065202':'Prosím zadejte položku Město.',
'-83271898':'Prosím zadejte položku PSČ.',
'1018067916':'Maximální počet znaků je 5.',
'12673416':'PSČ má nesprávný formát.',
'154576238':'Číslo kuponu má nesprávný formát.',
'-581020701':'je vyhlašována výrobcem vozidla povinně ze zákona vždy, když je ohroženo zdraví, bezpečnost, i životní prostředí. Týkají se zpravidla výrobních závad vozidla, které se projeví až při jeho provozu a výrobce tímto způsobem zajišťuje jejich bezplatnou nápravu. Informace jsou čerpány z webu <a href="https://svolavacky.cz/" target="_blank">Svolávačky.cz</a> s pomocí systému RAPEX.',
'1969671182':'Uvedené svolávací akce <strong>byly vygenerovány podle modelu, značky a roku výroby</strong> prověřovaného vozidla, <strong>nikoliv dle jeho VIN</strong>. Vždy si proto v detailu akce ověřte, <strong>zda se týkala i prověřovaného vozidla</strong>. Zároveň si ověřte u prodejce vozidla či v autorizovaném autoservisu, zda vozidlo platné svolávací akce podstoupilo.',
'-2076502822':'Svolávací akce je vyhlašována výrobcem vozidla povinně ze zákona, pokud má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada v autorizovaném servisu zdarma odstraněna. Data poskytuje portál <a href="https://svolavacky.cz/" target="_blank">Svolávačky.cz</a> s pomocí systému RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Uzavřete pojistku prostřednictvím portálu <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> a získejte zpět peníze za prověření vozidla.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'5180415':'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'-312241960':'* Soukromé databáze umožňují <strong>zpravidla</strong> komukoliv vložit informace o odcizení.',
'1225691915':'V dostupných databázích bylo zjištěno, že vozidlo bylo nebo stále je <span class="warning">provozováno jako taxi</span>. Důkladně zkontrolujte technický stav vozu.',
'-742868638':'Vaše e-mailová adresa',
'2033866416':'On-line platební kartou',
'138706621':'On-line převod',
'-1634274740':'Zobrazit Cebia REPORT',
'1047356790':'Prosím zadejte datum první registrace.',
'-39394372':'Inzerce vozidel s menší shodou',
'-862383013':'Inzerce podobných vozidel',
'-233652021':'HISTORIE VOZIDLA',
'271847434':'Cebia REPORT',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'Při objednání kontroly vozu na Automato.cz vložte do určeného pole v košíku slevový kód <b>AUTOTRACER</b> a sleva vám bude automaticky odečtena z ceny.',
'-1554923364':'(cesta technika zdarma)',
'288042578':'Prosím zadejte položku Název společnosti.',
'-1335200216':'Nesprávná hodnota IČ.',
'1693391088':'Email má nesprávný formát!',
'-766469985':'Prosím zadejte položku Email',
'-1397806656':'Souhlas se zpracováním osobních údajů',
'-189484387':'Odebírat',
'1095553640':'Všechny dotazy',
'1531150438':'Nenašli jste odpověď?',
'-469136467':'Kontaktujte nás',
'-461584700':'Před nákupem',
'400498991':'Zásady používání cookies',
'1329764956':'Co je VIN kód?',
'-394767958':'Nákup',
'181431424':'Po platbě',
'1649241229':'VIN kód je vyražen přímo na vozidle, popřípadě jej naleznete v technickém průkazu a dalších dokladech k vozidlu. Pokud chcete prověřit vůz inzerovaný na internetu, požádejte o sdělení VIN kódu prodejce. Více o tom, kde hledat VIN naleznete <a href="https://www.cebia.cz/pruvodce/kde-najdu-vin-kod" target="_blank">zde</a>.',
'1774694482':'Odkud pochází záznamy o vozidlech?',
'-1122721284':'Prověřuje Cebia vozidla i v zahraničí?',
'437878122':'Jaké informace se o vozidle dozvím?',
'850725814':'Rozsah informací se u každého vozidla liší v závislosti na jeho stáří a historii. Rozsah informací k vámi prověřovanému vozidlu se zdarma dozvíte ihned po zadání VIN kódu v přehledu nalezených informací.',
'-656971513':'Jak vypadá výsledek prověření?',
'966688141':'Cena prověření vozidla se odvíjí od množství a rozsahu informací dostupných k prověřovanému vozidlu. Rozsah informací k vámi prověřovanému vozidlu a cenu prověření se dozvíte ihned po zadání VIN kódu vozidla na přehledu nalezených informací.',
'764893782':'Zaplatit lze online platbou, v internetovém bankovnictví, bankovním převodem či prostřednictvím Google Pay a Apple Pay. Platby zajišťuje bezpečná platební brána GoPay.',
'726713260':'Za jak dlouho obdržím výsledek prověření?',
'1478989945':'Výsledek prověření je vygenerován ihned po přijetí platby. Služba funguje nepřetržitě a celý proces je automatický. Záleží tedy na zvolené platební metodě. Při platbě kartou je výsledek dostupný okamžitě, při platbě bankovním převodem až po přijetí platby.',
'1681038954':'Lze při platbě zadat firemní údaje?',
'696259005':'Při vyplňování vašich údajů lze zadat identifikační údaje vaší firmy. Po platbě je však vygenerován pouze zjednodušený daňový doklad. Pro zaslání faktury nás kontaktujte na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>.',
'-1555365601':'Výsledek prověření je vygenerován automaticky ihned po přijetí platby. Odkaz na výsledek prověření a číslo kuponu vám přijde také na emailovou adresu zadanou při nákupu. Výsledek prověření je možné zobrazovat po dobu 30 dní po jeho vygenerování.',
'1984400663':'Platba neproběhla úspěšně, co teď?',
'-956265022':'Pokud platba z nějakého důvodu neproběhla, vraťte se o krok zpět a proveďte platbu znovu. V případě, že vám byly z účtu strženy peníze, ale přesto vám nedorazil email o provedení platby, kontaktujte nás na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>.',
'-246543694':'Výsledek prověření je dostupný po dobu 30 dní po jeho vygenerování. Zobrazit si jej můžete pomocí odkazu zaslaného na emailovou adresu zadanou při nákupu prověření.',
'592240026':'Nedorazil mi žádný email, co s tím?',
'339130199':'Email po zaplacení je generován automaticky, pokud do vaší schránky nedorazil, kontaktujte nás na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>. Do zprávy prosím uveďte číslo kuponu, které naleznete ve výsledku prověření, případně vaše kontaktní údaje.',
'1681612939':'Zadal jsem špatný email, co teď?',
'-818807180':'Nic se neděje. Napište nám prosím správnou adresu na email <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a> a do zprávy uveďte číslo kuponu uvedené ve výsledku prověření. Přístupy vám následně zašleme na správný email.',
'-40057886':'Výsledek prověření lze reklamovat. Máte-li podezření, že jsou ve výsledku prověření uvedeny nesprávné údaje, obraťte se prosím na naše zákaznické oddělení na <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>. Více informací o reklamaci naleznete <a href="https://www.cebia.cz/kontakty#reklamace" target="_blank">zde</a>.',
'-755697752':'Lze vystavit fakturu?',
'1950759546':'Vzhledem k ceně prověření je automaticky generován pouze zjednodušený daňový doklad. Pro vystavení faktury nás kontaktujte na <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>. Pro urychlení do zprávy prosím uveďte i číslo kuponu uvedené v emailu, který vám přišel po nákupu.',
'-1960272511':'Zadejte VIN',
'-371538970':'Kde najdu VIN?',
'-1956291670':'Jak to funguje?',
'1850076653':'1. krok',
'-1260091754':'Dostupné údaje',
'1279291471':'2. krok',
'-811487544':'Platba',
'777550298':'3. krok',
'-1534039143':'Výsledek prověření',
'495126992':'4. krok',
'1916048998':'Prověřit',
'-580693003':'Kontakty',
'-760821457':'Potřebujete poradit?',
'1432374431':'Kontaktní formulář',
'1838884148':'Recepce Cebia',
'1665302400':'Sídlo společnosti',
'1196114687':'Česká republika',
'-95958777':'Fakturační údaje',
'1808758025':'Telefon',
'275235071':'Jsme k dispozici',
'353260003':'Po – Pá 8:00 – 16:30',
'822817400':'Prověřit historii vozidla',
'-1317542970':'Ověřeno zakazniky',
'-1884218466':'U každého vozu prověřujeme',
'1736624969':'Najeté kilometry',
'2137107000':'Historie poškození',
'647263705':'Odcizení vozidla',
'-441819024':'Servisní historie',
'982206269':'Záznamy inzerce',
'1753341741':'Využítí jako taxi',
'-366535509':'Profesionálové využívají služby Cebia',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'Znáte z...',
'654946563':'znaků',
'-1398891951':'Kontrola najetých kilometrů',
'1425691903':'33 % ojetin na českém trhu má stočený tachometr!',
'1803799674':'Kontrola stáří a původu',
'1389120536':'U ojetin ze zahraničí je stáří vozu a jeho původ často falšován!',
'-2068933994':'Kontrola odcizení',
'-1184837319':'Kontrola financování',
'-1877453535':'Kontrola poškození',
'64494271':'20 % ojetin ze zahraničí je po havárii!',
'1051433840':'Zkontrolujte, zda vůz nebyl inzerován jako havarovaný!',
'1076835371':'Ocenění vozidla',
'-1805177459':'Svolávací akce',
'957124168':'Počet majitelů v ČR',
'-1705145830':'Informace o vozidle',
'1890293527':'Kontrola taxi',
'-13439485':'Prosím zadejte položku Jméno',
'-1147931879':'Prosím zadejte položku Příjmení',
'2144560301':'Nesprávna hodnota Telefon',
'-1642977586':'Prosím zadejte položku Telefon',
'-1249914076':'Email nemá správný formát',
'-2038223065':'Prosím zadejte položku Zpráva',
'810453022':'E-mail',
'-1217737116':'Zpráva',
'-664538889':'Souhlasím se zpracováním',
'1322823680':'Odeslat zprávu',
'56891982':'Maximální počet znaků je 16.',
'277834303':'Děkujeme',
'552239414':'Vaše zpráva byla úspěšně odeslána.',
'1851370681':'Brzy se vám ozveme.',
'1952981238':'Varování',
'342579190':'Koupit prověření',
'1586626737':'Pokračovat',
'399846737':'Načítáme záznamy',
'556543048':'Lze prověřit následující informace:',
'560876430':'Fotky a inzerce',
'-320410471':'Není k dispozici',
'1185850210':'Nebyly nalezeny záznamy o poškození',
'-903608283':'Výpis z historie vozidla',
'718416430':'Informace o kuponu AUTOTRACER',
'668096075':'Číslo kuponu pro opakovaný vstup:',
'-1690204814':'Platnost do:',
'-840281270':'Převodovka',
'-1901926256':'Základní údaje o vozidle',
'-1550051512':'Typ karoserie',
'-1043441574':'VAROVÁNÍ:',
'-709435856':'Zkušenosti zákazníků',
'-1149927862':'Zjistěte, co o našem prověření říkají lidé',
'456577623':'Zjištěno bourané auto a přetočený tachometr. Děkuji.',
'329190632':'Splnilo očekávání, našlo poškození vozidla. Děkuji.',
'1450297818':'Kontrola odcizení v ČR a SR',
'-1594408882':'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
'341709606':'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Povinné ručení nebo havarijní pojištění musí být sjednané prostřednictvím portálu ePojisteni.cz do 30 dní od zaplacení prověření vozidla v systému AUTOTRACER',
'-355614707':'Nabídka se týká pojištění osobních, užitkových a nákladních automobilů (nevztahuje se na pojištění motocyklů, čtyřkolek, tříkolek nebo přívěsných vozíků a návěsů',
'-134420730':'Nevztahuje se na pojištění uzavřené prostřednictvím portálu ePojisteni.cz před zakoupením prověření vozidla v systému AUTOTRACER',
'1195365944':'Po uzavření pojištění vyplňte číslo účtu a číslo uzavřené pojistné smlouvy a částka Vám bude automaticky zaslaná na Vámi uvedený účet v žádosti po splnění podmínek nejdéle však do 60 kalendářních dnů od zaslání žádosti.',
'-1622287889':'Děkujeme, požadavek byl odeslán. Naši pracovníci Vás budou nejpozději do druhého pracovního dne kontaktovat.',
'1069770504':'Pokud je Vámi vybrané vozidlo individuálně dovezené do ČR, doporučujeme Vám využít ještě službu prověření původu vozidla v zahraničí PROVIN.',
'-121060108':'Prověříme registraci vozidla v zemi původu, zda bylo řádně odhlášeno, zda nebylo vozidlo vyřazeno z provozu z důvodu jeho totální havárie, zda vozidlo není evidováno v daném státě jako odcizené a další skutečnosti.',
'-1158490787':'Slevu je možné uplatnit do 30 dnů od nákupu prověření vozidla a nelze ji kombinovat s dalšími slevami.',
'-1471409910':'Chcete mít jistotu, že Vámi vybrané vozidlo není nelegálně předělané?  Doporučujeme Vám využít službu komplexního prověření původnosti identifikátorů vozidla VINTEST.',
'1555104762':'Služba VINTEST poskytuje klientům 100% garanci výsledku fyzického prověření vozidla. Na vozidle provedeme detailní fyzickou expertízu s využitím nejmodernější digitální diagnostiky. Cílem této služby je ověřit, jestli vozidlo má všechny identifikátory (VIN, štítky, svary karoserie) originální a není podezření, že vozidlo, nebo část vozidla pochází z trestné činnosti.',
'735500953':'Výsledkem dotazu je zjištění, zda vozidlo není předmětem financování (formou leasingu nebo úvěru), zástavy a zápůjčkou u uvedených společností. Data získáváme přímo z informačních systémů jednotlivých společností.',
'-1173444543':'Leasingové a úvěrové společnosti',
'-976513658':'*Carsharing = krátkodobý pronájem auta přímo od majitele (nebo sdílení auta mezi jeho majitelem a zájemcem o krátkodobou zápůjčku).',
'-1479754603':'V dostupných databázích bylo zjištěno, že vozidlo nebylo a v současnosti není provozováno jako vůz taxislužby.',
'-467405909':'Záznamy o provozu vozidla jako taxi',
'1930957191':'Sdělené údaje mají pouze informativní charakter. Cebia neodpovídá za škody vzniklé v souvislosti s využitím sdělených dat.',
'-1895346741':'Sdělíme vám obvyklou tržní cenu vozidla, pomocí které si ověříte, zda cena oceňovaného vozu odpovídá nabídkové ceně inzerce. Do výpočtu ceny je zahrnuto mimo jiné sledování inzerce ojetých vozidel v ČR i zahraničí včetně sledování cen skutečných realizovaných prodejů. Oceňovací algoritmus pracuje s výpočtovými modely postavenými na dlouhodobém monitoringu trhu, ale není schopen zohlednit fyzický stav vozidla.',
'-985858180':'V ojedinělých případech se může stát, že cenu vozidla nelze určit. Důvodem jsou nedostatečná data pro kvalifikované ocenění vozidla.',
'1915347014':'Zobrazíme vám inzeráty obdobných vozidel, která jsou aktuálně nabízena na trhu, a v některých případech také ceny skutečně prodaných obdobných vozidel (pomocí VIN porovnáváme přesnou specifikaci vozidel). Pomocí inzerce podobných vozidel si můžete porovnat, zda cena ověřovaného vozidla odpovídá podobným vozidlům na trhu. Ušetříme vám spoustu času stráveného vyhledáváním a porovnáváním podobných inzerátů na webu.',
'-767763886':'Nebyly nalezeny žádné záznamy o inzerci vozidla v ČR ani zahraničí.',
'797541609':'Zkontrolujte v jakém stavu a s jakým počtem najetých kilometrů byl vůz v minulosti inzerován. Pokud byl vůz inzerován s vyšším stavem tachometru, než má nyní, mohlo být s tachometrem nezákonně manipulováno.',
'-1902352540':'Odsouhlasení zadaných údajů. Po uložení nepůjdou změnit.',
'-2008975653':'Nebyly nalezeny záznamy o barvě vozidla evidované výrobcem vozidla či při první registraci vozidla v ČR.',
'7150984':'Zkontrolujte, zda se aktuální barva na vozidle shoduje s barvou deklarovanou výrobcem nebo s barvou evidovanou po první registraci v ČR (případně na Slovensku). V případě, že je barva rozdílná, může se jednat o vozidlo přebarvené, ale i havarované či odcizené.',
'-1664139465':'Seznam výbavy vozidla není k dispozici. Doporučujeme zkontrolovat funkčnost a originalitu výbavy přímo ve vozidle.',
'1681595153':'Výsledkem kontroly je informace, zda Vámi prověřované vozidlo je zabezpečeno formou značení skel a registrováno v Systému OCIS. Pokud ano, sdělíme Vám vyznačený kód a datum registrace.',
'571758489':'Na vozidle bylo v minulosti provedeno značení skel a je registrováno v mezinárodní databázi systému OCIS.',
'855264535':'Pokud na některých či na všech sklech vozidla unikátní kód chybí, může se jednat o vozidlo havarované, předělané či odcizené.',
'388233970':'Informace o provedeném značení skel:',
'1615022749':'Nepodařilo se nalézt technické parametry vozidla. Naleznete je však ve velkém technickém průkazu vozidla, popřípadě je můžete vyžádat u výrobce vozidla.',
'704641294':'Po zadání výběru vozidla Vám systém AUTOTRACER poskytne Návod na základní kontrolu identifikátorů. Návod platí obecně na zadanou značku a modelu vozidla. Pro ukázku bylo vybráno náhodně zvolené vozidlo.',
'-621659477':'Výběr vozidla může být již částečně předvyplněn, na základě zadaného VIN. Pokud předvyplněný výběr vozidla neodpovídá vozidlu, které prověřujete, upravte výběr ručně.',
'152333450':'Nebyly nalezeny žádné záznamy o poškození, co to znamená?',
'780709713':'Jak dlouho bude výsledek prověření dostupný?',
'830704335':'Zjistěte jaké informace jsou dostupné k vašemu vozu:',
'1850331238':'Super služba, vyplatí se ji pořídit. Zjistila jsem manipulaci s tachometrem i u předváděcího vozidla.',
'1705245752':'Chtěla jsem si koupit auto, o kterém mi tvrdili, že není havarované. Bylo havarované několikrát.',
'739619867':'Naprosto perfektní věc, nekoupili jsme za mnoho peněz bourané auto, díky!',
'-2071079400':'Vybral jsem si vůz, kde nebylo uvedeno, že je po dvou nehodách s vysokou škodou a zde sem to objevil ještě před koupí. Děkuji.',
'21202350':'Děkuji za tento program. Málem jsem přišla o 90 tisíc za auto. Auto prý jen lehce ťuknuté ze předu,podle majitele. Podle programu 2x bourané s velkou škodou.',
'1343185270':'Ověření mě uklidnilo a pomohlo mi se rozhodnout ke koupi.',
'2083029914':'Objevilo se rozsáhle poškození o kterém se mi prodejce nezmínil.',
'1933958141':'Zjištěná menší kolize + kontrola tachometru od data registrace.',
'1900495866':'Díky aplikaci jsem se dozvěděla, že s autem cosi bylo. Prodejce mi o tom samozřejmě nic neřekl a prodávající asi také mlčel. Děkuji.',
'-1668804606':'Při kontrole jsem zjistil závažné nedostatky, které mi prodávající zatajil, např. stav tachometru, bourané vozidlo v minulosti.',
'-273876487':'Zistil som že vozidlo ma stočené kilometre. Predajca uvadza 155.000 km a reálně má tak 250.000 km...',
'911857027':'Pro zobrazení zaplaceného výsledku prověření vložte číslo kuponu',
'1167593586':'Číslo kuponu získáte po koupi prověření v systému AUTOTRACER, tedy po zaplacení příslušného balíčku',
'-2136315768':'V případě, že úhradu provádíte formou platební karty, GooglePay nebo ApplePay, ihned se Vám zobrazí zakoupené informace a zároveň obdržíte číslo kuponu mailem',
'868918064':'V případě, že úhradu provádíte formou bankovního převodu, obdržíte číslo kuponu mailem ihned poté, co budou finanční prostředky připsány na bankovní účet Cebia',
'1572179326':'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
'-1322783148':'Umístění VIN se liší u každé značky a modelu vozidla. Vždy ho ale naleznete na malém i velkém technickém průkazu a často třeba pod čelním sklem.',
'700484420':'VIN je unikátní rodné číslo každého vozu. Naleznete ho v dokladech vozu nebo přímo na něm.',
'-851712281':'Dohledáme všechny záznamy dostupné k prověřovanému vozu a zobrazíme vám jejich rozsah. Vždy víte, co kupujete.',
'-1983041378':'Zaplatit můžete přes zabezpečenou platební bránu GoPay kartou nebo převodem.',
'-1809684191':'Výsledek prověření je k dispozici ihned po zaplacení. Samozřejmě vám jej pošleme také na email.',
'541894049':'Dozvíte se hodnoty stavu tachometru v daném období. V jednoduchém grafu se můžete přesvědčit, jak se stav tachometru vyvíjel v čase a odhalíte jeho případné stočení.  Pozor: více než 40 % vozidel ojetých vozidel má stočený tachometr!',
'643692561':'Zobrazíte si informace o evidovaných škodách na vozidle. V detailu mohou být připojeny informace o výši škody a rozsahu poškození. Pozor: nezapomeňte si zároveň prověřit technický stav vozidla a jeho předchozí poškození. Zda se například nejednalo o totální škodu.',
'878444309':'Pokud bylo vozidlo v minulosti inzerováno, zobrazíme vám inzerci a fotodokumentaci k vozidlu.',
'185243225':'Zjistíte detailní informace a přehled servisních úkonů, provedených v rámci oprav nebo běžného servisu vozidla.  Pozor: porovnejte si záznamy se servisní knihou a požadujte více informací o servisní historii vozidla.',
'1036319803':'Ověříme pro Vás skutečný rok výroby, což může mít podstatný dopad na současnou i budoucí cenu vozidla. Rok výroby se v technických průkazech již neuvádí. Zapisuje se pouze datum 1. registrace. To se ale od roku výroby může lišit až o 2 roky, ale není výjimkou ani 6 let. Přitom jeden rok omlazení může v ceně vozidla znamenat i několik desítek tisíc korun, které zaplatíte navíc.   <br />U většiny vozidel se také dozvíte, datum 1. registrace a pro jakou zemi bylo vozidlo původně vyrobeno.',
'-437053085':'Vozidlo prověříme v aktuálně dostupných databázích odcizených vozidel.<br />Jedná se převážně o evropské databáze ze státních i nestátních zdrojů. Seznam dostupných zemí a zdrojů najdete v placené části.',
'494784690':'Vozidlo prověříme v databázích finančních společností, autopůjčoven a zastaváren. V placené části lze zkontrolovat, zda vůz není zatížen financováním, zápůjčkou nebo zástavou.',
'2074346923':'Prověření v databázích vozidel provozovaných jako taxislužba. V placené části se dozvíte, zda vůz byl a nebo stále je provozován jako taxi.',
'-976680067':'Zobrazíme vám seznam svolávacích akcí, které by se mohly týkat prověřovaného vozidla.',
'1665109686':'Podle technického popisu si budete moct sami zkontrolovat důležité parametry, na jejichž základě bylo modelové provedení daného typu vozidla schváleno do provozu.   Porovnejte tyto parametry s údaji uvedenými v technickém průkazu vozidla.',
'-719963267':'Zobrazíme Vám výpis výbavy vozidla dle specifikace z jeho výroby. Můžete si tak porovnat současnou výbavu vozidla s tou, kterou udává výrobce. Pozor: pokud například výrobce uvádí v parametrech vozidla pravostranné řízení, kožené čalounění nebo střešní okno a Vaše vozidlo má řízení na levé straně, čalounění z látky nebo nemá střešní okno – pravděpodobně nejde o totéž vozidlo, ale může se jednat o vozidlo odcizené a předělané!  Při jakékoliv nesrovnalosti byste měli věnovat zvýšenou pozornost dalšímu fyzickému prověření vozidla a předejít tak špatnému nákupu vozidla, které může pocházet z trestné činnosti.',
'-1106584159':'Vaše vozidlo je zabezpečeno formou pískování nebo leptání skel, případně na vozidle mohlo být provedeno značení dílů karoserie. Sdělíme Vám vyznačený kód a datum registrace.',
'1830950672':'Zobrazíme vám barvu evidovanou výrobcem vozidla a barvu po registraci vozidla v ČR, případně po registraci na Slovensku (pokud máme tyto informace k dispozici).',
'1835889470':'Načítáme data...',
'1020539397':'Provozovatel systému AUTOTRACER - Cebia, spol. s r.o., upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter.',
'2054100346':'Na výsledek prověření vozidla v systému AUTOTRACER není poskytována záruka.',
'-167783152':'Tento výpis byl vytvořen dotazem do systému AUTOTRACER, který provozuje Cebia, spol. s r.o. dle platných Všeobecných podmínek pro používání systému AUTOTRACER. Poskytnuté informace je uživatel oprávněn v celém rozsahu využívat pouze pro vlastní potřebu. Jejich další šíření či upravování bez souhlasu provozovatele je zakázáno.',
'-1697979879':'Ředite společnosti Cebia, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Ředitel společnosti Cebia',
'759051798':'Společnost Cebia',
'-31539157':'Jsme česká společnost založená v roce 1991 v Praze. Naše služby z oblasti prověřování, zabezpečení a identifikace vozidel využívají zákazníci v 9 zemích Evropy. Našimi zákazníky jsou běžní motoristé, pojišťovny, prodejci vozidel, státní instituce, policejní složky i soudní znalci. Za svou historii jsme odhalili desetitisíce podvodů s ojetými vozidly a pomohli s nákupem vozu miliónům zákazníků po celé Evropě.',
'-1821588851':'Reklamace a stížnosti',
'-276118588':'Reklamace a stížnosti jsou pro nás cenným zdrojem podnětů pro zlepšení našich služeb. Kontaktujte nás prosím pomocí kontaktního formuláře nebo na: <a href="mailto:autotracer@cebia.com">autotracer@cebia.com</a>',
'-1446517877':'Reklamaci vyřídíme nejpozději do 14 dní od jejího obdržení a o jejím výsledku vás budeme informovat.',
'708234375':'Ozvěte se nám prostřednictvím kontaktního formuláře, nebo na e-mail <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>',
'1474175101':'Všeobecné podmínky služby',
'1343500875':'Zásady ochrany osobních údajů',
'-2083968164':'Vývoj vlastního nástroje na oceňování vozidel',
'907588970':'Založení společnosti v Praze',
'-770165824':'<strong>30 let zkušeností</strong> s prověřováním aut',
'-1138482741':'<strong>1 000 000+</strong> prověřených aut každý rok',
'154007106':'<strong>750 000+</strong> spokojených zákazníků',
'177137909':'Databáze Cebia obsahuje více než 1 miliardu záznamů o ojetých vozidlech pocházejících z více než 20 zemi. Jedná se především o záznamy najetých kilometrů, poškození vozidel, fotografie vozidel a servisní záznamy.',
'723486619':'Načítám data',
'742852638':'Období:',
'787763184':'období:',
'-685036969':'Vysvětlivky:',
'290784658':'Není k dispozici:',
'412892373':'K vozidlu jsme v dané kategorii nedohledali žádné informace',
'1853282090':'K dispozici:',
'-249038921':'XY záznamů:',
'805146013':'K vozidlu jsme v dané kategorii dohledali XY záznamů, které se dozvíte v placené části',
'242118549':'Všeobecné podmínky',
'521725542':'Zobrazení všeobecných podmínek pro používání systému AUTOTRACER',
'-571686819':'zde',
'639498968':'Platba za zobrazení detailních informací v části Výpis z historie vozidla nebo části OSVĚDČENÍ Cebia REPORT nebo postupně všech částí se Vám započítá podle podmínek uvedených ve smlouvě s Cebia.',
'-1705426324':'Detailní informace k 1 VIN si můžete zobrazit po dobu 30 dnů od prvního zobrazení tohoto VIN a platíte pouze jednou.',
'529403448':'Důležité upozornění',
'-821000576':'Cebia, spol. s r.o. upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter. V případě, kdy stav vozidla je ve významném rozporu s informacemi zde uváděnými, doporučujeme kontaktovat patřičného znalce za účelem ověření skutečného stavu vozidla.',
'-1138137280':'Informace o zpracování osobních údajů',
'125063053':'Zobrazení informace o zpracování osobních údajů uživatelů CebiaNET / AUTOTRACER',
'-608864551':'K vozidlu jsme v dané kategorii dohledali informace, které se dozvíte v placené části',
'-1879995849':'Zadané vstupní údaje',
'754897480':'ROK PRVNÍ REGISTRACE',
'-1834106204':'AKTUÁLNÍ STAV TACHOMETRU',
'404797827':'ZOBRAZIT CEBIA REPORT',
'1358780725':'VYGENEROVAT REPORT',
'-952450705':'Zkontroloval jsem a potvrzuji uvedené informace',
'-1601808583':'<span className="green">Hotovo!</span> U zadaného vozidla lze prověřit následující informace: ',
'-1763095911':'Lze vystavit Cebia REPORT hodnotící následující kategorie:',
'761362384':'Ukázka REPORTU',
'-397155268':'ZOBRAZIT VÝPIS',
'1375027269':'PROVĚŘIT VOZIDLO',
'2055286638':'více než',
'-1668243810':'Jsme silnější',
'1141836815':'Stáváme se součástí českého holdingu EAG. Díky tomu se naše databáze výrazně rozšiřují o záznamy z celé Evropy – především Německa, Rakouska či Dánska.',
'-903271711':'Uvádíme na trh online nástroj na určení ceny ojetých vozidel. Díky naší rozsáhlé databázi záznamů o ojetých vozech je velmi přesný a rychle si nachází cestu do největších pojišťoven a leasingových společností.',
'-1262829232':'Měníme trh s ojetými vozidly',
'1367974718':'S rostoucím zájmem o výběr vozu online začínáme spolupracovat s největšími inzertními portály ve střední Evropě. Výsledky našeho prověření historie se stávají puncem kvality ojetého vozu.',
'-300528762':'Vstup do online světa',
'-899322976':'Naše služby jsou od teď snadno dostupné každému z pohodlí domova. Podvody s ojetými vozy jsou na svém vrcholu a my proti nim bojujeme ze všech sil.',
'-4875574':'Rozšiřujeme služby',
'1166185728':'K našim službám přidáváme fyzickou kontrolu identifikátorů (VIN) vozidel a naše databáze se skokově rozšiřuje. Spolupracujeme s pojišťovnami, státními orgány, policií.',
'-1619922514':'Vstup do zahraničí',
'-844900279':'Nejen v České republice se rozmáhá obchod s odcizenými vozidly. Zakládáme proto mezinárodní databázi odcizených vozidel, která nám pomáhá odhalovat pravý původ ojetých vozů.',
'598423318':'Tři univerzitní spolužáci zakládají společnost Cebia. Původní záměr podnikat v IT brzy vystřídal zájem o zabezpečování vozidel.',
'-1828699417':'Exekuce/Insolvence',
'4000011':'Prověření dokladů zdarma',
'1479506336':'Historie vyhledávání',
'-1478881101':'Historie prověření vozidel',
'1115668272':'Historie Exekuce/Insolvence',
'691760517':'Historie prověření dokladů',
'-614781938':'Rozsah a cena prověření se může u každého vozidla lišit podle množství dostupných informací.',
'702191442':'Zpět',
'-1034048918':'Kontrola VIN',
'-1214567423':'Referenční číslo',
'427849990':'Vytvořit CERTIFIKÁT kontroly vozu',
'-1718377395':'Chyba načtení kuponu.',
'877365836':'Nepovedlo se vygenerovat platební url.',
'-1082791212':'Nespravna hodnota kuponu',
'-255296664':'Kód kuponu umožňuje opakovaný vstup na výpis placených informací a to po dobu 30 dní od aktivace kuponu.',
'456125545':'Provozovatel systému AUTOTRACER – Cebia, spol. s.r.o. upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter.',
'-1172808350':'Kontaktujte prodejce, aby Vám sdělil VIN vozidla a následně si vozidlo prověřte.',
'-2079080642':'Kontaktujte prodejce, aby Vám sdělil 17místný VIN a následně si vozidlo prověřte.',
'-585052469':'Pořizujete si ojetý vůz? Zjistěte si o něm vše! Zejména stav tachometru, rok výroby nebo záznamy o poškození podle VIN kódu.',
'76811920':'UKÁZKA výsledku prověření náhodného vozidla.',
'1050846059':'I přesto vám doporučujeme pečlivou prohlídku vozidla, ideálně s pomocí kvalifikovaného mechanika nebo ve vámi vybraném servisu. Taková prohlídka může předchozí poškození jednoznačně odhalit nebo vyloučit.',
'-1068264553':'Vždy zkontrolujte datum výroby deklarované výrobcem vozidla. V technických průkazech se uvádí pouze datum první registrace, které se však od data výroby může výrazně lišit (například i o dva roky). Vozidlo tak může být vydáváno za mladší, což má výrazný vliv na jeho tržní cenu.',
'756232271':'Nebyly nalezeny žádné záznamy o stáří či původu vozidla. Pochází-li vozidlo ze zahraničí, vždy si vyžádejte a pečlivě zkontrolujte původní zahraniční technický průkaz.Pokud chcete mít jistotu, že vůz pochází z deklarované země a není odcizený, můžete využít službu prověření původu v zahraničí <a class="Link Link--lightBlue" href="https://www.cebia.cz/sluzby/provin" target="_blank">PROVIN</a>.',
'-643660678':'Byly nalezeny záznamy o servisních úkonech, které byly v minulosti na vozidle provedeny v rámci jeho oprav či běžného servisu.',
'1533967406':'Cena prověření vozidla se odvíjí od množství a rozsahu informací dostupných k prověřovanému vozidlu. Rozsah informací (a tedy i cenu) se dozvíte ihned po zadání VIN vozidla. Minimální cena prověření je 199 Kč.',
'-1289463237':'Výsledek je obvykle vygenerován pár minut po přijetí platby. Služba funguje nepřetržitě a celý proces je automatický. Záleží tedy na zvolené platební metodě. Při platbě kartou je výsledek dostupný téměř okamžitě, při platbě bankovním převodem až po přijetí platby.',
'1304585346':'Výsledek prověření, respektive výpis ze systému AUTOTRACER k vybranému vozidlu, je vygenerován automaticky ihned po přijetí platby. Odkaz na něj naleznete v emailu, který vám přijde po zaplacení. V emailu obdržíte rovněž číslo kuponu pro opakované zobrazení výpisu. Výsledek je možné zobrazovat po dobu 30 dní.',
'-661494819':'Chyba načtení dat.',
'-1681989289':'již jste uskutečnil/a nákup kuponu AUTOTRACER, čímž získáváte nárok na slevu ve výši 100 Kč na tento nákup. Slevu uplatníte zadáním čísla kuponu AUTOTRACER, který jste obdržel/a mailem, do pole „Zadejte číslo kuponu“. Pozor: časové omezení možnosti využití této slevy je 90 dnů od nákupu prověření v systému AUTOTRACER.',
'681093117':'získal/a jste slevový kód při prodloužení služby FLEX na další rok. Slevu uplatníte zadáním čísla slevového kódu, který jste obdržel/a na daňovém dokladu k platbě služby FLEX, do pole „Zadejte číslo kuponu“.',
'-1551909822':'vlastníte slevový poukaz AUTOTRACER, který Vás opravňuje k nákupu kuponu zdarma. Uplatníte zadáním čísla kuponu AUTOTRACER do pole „Zadejte číslo kuponu“.',
'-103162544':'Na uvedenou e-mailovou adresu Vám po provedení úhrady budou zaslány informace potřebné pro zobrazení zaplacených informací a zjednodušený daňový doklad k platbě.',
'-591911074':'Na uvedený telefón Vám po provedení úhrady budou zaslány informace potřebné pro zobrazení zaplacených informací a zjednodušený daňový doklad k platbě.',
'706315962':'Společně s informacemi potřebnými pro zobrazení zaplacených informací obdržíte vždy i zjednodušený (neadresný) daňový doklad. Pokud chcete mít na daňovém dokladu vyplněny Vaše fakturační údaje (firmu, adresu, IČ, DIČ), zadejte je prosím níže.',
'500915765':'Při objednávce budete přesměrováni na platební bránu GoPay, kde provedete bezpečnou platbu platební kartou. V případě, že Vaše platba proběhne úspěšně, budete automaticky přesměrováni zpět do systému AUTOTRACER k zobrazení detailních informací k vozidlu. Zároveň systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER a účetní doklad.',
'-676823504':'Pokud zvolíte tento způsob platby, budete přesměrováni na platební bránu GoPay, kde si vyberete Váš bankovní ústav, ze kterého budete chtít platbu provést.',
'-764670786':'V případě, že Vaše banka nabízí tzv. on-line převod, tak Vás přesměrujeme do Vašeho internetového bankovnictví, kde budete mít předvyplněný platební příkaz, provedete úhradu služby.',
'2062466434':'Pokud obdržíme platbu od banky ihned, tak Vás automaticky přesměrujeme do placené části systému AUTOTRACER. V opačném případě prosím vyčkejte na obdržení emailu.',
'734607756':'Po připsání platby na náš účet systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER (= vstup na placené informace) a účetní doklad.',
'1715999355':'Google Pay je rychlý a snadný způsob placení kdekoliv na Googlu, bez zadávání platebních údajů. Můžete rychle zaplatit pomocí karty uložené ve službě Google Pay. Abyste tuto službu placení  mohli využívat, musí ji také podporovat Vaše banka.<p></p>V případě, že Vaše platba proběhne úspěšně, budete automaticky přesměrováni zpět do systému AUTOTRACER k zobrazení detailních informací o vozidle. Zároveň systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER pro následné zobrazení informací o vozidle a daňový doklad.',
'790632208':'Apple Pay je platební metoda, která umožňuje snadné platby pomocí karty uložené v Apple Wallet. Abyste tuto službu placení  mohli využívat, musí ji také podporovat Vaše banka.</p><p>V případě, že Vaše platba proběhne úspěšně, budete automaticky přesměrováni zpět do systému AUTOTRACER k zobrazení detailních informací o vozidle. Zároveň systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER pro následné zobrazení informací o vozidle a daňový doklad.',
'-1402859324':'V případě, že Vaše banka nabízí pouze tzv. off-line převod, vygenerujeme Vám pokyny k bankovnímu převodu, Vy následně u Vaší banky provedete převod peněžních prostředků a jakmile bude částka připsána na náš bankovní účet, zašleme Vám email s přístupem do placené části systému AUTOTRACER a doklad o provedené platbě.',
'347099860':'Tato operace může trvat až 2 pracovní dny (obvykle však 24 hodin).',
'-43783036':'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
'-802148009':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla.',
'1985201267':'Největší databáze údajů o autech v Evropě',
'-1590601891':'<span class=\'warning\'>Upozornění:</span> Systém AUTOTRACER nemusí mít k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné.',
'-1465948017':'<strong>Systém OCIS –</strong> značení skel vozidla. Jednoduché, efektivní a cenově výhodné zabezpečení vozidla. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html\' target=\'_blank\'>Více zde.</a>',
'1553997983':'<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html\' target=\'_blank\'>Více zde.</a>',
'-124714580':'Online platbou pomocí platební karty, v internetovém bankovnictví, bankovním převodem či prostřednictvím Google Pay, nebo Apple Pay. Zaplatit lze i v hotovosti <a href=\'https://www.cebia.cz/kontakty\' target=\'_blank\'> v sídle Cebia</a>',
'-753863533':'CERTIFIKÁT KONTROLY VOZU - přehled dostupných informací',
'1441255407':'Kategorie',
'1549893453':'Možnost kontroly',
'1547427535':'ANO',
'508023484':'Kontrola data registrace',
'2076692599':'Fyzická prověrka',
'-1521980547':'Kontrola stavu tachometru',
'-1009985044':'ANO - na základě',
'1747355819':'údajů',
'1639206028':'Kontrola původu vozu',
'-1524110425':'Kontrola platby EKO daně',
'-1961089879':'Kontrola DPH',
'1732127987':'Kontrola vážné havárie',
'-198755848':'Kontrola registrace',
'-1790774232':'Kontrola poškození povodní',
'-1781437228':'Kontrola typu motoru',
'-1344389293':'Kontrola identifikátorů vozu',
'-499181915':'Foto',
'-687939409':'Informace byly převzaty z evidencí služeb dokumentování stavů vozidel pořízených ve společnosti Cebia nebo v síti jejích smluvních partnerů.',
'-43022982':'Datum provedení služby:',
'-136414993':'Stav vozidla:',
'2023782231':'Na vozidle byla provedena fyzická kontrola jeho identifikátorů (ražba VIN, výrobní štítky apod.). Informace byly převzaty z evidence služby VINTEST prováděné společností Cebia nebo jejími smluvními partnery.',
'758499555':'Stav identifikátorů:',
'488100027':'ZPĚT',
'-1281249872':'POKRAČOVAT',
'-141562572':'VYGENEROVAT',
'-40297285':'Můžete tak získat přehled o vývoji ceny a údajů ověřovaného vozidla.',
'-1742933347':'Číslo inzerce',
'-2099386182':'Prodej',
'1560408371':'Prodejní cena',
'-280814568':'ZOBRAZIT CERTIFIKÁT KONTROLY VOZU',
'2075274708':'Datum pořízení',
'-1248991647':'Ukrajina',
'-1779298552':'Švédsko',
'-18328562':'Lotyšsko',
'1764744897':'Litva',
'-1273046880':'Rumunsko',
'1252567719':'Bulharsko',
'445626359':'Řecko',
'-868498706':'Slovinsko',
'-1528448787':'Maďarsko',
'-387469480':'Slovensko',
'25300212':'Rakousko',
'1771002523':'Itálie',
'69077775':'Dánsko',
'647944028':'Polsko',
'1160986873':'Německo',
'897084544':'Francie',
'2056598840':'Španělsko',
'264100206':'Rusko',
'-1609348689':'Zásady zpracování Cookies',
'-365994139':'Načíst další',
'807848789':'Miliony spokojených zákazníků',
'1677683955':'Cebia má více než <strong>2 000 partnerů</strong> z celé Evropy, kteří poskytují záznamy o vozidlech do systému AUTOTRACER.',
'652553309':'Víte, že...',
'1453750886':'Hotovo!',
'1498262202':'k dispozici',
'1514779988':'Kontrola vozu technikem',
'1058658544':'Slevové kupony Cebia',
'-2035347536':'0 záznamů',
'1050102897':'Podrobná specifikace výbav vozidla poskytnutá jeho výrobcem obsahuje všechny prvky základní i příplatkové výbavy. Kontrolou výbavy lze odhalit úpravy vozy jako např. změnu strany řízení.',
'2145745364':'Výpis záznamů tachometru',
'-1636264801':'Popis',
'-1488190529':'Číslo záznamu',
'64594583':'Financované vozidlo',
'-1441353378':'Bez financování',
'-2101084179':'leden',
'-1066567335':'únor',
'-1761670105':'březen',
'104663035':'duben',
'1397259452':'květen',
'-642974117':'červen',
'613657227':'červenec',
'-2044487450':'srpen',
'1921417285':'září',
'-1672709282':'říjen',
'-678062097':'listopad',
'1302554433':'prosinec',
'1268839568':'V databázích servisních úkonů nebyly nalezeny žádné záznamy. Vyžádejte si k vozidlu výpis ze servisní knihy (nejlépe z elektronické servisní knihy). Pravost servisních úkonu lze zkontrolovat přímo v autoservisu, kde byly dle servisní knihy provedeny.',
'-112660439':'Zde si vytvoříte Osvědčení <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, které můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále – <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Záznam nalezen',
'-1350474522':'V dostupných databázích nejsou k dispozici záznamy tachometru prověřovaného vozidla.',
'1040913417':'Doporučujeme vám prověřit další možné zdroje záznamů tachometru, např. servisní knížku nebo dotazem na servisní historii v autorizovaném servisu. Při prohlídce vozidla pak věnujte pozornost opotřebení zejména těch částí, které mohou napovědět počet skutečně najetých kilometrů – více naleznete v <a href="https://www.cebia.cz/pruvodce/jak-zkontrolovat-stav-ojeteho-auta" data-link-enabled="true" data-link-href="https://www.cebia.cz/pruvodce/jak-zkontrolovat-stav-ojeteho-auta" target="_blank" class="Link Link--lightBlue">našem průvodci.</a>',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'Email',
'-982011601':'Spojené státy',
'786789662':'Nákup zvýhodněných balíčků',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Chcete nakoupit na firmu nebo prověřit více vozidel? Využijte naše',
'845754511':'zvýhodněné balíčky!',
'-614796774':'Česko',
'1011350398':'Po-Pá: 8:00 - 16:30',
'-68730396':'Ke dni:',
'544810671':'NE - není evidováno',
'-1670731287':'V ČR registrováno jako:',
'1597705322':'Standardní vybavení',
'16575694':'Vozidlo:',
'-846486931':'záznamů',
'-1615816320':'záznam',
'1415114012':'záznamy',
'1802294807':'fotografií',
'1122888753':'fotografie',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pravni-informace/osobni-udaje">osobních údajů</a>',
'1802705032':'<a className="Link Link--lightBlue" href="https://www.cebia.cz/pruvodce/kde-najdu-vin-kod" target="_blank" rel="noreferrer noopener">Více informací</a>',
'883512613':'Kontrola VIN – Online prověření vozidla',
'-1965309318':'Zkontrolujte historii vozu online v největší databázi záznamů ojetých vozidlech v Evropě. Kontrola poškození, historie KM, servisní historie, fotografie a další.',
'-1871315210':'Výběr platební metody ',
'-1803227447':'Vyberte prosím vhodnou platební metodu.',
'-1249181167':'Blog metatitle',
'-838385662':'Blog metadescription',
'1154868985':'Časté dotazy - Online prověření vozidla',
'908158246':'Podívejte se na nejčastější dotazy zákazníků k online prověřování vozidel Cebia. Máte-li jinou otázku, rádi vám ji zodpovíme.',
'-633966577':'About metatitle',
'1171158673':'About metadescription',
'-1244282008':'Politika cookies',
'-792206035':'Tento web využívá různé soubory cookies. Některé cookies jsou umisťovány službami třetích stran. Váš souhlas s jejich používáním můžete kdykoliv odvolat.',
'1489309005':'Vstup zdarma v rámci limitu dle smlouvy',
'-470675791':'Zkontroloval jsem a potvrzuji uvedené informace.',
'2108896817':'ZRUŠIT ZADANOU SLEVU',
'6892736':'Sleva byla zrušena',
'-1199844274':'Kód není platný!',
'-552424253':'Za vstup do placené části je účtován poplatek dle smlouvy',
'167769730':'Platba hotově',
'1768026018':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
'820786512':'Kód byl přijat, cena byla přepočítána',
'-279227794':'Kontrola roku výroby',
'1470281039':'Kontrola leasingu/úvěru v ČR',
'60776637':'Číslo kuponu',
'529459564':'Prosím zadejte položku Číslo kuponu.',
'-861194224':'Prohlídka vozidla',
'2076412240':'nové',
'360601996':'Vypršela platnost vašeho kuponu.',
'1762078183':'Materiál',
'822990672':'Stisknutím tohoto tlačítka čerpáte 1x dotaz z limitu pro dotazy zdarma dle platné smlouvy o APC.',
'532528312':'ojeté',
'-319106355':'Vozidlo',
'935795728':'Ukázka prověření vozidla {-}',
'-916000765':'Car History Report',
'-1721948126':'Ukázka prověření historie vozidla. Zkontrolujte historii vozu online v největší databázi záznamů ojetých vozidlech v Evropě. ',
'329882651':'Stisknutím tohoto tlačítka Vám bude naúčtována cena za zobrazení detailních informací dle platné smlouvy o APC.',
'-494157101':'Doklad',
'-465994559':'Fotografie',
'857656094':'Tovární značka:',
'-2005319583':'Model vozidla:',
'339937023':'Rok první registrace dle tech. průkazu:',
'-441771827':'Načítáme data',
'-161761801':'neověřené období',
'1229398238':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Vozidlo je evidované jako <strong className="bolder">odcizené</strong>.',
'358386818':'Stáří vozidla:',
'-701699977':'Cebia REPORT nelze vytvořit - VIN nebylo možné jednoznačně identifikovat. </br> Zkontrolujte VIN podle dokladů od vozidla, případně <a href="mailto:autotracer@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN:{0}">Kontaktujte podporu</a>.',
'-1359814132':'Další obrázek',
'340541220':'Přiblížit',
'2096545526':'Oddálit',
'-158382161':'Předchozí obrázek',
'-749344895':'Získáváte slevu',
'-1755324145':'Model vozidla',
'1747292041':'Smart kód',
'1093009871':'Chytrá komunikace pro moderní autocentra',
'-421925379':'Chcete u svých vozidel propagovat, že jsou prověřená?',
'-303554559':'Dejte na každé vozidlo Smart kód a zákazník si sám načte výsledek jeho prověření.',
'5128661':'Úplně jednoduše. Cebia Smart kód vytisknete v systému AUTOTRACER (ve formátu A5) a umístíte za sklem prodávaného vozidla.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Ostatní informace (kompletní výpis ze systému AUTOTRACER) si pak může vyžádat přímo od Vás.',
'-566386963':'Vyplňte prosím tento stručný formulář a my se vám ozveme.',
'-1639125181':'Odeslat',
'76421921':'Povinné položky',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Máte zájem tisknout u vašich vozidel Smart kódy? Kontaktujte naše obchodní oddělení:',
'2094660384':'Petr Kala, vedoucí obchodního oddělení',
'361077673':'Nebo vyplňte formulář a my se Vám ozveme:',
'1277961418':'Opravdu chcete vygenerovat Smart kód?',
'-2133968142':'Mám slevový kupón',
'-1922950659':'Platební metoda',
'-2072875261':'Prověřované vozidlo',
'783887791':'Objednávka',
'-1499237034':'zdarma',
'-1854208328':'Uplatnit slevový kupon',
'-793343279':'dárek',
'-1393236672':'Kosovo',
'1342791550':'Historie najetých kilometrů',
'2063498881':'Volitelná výbava',
'-152116623':'QR – Smart kód',
'-956949153':'Výše škody',
'-552950132':'údaj',
'30639535':'údaje',
'-1164317619':'Ne',
'1402944789':'Zadejte prosím povinné údaje',
'-1812102598':'VAROVÁNÍ:Nelze provést <strong>kontrolu stavu tachometru</strong>. Kategorie bude mít výsledek - nedostatek informací.',
'666398003':'Varování!',
'771644825':'Zadané VIN může být chybné!',
'-1131044763':'Více informací?',
'-2054470344':'Zadané VIN vozidla může být chybné!',
'-1369269346':'V zadaném čísle VIN byla detekována možná chyba. Zkontrolujte prosím, zda se zadané VIN schoduje s VIN číslem vyraženém na vozidle nebo uvedeném technické průkazu.',
'-1625517200':'Kde hledat VIN?',
'1171142400':'Platba neproběhla. Platbu prosím opakujte.',
'-1192484737':'Bez souhlasu s Všeobecnými podmínkami nelze pokračovat',
'-247455599':'Prosím zadejte IČ společnosti.',
'-1944712721':'Minimální počet znaků je 12.',
'1664270996':'Stát',
'-862797995':'Stav vozidla evidovaný při jeho prodeji. Případné poškození si prohlédněte na přiložené fotodokumentaci.',
'-1997376096':'Slovenská republika',
'-1344951568':'Registr vozidel',
'-644036535':'Databáze',
'-426412387':'Státní registr vozidel',
'1736003883':'Údaje z technického průkazu',
'1610817679':'K vozidlu jsou dostupné záznamy tachometru. V placené části naleznete stavy tachometru za období, které uvádíme u této kategorie. Pomocí záznamů lze určit, zda najeté kilometry odpovídají skutečnosti nebo bylo s tachometrem vozu manipulováno.',
'1669009738':'Prověřované vozidlo bylo v minulosti inzerováno či fotografováno. V placené části si lze prohlédnout detaily inzerátů, stav tachometru, fotografie vozu a případně i inzerovanou cenu (rozsah informací se může u jednotlivých záznamů lišit). Záznamy mohou pocházet z ČR i zahraničí.',
'1518701163':'Máme k dispozici záznamy o stáří nebo původu vozu. V placené části lze zkontrolovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Rozsah informací v placené části se může u jednotlivých vozů lišit.',
'1816609203':'Rok výroby je často falšován, aby se vůz jevil jako mladší a tedy i dražší!',
'-900812009':'Odcizený vůz může být kupujícímu zabaven policií bez jakékoliv náhrady!',
'-976941031':'U tohoto VIN není kontrola v databázích vozidel provozovaných jako taxi k dispozici.',
'444450829':'až',
'982955181':'12 registrů',
'-1739651968':'Kontrola zabezpečení',
'-167461646':'Prověření historie vozidla',
'-1365221853':'Maximální počet znaků je 14.',
'1302766369':'Ocenění vozidla nebylo provedeno. Ocenění lze provést po dobu platnosti kuponu zadáním údajů o vozidle (datum první registrace a aktuálního stav tachometru) na našich webových stránkách po vložení čísla kuponu.',
'1881868699':'Nakupuji na firmu',
'631216697':'Prosím zadejte Váš e-mail.',
'945011940':'Vaše údaje',
'-401861769':'Kontaktní e-mail',
'1441058305':'Pokud vyplníte DIČ, pokusíme se zbylé údaje doplnit z oficiální databáze.',
'-1181757195':'Na uvedenou e-mailovou adresu Vám po platbě zašleme odkaz na zobrazení informací o historii Vašeho vozu a zjednodušený daňový doklad.',
'-1768425519':'Pokud chcete mít na zjednodušeném daňovém dokladu vyplněny Vaše fakturační údaje (firmu, adresu, IČ, DIČ), zadejte je prosím do polí níže. Pokud vyplníte DIČ, pokusíme se zbylé údaje doplnit z oficiální databáze.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Cebia REPORT poskytuje hodnocení kontroly stavu tachometru, kontroly v databázi odcizených vozidel, závazků u leasingových společností, kontroly správnosti VIN a roku výroby vozidla. Celkové hodnocení je vyjádřeno počtem hvězdiček (0–5) a slovním hodnocením.',
'308350422':'Celkem s DPH:',
'-24797068':'Slevu můžete uplatnit pokud:',
'-1323263000':'Máte u nás předplacenou službu FLEX a použijete kód uvedený na daňovém dokladu.',
'-724069837':'Vlastníte slevový poukaz, který Vás opravňuje k nákupu kuponu zdarma.',
'-127346860':'Máte poukaz od některého z našich partnerů.',
'-47884841':'bez DPH',
'881014685':'Celkem bez DPH',
'-15294371':'Nepotvrzené DIČ',
'1286068311':'Oficiální databáze plátců DPH nepotvrdila Vaše DIČ. Nemůžeme proto automaticky doplnit Vaše údaje. Zkontrolujte prosím zadané DIČ nebo vyplňte údaje o Vaší firmě ručně.',
'-172351568':'Databáze neodpovídá',
'1839952435':'Oficiální databáze plátců DPH nereaguje. Vyplňte prosím údaje o Vaší firmě ručně.',
'1678298800':'Ukázka prověření vozidla',
'-654354054':'Množství dostupných informací je u každého vozidla rozdílné',
'-1069736045':'evropské <label className="color-orange">inzertní portály</label>',
'1068440731':'až <label className="color-orange">12 registrů</label>',
'-534710218':'Původ a stáří vozidla',
'1267127794':'Pro zadané vozidlo lze na základě VIN kódu provést všechny kontroly uvedené na této stránce. Výsledky kontrol budou dostupné v placeném Reportu. Počet informací obsažený v placeném Reportu se může u každého vozidla lišit.',
'-186613120':'Nebylo provozováno jako taxi v ČR',
'1880784559':'Dostupné po zadání SPZ vozidla',
'2085931506':'<label className="color-orange">k dispozici</label>',
'-1765351165':'více než <label className="color-orange">200 milionů záznamů</label>',
'1815202337':'data z <label className="color-orange">EU a USA</label>',
'-555727222':'data z <label className="color-orange">18 zemí</label>',
'-1798142062':'Graf záznamů stavů tachometru',
'-1828887347':'VIN (identifikační číslo vozidla):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Kontrolu taxi na Slovensku lze provést zadáním SPZ vozidla na <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Prověřit VIN kód nyní',
'-1620583517':'Historie',
'239060530':'Historie prověřování VIN',
'1122350112':'Přehled historie všech dotazů na prověřované VIN ke dni aktivace kuponu.',
'-1288575114':'Nebylo prověřováno',
'-14589513':'Zákazník',
'-1170545783':'Prověřeno Vámi',
'452068758':'Smluvní partner Cebia',
'-1710315139':'Výsledek prověření obsahuje nalezené záznamy z historie vozidla. Nejčastěji to jsou záznamy tachometru, údaje o poškození vozidla, záznamy z technických kontrol, fotografie vozidla či záznamy o inzerci vozidla. Více se dozvíte v <a href="https://cz.cebia.com/payed/detail/example" target="_blank">Ukázce prověření</a>.',
'1607430837':'Přehled historie',
'-293598683':'Datum odhlášení',
'-1790772829':'Datum 1. registrace vozidla',
'743843679':'Datum přihlášení vozidla',
'1370884754':'Přihlášeno jako',
'644449918':'Barva vozu při přihlášení',
'-583571734':'Datum výroby vozidla:',
'-1219924366':'Stáří vozidla',
'1595420674':'Vyrobeno s výbavou',
'1151043140':'Doba od výroby po 1. registraci',
'708774908':'Albánie',
'1637995084':'Severní Makedonie',
'1001888760':'Ekonomická totální škoda *',
'1034299871':'Pojistná událost – ekonomická totální škoda*',
'1209738229':'Pojistná událost',
'913822268':'Poznámka',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praha 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'Dokončením objednávky souhlasím s <a href="https://www.cebia.cz/files/vseob_podm_atr.pdf" target="_blank" class="Link Link--lightBlue">obchodními podmínkami</a> a beru na vědomí <a href="https://www.cebia.cz/o-spolecnosti/ochrana-osobnich-udaju.html" target="_blank" class="Link Link--lightBlue">zásady zpracování osobních údajů</a>.',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Datum 1. registrace uvedené při přihlášení',
'280331577':'Doba od výroby po 1. přihlášení',
'1163480388':'V dostupných databázích nejsou k dispozici žádné záznamy.',
'-1319845523':'U vozidla evidujeme kalkulaci poškození vytvořenou pojišťovnou nebo autoservisem, u které neznáme detail (místo poškození). Věnujte prosím pozornost prohlídce vozidla.',
'779148402':'U vozidla evidujeme událost nahlášenou pojišťovnou, u které neznáme detail (místo poškození). Věnujte prosím pozornost prohlídce vozidla.',
'1115915387':'Doprava zdarma',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Neověřeno',
'-1464599260':'Práce',
'-592361226':'Lakování',
'1085310093':'Typ kontroly:',
'1984850101':'Datum kontroly',
'-1502399543':'Zobrazit protokol',
'-1961590845':'Databáze Ministerstva dopravy je momentálně nedostupná, zkuste to prosím později.',
'-1065211891':'Protokol není dostupný',
'1708745339':'K této prohlídce není detail protokolu dostupný.',
'1087254174':'Získat kompletní prověření',
'2096754301':'Základní prověření vozidla ke dni:',
'926095479':'Co znamená základní prověření vozidla?',
'-254140743':'Základní prověření obsahuje tyto kontroly:',
'1362304971':'Pro prověření celé historie vozidla doporučujeme zakoupit Kompletní prověření vozidla, které navíc může obsahovat <b>kontrolu poškození, historii inzerce, fotografie vozidla, servisní historii</b> a mnoho dalšího.',
'-267677252':'Základní prověření vozidla',
'-70564196':'- 10 EUR',
'1350105297':'Jste spokojeni s výsledkem prověření historie vozidla? Budeme rádi za vaše hodnocení.',
'1914051118':'Hodnocení',
'1786612140':'Mrzí nás, že prověření historie vozidla nesplnilo vaše očekávání. Napište nám prosím, co bychom měli do příště zlepšit.',
'-1265184280':'Chyba odeslání. Opakujte akci.',
'-669850784':'Maximální počet znaků je 2000.',
'1801814710':'<strong className="color-green">Děkujeme!</strong> Vaše hodnocení bylo odesláno a pomůže nám dále zlepšovat naše služby.',
'-883106932':'data z více než <label className="color-orange">32 zemí</label>',
'1408314893':'Cebia shromažďuje záznamy o vozidlech z více než 32 zemí. Záznamy nejčastěji pocházejí z oficiálních registrů, autoservisů, policejních databází, databází poškozených vozidel, pojišťoven či z inzertních portálů.',
'-1412306493':'Ano. Každé vozidlo je prověřováno v databázích záznamů z více než 32 zemí (včetně Německa, Dánska či USA). Po zadání VIN kódu se v přehledu nalezených informací zdarma dozvíte, jaké záznamy a z jakého období byly nalezeny.',
'1798507708':'V takovém případě jsme v našich databázích obsahujících více než 32 zemí čítající 200 milionů a USA nenalezli žádné záznamy o poškození prověřovaného vozidla. Tato skutečnost výrazně zvyšuje pravděpodobnost, že prověřovaný vůz nebyl v minulosti havarován.',
'568601301':'Záznamy o vozidlech z více <strong>než 32 zemí</strong>',
'899127599':'32 zemí',
'-1456368295':'Historie vozidel z 32+ zemí',
'1333238000':'30 000 000+ prověřených vozů',
'426416063':'Záznamy pochází z 32 zemí',
'-1549476292':'Systém AUTOTRACER prověřuje každý vůz v databázi poškozených vozidel s více než 200 miliony záznamů z 32 zemí? Nejvíce záznamů (62 %) pochází z Francie a Německa.',
'-924882043':'V databázích poškozených vozů z více než 32 zemí čítající 200 milionů záznamů škodních událostí <span class="color-red">byl nalezen</span> jeden či více záznamů o poškození prověřovaného vozidla.',
'-1366090835':'V databázích poškozených vozů z více než 32 zemí čítající 200 milionů záznamů škodních událostí <span class="light-green">nebyly nalezeny</span> záznamy o poškození prověřovaného vozidla.',
'931273814':'Historie vozů z 32+ zemí',
'-334327155':'<p>Vozidlo prověříme v databázích poškozených vozů z více než 32 zemí čítající přes 200 milionů záznamů škodních událostí.</p><p>V placené části se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.</p>',
'2078676994':'Servisní záznamy',
'503491296':'OK',
'1704037376':'Zrušit',
'-922638497':'kontrolováno',
'-2077712313':'až 12 registrů',
'-855779034':'Prověření již bylo <strong className="ml-5">provedeno</strong>',
'131879595':'Prověření <strong className="ml-5 mr-5">za poplatek</strong> dle smlouvy',
'-2034839108':'PDF',
'2049708688':'Bez záznamů ',
'1916268628':'Nizozemsko',
'-1425396841':'Cebia',
'-1521903902':'Číslo protokolu',
'1485582239':'Nesprávný formát Email-u.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Vlastníte slevový poukaz AUTOTRACER, který Vás opravňuje k nákupu kuponu zdarma. Uplatníte zadáním čísla kuponu AUTOTRACER do pole „Zadejte číslo kuponu“.',
'-675647872':'Ve všech uvedených případech pak zadáte číslo kuponu do příslušného pole na domovské stránce <a href=\'https://cz.cebia.com/\'>cz.cebia.com</a> a získáte ihned přístup k zakoupeným informacím, k nimž se v průběhu 30 dnů budete moci kdykoli vrátit',
'1080548025':'VAROVÁNÍ: Může se jednat o <strong>chybné VIN</strong>. Proveďte kontrolu VIN, případně <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN: {0}">kontaktujte podporu.</a>',
'-439637411':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Nenalezen rok výroby. <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR rokvy&body=VIN: {0}">kontaktujte podporu.</a>.',
'-43065820':'Nebyly nalezeny žádné záznamy o stáří či původu vozidla. Pochází-li vozidlo ze zahraničí, vždy si vyžádejte a pečlivě zkontrolujte originální dokumentaci k vozidlu (např. servisní knihu nebo technický průkaz).<br/><br/>Pokud chcete mít jistotu, že vůz pochází z deklarované země a není odcizený, můžete využít službu prověření původu v zahraničí <a href="https://www.cebia.cz/sluzby/provin" target="_blank" class="Link Link--lightBlue">PROVIN</a>.',
'-1362534546':'Pokud bylo vozidlo v minulosti inzerováno nebo provedena prohlídka, zobrazíme informace a fotodokumentaci.',
'431007496':'Záznam',
'-1525511285':'Údaje z technického průkazu čerpáme od státních institucí a reflektují stav v okamžiku první registrace vozidla v dané zemi.',
'1151204976':'VIN (Vehicle Identification Number) je unikátní 17místný kód, který jednoznačně a jedinečně identifikuje vozidlo. Vyražen je přímo na karoserii vozidla a pod tímto kódem jsou evidovány všechny záznamy o vozidle. Více o VIN kódu naleznete <a href="https://www.cebia.cz/pruvodce/vin-vehicle-identification-number" target="_blank">zde</a>.',
'205732919':'VIN je 17místný kód, který jednoznačně a jedinečně identifikuje vozidlo.',
'1905716471':'*) Definice <strong>Ekonomické totální škody</strong> se může lišit v různých státech i pojišťovnách. <strong>I když je škoda takto vyhodnocena, nemusí to nutně znamenat, že vozidlo bylo nepojízdné nebo neopravitelné.</strong> Představuje totiž takovou škodu na vozidle, jejíž oprava je dle podmínek pojišťovny neekonomická (tzn. že částka za opravu vozidla se blíží nebo je vyšší než hodnota vozidla v době škody). V každém případě doporučujeme podrobnou kontrolu vozidla.',
'-199845800':'Počet znaků musí být 10.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'VIN je zkratka anglického Vehicle Identification Number (identifikační číslo vozidla). Jedná se o unikátní 17místný kód tvořený písmeny a číslicemi, který výrobci přiřazují každému vyrobenému vozidlu. VIN se nachází na různých částech vozidla či v jeho dokladech. Více o VIN naleznete <a href=\'https://www.cebia.cz/pruvodce/vin-vehicle-identification-number\' target=\'_blank\'>zde</a>.',
'1682180910':'<p>V dostupných databázích nemáme k dispozici záznamy o poškození prověřovaného vozidla. Vámi zadané VIN může být chybné.</p><p>Upozornění: Systém AUTOTRACER nemusí mít k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné.</p>',
'-1276466490':'Vámi zadané VIN může být chybné',
'-1892907546':'K tomuto VIN nelze dohledat záznamy z jeho historie.',
'1843204209':'Přesto prověřit',
'-716345220':'Prověřit jiné VIN',
'1091884080':'Doporučujeme zkontrolovat VIN',
'-287497170':'Kontrola zástavního práva',
'1289833880':'Nalezeno zástavní právo',
'466639297':'Zástavní právo nebylo nalezené',
'-577812113':'Spisová značka',
'231435384':'Kontrola zástavních práv není momentálně k dispozici. Zkuste znovu načíst stránku po několika minutách.',
'-941220732':'Vozidlo je předmětem jednoho nebo více zástavních práv v uvedených státech a existuje možnost jeho zabavení v případném exekučním řízení osoby uvedené v zástavním rejstříku. Nákup vozidla se zástavním právem může být velmi rizikové. Data získáváme z oficiálních registrů zástav dané země.',
'2016489332':'Výsledkem dotazu je zjištění, zda vozidlo není předmětem zástavního práva v uvedených státech a nehrozí tedy jeho zabavení v případném exekučním řízení současného majitele. Data získáváme z oficiálních registrů zástav dané země.',
'1736313716':'Přílohy k článku',
'1021804797':'Prověření <strong className="ml-5 mr-5">zdarma</strong> v rámci limitu dle smlouvy',
'749816957':'Lze prověřit',
'981194702':'Kontrola exekucí',
'-1417536272':'Kontrola zástavních práv',
'-323399576':'Ano lze. Máte-li podezření, že jsou ve výsledku uvedeny nesprávné údaje, obraťte se prosím na naše zákaznické oddělení: <a href=\'mailto: autotracer@cebia.cz\'>autotracer@cebia.cz</a>, PO – PÁ: 8:00 – 16:30',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Číslo smlouvy (je-li k dispozici)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Provozováno jako taxi v roce',
'-137639801':'Číslo kuponu (vyplňte v případě reklamace)',
'-1619027976':'Pozn.: Vozidlo mohlo být provozováno jako taxi pouze po určitou dobu v uvedeném období.',
'-1010997259':'Pro podnikatele',
'503941074':'Detailní informace a data o prověřovaném vozidlu budou dostupná po zaplacení služby.',
'1088246106':'U zadaného vozidla zkontrolujeme:',
'-963915529':'Poškození vozidla',
'-1603961223':'data z <label className="color-orange">26 společností</label>',
'-1940014640':'Technická data',
'1785662376':'Služby',
'1913348655':'Chcete prověřit více vozidel za nižší cenu? Získejte přístup pro podnikatele a <b>ušetřete až 50 %</b> z ceny prověření. Sdělte nám prosím vaše kontaktní údaje a my se vám ozveme s nabídkou na míru.',
'1723999193':'Dostupné po zaplacení',
'1593405846':'Pojištění vozidla',
'670508858':'V rámci jedné objednávky je možné uplatnit vždy pouze jeden slevový kupon. Slevové kupony nelze sčítat ani vzájemně kombinovat. Sleva se vztahuje pouze na službu prověření historie vozidla.',
'1693716169':'Způsob platby',
'-503153747':'Zobrazení informací',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'50% SLEVA na další prověření',
'-1870036897':'Jste si již u nás nějaký vůz prověřoval/a, a zadáním čísla kuponu (uveden v obdrženém emailu) do 30 dnů od nákupu získáváte 50% slevu na další prověření.',
'163946327':'Zakoupením prověření vozidla získáváte 50% slevu na příští nákup prověření vozidla (není možné uplatnit u ceny prověření 199 Kč). Slevu uplatníte zadáním uvedeného čísla kuponu AUTOTRACER do pole pro vložení slevového kuponu v sekci „Platba“ při příštím nákupu.',
'858336732':'Sleva 50 % na další prověření',
'214251926':'Nákupem prověření získáváte automaticky slevu na další prověření jakéhokoliv dalšího vozidla. Platnost slevy je 30 dní od nákupu.',
'1317784962':'Sleva 10 % na další prověření',
'641443144':'Sleva 20 % na další prověření',
'-1318469146':'Sleva 30 % na další prověření',
'1939643950':'Sleva 40 % na další prověření',
'-1376459678':'Další proveření <br/> <b>se slevou!</b>',
'-937620931':'Data o vozidle',
'514768619':'Ověřujeme pravost VIN kódu',
'-916328171':'Ověřujeme identitu vozidla',
'1481465091':'Kontrolujeme výbavu vozidla',
'-276782700':'Prohledáváme databáze poškozených vozidel',
'1544438189':'Prověřujeme záznamy z pojišťoven',
'2021669548':'Prohledáváme historii aukčních portálů',
'2022162632':'Prověřujeme záznamy z autorizovaných servisů',
'1083617557':'Prověřujeme záznamy z neautorizovaných servisů',
'993080480':'Prohledáváme policejní databáze odcizených vozidel',
'-1240510013':'Prohledáváme soukromé databáze odcizených vozidel',
'-1027936662':'Kontrolujeme stáří vozidla',
'1299172359':'Kontrolujeme původ vozidla',
'413685839':'Prohledáváme elektronické servisní knížky',
'-473666121':'Prohledáváme státní registry vozidel',
'-254920359':'Prohledáváme státní databáze vozidel taxi',
'-811021673':'Kontrolujeme evropské svolávací akce',
'1923526539':'Kontrolujeme zabezpečení vozidla',
'-1424413327':'Kontrolujeme databáze záznamů najetých kilometrů',
'734665361':'Prověřujeme záznamy z leasingových společností',
'684288091':'Prohledáváme záznamy ze stanic technické kontroly',
'-1633964825':'Prohledáváme záznamy ze stanic měření emisí',
'-354982402':'Prohledáváme historii inzertních portálů',
'255365524':'Prohledáváme databáze prodejců nových vozidel',
'936372740':'Prohledáváme databáze prodejců ojetých vozidel',
'-1491634158':'Prověřujeme záznamy z autopůjčoven',
'-1425645764':'Operace může trvat až několik minut',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Vytváříme report',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Sdílet kompletní prověření',
'-2142411619':'Pro sdílení zkopírujte odkaz níže a zašlete jej komukoliv, s kým chcete prověření sdílet.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Doporučujeme',
'-1025377658':'položka',
'-909166524':'položky',
'-1998523642':'položek',
'-613016004':'Zvýhodněné nabídky',
'1083497926':'Ostatní služby',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Výsledek prověření historie vozidla',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Další proveření',
'-901212011':'za',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Kontrolujeme fotografie, o provedení budete informování e-mailem.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class=\'warning\'>Upozornění:</span> V systému nemusí být k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné. Doporučujeme tedy pečlivou prohlídku vozidla, ideálně s pomocí kvalifikovaného mechanika nebo ve vámi vybraném servisu. Taková prohlídka může odhalit další poškození nebo je vyloučit.',
'-1943079155':'<span class=\'warning\'>Poznámka:</span> Pokud fotografie neodpovídají prověřovanému vozidlu, prosím informujte nás na email <a href=\'mailto:autotracer@cebia.cz\'>autotracer@cebia.cz</a>.',
'707486310':'<span class=\'warning\'>Poznámka:</span> V systému nemusí být k dispozici všechny servisní záznamy vozidla. Doporučujeme porovnat záznamy se servisní knihou nebo se obrátit na příslušný autorizovaný servis značky se žádostí o výpis z digitální servisní knihy vozidla.',
'-1997337578':'Přehled vývoje stavu tachometru vozidla obsahuje hodnoty získané z dostupných informačních systémů spolupracujících partnerů. Dle vývoje grafu můžete sami posoudit, zda existuje podezření na manipulaci se stavem tachometru či nikoliv.',
'-282194273':'Doporučení',
'-1564285314':'V systému nemusí být k dispozici všechny záznamy stavů tachometru z historie provozu vozidla.',
'993789697':'Pokud u vozidla není průběžně zaznamenaná celá historie kilometrového nájezdu v čase, <b>doporučujeme</b> zkontrolovat záznamy tachometru také v servisní knize, nebo vozidlo nechat zkontrolovat v autorizovaném autoservisu. Tento postup doporučujeme především u vozidel, kde je mezi stavy tachometru časový úsek delší než dva roky.',
'2129711518':'Jedná se o dostupné informace základních parametrů, standardní a případně doplňkové výbavy z výroby. Kontrolou výbavy lze odhalit úpravy vozidla jako např. změnu strany řízení.',
'-593531916':'<span class=\'warning\'>Upozornění:</span> V systému nemusí být v některých případech k dispozici kompletní výbava vozidla.',
};

const TRANSLATIONS_DE = {
'1901555939':'VIN',
'-971921755':'Herstellermarke',
'1891129765':'Modell',
'691501523':'Karosserietyp',
'-1026019663':'Fahrzeugart',
'274952045':'Kraftstoff',
'-348535418':'Leistung',
'1000727131':'Hubraum',
'-440181428':'Herstellungsdatum',
'841597003':'Teilen',
'-762872471':'Anzeigen',
'-1615835216':'Link',
'-466013687':'Schließen',
'-1655267775':'Link kopieren',
'-1448849529':'Kann das Fahrzeug ohne Kenntnis der VIN überprüft werden?',
'-1310016716':'Online-Fahrzeugprüfung',
'1410745983':'online',
'-1803530129':'Zertifikat generieren ',
'934563996':'Ergebnis der Kontrolle',
'-1102433484':'Report-Muster',
'-1242381959':'Historie des Fahrzeugs',
'417677035':'Kontrolle der Verbindlichkeiten für das Fahrzeug',
'-115937358':'Zustand des Fahrzeugs',
'457834942':'Häufige Fragen',
'226942208':'Was ist der VIN-Code? ',
'1739046820':'Wie viel kostet die Überprüfung? ',
'16204687':'Wie kann ich das zahlen? ',
'-1607701055':'Wie zeige ich das Überprüfungsergebnis an? ',
'-1969713941':'Kann man das Überprüfungsergebnis beanstanden? ',
'1598751038':'Wo finde ich die Report ID',
'-1206763576':'Mehr Informationen',
'-729900752':'Online-Bezahlung',
'671435147':'Rabattcode eingeben',
'-2004947080':'Rabattcode eingeben',
'-1083354004':'NEU BERECHNEN',
'-1589008349':'Name',
'-704955254':'Nachname',
'-1631818401':'Straße und Hausnummer',
'1006101298':'Stadt',
'1063186093':'Land',
'287700219':'PLZ',
'-1411201334':'Umsatzsteuer-Identifikationsnummer',
'513366821':'UID',
'-102071178':'Name der Firma',
'-1882309597':'Identifikationsnummer der Firma',
'-1515081676':'ID',
'1440993804':'Kontakt-E-Mail',
'-1405758046':'Telefon',
'1366947192':'Ihre Telefonnummer',
'-490851561':'Rabatt von 100 CZK für den nächsten Report',
'-108743580':'CZK',
'786987358':'Kaufen',
'-1887658751':'Der Report ist sofort nach der Zahlung verfügbar',
'-1018049108':'Der Report ist in der Regel innerhalb von 60 Minuten verfügbar.',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Per Banküberweisung',
'-1663277':'Der Report ist innerhalb von 48 Stunden verfügbar',
'1626540224':'In bar am Hauptsitz von Cebia',
'72162446':'Fahrzeugprüfung',
'757687806':'Überblick über kostenlose Informationen',
'1954205527':'Wo finde ich den VIN-Code?',
'1603459473':'Zugang nach Bezahlung',
'2038366987':'Report anzeigen',
'-374549211':'Wo finde ich die Report ID?',
'1262814082':'VIN Eingabe',
'-15600132':'Erstzulassung',
'1535680605':'Report ID:',
'-1091217450':'Gültigkeit des Reports bis:',
'-1264594150':'Kaufdatum für den Report:',
'-701611594':'Das Fahrzeug kann leider nicht besichtigt werden.',
'160166931':'Der aktuelle Besitzer des Fahrzeugs möchte die Fahrzeugdaten nicht anzeigen.',
'-351255422':'Das Inserat, das Sie gerade sehen, enthält nicht die VIN des Fahrzeugs.',
'1704462566':'Die vollständige VIN ist nicht im Fahrzeuginserat aufgeführt.',
'366367808':'Wie lange dauert es, einen Car History Report zu erhalten?',
'2136729030':'VIN:',
'400669011':'Sie haben Anspruch auf einen Rabatt, wenn:',
'-219050562':'Blog',
'1449193326':'Über uns',
'1097675154':'Kontakt',
'2062322678':'Neuheiten',
'-1263831198':'Historie des Unternehmens',
'1166836894':'Unsere Partner ',
'-1532347183':'Für die Medien ',
'717698781':'Karriere',
'1620687461':'Das Zertifikat kann nicht erstellt werden. Das Fahrzeug ist als gestohlen gemeldet.',
'1365729306':'Zertifikatsnummer:',
'-89669431':'Jahr der Erstzulassung:',
'2130038984':'Kilometerstand:',
'2089870291':'Auto unter Kontrolle mit der Carolina-App',
'1333652846':'Kostenlos herunterladen ',
'1347577444':'Die Länge der VIN muss 17 Zeichen betragen',
'622759132':'Link wurde kopiert',
'1714373031':'Das zu prüfende Fahrzeug kann Gegenstand sein von',
'1227286459':'Rückrufaktionen',
'-477335598':'Eine RÜCKRUFAKTION',
'-343698081':'<strong>Keine Rückrufaktionen</strong> für das untersuchte',
'-1536285860':'Fahrzeug gefunden',
'-2052297753':'Datum der Bekanntgabe',
'1522685090':'Einzelheiten',
'444715065':'Mehr Info',
'-394230090':'Sie müssen die eingegebenen Angaben bestätigen. ',
'1224828864':'Falsches Jahr der Erstzulassung.',
'-470093943':'Bitte geben Sie das Jahr der Erstzulassung an.',
'1035831822':'Falscher Kilometerstand.',
'-1161922611':'Bitte geben Sie den Kilometerstand ein.',
'-1383225585':'Geben Sie das Jahr der Erstzulassung an',
'1111232857':'Jahr der Erstzulassung',
'-1998715395':'Eingabe des Kilometerstandes',
'-1962337257':'Kilometerstand',
'-1888253249':'Präzisierung des Modells',
'-339477539':'- 300 CZK',
'917746804':'Einfacher geht\'s nicht:',
'1405508217':'KOSTENLOSE Vorabberatung',
'127414665':'Wie wird der Rabatt angewendet?',
'-2081078087':'TECHNIKER AUSWÄHLEN',
'1899967331':'Die maximale Anzahl der Zeichen darf 20 betragen.',
'1699452695':'Falscher Bankcode',
'1812875983':'Bitte geben Sie Ihre Kontonummer ein.',
'-670727524':'Bitte geben Sie die Nummer des Versicherungsvertrags ein.',
'1884544037':'ePojisteni.cz',
'138266606':'Gültig bis',
'1881823014':'Bedingungen für die Geldrückgabe:',
'-1548724023':'Das Fahrzeug muss für mindestens 30 Tage versichert sein',
'1331666705':'Fehler beim Speichern. Wiederholen Sie die Aktion.',
'-1393210532':'Kontonummer',
'439576396':'Ihre Kontonummer',
'773379637':'Nummer des Versicherungsvertrags',
'-1281605763':'Rückerstattung beantragen',
'-1670752753':'Rabatt für Prüfung im Ausland',
'1496813111':'- 550',
'-856872708':'Grundpreis',
'616158592':'RABATT',
'-581170701':'Preis mit Rabatt',
'-437309148':'mit MWSt.',
'-1731075532':'Video',
'-189892310':'Bestellen',
'98918066':'Verfahren zur Bestellung:',
'-1944610133':'Rabatt für weitere Überprüfungen',
'-621660650':'Rabattcode:',
'-1845841489':'Prüfen Sie das nächste Auto mit Rabatt',
'1171588656':'Rabatt für Fahrzeugsicherheit',
'1440663595':'- 850',
'-1503110723':'Rabatt für die physische Inspektion',
'-675817237':'- 925',
'986037818':'Schäden gefunden',
'873964955':'Datum',
'790922325':'Kalkulation der Reparaturkosten',
'-1825735064':'Art des Ereignisses',
'486919475':'Erfasste Schadensstellen',
'1685506455':'Das Fahrzeug ist finanziert',
'2039551191':'Finanzierung nicht gefunden',
'-570695392':'in den aktuell verfügbaren Datenbänken',
'-765744228':'Inserate',
'659651744':'Datum des Inserats',
'-695638522':'Inserierter Kilometerstand',
'772986188':'Inserierter Preis',
'419477574':'Aufzeichnungen über das Alter und die Herkunft des Fahrzeugs',
'-109775782':'Herstellungsdatum laut Hersteller',
'-218677523':'Erstzulassung',
'-340115089':'Erstzulassung in  der Tschechischen Republik',
'1192563560':'Erstzulassung in der Slowakei',
'1650211924':'Hergestellt für den Markt',
'1812132824':'Seite der Lenkung',
'-1229728494':'Material (Liste)',
'-1147664160':'Arbeiten (Liste)',
'708828581':'Als Taxi betrieben',
'284182756':'Das Fahrzeug ist als entwendet erfasst. ',
'1244175337':'Nicht als gestohlen erfasst',
'791649880':'Quelle',
'705052471':'Am',
'1239149764':'Kontrolle der Kennungen',
'-799257304':'Modell:',
'-1964821919':'Datum der Erstzulassung:',
'-1530361098':'Preis des neuen Fahrzeugs',
'68348546':'Aktueller Marktpreis',
'763547310':'Datum der Erstzulassung',
'1968050624':'Speichern',
'-1300982647':'Ausstattungsliste nach Hersteller',
'397739850':'Kontrolle der Farbe des Fahrzeugs',
'479427038':'Steht zur Verfügung',
'2038192723':'Fahrzeug-Sicherheitskontrolle',
'-757741002':'Technische Beschreibung des Fahrzeugs',
'278755414':'Anleitung zur Fahrzeugidentifizierung',
'1529692191':'Farbe des Fahrzeugs',
'-1171491320':'Fahrzeugidentifikation',
'1549116932':'Liste der Fahrzeugausstattung',
'-1985480381':'Sonstiges',
'-1354462940':'KONTROLLIEREN SIE:',
'-690036295':'Zulassungsdatum:',
'1506895356':'Typ der Fahrzeugsicherung:',
'-749715579':'Markierungscode:',
'-329154043':'Farbe des Fahrzeugs:',
'1441236976':'Informationen über die Markierung der Scheiben',
'729768522':'Markierung der Scheiben',
'1812345820':'NEIN',
'847948448':'SIND SIE AN DER SICHERUNG DES FAHRZEUGS INTERESSIERT?',
'-1899070354':'Technische Beschreibung - Basis',
'-953303':'Technische Beschreibung - erweitert',
'1237026086':'Falscher Fahrzeugtyp.',
'-259360107':'Fahrzeugtyp eingeben.',
'-1906046398':'Die Mindestanzahl der Zeichen ist 1.',
'-69524395':'Die maximale Anzahl der Zeichen beträgt 30.',
'1663292305':'Bitte geben Sie eine Marke ein.',
'814923836':'Bitte das Modell eingeben.',
'-1985695287':'Bitte geben Sie das Ziel Detail.',
'244480988':'Marke',
'1602844398':'Detaillierte Bestimmung',
'-1749998805':'Wählen Sie ein Fahrzeug',
'-1162182506':'Fahrzeugtyp',
'1104651533':'Fahrzeugbrief - VIN-Code',
'-1898506589':'Windschutzscheibe - VIN-Code',
'216861745':'Bitte gleichen Sie die eingegebenen Daten ab!',
'-1328097963':'Die maximale Anzahl von Zeichen beträgt 9.',
'1678639740':'Bitte Telefon eingeben.',
'1303052228':'Bitte einen Namen eingeben.',
'1792286505':'Die maximale Anzahl der Zeichen beträgt 128.',
'2122707327':'Bitte Nachname eingeben.',
'-938825602':'Die maximale Anzahl der Zeichen beträgt 64.',
'1683031395':'Bitte geben Sie Straße und Hausnummer ein.',
'661065202':'Bitte geben Sie die Stadt.',
'-83271898':'Bitte geben Sie Ihre Postleitzahl ein.',
'1018067916':'Die maximale Anzahl von Zeichen beträgt 5.',
'12673416':'Die Postleitzahl ist im falschen Format angegeben.',
'154576238':'Report ID hat das falsche Format.',
'-581020701':'wird vom Fahrzeughersteller per Gesetz für obligatorisch erklärt, wenn Gesundheit, Sicherheit und Umwelt gefährdet sind. Dabei handelt es sich in der Regel um Herstellungsmängel des Fahrzeugs, die sich erst während des Betriebs zeigen, und der Hersteller sorgt dafür, dass sie kostenlos behoben werden. Die Informationen stammen von der Website <a href="https://car-recalls.eu/" target="_blank">Car-recalls.eu</a> mit Hilfe des RAPEX-Systems.',
'1969671182':'Diese Rückrufaktionen wurden anhand des Modells, der Marke und des Baujahres des überprüften Fahrzeugs erstellt, nicht anhand seiner VIN. Vergewissern Sie sich daher immer im Detail der Aktion, ob sie auch für das Fahrzeug gilt, das Sie überprüfen. Vergewissern Sie sich gleichzeitig beim Fahrzeughändler oder einer Vertragswerkstatt, dass das Fahrzeug den entsprechenden Rückrufmaßnahmen unterzogen wurde.',
'-2076502822':'Eine Rückrufaktion wird vom Fahrzeughersteller zwangsweise von Gesetzes wegen ausgerufen, wenn das Fahrzeug einen Herstellungsfehler aufweist, der die Gesundheit, die Sicherheit oder die Umwelt gefährdet. Im Rahmen der Rückrufaktion wird der Mangel in einer autorisierten Servicestelle kostenlos behoben. Die Daten werden von dem Portal <a href="https://car-recalls.eu/" target="_blank">Car-recalls.eu</a> mit Hilfe des RAPEX-Systems bereitgestellt.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Schließen Sie eine Versicherung über <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> ab und erhalten Sie Ihr Geld für die Fahrzeugprüfung zurück.',
'1014002660':'In Zusammenarbeit mit <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> bieten wir Ihnen die Möglichkeit, eine kostenlose Fahrzeugprüfung durchzuführen.',
'607518250':'Die Schaltfläche Bestellen führt Sie zur Online-Bestellung des PROVIN-Dienstes. Bitte füllen Sie diese Bestellung aus und geben Sie das Passwort in den Anmerkungen an: AUTOTRACER und die Prüfcode-Nummer.',
'5180415':'Die Schaltfläche Bestellen führt Sie zur Online-Bestellung des VINTEST-Dienstes. Bitte füllen Sie diese Bestellung aus und geben Sie das Passwort in den Anmerkungen an AUTOTRACER und Report ID.',
'-312241960':'* Private Datenbanken erlauben in der Regel jedem, Informationen über einen Diebstahl einzugeben.',
'1225691915':'In den verfügbaren Datenbanken wurde festgestellt, dass das Fahrzeug <span class="warning">als Taxi betrieben</span> wurde oder noch wird. Prüfen Sie gründlich den technischen Zustand des Fahrzeugs.',
'-742868638':'Ihre E-Mail Adresse',
'2033866416':'Online-Zahlung mit Kreditkarte',
'138706621':'Online-Überweisung',
'-1634274740':'REPORT ANZEIGEN',
'1047356790':'Bitte geben Sie das Datum der ersten Registrierung ein.',
'-39394372':'Anzeige von Fahrzeugen mit einer kleineren Übereinstimmung',
'-862383013':'Anzeigen ähnlicher Fahrzeuge',
'-233652021':'FAHRZEUGHISTORIE',
'271847434':'Car History Zertifikat',
'1918868575':'Ein erfahrener Techniker wird das Fahrzeug direkt am Verkaufsort in der Tschechischen Republik überprüfen. Die Inspektion umfasst eine physische Überprüfung von 170 Punkten des Fahrzeugs, einschließlich Diagnose und Messung der Lackstärke. Sie erhalten einen Online-Bericht über alle gefundenen Mängel und eine Empfehlung, ob das Fahrzeug für den Kauf geeignet ist. Und das alles innerhalb von <strong>48 Stunden</strong> nach dem Termin.',
'724746244':'Wenn Sie einen Autoscheck bei Automato.cz bestellen, geben Sie den Rabattcode <strong>AUTOTRACER</strong> in das dafür vorgesehene Feld im Warenkorb ein und der Rabatt wird automatisch vom Preis abgezogen.',
'288042578':'Bitte geben Sie den Firmennamen ein',
'-1335200216':'Falscher ID-Wert.',
'1693391088':'Das E-Mail-Format ist falsch!',
'-766469985':'Geben Sie Ihre E-Mail ein',
'-1397806656':'Zustimmung zur Verarbeitung von personenbezogenen Daten',
'-189484387':'Abonnieren ',
'1095553640':'Alle Fragen',
'1531150438':'Haben Sie keine Antwort gefunden? ',
'-469136467':'Kontaktieren Sie uns',
'-461584700':'Vor dem Kaufen',
'400498991':'Cookie-Richtlinien',
'1329764956':'Was ist der VIN-Code? ',
'-394767958':'Kaufen',
'181431424':'Nach der Zahlung ',
'1649241229':'Der VIN-Code ist direkt auf das Fahrzeug eingestanzt, oder Sie finden ihn im Fahrzeugschein und anderen Fahrzeugpapieren. Wenn Sie ein im Internet inseriertes Auto überprüfen wollen, fragen Sie den Händler nach dem VIN-Code. ',
'1774694482':'Woher die Fahrzeugdaten kommen? ',
'-1122721284':'Prüft Cebia auch Fahrzeuge im Ausland? ',
'437878122':'Welche Informationen bringe ich über das Fahrzeug in Erfahrung?',
'850725814':'Der Umfang der Informationen variiert für jedes Fahrzeug je nach Alter und Vorgeschichte. Sie können den Umfang der Informationen zu dem von Ihnen geprüften Fahrzeug kostenlos abfragen, indem Sie den VIN-Code in die Auskunft eingeben. ',
'-656971513':'Wie sieht das Ergebnis der Überprüfung aus? ',
'966688141':'Der Preis für die Fahrzeugüberprüfung hängt von der Menge und dem Umfang der verfügbaren Informationen über das zu überprüfende Fahrzeug ab. Sie können den Umfang der Informationen über das zu prüfende Fahrzeug und den Preis für die Prüfung sofort nach Eingabe des VIN-Codes des Fahrzeugs in der Übersicht der verfügbaren Informationen finden. ',
'764893782':'Sie können online per Kreditkarte oder über Google Pay und Apple Pay bezahlen. Zahlungen werden über das sichere Zahlungsportal GoPay abgewickelt.',
'726713260':'Wie lange dauert es, bis ich das Ergebnis der Überprüfung erhalte? ',
'1478989945':'Das Prüfergebnis wird unmittelbar nach Eingang der Zahlung generiert. Der Dienst arbeitet ununterbrochen und der gesamte Prozess ist automatisch. ',
'1681038954':'Kann man bei der Zahlung Firmendaten eingeben? ',
'696259005':'Bei der Eingabe Ihrer Daten können Sie die Identifikationsdaten Ihrer Firma angeben. Nach der Zahlung wird jedoch nur ein vereinfachtes Steuerdokument erstellt. Um eine Rechnung zu erhalten, kontaktieren Sie uns bitte per E-Mail  <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>.',
'-1555365601':'Das Ergebnis der Überprüfung wird automatisch generiert, sobald die Zahlung eingegangen ist. Ein Link zum Überprüfungsergebnis und die Gutscheinnummer werden ebenfalls an die beim Kauf angegebene E-Mail-Adresse gesendet. Das Ergebnis der Überprüfung kann 30 Tage lang nach seiner Generierung angezeigt werden.',
'1984400663':'Die Zahlung war nicht erfolgreich, was nun? ',
'-956265022':'Wenn die Zahlung aus irgendeinem Grund nicht erfolgt ist, gehen Sie einen Schritt zurück und nehmen Sie die Zahlung erneut vor. Wenn Ihr Konto bereits belastet wurde, Sie aber noch keine E-Mail über die Zahlung erhalten haben, kontaktieren Sie uns bitte unter <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>.',
'-246543694':'Das Überprüfungsergebnis ist nach seiner Generierung für 30 Tage verfügbar. Sie können es über einen Link ansehen, der an die E-Mail-Adresse gesendet wird, die Sie beim Kauf der Überprüfung angegeben haben. ',
'592240026':'Ich habe keine E-Mail bekommen, was nun? ',
'339130199':'Eine E-Mail wird automatisch nach der Zahlung generiert. Sollte sie nicht in Ihrem Posteingang angekommen sein, kontaktieren Sie uns bitte unter <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>. Bitte geben Sie in der Nachricht die im Überprüfungsergebnis gefundene Report ID oder Ihre Kontaktinformationen an.',
'1681612939':'Ich habe die falsche E-Mail eingegeben. Was nun? ',
'-818807180':'Alles gut. Bitte senden Sie die korrekte Adresse per E-Mail an <a href="mailto:info.de@cebia.com">info.de@cebia.com</a> und geben Sie in der Nachricht die im Überprüfungsergebnis angegebene Gutscheinnummer an. Wir werden die Zugangsdaten dann an die richtige E-Mail-Adresse senden.',
'-40057886':'Das Ergebnis der Überprüfung kann beanstandet werden. Wenn Sie den Verdacht haben, dass im Überprüfungsergebnis falsche Angaben gemacht wurden, wenden Sie sich bitte an unseren Kundendienst unter <a href="mailto:info.de@cebia.com">info.de@cebia.com </a>.',
'-755697752':'Kann eine Rechnung ausgestellt werden? ',
'1950759546':'Angesichts des Preises für die Überprüfung wird nur ein vereinfachtes Steuerdokument automatisch erstellt. Um eine Rechnung zu erhalten, kontaktieren Sie uns bitte unter <a href="mailto:info.de@cebia.com">info.de@cebia.com</a> Um die Dinge zu beschleunigen, geben Sie bitte auch die Gutscheinnummer aus der E-Mail an, die Sie nach Ihrem Kauf erhalten haben. ',
'-1960272511':'VIN eingeben',
'-371538970':'Wo finde ich den VIN-Code?',
'-1956291670':'Wie funktioniert es?',
'1850076653':'1. Schritt',
'-1260091754':'Verfügbare Angaben',
'1279291471':'2. Schritt',
'-811487544':'Bezahlung',
'777550298':'3. Schritt',
'-1534039143':'Car History Report  ',
'495126992':'4. Schritt',
'1916048998':'Überprüfen',
'-580693003':'Kontakte',
'-760821457':'Benötigen Sie einen Rat? ',
'1432374431':'KontaktFormular',
'1838884148':'Rezeption Cebia',
'1665302400':'Sitz des Unternehmens',
'1196114687':'Tschechien',
'-95958777':'Rechnungsdaten',
'1808758025':'Telefon',
'275235071':'Wir stehen Ihnen zur Verfügung',
'353260003':'Mo - Fr 8:00 - 16:30',
'822817400':'Prüfen der Fahrzeughistorie',
'-1317542970':'Verifiziert durch Kunden',
'-1884218466':'Für jedes Fahrzeug prüfen wir',
'1736624969':'Kilometerstand',
'2137107000':'Schadenshistorie',
'647263705':'Diebstahlkontrolle',
'-441819024':'Servicegeschichte',
'982206269':'Historische Fotos',
'1753341741':'Taxikontrolle',
'-366535509':'Fachleute nutzen Cebia Dienste',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'Sie kennen uns von...',
'654946563':'Zeichen',
'-1398891951':'Kontrolle des Kilometerstandes',
'1425691903':'33 % der Gebrauchtwagen auf dem tschechischen Markt haben einen manipulierten Tacho!',
'1803799674':'Kontrolle von Alter und Herkunft',
'1389120536':'Bei Gebrauchtwagen aus dem Ausland werden oft das Alter des Fahrzeugs und seine Herkunft gefälscht!',
'-2068933994':'Diebstahlkontrolle',
'-1184837319':'Finanzierungskontrolle',
'-1877453535':'Schadenkontrolle',
'64494271':'20% der Gebrauchtwagen aus dem Ausland sind verunfallt!',
'1051433840':'Prüfen Sie, ob das Auto als Unfallwagen inseriert wurde!',
'1076835371':'Fahrzeugbewertung',
'-1805177459':'Rückrufaktion ',
'957124168':'Anzahl der Eigentümer in der Tschechischen Republik',
'-1705145830':'Informationen über das Fahrzeug',
'1890293527':'Taxikontrolle',
'-13439485':'Bitte Name eingeben',
'-1147931879':'Bitte geben Sie Ihren Nachnamen ein',
'2144560301':'Falscher Wert Telefon',
'-1642977586':'Geben Sie Ihre Rufnummer ein',
'-1249914076':'Die E-Mail hat nicht das richtige Format',
'-2038223065':'Meldung eingeben',
'810453022':'E-mail',
'-1217737116':'Meldung',
'-664538889':'Ich bin mit der Verarbeitung der',
'1322823680':'Nachricht senden',
'56891982':'Die maximale Anzahl der Zeichen beträgt 16.',
'277834303':'Dankeschön',
'552239414':'Ihre Nachricht wurde erfolgreich gesendet.',
'1851370681':'Wir melden uns bald bei Ihnen.',
'1952981238':'Warnung',
'342579190':'Report kaufen',
'1586626737':'Weiter',
'399846737':'Wird geladen...',
'556543048':'Die folgenden Informationen können überprüft werden:',
'560876430':'Fotos und Werbung',
'-320410471':'Steht nicht zur Verfügung',
'1185850210':'Keine Aufzeichnungen über Schäden gefunden',
'-903608283':'Auszug aus der Fahrzeuggeschichte',
'718416430':'Informationen über den Report',
'668096075':'Report ID für die wiederholte Anzeige:',
'-1690204814':'Gültig bis:',
'-840281270':'Getriebe',
'-1901926256':'Grundlegende Fahrzeugdaten',
'-1550051512':'Karosserietyp',
'-1043441574':'WARNUNG:',
'-709435856':'Erfahrungen der Kunden',
'-1149927862':'Finden Sie heraus, was die Leute über das Vetting sagen',
'456577623':'Ich fand heraus, dass das Auto ein Totalschaden war. Dankeschön',
'329190632':'Cebia hat die Erwartungen erfüllt und Schäden am Fahrzeug festgestellt. Ich danke Ihnen.',
'1450297818':'Diebstahlkontrolle in der Tschechischen Republik und der Slowakei',
'-1594408882':'Rabatt für die Inspektion des Fahrzeugs durch einen unabhängigen Techniker Autobezobav.cz',
'341709606':'Wollen Sie so sicher wie möglich sein, dass das Auto auch technisch einwandfrei ist? Wählen Sie einen zertifizierten Techniker von Autobezobav, der das Fahrzeug mit Ihnen direkt am Verkaufsort besichtigt und Sie eindeutig darüber informiert, ob es sich um einen guten Kauf handelt oder nicht.',
'382487941':'Wenn Sie bei Autobezobav einen Techniker Ihrer Wahl anfordern, geben Sie zunächst die grundlegenden Informationen für eine kostenlose Vorabberatung ein und geben Sie im zweiten Schritt der Anforderung (physische Inspektion) Ihre Report ID in das Feld Rabattcoupon ein. Der Techniker wird dann automatisch 300 CZK vom Preis der Inspektion abziehen.',
'-28646667':'Die Pflichtversicherung (Haftpflicht- oder Unfallversicherung) muss über das Portal ePojisteni.cz innerhalb von 30 Tagen nach der Bezahlung der Fahrzeugkontrolle abgeschlossen werden.',
'-355614707':'Das Angebot gilt für die Versicherung von Pkws, Nutzfahrzeugen und Lkws (nicht für Motorräder,Vierräder, Dreiräder oder Anhänger und Sattelauflieger',
'-134420730':'Gilt nicht für Versicherungen, die über das Portal ePojisteni.cz vor dem Kauf eines Fahrzeugchecks abgeschlossen wurden ',
'1195365944':'Sobald die Versicherung abgeschlossen ist, geben Sie die Kontonummer und die Policennummer an. Der Betrag wird automatisch auf das von Ihnen im Antragsformular angegebene Konto überwiesen, sobald die Bedingungen erfüllt sind, spätestens jedoch 60 Kalendertage nach Absendung des Antrags.',
'-1622287889':'Danke, die Anfrage wurde abgeschickt. Unsere Mitarbeiter werden sich spätestens am nächsten Arbeitstag mit Ihnen in Verbindung setzen.',
'1069770504':'Wenn das von Ihnen ausgewählte Fahrzeug individuell in die Tschechische Republik importiert wird, empfehlen wir Ihnen, den PROVIN-Dienst zur Überprüfung der Herkunft im Ausland zu nutzen.',
'-121060108':'Wir prüfen, ob das Fahrzeug im Herkunftsland zugelassen war, ob es ordnungsgemäß abgemeldet wurde, ob das Fahrzeug wegen eines Totalschadens aus dem Verkehr gezogen wurde, ob das Fahrzeug im Land nicht als gestohlen gemeldet ist und andere Fakten.',
'-1158490787':'Der Rabatt kann innerhalb von 30 Tagen nach dem Kauf des überprüften Fahrzeugs eingelöst werden und ist nicht mit anderen Rabatten kombinierbar.',
'-1471409910':'Wollen Sie sicher sein, dass das von Ihnen gewählte Fahrzeug nicht illegal umgebaut wurde?  Wir empfehlen Ihnen die Nutzung des umfassenden Authentifizierungsdienstes VINTEST für Fahrzeugkennungen.',
'1555104762':'Der VINTEST-Service bietet den Kunden eine 100%ige Garantie für das Ergebnis der physischen Inspektion des Fahrzeugs. Wir führen eine detaillierte physische Untersuchung des Fahrzeugs mit Hilfe modernster digitaler Diagnosemethoden durch. Ziel dieses Dienstes ist es, zu überprüfen, ob das Fahrzeug alle Original-Identifizierungsmerkmale (Fahrgestellnummer, Kennzeichen, Schweißnähte an der Karosserie) aufweist und ob das Fahrzeug oder ein Teil des Fahrzeugs im Verdacht steht, aus einer kriminellen Handlung zu stammen.',
'735500953':'Auf diese Weise lässt sich feststellen, ob für das Fahrzeug eine Finanzierung (in Form von Leasing oder Kredit), eine Verpfändung oder ein Darlehen bei den aufgeführten Unternehmen besteht.Die Daten stammen aus den Informationssystemen der einzelnen Unternehmen.',
'-1173444543':'Leasing- und Kreditunternehmen',
'-976513658':'*Carsharing = kurzfristige Anmietung eines Autos direkt vom Eigentümer (oder Carsharing zwischen dem Eigentümer und dem kurzfristigen Entleiher).',
'-1479754603':'In den verfügbaren Datenbanken wurde festgestellt, dass das Fahrzeug nicht als Taxi betrieben wurde und auch derzeit nicht als Taxi betrieben wird.',
'-467405909':'Aufzeichnungen über den Betrieb des Fahrzeugs als Taxi',
'1930957191':'Die vorgelegten Informationen dienen nur zu Informationszwecken. Cebia haftet nicht für Schäden, die durch die Nutzung der übermittelten Daten entstehen.',
'-1895346741':'Wir teilen Ihnen den üblichen Marktpreis des Fahrzeugs mit, anhand dessen Sie überprüfen können, ob der Preis des Fahrzeugs, das Sie bewerten, mit dem Angebotspreis übereinstimmt. Die Preisberechnung umfasst unter anderem die Beobachtung der Anzeigen für Gebrauchtwagen in der Tschechischen Republik und im Ausland, einschließlich der Beobachtung der Preise der tatsächlichen Verkäufe. Der Bewertungsalgorithmus arbeitet mit Berechnungsmodellen, die auf einer langfristigen Marktbeobachtung basieren, kann aber den physischen Zustand des Fahrzeugs nicht berücksichtigen.',
'-985858180':'In seltenen Fällen kann der Preis des Fahrzeugs nicht festgelegt werden. Der Grund dafür ist, dass die Daten für eine qualifizierte Bewertung des Fahrzeugs nicht ausreichen.',
'1915347014':'Wir zeigen Ihnen Inserate ähnlicher Fahrzeuge, die derzeit auf dem Markt angeboten werden, und in einigen Fällen auch die Preise ähnlicher, tatsächlich verkaufter Fahrzeuge (wir verwenden die VIN, um die genaue Spezifikation der Fahrzeuge zu vergleichen). Anhand von Anzeigen ähnlicher Fahrzeuge können Sie vergleichen, ob der Preis des von Ihnen geprüften Fahrzeugs mit ähnlichen Fahrzeugen auf dem Markt übereinstimmt. Wir ersparen Ihnen eine Menge Zeit, die Sie mit der Suche und dem Vergleich ähnlicher Angebote im Internet verbringen.',
'-767763886':'Es wurden keine Aufzeichnungen über eine Inserierung des Fahrzeugs in der Tschechischen Republik oder im Ausland gefunden.',
'797541609':'Prüfen Sie, in welchem Zustand und mit welchem Kilometerstand das Auto in der Vergangenheit inseriert wurde. Wenn das Auto mit einem höheren Kilometerstand inseriert wurde, als es jetzt hat, kann der Kilometerzähler manipuliert worden sein.',
'-1902352540':'Bestätigung der eingegebenen Angaben. Nach dem Speichern können sie nicht mehr geändert werden.',
'-2008975653':'Es wurden keine Aufzeichnungen über die vom Fahrzeughersteller eingetragene Fahrzeugfarbe oder den Zeitpunkt der Erstzulassung des Fahrzeugs in der Tschechischen Republik oder der Slowakei gefunden.',
'7150984':'Prüfen Sie, ob die aktuelle Farbe des Fahrzeugs mit der vom Hersteller angegebenen Farbe oder der nach der Erstzulassung in der Tschechischen Republik eingetragenen Farbe übereinstimmt. Wenn die Farbe anders ist, kann es sich um ein neu lackiertes Fahrzeug handeln, aber auch um ein verunfalltes oder gestohlenes Fahrzeug.',
'-1664139465':'Das Verzeichnis der Ausstattung des Fahrzeugs steht nicht zur Verfügung. Wir empfehlen, direkt im Fahrzeug zu kontrollieren, ob die Ausstattung original ist und funktioniert.',
'1681595153':'Das Ergebnis der Prüfung ist die Information, ob das von Ihnen geprüfte Fahrzeug durch eine Scheibenmarkierung gesichert und im OCIS-System registriert ist. Wenn dies der Fall ist, teilen wir Ihnen den gekennzeichneten Code und das Datum der Registrierung mit.',
'571758489':'Das Fahrzeug wurde in der Vergangenheit mit Glasmarkierungen versehen und ist in der internationalen OCIS-Datenbank registriert.',
'855264535':'Wenn der eindeutige Code auf einigen oder allen Scheiben fehlt, könnte das Fahrzeug verunfallt, verändert oder gestohlen worden sein.',
'388233970':'Informationen über die Markierung der Scheiben:',
'1615022749':'Die technischen Parameter des Fahrzeugs konnten nicht ermittelt werden. Sie sind jedoch im Fahrzeugbrief zu finden oder können beim Fahrzeughersteller angefordert werden.',
'704641294':'Sobald Sie Ihre Fahrzeugwahl eingegeben haben, erhalten Sie von Car History die Anweisungen für eine grundlegende Überprüfung der Identifikatoren. Die Anweisungen gelten generell für die angegebene Fahrzeugmarke und das angegebene Fahrzeugmodell. Für die Demonstration wurde ein zufällig ausgewähltes Fahrzeug benutzt.',
'-621659477':'Die Fahrzeugwahl kann bereits teilweise auf der Grundlage der eingegebenen VIN vorausgefüllt sein. Wenn die vorausgefüllte Fahrzeugwahl nicht mit dem zu prüfenden Fahrzeug übereinstimmt, bearbeiten Sie die Auswahl manuell.',
'152333450':'Keine Schadensaufzeichnungen gefunden, was bedeutet das? ',
'780709713':'Wie lange wird das Ergebnis der Überprüfung zur Verfügung stehen? ',
'830704335':'Erfahren Sie alles über die Geschichte des Fahrzeugs:',
'1850331238':'Toller Service, der sich lohnt. Ich habe sogar bei einem Ausstellungsfahrzeug Manipulationen am Drehzahlmesser festgestellt.',
'1705245752':'Ich wollte ein Auto kaufen, von dem sie sagten, es sei nicht kaputt. Es war schon mehrmals abgestürzt.',
'739619867':'Absolut perfekter Service, wir haben nicht für viel Geld ein kaputtes Auto gekauft, danke!',
'-2071079400':'Ich habe mich für ein Auto entschieden, bei dem nicht angegeben war, dass es zwei Unfälle mit hohen Schäden hatte, und hier habe ich es vor dem Kauf entdeckt. Ich danke Ihnen.',
'21202350':'Vielen Dank für diese App. Ich habe fast 3.000 Euro mit meinem Auto verloren. Das Auto soll nur leicht von vorne angestoßen worden sein. Laut der App ist es zweimal abgestürzt und dabei stark beschädigt worden.',
'1343185270':'Die Überprüfung hat mich beruhigt und mir geholfen, eine Kaufentscheidung zu treffen.',
'2083029914':'Es gab umfangreiche Schäden, die der Verkäufer mir gegenüber nicht erwähnt hat.',
'1933958141':'Geringfügige Kollision festgestellt + Tachokontrolle ab Zulassungsdatum.',
'1900495866':'Dank Cebia erfuhr ich, dass mit dem Auto etwas nicht in Ordnung war. Natürlich hat mir der Händler nichts davon erzählt, und der Verkäufer hat wahrscheinlich auch geschwiegen. Ich danke Ihnen.',
'-1668804606':'Bei der Inspektion entdeckte ich schwerwiegende Mängel, die mir der Verkäufer verschwiegen hatte, wie z. B. die Verkrümmung des Drehzahlmessers und verdeckte Schäden.',
'-273876487':'Ich fand heraus, dass das Fahrzeug einen Kilometerstand hatte, der sich wellte. Der Verkäufer gibt 155.000 km an und hat tatsächlich 250.000 km...',
'911857027':'Um das bezahlte Ergebnis der Prüfung anzuzeigen, geben Sie die Report ID ein',
'1167593586':'Sie erhalten eine Report ID, wenn Sie einen Car History Report kaufen.',
'-2136315768':'Wenn Sie mit Kreditkarte, GooglePay oder ApplePay bezahlen, wird der Bericht sofort nach der Zahlung angezeigt und ein Link dazu an Ihre E-Mail geschickt.',
'868918064':'Wenn Sie per Banküberweisung zahlen, erhalten Sie einen Link zum Report per E-Mail, sobald der Betrag auf dem Cebia-Bankkonto gutgeschrieben wurde.',
'1572179326':'Wenn Sie in der Firmenzentrale von Cebia bar bezahlen, erhalten Sie den Report ausgedruckt',
'-1322783148':'Die Platzierung der VIN ist je nach Fahrzeugmarke und -modell unterschiedlich. Es ist jedoch immer auf Fahrzeugbrief und Fahrzeugschein und oft unter der Windschutzscheibe zu finden.',
'700484420':'Die VIN ist das eindeutige Identifizierungsmerkmal eines jeden Fahrzeugs. Sie finden sie in den Fahrzeugpapieren oder direkt am Fahrzeug.',
'-851712281':'Wir spüren alle verfügbaren Aufzeichnungen für das zu überprüfende Fahrzeug auf und zeigen Ihnen den Umfang der Aufzeichnungen. Sie wissen immer, was Sie kaufen.',
'-1983041378':'Sie können über das sichere Zahlungsportal GoPay per Karte oder Überweisung bezahlen.',
'-1809684191':'Der Car History Report ist sofort nach Bezahlung verfügbar (bei Online-Bezahlung). Wir schicken ihn Ihnen natürlich auch per E-Mail.',
'541894049':'Sie können die Kilometerstandwerte für einen bestimmten Zeitraum ablesen.  Ein einfaches Diagramm zeigt, wie sich der Kilometerstand im Laufe der Zeit entwickelt hat, und Sie erkennen eine eventuelle Manipulation.  Achtung: Mehr als 40% der Gebrauchtfahrzeuge haben einen manipulierten Tacho!',
'643692561':'Sie zeigen Informationen über erfasste Schäden am Fahrzeug an. Es können detaillierte Informationen über die Schadenshöhe und den Schadensumfang beigefügt sein. Achtung: Vergessen Sie nicht, gleichzeitig den technischen Zustand des Fahrzeugs und eventuelle Vorschäden zu überprüfen. Ob es sich beispielsweise nicht um einen Totalschaden gehandelt hat.',
'878444309':'Wenn das Fahrzeug in der Vergangenheit einmal inseriert worden ist, zeigen wir Ihnen das Inserat und die Fotodokumentation dazu an.',
'185243225':'Sie finden ausführliche Informationen und eine Übersicht über die Servicearbeiten, die im Rahmen der Reparatur oder der regelmäßigen Wartung Ihres Fahrzeugs durchgeführt wurden.  Achtung: Vergleichen Sie die Aufzeichnungen mit dem Serviceheft und erkundigen Sie sich nach weiteren Informationen über die Servicehistorie des Fahrzeugs.  ',
'1036319803':'Wir überprüfen für Sie das tatsächliche Baujahr, das einen erheblichen Einfluss auf den aktuellen und zukünftigen Preis des Fahrzeugs haben kann. Das Baujahr wird im Fahrzeugbrief nicht mehr angegeben. Es wird nur das Datum der 1. Zulassung eingetragen. Dies kann jedoch um bis zu 2 Jahre vom Baujahr abweichen, wobei 6 Jahre auch keine Ausnahme darstellen. Doch eine einjährige Verjüngung kann den Preis Ihres Fahrzeugs. Bei den meisten Fahrzeugen erfahren Sie auch das Datum der Erstzulassung und das Land, für das das Fahrzeug ursprünglich hergestellt wurde.',
'-437053085':'Wir prüfen das Fahrzeug in den aktuell verfügbaren Datenbanken gestohlener Fahrzeuge. Dabei handelt es sich hauptsächlich um europäische Datenbanken aus staatlichen und nichtstaatlichen Quellen. Eine Liste der verfügbaren Länder und Quellen finden Sie im kostenpflichtigen Bereich.',
'494784690':'Wir überprüfen das Fahrzeug in den Datenbanken von Finanzierungsgesellschaften, Autovermietungen und Pfandleihern. Im kostenpflichtigen Bereich können Sie prüfen, ob das Fahrzeug nicht durch eine Finanzierung, ein Darlehen oder ein Pfand belastet ist.',
'2074346923':'Überprüfung in Datenbanken von Fahrzeugen, die als Taxidienst betrieben werden. Im kostenpflichtigen Bereich können Sie herausfinden, ob das Fahrzeug als Taxi betrieben wurde oder noch betrieben wird.',
'-976680067':'Wir zeigen Ihnen eine Liste von Rückrufaktionen, die das überprüfte Fahrzeug betreffen könnten.',
'1665109686':'Anhand der technischen Beschreibung können Sie die wichtigsten Parameter selbst überprüfen, auf deren Grundlage die Modellversion des Fahrzeugtyps für den Verkehr zugelassen wurde.   Vergleichen Sie diese Parameter mit den Angaben im Fahrzeugbrief.',
'-719963267':'Wir zeigen Ihnen die Fahrzeugausstattung entsprechend der Spezifikation aus seiner Herstellung an. Sie können die derzeitige Ausstattung des Fahrzeugs mit der vergleichen, die der Hersteller angibt. Achtung: Wenn der Hersteller z.B. in den Parametern Rechtslenker, Ledersitze oder Dachfenster angibt und Ihr Fahrzeug Linkslenker, Stoffsitze und kein Dachfenster hat, handelt es sich wahrscheinlich nicht um das gleiche Fahrzeug, sondern es kann sich um ein entwendetes und modifiziertes Fahrzeug handeln! Sie sollten bei allen Unstimmigkeiten der nachfolgenden physischen Überprüfung des Fahrzeugs größere Aufmerksamkeit widmen, um so den Kauf eines Fahrzeugs zu vermeiden, das aus einer Straftat stammt.',
'-1106584159':'Ihr Fahrzeug ist durch Sandstrahlen oder Ätzen der Scheiben gesichert, oder am  Fahrzeug wurden Karosserieteile markiert. Wir teilen Ihnen den gekennzeichneten Code und das Datum der Zulassung mit.',
'1830950672':'Wir zeigen Ihnen die vom Fahrzeughersteller registrierte Farbe und die Farbe nach der Zulassung in der Tschechischen Republik, ggf. nach der Zulassung in der Slowakei (sofern uns diese Informationen vorliegen).',
'1835889470':'Wird geladen...',
'1020539397':'Der Betreiber des Dienstes Car History - Cebia, spol. s.r.o. weist die Nutzer darauf hin, dass die angegebenen Informationen von den Partnern des Betreibers zur Verfügung gestellt wurden, ohne dass die Möglichkeit besteht, deren Richtigkeit zu überprüfen. Alle Angaben dienen nur zu Informationszwecken.',
'2054100346':'Es wird keine Garantie auf das Ergebnis der Fahrzeugüberprüfung Car History Report gegeben.',
'-167783152':'Dieser Report wurde durch eine Abfrage des von Cebia, spol. s r.o. betriebenen Dienstes Car History gemäß den geltenden Allgemeinen Geschäftsbedingungen für die Nutzung des Dienstes Car History erstellt. Der Nutzer ist berechtigt, die bereitgestellten Informationen in ihrer Gesamtheit ausschließlich für den eigenen Gebrauch zu nutzen. Ihre Weiterverbreitung oder Veränderung ohne die Zustimmung des Betreibers ist untersagt.',
'-1697979879':'Direktor von cebia, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Direktor von cebia',
'759051798':'Die Firma Cebia ',
'-31539157':'Wir sind ein tschechisches Unternehmen, das 1991 in Prag gegründet wurde. Unsere Dienstleistungen in den Bereichen Fahrzeugüberprüfung, Sicherheit und Identifizierung werden von Kunden in 9 europäischen Ländern genutzt.   Unsere Kunden sind normale Autofahrer, Versicherungsgesellschaften, Autohändler, staatliche Institutionen, Polizeibehörden und gerichtliche Sachverständige. In unserer Geschichte haben wir Zehntausende von Gebrauchtwagenbetrügereien aufgedeckt und Millionen von Kunden in ganz Europa geholfen, ein Auto zu kaufen. ',
'-1821588851':'Beschwerden und Reklamationen',
'-276118588':'Beschwerden und Reklamationen sind eine wertvolle Quelle für Vorschläge zur Verbesserung unserer Dienstleistungen. Bitte kontaktieren Sie uns über das Kontaktformular oder unter: <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>',
'-1446517877':'Wir werden die Reklamation innerhalb von 14 Tagen nach Eingang bearbeiten und Sie über das Ergebnis informieren.',
'708234375':'Kontaktieren Sie uns über das Kontaktformular oder per E-Mail <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>.',
'1474175101':'Allgemeine Geschäftsbedingungen',
'1343500875':'Datenschutzerklärung',
'-2083968164':'Online-Fahrzeugbewertung',
'907588970':'Gründung des Unternehmens',
'-770165824':'<strong>30 Jahre Erfahrung</strong> in der Fahrzeugüberprüfung',
'-1138482741':'<strong>Mehr als 1.000.000</strong> überprüfte Fahrzeuge pro Jahr',
'154007106':'<strong>Über 750.000</strong> zufriedene Kunden',
'177137909':'Die Cebia-Datenbank enthält mehr als 1 Milliarde Datensätze von Gebrauchtwagen aus mehr als 32 Ländern. Dazu gehören Aufzeichnungen über den Kilometerstand, Schäden am Fahrzeug, Fahrzeugfotos und Werkstattprotokolle. ',
'723486619':'Wird geladen...',
'742852638':'Zeitraum:',
'787763184':'Zeitraum:',
'-685036969':'Erläuterungen:',
'290784658':'Steht nicht zur Verfügung:',
'412892373':'Wir konnten in dieser Kategorie keine Informationen über das Fahrzeug finden',
'1853282090':'Steht zur Verfügung:',
'-249038921':'XY Aufzeichnungen:',
'805146013':'Wir haben XY Aufzeichnungen für das Fahrzeug in der Kategorie ausfindig gemacht, die Sie im kostenpflichtigen Bereich finden können',
'242118549':'Allgemeine Geschäftsbedingungen',
'521725542':'Hier können Sie die Allgemeinen Geschäftsbedingungen für Car History einsehen',
'-571686819':'hier',
'639498968':'Die Zahlung für die Anzeige von detaillierten Informationen im Abschnitt Car History Report wird gemäß den Bedingungen, die im Vertrag mit Cebia festgelegt sind, in Rechnung gestellt.',
'-1705426324':'Sie können 30 Tage lang ab der ersten Anzeige einer VIN detaillierte Informationen zu dieser VIN abrufen, und Sie zahlen nur einmal.',
'529403448':'Wichtiger Hinweis',
'-821000576':'Cebia, spol. s r.o. informiert die Nutzer des Dienstes, dass die zur Verfügung gestellten Informationen von den Partnern des Betreibers stammen, ohne dass die Möglichkeit besteht, deren Richtigkeit zu überprüfen. Alle Angaben dienen nur zu Informationszwecken. Sollte der Zustand des Fahrzeugs erheblich von den hier gemachten Angaben abweichen, empfehlen wir, den tatsächlichen Zustand des Fahrzeugs durch einen entsprechenden Sachverständigen überprüfen zu lassen.',
'-1138137280':'Informationen über die Verarbeitung von personenbezogenen Daten',
'125063053':'Informationen über die Verarbeitung personenbezogener Daten der Nutzer von Car History anzeigen',
'-608864551':'Wir haben für das Fahrzeug in der Kategorie Informationen ausfindig gemacht, die Sie im kostenpflichtigen Bereich finden können',
'-1879995849':'Eingegebene Daten',
'754897480':'Jahr der Erstzulassung',
'-1834106204':'Aktueller Kilometerstand',
'404797827':'Zertifikat anzeigen',
'1358780725':'Zertifikat generieren ',
'-952450705':'Ich habe das kontrolliert und bestätige die aufgeführten Informationen. ',
'-1601808583':'<span className="green">Fertig!</span> Die folgenden Informationen können für das angegebene Fahrzeug überprüft werden:',
'-1763095911':'Ein Zertifikat kann für die folgenden Kategorien ausgestellt werden:',
'761362384':'Beispiel eines Zertifikats',
'-397155268':'Report anzeigen',
'1375027269':'FAHRZEUG ÜBERPRÜFEN',
'2055286638':'über',
'-1668243810':'Wir sind stärker',
'1141836815':'Wir werden Teil der tschechischen Holding EAG. Dadurch werden unsere Datenbanken mit Datensätzen aus ganz Europa - insbesondere aus Deutschland, Österreich oder Dänemark - erheblich erweitert. ',
'-903271711':'Wir führen ein Online-Tool zur Ermittlung des Preises von Gebrauchtwagen ein. Dank unserer umfangreichen Gebrauchtwagen-Datenbank sind die Daten sehr genau und finden schnell ihren Weg zu den größten Versicherungs- und Leasinggesellschaften. ',
'-1262829232':'Wir verändern den Gebrauchtwagenmarkt',
'1367974718':'Aufgrund des wachsenden Interesses an der Online-Automobilsuche arbeiten wir mit den größten Anzeigenportalen in Mitteleuropa zusammen. Die Ergebnisse unseres History Checks werden zum Qualitätsmerkmal für Gebrauchtwagen.',
'-300528762':'Einstieg in die Online-Welt',
'-899322976':'Unsere Dienstleistungen sind jetzt für jeden bequem von zu Hause aus zugänglich. Der Gebrauchtwagenbetrug hat seinen Höhepunkt erreicht und wir bekämpfen ihn mit aller Kraft.',
'-4875574':'Wir erweitern die Dienstleistungen',
'1166185728':'Wir erweitern unser Dienstleistungsangebot um die Überprüfung der Fahrzeugidentifizierungsnummer (VIN) und unsere Datenbank wächst sprunghaft an. Wir arbeiten mit Versicherungsgesellschaften, Behörden, Polizei zusammen. ',
'-1619922514':'Einstieg im Ausland',
'-844900279':'Nicht nur in der Tschechischen Republik ist der Handel mit gestohlenen Fahrzeugen auf dem Vormarsch. Deshalb bauen wir eine internationale Datenbank für gestohlene Fahrzeuge auf, die uns helfen soll, die wahre Herkunft von Gebrauchtwagen zu ermitteln.',
'598423318':'Drei Studienkollegen gründen ein Unternehmen namens Cebia. Die ursprüngliche Absicht, in die IT-Branche einzusteigen, wurde bald durch das Interesse an der Fahrzeugsicherheit ersetzt. ',
'-1828699417':'Zwangsvollstreckung/Insolvenz',
'4000011':'Kostenlose Überprüfung von Dokumenten',
'1479506336':'Suchverlauf',
'-1478881101':'Historie der Fahrzeugüberprüfung',
'1115668272':'Historie Zwangsvollstreckung/Insolvenz',
'691760517':'Historie der Dokumentenprüfung',
'-614781938':'Der Umfang und die Kosten der Überprüfung können für jedes Fahrzeug variieren, je nachdem, wie viele Informationen verfügbar sind.',
'702191442':'Zurück',
'-1034048918':'VIN-Kontrolle',
'-1214567423':'Referenznummer',
'427849990':'ZERTIFIKAT für Fahrzeugprüfung erstellen',
'-1718377395':'Fehler beim Laden des Codes.',
'877365836':'Eine Zahlungsseite konnte nicht erstellt werden.',
'-1082791212':'Falsche ReportID',
'-255296664':'Die Report ID ermöglicht es Ihnen, den Report nach dem Kauf 30 Tage lang wiederholt anzuzeigen.',
'456125545':'Der Betreiber des Dienstes Car History - Cebia, spol. s r.o., informiert die Nutzer des Dienstes, dass die angegebenen Informationen von den Partnern des Betreibers zur Verfügung gestellt wurden, ohne dass die Möglichkeit besteht, deren Richtigkeit zu überprüfen. Alle Angaben dienen nur zu Informationszwecken.',
'-1172808350':'Wenden Sie sich an den Händler, damit er Ihnen die 17-stellige VIN mitteilt, und überprüfen Sie dann das Fahrzeug.',
'-2079080642':'Wenden Sie sich an den Händler, damit er Ihnen die 17-stellige VIN mitteilt, und überprüfen Sie dann das Fahrzeug.',
'-585052469':'Sie kaufen einen Gebrauchtwagen? Finden Sie alles über ihn heraus! Insbesondere den Kilometerstand, das Baujahr oder die Schadensdatensätze nach VIN-Code.',
'76811920':'BEISPIEL eines Ergebnisses der stichprobenartigen Überprüfung eines Fahrzeugs.',
'1050846059':'Dennoch empfehlen wir Ihnen, Ihr Fahrzeug sorgfältig überprüfen zu lassen, am besten von einem qualifizierten Mechaniker oder in einer Werkstatt Ihrer Wahl. Eine solche Durchsicht kann frühere Schäden eindeutig aufdecken oder ausschließen.',
'-1068264553':'Überprüfen Sie immer das vom Fahrzeughersteller angegebene Herstellungsdatum. In den Fahrzeugbriefen wird lediglich das Datum der Erstzulassung angegeben, das erheblich vom Herstellungsdatum abweichen kann (z. B. um bis zu zwei Jahre). Das Fahrzeug kann somit für jünger ausgegeben werden, was sich erheblich auf seinen Marktpreis auswirkt.',
'756232271':'Es wurden keine Aufzeichnungen über das Alter oder die Herkunft des Fahrzeugs gefunden. Wenn das Fahrzeug aus dem Ausland kommt, verlangen Sie immer die Original-Fahrzeugpapiere (z. B. Serviceheft oder Fahrzeugschein) und prüfen Sie diese sorgfältig. Wenn Sie sicher sein wollen, dass das Fahrzeug aus dem angegebenen Land stammt und nicht gestohlen ist, können Sie den <a class="Link Link--lightBlue" href="https://www.cebia.cz/sluzby/provin" target="_blank">PROVIN-Dienst</a> zur Überprüfung der ausländischen Herkunft nutzen.',
'-643660678':'Es wurden Aufzeichnungen über Servicearbeiten gefunden, die in der Vergangenheit im Rahmen von Reparaturen oder Routinewartungen an dem Fahrzeug durchgeführt worden waren.',
'1533967406':'Der Preis für die Fahrzeugüberprüfung hängt von der Menge und dem Umfang der verfügbaren Informationen über das zu überprüfende Fahrzeug ab. Sie können den Umfang der Informationen über das zu prüfende Fahrzeug und den Preis für die Prüfung sofort nach Eingabe des VIN-Codes des Fahrzeugs in der Übersicht der verfügbaren Informationen finden. ',
'-1289463237':'Der Report wird in der Regel wenige Minuten nach Eingang der Zahlung erstellt. Der Service läuft nonstop und der gesamte Prozess ist automatisch.',
'1304585346':'Das Ergebnis der Überprüfung wird automatisch generiert, sobald die Zahlung eingegangen ist. Ein Link zum Überprüfungsergebnis und die Gutscheinnummer werden ebenfalls an die beim Kauf angegebene E-Mail-Adresse gesendet. Das Ergebnis der Überprüfung kann 30 Tage lang nach seiner Generierung angezeigt werden.',
'-661494819':'Fehler beim Laden von Daten.',
'-1681989289':'Sie bereits einen Fahrzeugcheck gekauft haben, der Sie zu einem Rabatt von 100 CZK auf diesen Kauf berechtigt. Sie können den Rabatt in Anspruch nehmen, indem Sie die Report ID, die Sie per E-Mail erhalten haben, in das Feld "Geben Sie die  Report ID ein" eingeben. Bitte beachten Sie: Die Frist für diesen Rabatt beträgt 90 Tage ab dem Kauf der Fahrzeugüberprüfung.',
'681093117':'Sie haben einen Rabattcode erhalten, wenn Sie Ihren FLEX-Dienst um ein weiteres Jahr verlängern. Um den Rabatt anzuwenden, geben Sie die Rabattcode-Nummer, die Sie auf Ihrem FLEX-Steuerbeleg erhalten haben, in das Feld "Rabattcode eingeben" ein.',
'-1551909822':'Sie haben einen Rabattcode, der Sie zu einer kostenlosen Überprüfung  berechtigt. Verwenden Sie diese, indem Sie die Report ID in das Feld "Report ID eingeben" eingeben.',
'-103162544':'Die für die Anzeige der bezahlten Informationen erforderlichen Angaben sowie ein vereinfachtes Steuerdokument für die Zahlung werden nach erfolgter Zahlung an die angegebene E-Mail-Adresse geschickt.',
'-591911074':'Die für die Anzeige der bezahlten Informationen erforderlichen Angaben sowie ein vereinfachtes Steuerdokument für die Zahlung werden nach erfolgter Zahlung an die angegebene E-Mail-Adresse geschickt.',
'706315962':'Sie erhalten immer einen vereinfachten (nicht adressierten) Steuerbeleg zusammen mit den Angaben, die zur Einsichtnahme in die gezahlten Informationen erforderlich sind. Wenn Sie möchten, dass Ihre Rechnungsdaten (Firma, Adresse, Umsatzsteuernummer) auf dem Steuerbeleg eingetragen werden, geben Sie sie bitte unten ein.',
'500915765':'Bei der Bestellung werden Sie zum GoPay-Zahlungsgateway weitergeleitet, um eine sichere Zahlung per Kreditkarte vorzunehmen. Nach der Zahlung werden Sie automatisch zum Ergebnis der Fahrzeugprüfung weitergeleitet. Ein Link zur Fahrzeugüberprüfung und der Buchungsbeleg wird gleichzeitig an Ihre E-Mail-Adresse gesendet.',
'-676823504':'Wenn Sie diese Zahlungsmethode wählen, werden Sie zum GoPay-Zahlungsgateway weitergeleitet, wo Sie Ihr Bankinstitut auswählen, von dem Sie die Zahlung vornehmen möchten.',
'-764670786':'Wenn Ihre Bank eine Online-Überweisung anbietet, leiten wir Sie zu Ihrem Online-Banking weiter, wo Sie einen vorausgefüllten Zahlungsauftrag erhalten und eine Zahlung für die Dienstleistung vornehmen können. ',
'2062466434':'Wenn wir die Zahlung von der Bank sofort erhalten, leiten wir Sie automatisch zum kostenpflichtigen Bereich des Car History-Dienstes weiter. Andernfalls warten Sie bitte, bis Sie eine E-Mail erhalten.',
'734607756':'Nachdem die Zahlung auf unserem Konto gutgeschrieben wurde, erhalten Sie eine E-Mail mit einem Link zum Ergebnis der Überprüfung und einem Buchungsbeleg.',
'1715999355':'Google Pay ist eine schnelle und einfache Möglichkeit, überall auf Google zu bezahlen, ohne Ihre Zahlungsdaten eingeben zu müssen. Sie können schnell mit Ihrer in Google Pay gespeicherten Karte bezahlen. Um diesen Zahlungsdienst nutzen zu können, muss Ihre Bank ihn ebenfalls unterstützen. Wenn Ihre Zahlung erfolgreich war, werden Sie automatisch zum Ergebnis der Überprüfung weitergeleitet. Gleichzeitig senden wir eine automatische Nachricht an die von Ihnen angegebene E-Mail-Adresse, die einen Link zum Ergebnis der Überprüfung und den Steuerbeleg enthält.',
'790632208':'Apple Pay ist eine Zahlungsmethode, die es einfach macht, mit einer in Apple Wallet gespeicherten Karte zu bezahlen. Um diesen Zahlungsdienst nutzen zu können, muss Ihre Bank ihn ebenfalls unterstützen. Wenn Ihre Zahlung erfolgreich war, werden Sie automatisch zum Ergebnis der Fahrzeugüberprüfung zurückgeleitet. Gleichzeitig senden wir eine automatische Nachricht an die von Ihnen angegebene E-Mail-Adresse, die einen Link zum Ergebnis der Überprüfung und den Steuerbeleg enthält.',
'-1402859324':'Wenn Ihre Bank nur Offline-Überweisungen anbietet, erstellen wir für Sie Instruktionen für Banküberweisungen. Sie tätigen dann die Überweisung bei Ihrer Bank und sobald der Betrag auf unserem Konto gutgeschrieben ist, senden wir Ihnen eine E-Mail mit dem Zugang zum kostenpflichtigen Bereich des Car History Service und dem Zahlungsbeleg.',
'347099860':'Dieser Vorgang kann bis zu 2 Arbeitstage dauern (in der Regel aber 24 Stunden).',
'-43783036':'Der Report wird am Hauptsitz von Cebia erhältlich sein',
'-802148009':'Die Überprüfung Car History Check wird persönlich am Sitz der Cebia, spol. s r.o. Vyskočilova 1461/2a, 140 00 Praha 4 in der Arbeitszeit Montag bis Freitag von 8.00 bis 16.30 Uhr in bar bezahlt. Sie müssen die VIN des zu prüfenden Fahrzeugs mit sich führen. Sie erhalten die Fahrzeuginformationen sofort nach Zahlung.',
'1985201267':'Europas größte Datenbank mit Fahrzeugdaten',
'-1590601891':'Hinweis: Der Service Car History enthält möglicherweise nicht alle Schäden am Fahrzeug, da einige Schäden den Versicherern nicht gemeldet werden oder in der Online-Überprüfung nicht verfügbar sind.',
'-1465948017':'<strong>OCIS - System</strong> zur Kennzeichnung von Fahrzeugscheiben. Einfache, effiziente und kostengünstige Fahrzeugsicherung. ',
'1553997983':'<strong>CEBIA SAT</strong> - moderne Fahrzeugsicherung. Schützt das Auto vor Diebstahl und die Insassen im Falle eines Unfalls. Sofortige Reaktion und ständige Kontrolle auf Ihrem Telefon. ',
'-124714580':'Sie können online per Kreditkarte oder über Google Pay und Apple Pay bezahlen. Zahlungen werden über das sichere Zahlungsportal GoPay abgewickelt.',
'-753863533':'ZERTIFIKAT DER FAHRZEUGPRÜFUNG - Übersicht der verfügbaren Informationen',
'1441255407':'Kategorie',
'1549893453':'Kontrollmöglichkeit',
'1547427535':'JA',
'508023484':'Kontrolle des Zulassungsdatums',
'2076692599':'Physische Besichtigung',
'-1521980547':'Kontrolle des Kilometerstands',
'-1009985044':'JA - anhand von',
'1747355819':'Angaben',
'1639206028':'Kontrolle der Herkunft des Fahrzeugs',
'-1524110425':'Kontrolle der Zahlung der Ökosteuer',
'-1961089879':'MwSt.-Kontrolle',
'1732127987':'Kontrolle eines schweren Unfalls',
'-198755848':'Zulassungskontrolle',
'-1790774232':'Kontrolle eines Hochwasserschadens',
'-1781437228':'Kontrolle des Motortyps',
'-1344389293':'Kontrolle der Kennungen des Fahrzeugs',
'-499181915':'Foto',
'-687939409':'Die Informationen stammen aus Aufzeichnungen von Fahrzeugzustandsdokumentationen, die von Cebia oder seinem Netz von Vertragspartnern erworben wurden.',
'-43022982':'Datum der Vornahme des Service:',
'-136414993':'Fahrzeugzustand:',
'2023782231':'Am Fahrzeug erfolgte die physische Kontrolle seiner Identifikatoren (Prägung der FIN, Produktionsschildchen usw.). Die Informationen wurden aus dem Register der Dienstleistung VINTEST übernommen, die von der Gesellschaft Cebia oder ihren Partnern betrieben wird.',
'758499555':'Stand der Identifikatoren:',
'488100027':'Zurück',
'-1281249872':'Weiter',
'-141562572':'Generieren',
'-40297285':'Sie können sich so einen Überblick über die Preis- und Datenentwicklung des zu prüfenden Fahrzeugs verschaffen.',
'-1742933347':'Inseratnummer',
'-2099386182':'Verkauf',
'1560408371':'Verkaufspreis',
'-280814568':'Fahrzeugkontrollzertifikat anzeigen',
'2075274708':'Datum des Inserats',
'-1248991647':'Ukraine',
'-1779298552':'Schweden',
'-18328562':'Lettland',
'1764744897':'Litauen',
'-1273046880':'Rumänien',
'1252567719':'Bulgarien',
'445626359':'Griechenland',
'-868498706':'Slowenien',
'-1528448787':'Ungarn',
'-387469480':'Slowakei',
'25300212':'Österreich',
'1771002523':'Italien',
'69077775':'Dänemark',
'647944028':'Polen',
'1160986873':'Deutschland',
'897084544':'Frankreich',
'2056598840':'Spanien',
'264100206':'Russland',
'-1609348689':'Cookie-Richtlinien',
'-365994139':'Mehr laden',
'807848789':'Millionen von zufriedenen Kunden',
'1677683955':'Cebia hat über <strong>2 000 Partner</strong> aus ganz Europa, die Aufzeichnungen über Fahrzeuge für den Service Car History liefern.',
'652553309':'Sie wissen, dass...',
'1453750886':'Fertig!',
'1498262202':'Steht zur Verfügung',
'1514779988':'Fahrzeuginspektion durch einen Techniker',
'1058658544':'Cebia Rabatt-Coupons',
'-2035347536':'0 Aufzeichnungen',
'1050102897':'Die detaillierte Ausstattungsbeschreibung des Fahrzeugherstellers enthält alle Grund- und Sonderausstattungen. Durch die Überprüfung der Ausstattung können Veränderungen am Fahrzeug festgestellt werden, wie z. B. eine Veränderung der Lenkseite.',
'2145745364':'Auszug aus den Tachometeraufzeichnungen',
'-1636264801':'Beschreibung',
'-1488190529':'Datensatz-Nummer',
'64594583':'Finanziertes Fahrzeug',
'-1441353378':'Ohne Finanzierung',
'-2101084179':'januar',
'-1066567335':'februar',
'-1761670105':'märz',
'104663035':'april',
'1397259452':'mai',
'-642974117':'juni',
'613657227':'juli',
'-2044487450':'august',
'1921417285':'september',
'-1672709282':'oktober',
'-678062097':'november',
'1302554433':'dezember',
'1268839568':'In den Datenbanken der Serviceleistungen wurden keine Einträge gefunden. Fragen Sie nach dem Serviceheft des Fahrzeugs (vorzugsweise ein elektronisches Serviceheft). Die Echtheit der Serviceleistungen kann direkt in der Werkstatt, in der sie laut Serviceheft durchgeführt wurden, überprüft werden.',
'-112660439':'Erstellen Sie ein Zertifikat, das Sie Interessenten für Ihr Auto zur Verfügung stellen können, und erhöhen Sie so dessen Vertrauenswürdigkeit beim Verkauf.',
'-1157869217':'Aufzeichnung wurde gefunden',
'-1350474522':'In den verfügbaren Datenbanken sind keine Aufzeichnungen über den Tachometer des überprüften Fahrzeugs vorhanden.',
'1040913417':'Wir empfehlen Ihnen, andere mögliche Quellen für den Kilometerstand zu prüfen, wie z. B. das Serviceheft oder eine Vertragswerkstatt nach dem Serviceheft zu fragen. Achten Sie bei der Inspektion des Fahrzeugs auf Abnutzungserscheinungen, insbesondere an Teilen, die einen Hinweis auf den tatsächlichen Kilometerstand geben können.',
'1010039483':'Fahrzeuginspektion ab 1 990 CZK (je nach gewähltem Techniker)',
'-622228786':'E-mail',
'-982011601':'USA',
'786789662':'Ermäßigte Pakete kaufen',
'737703701':'Möchten Sie weitere Fahrzeuge überprüfen? Wir bieten Pakete für Unternehmen und Privatpersonen zu ermäßigten Preisen an: 10 Background Checks für 5.000 CZK, 20 Background Checks für 10.000 CZK und 50 Background Checks für 20.000 CZK. Wenn Sie an einem der Pakete interessiert sind, geben Sie bitte unten Ihre Kontaktdaten ein und geben Sie in der Nachricht das gewünschte Paket an. Wir werden uns umgehend mit Ihnen in Verbindung setzen. ',
'-174399420':'Möchten Sie für Ihr Unternehmen einkaufen oder weitere Fahrzeuge besichtigen? Profitieren Sie von unseren',
'845754511':'ermäßigten Paketen!',
'-614796774':'Tschechien',
'1011350398':'Mo - Fr 8:00 - 16:30',
'-68730396':'Am:',
'544810671':'NE - ist nicht erfasst',
'-1670731287':'In CZE zugelassen als:',
'1597705322':'Grundausstattung',
'16575694':'Fahrzeug:',
'-846486931':'Aufzeichnungen',
'-1615816320':'Aufzeichnung',
'1415114012':'Aufzeichnungen',
'1802294807':'Fotos',
'1122888753':'Fotos',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pruvodce?id=109&do=downloadFile">personenbezogenen Daten einverstanden</a>',
'1802705032':'<a className="Link Link--lightBlue" href="/detailArticle/wo-finde-ich-den-vin-code" target="_blank" rel="noreferrer noopener">Mehr Informationen</a>',
'883512613':'Cebia Car History - Fahrzeugprüfung',
'-1965309318':'Prüfen Sie die Historie des Fahrzeugs in der größten Datenbank in Europa. Überprüfung von Schäden und Kilometerstand, Fotos und vieles mehr.',
'-1871315210':'Online-Bezahlung',
'-1803227447':'Bitte wählen Sie die entsprechende Zahlungsmethode.',
'-1249181167':'Nachrichten - Car History',
'-838385662':'In unserem Blog finden Sie wichtige Informationen zur Überprüfung und zum Kauf von Gebrauchtwagen, zur Vermeidung von Betrug und vieles mehr.',
'1154868985':'Häufige Fragen - Car History',
'908158246':'Sehen Sie sich die am häufigsten gestellten Fragen unserer Kunden an. Wenn Sie eine andere Frage haben, können Sie uns gerne kontaktieren.',
'-633966577':'Über uns - Car History',
'1171158673':'Erfahren Sie mehr über Cebia Car History und warum sie von Millionen von Kunden in ganz Europa zur Überprüfung der Fahrzeughistorie genutzt wird.',
'-1244282008':'Cookie-Richtlinien',
'-792206035':'Diese Website verwendet verschiedene Arten von Cookies. Einige Cookies werden von Drittanbietern gesetzt. Sie können Ihre Zustimmung zu deren Verwendung jederzeit widerrufen.',
'1489309005':'Freier Eintritt im Rahmen des Vertrages',
'-470675791':'Ich habe die Informationen überprüft und bestätige sie.',
'2108896817':'DEN ANGEGEBENEN RABATT STORNIEREN',
'6892736':'Der Rabatt wurde gestrichen',
'-1199844274':'Der Code ist nicht gültig!',
'-552424253':'Für den Eintritt in den kostenpflichtigen Bereich ist eine Gebühr zu entrichten, die im Vertrag festgelegt ist.',
'167769730':'Barzahlung',
'1768026018':'Die Überprüfung Car History Check wird persönlich am Sitz der Cebia, spol. s r.o. Vyskočilova 1461/2a, 140 00 Praha 4 in der Arbeitszeit Montag bis Freitag von 8.00 bis 16.30 Uhr in bar bezahlt. Sie müssen die VIN des zu prüfenden Fahrzeugs mit sich führen. Sie erhalten die Fahrzeuginformationen sofort nach Zahlung.',
'820786512':'Der Code wurde angenommen, der Preis wurde neu kalkuliert.',
'-279227794':'Baujahr-Kontrolle',
'1470281039':'Leasing-/Kreditkontrolle in der Tschechischen Republik',
'60776637':'Report ID',
'529459564':'Bitte Report ID eingeben.',
'-861194224':'Fahrzeuginspektion',
'2076412240':'Neu',
'360601996':'Ihre ReportID ist abgelaufen.',
'1762078183':'Material',
'822990672':'Durch Drücken dieses Buttons ziehen Sie 1x Abfrage aus dem freien Abfragelimit des aktuellen APC-Vertrags.',
'532528312':'gebraucht',
'-319106355':'Fahrzeug',
'935795728':'Report-Muster',
'-916000765':'Car History Report',
'-1721948126':'Report-Muster. Prüfen Sie die Historie des Fahrzeugs in der größten Datenbank in Europa.',
'329882651':'Wenn Sie auf diese Schaltfläche klicken, werden die Kosten für die Anzeige der detaillierten Informationen gemäß dem aktuellen APC-Vertrag berechnet.',
'-494157101':'Steuerdokument',
'-465994559':'Fotos',
'857656094':'Herstellermarke:',
'-2005319583':'Modell des Fahrzeugs:',
'339937023':'Jahr der Erstzulassung laut Fahrzeugbrief:',
'-441771827':'Laden von Daten',
'-161761801':'Unverifizierter Zeitraum',
'1229398238':'WARNUNG: Cebia CERTIFICATE kann nicht erstellt werden. Das Fahrzeug ist als <strong className="bolder">gestohlen</strong> registriert.',
'358386818':'Alter des Fahrzeugs:',
'-701699977':'Cebia CERTIFICATE konnte nicht erstellt werden - die Fahrgestellnummer konnte nicht eindeutig identifiziert werden. Überprüfen Sie die VIN anhand der Fahrzeugpapiere oder wenden Sie sich an den Kundendienst.',
'-1359814132':'Nächstes Foto',
'340541220':'Vergrößern',
'2096545526':'Verkleinern',
'-158382161':'Vorheriges Bild',
'-1554923364':'(cesta technika zdarma)',
'-749344895':'Rabatt',
'-1755324145':'Modell des Fahrzeugs:',
'1747292041':'Smart code',
'1093009871':'Intelligente Kommunikation für Autohändler',
'-421925379':'Chcete u svých vozidel propagovat, že jsou prověřená?',
'-303554559':'Dejte na každé vozidlo Smart kód a zákazník si sám načte výsledek jeho prověření.',
'5128661':'Ganz einfach. Sie drucken den Cebia Smart Code (A5-Format) aus und bringen ihn hinter der Windschutzscheibe des Fahrzeugs an, das Sie verkaufen.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Die anderen Informationen (der vollständige Auszug aus dem Car History) können dann direkt bei Ihnen angefordert werden.',
'-566386963':'Bitte füllen Sie dieses Formular aus und wir werden uns mit Ihnen in Verbindung setzen.',
'-1639125181':'Senden',
'76421921':'Obligatorische Punkte',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Sind Sie daran interessiert, Smart Codes für Ihre Fahrzeuge zu drucken? Kontaktieren Sie unsere Verkaufsabteilung:',
'2094660384':'Petr Kala, Leiter der Verkaufsabteilung',
'361077673':'Oder füllen Sie das Formular aus und wir werden uns bei Ihnen melden:',
'1277961418':'Möchten Sie wirklich einen Smart Code erstellen?',
'-2133968142':'Ich habe einen Rabattcode',
'-1922950659':'Zahlungsmethode',
'-2072875261':'Überprüftes Fahrzeug',
'783887791':'Bestellung',
'-1499237034':'kostenlose',
'-1854208328':'Rabattcode einlösen',
'-793343279':'Geschenk',
'-1393236672':'Kosovo',
'1342791550':'Kontrolle des Kilometerstandes',
'2063498881':'Sonderausstattung',
'-152116623':'QR – Smart kód',
'-956949153':'Höhe des Schadens',
'-552950132':'Daten',
'30639535':'Daten',
'-1164317619':'Nein',
'1402944789':'Bitte geben Sie die benötigten Daten ein',
'-1812102598':'WARNUNG: Der <strong>Kilometerverlauf </strong> kann nicht überprüft werden. Die Kategorie wird zu einem Mangel an Informationen führen.',
'666398003':'Warnung!',
'771644825':'Die eingegebene VIN kann falsch sein!',
'-1131044763':'Mehr Informationen?',
'-2054470344':'Die eingegebene VIN kann falsch sein!',
'-1369269346':'Es wurde ein möglicher Fehler in der eingegebenen VIN festgestellt. Bitte überprüfen Sie, ob die eingegebene VIN mit der auf dem Fahrzeug eingeprägten oder im Fahrzeugbrief angeführten VIN übereinstimmt. ',
'-1625517200':'Wo ist die VIN zu suchen?',
'1171142400':'Es wurde noch keine Zahlung geleistet. Bitte wiederholen Sie die Zahlung.',
'-1192484737':'Ohne Ihre Zustimmung zu den Allgemeinen Geschäftsbedingungen können Sie nicht fortfahren',
'-247455599':'Bitte geben Sie die ID-Nummer des Unternehmens ein.',
'-1944712721':'Die Mindestanzahl der Zeichen beträgt 12.',
'1664270996':'Staat',
'-862797995':'Der Zustand des Fahrzeugs, wie er beim Verkauf festgestellt wurde. Bitte sehen Sie sich die beigefügten Fotos für eventuelle Schäden an.',
'-1997376096':'Slowakische Republik',
'-1344951568':'Fahrzeugregister',
'-644036535':'Datenbank',
'-426412387':'Nationales Fahrzeugregister',
'1736003883':'Daten aus der Fahrzeugbrief',
'1610817679':'Für das Fahrzeug sind Kilometerstandaufzeichnungen vorhanden. Im kostenpflichtigen Bereich finden Sie die Kilometerstände für die Zeiträume, die wir für diese Kategorie auflisten. Anhand der Aufzeichnungen lässt sich feststellen, ob der Kilometerstand echt ist oder ob der Tachometer des Fahrzeugs manipuliert wurde.',
'1669009738':'Das zu prüfende Fahrzeug wurde in der Vergangenheit inseriert oder fotografiert. Im kostenpflichtigen Bereich können Sie die Details der Inserate, den Kilometerstand, Fotos des Fahrzeugs und ggf. den ausgeschriebenen Preis einsehen (der Umfang der Informationen kann von einer Aufzeichnung zur anderen variieren). ',
'1518701163':'Wir haben Aufzeichnungen über das Alter oder die Herkunft des Fahrzeugs. Im kostenpflichtigen Bereich können Sie das Herstellungsdatum des Fahrzeugs, das Datum der Inbetriebnahme des Fahrzeugs, das Datum der Zulassung des Fahrzeugs in der Tschechischen Republik oder das Land, für das das Fahrzeug hergestellt wurde, überprüfen. Der Umfang der Informationen im kostenpflichtigen Bereich kann von Fahrzeug zu Fahrzeug variieren.',
'1816609203':'Oft wird das Baujahr gefälscht, um das Auto jünger und damit teurer erscheinen zu lassen!',
'-900812009':'Ein gestohlenes Auto kann dem Käufer von der Polizei ohne jegliche Entschädigung beschlagnahmt werden!',
'-976941031':'Diese Fahrgestellnummer kann nicht für ein Taxi überprüft werden.',
'444450829':'bis zu ',
'982955181':'12 Register',
'-1739651968':'Sicherheitskontrolle',
'-167461646':'Überprüfen der Fahrzeughistorie',
'-1365221853':'Die maximale Anzahl der Zeichen beträgt 14.',
'1302766369':'Es wurde keine Bewertung des Fahrzeugs vorgenommen. Die Bewertung kann während der Gültigkeitsdauer des Gutscheins durch Eingabe der Fahrzeugdaten (Datum der Erstzulassung und aktueller Kilometerstand) auf unserer Website nach Eingabe der Gutscheinnummer vorgenommen werden.',
'1881868699':'Ich kaufe für die Firma',
'631216697':'Bitte geben Sie Ihre E-Mail-Adresse ein.',
'945011940':'Ihre Daten',
'-401861769':'Kontakt E-Mail',
'1441058305':'Wenn Sie die Umsatzsteuer-Identifikationsnummer angeben, werden wir versuchen, die restlichen Daten aus der offiziellen Datenbank zu übernehmen.',
'-1181757195':'Nach der Zahlung senden wir Ihnen an die von Ihnen angegebene E-Mail-Adresse einen Link, über den Sie Informationen über die Historie Ihres Fahrzeugs und ein vereinfachtes Steuerdokument abrufen können.',
'-1768425519':'Wenn Sie möchten, dass Ihre Rechnungsdaten (Firma, Adresse, MwSt.-Nummer) auf dem vereinfachten Steuerbeleg angegeben werden, tragen Sie sie bitte in die nachstehenden Felder ein. Wenn Sie die Umsatzsteuer-Identifikationsnummer angeben, werden wir versuchen, die restlichen Daten aus der offiziellen Datenbank zu übernehmen.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Das Cebia-Zertifikat bewertet die Überprüfung des Kilometerzählers, die Überprüfung der Datenbank gestohlener Fahrzeuge, die Verpflichtungen der Leasinggesellschaft sowie die Überprüfung der Fahrgestellnummer und des Herstellungsjahres. Die Gesamtbewertung wird durch die Anzahl der Sterne (0-5) und eine verbale Bewertung ausgedrückt.',
'308350422':'Gesamt mit MwSt.:',
'-24797068':'Sie haben Anspruch auf einen Rabatt, wenn:',
'-1323263000':'Sie haben einen FLEX-Prepaid-Service bei uns und verwenden den Code auf Ihrem Steuerbeleg.',
'-724069837':'Sie haben einen Rabattcode, der Sie zu einem kostenlosen Einkauf berechtigt.',
'-127346860':'Sie haben einen Rabattcode von einem unserer Partner.',
'-47884841':'ohne Mehrwertsteuer',
'881014685':'Preis ohne Mehrwertsteuer',
'-15294371':'Unbestätigte UID',
'1286068311':'Die offizielle Datenbank der Mehrwertsteuerzahler hat Ihre Mehrwertsteuernummer nicht bestätigt. Daher können wir Ihre Angaben nicht automatisch vervollständigen. Bitte überprüfen Sie Ihre Umsatzsteuer-Identifikationsnummer oder geben Sie Ihre Unternehmensdaten manuell ein.',
'-172351568':'Die Datenbank antwortet nicht',
'1839952435':'Die offizielle Datenbank der Mehrwertsteuerzahler antwortet nicht. Bitte geben Sie Ihre Unternehmensdaten manuell ein.',
'1678298800':'Report-Muster',
'-654354054':'Der Umfang der verfügbaren Informationen variiert von Fahrzeug zu Fahrzeug',
'-1069736045':'Europäische <label classname="color-orange">Werbeportale</label>',
'1068440731':'Bis zu <label classname="color-orange">12 Register</label>',
'-534710218':'Herkunft und Alter des Fahrzeugs',
'1267127794':'Alle auf dieser Seite aufgeführten Prüfungen können für das angegebene Fahrzeug anhand des VIN-Codes durchgeführt werden. Die Ergebnisse der Kontrollen werden in dem bezahlten Bericht verfügbar sein. Der Umfang der im Paid Report enthaltenen Informationen kann für jedes Fahrzeug variieren.',
'-186613120':'Das Fahrzeug wurde in der Tschechischen Republik nicht als Taxi betrieben',
'1880784559':'Verfügbar durch Eingabe des Fahrzeugkennzeichens',
'2085931506':'<label classname="color-orange">Steht zur Verfügung</label>',
'-1765351165':'mehr als <label classname="color-orange">200 Millionen Datensätze</label>',
'1815202337':'Daten aus <label classname="color-orange">EU und USA</label>',
'-555727222':'Daten aus <label classname="color-orange">18 Ländern</label>',
'-1798142062':'Tabelle der Tachometer-Statusaufzeichnungen',
'-1828887347':'VIN (Fahrzeug-Identifikationsnummer):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Sie können nach Taxis in der Slowakei suchen, indem Sie das Kennzeichen des Fahrzeugs auf <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'VIN-Code jetzt prüfen',
'-1620583517':'Geschichte',
'239060530':'Geschichte der VIN-Überprüfung',
'1122350112':'Eine Übersicht über die Historie aller Abfragen zur zu prüfenden Fahrgestellnummer ab dem Datum der Couponaktivierung.',
'-1288575114':'Es ist nicht untersucht worden',
'-14589513':'Kunde',
'-1170545783':'Du hast es überprüft',
'452068758':'Vertragspartner Cebia',
'-1710315139':'Das Ergebnis der Überprüfung enthält die in der Fahrzeughistorie gefundenen Datensätze. Meistens handelt es sich dabei um Aufzeichnungen über den Kilometerstand, Fahrzeugschäden, technische Inspektionen, Fahrzeugfotos oder Fahrzeuginserate. Weitere Informationen finden Sie im <a href="/payed/detail/exampleDE" target="_blank">Beispiel für eine Überprüfung</a>.',
'1607430837':'Überblick über die Geschichte',
'-293598683':'Datum der Abmeldung',
'-1790772829':'Datum der Erstzulassung',
'743843679':'Zulassungsdatum',
'1370884754':'Zugelassen als',
'644449918':'Farbe des Fahrzeugs bei der Zulassung',
'-583571734':'Herstellungsdatum:',
'-1219924366':'Alter des Fahrzeugs',
'1595420674':'Ausstattungsliste ',
'1151043140':'Zeit zwischen Herstellungsdatum und Datum der ersten Registrierung',
'708774908':'Albanien',
'1637995084':'Nordmazedonien',
'1001888760':'Wirtschaftlicher Totalschaden *',
'1034299871':'Versicherungsfall - Wirtschaftlicher Totalschaden*',
'1209738229':'Versicherungsfall',
'913822268':'Hinweis',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praha 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'Mit dem Abschluss des Kaufs erkläre ich mich mit den <a href="https://www.cebia.cz/pruvodce?id=107&do=downloadFile" target="_blank" class="Link Link--lightBlue">Allgemeinen Geschäftsbedingungen</a> einverstanden und erkenne die <a href="https://www.cebia.cz/pruvodce?id=109&do=downloadFile" target="_blank" class="Link Link--lightBlue">Erklärung zum Schutz der Privatsphäre und personenbezogener Daten</a>.',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Datum der Erstzulassung, das bei der Zulassung des Fahrzeugs angegeben wurde',
'280331577':'Zeit zwischen Herstellungsdatum und Datum der ersten Zulassung des Fahrzeugs',
'1163480388':'Es gibt keine Einträge in den verfügbaren Datenbanken.',
'-1319845523':'Es liegt ein von der Versicherung oder der Werkstatt erstellter Kostenvoranschlag vor, dessen Details (Schadensort) uns nicht bekannt sind. Bitte achten Sie auf die Fahrzeuginspektion.',
'779148402':'Es liegt ein von der Versicherung gemeldeter Vorfall vor, dessen Details (Schadensumfang) uns nicht bekannt sind. Bitte achten Sie auf die Fahrzeuginspektion.',
'1115915387':'Kostenloser Versand',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Nicht verifiziert',
'-1464599260':'Arbeiten',
'-592361226':'Lackierung',
'1085310093':'Art der Inspektion:',
'1984850101':'Datum der Inspektion',
'-1502399543':'Protokoll ansehen',
'-1961590845':'Die Datenbank ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal.',
'-1065211891':'Protokoll nicht verfügbar',
'1708745339':'Protokoll nicht verfügbar',
'1087254174':'Lassen Sie Ihr Fahrzeug vollständig inspizieren',
'2096754301':'Grundlegende Fahrzeuginspektion: ',
'926095479':'Was bedeutet eine grundlegende Fahrzeuginspektion?',
'-254140743':'Die grundlegende Fahrzeuginspektion umfasst:',
'1362304971':'Für eine vollständige Überprüfung der Fahrzeughistorie empfehlen wir den Kauf eines Complete Vehicle Check, der auch eine <b>Schadensprüfung, Listing History, Fahrzeugfotos, Service History</b> und vieles mehr beinhalten kann.',
'-267677252':'Grundlegende Fahrzeuginspektion',
'-70564196':'- 10 EUR',
'1350105297':'Sind Sie mit dem Ergebnis der Fahrzeughistorienprüfung zufrieden? Wir freuen uns auf Ihre Bewertung.',
'1914051118':'Bewertungen',
'1786612140':'Es tut uns leid, dass die Überprüfung der Fahrzeughistorie nicht Ihren Erwartungen entsprochen hat. Bitte lassen Sie uns wissen, was wir beim nächsten Mal verbessern sollten.',
'-1265184280':'Upload fehlgeschlagen. Wiederholen Sie die Aktion.',
'-669850784':'Die maximale Anzahl der Zeichen beträgt 2000.',
'1801814710':'<strong classname="color-green">Danke! </strong> Ihre Bewertung wurde eingereicht und wird uns helfen, unseren Service weiter zu verbessern.',
'-883106932':'Daten aus mehr als <label classname="color-orange">32 Ländern</label>',
'1408314893':'Cebia sammelt Fahrzeugdaten aus mehr als 32 Ländern. Die Datensätze stammen meist aus amtlichen Registern, Werkstätten, Polizeidatenbanken, Datenbanken für beschädigte Fahrzeuge, Versicherungsgesellschaften oder Anzeigenportalen. ',
'-1412306493':'Ja. Jedes Fahrzeug wird mit Datenbanken aus mehr als 32 Ländern (darunter Deutschland, Dänemark und den USA) abgeglichen. Nach Eingabe des VIN-Codes können Sie im kostenlosen Bericht der gefundenen Informationen sehen, welche Einträge gefunden wurden und aus welchem Zeitraum. ',
'1798507708':'In diesem Fall fanden wir in unseren Datenbanken mit mehr als 200 Millionen Schadenseinträgen aus 32 europäischen Ländern und den USA keine Aufzeichnungen über Schäden an dem untersuchten Fahrzeug. Diese Tatsache erhöht die Wahrscheinlichkeit erheblich, dass das untersuchte Fahrzeug in der Vergangenheit nicht verunfallt ist. ',
'568601301':'Fahrzeugaufzeichnungen aus mehr als <strong>32 Ländern</strong>',
'899127599':'32 Länder',
'-1456368295':'Fahrzeuggeschichte aus über 32 Ländern',
'1333238000':'30 000 000+ geprüfte Fahrzeuge',
'426416063':'Daten aus 32 Ländern',
'-1549476292':'Cebia jedes Fahrzeug in der Datenbank für beschädigte Fahrzeuge mit mehr als 200 Millionen Datensätzen aus 32 Ländern prüft? Und dass die meisten Datensätze (62 %) aus Frankreich und Deutschland stammen?',
'-924882043':'In den 200 Millionen Schadensdatensätzen aus mehr als 32 Ländern wurden ein oder mehrere Datensätze mit Schäden an dem untersuchten Fahrzeug <span class="color-red">gefunden</span>.',
'-1366090835':'Es wurden <span class="light-green">keine Aufzeichnungen</span> über Schäden an dem untersuchten Fahrzeug in den 200 Millionen Schadensdatensätzen aus mehr als 32 Ländern gefunden.',
'931273814':'Historie des Fahrzeugs aus 32+ Ländern',
'-334327155':'Wir prüfen das Fahrzeug in den Datenbanken von beschädigten Fahrzeugen aus mehr als 32 Ländern mit über 200 Millionen Schadeneinträgen. Im kostenpflichtigen Bereich können Sie herausfinden, ob das Fahrzeug in diesen Datenbanken in seiner Historie als beschädigt eingetragen ist. Den gefundenen Schadensprotokollen können detaillierte Informationen wie das Datum des Vorfalls, der Kilometerstand, das Ausmaß des Schadens, der Schadensbetrag oder Fotos des Fahrzeugs beigefügt sein.',
'2078676994':'Dienstaufzeichnungen',
'503491296':'OK',
'1704037376':'Abbrechen',
'-922638497':'überprüft',
'-2077712313':'bis zu 12 Registern',
'-855779034':'Die Überprüfung ist bereits erfolgt',
'131879595':'Besichtigung <strong classname="ml-5 mr-5">gegen Gebühr </strong> laut Vertrag',
'-2034839108':'PDF',
'2049708688':'Ohne Einträge',
'1916268628':'Niederlande',
'-1425396841':'Über uns',
'-1521903902':'Protokoll-ID',
'1485582239':'Falsches E-Mail-Format.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Sie haben einen Rabattcode, der Sie zu einer kostenlosen Überprüfung  berechtigt. Verwenden Sie diese, indem Sie die Report ID in das Feld "Report ID eingeben" eingeben.',
'-675647872':'In all diesen Fällen geben Sie die Gutscheinnummer in das entsprechende Feld auf der Homepage <a href="https://de.cebia.com/">de.cebia.com</a> ein und haben sofort Zugriff auf die von Ihnen erworbenen Informationen, die Sie innerhalb von 30 Tagen jederzeit wieder aufrufen können.',
'1080548025':'WARNUNG: Dies kann eine <strong>falsche VIN</strong> sein. Bitte überprüfen Sie die VIN, falls zutreffend: <a href="mailto:rokvy@cebia.cz?subject=Informationsunterstützung des Systems - ATR-Identifizierung&amp;body=VIN: {0}">kontaktieren Sie den Support</a>.',
'-439637411':'WARNUNG: Cebia-Zertifikat kann nicht erstellt werden. Es wurde kein Herstellungsjahr gefunden.',
'-43065820':'Es wurden keine Aufzeichnungen über das Alter oder die Herkunft des Fahrzeugs gefunden. Wenn das Fahrzeug aus dem Ausland kommt, verlangen Sie immer die Original-Fahrzeugpapiere (z. B. Serviceheft oder Fahrzeugschein) und prüfen Sie diese sorgfältig.',
'-1362534546':'Wenn das Fahrzeug in der Vergangenheit inseriert oder besichtigt wurde, zeigen wir Informationen und Fotodokumente an.',
'431007496':'Der Rekord',
'-1525511285':'Die Daten aus der Zulassungsbescheinigung werden von den nationalen Behörden übernommen und spiegeln den Stand zum Zeitpunkt der Erstzulassung des Fahrzeugs im Land wider.',
'1151204976':'Die VIN (Vehicle Identification Number) ist ein einzigartiger 17-stelliger Code, der das Fahrzeug eindeutig und unverwechselbar identifiziert. Er wird direkt auf die Karosserie des Fahrzeugs eingestanzt, und alle Fahrzeugdaten werden unter diesem Code gespeichert. Weitere Informationen über den VIN-Code finden Sie <a href=\'/detailArticle/der-vin-code\'>hier</a>.',
'205732919':'Die VIN ist ein 17-stelliger Code, der das Fahrzeug eindeutig und unverwechselbar identifiziert.',
'1905716471':'*) Die Definition <strong>des wirtschaftlichen Totalschadens</strong> kann von einem EU-Land zum anderen, aber auch von einer Versicherungsgesellschaft zur anderen innerhalb desselben Landes variieren. Selbst wenn der Schaden auf diese Weise beurteilt wird, bedeutet dies nicht zwangsläufig, dass das Fahrzeug fahruntüchtig oder gar irreparabel ist. Ein wirtschaftlicher Totalschaden ist ein Schaden an einem Fahrzeug, bei dem nach den Versicherungsbedingungen die Reparatur des Fahrzeugs unwirtschaftlich ist (=der Betrag für die Reparatur des Fahrzeugs liegt nahe am oder über dem Wert des Fahrzeugs zum Zeitpunkt des Schadens) oder der nach den im Versicherungsvertrag festgelegten Bedingungen als solcher bewertet wird.',
'-199845800':'Die Anzahl der Zeichen muss 10 betragen.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'<a href="/detailArticle/der-vin-code" target="_blank">VIN</a> ist die Abkürzung des englischen <a href="/detailArticle/der-vin-code" target="_blank">Vehicled Identification Number</a> (Fahrzeug-Identifikationsnummer). Dabei handelt es sich um einen eindeutigen Code aus 17 Buchstaben und Zahlen, den die Hersteller jedem produzierten Fahrzeug zuordnen. Die VIN ist auf verschiedenen Teilen des Fahrzeugs oder in den Fahrzeugpapieren zu finden.',
'1682180910':'<p>In den verfügbaren Datenbanken haben wir keine Aufzeichnungen über Schäden am überprüften Fahrzeug. Die von Ihnen eingegebene VIN kann fehlerhaft sein.</p><p>Warnung: Das Car History-System hat möglicherweise nicht alle Informationen über Fahrzeugschäden zur Verfügung. Einige Schäden werden den Versicherungen nicht gemeldet oder sind im Rahmen der Online-Überprüfung nicht verfügbar.</p>',
'-1276466490':'Die angegebene VIN könnte falsch sein',
'-1892907546':'Für diese VIN können keine historischen Aufzeichnungen gefunden werden.',
'1843204209':'Weiter',
'-716345220':'Weitere VIN überprüfen',
'1091884080':'Wir empfehlen, die VIN zu überprüfen',
'-287497170':'Überprüfung von Pfandrechten',
'1289833880':'Pfandrecht festgestellt',
'466639297':'Kein Pfandrecht gefunden',
'-577812113':'Pfandnummer',
'231435384':'Die Überprüfung der Pfandrechte steht derzeit nicht zur Verfügung. Versuchen Sie die Seite nach einigen Minuten erneut zu laden.',
'-941220732':'Das Fahrzeug ist Gegenstand eines oder mehrerer Pfandrechte in den genannten Staaten und es besteht die Möglichkeit, es im Falle eines möglichen Vollstreckungsverfahrens der im Pfandregister genannten Person zu beschlagnahmen. Der Kauf eines Fahrzeugs mit Pfandrecht kann sehr riskant sein. Die Daten erhalten wir aus den offiziellen Pfandregistern des betreffenden Landes.',
'2016489332':'Das Ergebnis der Anfrage ist die Feststellung, ob das Fahrzeug nicht Gegenstand eines Pfandrechts in den genannten Staaten ist und daher keine Gefahr besteht, dass es im Falle eines möglichen Vollstreckungsverfahrens gegen den aktuellen Besitzer beschlagnahmt wird. Die Daten beziehen wir aus den offiziellen Pfandregistern des betreffenden Landes.',
'1736313716':'Přílohy k článku',
'1021804797':'Kostenlose Überprüfung im Rahmen des Vertrages',
'749816957':'Kann überprüft werden',
'981194702':'Kontrola exekucí',
'-1417536272':'Pfandrechtprüfung',
'-323399576':'Ja. Wenn Sie vermuten, dass der Report falsche Informationen enthält, wenden Sie sich bitte an unseren Kundendienst: <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Číslo smlouvy (je-li k dispozici)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Betrieben als Taxi im Jahr',
'-137639801':'Report ID (Beschwerden und Reklamationen)',
'-1619027976':'Hinweis: Das Fahrzeug wurde möglicherweise nicht die ganze Zeit als Taxi betrieben.',
'-1010997259':'Für Unternehmer',
'503941074':'Detailní informace a data o prověřovaném vozidlu budou dostupná po zaplacení služby.',
'1088246106':'U zadaného vozidla zkontrolujeme:',
'-963915529':'Poškození vozidla',
'-1603961223':'data z <label className="color-orange">26 společností</label>',
'-1940014640':'Technická data',
'1785662376':'Služby',
'1913348655':'Möchten Sie mehr Fahrzeuge zu einem niedrigeren Preis überprüfen? Holen Sie sich Zugang für Unternehmen und <b>sparen Sie bis zu 50 %</b> bei den Prüfkosten. Bitte geben Sie uns Ihre Kontaktdaten, und wir werden uns mit einem individuellen Angebot bei Ihnen melden.',
'1723999193':'Verfügbar nach Bezahlung',
'1593405846':'Pojištění vozidla',
'670508858':'Pro Einkauf kann nur ein Rabattcode verwendet werden. Rabattcodes können nicht addiert oder kombiniert werden. Der Rabatt gilt nur für Car History Report.',
'1693716169':'Zahlungsmethode',
'-503153747':'Car History Report',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'Rabatt von 50 % für den nächsten Report',
'-1870036897':'Sie haben bereits ein Fahrzeug besichtigt, und wenn Sie innerhalb von 30 Tagen nach dem Kauf Ihre Report ID (die Sie in der E-Mail erhalten haben) eingeben, erhalten Sie einen Rabatt auf Ihren nächsten Kauf.',
'163946327':'Beim Kauf einer Fahrzeugprüfung erhalten Sie einen Rabatt von 50 % auf den nächsten Kauf einer Fahrzeugprüfung (gilt nicht für den Preis der Prüfung von 199 CZK). Sie können den Rabatt in Anspruch nehmen, indem Sie bei Ihrem nächsten Einkauf den Verifizierungscode in das Feld für die Eingabe des Rabattcodes in der Rubrik "Zahlung" eingeben.',
'858336732':'50% Rabatt auf die nächste Fahrzeugprüfung',
'214251926':'Mit dem Kauf einer Zuverlässigkeitsüberprüfung erhalten Sie automatisch einen Rabatt auf die nächste Zuverlässigkeitsüberprüfung für ein anderes Fahrzeug. Der Rabatt gilt für 30 Tage ab dem Kaufdatum.',
'1317784962':'10% Rabatt auf die nächste Fahrzeugprüfung',
'641443144':'20% Rabatt auf die nächste Fahrzeugprüfung',
'-1318469146':'30% Rabatt auf die nächste Fahrzeugprüfung',
'1939643950':'40% Rabatt auf die nächste Fahrzeugprüfung',
'-1376459678':'Weiterer Fahrzeugprüfung <br> <b>mit Rabatt</b>',
'-937620931':'Fahrzeugdaten',
'514768619':'Wir überprüfen die Echtheit der VIN',
'-916328171':'Wir überprüfen die Identität des Fahrzeugs',
'1481465091':'Wir überprüfen die Fahrzeugausstattung',
'-276782700':'Wir durchsuchen Datenbanken mit beschädigten Fahrzeugen',
'1544438189':'Wir prüfen die Unterlagen der Versicherungsgesellschaft',
'2021669548':'Wir suchen die Geschichte der Auktionsportale',
'2022162632':'Wir überprüfen Aufzeichnungen von autorisierten Servicezentren',
'1083617557':'Wir überprüfen Aufzeichnungen von nicht autorisierten Servicezentren',
'993080480':'Wir suchen in den Datenbanken der Polizei nach gestohlenen Fahrzeugen',
'-1240510013':'Wir suchen in privaten Datenbanken nach gestohlenen Fahrzeugen',
'-1027936662':'Wir prüfen das Alter des Fahrzeugs',
'1299172359':'Wir überprüfen die Herkunft des Fahrzeugs',
'413685839':'Wir suchen elektronische Dienstbücher',
'-473666121':'Wir suchen in staatlichen Fahrzeugregistern',
'-254920359':'Wir durchsuchen staatliche Datenbanken für Taxifahrzeuge',
'-811021673':'Überprüfung der europäischen Einberufungsveranstaltungen',
'1923526539':'Wir überprüfen die Fahrzeugsicherheit',
'-1424413327':'Wir überprüfen die Datenbanken der Kilometeraufzeichnungen',
'734665361':'Wir prüfen Aufzeichnungen von Leasinggesellschaften',
'684288091':'Wir durchsuchen die Aufzeichnungen der technischen Prüfstellen',
'-1633964825':'Wir suchen nach Aufzeichnungen von Emissionsmessstationen',
'-354982402':'Wir suchen in der Geschichte der Werbeportale',
'255365524':'Wir durchsuchen die Datenbanken von Neuwagenhändlern',
'936372740':'Wir durchsuchen Datenbanken von Gebrauchtwagenhändlern',
'-1491634158':'Wir überprüfen die Aufzeichnungen der Autovermietung',
'-1425645764':'Der Vorgang kann einige Minuten dauern',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Wir erstellen einen Bericht',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Report teilen',
'-2142411619':'Um den Report weiterzugeben, kopieren Sie den unten stehenden Link und senden Sie ihn an alle Personen, denen Sie den Report zugänglich machen möchten.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Wir empfehlen',
'-1025377658':'Artikel',
'-909166524':'Artikel',
'-1998523642':'Artikel',
'-613016004':'Ermäßigte Angebote',
'1083497926':'Andere Dienstleistungen',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Weiterer Fahrzeugprüfung',
'-901212011':'für',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Wir prüfen die Fotos, Sie werden per E-Mail über die Durchführung informiert.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Warnung:</span> Informationen zu allen Fahrzeugschäden sind möglicherweise nicht im System verfügbar, einige Schäden werden nicht an die Versicherung gemeldet oder sind im Rahmen einer Online-Prüfung nicht verfügbar. Wir empfehlen daher eine sorgfältige Inspektion des Fahrzeugs, idealerweise mit Hilfe eines qualifizierten Mechanikers oder in einem Servicecenter Ihrer Wahl. Eine solche Inspektion kann zusätzliche Schäden aufdecken oder ausschließen.',
'-1943079155':'<span class="warning">Hinweis:</span> Sollten die Fotos nicht dem überprüften Fahrzeug entsprechen, informieren Sie uns bitte per E-Mail <a href="mailto:info.de@cebia.com">info.de@cebia.com</a>.',
'707486310':'<span class="warning">Hinweis:</span> Möglicherweise sind nicht alle Fahrzeugwartungsunterlagen im System verfügbar. Wir empfehlen, die Unterlagen mit dem Serviceheft zu vergleichen oder sich mit der Bitte um einen Auszug aus dem digitalen Serviceheft des Fahrzeugs an den entsprechenden autorisierten Service der Marke zu wenden.',
'-1997337578':'Die Übersicht über die Entwicklung des Kilometerstandes eines Fahrzeugs enthält Werte, die aus den verfügbaren Informationssystemen kooperierender Partner stammen. Anhand der Entwicklung der Grafik können Sie selbst beurteilen, ob ein Verdacht auf Manipulation des Kilometerstandes vorliegt oder nicht.',
'-282194273':'Empfehlung',
'-1564285314':'Möglicherweise sind nicht alle Kilometerstände aus der Betriebshistorie des Fahrzeugs im System verfügbar.',
'993789697':'Wenn das Fahrzeug nicht kontinuierlich den gesamten Kilometerstand im Laufe der Zeit aufzeichnet, <b>empfehlen</b> wir, auch die Kilometerzähleraufzeichnungen im Serviceheft zu überprüfen oder das Fahrzeug in einer Vertragswerkstatt überprüfen zu lassen. Wir empfehlen dieses Vorgehen insbesondere bei Fahrzeugen, bei denen zwischen den Tachostandsablesungen ein Zeitraum von mehr als zwei Jahren liegt.',
'2129711518':'Dabei handelt es sich um verfügbare Informationen zu den Grundparametern, der Serienausstattung und ggf. Zusatzausstattung des Fahrzeugs ab Werk. Fahrzeugmodifikationen wie z. B. ein Wechsel der Lenkradseite können durch die Überprüfung der Ausstattung erkannt werden.',
'-593531916':'<span class="warning">Warnung:</span> In manchen Fällen ist möglicherweise nicht die komplette Ausstattung des Fahrzeugs im System verfügbar.',
};

const TRANSLATIONS_RO = {
'1901555939':'VIN',
'-971921755':'Marca',
'1891129765':'Model',
'691501523':'Tipul caroseriei',
'-1026019663':'Tipul vehiculului',
'274952045':'Combustibil',
'-348535418':'Performanţă',
'1000727131':'Capacitatea motorului',
'-440181428':'Data fabricației',
'841597003':'Distribuiți',
'-762872471':'Afișați',
'-1615835216':'Link',
'-466013687':'Închideți',
'-1655267775':'Copierea linkului',
'-1448849529':'Este posibil să verificați mașina fără posedarea cunoștințelor despre VIN?',
'-1310016716':'Verificare vehicul',
'1410745983':'online',
'-1803530129':'Generați certificatul',
'934563996':'Rezultatul testului',
'-1102433484':'Exemplu de Raport',
'-1242381959':'Istoricul vehiculului',
'417677035':'Verificarea fiabilității vehiculului',
'-115937358':'Starea vehiculului',
'457834942':'Întrebări frecvente',
'226942208':'Ce este seria de șasiu (VIN)?',
'1739046820':'Cât costă inspecția?',
'16204687':'Cum pot plăti?',
'-1607701055':'Cum pot vedea rezultatul verificării?',
'-1969713941':'Se poate revendica rezultatul inspecției?',
'1598751038':'Unde pot găsi un Report ID?',
'-1206763576':'Mai multe informații',
'-729900752':'Plata online',
'671435147':'Introduceți codul de reducere',
'-2004947080':'Introduceți codul de reducere',
'-1083354004':'RECALCUL',
'-1589008349':'Nume',
'-704955254':'Nume de familie',
'-1631818401':'Strada și numărul',
'1006101298':'Orașul',
'1063186093':'Țara',
'287700219':'COD POȘTAL',
'-1411201334':'Numărul de identificare fiscală',
'513366821':'CIF',
'-102071178':'Numele companiei',
'-1882309597':'Numărul de identificare al companiei',
'-1515081676':'CUI',
'1440993804':'E-mail-ul de contact',
'-1405758046':'Telefon',
'1366947192':'Numărul de telefon',
'-490851561':'20% reducere pentru inspecția ulterioară',
'-108743580':'lei',
'786987358':'Achiziționați',
'-1887658751':'Car History Report este disponibil imediat după achitare',
'-1018049108':'Raportul este de obicei disponibil în maxim 60 de minute',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Transfer bancar',
'-1663277':'Raportul va fi disponibil în maxim 48 de ore',
'1626540224':'În numerar la sediul Cebia',
'72162446':'Verificare autovehicul',
'757687806':'Prezentare generală gratuită a informațiilor',
'1954205527':'Unde pot găsi seria de șasiu (VIN)?',
'1603459473':'Am Report ID ',
'2038366987':'Vizualizează Report',
'-374549211':'Unde pot găsi un Report ID?',
'1262814082':'Introducerea VIN-ul',
'-15600132':'Data primei înmatriculări',
'1535680605':'Report ID:',
'-1091217450':'Valabil până la:',
'-1264594150':'Data achiziției raportului:',
'-701611594':'Ne pare rău, din păcate, vehiculul nu poate fi verificat.',
'160166931':'Actualul proprietar al vehiculului nu dorește să vadă detaliile vehiculului.',
'-351255422':'VIN-ul vehiculului nu este specificat în anunțul pe care îl vizualizați.',
'1704462566':'În anunțul vehiculului nu este menționat codul VIN complet.',
'366367808':'Cât durează până primesc Car History Report?',
'2136729030':'VIN:',
'400669011':'Aveți dreptul la o reducere dacă:',
'-219050562':'Blog',
'1449193326':'Despre noi ',
'1097675154':'Contactați-ne',
'2062322678':'Știri',
'-1263831198':'Istoria companiei',
'1166836894':'Partenerii noștri',
'-1532347183':'Pentru mass-media',
'717698781':'Cariere',
'1620687461':'Certificatul nu poate fi creat. Mașina este înregistrată drept furată.',
'1365729306':'Numarul certificatului:',
'-89669431':'Anul primei înregistrări:',
'2130038984':'Starea odometrului:',
'2089870291':'Mașina sub control cu aplicația Carolina',
'1333652846':'Descărcare gratis',
'1347577444':'Codul VIN trebuie să conțină 17 caractere',
'622759132':'Linkul este copiat',
'1714373031':'Pt fi aferente mașinii inspectate',
'1227286459':'Notificări de rechemare găsite',
'-477335598':'Notificări de rechemare',
'-343698081':'Nu au fost găsite notificări de rechemare care',
'-1536285860':'vizează mașina inspectată',
'-2052297753':'Data publicării',
'1522685090':'Detalii',
'444715065':'Mai multe informații',
'-394230090':'Trebuie să fiți de acord cu datele introduse.',
'1224828864':'Valoare incorectă pentru anul primei înmatriculări.',
'-470093943':'Introduceți anul primei înregistrări.',
'1035831822':'Stare incorectă a tahometrului.',
'-1161922611':'Vă rugăm să introduceți starea vitezometrului.',
'-1383225585':'Introduceți anul primei înregistrări',
'1111232857':'Anul primei înmatriculări',
'-1998715395':'Introduceți starea tahometrului',
'-1962337257':'Starea odometrului',
'-1888253249':'Precizarea modelului',
'-339477539':'- 300 CZK',
'917746804':'Nici nu poate fi mai simplu:',
'1405508217':'Consultație preliminară GRATUITĂ',
'127414665':'Cum pot beneficia de reducere?',
'-2081078087':'ALEGEȚI TEHNICIANUL',
'1899967331':'Numărul maxim de caractere este de 20.',
'1699452695':'Cod bancar eronat',
'1812875983':'Introduceți numărul de cont.',
'-670727524':'Introduceți numărul poliței.',
'1884544037':'ePojisteni.cz',
'138266606':'Valabil până la',
'1881823014':'Politica de rambursare:',
'-1548724023':'Vehiculul trebuie să fie asigurat cel puțin 30 de zile',
'1331666705':'Eroare de salvare. Repetați acțiunea.',
'-1393210532':'Numărul contului',
'439576396':'Numărul contului dumneavoastră',
'773379637':'Numărul contractului de asigurare',
'-1281605763':'Solicitați o rambursare',
'-1670752753':'Reducere la inspecția efectuată în străinătate',
'1496813111':'- 550',
'-856872708':'Prețul de bază',
'616158592':'REDUCERE',
'-581170701':'Prețul după reducere',
'-437309148':'cu TVA',
'-1731075532':'Video',
'-189892310':'Comandați',
'98918066':'Procedura de comandă:',
'-1944610133':'Reducere la următoarea inspecție',
'-621660650':'Cod de reducere:',
'-1845841489':'Inspecția unei alte mașini la reducere',
'1171588656':'Reducere pentru securitatea vehiculului',
'1440663595':'- 850',
'-1503110723':'Reducere la examinarea fizică',
'-675817237':'- 925',
'986037818':'S-au găsit daune',
'873964955':'Data',
'790922325':'Calculul costurilor de reparație',
'-1825735064':'Tipul incidentului',
'486919475':'Locurile de pagubă înregistrate',
'1685506455':'Vehiculul este finanțat',
'2039551191':'Finanțarea nu a fost găsită',
'-570695392':'în bazele de date disponibile actualmente',
'-765744228':'Reclamă',
'659651744':'Data plasării anunțului',
'-695638522':'Kilometraj parcurs indicat în anunț',
'772986188':'Prețul indicat în anunț',
'419477574':'Înregistrări cu privire la vechimea și originea vehiculului',
'-109775782':'Data fabricației conform producătorului',
'-218677523':'Prima înmatriculare',
'-340115089':'Prima înmatriculare în Cehia',
'1192563560':'Prima înmatriculare în Slovacia',
'1650211924':'Fabricat pentru piața',
'1812132824':'Postul de conducere',
'-1229728494':'Material (enumerare)',
'-1147664160':'Operațiuni efectuate (enumerare)',
'708828581':'Folosit ca taxi',
'284182756':'Mașina este înregistrată drept furată',
'1244175337':'Nu este înregistrat drept furat',
'791649880':'Sursa',
'705052471':'La zi:',
'1239149764':'Verificarea identificatorilor',
'-799257304':'Model:',
'-1964821919':'Data primei înmatriculări:',
'-1530361098':'Prețul unui vehicul nou',
'68348546':'Prețul actual de piață',
'763547310':'Data primei înmatriculări',
'1968050624':'Salvați',
'-1300982647':'Lista elementelor echipamentelor conform producătorului',
'397739850':'Verificarea culorii vehiculului',
'479427038':'Este disponibil',
'2038192723':'Verificarea securității vehiculului',
'-757741002':'Descrierea tehnică a vehiculului',
'278755414':'Instrucțiuni pentru identificarea vehiculului',
'1529692191':'Culoarea mașinii',
'-1171491320':'Identificarea vehiculului',
'1549116932':'Lista elementelor echipamentului vehiculului',
'-1985480381':'Altele',
'-1354462940':'VERIFICAȚI:',
'-690036295':' Data înregistrării:',
'1506895356':'Tipul de securitate al vehiculului:',
'-749715579':'Codul marcajului:',
'-329154043':'Culoarea mașinii:',
'1441236976':'Informații despre efectuarea marcajului geamurilor',
'729768522':'Marcajul geamurilor',
'1812345820':'NU',
'847948448':'VĂ INTERESEAZĂ SECURITATEA VEHICULUI?',
'-1899070354':'Descrierea tehnică de bază',
'-953303':'Descrierea tehnică în detaliu',
'1237026086':'Valoare incorectă Tipul de vehicul.',
'-259360107':'Introduceți tipul de vehicul.',
'-1906046398':'Numărul minim de caractere trebuie să fie 1.',
'-69524395':'Numărul maxim de caractere este de 30.',
'1663292305':'Tipul de marcă.',
'814923836':'Introduceți modelul.',
'-1985695287':'Vă rugăm să introduceți detaliile destinației.',
'244480988':'Marca',
'1602844398':'Determinare detaliată',
'-1749998805':'Selectați un vehicul',
'-1162182506':'Tipul vehiculului',
'1104651533':'cartea de identitate a vehiculului - codul VIN',
'-1898506589':'parbriz auto - codul VIN',
'216861745':'Vă rugăm să reconciliați datele introduse',
'-1328097963':'Numărul maxim de caractere este de 9.',
'1678639740':'Introduceți telefonul.',
'1303052228':'Introduceți numele.',
'1792286505':'Numărul maxim de caractere este de 128.',
'2122707327':'Introduceți un nume de familie.',
'-938825602':'Numărul maxim de caractere este de 64.',
'1683031395':'Introduceți o stradă și un număr.',
'661065202':'Introduceți orașul.',
'-83271898':'Introduceți un cod poștal.',
'1018067916':'Numărul maxim de caractere este de 5.',
'12673416':'Codul poștal are un format greșit.',
'154576238':'Report ID are un format greșit.',
'-581020701':'este declarată obligatorie de către producătorul vehiculului ori de câte ori sănătatea, siguranța și mediul sunt puse în pericol. Ele presupun de obicei defecte de fabricație ale vehiculului, care devin evidente doar în timpul funcționării acestuia, iar producătorul le asigură astfel gratuit. Informațiile provin de pe site-ul <a href="https://car-recalls.eu/" target="_blank">Car-recalls.eu</a> cu ajutorul sistemului RAPEX.',
'1969671182':'Aceste notificări de rechemare e au fost generate în funcție de modelul, marca și anul de fabricație al vehiculului inspectat, nu în funcție de codul VIN al acestuia. Prin urmare, verificați notificările de fiecare dată cu atenție dacă aceste au vizat și vehiculul inspectat. În același timp, verificați cu dealer-ul de vehicule sau cu un atelier de reparații auto autorizat dacă vehiculul a fost supus notificărilor de recemeare valabile.',
'-2076502822':'Notificările de rechemare se anunță de către producătorul vehiculului în mod obligatoriu prin lege în cazul în care vehiculul prezintă un defect de fabricație care pune in pericol sanatatea, siguranța sau mediul înconjurător. În cadrul notificării de rechemare, defecțiunea din serviciul autorizat este remediată gratuit. Datele sunt furnizate de portalul <a href="https://car-recalls.eu/" target="_blank">Car-recalls.eu</a> cu ajutorul sistemului RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Încheiați asigurarea prin portalul <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> și primiți banii înapoi pentru inspecția vehiculului.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Puteți accesa comanda online PROVIN prin click pe butonul Comanda. Completați această comandă, iar în note vă rugăm să introduceți parola: AUTOTRACER și Report ID.',
'5180415':'Prin intermediul butonului Comanda puteți ajunge la comanda online a serviciului VINTEST. Completați această comandă și vă rugăm să introduceți următoarea parolă în note: AUTOTRACER și Report ID.',
'-312241960':'* Bazele de date private de obicei permit oricui să introducă informații despre furt.',
'1225691915':'În bazele de date disponibile s-a constatat că vehiculul era folosit sau încă este <span class="warning">folosit ca taxi</span>. Verificați cu atenție starea tehnică a vehiculului.',
'-742868638':'Adresa ta de e-mail',
'2033866416':'Cardul de plată online',
'138706621':'Transfer online',
'-1634274740':'Vizualizați certificatul',
'1047356790':'Introduceți data primei înregistrări.',
'-39394372':'Anunțurile publicitare pentru mașini care corespund mai puțin filtrelor de căutare',
'-862383013':'Anunțurile publicitare pentru mașini similare',
'-233652021':'Istoricul vehiculului',
'271847434':'Certificatul Car History',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'Při objednání kontroly vozu na Automato.cz vložte do určeného pole v košíku slevový kód <b>AUTOTRACER</b> a sleva vám bude automaticky odečtena z ceny.',
'-1554923364':'(tehnician de călătorie gratuit)',
'288042578':'Introduceți numele companiei.',
'-1335200216':'Valoare CUI incorectă.',
'1693391088':'Formatul de e-mail este incorect!',
'-766469985':'Vă rugăm să introduceți e-mail',
'-1397806656':'Consimțământul pentru prelucrarea datelor cu caracter personal',
'-189484387':'Abonare',
'1095553640':'Toate întrebările',
'1531150438':'Nu ați găsit răspunsul? ',
'-469136467':'Contact',
'-461584700':'Înainte de achiziție',
'400498991':'Principiile de utilizare a cookie-urilor',
'1329764956':'Ce este seria de șasiu (VIN)?',
'-394767958':'Achiziție',
'181431424':'După efectuarea plății ',
'1649241229':'Codul VIN este ștanțat direct pe caroseria vehiculului, sau îl găsiți în pașaportul tehnic și alte documente auto. Dacă doriți să verificați mașina plasată în cadrul unui anunț pe Internet, cereți dealerului codul VIN. Pentru mai multe detalii despre cum puteți găsi codul VIN, accesați <a href=\'/detailArticle/unde-pot-gasi-numarul-vin\'>acest link</a>.',
'1774694482':'De unde provin înregistrările vehiculului?',
'-1122721284':'Cebia inspectează vehiculele în străinătate?',
'437878122':'Ce informații despre vehicul voi afla?',
'850725814':'Informațiile despre fiecare vehicul variază în funcție de vechimea și istoricul acestuia. Puteți verifica gratuit amploarea informațiilor despre vehiculul inspectat imediat după introducerea codului VIN în prezentarea generală a informațiilor găsite.',
'-656971513':'Cum arată rezultatul inspecției?',
'966688141':'Costul unei inspecții a vehiculului depinde de cantitatea și amploarea informațiilor disponibile despre vehiculul inspectat. Puteți verifica gratuit amploarea informațiilor despre vehiculul inspectat imediat după introducerea codului VIN în prezentarea generală a informațiilor găsite.',
'764893782':'Puteți plăti online cu cardul de credit sau prin Google Pay și Apple Pay. Plățile sunt furnizate de portalul de plată securizat GoPay.',
'726713260':'În cât timp pot obține rezultatul inspecției?',
'1478989945':'Rezultatul verificării este generat imediat după primirea plății. Serviciul funcționează non-stop și întregul proces este automat. ',
'1681038954':'Este posibilă introducerea datelor companiei în timpul plății online?',
'696259005':'Puteți introduce datele de identificare ale companiei dumneavoastră când completați datele dumneavoastră. Cu toate acestea, după efectuarea plății se va genera doar un document fiscal. Pentru a trimite o factură, contactați-ne prin e-mail la adresa <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a>',
'-1555365601':'Rezultatul verificării este generat automat imediat după recepționarea plății. Veți primi un link pe adresa de e-mail furnizată în timpul achiziției care vă va direcționa către rezultatul inspecției și numărul cuponului. Rezultatul inspecției va fi disponibil timp de 30 de zile după generare.',
'1984400663':'Plata a eșuat, ce pot face acum?',
'-956265022':'Dacă plata a eșuat din anumite motive, dați click înapoi și efectuați plata din nou. Dacă banii au fost deduși din contul dumneavoastră, dar încă nu ați primit un e-mail despre efectuarea plății, contactați-ne prin e-mail la adresa <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a>.',
'-246543694':'Rezultatul scanării este disponibil timp de 30 de zile după generare. Îl puteți vizualiza folosind link-ul trimis la adresa de e-mail furnizată la achiziționarea inspecției.',
'592240026':'Nu am primit un niciun e-mail, cum pot proceda în acest caz?',
'339130199':'E-mailul este generat automat după efectuarea plății. Dacă nu a ajuns în căsuța dumneavoastră de e-mail, contactați-ne prin e-mail la adresa <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a>. În mesaj, vă rugăm să includeți Report ID pe care l-ați găsit în rezultatul screening-ului sau datele dumneavoastră de contact.',
'1681612939':'Am introdus e-mailul greșit, cum pot proceda în acest caz?',
'-818807180':'Nicio problemă. Vă rugăm să ne scrieți adresa corectă la <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a> și să precizați Report ID menționat în rezultatul inspecției în raport. Vă vom trimite apoi linkul de acces la adresa de e-mail corectă.',
'-40057886':'Rezultatul verificării poate fi revendicat. Dacă bănuiți că în rezultatul inspecției sunt furnizate informații incorecte, vă rugăm să contactați Departamentul nostru lienți la adresa <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a>. Puteți găsi mai multe informații despre reclamație <a href=\'/about#complaints\'>aici</a>.',
'-755697752':'Pot emite o factură?',
'1950759546':'Din cauza costului verificării, după efectuarea plății se va genera automat doar un document fiscal simplificat. Pentru a emite o factură, contactați-ne la adresa <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a>. Pentru ca noi să vă putem oferi servicii în timp util, vă rugăm să includeți și Report ID furnizat în e-mailul pe care îl primiți după achiziție.',
'-1960272511':'Introduceți VIN-ul',
'-371538970':'Unde pot găsi seria de șasiu (VIN)?',
'-1956291670':'Cum funcționează?',
'1850076653':'Pasul 1',
'-1260091754':'Date disponibile',
'1279291471':'Pasul 2',
'-811487544':'Plată',
'777550298':'Pasul 3',
'-1534039143':'Car History Report',
'495126992':'Pasul 4',
'1916048998':'Verificați',
'-580693003':'Detalii de contact',
'-760821457':'Aveți nevoie de asistență? ',
'1432374431':'Formular de contact',
'1838884148':'Recepție Cebia',
'1665302400':'Sediul companiei',
'1196114687':'Cehia',
'-95958777':'Date de facturare',
'1808758025':'Telefon',
'275235071':'Suntem la dispoziția dumneavoastră',
'353260003':'Luni - Vineri 8:00 - 16:30',
'822817400':'Verificați istoricul vehiculului',
'-1317542970':'Verificat de CLIENȚI',
'-1884218466':'Verificăm fiecare mașină după următoarele criterii:',
'1736624969':'Kilometraj parcurs',
'2137107000':'Istoric daune',
'647263705':'Auto furate',
'-441819024':'Istoric service',
'982206269':'Fotografii istorice',
'1753341741':'Utilizare',
'-366535509':'Profesioniștii apelează la serviciile Cebia',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'Ne cunoașteți de pe...',
'654946563':'caractere',
'-1398891951':'Verificare kilometraj',
'1425691903':'Datele odometrului au fost date înapoi în cazul a tocmai 33% dintre mașinile second hand de pe piața!',
'1803799674':'Verificarea vechimii și originii',
'1389120536':'În cazul mașinilor second hand din străinătate, vechimea mașinii și proveniența sa este adesea falsificată!',
'-2068933994':'Controlul furturilor',
'-1184837319':'Verificarea finanțării',
'-1877453535':'Verificarea daunelor',
'64494271':'20% din mașinile second hand din străinătate au fost avariate!',
'1051433840':'Verificați dacă mașina nu a fost identificată drept avariată!',
'1076835371':'Evaluarea vehiculului',
'-1805177459':'Notificări de rechemare',
'957124168':'Numărul de proprietari din Republica Cehă',
'-1705145830':'Informații despre vehicul',
'1890293527':'Verificare utilizare',
'-13439485':'Vă rugăm să introduceți numele',
'-1147931879':'Vă rugăm să introduceți numele dvs. de familie',
'2144560301':'Valoare incorectă Telefon',
'-1642977586':'Vă rugăm să introduceți Telefon',
'-1249914076':'E-mailul nu are formatul corect',
'-2038223065':'Vă rugăm să introduceți un mesaj',
'810453022':'E-mail',
'-1217737116':'Mesaj',
'-664538889':'Sunt de acord cu procesarea',
'1322823680':'Trimiteți un mesaj',
'56891982':'Numărul maxim de caractere este de 16.',
'277834303':'Vă mulțumim',
'552239414':'Mesajul a fost trimis cu succes. ',
'1851370681':'Vom lua legătura în curând.',
'1952981238':'Avertizare',
'342579190':'Preț raport complet',
'1586626737':'Obţine raportul',
'399846737':'Încărcarea înregistrărilor',
'556543048':'Următoarele informații sunt verificabile pentru vehiculul în cauză:',
'560876430':'Fotografii și anunțuri publicitare',
'-320410471':'Nu este disponibil',
'1185850210':'Nu s-au găsit înregistrări cu privire la daune',
'-903608283':'Extras din istoricul vehiculului',
'718416430':'Informații despre Report',
'668096075':'Report ID:',
'-1690204814':'Valabil până la:',
'-840281270':'Cutia de viteze',
'-1901926256':'Datele de bază ale vehiculului',
'-1550051512':'Tipul caroseriei',
'-1043441574':'AVERTIZARE:',
'-709435856':'Experiența clienților',
'-1149927862':'Aflați feedbackul clienților în legătură cu verificarea noastră',
'456577623':'Sistemul dumneavoastră a găsit o mașină accidentată cu kilometraj modificat. Vă mulțumesc.',
'329190632':'Serviciul a corespuns așteptărilor, a găsit daune la vehicul. Vă mulțumesc.',
'1450297818':'Verificarea în registrul vehiculelor furturilor în Cehia și Slovacia',
'-1594408882':'Reducere la inspecția mașinii de către tehnicianul independent Autobezobav.cz',
'341709606':'Doriți să vă asigurați că mașina corespunde cerințelor tehnice? Apelați la un tehnician calificat de Dealer Auto care va inspecta mașina în fața dumneavoastră direct la punctul de vânzare și vă va îndruma dacă este o achiziție bună sau mai degrabă nu.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Asigurarea obligatorie de răspundere civilă sau de accident trebuie întocmită folosind portalul ePojisteni.cz în termen de 30 de zile de la plata inspecției vehiculului)',
'-355614707':'Oferta este valabilă în cazul asigurării auto, vehiculelor comerciale și camioanelor (nu este valabilă în cazul asigurărilor de motociclete, ATV-uri, triciclete sau remorci și semiremorci)',
'-134420730':'Nu se aplică în cazul asigurărilor încheiate prin intermediul portalului ePojisteni.cz înainte de achiziționarea inspecției vehiculului.',
'1195365944':'Dupa încheierea asigurării, completați numărul contului și numărul contractului de asigurare încheiat, iar suma va fi transferată automat în contul specificat de dumneavoastră în cerere după îndeplinirea condițiilor, dar nu mai tarziu de 60 de zile calendaristice de la transmiterea solicitării.',
'-1622287889':'Vă mulțumim, solicitarea dumneavoastră a fost trimisă. Personalul nostru vă va contacta cel târziu în a doua zi lucrătoare.',
'1069770504':'Dacă vehiculul pe care l-ați selectat este importat individual în România, vă recomandăm să utilizați și serviciul de verificare a originii vehiculului PROVIN în străinătate.',
'-121060108':'Vom verifica înmatricularea vehiculului în țara de origine, dacă acesta a fost radiat corespunzător, dacă vehiculul nu a fost scos din uz din cauza avarierii grave, dacă vehiculul nu este înscris în țara respectivă drept furat, ș.a.',
'-1158490787':'Reducerea poate fi aplicată în termen de 30 de zile de la achiziționarea inspecției vehiculului și nu poate fi cumulată cu alte reduceri.',
'-1471409910':'Doriți să vă asigurați că vehiculul selectat nu este remodelat ilegal? Vă recomandăm să utilizați serviciul de verificare completă a autenticității identificatorilor de vehicule VINTEST.',
'1555104762':'Serviciul VINTEST oferă clienților o garanție sută la sută a rezultatului inspecției fizice a vehiculului. Vom efectua o examinare fizică detaliată a vehiculului cu ajutorul celor mai recente instrumente de diagnostic digital. Scopul acestui serviciu constă în verificarea originalității identificatoarelor (VIN, etichete, sudură de caroserie) și nu există nicio suspiciune că vehiculul sau o parte din vehicul provine dintr-o infracțiune.',
'735500953':'Rezultatul solicitării constă în verificarea dacp vehiculul nu este obiectul finanțării (sub formă de leasing sau credit), garanții și împrumut de la companiile enumerate. Datele provin din sistemele informatice ale companiilor individuale.',
'-1173444543':'Societăți de leasing și credit',
'-976513658':'* Carsharing = închiriere auto pe termen scurt direct de la proprietar (sau car sharing între proprietarul acestuia și persoana interesată de un împrumut pe termen scurt).',
'-1479754603':'În bazele de date disponibile s-a constatat că vehiculul nu a fost și nu este folosit în prezent ca taxi.',
'-467405909':'Înregistrări privind folosirea vehiculului ca taxi',
'1930957191':'Datele comunicate au caracter informativ. Cebia nu este responsabilă de daunele suferite în urma utilizării datelor distribuite.',
'-1895346741':'Vă vom informa în legătură cu prețul obișnuit de piață al vehiculului, cu ajutorul căruia veți verifica dacă prețul autoturismului evaluat corespunde prețului de ofertă din anunțul publicitar. Calculul prețului include, printre altele, monitorizarea anunțurile publicitare ale vehiculelor second hand, inclusiv monitorizarea prețurilor vânzărilor efective. Algoritmul de evaluare funcționează cu modele de calcul bazate pe monitorizarea pieței pe termen lung, dar nu poate ține cont de starea fizică a vehiculului.',
'-985858180':'În cazuri rare, prețul vehiculului poate să nu fie determinat din cauza datelor insuficiente pentru evaluarea calificată a vehiculului.',
'1915347014':'Vom afișa anunțuri cu mașini similare disponibile în prezent pe piață și, uneori, și prețurile mașinilor similare vândute efectiv (cu ajutorul codului VIN comparăm specificațiile exacte ale mașinilor). Prin intermediul  anunțurilor publicitare pentru vehiculele similare, puteți compara dacă prețul vehiculului verificat corespunde vehiculelor similare de pe piață. Astfel veți economisi timp, nefiind necesară căutarea și compararea anunțurilor similare pe web.',
'-767763886':'Nu a fost găsită nicio înregistrare în legătură cu publicarea anunțurilor despre vehicule.',
'797541609':'Verificați starea și kilometrajul mașinii din anunțurile publicitare plasate în trecut. Dacă în trecut odometrul indica o valoare mai mare decât în prezent, e posibil ca datele odometrului să fi fost date înapoi.',
'-1902352540':'Reconcilierea datelor introduse. Odată salvate, acestea nu mai pot fi schimbate.',
'-2008975653':'Nu s-au găsit înregistrări cu privire la culoarea vehiculului înmatriculat de producătorul vehiculului sau în timpul primei înmatriculări a vehiculului.',
'7150984':'Verificați dacă culoarea actuală a vehiculului se potrivește cu culoarea declarată de producător. Dacă culoarea este diferită, vehiculul poate fi revopsit, dar și prăbușit sau furat.',
'-1664139465':'Lista elementelor echipamentului vehiculului nu este disponibilă. Vă recomandăm să verificați funcționalitatea și originalitatea echipamentului direct în vehicul.',
'1681595153':'Rezultatul inspecției constă în informații dacă vehiculul inspectat este securizat sub forma marcajelor pe sticlă și înregistrat în Sistemul OCIS. Dacă da, vă vom informa în privința codului marcat și a dății de înregistrare.',
'571758489':'Marcajul geamurilor vehiculului a fost efectuat în trecut și este înregistrat în baza de date internațională OCIS.',
'855264535':'Dacă pe unele sau pe toate geamurile vehiculului lipsește codul unic, vehiculul poate fi avariat, reparat sau furat.',
'388233970':'Informații despre efectuarea marcajului geamurilor:',
'1615022749':'Nu am putut găsi parametrii tehnici ai vehiculului. Îi puteți găsi în cartea de identitate a vehiculului sau le puteți solicita de la producătorul vehiculului.',
'704641294':'După ce ați introdus selecția vehiculului, Car History vă va oferi Ghidul de verificare al identificatorului de bază. Instrucțiunile se aplică în general mărcii și modelului specificate de vehicul. Un vehicul selectat aleatoriu a fost selectat drept model.',
'-621659477':'Selecția vehiculului poate fi deja parțial precompletată, pe baza codului VIN introdus. Dacă selecția precompletată a vehiculului nu se potrivește cu vehiculul pe care îl verificați, modificați selecția manual.',
'152333450':'Nu au fost găsite înregistrări de daune, ce înseamnă asta?',
'780709713':'Cât timp va fi disponibil rezultatul inspecției?',
'830704335':'Aflați ce informații despre mașina dumneavoastră sunt disponibile',
'1850331238':'Serviciu excelent, merită să-l achiziționați. Am constatat modificări ale kilometrajului chiar și la o mașină de test drive.',
'1705245752':'Am vrut să cumpăr o mașină despre care vânzătorul mi-a spus că nu a avut accidente. Aceasta a avut de fapt daune multiple.',
'739619867':'Lucru absolut perfect, nu am cumpărat o mașină lovită pentru o mulțime de bani, mulțumesc!',
'-2071079400':'Am ales o mașină pentru care nu era menționat că a suferit două accidente cu daune mari și aici am descoperit acest lucru înainte de a cumpăra. Vă mulțumesc.',
'21202350':'Vă mulțumim pentru acest program. Aproape că pierdeam 90 de mii de euro pe o mașină. Mașina ar fi fost ușor lovită în partea din față, potrivit proprietarului. Conform aplicației, a fost accidentată de două ori cu daune majore.',
'1343185270':'Verificarea m-a liniștit și m-a ajutat să iau o decizie de cumpărare.',
'2083029914':'Cebia a detectat daune importante pe care vânzătorul nu mi le-a menționat.',
'1933958141':'Coliziune minoră detectată + verificarea kilometrajului de la data înmatriculării.',
'1900495866':'Mulțumită aplicației, am aflat că era ceva în neregulă cu mașina. Desigur, dealerul nu mi-a spus nimic despre asta, iar vânzătorul a tăcut și el. Vă mulțumesc.',
'-1668804606':'În timpul inspecției, am găsit deficiențe grave pe care vânzătorul mi le-a ascuns, cum ar fi kilometraj modificat, daune din accidente.',
'-273876487':'Am aflat că vehiculul avea kilometrajul modificat. Vânzătorul declara un rulaj de 155.000 km și în realitate autovehiculul avea un parcurs de peste 250.000 km...  ',
'911857027':'Introduceți Report ID ca să accesați secțiunea plătită a rezultatului',
'1167593586':'Puteți obține Report ID la achiziționarea Car History Report.',
'-2136315768':'Dacă efectuați plata cu cardul de credit, GooglePay sau ApplePay, raportul va fi afișat imediat după efectuarea plății și veți obține un link către acesta la adresa de e-mail.',
'868918064':'Dacă efectuați plata prin transfer bancar, veți primi prin e-mail linkul raportului imediat după ce fondurile au fost recepționate în contul bancar Cebia.',
'1572179326':'Dacă efectuați plata în numerar la sediul social Cebia, veți primi un Report tipărit.',
'-1322783148':'Amplasarea VIN-ului diferă în funcție de marca și modelul vehicului. Îl vei găsi întotdeauna în documentația tehnică și deseori sub parbriz.',
'700484420':'VIN este CNP-ul unic al fiecărei mașini pe care îl puteți găsi în documentele mașinii sau chiar pe caroseria mașinii.',
'-851712281':'Vom urmări toate înregistrările disponibile pentru mașina inspectată și vă vom arăta toată gama de informații.',
'-1983041378':'Puteți achita prin intermediul portalului de plată securizat GoPay prin card sau transfer bancar.',
'-1809684191':'Car History Report este disponibil imediat după achitare (în cazul achitării online). Desigur, îl vom trimite și pe e-mail.',
'541894049':'Veți afla valorile stării odometrului în perioada selectată. Într-un grafic simplu, puteți vedea cum a evoluat starea odometrului în timp și puteți depista eventualele situații în care datele odometrului au fost date înapoi. Atenție: datele odometrului au fost date înapoi în peste 40% din cazuri!',
'643692561':'Veți vizualiza informații despre daunele înregistrate ale vehiculului. Informațiile detaliate presupun valoarea daunelor și amploarea daunelor. Atenție: nu uitați să verificați în același timp starea tehnică a vehiculului și deteriorarea anterioară a acestuia. De exemplu, dacă a mașina este nereparabilă.',
'878444309':'În cazul în care s-au plasat anunțuri publicitare în trecut, vă vom arăta anunțurilor publicitare și documentația foto ale vehiculului.',
'185243225':'Veți găsi informații detaliate și o privire de ansamblu asupra operațiunilor de service efectuate în timpul reparațiilor sau mentenanței de rutină a vehiculului. Atenție: comparați înregistrările cu cartea de service și solicitați mai multe informații despre istoricul de service al vehiculului.',
'1036319803':'Vom verifica anul efectiv de producție pentru dumneavoastră, ceea ce poate avea un impact semnificativ asupra prețului actual și viitor al vehiculului. Anul de fabricație nu mai este menționat în documentația tehnică, se introduce doar data primei înmatriculări. Cu toate acestea, aceasta poate varia cu până la 2 ani de la anul de producție, deși 6 ani nu este o excepție. La majoritatea vehiculelor, veți afla și data primei înmatriculări și pentru ce țară a fost fabricat inițial vehiculul.',
'-437053085':'Vom verifica vehiculul în bazele de date disponibile în prezent aferente vehiculelor furate.<br /> Acestea sunt în principal baze de date europene din surse statale și nestatale. Consultați secțiunea plătită pentru vizualizarea listei țărilor și resurselor disponibile.',
'494784690':'Vom verifica vehiculul în bazele de date disponibile în prezent cu vehicule furate.<br /> Acestea sunt în principal baze de date europene din surse de stat și private. Consultați secțiunea plătită pentru lista țărilor și resurselor disponibile.',
'2074346923':'Verificarea în bazele de date a vehiculelor operate ca serviciu de taxi. În secțiunea plătită veți afla dacă mașina a fost sau încă este operată ca taxi.',
'-976680067':'Vom afișa lista notificărilor de rechemare care ar putea avea legătură cu vehiculul inspectat.',
'1665109686':'Conform descrierii tehnice, veți putea verifica parametrii importanți pe baza cărora versiunea de model a tipului de vehicul dat a fost aprobată pentru funcționare. Comparați acești parametri cu datele din cartea tehnică  a vehiculului.',
'-719963267':'Vă vom arăta o listă cu echipamentele vehiculului conform specificațiilor producției sale. Acest lucru vă permite să comparați echipamentul actual al vehiculului cu cel specificat de producător. Atenție: dacă, de exemplu, producătorul precizează volanul pe dreapta în parametrii vehiculului, tapițerie din piele sau trapă și vehiculul dumneavoastră are volan pe stânga, tapițerie din material sau fără trapă - probabil că nu este același vehicul, dar poate fi furat si remodelat! În cazul oricăror discrepanțe, ar trebui să acordați o atenție deosebită inspecției fizice ulterioare a vehiculului pentru a preveni achiziționarea unui vehicul care ar putea fi obiectul unei infracțiuni.',
'-1106584159':'Vehiculul dumneavoastră este asigurat prin sablarea sau gravarea geamurilor, sau este posibil ca părțile caroseriei să fi fost marcate pe vehicul. Vă vom informa în privința codului marcat și dății de înregistrare.',
'1830950672':'Vă vom arăta culoarea înregistrată de producătorul vehiculului (dacă avem aceste informații disponibile).',
'1835889470':'Se încarcă...',
'1020539397':'Operatorul serviciului Car History - Cebia spol. s r.o. avertizează utilizatorii serviciului că informațiile au fost furnizate de partenerii operatorului fără posibilitatea de a verifica corectitudinea acestora. Toate informațiile sunt doar cu scop informativ.',
'2054100346':'Rezultatul inspecției vehiculului din cadrul Car History Report nu prevede garanția.',
'-167783152':'Acest raport a fost creat ăn urma solicitării serviciului Car History operat de Cebia spol. s r.o. conform Termenilor și Condițiilor Generale valabile de utilizare a serviciului Car History. Utilizatorul are dreptul de utilizare a  informațiilor furnizate în întregime numai pentru propriile necesități. Diseminarea sau modificarea ulterioară a acestora fără acordul operatorului este interzisă.',
'-1697979879':'Director al Cebia, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Director al Cebia',
'759051798':'Compania Cebia ',
'-31539157':'Cebia este o companie cehă fondată în 1991 la Praga. Gama noastră de servicii include inspecția, securizarea și identificarea vehiculelor și sunt folosite de clienți în 9 țări europene. Clienții noștri sunt șoferi obișnuiți, companii de asigurări, dealeri auto, instituții ale statului, forțe de poliție și experți în criminalistică. De-a lungul timpului am depistat zeci de mii de nereguli și am ajutat milioane de clienți din toată Europa să-și cumpere o mașină.',
'-1821588851':'Sesizări și reclamații',
'-276118588':'Reclamațiile și nemulțumirile reprezintă o sursă valoroasă pentru a ne îmbunătăți serviciile. Vă rugăm să ne contactați folosind formularul de contact sau la <a href="mailto:info.ro@cebia.com">info.ro@cebia.com</a>.',
'-1446517877':'Vom procesa reclamația în maxim 14 zile de la recepționarea acesteia și vă vom informa în legătură cu rezultatul acesteia.',
'708234375':'Contactați-ne prin intermediul formularului de contact sau prin e-mail <a href=\'mailto:info.ro@cebia.com\'>info.ro@cebia.com</a>',
'1474175101':'Condițiile generale de utilizare',
'1343500875':'Politica de confidențialitate',
'-2083968164':'Dezvoltarea unui instrument proprietar de evaluare a vehiculelor',
'907588970':'Înființarea companiei',
'-770165824':'<strong>30 de ani de experiență</strong> în inspectarea mașinilor',
'-1138482741':'<strong>Peste 1.000.000</strong> de mașini testate în fiecare an',
'154007106':'<strong>Peste 750.000</strong> de clienți mulțumiți',
'177137909':'Baza de date Cebia include peste 1 miliard de înregistrări ale vehiculelor rulate din peste 32 de țări. Acestea sunt în principal înregistrări privind kilometrajul, defecțiunile tehnice, fotografiile vehiculelor și înregistrări de service',
'723486619':'Se încarcă',
'742852638':'Perioada:',
'787763184':'Perioada:',
'-685036969':'Explicații:',
'290784658':'Nu e disponibil:',
'412892373':'Nu am găsit nicio informație despre vehicul din categoria respectivă',
'1853282090':'Disponibil:',
'-249038921':'XY intrări:',
'805146013':'Am căutat înregistrări XY ale acestui vehicul în categoria respectivă pe care le puteți vizualiza în secțiunea plătită',
'242118549':'Termeni și condiții',
'521725542':'Puteți găsi aici termenii și condițiile generale ale serviciului Car History',
'-571686819':'aici',
'639498968':'Plata pentru afișarea informațiilor detaliate în secțiunea Car History Report vă va fi percepută conform condițiilor menționate în acordul cu Cebia.',
'-1705426324':'Puteți vizualiza rezultatele scanării timp de până la 30 de zile după achiziționarea scanării.',
'529403448':'Avertisment important',
'-821000576':'Operatorul serviciului Car History - Cebia spol. s r.o. avertizează utilizatorii serviciului că informațiile au fost furnizate de partenerii operatorului fără posibilitatea de a verifica corectitudinea acestora. Toate informațiile sunt doar cu scop informativ.',
'-1138137280':'Informații privind prelucrarea datelor cu caracter personal',
'125063053':'Vizualizați informațiile despre prelucrarea datelor cu caracter personal de către utilizatorii Car History',
'-608864551':'Am găsit informații despre vehicul din categoria respectivă pe care le veți accesa în secțiunea plătită',
'-1879995849':'Date de acces introduse',
'754897480':'Anul primei înmatriculări',
'-1834106204':'Kilometrajul real',
'404797827':'Vizualizați certificatul',
'1358780725':'Generați certificatul',
'-952450705':'Am examinat și confirm informațiile furnizate',
'-1601808583':'<span className="green">Gata!</span> Următoarele informații sunt verificabile pentru vehiculul în cauză:',
'-1763095911':'Se poate elibera un Certificat de evaluare a următoarelor categorii:',
'761362384':'Exemplu de Raport',
'-397155268':'Vizualizează Report',
'1375027269':'INSPECTAȚI VEHICULUL',
'2055286638':'peste',
'-1668243810':'Suntem mai puternici',
'1141836815':'Am devenit parte a holdingului ceh EAG. Datorită acestui fapt, bazele noastre de date sunt extinse substanțial cu înregistrări din toată Europa - în special Germania, Austria și Danemarca. ',
'-903271711':'Lansăm un instrument online pentru determinarea prețului vehiculelor second hand. Datorită faptului că avem o bază de date extinsă ce cuprinde înregistrări ale mașinilor uzate, instrumentul nostru online oferă date precise și devine din ce în ce mai popular în rândul marilor companii de asigurări și leasing.',
'-1262829232':'Schimbăm piața de mașini second hand',
'1367974718':'Odată cu creșterea interesului de achiziție a mașinilor online, am început să colaborăm cu cele mai mari portaluri de publicitate din Europa Centrală. Rezultatele verificării istoricului mașinii au devenit un semn distinctiv al calității unei mașini second hand.',
'-300528762':'Accesarea online a serviciilor noastre',
'-899322976':'De acum înainte, serviciile noastre sunt ușor accesibile tuturor din confortul de acasă. Înșelăciunile în legătură cu mașinile second hand au atins apogeul și depunem toate eforturile necesare ca să le contracarăm.',
'-4875574':'Ne extindem serviciile',
'1166185728':'Gama de servicii prestate includem verificarea fizică a identificatorilor vehiculelor (VIN), iar baza noastră de date se extinde rapid. Colaborăm cu diverse companii de asigurări, autorități de stat, poliție.',
'-1619922514':'Extinderea pe piața străină',
'-844900279':'Afacerile care implică vehiculele furate se extind în afara granițelor Cehiei. Prin urmare, am pus bazele unei baze de date internațională a vehiculelor furate care ne ajută să descoperim adevărata origine a mașinilor second hand.',
'598423318':'Trei colegi de facultate au pus bazele companiei Cebia. Inițial au intenționat să pună bazele unei afaceri în IT, după care a apărut interesul pentru securizarea vehiculelor.',
'-1828699417':'Executare/Insolvență',
'4000011':'Verificarea gratuită a documentelor',
'1479506336':'Istoricul căutărilor',
'-1478881101':'Istoricul inspecțiilor vehiculului',
'1115668272':'Istoricul executărilor/insolvenței',
'691760517':'Istoricul verificării documentelor',
'-614781938':'Sfera și prețul inspecției pot varia în funcție de vehicul și de amploarea informațiilor disponibile.',
'702191442':'Înapoi',
'-1034048918':'Verificarea numărului VIN',
'-1214567423':'Numărul de referință',
'427849990':'Creați un CERTIFICAT de inspecție auto',
'-1718377395':'Raport de încărcare a erorilor.',
'877365836':'Nu s-a reușit generarea URL-ului de plată.',
'-1082791212':'Report ID incorect',
'-255296664':'Report ID permite vizualizarea repetată a raportului pentru o perioadă de 30 de zile de la achiziție.',
'456125545':'Operatorul serviciului Car History - Cebia spol. s r.o. avertizează utilizatorul că informațiile au fost furnizate de partenerii operatorului fără posibilitatea verificării acurateței acestora. Toate informațiile au doar scop informativ.',
'-1172808350':'Contactați dealer-ul ca să aflați VIN-ul vehiculului, după care puteți inspecta vehiculul.',
'-2079080642':'Contactați dealer-ul ca să obțineți codul VIN din 17 cifre, după care puteți inspecta vehiculul.',
'-585052469':'Doriți să achiziționați o mașină second hand? Aflați toate informațiile disponibile, în special starea odometrului, anul de fabricație sau înregistrările daunelor utilizând codul VIN.',
'76811920':'EXEMPLU de rezultat al unei inspecții aleatorii a vehiculului.',
'1050846059':'Cu toate acestea, vă recomandăm inspecția atentă a vehiculului, preferabil cu ajutorul unui mecanic calificat sau la un service la alegere. O astfel de inspecție poate detecta cu precizie sau exclude daunele anterioare.',
'-1068264553':'Verificați întotdeauna data de fabricație declarată de producătorul vehiculului. Doar data primei înmatriculări este menționată în documentația tehnică, care, totuși, pot diferi semnificativ de data fabricării (de exemplu, cu doi ani). Vehiculul poate fi astfel considerat mai nou, ceea ce are un influențează substanțial prețul de pe piață.',
'756232271':'Nu s-au găsit înregistrări cu privire la vechimea sau originea vehiculului. Dacă autovehiculul provine din străinătate, solicitați întotdeauna și verificați cu atenție documentația originală a vehiculului (ex. carte service sau carte tehnică).',
'-643660678':'Au fost găsite înregistrări ale operațiunilor de service efectuate în trecut, ca parte a reparațiilor sau a service-ului de rutină.',
'1533967406':'Prețul raportului Car History depinde de cantitatea și conținutul informațiilor disponibile pentru vehiculul inspectat. Veți afla conținutul informațiilor (și, prin urmare, și prețul) imediat după introducerea codului VIN al vehiculului.',
'-1289463237':'Raportul este de obicei generat în câteva minute după recepționarea plății. Serviciul funcționează non-stop și întregul proces este automat. ',
'1304585346':'Car History Report este generat automat imediat după recepționarea plății. Veți obține un link către acesta în e-mailul pe care îl veți primiți după efectuarea plății. De asemenea, veți primi un Report ID la adresa de e-mail pentru vizualizarea repetată a raportului timp de 30 de zile.',
'-661494819':'Eroare la încărcarea datelor.',
'-1681989289':'Ați achiziționat deja o inspecție a vehiculului, ceea ce vă dă dreptul la o reducere de la această achiziție. Pentru a aplica reducerea, introduceți Report ID pe care l-ați primit prin e-mail în câmpul „Introduceți Report ID”. Atenție: termenul-limită de utilizare a acestei reduceri este de 90 de zile de la achiziționarea inspecției vehiculului.',
'681093117':'ați primit un cod de reducere pentru prelungirea serviciului FLEX cu încă un an. Pentru a solicita reducerea, introduceți numărul codului de reducere menționat ăn factură entru plata FLEX în câmpul „Introduceți codul de reducere”.',
'-1551909822':'Aveți un cod de reducere care vă dă dreptul să beneficiați de inspecția auto gratis. În vederea utilizării acestuia, introduceți Report ID în câmpul „Introduceți Report ID”.',
'-103162544':'După efectuarea plății, informațiile necesare pentru afișarea informațiilor plătite și documentuș fiscal simplificat pentru plată vor fi trimise la adresa de e-mail furnizată.',
'-591911074':'După efectuarea plății, informațiile necesare pentru afișarea informațiilor plătite și documentuș fiscal simplificat pentru plată vor fi trimise la adresa de e-mail furnizată.',
'706315962':'Veți primi de fiecare dată un document fiscal simplificat (neadresat) împreună cu informațiile necesare pentru afișarea informațiilor plătite. Dacă doriți să completați informațiile dumneavoastră pentru factură pe factura (companie, adresă, număr de identitate, număr de TVA), vă rugăm să le introduceți mai jos.',
'500915765':'În timpul efectuării comenzii veți fi redirecționat către portalul de plată GoPay unde veți efectua o plată securizată cu cardul de credit. Veți fi redirecționat automat către rezultatul inspecției vehiculului. Linkul pentru  inspecția autovehiculului și documentul contabil va fi trimis și pe adresa dumneavoastră de e-mail.',
'-676823504':'Dacă alegeți această metodă de plată, veți fi redirecționat către portalul de plată GoPay, unde vă veți alege instituția bancară de la care veți dori să efectuați plata.',
'-764670786':'Dacă banca dumneavoastră oferă un așa-numit transfer online, vă vom redirecționa către Internet Banking, unde veți avea un ordin de plată precompletat și veți plăti pentru serviciu.',
'2062466434':'Dacă recepționăm imediat plata de la bancă, vă vom redirecționa automat către secțiunea plătită a serviciului Car History. În caz contrar, așteptați recepționarea e-mailului.',
'734607756':'După creditarea plății în contul nostru, veți primi un e-mail cu linkul către rezultatul verificării și factura.',
'1715999355':'Google Pay este o modalitate rapidă și ușoară de plată oriunde pe Google fără introducerea informațiilor de plată. Puteți plăti rapid cu un card stocat în Google Pay. Pentru a utiliza acest serviciu de plată, acesta trebuie să fie suportat și de banca dumneavoastră. Dacă plata a fost efectuată cu succes, veți fi redirecționat automat către rezultatul verificării. În același timp, vom trimite un mesaj automat la adresa de e-mail pe care ați furnizat-o, care conține linkul către rezultatul verificării și factura.',
'790632208':'Apple Pay este metoda de plată care permite plăți simplificate cu ajutorul un card stocat în Apple Wallet. Pentru a utiliza acest serviciu de plată, acesta trebuie să fie suportat și de banca dumneavoastră. Dacă plata dumneavoastră are loc cu succes, veți fi redirecționat înapoi automat la rezultatul inspecției vehiculului. Totodată, vom trimite un mesaj automat la adresa de e-mail pe care ați furnizat-o care conține linkul către rezultatul verificării și factura.',
'-1402859324':'Dacă banca dumneavoastră oferă doar așa-numitul transfer off-line, vom genera instrucțiuni pentru transferul dumneavoastră bancar, apoi veți transfera fonduri la banca dumneavoastră și, de îndată ce suma este creditată în contul nostru bancar, vă vom trimite un e-mail de acces la secțiunea plătită a serviciului Car History și dovada plății.',
'347099860':'Această operațiune poate dura până la 2 zile lucrătoare (de obicei durează 24 de ore).',
'-43783036':'Raportul va fi disponibil la sediul Cebia',
'-802148009':'Veți achita personal pentru verificarea Car History la sediul social al Cebia spol. s r.o., strada Vyskočilova 1461/2a, Praga 4, codul poștal 140 00, în timpul programului de lucru de luni până vineri de la 8.00 la 16.30. Este necesar să aveți cu dumneavoastră VIN-ul vehiculului inspectat. Veți primi informații despre vehicul imediat după efectuarea plății.',
'1985201267':'Cea mai mare bază de date online din Europa care cuprinde înregistrările vehiculelor rulate ',
'-1590601891':'<span class=\'warning\'>Avertisment:</span> Serviciul Car History poate să nu includă informații despre toate daunele vehiculului, unele daune nu sunt raportate companiilor de asigurări sau nu sunt disponibile în cazul inspecției online.',
'-1465948017':'<strong>Sistemul OCIS</strong> - marcajul geamurilor vehiculului. securitatea vehiculului este simplă, eficientă și rentabilă.',
'1553997983':'<strong>CEBIA SAT</strong> - securitatea vehiculului modern. Protejează mașina de furt și pasagerii în caz de accident. Răspuns imediat și monitorizare continuă pe telefonul dumneavoastră.',
'-124714580':'Puteți plăti online cu cardul de credit sau prin Google Pay și Apple Pay. Plățile sunt furnizate de portalul de plată securizat GoPay.',
'-753863533':'CERTIFICAT DE INSPECȚIE VEHICULE - prezentarew generală a informațiilor disponibile',
'1441255407':'Categorie',
'1549893453':'Posibilitate de verificare',
'1547427535':'DA',
'508023484':'Verifică data înmatriculării',
'2076692599':'Inspecția fizică a vehiculului ',
'-1521980547':'Verificarea stării odometrului',
'-1009985044':'DA - pe baza',
'1747355819':'date',
'1639206028':'Verificarea originii mașinii',
'-1524110425':'Verificarea achitării taxelor eco',
'-1961089879':'Verificare TVA',
'1732127987':'Verificarea accidentelor grave',
'-198755848':'Verificarea înregistrării',
'-1790774232':'Verificareal daunelor provocate de inundații',
'-1781437228':'Verificarea tipului motorului',
'-1344389293':'Verificarea identificatorilor vehiculului',
'-499181915':'Fotografii',
'-687939409':'Informațiile au fost preluate din registrele serviciilor de documentare a stării vehiculelor achiziționate de la Cebia sau din rețeaua de parteneri contractuali ai acesteia.',
'-43022982':'Data efectuării serviciului:',
'-136414993':'Starea vehiculului:',
'2023782231':'S-a efectuat verificarea fizică a identificatorilor acestuia pe vehicul (ștanțarea codului VIN, plăcuțe de producție etc.). Informațiile au fost preluate din înregistrările serviciului VINTEST efectuat de Cebia sau de partenerii săi contractuali.',
'758499555':'Starea identificatorului:',
'488100027':'Înapoi',
'-1281249872':'Continuați',
'-141562572':'Generați',
'-40297285':'Puteți obține o imagine de ansamblu în privința evoluției prețului și a datelor vehiculului inspectat.',
'-1742933347':'Numărul anunțului publicitar',
'-2099386182':'Vânzări',
'1560408371':'Preț de vânzare',
'-280814568':'Vizualizați certificatul de inspecție auto',
'2075274708':'Data plasării anunțului',
'-1248991647':'Ucraina',
'-1779298552':'Suedia',
'-18328562':'Letonia',
'1764744897':'Lituania',
'-1273046880':'România',
'1252567719':'Bulgaria',
'445626359':'Grecia',
'-868498706':'Slovenia',
'-1528448787':'Ungaria',
'-387469480':'Slovacia',
'25300212':'Austria',
'1771002523':'Italia',
'69077775':'Danemarca',
'647944028':'Polonia',
'1160986873':'Germania',
'897084544':'Franța',
'2056598840':'Spania',
'264100206':'Rusia',
'-1609348689':'Politica de cookies',
'-365994139':'Încărcați mai mult',
'807848789':'Milioane de clienți mulțumiți',
'1677683955':'Cebia are peste <strong>2.000 de parteneri</strong> din toată Europa care furnizează înregistrări ale vehiculelor pentru istoricul mașinii.',
'652553309':'Știai că...',
'1453750886':'Gata!',
'1498262202':'Disponibil',
'1514779988':'Inspecția vehiculului de către un tehnician',
'1058658544':'Cupoane de reducere Cebia',
'-2035347536':'înregistrări 0',
'1050102897':'Specificația detaliată a echipamentului furnizată de producătorul vehiculului conține toate elementele echipamentelor de bază și opționale. Inspecțiile echipamentelor pot dezvălui modificări ale mașinii, cum ar fi schimbarea postuylui de conducere.',
'2145745364':'Lista înregistrărilor odometrului',
'-1636264801':'Descriere',
'-1488190529':'Numărul înregistrării',
'64594583':'Vehicul finanțat',
'-1441353378':'Fără finanțare',
'-2101084179':'Ianuarie',
'-1066567335':'februarie',
'-1761670105':'martie',
'104663035':'aprilie',
'1397259452':'mai',
'-642974117':'iunie',
'613657227':'iulie',
'-2044487450':'august',
'1921417285':'septembrie',
'-1672709282':'octombrie',
'-678062097':'noiembrie',
'1302554433':'decembrie',
'1268839568':'Nu au fost găsite înregistrări în bazele de date ale serviciului. Solicitați o declarație din cartea de service (de preferință din cartea electronică de service) a vehiculului. Autenticitatea operațiunilor de service poate fi verificată direct la atelierul de reparații auto, unde au fost efectuate conform cărții de service.',
'-112660439':'Puteți oferi certificatul celor interesați de mașina dumneavoastră; astfel vă creșteți credibilitatea acesteia atunci când vindeți.',
'-1157869217':'Înregistrare găsită',
'-1350474522':'Nu există înregistrările odometrului vehiculului inspectat în bazele de date disponibile.',
'1040913417':'Vă recomandăm să verificați și alte surse posibile ale înregistrărilor odometrului, cum ar fi cartea service sau solicitând informații despre istoricul service-ului în cadrul unui centru de service autorizat. La inspectarea vehiculului, atenție la uzură, în special a acelor piese care pot indica kilometrajul efectiv.',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'E-mail',
'-982011601':'Statele Unite ale Americii',
'786789662':'Achiziționați pachete la preț redus',
'737703701':'Aveți nevoie să verificați mai multe vehicule? Oferim pachete pentru companii și persoane fizice la prețuri reduse: </br></br><strong>10 verificări</strong> de antecedente pentru <strong>420 lei</strong> </br><strong>20 de verificări</strong> de antecedente pentru <strong>800 lei</strong> și </br><strong>50 de verificări</strong> de antecedente pentru <strong>1 800 lei</strong> </br></br>Dacă sunteți interesat de unul dintre aceste pachete, vă rugăm să introduceți datele dumneavoastră de contact mai jos și să specificați în mesaj pachetul de care sunteți interesat. Vă vom răspunde cu promptitudine.',
'-174399420':'Doriți să cumpărați pentru compania dvs. sau să verificați mai multe vehicule? Profitați de',
'845754511':'pachetele la preț redus!',
'-614796774':'Cehia',
'1011350398':'Luni - Vineri: 8:00 - 16:30',
'-68730396':'La zi:',
'544810671':'NF - nu este înregistrat',
'-1670731287':'Înregistrat în Republica Cehă ca:',
'1597705322':'Echipament standard',
'16575694':'Vehicul:',
'-846486931':'înregistrărilor',
'-1615816320':'înregistrare',
'1415114012':'înregistrări',
'1802294807':'fotografiilor',
'1122888753':'fotografii',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pruvodce?id=111&do=downloadFile">datelor cu caracter personal</a>',
'1802705032':'<a className="Link Link--lightBlue" href="/detailArticle/unde-pot-gasi-numarul-vin" target="_blank" rel="noreferrer noopener">Mai multe informații</a>',
'883512613':'Cebia Car History - Verificare autovehicul',
'-1965309318':'Verificați online istoricul vehiculului în cea mai mare bază de date din Europa. Verificarea daunelor și a kilometrajului, fotografii și multe altele.',
'-1871315210':'Plata online',
'-1803227447':'Vă rugăm să selectați metoda de plată corespunzătoare.',
'-1249181167':'Știri - Car History',
'-838385662':'Pe blogul nostru puteți găsi informații importante despre verificarea și cumpărarea mașinilor second-hand, despre evitarea înșelăciunilor și multe altele.',
'1154868985':'Întrebări frecvente - Car History',
'908158246':'Consultați cele mai frecvente întrebări despre Cebia Car History de la clienții noștri. Dacă aveți o altă întrebare, nu ezitați să ne contactați.',
'-633966577':'Despre noi - Car History',
'1171158673':'Aflați mai multe despre Cebia Car History, baza sa de date și de ce este folosită de milioane de clienți pentru a verifica istoricul vehiculelor.',
'-1244282008':'Politica de cookies',
'-792206035':'Acest site utilizează diferite tipuri de module cookie. Unele module cookie sunt plasate de către servicii terțe. Vă puteți retrage în orice moment consimțământul pentru utilizarea acestora.',
'1489309005':'Acces liber conform prevederilor contractului',
'-470675791':'Am examinat și confirm informațiile furnizate',
'2108896817':'ANULAȚI REDUCEREA INTRODUSĂ',
'6892736':'Reducerea a fost anulată',
'-1199844274':'Codul nu este valabil!',
'-552424253':'Taxa de intrare în partea plătită se percepe în funcție de contract.',
'167769730':'Plată în numerar',
'1768026018':'Veți achita personal pentru verificarea Car History la sediul social al Cebia SRL, strada Vyskočilova 1461/2a, Praga 4, codul poștal 140 00, în timpul programului de lucru de luni până vineri de la 8.00 la 16.30. Este necesar să aveți cu dumneavoastră VIN-ul vehiculului inspectat. Veți primi informații despre vehicul imediat după efectuarea plății.',
'820786512':'Codul este acceptat, prețul a fost recalculat',
'-279227794':'Verificarea anului de producție',
'1470281039':'Verificarea leasing-ului/creditului',
'60776637':'Report ID',
'529459564':'Introduceți Report ID.',
'1762078183':'Material (enumerare)',
'822990672':'Prin apăsarea acestui buton se extrage o interogare din limita de interogare gratuită a contractului APC curent.',
'532528312':'folosit',
'-319106355':'Vehicul',
'935795728':'Examplu de raport',
'-916000765':'Car History Report',
'-1721948126':'Examplu de raport - Verificați online istoricul vehiculului în cea mai mare bază de date din Europa.',
'329882651':'Prin apăsarea acestui buton veți fi taxat pentru vizualizarea informațiilor detaliate conform contractului APC în vigoare.',
'-494157101':'Factura',
'-465994559':'Fotografii',
'857656094':'Marca:',
'-2005319583':'Model de vehicul:',
'339937023':'Anul primei înmatriculări conform cărții de identitate a vehiculului',
'-441771827':'Încărcare date',
'-161761801':'perioadă neverificată',
'1229398238':'AVERTISMENT: Certificatul nu poate fi creat. Mașina este înregistrată drept <strong className="bolder">furată</strong>.',
'358386818':'Vechimea vehiculului:',
'-701699977':'Certificatul nu a putut fi creat din cauza codului VIN incorect. Verificați codul VIN-ul conform documentației vehiculului sau contactați Serviciul Asistență Clienți.',
'-1359814132':'Următoarea imagine',
'340541220':'Zoom in',
'2096545526':'Zoom out',
'-158382161':'Imaginea anterioară',
'-861194224':'Inspecția vehiculului',
'2076412240':'nou',
'360601996':'Report ID expirat',
'-749344895':'Reducere',
'-1755324145':'Model de vehicul',
'1747292041':'Smart code',
'1093009871':'Comunicare inteligentă pentru dealerii de vehicule',
'-421925379':'Doriți să anunțați că vehiculele dvs. sunt dovedite?',
'-303554559':'Puneți un Smart code pe fiecare vehicul, iar clientul va prelua singur rezultatul verificării.',
'5128661':'Pur și simplu. Imprimați codul Cebia Smart Code (format A5) și plasați-l în spatele parbrizului vehiculului pe care îl vindeți.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Ostatní informace (kompletní výpis ze systému AUTOTRACER) si pak může vyžádat přímo od Vás.',
'-566386963':'Vă rugăm să completați acest scurt formular și vă vom contacta noi.',
'-1639125181':'Trimite',
'76421921':'Elemente obligatorii',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Sunteți interesați să imprimați Smart code pentru vehiculele dumneavoastră? Contactați departamentul nostru de vânzări:',
'2094660384':'Petr Kala, șeful departamentului de vânzări',
'361077673':'Sau completați formularul și vă vom contacta noi:',
'1277961418':'Chiar doriți să generați un Smart code?',
'-2133968142':'Am un cod de reducere',
'-1922950659':'Metoda de plată',
'-2072875261':'Vehiculul inspectat',
'783887791':'Comandă',
'-1499237034':'Gratuit',
'-1854208328':'Răscumpărați cuponul de reducere',
'-793343279':'cadou',
'-1393236672':'Kosovo',
'1342791550':'Istoricul kilometrajului',
'2063498881':'Echipament opțional',
'-152116623':'QR – Smart code',
'-956949153':'Valoarea daunelor',
'-552950132':'Date',
'30639535':'datele',
'-1164317619':'Nu',
'1402944789':'Vă rugăm să introduceți datele necesare',
'-1812102598':'AVERTISMENT: Kilometrajul nu poate fi verificat. Categoria va avea ca rezultat "informații insuficiente".',
'666398003':'Atenție!',
'771644825':'Este posibil ca numărul VIN să fie incorect!',
'-1131044763':'Mai multe informații?',
'-2054470344':'Este posibil ca numărul VIN să fie incorect!',
'-1369269346':'A fost detectată o posibilă eroare în numărul VIN introdus. Vă rugăm să verificați dacă numărul VIN introdus corespunde cu numărul VIN ștampilat pe vehicul sau pe certificatul tehnic.',
'-1625517200':'Unde pot găsi seria de șasiu (VIN)?',
'1171142400':'Plata a eșuat. Vă rugăm să încercați din nou. ',
'-1192484737':'Dacă nu sunteți de acord cu Termenii și condițiile, nu puteți continua.',
'-247455599':'Vă rugăm să introduceți numărul de identificare al companiei.',
'-1944712721':'Numărul minim de caractere este de 12.',
'1664270996':'Stat',
'-862797995':'Starea vehiculului, așa cum a fost înregistrată în momentul vânzării. Vă rugăm să consultați documentația foto atașată pentru orice deteriorare.',
'-1997376096':'Slovacia',
'-1344951568':'Registrul vehiculului',
'-644036535':'Bază de date',
'-426412387':'Registrul de înmatriculare a vehiculelor',
'1736003883':'Datele din certificatul de înmatriculare a vehiculului',
'1610817679':'Înregistrările odometrului ale vehiculului sunt disponibile. În secțiunea plătită puteți găsi valorile odometrului pentru perioada afișată în această categorie. Înregistrările pot fi folosite pentru a determina kilometrajul real  sau dacă datele odometrului au fost date înapoi.',
'1669009738':'Vehiculul inspectat a fost plasat în cadrul anunțurilor publicitare sau a fost fotografiat în trecut. În secțiunea plătită puteți vedea detaliile anunțurilor publicitare, valorile odometrului, fotografiile mașinii și eventual și prețul anunțat (conținutul informațiilor poate varia în funcție de înregistrare). Înregistrările pot fi din România sau din străinătate.',
'1518701163':'Avem înregistrări cu privire la vechimea sau originea mașinii. În secțiunea plătită, puteți verifica data fabricării vehiculului, data punerii în funcțiune, data înmatriculării mașinii în România sau pentru ce țară a fost fabricată mașina. Cantitatea de informații din secțiunea plătită poate varia în funcție de mașină.',
'1816609203':'Anul de fabricație este adesea falsificat pentru reducea vechimii mașinii și, prin urmare, pentru ridicarea prețului!',
'-900812009':'Mașina furată poate fi confiscată de la cumpărător de către poliție fără nicio despăgubire!',
'-976941031':'Acest VIN nu este disponibil pentru a fi verificat în bazele de date ale vehiculelor exploatate ca taxiuri.',
'444450829':'tocmai ',
'982955181':'12 registre',
'-1739651968':'Verificarea de securitate',
'-167461646':'Verificarea istoricului vehiculului',
'-1365221853':'Numărul maxim de caractere este 14.',
'1302766369':'Nu a fost efectuată nicio evaluare a vehiculului. Evaluarea poate fi efectuată pe durata raportului prin introducerea detaliilor vehiculului (data primei înmatriculări și valoarea actuală a kilometrajului) pe site-ul nostru, după introducerea Report ID.',
'1881868699':'Cumpăr pentru companie',
'631216697':'Vă rugăm să introduceți adresa dvs. de e-mail.',
'945011940':'Detalii de contact',
'-401861769':'Adresa ta de e-mail',
'1441058305':'Dacă introduceți numărul de TVA, vom încerca să completăm datele rămase.',
'-1181757195':'După efectuarea plății, vă vom trimite la adresa de e-mail furnizată de dvs. un link pentru a vizualiza informații despre istoricul mașinii dvs. și un document fiscal simplificat.',
'-1768425519':'Dacă doriți ca datele dumneavoastră de facturare (firmă, adresă, număr de TVA) să fie completate pe documentul fiscal simplificat, vă rugăm să le introduceți în câmpurile de mai jos. Dacă introduceți numărul de TVA, vom încerca să completăm restul datelor din baza de date oficială.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Certificatul Cebia oferă o evaluare a verificărilor odometrului, a verificărilor în baza de date a vehiculelor furate, a angajamentelor companiilor de leasing, a verificărilor VIN și a anului de fabricație. Evaluarea generală este exprimată prin numărul de stele (0-5) și o evaluare verbală.',
'308350422':'Total cu TVA:',
'-24797068':'Aveți dreptul la o reducere dacă:',
'-1323263000':'Aveți un serviciu FLEX preplătit la noi și utilizați codul de pe chitanța fiscală.',
'-724069837':'Aveți un cupon de reducere care vă dă dreptul la o achiziție gratuită.',
'-127346860':'Aveți un cod de reducere de la unul dintre partenerii noștri.',
'-47884841':'fără TVA',
'881014685':'Total fără TVA',
'-15294371':'Număr de TVA neconfirmat',
'1286068311':'Baza de date oficială a plătitorilor de TVA nu a confirmat numărul dumneavoastră de TVA. Prin urmare, nu putem completa automat datele dumneavoastră. Vă rugăm să verificați numărul de TVA sau să completați manual datele companiei dumneavoastră.',
'-172351568':'Baza de date nu se potrivește',
'1839952435':'Baza de date oficială a plătitorilor de TVA nu răspunde. Vă rugăm să completați manual datele companiei dumneavoastră.',
'1678298800':'Exemplu de Raport',
'-654354054':'Cantitatea de informații disponibile variază de la un vehicul la altul.',
'-1069736045':'înregistrările pieței  <label className="color-orange">auto europene</label>',
'1068440731':'tocmai <label className="color-orange">12 registre</label>',
'-534710218':'Vârsta vehiculului și țara de origine',
'1267127794':'În mod specific pentru numărul de identificare (VIN) interogat, putem furniza informații despre vehicul care acoperă toate categoriile de date enumerate mai sus. Aceste informații vor fi disponibile după ce achiziționați Raportul istoric auto. Vă rugăm să rețineți că volumul de informații conținut în Raportul istoricul vehiculului poate varia de la vehicul la vehicul.',
'-186613120':'Vehiculul nu a fost exploatat ca taxi în Republica Cehă',
'1880784559':'Disponibil prin introducerea numărului de înmatriculare al vehiculului',
'2085931506':'<label className="color-orange">este disponibil</label>',
'-1765351165':'peste <label className="color-orange">200 de milioane de înregistrări</label>',
'1815202337':'pe baza datelor din <label className="color-orange">UE și SUA</label>',
'-555727222':'pe baza datelor din <label className="color-orange">18 țări</label>',
'-1798142062':'Graficul înregistrărilor odometrului',
'-1828887347':'VIN (numărul de identificare a vehiculului):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Puteți verifica dacă există taxiuri în Slovacia introducând numărul de înmatriculare al vehiculului pe <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Crăciun 30% reducere la inspecția vehiculului</b></h2><br><p>Verificați orice vehicul cu o reducere de 30% de Crăciun. Promoția este valabilă până la 2 ianuarie 2023 și nu poate fi combinată cu alte reduceri.</p><br>',
'-765158573':'Verificați codul VIN acum',
'-1620583517':'Istorie',
'239060530':'Istoric al verificării VIN',
'1122350112':'O imagine de ansamblu a istoricului tuturor interogărilor privind VIN care urmează să fie verificată la data activării cuponului.',
'-1288575114':'Vehiculul nu a fost percheziționat',
'-14589513':'Client',
'-1170545783':'Verificat de dumneavoastră',
'452068758':'Partener contractual Cebia',
'-1710315139':'Rezultatul inspecției include înregistrările găsite din istoricul vehiculului. Cele mai frecvente sunt înregistrările turometrului, istoricul daunelor vehiculului, informațiile despre inspecția tehnică, fotografiile vehiculului sau raportul anunțurilor publicitare ale vehiculului. Aflați mai multe în <a href="/payed/detail/exampleRO">Demo-ul inspecției</a>.',
'1607430837':'Prezentare generală a istoriei',
'-293598683':'Data radierii',
'-1790772829':'Data primei înmatriculări',
'743843679':'Data de înmatriculare a vehiculului',
'1370884754':'Înregistrat ca',
'644449918':'Culoarea vehiculului la înmatriculare',
'-583571734':'Data fabricației:',
'-1219924366':'Vechimea vehiculului',
'1595420674':'Realizat cu echipamente',
'1151043140':'Timpul dintre data de fabricație și data primei înregistrări',
'708774908':'Albania',
'1637995084':'Macedonia de Nord',
'1001888760':'Accident economic total *',
'1034299871':'Eveniment de asigurare - pierdere economică totală*',
'1209738229':'Eveniment de asigurare',
'913822268':'Notă',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praha 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'Prin finalizarea achiziției, sunt de acord cu <a href="https://www.cebia.cz/pruvodce?id=110&do=downloadFile" target="_blank" class="Link Link--lightBlue">Termenii și Condițiile Generale</a> și recunosc <a href="https://www.cebia.cz/pruvodce?id=111&do=downloadFile" target="_blank" class="Link Link--lightBlue">Politică de confidențialitate și protecție a datelor cu caracter personal</a>.',
'-983289066':'Cooperarea cu Gjirafa',
'-1915066431':'În cooperare cu Gjirafa, ne-am extins serviciile în Kosovo, Albania și Macedonia de Nord.',
'-427869571':'Data primei înmatriculări declarată la înmatricularea vehiculului',
'280331577':'Timpul dintre data de fabricație și data primei autentificări',
'1163480388':'Nu există înregistrări în bazele de date disponibile.',
'-1319845523':'Există un calcul al daunelor făcut de compania de asigurări sau de garaj pentru care nu cunoaștem detaliile (locația daunei). Vă rugăm să acordați atenție la inspecția vehiculului.',
'779148402':'Există un incident raportat de compania de asigurări pentru care nu cunoaștem detaliile (locul daunei). Vă rugăm să acordați atenție la inspecția vehiculului.',
'1115915387':'Transport gratuit',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Nedeclarat',
'-1464599260':'Operațiuni efectuate',
'-592361226':'Lacuire',
'1085310093':'Tipul de control:',
'1984850101':'Data inspecției',
'-1502399543':'Vezi protocolul',
'-1961590845':'Baza de date este momentan indisponibilă, vă rugăm să încercați din nou mai târziu.',
'-1065211891':'Protocolul nu este disponibil',
'1708745339':'Protocolul nu este disponibil',
'1087254174':'Obțineți o verificare completă a antecedentelor',
'2096754301':'Verificarea de bază a vehiculului până în prezent:',
'926095479':'Ce este o verificare de bază a vehiculului?',
'-254140743':'Screeningul de bază include următoarele verificări:',
'1362304971':'Pentru o verificare completă a istoricului vehiculului, vă recomandăm să achiziționați o verificare completă a vehiculului, care poate include, de asemenea, o <b>verificare a daunelor, istoricul listării, fotografii ale vehiculului, istoricul service-ului</b> și multe altele.',
'-267677252':'Verificarea de bază a vehiculului',
'-70564196':'- 10 EUR',
'1350105297':'Sunteți mulțumit de rezultatul verificării istoricului vehiculului? Vom fi bucuroși pentru evaluarea dvs.',
'1914051118':'Evaluare',
'1786612140':'Ne pare rău că verificarea istoricului vehiculului nu a îndeplinit așteptările dumneavoastră. Vă rugăm să ne spuneți ce ar trebui să îmbunătățim data viitoare.',
'-1265184280':'Eroare de trimitere. Repetați acțiunea.',
'-669850784':'Numărul maxim de caractere este de 2000.',
'1801814710':'<strong classname="color-green">Mulțumesc!</strong> Evaluarea a fost trimisă și ne va ajuta să ne îmbunătățim în continuare serviciile.',
'-883106932':'pe baza datelor din <label classname="color-orange">peste 32 de țări</label>',
'1408314893':'Cebia însumează istoricul vehiculelor din peste 32 de țări. Instoricul provine cel mai adesea din registrele oficiale, atelierele de reparații auto, bazele de date ale poliției, bazele de date ale vehiculelor avariate, companiile de asigurări sau portaluri de publicitate.',
'-1412306493':'Da. Fiecare vehicul este verificat în bazele de date a istoricului auto din peste 32 de țări (inclusiv Germania, Danemarca și SUA). După introducerea codului VIN veți putea accesa prezentarea generală gratuită a informațiilor cu privire la înregistrări și în ce perioadă au fost efectuate. ',
'1798507708':'În acest caz, nu am găsit nicio înregistrare de daune în cadrul bazelor noastre de date care să conțină mai mult de 200 de milioane de înregistrări de daune din 32 țări europene și SUA. Acest fapt crește semnificativ probabilitatea ca mașina inspectată să nu fi fost avariată în trecut.',
'568601301':'Date ale vehiculelor din <strong>peste 32 de țări</strong>',
'899127599':'32 țări',
'-1456368295':'Istoricul vehiculului din peste 32 de țări',
'1333238000':'Peste 30.000.000 de mașini verificate',
'426416063':'Înregistrările provin din 32 de țări',
'-1549476292':'Cebia verifică fiecare mașină din baza sa de date cu peste 200 de milioane de înregistrări de vehicule avariate din 32 țări? Cele mai multe înregistrări (62%) provin din Franța și Germania.',
'-924882043':'<span class="color-red">S-a găsit</span> cel puțin o înregistrare de daune în bazele de date de vehicule avariate din mai mult de 32 țări, numărând 200 de milioane de înregistrări de daune.',
'-1366090835':'<span class="light-green">Nu au fost găsite înregistrări</span> cu privire la daune în bazele de date de vehicule avariate din peste 32 țări, numărând 200 de milioane de înregistrări de daune.',
'931273814':'Istoricul vehiculelor din peste 32 de țări',
'-334327155':'Vom inspecta vehiculul în bazele de date ale vehicule avariate din peste 32 țări, numărând peste 200 de milioane de înregistrări ale daunelor. În secțiunea plătită veți afla dacă vehiculul este înregistrat în istoricul său drept  avariat. Înregistrările daunelor găsite pot fi însoțite de informații detaliate, cum ar fi data evenimentului, valorile odometrului, amploarea pagubei sau valoarea prejudiciului.',
'2078676994':'Înregistrări de service',
'503491296':'OK',
'1704037376':'Anulează',
'-922638497':'verificat',
'-2077712313':'până la 12 registre',
'-855779034':'Inspecția a fost deja <strong classname="ml-5">efectuată</strong>',
'131879595':'Inspecție contra cost în conformitate cu prevederile contractului',
'-2034839108':'PDF',
'2049708688':'Fără înregistrări',
'1916268628':'Olanda',
'-1425396841':'Despre noi ',
'-1521903902':'ID de protocol',
'1485582239':'Format de e-mail incorect.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Aveți un cod de reducere care vă dă dreptul să beneficiați de inspecția auto gratis. În vederea utilizării acestuia, introduceți Report ID în câmpul „Introduceți codul de reducere”.',
'-675647872':'În toate aceste cazuri, veți introduce apoi numărul voucherului în câmpul corespunzător de pe pagina de start <a href="https://ro.cebia.com/">ro.cebia.com</a> și veți avea acces imediat la informațiile pe care le-ați cumpărat, la care puteți reveni oricând în termen de 30 de zile.',
'1080548025':'AVERTISMENT: Acesta poate fi un <strong>VIN greșit</strong>. Verificați VIN-ul sau: <a href="mailto:rokvy@cebia.cz?subject=Asistență pentru informații ale sistemului de identificare ATR&amp;body=VIN: {0}">Contactați asistența</a>.',
'-439637411':'AVERTISMENT: Certificatul Cebia nu poate fi creat. Nu s-a găsit anul de fabricație.',
'-43065820':'Nu s-au găsit înregistrări cu privire la vechimea sau originea vehiculului. Dacă autovehiculul provine din străinătate, solicitați întotdeauna și verificați cu atenție documentația originală a vehiculului (ex. carte service sau carte tehnică).',
'-1362534546':'În cazul în care vehiculul a fost anunțat sau inspectat în trecut, vom afișa informații și documente foto.',
'431007496':'Record',
'-1525511285':'Descrierea tehnică indică caracteristicile tehnice de bază cu care vehiculul a fost înregistrat în registrul național al vehiculelor din țara respectivă.',
'1151204976':'Codul VIN (engl. Vehicle Identification Number) este un număr unic din 17 cifre care identifică în mod unic și neechivoc un vehicul. Este ștanțat direct pe caroseria vehiculului, raportul aferent oferind informații despre istoricul vehiculului. Pentru mai multe detalii despre codul VIN, accesați <a href=\'/detailArticle/numarul-vin-vehicle-identification-number\'>acest link</a>.',
'205732919':'VIN-ul este un cod format din 17 caractere care identifică în mod unic și neechivoc un vehicul.',
'1905716471':'*) Definiție <strong>Dauna totală economică</strong> este definită diferit în statele UE, precum și de către companiile de asigurări individuale dintr-o singură țară. Chiar dacă prejudiciul este evaluat în acest fel, aceasta nu înseamnă neapărat că vehiculul este imobil sau chiar ireparabil. Un <strong>Accident economic total</strong> reprezintă prejudiciul adus unui vehicul în cazul căruia, conform condițiilor companiei de asigurări, reparația vehiculului nu riște economică (= suma pentru repararea vehiculului se apropie de valoarea vehiculului la momentul producerii avariei sau este mai mare) sau este evaluată conform condițiilor prevăzute în contractul de asigurare.',
'-199845800':'Numărul de caractere trebuie să fie 10.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Numărul maxim de caractere este de 6.',
'1439047896':'<a href="/detailArticle/numarul-vin-vehicle-identification-number" target="_blank">VIN</a> (<a href="/detailArticle/numarul-vin-vehicle-identification-number" target="_blank">Vehicle Identification Number</a>) – Acesta este un cod unic format din 17 caractere, pe care producătorii le atribuie fiecărui vehicul fabricat. Puteți găsi VIN-ul în diferite locuri ale vehiculului sau în documentele acestuia.',
'1682180910':'<p>În bazele de date disponibile, nu avem înregistrări privind daunele vehiculului verificat. Numărul VIN pe care l-ați introdus ar putea fi incorect.</p><p>Avertisment: Sistemul Car History nu poate avea la dispoziție informații despre toate deteriorările vehiculului, unele daune nu sunt raportate companiilor de asigurări sau nu sunt disponibile în cadrul verificării online.</p>',
'-1276466490':'VIN-ul furnizat ar putea fi incorect',
'-1892907546':'Nu pot fi găsite înregistrări istorice pentru acest VIN.',
'1843204209':'Continuați',
'-716345220':'Verificați alt VIN',
'1091884080':'Recomandăm verificarea VIN-ului',
'-287497170':'Verificare de drept de gaj',
'1289833880':'Drept de gaj găsit',
'466639297':'Drept de gaj nu a fost găsit',
'-577812113':'ID de drept de gaj',
'231435384':'Verificarea drepturilor de gaj nu este momentan disponibilă. Vă rugăm să încercați să reîncărcați pagina după câteva minute.',
'-941220732':'Vehiculul este obiectul unuia sau mai multor drepturi de gaj în statele menționate, există posibilitatea de a-l confisca în cazul unei eventuale proceduri de executare a persoanei menționate în registrul de gaj. Achiziționarea unui vehicul cu drept de gaj poate fi foarte riscantă. Datele sunt obținute din registrele oficiale de gaj ale țării respective.',
'2016489332':'Rezultatul interogării constă în stabilirea dacă vehiculul nu este obiectul unui drept de gaj în statele menționate și, prin urmare, nu există niciun risc de confiscare în cazul unei eventuale proceduri de executare împotriva proprietarului curent. Datele sunt obținute din registrele oficiale de gaj ale țării respective.',
'1736313716':'Přílohy k článku',
'1021804797':'Inspecție gratuită conform prevederilor contractului',
'749816957':'Poate fi verificat',
'981194702':'Kontrola exekucí',
'-1417536272':'Verificare de drept de gaj',
'-323399576':'Rezultatul verificării poate fi revendicat. Dacă bănuiți că în rezultatul inspecției sunt furnizate informații incorecte, vă rugăm să contactați Departamentul nostru lienți la adresa <a href="mailto:info.ro@cebia.com">info.ro@cebia.com</a>. Puteți găsi mai multe informații despre reclamație <a href="/about#complaints">aici</a>.',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Report ID (dacă este disponibil)',
'818778283':'Report ID',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Operat ca taxi în anul',
'-137639801':'Report ID (completați - reclamație)',
'-1619027976':'Nota: Este posibil ca vehiculul să nu fi fost exploatat ca taxi pe întreaga perioadă.',
'-1010997259':'Pentru afaceri',
'503941074':'Informațiile detaliate și datele despre vehiculul verificat vor fi disponibile după plata serviciului.',
'1088246106':'Raportul privind istoricul mașinii va verifica:',
'-963915529':'Daune ascunse ale vehiculului',
'-1603961223':'<label classname="color-orange">este disponibil</label>',
'-1940014640':'Informații importante despre vehicul',
'1785662376':'Servicii',
'1913348655':'Doriți să verificați mai multe vehicule la un preț mai mic? Obțineți acces pentru afaceri și <b>economisiți până la 50%</b> din costurile de verificare. Vă rugăm să ne furnizați datele de contact, iar noi vă vom contacta cu o ofertă personalizată.',
'1723999193':'Disponibil după plată',
'1593405846':'Pojištění vozidla',
'670508858':'Un singur cupon de reducere poate fi folosit pentru fiecare comandă. Cupoanele de reducere nu pot fi adăugate sau combinate. Reducerea se aplică doar la Car History Report.',
'1693716169':'Metoda de plată',
'-503153747':'Car History Report',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'20% reducere pentru inspecția ulterioară',
'-1870036897':'Ați inspectat deja un vehicul și, dacă introduceți Report ID (furnizat în e-mailul primit) în termen de 30 de zile de la achiziție, veți primi o reducere la următoarea achiziție.',
'163946327':'De asemenea, vă puteți califica pentru o reducere de 50 % la următoarea inspecție a vehiculului. Puteți beneficia de reducere introducând ID-ul raportului de mai sus în câmpul cuponului de reducere "Răscumpărați cuponul de reducere" la următoarea achiziție. ',
'858336732':'50% reducere la o alta verificare',
'214251926':'Prin achiziționarea unui raport, obțineți automat o reducere pentru următoarea verificare pentru orice vehicul. Reducerea este valabilă 30 de zile de la cumpărare.',
'1317784962':'10% reducere la alte verificare',
'641443144':'20% reducere la alte verificare',
'-1318469146':'30% reducere la alte verificare',
'1939643950':'40% reducere la alte verificare',
'-1376459678':'Alte verificare <br> <b>cu reducere!</b>',
'-937620931':'Date despre vehicul',
'514768619':'Verificăm autenticitatea VIN',
'-916328171':'Verificăm identitatea vehiculului',
'1481465091':'Verificăm echipamentul vehiculului',
'-276782700':'Căutăm în baza de date a vehiculelor avariate',
'1544438189':'Verificăm înregistrările companiei de asigurări',
'2021669548':'Căutâm în istoria portalurilor de licitații',
'2022162632':'Verificăm înregistrările de la centrele de service autorizate',
'1083617557':'Verificăm înregistrările de la centrele de service neautorizate',
'993080480':'Căutăm în bazele de date ale poliției pentru vehicule furate',
'-1240510013':'Căutăm în bazele de date private pentru vehicule furate',
'-1027936662':'Verificăm vechimea vehiculului',
'1299172359':'Verificăm originea vehiculului',
'413685839':'Căutăm în cărți de service electronice',
'-473666121':'Căutăm în registrele de stat ale vehiculelor',
'-254920359':'Căutăm în bazele de date de stat ale vehiculelor taxi',
'-811021673':'Verificarea evenimentelor europene de convocare',
'1923526539':'Verificăm securitatea vehiculului',
'-1424413327':'Verificăm bazele de date de evidență a kilometrajului',
'734665361':'Verificăm înregistrările de la companiile de leasing',
'684288091':'Căutăm în evidențele stațiilor de inspecție tehnică',
'-1633964825':'Căutăm înregistrări de la stațiile de măsurare a emisiilor',
'-354982402':'Căutăm în istoria portalurilor de publicitate',
'255365524':'Căutăm în bazele de date ale dealerilor de mașini noi',
'936372740':'Căutăm în bazele de date ale dealerilor de mașini second-hand',
'-1491634158':'Verificăm înregistrările mașinilor închiriate',
'-1425645764':'Operația poate dura câteva minute',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Cream un raport',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Distribuiți Report',
'-2142411619':'În vederea distribuirii raportului, copiați linkul de mai jos și trimiteți-l oricui doriți.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Iti recomandam',
'-1025377658':'articol',
'-909166524':'articole',
'-1998523642':'articole',
'-613016004':'Oferte reduse',
'1083497926':'Alte servicii',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'O altă verificare',
'-901212011':'pentru doar',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Vă rugăm să selectați una dintre opțiuni.',
'1917667238':'Da',
'-1946020034':'Nu știu',
'-1737499797':'Vehiculul este sau a fost inmatriculat in Romania?',
'-1292409486':'Verificăm fotografiile, veți fi informat despre execuție prin e-mail.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Avertisment:</span> este posibil ca informațiile despre toate daunele vehiculului să nu fie disponibile în sistem, unele daune să nu fie raportate companiilor de asigurări sau să nu fie disponibile ca parte a unei verificări online. Vă recomandăm prin urmare o inspecție atentă a vehiculului, în mod ideal cu ajutorul unui mecanic calificat sau la centrul de service la alegere. O astfel de inspecție poate dezvălui daune suplimentare sau poate exclude.',
'-1943079155':'<span class="warning">Notă:</span> Dacă fotografiile nu corespund vehiculului verificat, vă rugăm să ne informați prin e-mail <a href="mailto:info.ro@cebia.com">info.ro@cebia.com</a>.',
'707486310':'<span class="warning">Notă:</span> este posibil ca în sistem să nu fie disponibile toate înregistrările de service ale vehiculului. Vă recomandăm să comparați înregistrările cu cartea de service sau să contactați serviciul autorizat relevant al mărcii cu o solicitare pentru un extras din cartea de service digitală a vehiculului.',
'-1997337578':'Prezentarea generală a unei evoluții a contorului de parcurs al vehiculului conține valori obținute din sistemele de informații disponibile ale partenerilor cooperanți. Conform dezvoltării graficului, puteți judeca singur dacă există o suspiciune de manipulare a stării contorului de parcurs sau nu.',
'-282194273':'Recomandare',
'-1564285314':'Este posibil să nu fie disponibile în sistem toate înregistrările privind starea contorului de parcurs din istoricul de funcționare al vehiculului.',
'993789697':'Dacă vehiculul nu înregistrează în mod continuu întregul istoric al kilometrajului de-a lungul timpului, vă <b>recomandăm</b> să verificați și înregistrările contorului de parcurs din cartea de service sau să verificați vehiculul la un garaj autorizat. Recomandăm această procedură în special pentru vehiculele în care există o perioadă de timp mai mare de doi ani între citirile contorului de parcurs.',
'2129711518':'Acestea sunt informații disponibile despre parametrii de bază, echipamentele standard și eventual suplimentare ale vehiculului din fabrică. Modificările vehiculului, cum ar fi schimbarea părții laterale a volanului, pot fi detectate prin verificarea echipamentului.',
'-593531916':'<span class="warning">Avertisment:</span> în unele cazuri, este posibil ca echipamentul complet al vehiculului să nu fie disponibil în sistem.',
};

const TRANSLATIONS_SK = {
'1901555939':'VIN',
'-971921755':'Továrenská značka',
'1891129765':'Model',
'691501523':'Typ karosérie',
'-1026019663':'Druh vozidla',
'274952045':'Palivo',
'-348535418':'Výkon',
'1000727131':'Objem',
'-440181428':'Dátum výroby',
'841597003':'Zdieľať',
'-762872471':'Zobraziť',
'-1615835216':'Odkaz',
'-466013687':'Zavrieť',
'-1655267775':'Kopírovať odkaz',
'-1448849529':'Možno vozidlo overiť i bez poznania VIN?',
'-1310016716':'Overenie vozidla',
'1410745983':'online',
'-1803530129':'Vygenerovať Cebia REPORT',
'934563996':'Výsledok kontroly',
'-1102433484':'Ukážka overenia',
'-1242381959':'História vozidla',
'417677035':'Kontrola záväzkov vozidla',
'-115937358':'Stav vozidla',
'457834942':'Časté dopyty',
'226942208':'Čo je to VIN?',
'1739046820':'Koľko overenie stojí?',
'16204687':'Ako môžem zaplatiť?',
'-1607701055':'Ako si zobrazím výsledok overenia?',
'-1969713941':'Možno výsledok overenia reklamovať?',
'1598751038':'Kde nájdem Report ID',
'-1206763576':'Zistiť viac',
'-729900752':'Online platba',
'671435147':'Zadajte číslo zľavového kódu',
'-2004947080':'Zadajte zľavový kód',
'-1083354004':'PREPOČÍTAŤ',
'-1589008349':'Meno',
'-704955254':'Priezvisko',
'-1631818401':'Ulica a číslo',
'1006101298':'Město',
'1063186093':'Krajina',
'287700219':'PSČ',
'-1411201334':'Daňové identifikačné číslo',
'513366821':'IČ DPH',
'-102071178':'Názov spoločnosti',
'-1882309597':'Identifikačné číslo firmy',
'-1515081676':'IČ ',
'1440993804':'Kontaktný e-mail',
'-1405758046':'Kontaktný telefón',
'1366947192':'Vaše telefónne číslo',
'-490851561':'Zľava 50% na ďalšie overenie',
'-108743580':'Kč',
'786987358':'Kúpiť',
'-1887658751':'Výsledky sú k dispozícii hneď po zaplatení',
'-1018049108':'Výsledok je k dispozícii po pripísaní prostriedkov na náš účet',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Bankovým prevodom',
'-1663277':'Výsledky budú k dispozícii do 48 hodín',
'1626540224':'V hotovosti v sídle Cebia',
'72162446':'Overte si vozidlo',
'757687806':'Prehľad informácií zdarma',
'1954205527':'Kde nájdem VIN kód?',
'1603459473':'Vstup po zaplatení',
'2038366987':'Zobraziť výsledok overenia',
'-374549211':'Kde nájdem Report ID?',
'1262814082':'Zadanie VIN',
'-15600132':'Dátum 1. registrácie',
'1535680605':'Report ID:',
'-1091217450':'Platnosť Report ID do:',
'-1264594150':'Dátum aktivácie Reportu:',
'-701611594':'Ľutujeme, vozidlo bohužiaľ nemožno overiť.',
'160166931':'Stávajúci majiteľ vozidla si nepraje zobrazenie údajov o vozidle.',
'-351255422':'Inzerát, ktorý prezeráte, nemá uvedený VIN vozidla.',
'1704462566':'V Inzeráte vozidla nie je uvedený celý VIN.',
'366367808':'Ako dlho trvá, než obdržím výsledok overenia?',
'2136729030':'VIN:',
'400669011':'Na zľavu máte nárok v prípade že:',
'-219050562':'Blog',
'1449193326':'O nás',
'1097675154':'Kontakt',
'2062322678':'Novinky',
'-1263831198':'História spoločnosti',
'1166836894':'Naši partneri',
'-1532347183':'Pre média',
'717698781':'Kariéra',
'1620687461':'Vozidlo je evidované ako odcudzené. Cebia REPORT nemožno vytvoriť',
'1365729306':'Číslo CR:',
'-89669431':'Rok prvej registrácie:',
'2130038984':'Stav tachometra:',
'2089870291':'Auto pod kontrolou s aplikáciou Carolina',
'1333652846':'Stáhnout zdarma',
'1347577444':'Dĺžka VIN musí byť 17 znakov',
'622759132':'Odkaz skopírovaný',
'1714373031':'Preverovaného vozidla sa môžu týkať',
'1227286459':'Nájdené zvolávacie akcie',
'-477335598':'ZVOLÁVACIE AKCIE',
'-343698081':'K preverovanému vozidlu neboli nájdené',
'-1536285860':'Žiadne zvolávacie akcie',
'-2052297753':'Dátum vyhlásenia',
'1522685090':'Detail',
'444715065':'Viac info',
'-394230090':'Musíte odsúhlasiť zadané údaje.',
'1224828864':'Nesprávna hodnota rok prvej registrácie.',
'-470093943':'Prosím zadajte rok prvej registrácie.',
'1035831822':'Nesprávna hodnota stav tachometra.',
'-1161922611':'Prosím zadajte položku stav tachometra.',
'-1383225585':'Zadajte rok prvej registrácie',
'1111232857':'Rok prvej registrácie',
'-1998715395':'Zadajte stav tachometra',
'-1962337257':'Stav tachometra',
'-1888253249':'Upresnenie modelu',
'-339477539':'- 300 Kč',
'917746804':'Jednoduchšie to už byť nemôže:',
'1405508217':'Predbežná konzultácia ZADARMO',
'127414665':'Ako uplatniť zľavu?',
'-2081078087':'VYBERTE SI TECHNIKA',
'1899967331':'Maximálny počet znakov je 20.',
'1699452695':'Chybný kód banky',
'1812875983':'Prosím zadejte položku Číslo účtu.',
'-670727524':'Prosím zadejte položku Číslo pojistné smlouvy.',
'1884544037':'ePojisteni.cz',
'138266606':'Platnosť do',
'1881823014':'Podmínky pro vrácení peněz:',
'-1548724023':'Vozidlo musí být pojištěno minimálně 30 dní',
'1331666705':'Chyba uloženia. Opakujte akciu.',
'-1393210532':'Číslo účtu',
'439576396':'Vaše číslo účtu',
'773379637':'Číslo pojistné smlouvy',
'-1281605763':'Uplatnit vrácení peněz',
'-1670752753':'Sleva na prověření v zahraničí',
'1496813111':'- 550',
'-856872708':'Základná cena',
'616158592':'ZĽAVA',
'-581170701':'Cena po zľave',
'-437309148':'s DPH',
'-1731075532':'Video',
'-189892310':'Objednat',
'98918066':'Postup pro objednání:',
'-1944610133':'Zľava na ďalšie overenie',
'-621660650':'Číslo zľavového kódu:',
'-1845841489':'Overiť ďalšie vozidlo so zľavou',
'1171588656':'Sleva na zabezpečení vozidla',
'1440663595':'- 850',
'-1503110723':'Sleva na fyzickou prověrku',
'-675817237':'- 925',
'986037818':'Zistené poškodenie',
'873964955':'Dátum',
'790922325':'Kalkulácia nákladov na opravu',
'-1825735064':'Druh udalosti',
'486919475':'Evidované miesta poškodenia',
'1685506455':'Vozidlo je financované',
'2039551191':'Financovanie nebolo nájdené',
'-570695392':'v aktuálne dostupných databázach',
'-765744228':'Inzercia',
'659651744':'Dátum zadania inzerátu',
'-695638522':'Inzerovaný počet kilometrov',
'772986188':'Inzerovaná cena',
'419477574':'Záznamy o veku a pôvode vozidla',
'-109775782':'Dátum výroby podľa výrobcu',
'-218677523':'Prvá registrácia',
'-340115089':'Prvá registrácia v ČR',
'1192563560':'Prvá registrácia v SK',
'1650211924':'Vyrobené pre trh',
'1812132824':'Strana riadenia',
'-1229728494':'Materiál (súpis)',
'-1147664160':'Práca (súpis)',
'708828581':'Prevádzkované ako taxi',
'284182756':'Vozidlo je evidované ako odcudzené',
'1244175337':'Nie je evidované ako odcudzené',
'791649880':'Zdroj',
'705052471':'Ku dňu',
'1239149764':'Kontrola identifikátorov',
'-799257304':'Model:',
'-1964821919':'Dátum prvej registrácie:',
'-1530361098':'Cena nového vozidla',
'68348546':'Aktuálna cena na trhu',
'763547310':'Dátum prvej registrácie',
'1968050624':'Uložiť',
'-1300982647':'Zoznam výbavy podľa výrobcu',
'397739850':'Kontrola farby vozidla',
'479427038':'K dispozícii',
'2038192723':'Kontrola zabezpečenia vozidla',
'-757741002':'Technický popis vozidla',
'278755414':'Návod na identifikáciu vozidla',
'1529692191':'Farba vozidla',
'-1171491320':'Identifikácia vozidla',
'1549116932':'Súpis výbavy vozidla',
'-1985480381':'Ostatné',
'-1354462940':'SKONTROLUJTE:',
'-690036295':'Dátum registrácie:',
'1506895356':'Typ zabezpečenia vozidla:',
'-749715579':'Kód značenia:',
'-329154043':'Farba vozidla:',
'1441236976':'Informácie o označení skiel',
'729768522':'Značenie skiel',
'1812345820':'NIE',
'847948448':'MÁTE ZÁJEM O ZABEZPEČENÍ VOZIDLA?',
'-1899070354':'Technický popis - základný',
'-953303':'Technický popis - rozšírený',
'1237026086':'Nesprávna hodnota Typ vozidla.',
'-259360107':'Prosím zadajte Typ vozidla.',
'-1906046398':'Minimálny počet znakov musí byť 1.',
'-69524395':'Maximálny počet znakov musí byť 30.',
'1663292305':'Prosím zadajte položku Značka.',
'814923836':'Prosím zadajte položku Model.',
'-1985695287':'Prosím zadajte položku Detail určenia.',
'244480988':'Značka',
'1602844398':'Detailné určenie',
'-1749998805':'Vyberte vozidlo',
'-1162182506':'Typ vozidla',
'1104651533':'technický preukaz - vin kód',
'-1898506589':'čelné sklo auta - vin kód',
'216861745':'Prosím odsúhlaste zadané údaje!',
'-1328097963':'Maximálny počet znakov je 9.',
'1678639740':'Prosím zadajte položku Telefón.',
'1303052228':'Prosím zadajte položku Meno.',
'1792286505':'Maximálny počet znakov je 128.',
'2122707327':'Prosím zadajte položku Priezvisko.',
'-938825602':'Maximálny počet znakov je 64.',
'1683031395':'Prosím zadajte položku Ulica a číslo.',
'661065202':'Prosím zadajte položku Mesto.',
'-83271898':'Prosím zadajte položku PSČ.',
'1018067916':'Maximálny počet znakov je 5.',
'12673416':'PSČ má nesprávny formát.',
'154576238':'Číslo Report ID má nesprávny formát.',
'-581020701':'je vyhlasovaná výrobcom vozidla povinne zo zákona vždy, keď je ohrozené zdravie, bezpečnosť, i životné prostredie. Týkajú sa spravidla výrobných chýb vozidla, ktoré sa prejavia až pri jeho prevoze a výrobca týmto spôsobom zaisťuje ich bezplatnú nápravu. Informácie sú čerpané z webu <a href="https://svolavacky.cz/" target="_blank">Svolávačky.cz</a> s pomocou systému RAPEX.',
'1969671182':'Uvedené zvolávacie akcie <strong>boli vygenerované podľa modelu, značky a roku výroby</strong> preverovaného vozidla, <strong>nie podľa jeho VIN</strong>. Vždy si preto v detaile akcie overte, <strong>či sa týkala i preverovaného vozidla</strong>. Zároveň si overte u predajcu vozidla či v autorizovanom autoservise, či vozidlo platné zvolávacie akcie podstúpilo.',
'-2076502822':'Zvolávacia akcia je vyhlasovaná výrobcom vozidla povinne zo zákona, pokiaľ vozidlo vykazuje výrobnú chybu ohrozujúcu zdravie, bezpečnosť či životné prostredie. V rámci zvolávacej akcie je chyba v autorizovanom servise zdarma odstránená. Dáta poskytuje portál <a href="https://svolavacky.cz/" target="_blank">Svolávačky.cz</a> s pomocou systému RAPEX.',
'1854708237':'Službu AUTO BEZ OBÁV neposkytuje spoločnosť Cebia, spol. s r.o. Bližšie informácie o tejto službe nájdete na webovej stránke <a href="https://autobezobav.sk/" target="_blank">Autobezobav.sk</a> a pre prípadné otázky týkajúce sa služby môžete využiť <a href="https://autobezobav.sk/kontakt/" target="_blank">kontaktný formulár</a>.',
'-1531415911':'Uzavřete pojistku prostřednictvím portálu <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> a získejte zpět peníze za prověření vozidla.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'5180415':'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'-312241960':'* Súkromné databázy umožňujú <strong>spravidla</strong> komukoľvek vložiť informácie o odcudzení.',
'1225691915':'V dostupných databázach bolo zistené, že vozidlo bolo alebo stále je <span class="warning">prevádzkované ako taxi</span>. Dôkladne skontrolujte technický stav vozidla.',
'-742868638':'Vaša e-mailová adresa',
'2033866416':'On-line platobnou kartou',
'138706621':'On-line prevod',
'-1634274740':'Zobraziť Cebia REPORT',
'1047356790':'Prosím zadajte dátum prvej registrácie.',
'-39394372':'Inzercia vozidiel s menšou zhodou',
'-862383013':'Inzercia podobných vozidiel',
'-233652021':'História vozidla',
'271847434':'Cebia REPORT',
'1918868575':'Skúsený technik vozidlo preverí priamo u predajcu kdekoľvek po celom Slovensku aj Česku a to ako v autobazáre, tak u súkromníka. Preverenie zahŕňa fyzickú kontrolu 170 bodov na vozidle vrátane diagnostiky či merania hrúbky laku. Získate on-line report všetkých nájdených závad a odporúčaní, či je vozidlo vhodné na kúpu. Všetko <b>do 48 hodín</b> po objednaní.',
'724746244':'Pri objednaní kontroly vozidla na iautomato.sk vložte do určeného poľa v košíku zľavový kód <b>AUTOTRACER</b> a zľava vám bude automaticky odpočítaná z ceny.',
'-1554923364':'(cesta technika zadarmo)',
'288042578':'Prosím zadajte položku Názov spoločnosti.',
'-1335200216':'Nesprávna hodnota IČ.',
'1693391088':'E-mail má nesprávny formát!',
'-766469985':'Prosím zadajte položku E-mail',
'-1397806656':'Súhlas so spracovaním osobných údajov',
'-189484387':'Odoberať',
'1095553640':'Všetky dopyty',
'1531150438':'Nenašli ste odpoveď?',
'-469136467':'Kontaktujte nás',
'-461584700':'Pred nákupom',
'400498991':'Zásady používania cookies',
'1329764956':'Čo je VIN kód?',
'-394767958':'Nákup',
'181431424':'Po platbe',
'1649241229':'VIN kód je vyrazený priamo na vozidle, prípadne ho nájdete v technickom preukaze a ďalších dokladoch k vozidlu. Pokiaľ chcete overiť vozidlo inzerované na internete, požiadajte o zdelenie VIN kódu predajcu. Viac o tom, <a href="/detailArticle/kde-najdem-vin-kod">kde hľadať </a> nájdete v samostatnom článku.',
'1774694482':'Odkiaľ pochádzajú záznamy o vozidlách?',
'-1122721284':'Overuje Cebia vozidlá i v zahraničí?',
'437878122':'Aké informácie sa o vozidle dozviem?',
'850725814':'Rozsah informácií sa u každého vozidla líši v závislosti na jeho veku a histórii. Rozsah informácií k vami overovanému vozidlu sa zdarma dozviete ihneď po zadaní VIN kódu v prehľade nájdených informácií.',
'-656971513':'Ako vyzerá výsledok overenia?',
'966688141':'Cena overenia vozidla sa odvíja od množstva a rozsahu informácií dostupných k overovanému vozidlu. Rozsah informácií k vami overovanému vozidlu a cenu za overenie sa dozviete ihneď po zadaní VIN kódu vozidla v prehľade nájdených informácií.',
'764893782':'Zaplatiť možno online platbou, v internetovom bankovníctve, bankovým prevodom či prostredníctvom Google Pay a Apple Pay. Platby zaisťuje bezpečná platobná brána GoPay.',
'726713260':'Za ako dlho obdržím výsledok overenia?',
'1478989945':'Výsledok overenia je vygenerovaný ihneď po prijatí platby. Služba funguje nepretržite a celý proces je automatický. Záleží teda na zvolenej platobnej metóde. Pri platbe kartou je výsledok dostupný okamžite, pri platbe bankovým prevodom až po prijatí platby.',
'1681038954':'Možno pri platbe zadať firemné údaje?',
'696259005':'Pri vyplňovaní vašich údajov možno zadať identifikačné údaje vašej firmy. Po platbe je však vygenerovaný len zjednodušený daňový doklad. Pre zaslanie faktúry nás kontaktujte na e-mail <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>.',
'-1555365601':'Výsledok overenia je vygenerovaný automaticky ihneď po prijatí platby. Odkaz na výsledok overenia vám príde tiež na e-mailovú adresu zadanú pri nákupe. Výsledok overenia je možné zobrazovať po dobu 30 dní po jeho vygenerovaní.',
'1984400663':'Platba neprebehla úspešne, čo teraz?',
'-956265022':'Pokiaľ platba z nejakého dôvodu neprebehla, vráťte sa o krok späť a realizujte platbu znovu. V prípade, že vám boli z účtu strhnuté peniaze, ale napriek tomu vám nedorazil e-mail o uskutočnení platby, kontaktujte nás na e-mail <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>.',
'-246543694':'Výsledok overenia je dostupný po dobu 30 dní po jeho vygenerovaní. Zobraziť si ho môžete pomocou odkazu zaslaného na e-mailovú adresu zadanú pri nákupe overenia.',
'592240026':'Nedorazil mi žiadny e-mail, čo s tým?',
'339130199':'E-mail po zaplatení je generovaný automaticky, pokiaľ do vašej schránky nedorazil, kontaktujte nás na e-mail <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>. Do správy prosím uveďte Report ID, ktoré nájdete vo výsledku overenia, prípadne vaše kontaktné údaje.',
'1681612939':'Zadal som nesprávny e-mail, čo teraz?',
'-818807180':'Nič sa nedeje. Napíšte nám prosím správnu adresu na e-mail <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a> a do správy uveďte Report ID uvedené vo výsledku overenia. Prístupy vám následne zašleme na správny e-mail.',
'-40057886':'Výsledok overenia možno reklamovať. Ak máte podozrenie, že sú vo výsledku overenia uvedené nesprávne údaje, obráťte sa prosím na naše zákaznícke oddelenie na <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>. Viac informácií o reklamácii nájdete na <a href="/about#complaints">stránke kontaktov</a>.',
'-755697752':'Je možné vystaviť faktúru?',
'1950759546':'Vzhľadom na cenu overenia je automaticky generovaný len zjednodušený daňový doklad. Pre vystavenie faktúry nás kontaktujte na <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>. Pre urýchlenie uveďte prosím do správy i Report ID uvedené v e-maile, ktorý vám prišiel po nákupe.',
'-1960272511':'Zadajte VIN',
'-371538970':'Kde nájdem VIN?',
'-1956291670':'Ako to funguje?',
'1850076653':'1. krok',
'-1260091754':'Dostupné údaje',
'1279291471':'2. krok',
'-811487544':'Platba',
'777550298':'3. krok',
'-1534039143':'Car History Report',
'495126992':'4. krok',
'1916048998':'Overiť',
'-580693003':'Kontakty',
'-760821457':'Potrebujete poradiť?',
'1432374431':'Kontaktný formulár',
'1838884148':'Recepcia Cebia',
'1665302400':'Sídlo spoločnosti',
'1196114687':'Česko',
'-95958777':'Fakturačné údaje',
'1808758025':'Telefón',
'275235071':'Sme k dispozícii',
'353260003':'Po – Pia 8:00 – 16:30',
'822817400':'Overiť históriu vozidla',
'-1317542970':'Overené zákazníkmi',
'-1884218466':'U každého vozidla overujeme',
'1736624969':'Najazdené kilometre',
'2137107000':'História poškodení',
'647263705':'Odcudzenie vozidla',
'-441819024':'Servisná história',
'982206269':'Záznamy inzercie',
'1753341741':'Využitie ako taxi',
'-366535509':'Profesionáli využívajú služby Cebia',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'Viete z...',
'654946563':'znakov',
'-1398891951':'Kontrola najazdených kilometrov',
'1425691903':'33 % ojazdených vozidiel na českom trhu má stočený tachometer!',
'1803799674':'Kontrola veku a pôvodu',
'1389120536':'U ojazdených vozidiel zo zahraničia je vek vozidla a jeho pôvod často falšovaný!',
'-2068933994':'Kontrola odcudzenia',
'-1184837319':'Kontrola financovania',
'-1877453535':'Kontrola poškodenia',
'64494271':'20 % ojazdených vozidiel zo zahraničia je po havárii!',
'1051433840':'Skontrolujte, či vozidlo nebolo inzerované ako havarované!',
'1076835371':'Ocenenie vozidla',
'-1805177459':'Zvolávacie akcie',
'957124168':'Počet majitelů v ČR',
'-1705145830':'Informácie o vozidle',
'1890293527':'Kontrola taxi',
'-13439485':'Prosím zadajte položku Meno',
'-1147931879':'Prosím zadajte položku Priezvisko',
'2144560301':'Nesprávna hodnota Telefón',
'-1642977586':'Prosím zadajte položku Telefón',
'-1249914076':'E-mail nemá správny formát',
'-2038223065':'Zadajte prosím správu',
'810453022':'E-mail',
'-1217737116':'Správa',
'-664538889':'Súhlas so spracovaním',
'1322823680':'Odoslať zprávu',
'56891982':'Maximálny počet znakov je 16.',
'277834303':'Ďakujeme',
'552239414':'Vaša správa bola úspešne odoslaná.',
'1851370681':'Čoskoro sa vám ozveme.',
'1952981238':'Varovanie: ',
'342579190':'Kúpiť overenie',
'1586626737':'Pokračovať',
'399846737':'Načítame záznamy',
'556543048':'Možno overiť nasledujúce informácie:',
'560876430':'Fotky a Inzercia',
'-320410471':'Nie je k dispozícii',
'1185850210':'Neboli nájdené záznamy o poškodení',
'-903608283':'Prehľad histórie vozidla',
'718416430':'Informácie o Reporte',
'668096075':'Report ID pre opakovaný vstup:',
'-1690204814':'Platnosť do:',
'-840281270':'Prevodovka',
'-1901926256':'Základné údaje o vozidle',
'-1550051512':'Typ karosérie',
'-1043441574':'VAROVANIE:',
'-709435856':'Skúsenosti zákazníkov',
'-1149927862':'Zistite, čo o našom overení hovoria ľudia',
'456577623':'Zistené havarované auto a pretočený tachometer. Ďakujem.',
'329190632':'Splnilo očakávanie, našlo poškodenie vozidla. Ďakujem.',
'1450297818':'Kontrola odcudzenia v ČR a SR',
'-1594408882':'Zľava na fyzickú kontrolu vozidla od AUTO BEZ OBÁV',
'341709606':'Chcete mať čo najväčšiu istotu, že auto je v dobrom technickom stave? Vyberte si overeného technika AUTO BEZ OBAV, ktorý s vami auto skontroluje priamo u predajcu a jasne vám odporučí, či je to dobrá kúpa alebo nie.',
'382487941':'Pri objednávke vybraného technika v AUTO BEZ OBÁV zadajte do poľa Zľavový kupón (druhý krok objednávky) číslo vášho kupónu AUTORACER. Technik vám potom počas prehliadky automaticky odpočíta 10€ z ceny prehliadky.',
'-28646667':'Povinné ručení nebo havarijní pojištění musí být sjednané prostřednictvím portálu ePojisteni.cz do 30 dní od zaplacení prověření vozidla v systému AUTOTRACER',
'-355614707':'Ponuka sa vzťahuje na poistenie osobných, úžitkových a nákladných vozidiel (nie motocyklov, štvorkoliek, trojkoliek alebo prívesov a návesov).',
'-134420730':'Nevztahuje se na pojištění uzavřené prostřednictvím portálu ePojisteni.cz před zakoupením prověření vozidla v systému AUTOTRACER',
'1195365944':'Po uzavření pojištění vyplňte číslo účtu a číslo uzavřené pojistné smlouvy a částka Vám bude automaticky zaslaná na Vámi uvedený účet v žádosti po splnění podmínek nejdéle však do 60 kalendářních dnů od zaslání žádosti.',
'-1622287889':'Ďakujeme, požiadavka bola odoslaná. Naši pracovníci Vás budú najneskôr do druhého pracovného dňa kontaktovať.',
'1069770504':'Pokud je Vámi vybrané vozidlo individuálně dovezené do ČR, doporučujeme Vám využít ještě službu prověření původu vozidla v zahraničí PROVIN.',
'-121060108':'Preveríme registráciu vozidla v krajine pôvodu, či bolo riadne odhlásené z evidencie, či bolo vozidlo vyradené z prevádzky z dôvodu totálnej havárie, či nie je v krajine evidované ako odcudzené a ďalšie skutočnosti.',
'-1158490787':'Zľavu je možné uplatniť do 30 dní od nákupu overenia vozidla a nemožno ju kombinovať s ďalšími zľavami.',
'-1471409910':'Chcete mít jistotu, že Vámi vybrané vozidlo není nelegálně předělané?  Doporučujeme Vám využít službu komplexního prověření původnosti identifikátorů vozidla VINTEST.',
'1555104762':'Služba VINTEST poskytuje klientům 100% garanci výsledku fyzického prověření vozidla. Na vozidle provedeme detailní fyzickou expertízu s využitím nejmodernější digitální diagnostiky. Cílem této služby je ověřit, jestli vozidlo má všechny identifikátory (VIN, štítky, svary karoserie) originální a není podezření, že vozidlo, nebo část vozidla pochází z trestné činnosti.',
'735500953':'Výsledkom dopytu je zistenie, či vozidlo nie je predmetom financovania (formou leasingu alebo úveru), zástavy a zapožičania u uvedených spoločností. Dáta získavame priamo z informačných systémov jednotlivých spoločností.',
'-1173444543':'Leasingové a úverové spoločnosti',
'-976513658':'*Carsharing = krátkodobý prenájom auta priamo od majiteľa (alebo zdieľanie auta medzi jeho majiteľom a záujemcom o krátkodobé zapožičanie).',
'-1479754603':'V dostupných databázach bolo zistené, že vozidlo nebolo a v súčasnosti nie je prevádzkované ako vozidlo taxislužby.',
'-467405909':'Záznamy o prevádzkovaní vozidla ako taxi',
'1930957191':'Poskytnuté údaje majú len informatívny charakter. Cebia nezodpovedá za škody vzniknuté v súvislosti s využitím poskytnutých dát.',
'-1895346741':'Zdelíme vám obvyklú trhovú cenu vozidla, pomocou ktorej si overíte, či cena oceňovaného vozidla zodpovedá ponukovej cene inzercie. Do výpočtu ceny je zahrnuté okrem iného sledovanie inzercie ojazdených vozidiel v ČR i zahraničí vrátane sledovania cien skutočných realizovaných predajov. Oceňovací algoritmus pracuje s výpočtovými modelmi postavenými na dlhodobom monitoringu trhu, nie je však schopný zohľadniť fyzický stav vozidla.',
'-985858180':'V ojedinelých prípadoch sa môže stať, že cenu vozidla nemožno určiť. Dôvodom sú nedostatočné dáta pre kvalifikované ocenenie vozidla.',
'1915347014':'Zobrazíme vám inzeráty obdobných vozidiel, ktoré sú aktuálne ponúkané na trhu, a v niektorých prípadoch tiež ceny skutočne predaných obdobných vozidiel (pomocou VIN porovnávame presnú špecifikáciu vozidiel). Pomocou inzercie podobných vozidiel si môžete porovnať, či cena overovaného vozidla zodpovedá cene podobných vozidiel na trhu. Ušetríme vám hodne času stráveného vyhľadávaním a porovnávaním podobných inzerátov na webe.',
'-767763886':'Neboli nájdené žiadne záznamy o inzercii vozidla v ČR ani v zahraničí.',
'797541609':'Skontrolujte v akom stave a s akým počtom najazdených kilometrov bolo vozidlo v minulosti inzerované. Pokiaľ bolo vozidlo inzerované s vyšším stavom tachometra, než má teraz, mohlo byť s tachometrom nezákonne manipulované.',
'-1902352540':'Odsúhlasenie zadaných údajov. Po uložení už sa nebudú dať zmeniť.',
'-2008975653':'Neboli nájdené záznamy o farbe vozidla evidované výrobcom vozidla či pri prvej registrácii vozidla v ČR.',
'7150984':'Skontrolujte, či sa aktuálna farba na vozidle zhoduje s farbou deklarovanou výrobcom alebo s farbou evidovanou po prvej registrácii v ČR (prípadne na Slovensku). V prípade, že je farba rozdielna, môže sa jednať o vozidlo prefarbené, ale i havarované či odcudzené.',
'-1664139465':'Zoznam výbavy vozidla nie je k dispozícii. Odporúčame skontrolovať funkčnosť a originalitu výbavy priamo vo vozidle.',
'1681595153':'Výsledkom kontroly je informácia, či Vami preverované vozidlo je zabezpečené formou značenia skiel a registrované v Systéme OCIS. Pokiaľ áno, zdelíme Vám vyznačený kód a dátum registrácie.',
'571758489':'Na vozidle bolo v minulosti urobené značenie skiel a je registrované v medzinárodnej databáze systému OCIS.',
'855264535':'Pokiaľ na niektorých či na všetkých sklách vozidla unikátny kód chýba, môže sa jednať o vozidlo havarované, prerobené či odcudzené.',
'388233970':'Informácie o označení skiel:',
'1615022749':'Nepodarilo sa nájsť technické parametre vozidla. Nájdete ich však vo veľkom technickom preukaze vozidla, prípadne si ich môžete vyžiadať u výrobcu vozidla.',
'704641294':'Po zadaní výberu vozidla vám poskytneme návod na základnú kontrolu identifikátorov. Návod platí všeobecne na zadanú značku a model vozidla. Pre ukážku bolo vybrané náhodne zvolené vozidlo.',
'-621659477':'Výber vozidla môže byť už čiastočne predvyplnený, na základe zadaného VIN. Pokiaľ predvyplnený výber vozidla nezodpovedá vozidlu, ktoré preverujete, upravte výber ručne.',
'152333450':'Neboli nájdené žiadne záznamy o poškodení. Čo to znamená?',
'780709713':'Ako dlho bude výsledok overenia dostupný?',
'830704335':'Zistite, aké informácie sú dostupné k vášmu vozidlu:',
'1850331238':'Super služba, vyplatí sa zaobstarať si ju. Zistila som manipuláciu s tachometrom i u predvádzacieho vozidla.',
'1705245752':'Chcela som si kúpiť auto, o ktorom mi tvrdili, že nie je havarované. Bolo havarované niekoľkokrát.',
'739619867':'Úžasná, perfektná vec, nekúpili sme za veľa peňazí havarované auto, vďaka!',
'-2071079400':'Vybral som si vozidlo, kde nebolo uvedené, že je po dvoch nehodách s vysokou škodou a tu som to objavil ešte pred kúpou. Ďakujem.',
'21202350':'Ďakujem za tento program. Skoro som prišla o 90 tisíc za auto. Auto vraj jen ľahko ťuknuté spredu, podľa majiteľa. Podľa programu 2x havarované s veľkou škodou.',
'1343185270':'Overenie ma ukľudnilo a pomohlo mi sa rozhodnúť ku kúpe.',
'2083029914':'Objavilo sa rozsiahle poškodenie o ktorom sa mi predajca nezmienil.',
'1933958141':'Zistená menšia kolízia + kontrola tachometra od dátumu registrácie.',
'1900495866':'Vďaka aplikácii som sa dozvedela, že s autom niečo bolo. Predajca mi o tom samozrejme nič nepovedal a predávajúci asi tiež mlčal. Ďakujem.',
'-1668804606':'Pri kontrole som zistil závažné nedostatky, ktoré mi predávajúci zatajil, napr. stav tachometra, havarované vozidlo v minulosti.',
'-273876487':'Zistil som že vozidlo ma stočené kilometre. Predajca uvadza 155.000 km a reálně má tak 250.000 km...',
'911857027':'Pre zobrazenie zaplateného výsledku overenia vložte Report ID',
'1167593586':'Report ID získate zakúpením overenia Car History Report.',
'-2136315768':'V prípade, že úhradu realizujete formou platobnej karty, GooglePay alebo ApplePay, Car History Report bude dostupný ihneď po platbe a odkaz na neho dostanete e-mailom.',
'868918064':'V prípade, že úhradu realizujete formou bankového prevodu, obdržíte odkaz na Car History Report e-mailom hneď potom, ako budú finančné prostriedky pripísané na bankový účet Cebia',
'1572179326':'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
'-1322783148':'Umiestnenie VIN sa líši u každej značky a modelu vozidla. Vždy ho ale nájdete v malom i veľkom technickom preukaze a často napríklad pod čelným sklom.',
'700484420':'VIN je unikátne rodné číslo každého vozidla. Nájdete ho v dokladoch od vozidla alebo priamo na ňom.',
'-851712281':'Dohľadáme všetky záznamy dostupné k overovanému vozidlu.',
'-1983041378':'Zaplatiť môžete cez zabezpečenú platobnú bránu GoPay kartou alebo prevodom.',
'-1809684191':'Car History Report je k dispozícii ihneď po zaplatení. Samozrejme vám ho pošleme tiež na e-mail.',
'541894049':'Dozviete sa hodnoty stavu tachometra v danom období. V jednoduchom grafe sa môžete presvedčiť, ako sa stav tachometra vyvíjal v čase a odhalíte jeho prípadné stočenie. Pozor: viac než 40 % ojazdených vozidiel má stočený tachometer!',
'643692561':'Zobrazíte si informácie o evidovaných škodách na vozidle. Detailne môžu byť pripojené informácie o výške škody a rozsahu poškodenia. Pozor: nezabudnite si zároveň overiť technický stav vozidla a jeho predchádzajúce poškodenie. Či sa napríklad nejednalo o totálnu škodu.',
'878444309':'Pokiaľ bolo vozidlo v minulosti inzerované, zobrazíme vám inzerciu a fotodokumentáciu k vozidlu.',
'185243225':'Zistíte detailné informácie a prehľad servisných úkonov, vykonaných v rámci opráv alebo bežného servisu vozidla. Pozor: porovnajte si záznamy so servisnou knihou a požadujte viac informácií o servisnej histórii vozidla.',
'1036319803':'Overíme pre Vás skutočný rok výroby, čo môže mať podstatný dopad na súčasnú i budúcu cenu vozidla. Rok výroby sa v technických preukazoch už neuvádza. Zapisuje sa iba dátum 1. registrácie. To sa ale od roku výroby môže líšiť až o 2 roky, nie je však výnimkou ani 6 rokov. Pritom jeden rok omladenia môže v cene vozidla znamenať i niekoľko desiatok tisíc korún, ktoré zaplatíte navyše. U väčšiny vozidiel sa tiež dozviete dátum 1. registrácie a pre akú krajinu bolo vozidlo pôvodne vyrobené.',
'-437053085':'Vozidlo overíme v aktuálne dostupných databázach odcudzených vozidiel. Jedná sa prevažne o európske databázy zo štátnych i neštátnych zdrojov. Zoznam dostupných krajín a zdrojov nájdete v platenej časti.',
'494784690':'Vozidlo preveríme v databázach finančných spoločností, autopožičovní a záložní. V platenej časti možno skontrolovať, či vozidlo nie je zaťažené financovaním, výpožičkou alebo záložňou.',
'2074346923':'Overenie v databázach vozidiel prevádzkovaných ako taxislužba. V platenej časti sa dozviete, či vozidlo bolo, alebo stále je prevádzkované ako taxi.',
'-976680067':'Zobrazíme vám zoznam zvolávacích akcií, ktoré by sa mohli týkať overovaného vozidla.',
'1665109686':'Podľa technického popisu si budete môcť sami skontrolovať dôležité parametre, na základe ktorých bolo modelové prevedenie daného typu vozidla schválené pre prevádzku. Porovnajte tieto parametre s údajmi uvedenými v technickom preukaze vozidla.',
'-719963267':'Zobrazíme Vám výpis výbavy vozidla podľa špecifikácie z jeho výroby. Môžete si tak porovnať súčasnú výbavu vozidla s tou, ktorú udáva výrobca. Pozor: pokiaľ napríklad výrobca uvádza v parametroch vozidla pravostranné riadenie, kožené čalúnenie alebo strešné okno a Vaše vozidlo má riadenie na ľavej strane, čalúnenie z látky alebo nemá strešné okno – pravdepodobne nejde o to isté vozidlo, ale môže sa jednať o vozidlo odcudzené a prerobené!  Pri akejkoľvek nezrovnalosti by ste mali venovať zvýšenú pozornosť ďalšiemu fyzickému prevereniu vozidla a predísť tak nekorektnému nákupu vozidla, ktoré môže pochádzať z trestnej činnosti.',
'-1106584159':'Vaše vozidlo je zabezpečené formou pieskovania alebo leptania skiel, prípadne na vozidle mohlo byť uskutočnené značenie dielov karosérie. Zdelíme Vám vyznačený kód a dátum registrácie.',
'1830950672':'Zobrazíme vám farbu evidovanú výrobcom vozidla a farbu po registrácii vozidla v ČR, prípadne po registrácii na Slovensku (pokiaľ máme tieto informácie k dispozícii).',
'1835889470':'Načítame dáta...',
'1020539397':'Prevádzkovateľ aplikácie Car History – Cebia, spol. s.r.o. upozorňuje užívateľov aplikácie, že uvádzané informácie boli poskytnuté do aplikácie partnermi prevádzkovateľa bez možnosti overiť ich správnosť. Všetky informácie majú výhradne informatívny charakter.',
'2054100346':'Na výsledok overenia vozidla v aplikácii Car History nie je poskytovaná záruka.',
'-167783152':'Tento Report bol vytvorený dopytom do aplikácie Car History, ktorú prevádzkuje Cebia, spol. s r.o. podľa platných Všeobecných podmienok pre používanie aplikácie Car History. Poskytnuté informácie je užívateľ oprávnený v celom rozsahu využívať len pre vlastnú potrebu. Ich ďalšie šírenie či upravovanie bez súhlasu prevádzkovateľa je zakázané.',
'-1697979879':'Riaditeľ spoločnosti Cebia, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Riaditeľ spoločnosti Cebia',
'759051798':'Spoločnosť Cebia',
'-31539157':'Sme česká spoločnosť založená v roku 1991 v Prahe. Naše služby z oblasti overovania, zabezpečenia a identifikácie vozidiel využívajú zákazníci v 9 krajinách Európy. Našimi zákazníkmi sú bežní motoristi, poisťovne, predajcovia vozidiel, štátne inštitúcie, policajné zložky i súdni znalci. Za svoju históriu sme odhalili desaťtisíce podvodov s ojazdenými vozidlami a pomohli s nákupom vozidla  miliónom zákazníkov po celej Európe.',
'-1821588851':'Reklamácie a sťažnosti',
'-276118588':'Reklamácie a sťažnosti sú pre nás cenným zdrojom podnetov na zlepšenie našich služieb. Kontaktujte nás prosím pomocou kontaktného formulára alebo na: <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>',
'-1446517877':'Reklamáciu vybavíme najneskôr do 14 dní od jej obdržania a o jej výsledku vás budeme informovať.',
'708234375':'Ozvite sa nám prostredníctvom kontaktného formulára, alebo na e-mail <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>',
'1474175101':'Všeobecné podmienky služby',
'1343500875':'Zásady ochrany osobných údajov',
'-2083968164':'Vývoj vlastného nástroja na oceňovanie vozidiel',
'907588970':'Založenie spoločnosti v Prahe',
'-770165824':'<strong>30 rokov skúseností</strong> s overovaním áut',
'-1138482741':'<strong>1 000 000+</strong> overených áut každý rok',
'154007106':'<strong>750 000+</strong> spokojných zákazníkov',
'177137909':'Databáza Cebia obsahuje viac než 1 miliardu záznamov o ojazdených vozidlách pochádzajúcich z viac než 32 krajín. Jedná sa predovšetkým o záznamy najazdených kilometrov, poškodenia vozidiel, fotografie vozidiel a servisné záznamy.',
'723486619':'Načítame dáta',
'742852638':'Obdobie:',
'787763184':'obdobie:',
'-685036969':'Vysvetlivky:',
'290784658':'Nie je k dispozícii:',
'412892373':'K vozidlu sme v danej kategórii nedohľadali žiadne informácie',
'1853282090':'K dispozícii:',
'-249038921':'XY  záznamov:',
'805146013':'K vozidlu sme v danej kategórii dohľadali XY záznamov, ktoré sa dozviete v platenej časti',
'242118549':'VŠEOBECNÉ PODMIENKY',
'521725542':'Zobrazenie všeobecných podmienok pre používanie aplikácie Car History',
'-571686819':'tu',
'639498968':'Platba za zobrazenie detailných informácií v časti Výpis z histórie vozidla alebo v časti OSVEDČENIE Cebia REPORT alebo postupne vo všetkých častiach sa Vám započíta podľa podmienok uvedených v zmluve s Cebia.',
'-1705426324':'Detailné informácie k 1 VIN si môžete zobraziť po dobu 30 dní od prvého zobrazenia tohto VIN a platíte len raz.',
'529403448':'Dôležité upozornenie',
'-821000576':'Cebia, spol. s r.o. upozorňuje užívateľov systému, že uvádzané informácie boli poskytnuté do systému partnermi prevádzkovateľa bez možnosti overiť ich správnosť. Všetky informácie majú výhradne informatívny charakter. V prípade, kedy stav vozidla je vo významnom rozpore s informáciami tu uvádzanými, odporúčame kontaktovať patričného znalca za účelom overenia skutočného stavu vozidla.',
'-1138137280':'Informácie o spracovaní osobných údajov',
'125063053':'Zobrazenie informácie o spracovaní osobných údajov užívateľov CebiaNET / Car History',
'-608864551':'K vozidlu sme v danej kategórii dohľadali informácie, ktoré sa dozviete v platenej časti',
'-1879995849':'Zadané vstupné údaje',
'754897480':'Rok prvej registrácie',
'-1834106204':'AKTUÁLNY STAV TACHOMETRA',
'404797827':'ZOBRAZIŤ CEBIA REPORT',
'1358780725':'VYGENEROVAŤ REPORT',
'-952450705':'Skontroloval som a potvrdzujem uvedené informácie',
'-1601808583':'<span classname="green">Hotové!</span> U zadaného vozidla možno overiť nasledujúce informácie:',
'-1763095911':'Možno vystaviť Cebia REPORT hodnotiaci nasledujúce kategórie:',
'761362384':'Ukážka REPORTU',
'-397155268':'ZOBRAZIŤ VÝPIS',
'1375027269':'OVERIŤ VOZIDLO',
'2055286638':'viac než',
'-1668243810':'Sme silnejší',
'1141836815':'Stávame sa súčasťou českého holdingu EAG. Vďaka tomu sa naše databázy výrazne rozširujú o záznamy z celej Európy – predovšetkým Nemecka, Rakúska či Dánska.',
'-903271711':'Uvádzame na trh online nástroj na určenie ceny ojazdených vozidiel. Vďaka našej rozsiahlej databáze záznamov o ojazdených vozidlách je veľmi presný a rýchlo si nachádza cestu do najväčších poisťovní a leasingových spoločností.',
'-1262829232':'Meníme trh s ojazdenými vozidlami',
'1367974718':'S rastúcim záujmom o výber vozidla online začíname spolupracovať s najväčšími inzertnými portálmi v strednej Európe. Výsledky nášho overenia histórie sa stávajú puncom kvality ojazdeného vozidla.',
'-300528762':'Vstup do online sveta',
'-899322976':'Naše služby sú od teraz ľahko dostupné každému z pohodlia domova. Podvody s ojazdenými vozidlami sú na svojom vrchole a my proti nim bojujeme zo všetkých síl.',
'-4875574':'Rozširujeme služby',
'1166185728':'K našim službám pridávame fyzickú kontrolu identifikátorov (VIN) vozidiel a naša databáza sa skokovo rozširuje. Spolupracujeme s poisťovňami, štátnymi orgánmi, políciou.',
'-1619922514':'Vstup do zahraničia',
'-844900279':'Nielen v Českej republike sa rozmáha obchod s odcudzenými vozidlami. Zakladáme preto medzinárodnú databázu odcudzených vozidiel, ktorá nám pomáha odhaľovať pravý pôvod ojazdených vozidiel.',
'598423318':'Traja univerzitní spolužiaci zakladajú spoločnosť Cebia. Pôvodný zámer podnikať v IT čoskoro vystriedal záujem o zabezpečovanie vozidiel.',
'-1828699417':'Exekuce/Insolvence',
'4000011':'Prověření dookladů zdarma',
'1479506336':'História vyhľadávania',
'-1478881101':'História preverovania vozidiel',
'1115668272':'História Exekúcia/Insolvencia',
'691760517':'História preverenia dokladov',
'-614781938':'Rozsah a cena overenia sa môže u každého vozidla líšiť podľa množstva dostupných informácií.',
'702191442':'Späť',
'-1034048918':'Kontrola VIN',
'-1214567423':'Referenčné číslo',
'427849990':'Vytvoriť CERTIFIKÁT kontroly vozidla',
'-1718377395':'Chyba načítania Report ID',
'877365836':'Nepodarilo sa vygenerovať platobný url.',
'-1082791212':'Nesprávna hodnota Report ID',
'-255296664':'Report ID umožňuje opakovaný vstup na Car History Report a to po dobu 30 dní od nákupu Reportu.',
'456125545':'Prevádzkovateľ aplikácie Car History - Cebia, spol. s r.o., upozorňuje užívateľa aplikácie, že uvádzané informácie boli poskytnuté do systému partnermi prevádzkovateľa bez možnosti overiť ich správnosť. Všetky informácie majú výhradne informatívny charakter.',
'-1172808350':'Kontaktujte predajcu, aby Vám zdelil VIN vozidla a následne si vozidlo overte.',
'-2079080642':'Kontaktujte predajcu, aby Vám zdelil 17-miestny VIN a následne si vozidlo overte.',
'-585052469':'Kupujete si ojazdené vozidlo? Zistite si o ňom všetko! Hlavne stav tachometra, rok výroby alebo záznamy o poškodení podľa VIN kódu.',
'76811920':'UKÁŽKA výsledku overenia náhodného vozidla.',
'1050846059':'I napriek tomu vám odporúčame dôkladnú prehliadku vozidla, ideálne s pomocou kvalifikovaného mechanika alebo vo vami vybranom servise. Taká prehliadka môže predchádzajúce poškodenie jednoznačne odhaliť alebo vylúčiť.',
'-1068264553':'Vždy skontrolujte dátum výroby deklarovaný výrobcom vozidla. V technických preukazoch sa uvádza len dátum prvej registrácie, ktorý sa však od dátumu výroby môže výrazne líšiť (napríklad i o dva roky). Vozidlo tak môže byť vydávané za mladšie, čo má výrazný vplyv na jeho trhovú cenu.',
'756232271':'Neboli nájdené žiadne záznamy o veku či pôvode vozidla. Ak pochádza vozidlo zo zahraničia, vždy si vyžiadajte a starostlivo skontrolujte pôvodný zahraničný technický preukaz.',
'-643660678':'Boli nájdené záznamy o servisných úkonoch, ktoré boli v minulosti na vozidle uskutočnené v rámci jeho opráv či bežného servisu.',
'1533967406':'Cena overenia vozidla sa odvíja od množstva a rozsahu informácií dostupných k overovanému vozidlu. Rozsah informácií (a teda i cenu) sa dozviete ihneď po zadaní VIN vozidla.',
'-1289463237':'Report je obvykle vygenerovaný pár minút po prijatí platby. Služba funguje nepretržite a celý proces je automatický. Záleží teda na zvolenej platobnej metóde. Pri platbe kartou je Report dostupný takmer okamžite, pri platbe bankovým prevodom až po prijatí platby.',
'1304585346':'Report je vygenerovaný automaticky ihneď po prijatí platby. Odkaz na neho nájdete v emaili, ktorý vám príde po zaplatení. V emaili dostanete taktiež Report ID pre opakované zobrazenie Reportu. Report možno zobrazovať po dobu 30 dní.',
'-661494819':'Chyba načítania dát.',
'-1681989289':'už ste uskutočnil/a nákup Car History Reportu, čím získavate nárok na zľavu vo výške 100 Kč na tento nákup. Zľavu uplatníte zadaním Report ID, ktoré ste dostal/a emailom, do políčka pre zľavový kód. Pozor: časové obmedzenie možnosti využitia tejto zľavy je 90 dní od nákupu Car History Reportu.',
'681093117':'získal/a jste slevový kód při prodloužení služby FLEX na další rok. Slevu uplatníte zadáním čísla slevového kódu, který jste obdržel/a na daňovém dokladu k platbě služby FLEX, do pole „Zadejte číslo kuponu“.',
'-1551909822':'Vlastníte zľavový kód Car History, ktorý Vás oprávňuje k prevereniu vozidla zdarma. Uplatníte zadaním Report ID do políčka pre zľavový kód pri platbe budúceho Reportu.',
'-103162544':'Na uvedenú e-mailovú adresu Vám po úhrade budú zaslané informácie potrebné pre zobrazenie Car History Reportu a zjednodušený daňový doklad k platbe.',
'-591911074':'Na uvedený telefón Vám po úhrade budú zaslané informácie potrebné pre zobrazení Car History Reportu a zjednodušený daňový doklad k platbe.',
'706315962':'Spoločne s informáciami potrebnými pre zobrazenie Car History Reportu dostanete vždy i zjednodušený (neadresný) daňový doklad. Pokiaľ chcete mať na daňovom doklade vyplnené Vaše fakturačné údaje (firmu, adresu, IČ, DIČ), zadajte ich prosím nižšie.',
'500915765':'Pri objednávke budete presmerovaní na platobnú bránu GoPay, kde bezpečne zaplatíte platobnou kartou. V prípade úspešnej platby budete automaticky presmerovaní na Car History Report. Zároveň na vami uvedenú e-mailovú adresu dostanete účtovný doklad a odkaz pre opätovné zobrazenie Reportu.',
'-676823504':'Pokiaľ zvolíte tento spôsob platby, budete presmerovaní na platobnú bránu GoPay, kde si vyberiete Váš bankový ústav, z ktorého budete chcieť platbu uskutočniť.',
'-764670786':'V prípade, že Vaša banka ponúka tzv. on-line prevod, tak Vás presmerujeme do Vášho internetového bankovníctva, kde budete mať predvyplnený platobný príkaz, a uhradíte služby.',
'2062466434':'Pokiaľ obdržíme platbu od banky ihneď, tak vás automaticky presmerujeme na Car History Report. V opačnom prípade prosím vyčkajte na email.',
'734607756':'Po pripísaní platby na náš účet dostanete email s daňovým dokladom a odkazom pre zobrazenie Car History Reportu.',
'1715999355':'Google Pay je rýchly a jednoduchý spôsob platenia kdekoľvek na Googli, bez zadávania platobných údajov. Môžete rýchlo zaplatiť pomocou karty uloženej v službe Google Pay. Aby ste túto službu platenia mohli využívať, musí ju tiež podporovať Vaša banka. V prípade Vašej úspešnej platby budete automaticky presmerovaní na Car History Report. Zároveň na Vami uvedenú e-mailovú adresu bude zaslaný daňový doklad a odkaz pre opätovné zobrazenie Reportu.',
'790632208':'Apple Pay je platobná metóda, ktorá umožňuje jednoduché platby pomocou karty uloženej v Apple Wallet. Aby ste túto službu platenia mohli využívať, musí ju tiež podporovať Vaša banka. V prípade úspešnej platby budete automaticky presmerovaní na Car History Report. Zároveň na Vami uvedenej e-mailovej adrese obdržíte daňový doklad a odkaz pre opätovné zobrazenie Reportu.',
'-1402859324':'V prípade, že vaša banka ponúka len tzv. off-line prevod, vygenerujeme vám pokyny k bankovému prevodu, vy následne prevediete peňažné prostriedky a keď bude čiastka pripísaná na náš bankový účet, zašleme vám email s odkazom na Car History Report a daňový doklad.',
'347099860':'Táto operácia môže trvať až 2 pracovné dni (obvykle však 24 hodín).',
'-43783036':'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
'-802148009':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla.',
'1985201267':'Najväčšia databáza údajov o autách v Európe',
'-1590601891':'<span class="warning">Upozornenie:</span> Cebia nemusí mať k dispozícii informácie o všetkých poškodeniach vozidla, niektoré škody nie sú hlásené poisťovniam, alebo nie sú v rámci online overenia dostupné.',
'-1465948017':'<strong>Systém OCIS –</strong> značení skel vozidla. Jednoduché, efektivní a cenově výhodné zabezpečení vozidla. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html\' target=\'_blank\'>Více zde.</a>',
'1553997983':'<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html\' target=\'_blank\'>Více zde.</a>',
'-124714580':'Online platbou pomocou platobnej karty, v internetovom bankovníctve, bankovým prevodom či prostredníctvom Google Pay, alebo Apple Pay.',
'-753863533':'CERTIFIKÁT KONTROLY VOZIDLA  - prehľad dostupných informácií',
'1441255407':'Kategória',
'1549893453':'Možnosť kontroly',
'1547427535':'ÁNO',
'508023484':'Kontrola dátumu registrácie',
'2076692599':'Fyzická previerka',
'-1521980547':'Kontrola stavu tachometra',
'-1009985044':'ÁNO - na základe',
'1747355819':'údajov',
'1639206028':'Kontrola pôvodu vozidla',
'-1524110425':'Kontrola platby EKO dane',
'-1961089879':'Kontrola DPH',
'1732127987':'Kontrola vážnej havárie',
'-198755848':'Kontrola registrácie',
'-1790774232':'Kontrola poškodenia povodňou',
'-1781437228':'Kontrola typu motora',
'-1344389293':'Kontrola identifikátorov vozidla',
'-499181915':'Foto',
'-687939409':'Informácie boli prevzaté z evidencií služieb dokumentovania stavov vozidiel uskutočnených spoločnosťou Cebia alebo v sieti jej zmluvných partnerov.',
'-43022982':'Dátum realizovania služby:',
'-136414993':'Stav vozidla:',
'2023782231':'Na vozidle bola uskutočnená fyzická kontrola jeho identifikátorov (razba VIN, výrobné štítky apod.). Informácie boli prevzaté z evidencie služby VINTEST vykonávané spoločnosťou Cebia alebo jej zmluvnými partnermi.',
'758499555':'Stav identifikátorov:',
'488100027':'Späť',
'-1281249872':'POKRAČOVAŤ',
'-141562572':'VYGENEROVAŤ',
'-40297285':'Môžete tak získať prehľad o vývoji ceny a údajov overovaného vozidla.',
'-1742933347':'Číslo inzercie',
'-2099386182':'Predaj',
'1560408371':'Predajná cena',
'-280814568':'ZOBRAZIŤ CERTIFIKÁT KONTROLY VOZIDLA',
'2075274708':'Dátum',
'-1248991647':'Ukrajina',
'-1779298552':'Švédsko',
'-18328562':'Lotyšsko',
'1764744897':'Litva',
'-1273046880':'Rumunsko',
'1252567719':'Bulharsko',
'445626359':'Grécko',
'-868498706':'Slovinsko',
'-1528448787':'Maďarsko',
'-387469480':'Slovensko',
'25300212':'Rakúsko',
'1771002523':'Taliansko',
'69077775':'Dánsko',
'647944028':'Poľsko',
'1160986873':'Nemecko',
'897084544':'Francúzsko',
'2056598840':'Španielsko',
'264100206':'Rusko',
'-1609348689':'Zásady spracovania Cookies',
'-365994139':'Načítať ďalšie',
'807848789':'Milióny spokojných zákazníkov',
'1677683955':'Cebia má viac než <strong>2 000 partnerov</strong> z celej Európy, ktorí poskytujú záznamy o vozidlách do aplikácie Car History.',
'652553309':'Viete, že...',
'1453750886':'Hotové!',
'1498262202':'k dispozícii',
'1514779988':'Technický stav vozidla',
'1058658544':'Zľavové kupóny Cebia',
'-2035347536':'0 záznamov',
'1050102897':'Podrobná špecifikácia výbavy vozidla poskytnutá jeho výrobcom obsahuje všetky prvky základnej i príplatkovej výbavy. Kontrolou výbavy možno odhaliť úpravy vozidla, ako napr. zmenu strany riadenia.',
'2145745364':'Výpis záznamov tachometra',
'-1636264801':'Popis',
'-1488190529':'Číslo záznamu',
'64594583':'Financované vozidlo',
'-1441353378':'Bez financovania',
'-2101084179':'január',
'-1066567335':'február',
'-1761670105':'marec',
'104663035':'apríl',
'1397259452':'máj',
'-642974117':'jún',
'613657227':'júl',
'-2044487450':'august',
'1921417285':'september',
'-1672709282':'október',
'-678062097':'november',
'1302554433':'december',
'1268839568':'V databázach servisných úkonov neboli nájdené žiadne záznamy. Vyžiadajte si k vozidlu výpis zo servisnej knihy (najlepšie z elektronickej servisnej knihy). Pravosť servisných úkonov možno skontrolovať priamo v autoservise, kde boli podľa servisnej knihy vykonané.',
'-112660439':'Tu si vytvoríte Osvedčenie Cebia REPORT, ktoré môžete poskytnúť záujemcom o Vaše auto a zvýšiť tak jeho dôveryhodnosť pri predaji alebo pri inzercii na internete.',
'-1157869217':'Záznam nájdený',
'-1350474522':'V dostupných databázach nie sú k dispozícii záznamy tachometra overovaného vozidla.',
'1040913417':'Odporúčame vám overiť ďalšie možné zdroje záznamov tachometra, napr. servisnú knižku alebo dopytom na servisnú históriu v autorizovanom servise. Pri prehliadke vozidla potom venujte pozornosť opotrebeniu hlavne tých častí, ktoré môžu napovedať počet skutočne najazdených kilometrov.',
'1010039483':'Prehliadka od 150€ (v závislosti od vybraného technika)',
'-622228786':'Email',
'-982011601':'Spojené štáty',
'786789662':'Nákup zvýhodnených balíčkov',
'737703701':'Potrebujete overiť viac vozidiel? Pre firmy i súkromné osoby ponúkame balíčky za zvýhodnené ceny: 10 overení za 5 000 Kč, 20 overení za 10 000 Kč a 50 overení za 20 000 Kč. Ak máte o jeden z balíčkov záujem, zadajte prosím nižšie svoje kontaktné údaje a do správy uveďte balíček, o ktorý máte záujem. My sa vám obratom ozveme.',
'-174399420':'Chcete nakúpiť na firmu alebo overiť viac vozidiel? Využite naše',
'845754511':'zvýhodnené balíčky!',
'-614796774':'Česko',
'1011350398':'Po – Pia 8:00 – 16:30',
'-68730396':'Ku dňu:',
'544810671':'NIE - nie je evidované',
'-1670731287':'V ČR registrované ako:',
'1597705322':'Štandardná výbava',
'16575694':'Vozidlo:',
'-846486931':'záznamov',
'-1615816320':'záznam',
'1415114012':'záznamy',
'1802294807':'fotografií',
'1122888753':'fotografie',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pruvodce?id=124&do=downloadFile">osobných údajov</a>',
'1802705032':'<a className="Link Link--lightBlue" href="/detailArticle/kde-najdem-vin-kod" target="_blank" rel="noreferrer noopener">Viac informácií</a>',
'883512613':'Overenie vozidla - kontrola histórie online',
'-1965309318':'Overenie vozidla v najväčšej databáze ojazdených vozidiel v Európe. Overenie km, odcudzenie, história poškodenia a ďalšie kontroly v až 32 krajinách.',
'-1871315210':'Výber platobnej metódy ',
'-1803227447':'Vyberte prosím vhodnú platobnú metódu.',
'-1249181167':'Novinky',
'-838385662':'Na našom blogu nájdete dôležité informácie o kontrole a kúpe ojazdených vozidiel, ako sa vyhnúť podvodom a mnoho ďalších informácií.',
'1154868985':'Časté dopyty - Online overenie vozidla',
'908158246':'Pozrite sa na najčastejšie dopyty zákazníkov k online overovaniu vozidiel Cebia. Ak máte inú otázku, radi vám ju zodpovieme.',
'-633966577':'O nás',
'1171158673':'Zistite viac o spoločnosti Cebia Car History, jej databáze a dôvodoch, prečo ju na kontrolu histórie vozidiel využívajú milióny zákazníkov v celej Európe. ',
'-1244282008':'Politika cookies',
'-792206035':'Tento web využíva rôzne súbory cookies. Niektoré cookies sú umiestňované službami tretích strán. Váš súhlas s ich používaním môžete kedykoľvek odvolať.',
'1489309005':'Vstup zdarma v rámci limitu podľa zmluvy',
'-470675791':'Skontroloval som a potvrdzujem uvedené informácie.',
'2108896817':'ZRUŠIŤ ZADANÚ ZĽAVU',
'6892736':'Zľava bola zrušená',
'-1199844274':'Kód nie je platný!',
'-552424253':'Za vstup do platenej časti je účtovaný poplatok podľa zmluvy',
'167769730':'Platba v hotovosti',
'1768026018':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
'820786512':'Kód bol prijatý, cena bola prepočítaná',
'-279227794':'Kontrola roku výroby',
'1470281039':'Kontrola lízingu/úverov v ČR',
'60776637':'Report ID',
'529459564':'Zadajte prosím Report ID',
'-861194224':'Prehliadka vozidla',
'2076412240':'nové',
'360601996':'Platnosť Report ID vypršala.',
'1762078183':'Materiál',
'822990672':'Stlačením tohto tlačidla čerpáte 1x dopyt z limitu pre dopyty zdarma podľa platnej zmluvy o APC.',
'532528312':'ojazdené',
'-319106355':'Vozidlo',
'935795728':'Ukážka overenia vozidla',
'-916000765':'Car History Report',
'-1721948126':'Ukážka overenia histórie vozidla. Overte históriu vozidla online v najväčšej databáze záznamov ojazdených vozidiel v Európe.',
'329882651':'Stlačením tohto tlačidla Vám bude naúčtovaná cena za zobrazenie detailných informácií podľa platnej zmluvy o APC.',
'-494157101':'Doklad',
'-465994559':'Fotografie',
'857656094':'Továrenská značka:',
'-2005319583':'Model vozidla:',
'339937023':'Rok prvej registrácie podľa tech. preukazu:',
'-441771827':'Načítame dáta',
'-161761801':'neoverené obdobie',
'1229398238':'VAROVANIE: Cebia REPORT nemožno vytvoriť. Vozidlo je evidované ako <strong className="bolder">odcudzené</strong>.',
'358386818':'Vek vozidla: ',
'-701699977':'Cebia REPORT nemožno vytvoriť - VIN nebolo možné jednoznačne identifikovať. Skontrolujte VIN podľa dokladov od vozidla, prípadne kontaktujte podporu.',
'-1359814132':'Ďalší obrázok',
'340541220':'Priblížiť',
'2096545526':'Oddialiť',
'-158382161':'Predchádzajúci obrázok',
'-749344895':'Získavate zľavu',
'-1755324145':'Model vozidla',
'1747292041':'Smart kód',
'1093009871':'Chytrá komunikácia pre moderné autocentrá',
'-421925379':'Chcete u svojich vozidiel propagovať, že sú overené?',
'-303554559':'Na každé vozidlo umiestnite Smart kód a zákazník sám získa výsledok overenia.',
'5128661':'Úplne jednoducho. Cebia Smart kód vytlačíte v aplikácii Car History (vo formáte A5) a umiestnite za sklom predávaného vozidla.',
'1608809132':'Zákazník si potom sám pomocou mobilného telefónu prostredníctvom QR kódu načíta základné informácie o vozidle a zobrazí si graf s počtom najazdených kilometrov.',
'-703122978':'Ostatné informácie (kompletný Car History Report) si potom môže vyžiadať priamo od Vás.',
'-566386963':'Vyplňte prosím tento stručný formulár a my sa vám ozveme.',
'-1639125181':'Odoslať',
'76421921':'Povinné položky',
'-2097738269':'Po načítaní QR kódu sa zákazníkovi v jeho mobilnom telefóne zobrazí „základný výpis informácií z histórie vozidla“',
'-80023088':'Máte záujem vytlačiť u vašich vozidiel Smart kódy? Kontaktujte naše obchodné oddelenie:',
'2094660384':'Petr Kala, vedúci obchodného oddelenia',
'361077673':'Alebo vyplňte formulár a my sa Vám ozveme:',
'1277961418':'Skutočne chcete vygenerovať Smart kód?',
'-2133968142':'Mám zľavový kód',
'-1922950659':'Platobná metóda',
'-2072875261':'Overované vozidlo',
'783887791':'Objednávka',
'-1499237034':'zdarma',
'-1854208328':'Uplatniť zľavový kód',
'-793343279':'dar',
'-1393236672':'Kosovo',
'1342791550':'História najazdených kilometrov',
'2063498881':'Voliteľná výbava',
'-152116623':'QR – Smart kód',
'-956949153':'Výška škody',
'-552950132':'údaj',
'30639535':'údaje',
'-1164317619':'Ne',
'1402944789':'Prosím, zadajte požadované údaje',
'-1812102598':'UPOZORNENIE:Nie je možné vykonať <strong>kontrolu tachometra</strong>. Výsledkom kategórie bude nedostatok informácií.',
'666398003':'Varovanie!',
'771644825':'Zadaný kód VIN môže byť nesprávny!',
'-1131044763':'Viac informácií?',
'-2054470344':'Zadaný kód VIN je nesprávny!',
'-1369269346':'V zadanom čísle VIN bola zistená možná chyba. Skontrolujte, či sa zadané číslo VIN zhoduje s číslom VIN vyrazeným na vozidle alebo v technickom osvedčení.',
'-1625517200':'Kde hľadať VIN?',
'1171142400':'Platba neprebehla. Platbu prosím opakujte.',
'-1192484737':'Bez súhlasu so Všeobecnými zmluvnými podmienkami nemôžete pokračovať',
'-247455599':'Prosím zadajte IČ spoločnosti.',
'-1944712721':'Minimálny počet znakov je 12.',
'1664270996':'Štát ',
'-862797995':'Stav vozidla zaznamenaný pri jeho predaji. Prípadné poškodenia nájdete v priloženej fotodokumentácii.',
'-1997376096':'Slovenská republika',
'-1344951568':'Register vozidiel',
'-644036535':'Databáza',
'-426412387':'Štátny register vozidiel',
'1736003883':'Údaje z technického osvedčenia',
'1610817679':'Pre vozidlo sú k dispozícii záznamy o počítadle kilometrov. V platenej časti nájdete údaje z tachometra za obdobie, ktoré uvádzame pre túto kategóriu. Na základe týchto záznamov je možné určiť, či je počet najazdených kilometrov presný, alebo či bolo s tachometrom vozidla manipulované.',
'1669009738':'Vyšetrované vozidlo bolo v minulosti inzerované alebo fotografované. V platenej časti si môžete prezrieť podrobnosti o inzerátoch, stav tachometra, fotografie vozidla a prípadne inzerovanú cenu (množstvo informácií sa môže v jednotlivých záznamoch líšiť). Záznamy môžu pochádzať zo Slovenska alebo zo zahraničia.',
'1518701163':'Máme k dispozici záznamy o stáří nebo původu vozu. V placené části lze zkontrolovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v SR/ČR či pro jakou zemi byl vůz vyroben. Rozsah informací v placené části se může u jednotlivých vozů lišit.',
'1816609203':'Rok výroby sa často falšuje, aby vozidlo vyzeralo mladšie, a teda drahšie!',
'-900812009':'Ukradnuté auto môže polícia zabaviť bez akejkoľvek náhrady!',
'-976941031':'Nie je možné overiť, či bolo zadané vozidlo prevádzkované ako taxík.',
'444450829':'až',
'982955181':'12 registrov',
'-1739651968':'Kontrola zabezpečenia',
'-167461646':'Overenie histórie vozidla',
'-1365221853':'Maximálny počet znakov je 14.',
'1302766369':'Nebolo vykonané žiadne ocenenie vozidla. Ocenenie je možné vykonať počas platnosti Reportu zadaním údajov o vozidle (dátum prvej registrácie a aktuálny stav tachometra) na našej webovej stránke po Report ID.',
'1881868699':'Kupujem pre firmu',
'631216697':'Zadajte svoj e-mail.',
'945011940':'Kontaktné údaje',
'-401861769':'Kontaktný e-mail',
'1441058305':'Ak vyplníte IČ DPH, pokúsime sa doplniť ostatné údaje z oficiálnej databázy.',
'-1181757195':'Po zaplatení vám na zadanú e-mailovú adresu pošleme odkaz na zobrazenie informácií o histórii vášho vozidla a zjednodušený daňový doklad.',
'-1768425519':'Ak chcete, aby sa na zjednodušenom daňovom doklade vyplnili vaše fakturačné údaje (firma, adresa, IČ DPH), zadajte ich do polí nižšie. Ak vyplníte IČ DPH, pokúsime sa doplniť ostatné údaje z oficiálnej databázy.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT môžete poskytnúť záujemcom o Vaše auto a zvýšiť tak jeho dôveryhodnosť pri predaji alebo pri inzercii na najväčšom inzertnom portáli - <a href="https://www.sauto.sk">www.sauto.cz</a>',
'1985240700':'Certifikát Cebia poskytuje vyhodnotenie kontrol odometrov, databáz odcudzených vozidiel, záväzkov lízingovej spoločnosti, VIN a roku výroby. Celkové hodnotenie je vyjadrené počtom hviezdičiek (0-5) a slovným hodnotením.',
'308350422':'Celkom s DPH:',
'-24797068':'Na zľavu máte nárok v prípade že:',
'-1323263000':'Máte u nás predplatenú službu FLEX a používate kód na daňovom doklade.',
'-724069837':'Máte zľavový kupón, ktorý vás oprávňuje na bezplatný nákup.',
'-127346860':'Máte kód od niektorého z našich partnerov.',
'-47884841':'bez DPH',
'881014685':'Celkom bez DPH:',
'-15294371':'Nepotvrdené IČ DPH',
'1286068311':'Oficiálna databáza platiteľov DPH nepotvrdila vaše IČ DPH. Preto nemôžeme automaticky doplniť vaše údaje. Skontrolujte svoje IČ DPH alebo vyplňte údaje o svojej spoločnosti ručne.',
'-172351568':'Databáza nereaguje',
'1839952435':'Oficiálna databáza platiteľov DPH neodpovedá. Vyplňte údaje o svojej spoločnosti ručne.',
'1678298800':'Vzor overenia vozidla',
'-654354054':'Množstvo dostupných informácií sa líši od vozidla k vozidlu',
'-1069736045':'európske <label classname="color-orange">webové portály</label>',
'1068440731':'až <label classname="color-orange">12 registrov</label>',
'-534710218':'Pôvod a vek vozidla',
'1267127794':'Všetky kontroly uvedené na tejto stránke možno vykonať pre zadané vozidlo na základe kódu VIN. Výsledky kontrol budú k dispozícii v platenej správe. Množstvo informácií obsiahnutých v platenej správe sa môže pre každé vozidlo líšiť.',
'-186613120':'V Českej republike nebol prevádzkovaný ako taxík',
'1880784559':'K dispozícii po zadaní EČV',
'2085931506':'<label classname="color-orange">dostupné</label>',
'-1765351165':'viac ako <label classname="color-orange">200 miliónov záznamov</label>',
'1815202337':'údaje z <label classname="color-orange">EÚ a USA</label>',
'-555727222':'údaje z <label classname="color-orange">18 krajín</label>',
'-1798142062':'Graf záznamov stavu tachometra',
'-1828887347':'VIN (identifikačné číslo vozidla):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Kontrolu taxislužby na Slovensku môžete vykonať zadaním EČV na <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Skontrolujte kód VIN teraz',
'-1620583517':'História',
'239060530':'História preverovania VIN',
'1122350112':'Prehľad histórie všetkých dotazov na VIN k dátumu aktivácie kupónu.',
'-1288575114':'Nebolo preverené',
'-14589513':'Zákazník',
'-1170545783':'Preverené vami',
'452068758':'Zmluvný partner Cebia',
'-1710315139':'Výsledok overenia obsahuje nájdené záznamy z histórie vozidla. Najčastejšie sú to záznamy tachometra, údaje o poškodení vozidla, záznamy z technických kontrol, fotografie vozidla či záznamy o inzercii vozidla. Viac sa dozviete v <a href="/payed/detail/exampleSK" target="_blank">Ukážke overenia</a>.',
'1607430837':'Prehľad histórie',
'-293598683':'Dátum zrušenia registrácie',
'-1790772829':'Dátum prvej registrácie vozidla',
'743843679':'Dátum registrácie vozidla',
'1370884754':'Registrované ako',
'644449918':'Farba auta pri registrácii',
'-583571734':'Dátum výroby vozidla:',
'-1219924366':'Vek vozidla',
'1595420674':'Vyrobeno s výbavou',
'1151043140':'Čas od dátumu výroby do prvej registrácie',
'708774908':'Albánsko',
'1637995084':'Severné Macedónsko',
'1001888760':'Ekonomická totálna škoda *',
'1034299871':'Poistná udalosť - ekonomická totálna škoda*',
'1209738229':'Poistná udalosť',
'913822268':'Poznámka',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praha 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'Dokončením nákupu súhlasím so <a href="https://www.cebia.cz/pruvodce?id=123&do=downloadFile" target="_blank" class="Link Link--lightBlue">všeobecnými podmienkami</a> a beriem na vedomie <a href="https://www.cebia.cz/pruvodce?id=124&do=downloadFile" target="_blank" class="Link Link--lightBlue">zásady spracovania osobných údajov</a>.',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Dátum prvej registrácie uvedený pri prihlásení',
'280331577':'Čas od výroby po prvé prihlásenie',
'1163480388':'V dostupných databázach nie sú žiadne záznamy.',
'-1319845523':'Máme k dispozícii výpočet škody na vozidle vytvorený poisťovňou alebo autoservisom, pri ktorom nepoznáme detail (miesto poškodenia). Venujte prosím pozornosť obhliadke vozidla.',
'779148402':'Poisťovňa nahlásila udalosť, pri ktorej nepoznáme podrobnosti (rozsah poškodenia). Venujte pozornosť kontrole vozidla.',
'1115915387':'Doprava zadarmo',
'-1556562078':'Zľava na kontrolu po celom Slovensku od iautomato.sk                          ',
'-556554618':'Neoverené',
'-1464599260':'Práca',
'-592361226':'Lakovanie',
'1085310093':'Druh kontroly:',
'1984850101':'Dátum kontroly',
'-1502399543':'Zobraziť protokol',
'-1961590845':'Databáza ministerstva dopravy je momentálne nedostupná, skúste to prosím neskôr.',
'-1065211891':'Protokol nie je k dispozícii',
'1708745339':'Protokol nie je k dispozícii',
'1087254174':'Získať kompletné overenie',
'2096754301':'Základné overenie vozidla ku dňu:',
'926095479':'Čo je základné overenie vozidla?',
'-254140743':'Základné overenie vozidla zahŕňa tieto kontroly:',
'1362304971':'Ak chcete získať kompletné overenie histórie vozidla, odporúčame vám zakúpiť si Kompletné overenie vozidla, ktoré môže zahŕňať aj <b>kontrolu poškodenia, históriu výpisov, fotografie vozidla, servisnú históriu</b> a mnoho ďalšieho.',
'-267677252':'Základné overenie vozidla',
'-70564196':'- 10 EUR',
'1350105297':'Ste spokojní s výsledkom kontroly histórie vozidla? Budeme radi, ak nám pošlete svoje hodnotenie.',
'1914051118':'Hodnotenie',
'1786612140':'Je nám ľúto, že kontrola histórie vozidla nesplnila vaše očakávania. Dajte nám prosím vedieť, čo by sme mali nabudúce zlepšiť.',
'-1265184280':'Chyba pri odosielaní. Zopakujte akciu.',
'-669850784':'Maximálny počet znakov je 2000.',
'1801814710':'<strong classname="color-green">Ďakujeme!</strong> Vaša recenzia bola odoslaná a pomôže nám ďalej zlepšovať naše služby.',
'-883106932':'údaje z viac ako <label classname="color-orange">32 krajín</label>',
'1408314893':'Cebia zhromažďuje záznamy o vozidlách z viac než 32 krajín. Záznamy najčastejšie pochádzajú z oficiálnych registrov, autoservisov, policajných databáz, databáz poškodených vozidiel, poisťovní či z inzertných portálov.',
'-1412306493':'Áno. Každé vozidlo sa overuje v databázach záznamov z viac než 32 krajín (vrátane Nemecka, Dánska či USA). Po zadaní VIN kódu sa v prehľade nájdených informácií zdarma dozviete, aké záznamy a z akého obdobia boli nájdené.',
'1798507708':'V takom prípade sme v našich databázach obsahujúcich viac než 200 miliónov záznamov o škodách z 32 európskych krajín a USA nenašli žiadne záznamy o poškodení overovaného vozidla. Táto skutočnosť výrazne zvyšuje pravdepodobnosť, že overované vozidlo nebolo v minulosti havarované.',
'568601301':'Záznamy o vozidlách z viac <strong>než 32 krajín</strong>',
'899127599':'32 krajín',
'-1456368295':'História vozidiel z 32+ krajín',
'1333238000':'30 000 000+ overených vozidiel',
'426416063':'Záznamy pochádzajú z 32 krajín',
'-1549476292':'Cebia overuje každé vozidlo v databáze poškodených vozidiel s viac než 200 miliónmi záznamov z 32 krajín. Najviac záznamov (62 %) pochádza z Francúzska a Nemecka.',
'-924882043':'V databázach poškodených vozidiel z viac než 32 krajín s 200 miliónmi záznamov škodných udalostí <span class="color-red">bol nájdený</span> jeden či viac záznamov o poškodení overovaného vozidla.',
'-1366090835':'V databázach poškodených vozidiel z viac než 32 krajín čítajúcich 200 miliónov záznamov škodných udalostí <span class="light-green">neboli nájdené</span> záznamy o poškodení overovaného vozidla.',
'931273814':'História vozidiel z 32+ krajín',
'-334327155':'Vozidlo porovnáme s databázou poškodených vozidiel z viac ako 32 krajín s viac ako 200 miliónmi záznamov o škodách. V platenej časti zistíte, či je vozidlo v týchto databázach evidované ako poškodené. K nájdeným záznamom škodách môžu byť priložené detailné informácie ako dátum udalosti, stav tachometra, rozsah poškodenia, výška škody alebo fotografie vozidla.',
'2078676994':'Servisné záznamy',
'503491296':'OK',
'1704037376':'Zrušiť',
'-922638497':'skontrolované',
'-2077712313':'až 12 registrov',
'-855779034':'Overenie už <strong classname="ml-5">prebehlo</strong>',
'131879595':'Overenie <strong classname="ml-5 mr-5">za poplatok</strong> podľa zmluvy',
'-2034839108':'PDF',
'2049708688':'Bez záznamov',
'1916268628':'Holandsko',
'-1425396841':'O nás',
'-1521903902':'ID protokolu',
'1485582239':'Nesprávny formát e-mailu.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Vlastníte zľavový kód Car History, ktorý Vás oprávňuje k overeniu vozidla zdarma. Uplatníte zadaním Report ID do políčka pre zľavový kód pri platbe budúceho Reportu.',
'-675647872':'Vo všetkých týchto prípadoch potom zadáte Report ID do príslušného poľa na domovskej stránke <a href="https://sk.cebia.com/">sk.cebia.com</a> a získate okamžitý prístup k zakúpeným informáciám, ku ktorým sa môžete kedykoľvek vrátiť v priebehu 30 dní.',
'1080548025':'VAROVANIE: Zadané VIN môže byť <strong>nesprávne</strong>. Skontrolujte VIN, alebo <a href="mailto:rokvy@cebia.cz?subject=Informačná podpora systému-ATR identifikácia&amp;body=VIN: {0}">kontaktujte podporu</a>.',
'-439637411':'VAROVANIE: Cebia REPORT nemožno vytvoriť. Nebol nájdený rok výroby.',
'-43065820':'Neboli nájdené žiadne záznamy o veku či pôvode vozidla. Ak pochádza vozidlo zo zahraničia, vždy si vyžiadajte a starostlivo skontrolujte pôvodný zahraničný technický preukaz.',
'-1362534546':'Ak bolo vozidlo v minulosti inzerované alebo vykonaná prehliadka, zobrazíme informácie a fotodokumentáciu.',
'431007496':'Záznam',
'-1525511285':'Údaje z osvedčenia o evidencii sú prevzaté od vnútroštátnych orgánov a odrážajú stav v čase prvej registrácie vozidla v krajine.',
'1151204976':'VIN (Vehicle Identification Number) je unikátny 17 miestny kód, ktorý jednoznačne a jedinečne identifikuje vozidlo. Vyrazený je priamo na karosérii vozidla a pod týmto kódom sú evidované všetky záznamy o vozidle. <a href="/detailArticle/vin-kod-vehicle-identification-number">Viac informácií o VIN kóde</a> nájdete v samostatnom článku.',
'205732919':'VIN je 17-miestny kód, ktorý jednoznačne a jedinečne identifikuje vozidlo.',
'1905716471':'*) Definícia <strong>Ekonomická totálna havária</strong> sa môže líšiť v rôznych štátoch EU, ale tiež u jednotlivých poisťovní v rámci jedného štátu. <strong>Aj keď je škoda takto vyhodnotená, nemusí to nutne znamenať, že je vozidlo nepojazdné alebo dokonca neopraviteľné</strong>. Ekonomická totálna havária predstavuje škodu na vozidle, pri ktorej je podľa podmienok poisťovne oprava vozidla neekonomická (=čiastka na opravu vozidla sa blíži alebo je vyššia než hodnota vozidla v dobe poškodenia), alebo je takto vyhodnotená v súlade s podmienkami stanovenými v poistnej zmluve.',
'-199845800':'Počet znakov musí byť 10.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'VIN je skratka anglického Vehicle Identification Number (identifikačné číslo vozidla). Jedná sa o unikátny kód tvorený 17 písmenami a číslicami, ktorý výrobcovia priraďujú každému vyrobenému vozidlu. VIN sa nachádza na rôznych častiach vozidla či v jeho dokladoch. Viac o VIN nájdete v <a href="/detailArticle/vin-kod-vehicle-identification-number">tomto článku</a>.',
'1682180910':'<p>V dostupných databázách nemáme k dispozícii záznamy o poškodení overovaného vozidla. Vami zadané VIN môže byť nesprávne.</p><p>Upozornenie: Systém Car History nemusí mať k dispozícii informácie o všetkých poškodeních vozidla, niektoré škody nie sú hlásené poisťovniam, alebo nie sú dostupné v rámci online overenia.</p>',
'-1276466490':'Zadané VIN môže byť nesprávne',
'-1892907546':'Históriu tohto VIN nie je možné vysledovať.',
'1843204209':'Presto overiť',
'-716345220':'Skontrolovať iné VIN',
'1091884080':'Odporúčame skontrolovať VIN',
'-287497170':'Kontrola záložných práv',
'1289833880':'Zistené záložné právo',
'466639297':'Záložné právo nebolo nájdené',
'-577812113':'Spisová značka',
'231435384':'Kontrola záložných práv nie je momentálne k dispozícii. Skúste stránku načítať znova po niekoľkých minútach.',
'-941220732':'Vozidlo je predmetom jedného alebo viacerých záložných práv v uvedených štátoch a existuje možnosť jeho zabavenia v prípadnom exekučnom konaní osoby uvedenej v záložnom registri. Nákup vozidla so záložným právom môže byť veľmi rizikový. Údaje získavame z oficiálnych registrov záloh danej krajiny.',
'2016489332':'Výsledkom dopytu je zistenie, či vozidlo nie je predmetom záložného práva v uvedených štátoch a nehrozí teda jeho zabavenie v prípadnom exekučnom konaní súčasného majiteľa. Údaje získavame z oficiálnych registrov záloh daného štátu.',
'1736313716':'Prílohy k článku',
'1021804797':'Preverenie&nbsp;<strong>zdarma</strong>&nbsp;v rámci limitu podľa zmluvy',
'749816957':'Možno skontrolovať',
'981194702':'Kontrola exekucí',
'-1417536272':'Kontrola záložných práv',
'-323399576':'Áno, je to možné. Ak máte podozrenie, že sú v Reporte uvedené nesprávne údaje, obráťte sa prosím na naše zákaznícke oddelenie: <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Otázka sa týka služby',
'1845072895':'Číslo zmluvy (ak je k dispozícii)',
'818778283':'Číslo zmluvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základné overenie',
'1521773717':'Prevádzkovaná ako taxislužba v roku',
'-137639801':'Report ID (vyplňte v prípade reklamácie)',
'-1619027976':'Poznámka: Vozidlo nemuselo byť prevádzkované ako taxík počas celého obdobia.',
'-1010997259':'Pre podnikateľov',
'503941074':'Detailné informácie a dáta o overovanom vozidle budú dostupné po zaplatení služby.',
'1088246106':'U zadaného vozidla zkontrolujeme:',
'-963915529':'Poškodenie vozidla',
'-1603961223':'data z <label className="color-orange">26 společností</label>',
'-1940014640':'Technická data',
'1785662376':'Služby',
'1913348655':'Chcete skontrolovať viac vozidiel za nižšiu cenu? Získajte prístup pre podnikateľov a <b>ušetríte až 50 %</b> nákladov na kontrolu. Prosím, poskytnite nám svoje kontaktné údaje, a my vás budeme kontaktovať s osobnou ponukou.',
'1723999193':'Dostupné po zaplatení',
'1593405846':'Poistenie vozidla',
'670508858':'V rámci jednej objednávky je možné uplatniť vždy iba jeden zľavový kód. Zľavové kódy nemožno sčítať ani vzájomne kombinovať. Zľava platí iba pre Car History Report.',
'1693716169':'Platobná metóda',
'-503153747':'Car History Report',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategória je dostupná iba v kompletnom overení',
'2071045760':'Vozidlo je možné preveriť v databázach poškodených vozidiel z viac ako 32 krajín s vyše 200 miliónmi záznamov škodových udalostí.',
'438159823':'V kompletnom overení sa dozviete, či je v týchto databázach vozidlo v jeho histórii evidované ako poškodené. K nájdeným záznamom poškodenia môžu byť priložené detailné informácie ako dátum udalosti, stav tachometra, rozsah poškodenia, výška škody alebo fotografie vozidla.',
'698074592':'Získajte kompletné overenie so zľavou',
'-1347227017':'Je možné skontrolovať záznamy o starobe a pôvode vozidla. Kompletné overenie môže obsahovať dátum výroby vozidla, dátum uvedenia do prevádzky, dátum prihlásenia vozidla v SR či pre akú krajinu bolo vozidlo vyrobené. Pomocou týchto informácií je možné ľahko skontrolovať, či vozidlo skutočne pochádza z krajiny, ktorú deklaruje predajca.',
'1239291972':'Možno skontrolovať, či sa vozidlá mohli týkať jedna alebo viacerých zvolávacích akcií. Zvolávacia akcia je vyhlasovaná výrobcom v prípade, že má vozidlo výrobnú závadu ohrozujúcu zdravie, bezpečnosť či životné prostredie. V rámci zvolávacej akcie je závada zadarmo opravená v autorizovanom servise. Vďaka informáciám v kompletnom overení môžete skontrolovať, či vozidlo všetky platné zvolávacie akcie podstúpilo.',
'1463592294':'Kompletné overenie môže obsahovať detailné informácie o vozidle, ktoré môžu zahŕňať jeho technické parametre, zoznam výbavy podľa výrobcu, návod na identifikáciu vozidla, údaje z technického preukazu, záznamy o stavoch tachometra a mnoho ďalších užitočných informácií.',
'1982475919':'Je možné overiť, či bolo vozidlo v minulosti inzerované alebo fotografované. K nájdeným záznamom inzercie môžu byť priložené detailné informácie, ako dátum inzercie, stav tachometra, inzerovaná cena alebo fotografie vozidla. Pomocou historických fotografií vozidla môžete skontrolovať, či nebolo vozidlo v minulosti inzerované ako havarované.',
'1201671594':'Je možné overiť, či je vozidlo zabezpečené značením skiel OCIS. Kompletné overenie môže obsahovať dátum inštalácie zabezpečenia a unikátny kód vyznačený na sklách. Riziko odcudzenia je u takto zabezpečeného vozidla 30-krát nižšie.',
'855265460':'Zľava 50% na ďalšie overenie',
'-1870036897':'Jste si již u nás nějaký vůz prověřoval/a, a zadáním čísla kuponu (uveden v obdrženém emailu) do 30 dnů od nákupu získáváte slevu 50% na další prověření.',
'163946327':'Zároveň máte nárok na zľavu 50% na ďalšiu prehliadku vozidla. Ak chcete zľavu uplatniť, zadajte vyššie uvedené ID Reportu do poľa pre zadanie zľavového kupónu v poli "Uplatniť zľavový kód" pri ďalšom nákupe.',
'858336732':'Zľava 50% na ďalšie overenie',
'214251926':'Nákupom overenia získavate automaticky zľavu na ďalšie overenie akéhokoľvek ďalšieho vozidla. Platnosť zľavy je 30 dní od nákupu.',
'1317784962':'Zľava 10 % na ďalšie overenie',
'641443144':'Zľava 50% na ďalšie overenie',
'-1318469146':'Zľava 30 % na ďalšie overenie',
'1939643950':'Zľava 40 % na ďalšie overenie',
'-1376459678':'Ďalšie overenie<br> <b>so zľavou!</b>',
'-937620931':'Data o vozidle',
'514768619':'Overujeme pravosť kódu VIN',
'-916328171':'Overujeme totožnosť vozidla',
'1481465091':'Kontrolujeme vybavenie vozidla',
'-276782700':'Vyhľadávame v databázach poškodených vozidiel',
'1544438189':'Preverujeme záznamy z poisťovní',
'2021669548':'Vyhľadávame v histórii aukčných portálov',
'2022162632':'Preverujeme záznamy z autorizovaných servisov',
'1083617557':'Preverujeme záznamy z neautorizovaných servisov',
'993080480':'Prehľadávame policajné databázy odcudzených vozidiel',
'-1240510013':'Prehľadávame súkromné databázy odcudzených vozidiel',
'-1027936662':'Kontrolujeme vek vozidla',
'1299172359':'Kontrolujeme pôvod vozidla',
'413685839':'Prehľadávame elektronické servisné knižky',
'-473666121':'Prehľadávame štátne registre vozidiel',
'-254920359':'Prehľadávame štátnej databázy vozidiel taxi',
'-811021673':'Kontrolujeme európske zvolávacie akcie',
'1923526539':'Kontrolujeme zabezpečenie vozidla',
'-1424413327':'Kontrolujeme databázy záznamov najazdených kilometrov',
'734665361':'Preverujeme záznamy z leasingových spoločností',
'684288091':'Prehľadávame záznamy zo staníc technickej kontroly',
'-1633964825':'Prehľadávame záznamy zo staníc merania emisií',
'-354982402':'Prehľadávame históriu inzertných portálov',
'255365524':'Prehľadávame databázy predajcov nových vozidiel',
'936372740':'Prehľadávame databázy predajcov ojazdených vozidiel',
'-1491634158':'Overujeme záznamy z autopožičovní',
'-1425645764':'Operácia môže trvať niekoľko minút',
'1220599811':'Dovoľujeme si Vám ponúknuť jednoduché, efektívne a dostupné zabezpečenie Vášho nového vozidla formou bezpečnostného značenia skiel - OCIS. Tento spôsob zabezpečenia znižuje pravdepodobnosť odcudzenia Vášho vozidla až o 96% a pri niektorých poisťovniach získate až 10% zľavu na poistnom.',
'-1744852741':'Vytvárame report',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Zdieľať overenie',
'-1027095167':'Kompletné overenie',
'457573591':'Zdieľať Report',
'-2142411619':'Pre zdieľanie Reportu skopírujte odkaz nižšie a zašlite ho komukoľvek, s kým chcete Report zdieľať.',
'-343899855':'Vyberte prosím, ktorú verziu preverenia chcete zdieľať.',
'-2056420236':'Pre inzerciu vozidla pri predaji',
'972374988':'Zdieľať základné overenie',
'468810525':'Základné overenie obsahuje základné kontroly a informácie o histórii vozidla. Je určené predovšetkým pri jeho predaji.',
'-2024479655':'Zobraziť základné overenie',
'100549150':'<b>Report ID:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Report ID skopírované',
'1855925079':'Odporúčame',
'-1025377658':'položka',
'-909166524':'položky',
'-1998523642':'položiek',
'-613016004':'Zvýhodnené ponuky',
'1083497926':'Ostatné služby',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>"Bazar":</b><br> Skopírujte nižšie uvedené Report ID a zadajte ho do určeného poľa pri vytváraní inzerátu.',
'893308548':'<b>Ostatné portály:</b><br> Skopírujte nižšie uvedený odkaz a vložte ho priamo do textu inzerátu.',
'1903758389':'Ďalšie overenie',
'-901212011':'za',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Kontrolujeme fotografie, o prevedení budete informovaní e-mailom.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Upozornenie:</span> V systéme nemusia byť k dispozícii informácie o všetkých poškodeniach vozidla, niektoré škody nie sú hlásené poisťovniam, alebo nie sú v rámci online preverenia dostupné. Odporúčame teda starostlivú prehliadku vozidla, ideálne s pomocou kvalifikovaného mechanika alebo vo vami vybranom servise. Taká prehliadka môže odhaliť ďalšie poškodenie alebo ich vylúčiť.',
'-1943079155':'<span class="warning">Poznámka:</span> Ak fotografie nezodpovedajú overovanému vozidlu, prosím informujte nás na email <a href="mailto:info.sk@cebia.com">info.sk@cebia.com</a>.',
'707486310':'<span class="warning">Poznámka:</span> V systéme nemusia byť k dispozícii všetky servisné záznamy vozidla. Odporúčame porovnať záznamy so servisnou knihou alebo sa obrátiť na príslušný autorizovaný servis značky so žiadosťou o výpis z digitálnej servisnej knihy vozidla.',
'-1997337578':'Prehľad vývoja stavu tachometra vozidla obsahuje hodnoty získané z dostupných informačných systémov spolupracujúcich partnerov. Podľa vývoja grafu môžete sami posúdiť, či existuje podozrenie na manipuláciu so stavom tachometra alebo nie.',
'-282194273':'Odporúčanie',
'-1564285314':'V systéme nemusia byť k dispozícii všetky záznamy stavov tachometra z histórie prevádzky vozidla.',
'993789697':'Pokiaľ u vozidla nie je priebežne zaznamenaná celá história kilometrového nájazdu v čase, <b>odporúčame</b> skontrolovať záznamy tachometra aj v servisnej knihe, alebo vozidlo nechať skontrolovať v autorizovanom autoservise. Tento postup odporúčame predovšetkým pri vozidlách, kde je medzi stavmi tachometra časový úsek dlhší ako dva roky.',
'2129711518':'Ide o dostupné informácie základných parametrov, štandardnej a prípadne doplnkovej výbavy z výroby. Kontrolou výbavy je možné odhaliť úpravy vozidla ako napr. zmenu strany riadenia.',
'-593531916':'<span class="warning">Upozornenie:</span> V systéme nemusí byť v niektorých prípadoch k dispozícii kompletná výbava vozidla.',
};

const TRANSLATIONS_US = {
'1901555939':'VIN ',
'-971921755':'Car brand',
'1891129765':'Model ',
'691501523':'Body type',
'-1026019663':'Vehicle type',
'274952045':'Fuel',
'-348535418':'Power',
'1000727131':'Engine capacity',
'-440181428':'Date of manufacture',
'841597003':'Share',
'-762872471':'View',
'-1615835216':'Link',
'-466013687':'Close',
'-1655267775':'Copy link',
'-1448849529':'Can I check vehicle history even if I do not know the vehicle\'s VIN?',
'-1310016716':'Vehicle check',
'1410745983':'online ',
'-1803530129':'Generate Cebia REPORT',
'934563996':'Result of the check',
'-1102433484':'Sample Report ',
'-1242381959':'Vehicle history',
'417677035':'Check of vehicle financing',
'-115937358':'Vehicle status',
'457834942':'FAQ',
'226942208':'What is a VIN?',
'1739046820':'How much does a Car History Report cost?',
'16204687':'How can I pay? ',
'-1607701055':'How to access the Car History Report? ',
'-1969713941':'How to make a complaint about a Car History Report? ',
'1598751038':'Where do I find the Report ID',
'-1206763576':'More information',
'-729900752':'Payment',
'671435147':'Enter the code of your discount coupon',
'-2004947080':'Discount code',
'-1083354004':'RECALCULATE',
'-1589008349':'Name',
'-704955254':'Surname',
'-1631818401':'Street and house number',
'1006101298':'City',
'1063186093':'Country',
'287700219':'Postal code',
'-1411201334':'Tax ID',
'513366821':'VAT ID',
'-102071178':'Company name',
'-1882309597':'Company ID',
'-1515081676':'Company ID',
'1440993804':'Contact email',
'-1405758046':'Phone number',
'1366947192':'Your phone number',
'-490851561':'Discount 100 CZK for your next Car History Report',
'-108743580':'CZK',
'786987358':'Buy',
'-1887658751':'The Car History Report is available immediately',
'-1018049108':'Results are usually available within 60 minutes',
'630102694':' Google Pay',
'-1698989463':'Apple Pay ',
'-663138552':'Bank transfer',
'-1663277':'Results will be available within 48 hours',
'1626540224':'In cash at Cebia headquarters',
'72162446':'Check vehicle history',
'757687806':'Free overview of available information',
'1954205527':'Where to find the VIN?',
'1603459473':'Enter your Report ID',
'2038366987':'View result',
'-374549211':'Where to find the Report ID?',
'1262814082':'Enter VIN',
'-15600132':'Date of first registration',
'1535680605':'Report ID:',
'-1091217450':'Report ID valid until:',
'-1264594150':'Date of Car History Report purchase:',
'-701611594':'We are sorry, this car can´t be checked. ',
'160166931':'Current owner doesn´t want the car data to be shown. ',
'-351255422':'The ad you are viewing does not mention the vehicle VIN number.',
'1704462566':'The full VIN is not listed in the vehicle advertisement.',
'366367808':'How long does it take to receive the purchased Car History Report? ',
'2136729030':'VIN:',
'400669011':'You are entitled to a discount if:',
'-219050562':'Blog',
'1449193326':'About us',
'1097675154':'Contact',
'2062322678':'News',
'-1263831198':'Company history',
'1166836894':'Our partners',
'-1532347183':'For media',
'717698781':'Careers',
'1620687461':'The vehicle is listed as stolen. Certificate can not be generated. ',
'1365729306':'Certificate Number:',
'-89669431':'Year of first registration',
'2130038984':'Mileage (km)',
'2089870291':'Car under control with the Carolina app',
'1333652846':'Download for free',
'1347577444':'VIN must contain 17 characters.',
'622759132':'Link copied',
'1714373031':'The vehicle being inspected may be subject to',
'1227286459':'Recall events found',
'-477335598':'Recall',
'-343698081':'The following records could not be found for the vehicle under inspection',
'-1536285860':'No car recall events',
'-2052297753':'Announcement date',
'1522685090':'Detail ',
'444715065':'More info',
'-394230090':'Please confirm that the details you entered are correct.',
'1224828864':'Invalid value - date of first registration',
'-470093943':'Please enter the year of first registration',
'1035831822':'Incorrect mileage format',
'-1161922611':'Please enter mileage',
'-1383225585':'Enter the year of first registration',
'1111232857':'Year of first vehicle registration',
'-1998715395':'Enter odometer status',
'-1962337257':'Odometer reading',
'-1888253249':'Specification of the model',
'-339477539':'- 300 CZK',
'917746804':'It can\'t get any easier:',
'1405508217':'First consultation for FREE',
'127414665':'How do I claim the discount?',
'-2081078087':'SELECT A TECHNICIAN',
'1899967331':'The maximum number of characters is 20',
'1699452695':'Wrong bank code',
'1812875983':'Enter your bank account number',
'-670727524':'Please enter your insurance policy ID.',
'1884544037':'ePojisteni.cz ',
'138266606':'Valid until',
'1881823014':'Refund policy:',
'-1548724023':'The vehicle must be insured for at least 30 days',
'1331666705':'Save failed. Try again.',
'-1393210532':'Account number',
'439576396':'Your account number',
'773379637':'Insurance policy number',
'-1281605763':'Apply for a refund',
'-1670752753':'Discount code for our service PROVIN (service that verifies vehicle origin)',
'1496813111':'- 550',
'-856872708':'Basic price',
'616158592':'DISCOUNT',
'-581170701':'Price after discount',
'-437309148':'including VAT',
'-1731075532':'Video ',
'-189892310':'Order',
'98918066':'How to order:',
'-1944610133':'Discount on your next Car History Report',
'-621660650':'Report ID:',
'-1845841489':'Check another car with this discount',
'1171588656':'Vehicle security discount',
'1440663595':'- 850',
'-1503110723':'Discount on a physical check of the vehicle',
'-675817237':'- 925',
'986037818':'Damage found',
'873964955':'Date',
'790922325':'Repair cost calculation',
'-1825735064':'Type of insurance claim',
'486919475':'Recorded damage sites',
'1685506455':'The car is financed',
'2039551191':'Financing not found',
'-570695392':'in the databases that are currently available',
'-765744228':'Advert',
'659651744':'Date of advertisement',
'-695638522':'Advertised mileage',
'772986188':'Advertised price',
'419477574':'Records of vehicle age and origin',
'-109775782':'Date of manufacture according to producer ',
'-218677523':'First registration',
'-340115089':'First registration in Czechia',
'1192563560':'First registration in Slovakia',
'1650211924':'Manufactured for market',
'1812132824':'Steering side',
'-1229728494':'Material (list)',
'-1147664160':'Work (list)',
'708828581':'Used as a taxi vehicle',
'284182756':'Vehicle is reported as stolen ',
'1244175337':'Not recorded as stolen',
'791649880':'Source',
'705052471':'On',
'1239149764':'Check of identifiers',
'-799257304':'Model: ',
'-1964821919':'Date of first registration:',
'-1530361098':'Price of new vehicle',
'68348546':'Present market price',
'763547310':'Date of first registration',
'1968050624':'Save',
'-1300982647':'Equipment list ',
'397739850':'Checking the vehicle colour',
'479427038':'Available',
'2038192723':'Check of vehicle security',
'-757741002':'Technical description of the vehicle',
'278755414':'Car identification instructions',
'1529692191':'Vehicle colour',
'-1171491320':'Vehicle identification',
'1549116932':'Vehicle equipment list',
'-1985480381':'Other',
'-1354462940':'CHECK:',
'-690036295':'Registration date:',
'1506895356':'Vehicle security:',
'-749715579':'Marking code:',
'-329154043':'Vehicle colour:',
'1441236976':'Information on glass marking',
'729768522':'Glass marking',
'1812345820':'NO',
'847948448':'ARE YOU INTERESTED IN SECURING YOUR VEHICLE?',
'-1899070354':'Basic technical description',
'-953303':'Technical description - extended',
'1237026086':'Invalid value - vehicle type',
'-259360107':'Please enter vehicle type',
'-1906046398':'The minimum number of characters must be 1.',
'-69524395':'The maximum number of characters is 30',
'1663292305':'Please enter car brand',
'814923836':'Please enter vehicle model',
'-1985695287':'Please enter the destination detail.',
'244480988':'Make',
'1602844398':'Detailed identification',
'-1749998805':'Select vehicle',
'-1162182506':'Vehicle type',
'1104651533':'vehicle registration certificate - VIN',
'-1898506589':'car windscreen - VIN',
'216861745':'Please confirm the entered data',
'-1328097963':'The maximum number of characters is 9',
'1678639740':'Please enter your phone number',
'1303052228':'Please enter your name',
'1792286505':'The maximum number of characters is 128',
'2122707327':'Please enter your surname',
'-938825602':'The maximum number of characters is 64',
'1683031395':'Please enter street and house number.',
'661065202':'Please enter a town',
'-83271898':'Please enter a postal code',
'1018067916':'The maximum number of characters is 5',
'12673416':'Incorrect postal code format',
'154576238':'Incorrect Report ID format',
'-581020701':'is issued by the vehicle manufacturer whenever health, safety or the environment are at a considerable risk. Most often, these events are triggered by a hidden manufacturing defect of the vehicle that become apparent only after the vehicle has been in operation for some time. As a rule, the manufacturer must repair these vehicle defects free of charge. For more information, please visit the following website <a href=\'https://car-recalls.eu/\' target=\'_blank\'>car-recalls.eu</a> in cooperation with the EU information system RAPEX.',
'1969671182':'The listed <strong>recall events are sorted by model, make and year of vehicle manufacture</strong>, they are not sorted by vehicle VIN. Therefore, always check the recall details to see if it concerned specifically the vehicle you are inspecting. At the same time, check with the vehicle\'s dealer or authorised garage that the vehicle has undergone a recall.',
'-2076502822':'Recall event is issued by the vehicle manufacturer whenever the vehicle poses a considerable risk to the health, safety or the environment. As a rule, the manufacturer must repair these vehicle defects free of charge. For more information, please visit the following website <a href=\'https://car-recalls.eu/\' target=\'_blank\'> Car-recalls.eu</a> in cooperation with the EU information system RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Sign your car insurance via <a href=\'https://www.epojisteni.cz/poptavka?pid=2226\' target=\'_blank\'>ePojisteni.cz</a> and get a refund on your Car History Report.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'The Order button will redirect you to the webpage of VINTEST online reservation form. Please fill out this order form and in the notes section, please enter the following password: <strong>Car History</strong> and your <strong>Car History Report ID</strong>.',
'5180415':'The Order button will redirect you to the webpage of VINTEST online reservation form. Please fill out this order form and in the notes section, please enter the following password: <strong>AUTOTRACER</strong> and your <strong>Car History Report ID</strong>',
'-312241960':'* Anyone can <strong>usually</strong> add theft records into private databases.',
'1225691915':'According to our databases, the vehicle has been used or is still <span class="warning">being used as a taxi</span>. Check the roadworthiness of the car thoroughly.',
'-742868638':'Your e-mail address',
'2033866416':'Online by card',
'138706621':'Online transfer',
'-1634274740':'View Cebia REPORT Certificate',
'1047356790':'Please enter the year of first registration',
'-39394372':'Adverts for vehicles with a lower match %',
'-862383013':'Adverts for the sale of similar vehicles',
'-233652021':'VEHICLE HISTORY',
'271847434':'Cebia Certificate',
'1918868575':'An experienced technician will inspect the car directly at the point of sale anywhere in the Czech Republic. The inspection includes a physical check of 170 points on the car including diagnostics and paint thickness measurement. You\'ll get an online report of any defects found and a recommendation on whether the car is suitable for purchase. All within <strong>48 hours</strong> of the appointment.',
'724746244':'When ordering a car check from Automato.cz, enter the discount code <strong>AUTOTRACER</strong> in the designated field in the shopping cart and the discount will be automatically deducted from the price. ',
'288042578':'Please enter a company name',
'-1335200216':'Invalid value - company ID.',
'1693391088':'Incorrect e-mail format',
'-766469985':'Please enter your e-mail',
'-1397806656':'I agree with the processing of personal data',
'-189484387':'Subscribe',
'1095553640':'All questions',
'1531150438':'Didn\'t find the answer to your question? ',
'-469136467':'Contact us',
'-461584700':'Before you buy',
'400498991':'Cookie Policy',
'1329764956':'What is a VIN? ',
'-394767958':'Purchase',
'181431424':'After the purchase',
'1649241229':'VIN is stamped directly on the vehicle or it can be found on the vehicle registration certificate and other vehicle documents. If you want to check a car advertised on the internet, ask your car dealer for a VIN code. More information on where to look for a VIN can be found <a href=\'/detailArticle/where-do-i-find-the-vin-code\'>here</a>. ',
'1774694482':'Where do vehicle records come from? ',
'-1122721284':'Does Cebia also check vehicles abroad? ',
'437878122':'What information can I find out about the vehicle?',
'850725814':'The scope of available information varies from vehicle to vehicle depending on its age and history. After you enter the VIN code, we immediately tell you what information we can provide to you about the vehicle in <a href="/payed/detail/exampleUS">Sample Report</a>.',
'-656971513':'What does the Car History Report look like? ',
'966688141':'The cost of the Report depends on the amount and range of information available regarding the vehicle being checked. You can find out the scope of available information and the associated cost of the Car History Report by entering the vehicle\'s VIN code.',
'764893782':'You can pay online with a credit card or via Google Pay and Apple Pay. Payments are handled by a secure payment gateway GoPay.',
'726713260':'How long will it take me to receive the Report?',
'1478989945':'The Report is generated immediately after the payment is received. The service works around the clock and the whole process is automatic.  ',
'1681038954':'Can I enter my company details when making the payment? ',
'696259005':'When filling in your details, you can enter your company\'s details. However, only a simplified tax document is generated after payment. If you want to receive an invoice, please contact us at <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a>.',
'-1555365601':'The Car History Report is generated automatically as soon as the payment is received. A link to the result and your Report ID will also be sent to the email address you entered at the time of purchase. The Car History Report can be viewed online for 30 days after it has been generated. ',
'1984400663':'The payment was not successful, now what? ',
'-956265022':'If for some reason the payment did not go through, go back one step and make the payment again. If you have been charged but still have not received a confirmation email, please contact us at <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a>.',
'-246543694':'It will be available for 30 days after it is generated. You can view it using a link sent to the email address you provided when you purchased the Report. ',
'592240026':'I have not received a confirmation email, what can I do? ',
'339130199':'An email is automatically generated after payment, if it has not arrived in your inbox, please contact us at <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a>. Please include the Report ID or VIN number in the message. ',
'1681612939':'I entered the wrong email, now what? ',
'-818807180':'No worries. Please email us at <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a> with the correct email address and include the Report ID found in the Report. We will then send the access link to the correct email address. ',
'-40057886':'The Report can be complained about. If you suspect that incorrect information is included in the Report, please contact our customer service department at <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a>. ',
'-755697752':'Can an invoice be issued? ',
'1950759546':'Due to the cost of the verification, only a simplified tax document is automatically generated. Please contact us at <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a> for an invoice. To speed things up, please also include the coupon number in the email you received after your purchase.',
'-1960272511':'Enter VIN',
'-371538970':'Where to find the VIN?',
'-1956291670':'How does it work?',
'1850076653':'STEP 1',
'-1260091754':'Available data',
'1279291471':'STEP 2',
'-811487544':'Payment',
'777550298':'STEP 3',
'-1534039143':'Car History Report',
'495126992':'STEP 4',
'1916048998':'Check VIN',
'-580693003':'Contacts',
'-760821457':'Do you need advice? ',
'1432374431':'Contact form',
'1838884148':'Cebia reception',
'1665302400':'Company headquarters',
'1196114687':'Czechia',
'-95958777':'Billing information',
'1808758025':'Phone',
'275235071':'We are available',
'353260003':'Mon - Fri: 8:00 - 16:30 CET',
'822817400':'Check vehicle history',
'-1317542970':'Trusted service',
'-1884218466':'For each car we check',
'1736624969':'Mileage',
'2137107000':'Past damages',
'647263705':'Vehicle theft',
'-441819024':'Service history',
'982206269':'Listing records',
'1753341741':'Use as taxi',
'-366535509':'Vehicle experts use Cebia services',
'-1435496559':'Generali Česká spořitelna Logo',
'1847411491':'Logo Kooperativa',
'1734653310':'You know us from...',
'654946563':'characters',
'-1398891951':'Mileage',
'1425691903':'Odometer rollback is present in 33% of used cars advertised in the Czech car marketplace!',
'1803799674':'Vehicle age and origin',
'1389120536':'Both the age and the origin of used cars imported from abroad are often falsified!',
'-2068933994':'Theft check',
'-1184837319':'Check of financing',
'-1877453535':'Damage check',
'64494271':'20% of imported used cars are wrecked!',
'1051433840':'Check if the car has been advertised as crashed!',
'1076835371':'Vehicle valuation',
'-1805177459':'Recall events',
'957124168':'Number of owners in Czechia',
'-1705145830':'Vehicle details',
'1890293527':'Taxi check',
'-13439485':'Please enter your name',
'-1147931879':'Please enter your surname',
'2144560301':'Invalid phone number',
'-1642977586':'Please enter your phone number',
'-1249914076':'Incorrect e-mail format',
'-2038223065':'Please enter your message',
'810453022':'E-mail',
'-1217737116':'Message',
'-664538889':'I agree to the processing of',
'1322823680':'Send message',
'56891982':'The maximum number of characters is 16.',
'277834303':'Thank you',
'552239414':'Your message has been sent.',
'1851370681':'We will contact you soon.',
'1952981238':'Warning',
'342579190':'Buy report',
'1586626737':'Continue',
'399846737':'Loading',
'556543048':'The following information can be checked:',
'560876430':'Photos and adverts',
'-320410471':'Not available',
'1185850210':'No damage records were found',
'-903608283':'Summary of vehicle history',
'718416430':'Information about Car History Report ID',
'668096075':'Enter Report ID for repeated entry:',
'-1690204814':'Valid until:',
'-840281270':'Transmission',
'-1901926256':'Basic vehicle specification',
'-1550051512':'Car body type',
'-1043441574':'WARNING:',
'-709435856':'Customer Experience',
'-1149927862':'Find out what people are saying about Cebia Car History',
'456577623':'Your service found that the car had been crashed, and the odometer had been rolled back. Thank you.',
'329190632':'The service met expectations, found damage to vehicle. Thank you.',
'1450297818':'Theft check in the Czech Republic and in Slovakia',
'-1594408882':'Discount on car inspection by an independent technician from Autobezobav.cz',
'341709606':'Do you want to be absolutely sure that the car is technically sound? Choose a verified Autobezobav technician who will inspect the car with you directly at the point of sale and will clearly advise you whether it is a good buy or not.',
'382487941':'When requesting your chosen technician at Autobezobav, first enter basic information for a free preliminary consultation and in the second step of the request (Physical Inspection) enter your AUTORACER coupon number in the Discount Coupon field. During the inspection, the technician will then automatically deduct 300 CZK from the inspection price.',
'-28646667':'Compulsory third-party insurance must be taken out via the ePojisteni.cz portal within 30 days of payment for the Car History Report.',
'-355614707':'The offer applies to insurance of cars, commercial vehicles and trucks (does not apply to insurance of motorcycles, ATVs, tricycles or trailers and semi-trailers',
'-134420730':'Does not apply to insurance taken out via the ePojisteni.cz portal prior to the purchase of vehicle history check in the Car History system',
'1195365944':'After you have taken out an insurance policy, fill in your bank details and the insurance policy code into our application form. Once we confirm these details, we will immediately send you the amount to the bank account you specified in application form. This might take up to 60 calendar days after your application form has been submitted.',
'-1622287889':'Your request has been sent. We will contact you by the next working day at the latest.',
'1069770504':'If the selected vehicle has been individually imported into the Czech Republic, we recommend you our service PROVIN which verifies foreign origin.',
'-121060108':'We will check the vehicle registration in the country of origin, whether it has been properly deregistered, whether the vehicle has been put out of service due to a total crash, whether the vehicle is recorded as stolen in that country and much more.',
'-1158490787':'The discount can be redeemed within 30 days of Car History Report purchase and it cannot be combined with other discounts.',
'-1471409910':'Do you want to be sure that the vehicle you have chosen has not been illegally remodelled? We recommend you to use the VINTEST comprehensive vehicle identifier authentication service.',
'1555104762':'The VINTEST service provides its clients with a 100% guaranteed vehicle history information that is based on physical inspection of the vehicle. We perform a detailed vehicle physical inspection using the latest digital diagnostics. VINTEST verifies that the vehicle has all its original identifiers (VIN, tags, body welds) and that it is not suspected to be illegally altered.',
'735500953':'It is possible to find out whether the vehicle is subject to financing (in the form of leasing or credit), pledge or loan with the listed companies. The data is obtained directly from the information systems of each company.',
'-1173444543':'Leasing and credit companies',
'-976513658':'*Car-sharing = a car rental whereby a private individual rents his or her car for a short period of time (or, alternatively, car-sharing can also imply sharing of the car between the private owner and the short-term borrower).',
'-1479754603':'According to our databases, the vehicle has not been used as a taxi vehicle.',
'-467405909':'Records of the vehicle being used as a taxi vehicle',
'1930957191':'The information provided is for informational purposes only. Cebia shall not be liable for any damages arising from the use of the data.',
'-1895346741':'Our Value Calculator will estimate the market price of any used vehicle. We estimate this market price based on used vehicle listings in the Czech Republic and abroad, such as prices of actual sales made. The valuation algorithm works with calculation models based on long-term market monitoring, but is not able to take into account the physical condition of the vehicle. You can use this calculator to check that the price of the car you are valuing is the same as the listing price. ',
'-985858180':'In rare cases, the price of the vehicle may not be ascertainable. This is because there is insufficient data for a qualified valuation of the vehicle price.',
'1915347014':'We will show you listings of similar vehicles that are currently offered on the market, and in some cases also the prices of similar vehicles actually sold (we use the VIN to compare the exact specification of the vehicles). By using similar vehicle listings, you can compare whether the price of the vehicle you are checking matches similar vehicles on the market. We save you a lot of time spent searching and comparing similar listings on the web.',
'-767763886':'No records of vehicle advertising were found.',
'797541609':'Check in what condition and at what mileage the car has been advertised in the past. If the car was previously advertised with a higher odometer reading than it has now, the odometer may have been tampered with.',
'-1902352540':'I have checked and I confirm the information above',
'-2008975653':'No records of the vehicle colour were recorded by the manufacturer or when the vehicle was first registered.',
'7150984':'Verify that the current colour on the vehicle matches the colour declared by the manufacturer or the colour documented after the first registration in the Czech Republic (or Slovakia). If the colour is different, the vehicle may have been repainted, crashed or stolen.',
'-1664139465':'The equipment list is not available. We recommend that you check the functionality and originality of the equipment physically in the vehicle.',
'1681595153':'As part of the check, we will tell you whether the vehicle under investigation is secured by glass marking and whether it is registered in the OCIS System. If so, we will provide you with the marked code and date of registration.',
'571758489':'The vehicle has been glass marked in the past and is registered in the international OCIS database.',
'855264535':'If the unique code is missing on some or all of the vehicle\'s windows, the vehicle may have been crashed, remade or stolen.',
'388233970':'Information about the performed glass marking:',
'1615022749':'We were not able to find technical parameters of the vehicle. However, you can find these parameters in the vehicle registration certificate Part II or you can request them from the vehicle manufacturer.',
'704641294':'Enter the vehicle details and get instructions on how to check vehicle identifiers. The instructions apply generally to any vehicle with the make and model you specified. ',
'-621659477':'Based on your entered VIN, we might automatically pre-fill the vehicle details. If the pre-filled vehicle details do not correspond to the actual details of your vehicle, please delete them and enter your vehicle details manually. ',
'152333450':'No damage records were found, what does this mean? ',
'780709713':'How long will the Report be available? ',
'830704335':'Find out what information is available for your car:',
'1850331238':'Great service, well worth getting. Car History detected tampering with the odometer even on a demo vehicle.',
'1705245752':'I wanted to buy a car that the sellers claimed wasn\'t crashed. Autotracer detected that it had been crashed several times.',
'739619867':'An absolutely fantastic service, thanks to it we didn\'t buy a crashed car for a lot of money, thanks!',
'-2071079400':'Your service discovered that the car I picked had two high-damage accidents that the seller did not disclose. Thanks to it, I did not buy the vehicle. Thank you.',
'21202350':'Thank you for this service. If it was not for Autotracer, I would have lost 90K CZK on a car. According to the owner, the car was only slightly dented from the front. Autotracer detected that it has been wrecked twice with major damage.',
'1343185270':'Car History check reassured me and helped me make the decision to buy.',
'2083029914':'Cebia detected extensive damage that the dealer failed to mention to me.',
'1933958141':'Minor collision detected + odometer check from date of registration.',
'1900495866':'Thanks to the app, I discovered that there was something wrong with the car. Of course, the dealer didn\'t tell me anything about it and the seller was probably silent as well. Thank you.',
'-1668804606':'During the Cebia check I found serious deficiencies of the car that the seller withheld from me, e.g., odometer rollback, past vehicle accident.',
'-273876487':'Cebia detected odometer fraud. The seller stated that the car had driven 155,000 km while, in reality, it had driven 250,000 km...',
'911857027':'Enter your Report ID to view your Car History Report',
'1167593586':'You will receive a Report ID after you purchase the Car History Report.',
'-2136315768':'If you pay by card, GooglePay or ApplePay, you will immediately receive a Report ID by email and you will be instantly able to view the purchased Car History Report. ',
'868918064':'If you are paying by bank transfer, you will receive an email with payment Report ID immediately after the funds have been credited to our Cebia bank account.',
'1572179326':'If you pay in cash at the headquarters of Cebia, spol. s r.o., we will print the Report ID out for you.',
'-1322783148':'The VIN location varies for one vehicle make and model to the next. But as a rule, VIN can always be found in the vehicle registration certificate and it is often found under the windscreen.',
'700484420':'VIN is a unique identification number of each car. It can be found in the car\'s documents or directly on the car. ',
'-851712281':'We\'ll track down all the records available for the car you\'re checking and show you the range of these records. You always know what you are buying.',
'-1983041378':'You can pay via a secure payment gateway GoPay either by card or using a bank transfer.',
'-1809684191':'Car History Report is available immediately after payment. Of course, we will also send it to you by email.',
'541894049':'You will learn the evolution of the vehicle\'s mileage during the observed time frame. In a simple graph you can see how the odometer readings have evolved over time and detect any possible rollbacks.<br><strong>Attention</strong>: more than 40% of used vehicles have been a subject to odometer rollback!',
'643692561':'You can view past damage records for the vehicle in question. Information about the damage cost and damage extent are available in the details section. Warning: do not forget to check the technical condition of the vehicle and its other damages. For example, whether there were any total damages to the vehicle.',
'878444309':'If the vehicle has been advertised in the past, we will show you the advertisement and photo documentation for the vehicle.',
'185243225':'You will find detailed information and an overview of the service work carried out as part of the repair or routine servicing of your vehicle. Attention: compare the records with the service book and ask for more information about the vehicle\'s service history. ',
'1036319803':'We will verify the actual year of manufacture for you, which can have a significant impact on the current and future price of the vehicle. The year of manufacture is no longer shown on the vehicle registration certificate. Only the date of first registration is now listed on the certificate. These two dates can differ from the year of manufacture by up to 2 years, but even 6 years discrepancy is no exception. In effect, vehicle rejuvenation of one year can mean a loss of several tens of thousands of CZK in the vehicle price. For most vehicles you will also find out the date of the 1st registration and the country for which the vehicle was originally manufactured.',
'-437053085':'We will check the vehicle against available databases of stolen vehicles. These are mainly European databases collected from both public and private sources. A list of available countries and sources can be found in the Car History Report.',
'494784690':'We will check the vehicle against databases of finance companies, car rental companies and pawnbrokers. In the Car History Report you will find out whether the car is in lien by financing, loan or pledge.',
'2074346923':'We will check the vehicle against taxi databases. In the Car History Report you will find out whether the vehicle has been operated as a taxi vehicle.',
'-976680067':'We will show you a list of recall events that could apply to the vehicle you are checking.',
'1665109686':'The technical description will enable you to check the important parameters on the basis of which the model version of the vehicle type was approved for use. Compare these parameters with the information on the vehicle registration certificate.',
'-719963267':'We will display a list of vehicle equipment according to the production specification. You can compare the current equipment of the vehicle with that specified by the manufacturer. Please note: if, for example, the manufacturer lists right-hand drive, leather upholstery or sunroof in the vehicle specifications and your vehicle has left-hand drive, cloth upholstery or no sunroof - it is probably not the same vehicle, but may be a stolen and rebuilt vehicle! If there is any discrepancy, you should pay close attention to further physical examination of the vehicle to prevent a misguided purchase of a vehicle that may be the product of criminal activity.',
'-1106584159':'Your vehicle is secured by window sandblasting or etching, or the vehicle may have marked body parts. We will provide you with the marked code and date of vehicle registration.',
'1830950672':'We will show you the colour registered by the vehicle manufacturer and the colour after registration in the Czech Republic or after registration in Slovakia (if we have this information available).',
'1835889470':'Loading...',
'1020539397':'The operator of the Car History - Cebia, spol. s.r.o. would like to inform its users that the available information has been entered into the system by the operator\'s partners without any possibility of verifying its accuracy. All information is for informational purposes only.',
'2054100346':'We cannot guarantee the accuracy of Car History Report results. ',
'-167783152':'This report was created by a query into Car History system operated by Cebia, spol. s r.o. according to the valid General Terms and Conditions for the use of the Car History. The user is authorised to use the information provided in its entirety for his/her own use only. Its further distribution or modification without the consent of the operator is prohibited.',
'-1697979879':'Cebia CEO, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Cebia CEO',
'759051798':'About Cebia',
'-31539157':'We are a Czech company founded in Prague in 1991. Our services in the field of verification, security and identification of vehicles are used by customers in 9 European countries. Among our customers are motorists, insurance companies, car dealers, vehicle insurers, government institutions, police forces and forensic experts. Over our thirty-year history, we have uncovered tens of thousands of used car frauds and have helped millions of customers to safely purchase a vehicle in countries across Europe. ',
'-1821588851':'Claims and complaints ',
'-276118588':'Claims and complaints are a valuable source of suggestions and help us improve our services. Please contact us using the contact form or by email: <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a>',
'-1446517877':'We will deal with your complaint within 14 days of receiving it and we will inform you of the outcome.',
'708234375':'Contact us via the contact form, or email <a href=\'mailto:info.en@cebia.com\'>info.en@cebia.com</a>',
'1474175101':'General Terms of Service',
'1343500875':'Privacy Policy',
'-2083968164':'Online vehicle valuation',
'907588970':'Founding of the company',
'-770165824':'<strong>30 years</strong> of experience with checks of used cars',
'-1138482741':'<strong>1,000,000+</strong> cars verified every year',
'154007106':'<strong>750,000+</strong> satisfied customers',
'177137909':'Cebia database contains more than 1 billion records of used vehicles from over 32 countries. These are mainly records of car mileage, damage, photographs of the vehicles and their service records. ',
'723486619':'Loading...',
'742852638':'Period:',
'787763184':'time period:',
'-685036969':'Legend:',
'290784658':'Not available:',
'412892373':'We could not find any information on the vehicle in this category',
'1853282090':'Available:',
'-249038921':'XY records:',
'805146013':'We have found XY records for the vehicle in this category. You can access these records if you pay for the service',
'242118549':'General Terms and Conditions',
'521725542':'View the General Terms and Conditions for the use of CAR HISTORY',
'-571686819':'here',
'639498968':'Payment for viewing the Car History Report or Certificate will be charged according to the terms of your contract with Cebia.',
'-1705426324':'You can go back to your online Car History Report for 30 days after you first accessed it. ',
'529403448':'Important:',
'-821000576':'Cebia, spol. s r.o. would like to inform its customers that the information provided has been entered into the system by the operator\'s partners without the possibility of verifying its accuracy. All information is for informational purposes only. If the condition of the vehicle is in significant contradiction with the information provided here, we recommend contacting an appropriate expert to verify the exact condition of the vehicle.',
'-1138137280':'Our policy on the protection and processing of personal data ',
'125063053':'View our personal data processing policy for CebiaNET and Car History.',
'-608864551':'We have tracked down vehicle information in this category. You can view the information in the Car History Report.',
'-1879995849':'Entered data',
'754897480':'Year of the first registration',
'-1834106204':'Current mileage',
'404797827':'View Certificate',
'1358780725':'Generate Certificate',
'-952450705':'I have checked and I confirm the information above',
'-1601808583':'<span className="green">Done!</span> The following information was found: ',
'-1763095911':'It is possible to issue a Cebia REPORT Certificate that evaluates the following categories:',
'761362384':'Sample REPORT',
'-397155268':'VIEW REPORT',
'1375027269':'INSPECT THE VEHICLE',
'2055286638':'in more than',
'-1668243810':'Growing stronger',
'1141836815':'We become part of international investment group EAG Holding. As a result, our databases are significantly expanding to include records from all over Europe - especially Germany, Austria, and Denmark. ',
'-903271711':'We launch an online tool to determine the price of used vehicles. Thanks to our extensive database of used car records, this tool is highly accurate and is demanded by largest insurance and leasing companies. ',
'-1262829232':'Transforming the used car market',
'1367974718':'With the growing interest in choosing a car online, we are starting to work with the largest classifieds portals in Central Europe. The results of our history checks become the hallmark of quality in the used car segment.',
'-300528762':'Entering the online world',
'-899322976':'Our services are now easily accessible to anyone from the comfort of their home. Used car fraud is at its peak and we are fighting it with all our might.',
'-4875574':'Expanding our services',
'1166185728':'We add physical verification of vehicle identifiers (VINs) among our services and our database expands by leaps and bounds. We work with insurance companies, government agencies, and the police. ',
'-1619922514':'Going abroad',
'-844900279':'The trade of stolen vehicles proliferates in many more countries than just the Czech Republic. We therefore set up an international database of stolen vehicles to help us uncover the true origin of used cars.',
'598423318':'Three university classmates are setting up Cebia. The original aim of the company to be in the IT sector was soon replaced by an interest in vehicle security. ',
'-1828699417':'Foreclosure/Insolvency',
'4000011':'Free document check',
'1479506336':'Search history',
'-1478881101':'Vehicle verification history',
'1115668272':'Foreclosure/Insolvency history',
'691760517':'Document verification history',
'-614781938':'The extent and cost of the Car History Report may vary from vehicle to vehicle depending on the amount of information we have available about the vehicle.',
'702191442':'Back',
'-1034048918':'VIN check',
'-1214567423':'Reference number',
'427849990':'Create Certificate',
'-1718377395':'Error reading Report ID.',
'877365836':'Failed to generate a payment URL.',
'-1082791212':'Invalid discount code',
'-255296664':'Report ID gives you access to your Report for 30 days after purchase.',
'456125545':'Cebia, spol. s r.o. would like to inform its customers that the information provided has been entered into the system by the operator\'s partners without the possibility of verifying its accuracy. All information is for informational purposes only.',
'-1172808350':'Ask the seller to provide you with the VIN code and then check the vehicle.',
'-2079080642':'Ask the seller to provide you with the 17-digit VIN code and then check the vehicle.',
'-585052469':'Buying an used vehicle? Check its history, mileage or damage records by entering its VIN code!',
'76811920':'SAMPLE result of a vehicle check',
'1050846059':'Nevertheless, we still recommend that you have your vehicle physically inspected, ideally by a qualified mechanic or at a garage of your choice. Such an inspection can clearly detect or rule out previous damage.',
'-1068264553':'Always check the date of manufacture declared by the vehicle manufacturer. Only the date of first registration is now listed on the vehicle registration certificate. These two dates can differ from the year of manufacture by up to 2 years, but even 6 years discrepancy is no exception.',
'756232271':'No records of the age or origin of the vehicle have been found. If the vehicle comes from abroad, always request and carefully check the original documentation for the vehicle (e.g. service book or vehicle registration certificate).',
'-643660678':'One or more service records were found.',
'1533967406':'The price of Car History Report varies. It depends on the amount and range of history information we have available for a given vehicle. You can find out the scope of available information and the corresponding price of a specific Car History Report by entering the vehicle\'s VIN.',
'-1289463237':'The Report is usually generated right after the payment. The service is available 24/7 and is fully automated.',
'1304585346':'After your payment has been processed, the Car History system will send you an automatic email to the email address you provided. In the email, you will find the Car History Report ID (= which will enable you to access the paid information) together with a simplified tax document.',
'-661494819':'Loading error',
'-1681989289':'By purchasing the Car History Report you got a 100 CZK discount on your next purchase. Enter your Report ID in the discount code field on your next purchase. The discount is valid for 90 days after purchase.',
'681093117':'You have received a discount voucher for renewing your FLEX service for another year. To redeem the discount, enter the discount code number you received on your FLEX tax receipt in the "Enter Voucher Number" field.',
'-1551909822':'You have received a discount code for a free vehicle inspection. You can redeem the discount by entering your Report ID in the discount code field on your next purchase.',
'-103162544':'After the payment has been processed, we will send you a link to your Car History Report together with a simplified tax document to the following email address.',
'-591911074':'After the payment has been processed, we will send you a link to your Car History Report together with a simplified tax document to the following email address.',
'706315962':'If you need an invoice with company billing information included (i.e., document that contains company name, address, registration number and VAT identification number), please fill in this information below.',
'500915765':'When you place the order, you will be redirected to GoPay payment gateway to make a secure payment by card. If your payment is successful, you will then be automatically redirected back to the Car History system to view the purchased history report. At the same time, we will send you an email to the address you provide containing a tax receipt and a Car History Report ID.',
'-676823504':'If you select this payment method, you will be redirected to the payment gateway GoPay, where you select your banking institution from which you wish to make the payment.',
'-764670786':'If your bank offers an instant online transfer, we will redirect you to your online banking where you will have a pre-filled payment order.',
'2062466434':'If we receive payment from the bank immediately, we will automatically redirect you to the Car History Report. Otherwise, please wait until you receive the email.',
'734607756':'After your payment has been processed, the Car History system will send you an automatic email to the email address you provided. In the email, you will find the Car History Report ID (= which will enable you to access the paid information) together with a simplified tax document.',
'1715999355':'Google Pay is a quick and an easy way to pay anywhere through Google, without entering your payment details. You can pay by using any card stored on Google Pay. However, please note that to use this payment service, your bank must support Google Pay. If your payment is successful, you will be automatically redirected back to Car History Report to view detailed vehicle information. At the same time, the Car History system will send you an automatic message to the e-mail address you provide, containing a simplified tax document and a Car History Report ID which you can use to access the Car History Report at a later date.',
'790632208':'Apple Pay is a payment method that makes it easy to pay using a card stored in Apple Wallet. Disclaimer: Not all banks support this payment method, check whether your bank uses Apple Pay before use.If your Apple Pay payment is successful, you will be automatically redirected back to Car History Report where you will be able to view detailed vehicle history. At the same time, the Car History system will automatically send you an e-mail with a simplified tax document and a Report ID, which you can use to display the Car History Report at a later date.',
'-1402859324':'In case your bank offers only the so-called offline transfer, we will generate instructions for the bank transfer, you will then transfer the funds at your bank and once the amount is credited to our bank account, we will send you an email with access to the Car History system and a proof of payment.',
'347099860':'This might take up to 2 working days (but it is usually ready within 24 hours).',
'-43783036':'Car History report will be available at Cebia headquarters',
'-802148009':'You can pay for the Car History Report in cash at Cebia\'s headquarters during working hours Monday to Friday from 8.00 am to 4.30 pm. It is necessary to have the VIN code of the vehicle with you.',
'1985201267':'Europe\'s <strong>largest database</strong> of used vehicle records',
'-1590601891':'<span class=\'color-red\'>Warning:</span> Car History Report may not have the records of all damages to the vehicle, some damages might not get reported to insurers or the data might not be available.',
'-1465948017':'<strong>OCIS system -</strong> marking of vehicle windows. Simple, efficient and cost-effective vehicle security. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/en/services/glass-sandblasting\' target=\'_blank\'>More here</a>.',
'1553997983':'<strong>CEBIA SAT</strong> - modern vehicle security. Protects vehicle crew in the event of a crash, prevents theft of the vehicle. Immediate response and continuous monitoring of the vehicle directly on your phone. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/en/services/cebia-sat\' target=\'_blank\'>More here</a>.',
'-124714580':'You can pay online with a credit card or via Google Pay and Apple Pay. Payments are handled by a secure payment gateway GoPay.',
'-753863533':'CERTIFICATE OF VEHICLE CHECK - overview of available information',
'1441255407':'Category',
'1549893453':'Check option',
'1547427535':'YES',
'508023484':'Check of vehicle registration date',
'2076692599':'Physical inspection',
'-1521980547':'Mileage check',
'-1009985044':'YES - based on',
'1747355819':'data',
'1639206028':'Check of vehicle origin',
'-1524110425':'Control of ECO tax payment',
'-1961089879':'VAT check',
'1732127987':'Check for serious damages',
'-198755848':'Registration check',
'-1790774232':'Check of flood damage',
'-1781437228':'Engine type check',
'-1344389293':'Check of identifiers',
'-499181915':'Photo',
'-687939409':'The information was taken from records of vehicle status acquired by Cebia or by its network of contractors.',
'-43022982':'Date of service:',
'-136414993':'Vehicle status:',
'2023782231':'The vehicle has been physically inspected for its identifiers (VIN stamping, production labels, etc.). The information was taken from the VINTEST service records carried out by Cebia or its contractors.',
'758499555':'Condition of identifiers:',
'488100027':'BACK',
'-1281249872':'CONTINUE',
'-141562572':'CREATE',
'-40297285':'You can get a price development and data overview for the vehicle you are checking.',
'-1742933347':'Advert number',
'-2099386182':'Sale',
'1560408371':'Sale price',
'-280814568':'View Vehicle Inspection Certificate',
'2075274708':'Date',
'-1248991647':'Ukraine',
'-1779298552':'Sweden',
'-18328562':'Latvia',
'1764744897':'Lithuania',
'-1273046880':'Romania',
'1252567719':'Bulgaria',
'445626359':'Greece',
'-868498706':'Slovenia',
'-1528448787':'Hungary',
'-387469480':'Slovakia',
'25300212':'Austria',
'1771002523':'Italy',
'69077775':'Denmark',
'647944028':'Poland',
'1160986873':'Germany',
'897084544':'France',
'2056598840':'Spain',
'264100206':'Russia',
'-1609348689':'Cookies policy',
'-365994139':'Load more',
'807848789':'Millions of satisfied customers',
'1677683955':'Cebia has over <strong>2 000 partners</strong> all around Europe. These partners provide us with vehicle records from all over the globe.',
'652553309':'Did you know? ',
'1453750886':'Done!',
'1498262202':'available',
'1514779988':'Vehicle inspection by a technician',
'1058658544':'Cebia discount vouchers',
'-2035347536':'0 records',
'1050102897':'The detailed equipment specification provided by the vehicle manufacturer includes all basic and optional equipment. A check of the equipment can reveal modifications to the car such as a change to the steering side.',
'2145745364':'Odometer records',
'-1636264801':'Description',
'-1488190529':'Record number',
'64594583':'Financed vehicle',
'-1441353378':'No financing',
'-2101084179':'January',
'-1066567335':'February',
'-1761670105':'March',
'104663035':'April',
'1397259452':'May',
'-642974117':'June',
'613657227':'July',
'-2044487450':'August',
'1921417285':'September',
'-1672709282':'October',
'-678062097':'November',
'1302554433':'December',
'1268839568':'No records were found in the service databases. Ask for a service logbook extract (preferably from the electronic service logbook) for the vehicle.',
'-112660439':'Here you can create a <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, which you can provide to those interested in your car and increase its credibility when selling or advertising on the largest advertising portal - <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Used as a taxi',
'-1350474522':'Our databases do not contain any odometer records for the vehicle.',
'1040913417':'We recommend that you check other possible sources of odometer records, including the vehicle\'s service book. It is also recommended that you ask for service history at an authorised workshop. Then, when inspecting the vehicle, pay attention to wear and tear, especially on parts that can give a clue to the actual mileage.',
'1010039483':'Car inspection from 1 990 CZK (according to the selected technician)',
'-622228786':'Email',
'-982011601':'US',
'786789662':'Purchase discounted packages',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Do you want to buy for your company or check out more vehicles? Use our',
'845754511':'zvýhodněné balíčky!',
'-614796774':'Czechia',
'1011350398':'Mon-Fri: 8:00 - 16:30 CET',
'-68730396':'To date:',
'544810671':'NO - not registered',
'-1670731287':'Registered in Czechia as:',
'1597705322':'Standard equipment',
'16575694':'Vehicle:',
'-846486931':'records',
'-1615816320':'record',
'1415114012':'records',
'1802294807':'photos',
'1122888753':'photo',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/en/legal-information/personal-data">personal data</a>',
'1802705032':'<a classname="Link Link--lightBlue" href="/detailArticle/where-do-i-find-the-vin-code" target="_blank" rel="noreferrer noopener">More information</a>',
'883512613':'Cebia Car History - Online vehicle check',
'-1965309318':'Check the vehicle\'s history online in the largest database of used vehicle records in Europe. Damage & mileage check, service history, photos and much more.',
'-1871315210':'Payment methods',
'-1803227447':'Please select the appropriate payment method.',
'-1249181167':'News - Car History',
'-838385662':'You can find important information about checking and buying used cars, avoiding scams and much more in our blog.',
'1154868985':'FAQ - Car History',
'908158246':'Check out the most frequently asked questions about Cebia Car History from our customers. If you have another question, feel free to contact us.',
'-633966577':'About - Car History',
'1171158673':'Learn more about Cebia Car History, its database, and why it is used to check vehicle history by millions of customers across Europe.',
'-1244282008':'Cookies policy',
'-792206035':'This site uses different types of cookies. Some cookies are placed by third party services. You can withdraw your consent to their use at any time.',
'1489309005':'Free access - within the limits specified in the contract',
'-470675791':'I have checked and I confirm the information above',
'2108896817':'CANCEL THIS DISCOUNT',
'6892736':'The discount code is no longer valid',
'-1199844274':'Code is not valid!',
'-552424253':'Entry to the paid Report is charged according to the contract',
'167769730':'Payment in cash',
'1768026018':'You can pay for the Car History Report in cash at Cebia\'s headquarters during working hours Monday to Friday from 8.00 am to 4.30 pm. It is necessary to have the VIN code of the vehicle with you.',
'820786512':'Code has been accepted, price has been recalculated',
'-279227794':'Check of year of manufacture',
'1470281039':'Check of leasing/credit in the Czech Republic',
'60776637':'Report ID',
'529459564':'Please enter your Report ID ',
'-861194224':'Vehicle inspection',
'2076412240':'new',
'360601996':'Your Report ID is no longer valid.',
'1762078183':'Material',
'822990672':'By clicking this button, you use 1 of your free queries according to the free query limit specified in your contract.',
'532528312':'used',
'-319106355':'Vehicle',
'935795728':'Sample Report',
'-916000765':'Car History Report',
'-1721948126':'Sample Car History Report - check the vehicle\'s history online in the largest database of used vehicle records in Europe.',
'329882651':'By pressing this button you will be charged the price for displaying detailed information according to effective contract.',
'-494157101':'Invoice',
'-465994559':'Photos',
'857656094':'Manufacture code:',
'-2005319583':'Vehicle model:',
'339937023':'Year of first registration according to tech. certificate:',
'-441771827':'Loading data',
'-161761801':'no data found',
'1229398238':'WARNING: Cebia CERTIFICATE cannot be created. The vehicle is registered as <strong className="bolder">stolen</strong>.',
'358386818':'Vehicle age:',
'-701699977':'Cebia REPORT could not be created - the VIN could not be uniquely identified.  Check the VIN against the vehicle documents or contact support.',
'-1359814132':'Next picture',
'340541220':'Zoom in',
'2096545526':'Zoom out',
'-158382161':'Previous picture',
'-1554923364':'(technician travel free of charge)',
'-749344895':'Discount',
'-1755324145':'Vehicle model',
'1747292041':'Smart code',
'1093009871':'Smart communication for modern car dealers',
'-421925379':'Do you want to advertise that your vehicles are proven?',
'-303554559':'Put a Smart Code on each vehicle and the customer will retrieve the result of the verification himself.',
'5128661':'Quite simply. Print the Cebia Smart Code (A5 format) and place it behind the windscreen of the vehicle you are selling.',
'1608809132':'The customer can then use his mobile phone to retrieve basic information about the vehicle via the QR code and view the mileage graph.',
'-703122978':'The other information (the complete Car History Report) can then be requested directly from you.',
'-566386963':'Please fill out this form and we will get back to you.',
'-1639125181':'Send',
'76421921':'Required',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Interested in printing Smart Codes for your vehicles? Contact our sales department:',
'2094660384':'Petr Kala, Head of Sales ',
'361077673':'Or fill out the form and we will get back to you:',
'1277961418':'Do you want to generate a Smart Code?',
'-2133968142':'I have a discount code',
'-1922950659':'Payment method',
'-2072875261':'Vehicle being checked',
'783887791':'Order',
'-1499237034':'For free',
'-1854208328':'Apply discount code',
'-793343279':'gift',
'-1393236672':'Kosovo',
'1342791550':'Mileage history',
'2063498881':'Optional equipment',
'-152116623':'QR – Smart code',
'-956949153':'Repair cost',
'-552950132':'data',
'30639535':'data',
'-1164317619':'No',
'1402944789':'Please enter the required information',
'-1812102598':'WARNING: The odometer can not be checked. The category will have the result "lack of data".',
'666398003':'Warning!',
'771644825':'The entered VIN may be incorrect!',
'-1131044763':'More information?',
'-2054470344':'The entered VIN may be incorrect!',
'-1369269346':'A possible error has been detected in the VIN number entered. Please check whether the VIN entered matches the VIN number stamped on the vehicle or on the technical certificate.',
'-1625517200':'Where to find the VIN?',
'1171142400':'Payment has failed. Please, try it again. ',
'-1192484737':'You cannot proceed without agreeing to the Terms and Conditions',
'-247455599':'Please enter your company ID.',
'-1944712721':'The minimum number of characters is 12.',
'1664270996':'State',
'-862797995':'The condition of the vehicle as recorded when it was advertised. Please see the attached photo documentation for possible damage.',
'-1997376096':'Slovakia',
'-1344951568':'Vehicle register',
'-644036535':'Databases',
'-426412387':'National Vehicle Register',
'1736003883':'Technical certificate data',
'1610817679':'Odometer records are available for the vehicle. In the Car History Report we will disclose available odometer readings for the time period specified above. The records can be used to determine whether the mileage is accurate or whether the vehicle\'s odometer has been tampered with.',
'1669009738':'The queried vehicle has been advertised or photodocumented. In the Car History Report, you can view details of the advertisements, odometer readings, photos of the car and, if applicable, the advertised price. Please note that the amount of information may vary from one advert record to the next.',
'1518701163':'We know the vehicle age and/or its origin. In the Car History Report you can check the date of vehicle manufacture, the date of first registration, the date of the vehicle registration in the Czech Republic and information regarding the country for which the vehicle was originally manufactured. Please note that the scope of information in the Car History Report may vary from vehicle to vehicle.',
'1816609203':'When it comes to cross-border vehicle sales, the year of manufacture is often tampered with!',
'-900812009':'A stolen car can be confiscated by the police without any compensation!',
'-976941031':'Taxi check is not available for this vehicle.',
'444450829':'up to',
'982955181':'12 registers',
'-1739651968':'Check of vehicle security',
'-167461646':'Vehicle history check',
'-1365221853':'The maximum number of characters is 14.',
'1302766369':'Valuation of the vehicle has not been carried out. Valuation can be done during the validity period of the voucher by entering the vehicle details (date of first registration and current odometer reading) on our website after entering the voucher number.',
'1881868699':'Purchase for business',
'631216697':'Please enter your email.',
'945011940':'Your data',
'-401861769':'Contact e-mail',
'1441058305':'If you fill in the VAT number, we will try to fill in the remaining data from the official database.',
'-1181757195':'We will send you a link to view information about your car\'s history and a simplified tax document to the email address provided after payment.',
'-1768425519':'If you would like to have your billing details (company, address, VAT number) filled in on the simplified tax document, please enter them in the fields below. If you fill in the VAT number, we will try to fill in the remaining data from the official database.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Cebia Certificate provides evaluation of tachometer check, stolen vehicle database check, leasing company commitments, VIN and year of manufacture check. The overall rating is expressed by the number of stars (0-5) and a verbal rating.',
'308350422':'Total incl. VAT:',
'-24797068':'You are entitled to a discount if:',
'-1323263000':'You have a FLEX prepaid service with us and use the code on your tax receipt.',
'-724069837':'You have a discount code that entitles you to a free purchase.',
'-127346860':'You have a discount code from one of our partners.',
'-47884841':'excluding VAT',
'881014685':'Total without VAT',
'-15294371':'Unconfirmed VAT number',
'1286068311':'The official database of VAT payers has not confirmed your VAT number. We cannot therefore automatically complete your details. Please check your VAT number or fill in your company details manually.',
'-172351568':'Database is not responding',
'1839952435':'The official database of VAT payers does not respond. Please fill in your company details manually.',
'1678298800':'Sample Report',
'-654354054':'The amount of information available varies from vehicle to vehicle',
'-1069736045':'European <label classname="color-orange">marketplaces</label>',
'1068440731':'up to <label classname="color-orange">12 registers</label>',
'-534710218':'Vehicle origin and age',
'1267127794':'All the checks listed on this page can be carried out for the specified vehicle based on the VIN code. The results of the checks will be available in the paid Report. The amount of information contained in the paid Report may vary for each vehicle.',
'-186613120':'It was not operated as a taxi in the Czech Republic',
'1880784559':'Available by entering the vehicle registration number',
'2085931506':'<label className="color-orange">available</label>',
'-1765351165':'more than <label className="color-orange">200 milion records</label>',
'1815202337':'records from <label classname="color-orange">EU and US</label>',
'-555727222':'records from <label classname="color-orange">18 countries</label>',
'-1798142062':'Chart of odometer records',
'-1828887347':'VIN (Vehicle Identification Number):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'To check whether the vehicle was operated as a taxi in Slovakia, enter its registration number at <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Check VIN number now',
'-1620583517':'History',
'239060530':'History of VIN verification',
'1122350112':'Overview of the history of all queries on the VIN as of the date of purchase',
'-1288575114':'Not checked',
'-14589513':'Customer',
'-1170545783':'Checked by you ',
'452068758':'Contracting partner Cebia',
'-1710315139':'Car History Report contains the records found about the vehicle\'s history. Most often these are past odometer records, vehicle damage data, technical inspection records, vehicle photos or vehicle advertising records. You can find more in the <a href="/payed/detail/exampleUS">Sample Report</a>. ',
'1607430837':'History overview',
'-293598683':'Date of de-registration',
'-1790772829':'Date of 1st vehicle registration',
'743843679':'Date of vehicle registration',
'1370884754':'Registered as',
'644449918':'Colour of the vehicle when registered',
'-583571734':'Date of manufacture:',
'-1219924366':'Age of the vehicle',
'1595420674':'Manufactured with equipment',
'1151043140':'Time between date of manufacture and date of 1st registration',
'708774908':'Albania',
'1637995084':'Northern Macedonia',
'1001888760':'Total economical loss *',
'1034299871':'Damage claim - total economical loss*',
'1209738229':'Damage claim',
'913822268':'Note',
'584620341':'Brumlovka, the Alpha building',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praha 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'By completing the purchase I agree to the <a href="https://www.cebia.cz/files/general_terms_and_conditions_car_history.pdf" target="_blank" class="Link Link--lightBlue">General Terms and Conditions</a> and acknowledge the <a href="https://www.cebia.cz/en/legal-information/personal-data/" target="_blank" class="Link Link--lightBlue"> Privacy and Personal Data Protection Policy</a>.',
'-983289066':'Cooperation with Gjirafa',
'-1915066431':'In cooperation with Gjirafa, we have expanded our services to Kosovo, Albania and Northern Macedonia.',
'-427869571':'Date of 1st registration according to the registration application',
'280331577':'Time between date of manufacture and date of 1st registration',
'1163480388':'There are no records in the available databases.',
'-1319845523':'There is a damage calculation created by an insurance company or a car repair shop for which we do not know the detail (damage sites). Please pay attention to the vehicle inspection.',
'779148402':'There is an incident reported by the insurance company for which we do not know the detail (damage sites). Please pay attention to the vehicle inspection.',
'1115915387':'Doprava zdarma',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Not verified',
'-1464599260':'Work',
'-592361226':'Paintjob',
'1085310093':'Type of inspection:',
'1984850101':'Date of inspection',
'-1502399543':'View protocol',
'-1961590845':'The database is currently unavailable, please try again later.',
'-1065211891':'Protocol not available',
'1708745339':'Protocol is not available for this inspection.',
'1087254174':'Get a complete check',
'2096754301':'Basic vehicle check to date:',
'926095479':'What is a basic vehicle check?',
'-254140743':'Basic screening includes the following checks:',
'1362304971':'For a full vehicle history check, we recommend purchasing a Complete Vehicle Check, which can also include a <b>damage check, listing history, vehicle photos, service history</b> and much more.',
'-267677252':'Basic vehicle check',
'-70564196':'- 10 EUR',
'1350105297':'Are you satisfied with the result of the vehicle history check? We will be glad to receive your review.',
'1914051118':'Review',
'1786612140':'We are sorry that the vehicle history check did not meet your expectations. Please let us know what we should improve for next time.',
'-1265184280':'Sending error. Repeat the action.',
'-669850784':'The maximum number of characters is 2000.',
'1801814710':'<strong classname="color-green">Thank you!</strong> Your review has been submitted and will help us further improve our service.',
'-883106932':'records from more than <label classname="color-orange">32 countries</label>',
'1408314893':'Cebia collects vehicle records from over 32 countries. The records most often come from official registers, car dealerships, police databases, damaged vehicle databases, insurance companies or classifieds portals. ',
'-1412306493':'Yes. Each vehicle is checked against databases of records from more than 32 countries (including Germany, Denmark and the USA). After entering the VIN, you can see what records were found in our database and how dated these records are. We provide all this information in our free Summary of Available Information.',
'1798507708':'In this case we have found no damage records for the vehicle being inspected in our databases containing over 200 million damage records from 32 European countries and the USA. This fact substantially increases the likelihood that the car being inspected has not been crashed in the past. ',
'568601301':'Vehicle records from more than <strong>32 countries</strong>',
'899127599':'32 countries',
'-1456368295':'Vehicle history from 32+ countries',
'1333238000':'30 000 000+ checked vehicles',
'426416063':'Data from 32 countries',
'-1549476292':'that our system checks each VIN you enter against a database of damaged vehicles? Our database contains the records of over 200 million damaged vehicles from 32 countries. The majority of these records (62 %) come from France and Germany.',
'-924882043':'One or more records of damage to the vehicle <span class="color-red"> were found</span> in our databases of damaged vehicle compiling data from more than 32 countries and approximately 200 million damage records.',
'-1366090835':'<span class="light-green">No records of damage</span> to the vehicle under investigation were found in the databases of 200 million damage claims from more than 32 countries.',
'931273814':'Vehicle history data from 32+ countries',
'-334327155':'We will check the vehicle against databases of damaged vehicles from more than 32 European countries and the United States, comprising more than 200 million damage records. In the Car History Report you will find whether the vehicle has been registered as damaged in the past. Detailed information such as damage date, odometer status, damage extent, repair cost or damage photos might also be included.',
'2078676994':'Service records',
'503491296':'OK',
'1704037376':'Cancel',
'-922638497':'checked',
'-2077712313':'up to 12 registers',
'-855779034':'The check has already been <strong classname="ml-5">performed</strong>',
'131879595':'Vehicle check <strong classname="ml-5 mr-5">for a fee</strong> according to the contract',
'-2034839108':'PDF',
'2049708688':'No records',
'1916268628':'Netherlands',
'-1425396841':'About',
'-1521903902':'Protocol ID',
'1485582239':'Incorrect Email format.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'About payment',
'1275361495':'You have received a discount code for a free vehicle inspection. You can redeem the discount by entering your Report ID in the discount code field on your next purchase.',
'-675647872':'You can enter the Report ID in the relevant field on the homepage <a href="/">en.cebia.com</a> and get immediate access to the purchased information, which you can return to at any time within 30 days after purchase.',
'1080548025':'WARNING: This may be an incorrect VIN. Check it and if necessary <a href="mailto:rokvy@cebia.cz?subject=Information system support-ATR Identification&amp;body=VIN: {0}">please contact support</a>.',
'-439637411':'WARNING: Cebia REPORT cannot be created. <a href="mailto:rokvy@cebia.cz?subject=Information-support-system-ATR-years&amp;body=VIN: {0}">Contact support</a>.',
'-43065820':'No records of the age or origin of the vehicle have been found. If the vehicle comes from abroad, always request and carefully check the original documentation for the vehicle (e.g. service book or vehicle registration certificate).',
'-1362534546':'If the vehicle has been advertised or inspected in the past, we will display information and photo documentation.',
'431007496':'Record',
'-1525511285':'Vehicle registration data is obtained from national authorities and reflects the status when the vehicle was first registered in the country.',
'1151204976':'<a href=\'/detailArticle/vin-vehicle-identification-number\'>VIN (Vehicle Identification Number)</a> is a unique 17-digit code that unambiguously and distinctively identifies each vehicle. It is stamped directly on the vehicle body and all records of the vehicle are filed under this number. ',
'205732919':'VIN is a 17-digit code that uniquely and unambiguously identifies each vehicle.',
'1905716471':'* The definition of <strong>Economic total damage</strong> may vary from one EU country to another, but also from one insurance company to another within the same country. Even if a given damage is assessed as total damage, it does not necessarily mean that the vehicle is unroadworthy or even beyond repair. An <strong>Economic total loss</strong> is damage to a vehicle where, according to the terms of the insurance company, it is uneconomical to repair the vehicle (=the amount to repair the vehicle is close to or greater than the value of the vehicle at the time of the damage) or is assessed as such according to the terms of the insurance policy.',
'-199845800':'The number must contain exactly 10 characters.',
'1657920051':'The maximum number of characters is 18.',
'-1030611916':'The maximum number of characters is 6.',
'1439047896':'<a href="/detailArticle/vin-vehicle-identification-number">VIN</a> (<a href="/detailArticle/vin-vehicle-identification-number">Vehicle Identification Number</a>) is a unique 17-digit code that unambiguously and distinctively identifies each vehicle. It is stamped directly on the vehicle body and all records of the vehicle are filed under this number. ',
'1682180910':'<p>In the available databases, we do not have records of the damage to the inspected vehicle. The VIN provided by you may be incorrect.</p><p>Warning: The AUTOTRACER system may not have information about all vehicle damages; some damages are not reported to insurance companies or are not available during online verification.</p>',
'-1276466490':'The entered VIN may be incorrect',
'-1892907546':'There are no records of its history available for this VIN.',
'1843204209':'Continue',
'-716345220':'Check another VIN',
'1091884080':'We recommend checking the VIN',
'-287497170':'Lien check',
'1289833880':'Lien was found',
'466639297':'Lien was not found',
'-577812113':'Lien ID',
'231435384':'The lien check is currently not available. Please try reloading the page after a few minutes.',
'-941220732':'The vehicle is the subject of one or more liens in the mentioned states, and there is a possibility of its confiscation in the event of a potential enforcement proceeding of the person listed in the liens registry. Purchasing a vehicle with an lien can be very risky. The data is obtained from the official lien registries of the given country.',
'2016489332':'The outcome of the query is to ascertain whether the vehicle is not subject to any lien in the mentioned states and therefore there is no risk of its seizure in the event of potential enforcement proceedings against the current owner. We obtain the data from the official liens registries of the respective country.',
'1736313716':'Attachments to the article',
'1021804797':'Free Car History Report within the limits specified in the contract',
'749816957':'Can be checked',
'981194702':'Control of foreclosures',
'-1417536272':'Lien check',
'-323399576':'The Report can be complained about. If you suspect that incorrect information is included in the Report, please contact our customer service department at <a href="mailto:info.en@cebia.com">info.en@cebia.com</a>. ',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'The question is about the service',
'1845072895':'Report ID (is required for a claim)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'View report',
'1269795688':'Základní prověření',
'1521773717':'Operated as a taxi in',
'-137639801':'Report ID (fill in case of complaint)',
'-1619027976':'Note: The vehicle may not have been operated as a taxi for the entire time.',
'-1010997259':'For Business',
'503941074':'Detailed information and data about the inspected vehicle will be available after paying for the service.',
'1088246106':'For the given vehicle, we check:',
'-963915529':'Vehicle damage',
'-1603961223':'data from <label className="color-orange">26 companies</label>',
'-1940014640':'Technical data',
'1785662376':'Services',
'1913348655':'Do you want to check more vehicles at a lower price? Get access for businesses and <b>save up to 50%</b> on the verification cost. Please provide us with your contact details, and we will get in touch with a custom offer.',
'1723999193':'Available after payment',
'1593405846':'Pojištění vozidla',
'670508858':'Only one discount code can be used per purchase. Discount codes cannot be added or combined. Discount only applies to Car History Report.',
'1693716169':'Payment method',
'-503153747':'Car History Report',
'-1127924333':'Základní výpis',
'-946840675':'Review of previous advertisement',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'DISCOUNT 50 % on next Car History Report ',
'-1870036897':'You have already had a car checked and by entering the Report ID (provided in the email you received) within 30 days of purchase you will receive a 50% discount on the next purchase.',
'163946327':'With each purchase of Car History Report you will receive a 50% discount on your next Car History Report purchase. Please note: this discount cannot be applied to the cheapest category of Car History Reports worth 199 CZK. During your next purchase, you can apply your discount by entering your previous Car History Report ID in the text box labelled "Discount coupon" located in the "Payment" section.',
'858336732':'50% discount next vehicle check',
'214251926':'By purchasing a vehicle check, you will automatically receive a discount on the next vehicle check for any vehicle. The discount is valid for 30 days from the date of purchase.',
'1317784962':'10% discount next vehicle check',
'641443144':'20% discount next vehicle check',
'-1318469146':'30% discount next vehicle check',
'1939643950':'40% discount next vehicle check',
'-1376459678':'Another check <br> <b>with a discount!</b>',
'-937620931':'Vehicle data',
'514768619':'Verifying the authenticity of the VIN code',
'-916328171':'Verifying the identity of the vehicle',
'1481465091':'Checking the vehicle equipment',
'-276782700':'Searching databases of damaged vehicles',
'1544438189':'Checking insurance company records',
'2021669548':'Searching the history of auction portals',
'2022162632':'Checking records from authorised service centres',
'1083617557':'Checking the records of unauthorised services',
'993080480':'Searching police databases for stolen vehicles',
'-1240510013':'Searching private databases for stolen vehicles',
'-1027936662':'Checking the age of the vehicle',
'1299172359':'Checking the origin of the vehicle',
'413685839':'Searching electronic service books',
'-473666121':'Searching state vehicle registers',
'-254920359':'Searching state databases of taxi vehicles',
'-811021673':'Checking European recalls',
'1923526539':'Checking vehicle security',
'-1424413327':'Checking mileage record databases',
'734665361':'Checking records from leasing companies',
'684288091':'Checking the records of technical inspection stations',
'-1633964825':'Searching records from emission measurement stations',
'-354982402':'Searching the history of advertising portals',
'255365524':'Searching the databases of new vehicle dealers',
'936372740':'Searching databases of used vehicle dealers',
'-1491634158':'Checking records from car rental companies',
'-1425645764':'This may take a few minutes',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Creating a report',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Share Report',
'-2142411619':'To share the Car History Report, copy the link below and send it to whoever you want to share the report with.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'We recommend',
'-1025377658':'items',
'-909166524':'items',
'-1998523642':'item',
'-613016004':'Discounted offers',
'1083497926':'Other services',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Next report',
'-901212011':'for',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'We are checking the photos, you will be informed about the execution by e-mail.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Warning:</span> Information on all vehicle damages may not be available in the system, some damages are not reported to insurance companies, or are not available as part of an online check. We therefore recommend a careful inspection of the vehicle, ideally with the help of a qualified mechanic or at the service center of your choice. Such an inspection may reveal additional damage or rule it out.',
'-1943079155':'<span class="warning">Note:</span> If the photos do not correspond to the vehicle being checked, please inform us by email <a href="mailto:info.en@cebia.com">info.en@cebia.com</a>.',
'707486310':'<span class="warning">Note:</span> Not all vehicle service records may be available in the system. We recommend comparing the records with the service book or contacting the relevant authorized service of the brand with a request for an extract from the digital service book of the vehicle.',
'-1997337578':'The overview of an evolution of the vehicle\'s odometer contains values ​​obtained from the available information systems of cooperating partners. According to the development of the graph, you can judge for yourself whether there is a suspicion of manipulation of the odometer status or not.',
'-282194273':'Recommendation',
'-1564285314':'Not all odometer state records from the vehicle\'s operating history may be available in the system.',
'993789697':'If the vehicle does not continuously record the entire mileage history over time, we <b>recommend</b> check the odometer records in the service book as well, or have the vehicle checked at an authorized garage. We recommend this procedure especially for vehicles where there is a time period of more than two years between odometer readings.',
'2129711518':'This is available information on the basic parameters, standard and possibly additional equipment of the vehicle from the factory. Vehicle modifications such as changing the side of the steering wheel can be detected by checking the equipment.',
'-593531916':'<span class="warning">Warning:</span> In some cases, the complete equipment of the vehicle may not be available in the system.',
};

const TRANSLATIONS_UA = {
'1901555939':'VIN',
'-971921755':'Марка авто ',
'1891129765':'Модель',
'691501523':'Тип кузова',
'-1026019663':'Тип транспортного засобу',
'274952045':'Пальне',
'-348535418':'Потужність',
'1000727131':'Об´єм двигуна',
'-440181428':'Дата виготовлення',
'841597003':'Поділитися',
'-762872471':'Переглянути',
'-1615835216':'Посилання',
'-466013687':'Закрити',
'-1655267775':'Скопіювати посилання',
'-1448849529':'Чи можу я перевірити історію авто, навіть якщо не знаю його VIN? ',
'-1310016716':'Перевірка транспортного засобу',
'1410745983':'Онлайн',
'-1803530129':'Згенерувати звіт Cebia',
'934563996':'Результат перевірки',
'-1102433484':'Зразок звіту',
'-1242381959':'Історія транспортного засобу',
'417677035':'Перевірити фінансування транспортного засобу',
'-115937358':'Стан транспортного засобу',
'457834942':'Питання та відповіді',
'226942208':'Що таке VIN? ',
'1739046820':'Скільки коштує звіт історії автомобіля?',
'16204687':'Як я можу заплатити?',
'-1607701055':'Як отримати доступ до звіту про історію автомобіля?',
'-1969713941':'Як подати скаргу на звіт про історію автомобвля від Cebia? ',
'1598751038':'Де знайти ідентифікатор звіту',
'-1206763576':'Більше інформації',
'-729900752':'Оплата',
'671435147':'Промокод',
'-2004947080':'Застосуйте промокод на знижку!',
'-1083354004':'Застосувати промокод',
'-1589008349':'Ім´я',
'-704955254':'Прізвище',
'-1631818401':'Вулиця та номер будинку',
'1006101298':'Місто',
'1063186093':'Країна',
'287700219':'Поштовий індекс',
'-1411201334':'ІПН',
'513366821':'ПДВ номер',
'-102071178':'Назва копанії',
'-1882309597':'Назва компанії',
'-1515081676':'Назва компанії',
'1440993804':'Контактна адреса електорнної пошти ',
'-1405758046':'Номер телефону',
'1366947192':'Ваш номер телефону',
'-490851561':'Знижка 35 % на ваш наступний звіт по історії автомобіля',
'-108743580':'CZK',
'786987358':'Отримати звіт',
'-1887658751':'Звіт по історії автомобіля доступний миттєво ',
'-1018049108':'Результати зазвичай доступні протягом 60 хвилин',
'630102694':' Google Pay',
'-1698989463':'Apple Pay ',
'-663138552':'Банківський переказ',
'-1663277':'Результати будуть доступні протягом 48 годин',
'1626540224':'Готівкою в представництвах Cebia',
'72162446':'Перевірити історію автомобіля',
'757687806':'Безкоштовний перегляд доступної інформації',
'1954205527':'Де знайти VIN? ',
'1603459473':'Введіть номер Вашого звіту',
'2038366987':'Перглянути результат',
'-374549211':'Де знайти номер звіту?',
'1262814082':'Введіть VIN',
'-15600132':'Дата першої реєстрації',
'1535680605':'Номер звіту:',
'-1091217450':'Номер звіту дійсний до:',
'-1264594150':'Дата купівлі звіту по історії автомобіля:',
'-701611594':'На жаль, цей автомобіль неможливо перевірити.',
'160166931':'Поточний власник не хоче, щоб дані автомобіля відображалися.',
'-351255422':'В оголошенні, яке ви переглядаєте, не вказаний VIN.',
'1704462566':'Повний VIN не вказано в оголошенні автомобіля.',
'366367808':'Скільки часу потрібно для отримання купленого звіту про історію автомобіля? ',
'2136729030':'VIN:',
'400669011':'Ви маєте право на знижку якщо:',
'-219050562':'Блог',
'1449193326':'Про нас',
'1097675154':'Контакт',
'2062322678':'Новини',
'-1263831198':'Історія компанії',
'1166836894':'Наші партнери',
'-1532347183':'Для медіа',
'717698781':'Кар´єра',
'1620687461':'Транспортний засіб позначений як викрадений. Неможливо згенерувати сертифікат.',
'1365729306':'Номер сертифікату:',
'-89669431':'Рік першої реєстрації',
'2130038984':'Пробіг (км)',
'2089870291':'Автомобіль під контролем з додатком Carolina',
'1333652846':'Завантажити безкоштовно',
'1347577444':'VIN повинен містити 17 символів.',
'622759132':'Лінк скопійовано',
'1714373031':'Транспортний засіб, що проходить перевірку, може',
'1227286459':'Переглянути знайдені події',
'-477335598':'Нагадування',
'-343698081':'Для транспортного засобу, що перевіряється, не вдалося знайти наступні записи',
'-1536285860':'Немає випадків відкликання автомобілів',
'-2052297753':'дата оголошення',
'1522685090':'Деталі',
'444715065':'Більше інформації',
'-394230090':'Підтвердіть будь ласка, що введені вами дані правильні.',
'1224828864':'Невірне значення дати першої реєстрації. ',
'-470093943':'Будь ласка, введіть рік першої реєстрації.',
'1035831822':'Невірний формат пробігу',
'-1161922611':'Будь ласка, введіть пробіг',
'-1383225585':'Введіть рік першої реєстрації',
'1111232857':'Рік першої реєстрації транспортного засобу',
'-1998715395':'Введіть показник одометра',
'-1962337257':'Показник одометра',
'-1888253249':'Специфікація моделі',
'-339477539':'- 300 CZK',
'917746804':'Простіше не буває:',
'1405508217':'First consultation for FREE',
'127414665':'Як отримати знижку?',
'-2081078087':'SELECT A TECHNICIAN',
'1899967331':'Максимальна кількість символів - 20',
'1699452695':'Невірний банківський код',
'1812875983':'Введіть ваш банківський номер рахунку',
'-670727524':'Будь ласка, введіть номер полісу.',
'1884544037':'ePojisteni.cz ',
'138266606':'Дійсний до',
'1881823014':'Політика повернення грошових коштів:',
'-1548724023':'The vehicle must be insured for at least 30 days',
'1331666705':'Не вдалося зберегти. Спробуйте знову.',
'-1393210532':'Номер банківського рахунку',
'439576396':'номер рахунку в банку',
'773379637':'Номер договору страхування',
'-1281605763':'Запит на відшкодування',
'-1670752753':'Код на знижку для нашого сервісу PROVIN (тобто нашого сервісу, який перевіряє походження автомобіля)',
'1496813111':'- 550',
'-856872708':'Базова ціна',
'616158592':'ЗНИЖКА',
'-581170701':'Ціна після знижки',
'-437309148':'Включно',
'-1731075532':'Відео',
'-189892310':'Замовлення',
'98918066':'Як замовити:',
'-1944610133':'Знижка на ваш наступний звіт по історії автомобіля',
'-621660650':'Купон на знижку номер:',
'-1845841489':'Перевірте інше авто з цією знижкою',
'1171588656':'Vehicle security discount',
'1440663595':'- 850',
'-1503110723':'Discount on a physical check of the vehicle',
'-675817237':'- 925',
'986037818':'Знайдено пошкодження',
'873964955':'Дата',
'790922325':'Розрахунок витрат на ремонт',
'-1825735064':'Тип страхового відшкодування ',
'486919475':'Зафіксовані місця пошкоджень',
'1685506455':'Автомобіль профінансований ',
'2039551191':'Фінансування не знайдено',
'-570695392':'У базах даних, які доступні на даний момент',
'-765744228':'Оголошення',
'659651744':'Дата оголошення',
'-695638522':'Оголошений пробіг',
'772986188':'Оголошена ціна',
'419477574':'Записи віку авто та походження',
'-109775782':'Дата виготовлення згідно з виробником',
'-218677523':'Перша реєстрація',
'-340115089':'Перша реєстрація в Чехії',
'1192563560':'Перша реєстрація в Словаччині',
'1650211924':'Виготовлено для ринку',
'1812132824':'Розміщення керма',
'-1229728494':'Матеріал (список)',
'-1147664160':'Робота (список)',
'708828581':'Використовувалося як таксі',
'284182756':'Транспортний засіб оголошено вкраденим',
'1244175337':'Не оголошено вкраденим',
'791649880':'Джерело',
'705052471':'Увімкнено',
'1239149764':'Перевірка ідентифікаторів',
'-799257304':'Модель:',
'-1964821919':'Дата першої реєстрації:',
'-1530361098':'Ціна нового транспортного засобу:',
'68348546':'Поточна ціна на ринку',
'763547310':'Дата першої реєстрації',
'1968050624':'Зберегти',
'-1300982647':'Комплектація',
'397739850':'Перевірка кольору транспортного засобу',
'479427038':'Доступно',
'2038192723':'Перевірка безпеки транспортного засобу',
'-757741002':'Технінчий опис транспортного засобу',
'278755414':'Інструкція з ідентифікації автомобіля',
'1529692191':'Колір транспортного засобу',
'-1171491320':'Ідентифікація транспортного засобу',
'1549116932':'Комплектація транспортного засобу',
'-1985480381':'Інше',
'-1354462940':'ПЕРЕВІРКА:',
'-690036295':'Дата реєстрації:',
'1506895356':'Безпека транспортних засобів:',
'-749715579':'Код маркування:',
'-329154043':'Колір транспортного засобу:',
'1441236976':'Інформація про маркування скла',
'729768522':'Маркування скла',
'1812345820':'НІ',
'847948448':'',
'-1899070354':'Основні технічні характреистики',
'-953303':'Розширені технічені характеристики',
'1237026086':'Невірне значення типу транспортного засобу',
'-259360107':'Будь ласка, введіть тип транспортного засобу',
'-1906046398':'Мінімальна кількість символів - 1',
'-69524395':'Максимальна кількість символів - 30',
'1663292305':'Будь ласка, введіть марку авто',
'814923836':'Будь ласка, введіть модель транспортного засобу',
'-1985695287':'Будь ласка, введіть дані про місце призначення.',
'244480988':'Марка',
'1602844398':'Детальна ідентифікація',
'-1749998805':'Виберіть транспортний засіб',
'-1162182506':'Тип транспортного засобу',
'1104651533':'свідоцтво про реєстрацію транспортного засобу - VIN',
'-1898506589':'лобове скло автомобіля - VIN',
'216861745':'Будь ласка, підтвердіть введені дані',
'-1328097963':'Максимальна кількість символів - 9',
'1678639740':'Будь ласка, введіть свій номер телефону',
'1303052228':'Будь ласка, введіть своє ім\'я',
'1792286505':'Максимальна кількість символів - 128',
'2122707327':'Будь ласка, введіть ваше прізвище',
'-938825602':'Максимальна кількість символів - 64',
'1683031395':'Будь ласка, введіть вулицю та номер будинку.',
'661065202':'Будь ласка, введіть місто',
'-83271898':'Будь ласка, введіть поштовий індекс',
'1018067916':'Максимальна кількість символів - 5',
'12673416':'Неправильний формат поштового індексу',
'154576238':'Неправильний формат ідентифікатора звіту',
'-581020701':'видається виробником транспортного засобу, коли здоров’я, безпека чи навколишнє середовище піддаються значному ризику. Найчастіше ці події викликані прихованими виробничими дефектами автомобіля, які виявляються лише після того, як автомобіль деякий час експлуатується. Як правило, виробник повинен усунути ці дефекти автомобіля безкоштовно. Для отримання додаткової інформації відвідайте веб-сайт <a href=\'https://car-recalls.eu/\' target=\'_blank\'>car-recalls.eu</a> у співпраці з інформаційною системою ЄС RAPEX.',
'1969671182':'Перераховані події відкликання відсортовані за моделлю, маркою та роком виробництва автомобіля, та не сортуються за VIN автомобіля. Перевіряйте деталі відкликання, щоб дізнатися, чи стосуються вони конкретного транспортного засобу, який ви перевіряєте. Во',
'-2076502822':'Подія про відкликання оголошується виробником транспортного засобу щоразу, коли транспортний засіб становить значний ризик для здоров’я, безпеки чи навколишнього середовища. Як правило, виробник повинен усунути ці дефекти автомобіля безкоштовно. Для отримання додаткової інформації відвідайте веб-сайт <a href="https://car-recalls.eu/" target="_blank">Car-recalls.eu</a> у співпраці з інформаційною системою ЄС RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Придбайте поліс через <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> та отримайте назад гроші за перевірку транспортного засобу.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'The Order button will redirect you to the webpage of VINTEST online reservation form. Please fill out this order form and in the notes section, please enter the following password: &amp;lt;strong&amp;gt;Car History&amp;lt;/strong&amp;gt; a &amp;lt;strong&amp;gt;your Car History Report ID.&amp;lt;/strong&amp;gt;',
'5180415':'Кнопка "Замовити" перенаправить вас на веб-сторінку з формою онлайн-бронювання VINTEST. Будь ласка, заповніть цю форму замовлення і в розділі приміток введіть наступний пароль: <strong>CARHISTORY</strong> і ваш <strong>Car History Report ID</strong>.',
'-312241960':'* Зазвичай будь-хто може додавати записи про крадіжки до приватних баз',
'1225691915':'Згідно з нашими базами, транспортний засіб використовувався або використовується як таксі. Ретельно перевірте придатність автомобіля до експлуатації.',
'-742868638':'Ваша адреса електронної пошти',
'2033866416':'Платіжна картка / Apple Pay / Google Pay',
'138706621':'Онлайн-переказ',
'-1634274740':'Переглянути сертифікат Cebia REPORT',
'1047356790':'Будь ласка, введіть рік першої реєстрації',
'-39394372':'Оголошення для автомобілів з меншим % збігу',
'-862383013':'Оголошення про продаж подібних автомобілів',
'-233652021':'ІСТОРІЯ АВТОМОБІЛЯ',
'271847434':'Сертифікат Cebia',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'При замовленні техогляду на Automato.cz введіть код знижки <b>AUTOTRACER</b> у відповідне поле в кошику, і знижка буде автоматично вирахувана з ціни.',
'-1554923364':'(безкоштовний технічний спеціаліст)',
'288042578':'Будь ласка, введіть назву компанії',
'-1335200216':'Неправильне значення ідентифікатора компанії.',
'1693391088':'Неправильний формат електронної пошти',
'-766469985':'Будь ласка, введіть свою електронну пошту',
'-1397806656':'Я згоден на обробку персональних даних',
'-189484387':'Підписатися',
'1095553640':'Всі питання',
'1531150438':'Не знайшли відповідь на своє питання?',
'-469136467':'Зв\'яжіться з нами',
'-461584700':'Перш ніж купувати',
'400498991':'Політика щодо файлів cookie',
'1329764956':'Що таке VIN?',
'-394767958':'Купівля',
'181431424':'Після покупки',
'1649241229':'VIN проштампований безпосередньо на транспортному засобі, або його можна побачити у свідоцтві про реєстрацію транспортного засобу',
'1774694482':'Звідки беруться записи про транспортні засоби?',
'-1122721284':'Cebia також перевіряє транспортні засоби за кордоном?',
'437878122':'Яку інформацію я можу дізнатися про автомобіль?',
'850725814':'Обсяг доступної інформації залежить від автомобіля до автомобіля залежно від його віку та історії. Після того, як ви введете VIN-код, ми одразу повідомимо вам, яку інформацію ми можемо надати вам про транспортний засіб у <a href="/payed/detail/exampleUA" target="_blank">зразку звіту</a>.',
'-656971513':'Як виглядає звіт про історію автомобіля?',
'966688141':'Вартість звіту залежить від обсягу доступної інформації про транспортний засіб. Дізнатися, які саме записи доступні, та побачити відповідну вартість звіту про історію автомобіля можна, ввівши VIN-код.',
'764893782':'Ви можете оплатити онлайн кредитною карткою або через Google Pay та ApplePay. Платежі захищені безпечною платіжною системою.',
'726713260':'Скільки часу знадобиться для отримання звіту?',
'1478989945':'Звіт формується одразу після отримання платежу. Сервіс працює у повністю автоматичному режимі.',
'1681038954':'Чи можу я ввести дані моєї компанії при здійсненні платежу?',
'696259005':'Заповнюючи свої дані, ви можете ввести дані своєї компанії. Але після оплати формується тільки спрощений податковий документ. Якщо ви хочете отримати рахунок-фактуру, зв’яжіться з нами за адресою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>.',
'-1555365601':'Звіт про історію автомобіля створюється автоматично, щойно надходить платіж. Посилання на результат і ваш ідентифікатор звіту також буде надіслано на електронну адресу, яку ви ввели під час покупки. Звіт про історію автомобіля можна переглядати онлайн протягом 30 днів після його створення.',
'1984400663':'Платіж не був успішним, що тепер?',
'-956265022':'Якщо з якоїсь причини платіж не пройшов, поверніться на один крок назад і здійсніть платіж знову. Якщо з вас стягнуто плату, але ви досі не отримали підтвердження електронною поштою, зв’яжіться з нами за адресою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>.',
'-246543694':'Він буде доступний протягом 30 днів після його створення. Ви можете переглянути його за посиланням, надісланим на адресу електронної пошти, яку ви вказали під час придбання звіту.',
'592240026':'Я не отримав електронного листа з підтвердженням, що я можу зробити?',
'339130199':'Електронний лист автоматично генерується після оплати, якщо він не надійшов до вашої скриньки, зв’яжіться з нами за адресою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>. Додайте в повідомлення ідентифікатор звіту або номер VIN.',
'1681612939':'Я ввів неправильний email, що тепер?',
'-818807180':'Нічого страшного. Будь ласка, напишіть нам на адресу <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>, вказавши правильну адресу електронної пошти та вказавши ідентифікатор звіту, знайдений у звіті. Потім ми надішлемо посилання для доступу на правильну електронну адресу.',
'-40057886':'На звіт можна поскаржитися. Якщо ви підозрюєте, що до Звіту включено невірну інформацію, зверніться до нашого відділу обслуговування клієнтів за адресою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>.',
'-755697752':'Чи можна виставити рахунок-фактуру?',
'1950759546':'Через вартість перевірки автоматично формується лише спрощений податковий документ. Щоб отримати рахунок, зв’яжіться з нами за адресою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>. Щоб пришвидшити процес, будь ласка, також вкажіть номер купона в електронному листі, який ви отримали після покупки.',
'-1960272511':'Введіть VIN',
'-371538970':'Де знайти VIN-код?',
'-1956291670':'Як це працює?',
'1850076653':'КРОК 1',
'-1260091754':'Доступні дані',
'1279291471':'КРОК 2',
'-811487544':'Оплата',
'777550298':'КРОК 3',
'-1534039143':'Звіт про історію автомобіля',
'495126992':'КРОК 4',
'1916048998':'Перевірте',
'-580693003':'Контакти',
'-760821457':'Вам потрібна допомога?',
'1432374431':'Контактна форма',
'1838884148':'Рецепція Cebia',
'1665302400':'Штаб-квартира компанії',
'1196114687':'Україна',
'-95958777':'Інформація для виставлення рахунків',
'1808758025':'Телефон',
'275235071':'Ми доступні',
'353260003':'Пн - Пт: 8:00 - 16:30 CET',
'822817400':'Перевірити історію автомобіля',
'-1317542970':'Надійний сервіс',
'-1884218466':'Для кожного автомобіля ми перевіряємо',
'1736624969':'Пробіг',
'2137107000':'Історія пошкоджень',
'647263705':'Викрадення транспортних засобів',
'-441819024':'Історія обслуговування',
'982206269':'Записи з реєстру',
'1753341741':'Використовувався як таксі',
'-366535509':'Автомобільні експерти користуються послугами Cebia',
'-1435496559':'Generali Česká spořitelna Logo',
'1847411491':'Logo Kooperativa',
'1734653310':'Ви знаєте нас з...',
'654946563':'символи',
'-1398891951':'Пробіг',
'1425691903':'Понад 33% вживаних автомобілів на ринку мають штучно зменшений пробіг!',
'1803799674':'Справжні рік та країна виготовлення',
'1389120536':'Вік і походження вживаних автомобілів, які привозять з-за кордону, часто фальсифікують!',
'-2068933994':'Перевірка на крадіжку',
'-1184837319':'Перевірка на лізинг',
'-1877453535':'Перевірка на пошкодження',
'64494271':'35% імпортованих вживаних автомобілів були в ДТП!',
'1051433840':'Перевірте, чи не був автомобіль оголошений як розбитий!',
'1076835371':'Оцінка транспортних засобів',
'-1805177459':'Згадати події',
'957124168':'Кількість власників у Чехії',
'-1705145830':'Детальна комплектація авто',
'1890293527':'Перевірка роботи в таксі',
'-13439485':'Будь ласка, введіть своє ім\'я',
'-1147931879':'Будь ласка, введіть своє прізвище',
'2144560301':'Неправильний номер телефону',
'-1642977586':'Будь ласка, введіть свій номер телефону',
'-1249914076':'Неправильний формат електронної пошти',
'-2038223065':'Будь ласка, введіть своє повідомлення',
'810453022':'Електронна пошта',
'-1217737116':'Повідомлення',
'-664538889':'Я даю згоду на обробку моїх персональних даних',
'1322823680':'Надіслати повідомлення',
'56891982':'Максимальна кількість символів - 16.',
'277834303':'Дякую',
'552239414':'Ваше повідомлення надіслано',
'1851370681':'Ми зв\'яжемося з вами найближчим часом.',
'1952981238':'Попередження',
'342579190':'Всього',
'1586626737':'Отримати звіт',
'399846737':'Завантаження',
'556543048':'Отримайте наступну інформацію:',
'560876430':'Фото з попередніх продажів',
'-320410471':'Немає в наявності',
'1185850210':'Записів про пошкодження не знайдено',
'-903608283':'Короткий огляд історії транспортного засобу',
'718416430':'Інформація щодо звіту про історію автомобіля',
'668096075':'Введіть ідентифікатор звіту для повторного введення:',
'-1690204814':'Дійсний до:',
'-840281270':'Передача',
'-1901926256':'Базові технічні характеристики автомобіля',
'-1550051512':' Тип кузова автомобіля',
'-1043441574':'ПОПЕРЕДЖЕННЯ:',
'-709435856':'Клієнтський досвід',
'-1149927862':'Що говорять користувачі про Cebia!',
'456577623':'Виявилося, що автомобіль був битий, а пробіг - скручений. Дякую!',
'329190632':'Сервіс виправдав очікування, машина виявилась після ДТП.',
'1450297818':'Перевірка на крадіжку в Чехії та Словаччині',
'-1594408882':'Знижка на перевірку автомобіля незалежним техніком Autobezobav.cz',
'341709606':'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Обов\'язкове страхування цивільно-правової відповідальності або страхування від нещасного випадку необхідно оформити через портал ePojisteni.cz протягом 30 днів з моменту оплати чеку транспортного засобу в системі AUTOTRACER',
'-355614707':'Пропозиція поширюється на страхування легкових, комерційних та вантажних автомобілів (крім мотоциклів, квадроциклів, трициклів, причепів та напівпричепів)',
'-134420730':'Не поширюється на страхування, оформлене через портал ePojisteni.cz, перед покупкою транспортного засобу перевірте в системі Car History',
'1195365944':'Після того, як страховка буде оформлена, заповніть номер рахунку та номер полісу, і сума буде автоматично відправлена на рахунок, вказаний вами в анкеті, як тільки будуть виконані умови, але не пізніше 60 календарних днів з моменту відправлення заявки.',
'-1622287889':'Ваш запит надіслано. Ми зв\'яжемося з вами не пізніше наступного робочого дня.',
'1069770504':'Якщо обраний вами транспортний засіб ввозиться до Чеської Республіки в індивідуальному порядку, ми рекомендуємо вам скористатися послугою перевірки іноземного походження PROVIN.',
'-121060108':'Ми перевіримо реєстрацію транспортного засобу в країні походження, чи був він належним чином знятий з обліку, чи не був транспортний засіб виведений з експлуатації через повну поломку, чи не числиться він в країні як викрадений та інші факти.',
'-1158490787':'Знижку можна використати протягом 30 днів після покупки Car History Report і її не можна комбінувати з іншими знижками.',
'-1471409910':'Хочете бути впевненими, що обраний вами автомобіль не був незаконно переобладнаний?  Ми рекомендуємо вам скористатися послугою комплексної перевірки ідентифікатора транспортного засобу VINTEST.',
'1555104762':'Послуга VINTEST надає клієнтам 100% гарантію результату фізичного огляду транспортного засобу. Ми проводимо детальний фізичний огляд автомобіля з використанням новітньої цифрової діагностики. Метою цієї послуги є перевірка того, що транспортний засіб має всі оригінальні ідентифікатори (VIN, мітки, зварні шви кузова) і що транспортний засіб або частина транспортного засобу не є підозрюваним результатом злочинної діяльності.',
'735500953':'У перерахованих компаніях можна дізнатися, чи транспортний засіб перебуває у фінансуванні (в лізингу чи кредиті), або ж заставі. Дані отримуються безпосередньо з інформаційних систем кожної компанії.',
'-1173444543':'Лізингові та кредитні компанії',
'-976513658':'*Каршеринг = оренда автомобіля, коли приватна особа орендує свій автомобіль на короткий період часу (або, альтернативно, каршеринг може також означати спільне використання автомобіля між приватним власником і короткостроковим позичальником).',
'-1479754603':'Згідно з нашими базами, транспортний засіб не використовувався як таксі.',
'-467405909':'Облік транспортного засобу, який використовується як таксі',
'1930957191':'Надана інформація носить виключно інформаційний характер. Cebia не несе відповідальності за будь-які збитки, спричинені використанням даних.',
'-1895346741':'Наш калькулятор вартості оцінить ринкову ціну будь-якого вживаного автомобіля. Ми оцінюємоїї на основі масиву даних щодо продажів вживаних автомобілів у Чеській Республіці та за кордоном, наприклад, ціни фактичних продажів. Алгоритм оцінки працює з розрах',
'-985858180':'У рідкісних випадках вартість транспортного засобу може бути недоступною. Це тому, що недостатньо даних для кваліфікованої оцінки вартості автомобіля.',
'1915347014':'Ми покажемо вам списки подібних транспортних засобів, які наразі пропонуються на ринку, а в деяких випадках також ціни на схожі транспортні засоби, які фактично продаються (ми використовуємо VIN-код для порівняння точних характеристик транспортних засобів). Використовуючи списки схожих транспортних засобів, ви можете порівняти, чи відповідає ціна транспортного засобу, який ви перевіряєте, аналогічним автомобілям на ринку. Ми економимо ваш час, витрачений на пошук і порівняння схожих списків в Інтернеті.',
'-767763886':'Записів оголошень продажу транспортних засобів не виявлено.',
'797541609':'Перевірте, в якому стані та з яким пробігом автомобіль продавали в інтернеті раніше. Якщо в оголошенні продажу був вказаний вищий показник одометра, ніж зараз, можливо, пробіг був скрученим.',
'-1902352540':'Я перевірив і підтверджую інформацію вище',
'-2008975653':'Виробник або під час першої реєстрації транспортного засобу не вказували колір автомобіля.',
'7150984':'Переконайтеся, що поточний колір автомобіля збігається з кольором, заявленим виробником, або кольором, задокументованим після першої реєстрації в Чехії (або Словаччині). Якщо колір інший, можливо, автомобіль був перефарбований, розбитий або викрадений.',
'-1664139465':'Список обладнання відсутній. Рекомендуємо перевірити працездатність та оригінальність обладнання фізично в автомобілі.',
'1681595153':'Ми повідомимо, чи має автомобіль маркування на склі та чи зареєстрований він у системі OCIS. Якщо так, ми надамо вам позначений код і дату реєстрації.',
'571758489':'Транспортний засіб раніше мав маркування скла та зареєстрований у міжнародній базі даних OCIS.',
'855264535':'Якщо унікальний код відсутній на деяких або всіх вікнах транспортного засобу, можливо, автомобіль був розбитий, перероблений або викрадений.',
'388233970':'Інформація про виконане маркування скла:',
'1615022749':'Нам не вдалося знайти технічні параметри автомобіля. Однак ви можете знайти ці параметри в свідоцтві про реєстрацію транспортного засобу, або ж ви можете запитати їх у виробника транспортного засобу.',
'704641294':'Введіть дані автомобіля та отримайте інструкції щодо перевірки ідентифікаторів автомобіля. Інструкції загалом стосуються будь-якого транспортного засобу зазначеної марки та моделі.',
'-621659477':'На основі введеного вами VIN ми можемо автоматично попередньо заповнити дані транспортного засобу. Якщо попередньо заповнені дані про транспортний засіб не відповідають фактичним даним вашого транспортного засобу, видаліть їх і введіть дані про свій транспортний засіб вручну.',
'152333450':'Записів про пошкодження не знайдено, що це означає?',
'780709713':'Як довго звіт буде доступним?',
'830704335':'Дізнайтеся, яка інформація доступна для вашого автомобіля:',
'1850331238':'Чудовий сервіс, вартий своїх грошей. Car History виявив скрутку пробігу',
'1705245752':'Я хотів купити машину, про яку продавці казали «не бита-немальована». Autotracer виявив аж три дтп за кордоном!',
'739619867':'Зекономили гроші, бо не купили автомобіль після аварії',
'-2071079400':'Продавець не знав (або не сказав) про пошкодження машини в минулому. Завдяки сервісу мав можливість поторгуватися і суттєво збити ціну',
'21202350':'Дякую за перевірку! Якби не Autotracer, втратив би кілька тисяч доларів на машині. За словами продавця, "був трохи битий спереду". По факту вся безпека спрацювала, машина бита по кругу. Не купив і щасливий',
'1343185270':'Перевірив машину, впевнився, що все добре, і стало лешге прийняти рішення про купівлю авто',
'2083029914':'Cebia виявила значні пошкодження, про які продавець навіть не згадував',
'1933958141':'Виявив незначне дтп + звірив одометр з дати першої реєстрації.',
'1900495866':'Завдяки програмі виявив проблеми з машиною і почав більше її перевіряти, дякую',
'-1668804606':'Перевірка Cebia показала серйозні пошкодження автомобіля, які продавець приховав від мене, і скручений пробіг',
'-273876487':'Тепер знаю, що мій автомобіль насправді проїхав 430 тисяч км, а не 240, як на одометрі',
'911857027':'Введіть ідентифікатор звіту, щоб переглянути звіт про історію автомобіля',
'1167593586':'Ви отримаєте ідентифікатор після того, як придбаєте звіт про історію автомобіля.',
'-2136315768':'Якщо ви оплачуєте карткою, GooglePay або ApplePay, ви миттєво отримаєте ідентифікатор звіту електронною поштою та зможете миттєво переглянути звіт про історію авто.',
'868918064':'Якщо ви оплачуєте банківським переказом, ви отримаєте електронний лист із ідентифікатором звіту про платіж одразу після зарахування коштів на наш банківський рахунок Cebia.',
'1572179326':'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
'-1322783148':'Розташування VIN-коду залежить від однієї марки та моделі автомобіля. Але, як правило, VIN завжди можна знайти в свідоцтві про реєстрацію автомобіля, і часто він знаходиться під лобовим склом.',
'700484420':'VIN - це унікальний ідентифікаційний номер кожного автомобіля. Його можна знайти в документах на автомобіль або безпосередньо на автомобілі.',
'-851712281':'Ми відстежимо всі записи, доступні для автомобіля, який ви перевіряєте, і покажемо вам діапазон цих записів. Ви завжди знаєте, що купуєте.',
'-1983041378':'Ви можете оплатити через безпечний Оплата карткою або банківським переказом.',
'-1809684191':'Звіт про історію автомобіля доступний відразу після оплати. Звичайно, ми також надішлемо його вам електронною поштою.',
'541894049':'Ви дізнаєтесь про зміну пробігу транспортного засобу протягом спостережуваного періоду часу. На простому графіку ви можете побачити, як змінювалися показання одометра з часом, і виявити можливі скрутки пробігу.',
'643692561':'Ви можете переглянути попередні записи про пошкодження транспортного засобу, про який йде мова. Інформація про вартість збитку та розмір збитків доступна в розділі деталей. Увага: не забудьте перевірити технічний стан автомобіля та інші його пошкодження. Наприклад, чи були повні пошкодження автомобіля.',
'878444309':'Якщо транспортний засіб раніше продавали в інтернеті, ми покажемо вам оголошення та фото',
'185243225':'Ви знайдете детальну інформацію та огляд сервісних робіт, які виконувалися під час ремонту або планового обслуговування автомобіля. Увага: порівняйте записи з сервісною книжкою та попросіть більше інформації про історію обслуговування автомобіля.',
'1036319803':'Ми перевіримо для вас фактичний рік випуску, який може суттєво вплинути на поточну та майбутню ціну автомобіля. У свідоцтві про реєстрацію автомобіля не завжди вказується рік випуску, а лише дата першої реєстрації. Ці дві дати можуть відрізнятися від року',
'-437053085':'Ми перевіримо автомобіль за наявними базами даних викрадених автомобілів. В основному це європейські бази даних, зібрані як з державних, так і з приватних джерел. Список доступних країн і джерел можна знайти у звіті про історію автомобіля.',
'494784690':'Ми перевіримо автомобіль за базами даних фінансових компаній, компаній з прокату автомобілів і ломбардів. У звіті про історію автомобіля ви дізнаєтеся, чи обтяжений автомобіль фінансуванням, кредитом чи заставою.',
'2074346923':'Ми перевіримо транспортний засіб по базам даних таксі. У звіті про історію автомобіля ви дізнаєтесь, чи використовувався автомобіль як автомобіль таксі.',
'-976680067':'Ми покажемо вам список подій відкликання, які можуть стосуватися автомобіля, який ви перевіряєте.',
'1665109686':'Технічний опис дозволить вам перевірити важливі параметри, на підставі яких модельний варіант типу автомобіля був схвалений до використання. Порівняйте ці параметри з даними свідоцтва про реєстрацію транспортного засобу.',
'-719963267':'Виведемо перелік обладнання автомобіля згідно технічного завдання. Ви можете порівняти поточне оснащення автомобіля з тим, що зазначено виробником. Будь ласка, зверніть увагу: якщо, наприклад, виробник вказує правостороннє кермо, шкіряну оббивку або люк у специфікаціях автомобіля, а ваш автомобіль має лівостороннє кермо, тканинну оббивку або не має люка, це, ймовірно, не той самий автомобіль, але може бути викрадений та відремонтований автомобіль! Якщо є будь-яка невідповідність, вам слід звернути особливу увагу на подальший фізичний огляд транспортного засобу, щоб запобігти помилковій купівлі транспортного засобу, який може бути продуктом злочинної діяльності.',
'-1106584159':'Ваш автомобіль захищено за допомогою піскоструминної обробки вікон або травлення, або автомобіль може мати позначені частини кузова. Ми надамо вам позначений код і дату реєстрації автомобіля.',
'1830950672':'Ми покажемо вам колір, зареєстрований виробником транспортного засобу, і колір після реєстрації.',
'1835889470':'Завантаження...',
'1020539397':'Оператор історії автомобіля - ТОВ "Дата Авто" повідомляє своїх користувачів, що доступна інформація була введена в систему партнерами оператора без можливості перевірки її достовірності. Вся інформація носить виключно інформаційний характер.',
'2054100346':'Ми не можемо гарантувати точність результатів звіту про історію автомобіля.',
'-167783152':'Цей звіт було створено на основі запиту до системи Car History, якою керує ТОВ "Дата Авто" згідно з чинними Загальними положеннями та умовами використання Історії автомобіля. Користувач має право використовувати надану інформацію в повному обсязі виключно для власного використання. Його подальше розповсюдження або зміна без згоди оператора заборонено.',
'-1697979879':'Генеральний директор Cebia, Ing. Мартін Паєр',
'-893067318':'<strong>Інж. Мартін Паєр</strong> генеральний директор Cebia',
'759051798':'Про Cebia',
'-31539157':'Ми - чеська компаня, заснована в Празі в 1991 році. Нашими верафікаціями, безпеки та ідентифікації транспортних засобів користуються клієнти в 9 країнах Європи. Серед наших клієнтів - автомобілісти, страхові компанії, автодилери, страховики, урядові установ',
'-1821588851':'Претензії та скарги',
'-276118588':'Претензії та скарги є цінним джерелом пропозицій і допомагають нам покращувати наші послуги. Будь ласка, зв\'яжіться з нами за допомогою контактної форми або електронною поштою: <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>',
'-1446517877':'Ми розглянемо вашу скаргу протягом 14 днів після її отримання та повідомимо вам про результат.',
'708234375':'Зв\'яжіться з нами через контактну форму або електронною поштою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>',
'1474175101':'Загальні умови обслуговування',
'1343500875':'Політика конфіденційності',
'-2083968164':'Онлайн оцінка автомобіля',
'907588970':'Заснування компанії',
'-770165824':'<strong>30 років досвіду</strong> в перевірці вживаних авто',
'-1138482741':'Щороку перевіряється понад <strong>1 000 000 автомобілів</strong>',
'154007106':'<strong>750 000+</strong> задоволених клієнтів',
'177137909':'База даних Cebia містить понад 1 мільярд записів про вживані авто з понад 32 країн. В основному це записи пробігу автомобіля, пошкоджень, фотографії транспортних засобів та їх сервісні книжки.',
'723486619':'Завантаження...',
'742852638':'Період:',
'787763184':'період часу:',
'-685036969':'Легенда:',
'290784658':'Недоступний:',
'412892373':'Нам не вдалося знайти жодної інформації про транспортний засіб у цій категорії',
'1853282090':'В наявності:',
'-249038921':'XY записів:',
'805146013':'Ми знайшли XY записів для автомобіля в цій категорії. Ви можете отримати доступ до цих записів, якщо заплатите за послугу',
'242118549':'Загальні положення та умови',
'521725542':'Перегляньте Загальні положення та умови використання CAR HISTORY',
'-571686819':'Тут ',
'639498968':'Оплата за перегляд звіту про історію автомобіля або сертифіката стягуватиметься згідно з умовами вашого договору з Cebia.',
'-1705426324':'Ви можете повернутися до онлайн-звіту про історію автомобіля протягом 30 днів після першого доступу до нього.',
'529403448':'Важливо:',
'-821000576':'Cebia, spol. s r.o. повідомляє своїх клієнтів, що надана інформація була внесена в систему партнерами оператора без можливості перевірки її достовірності. Вся інформація носить виключно інформаційний характер. Якщо стан транспортного засобу значно супереч',
'-1138137280':'Наша політика щодо захисту та обробки персональних даних',
'125063053':'Перегляньте нашу політику обробки персональних даних для CebiaNET і Car History.',
'-608864551':'Ми знайшли інформацію про транспортний засіб у цій категорії. Ви можете переглянути інформацію у звіті про історію автомобіля.',
'-1879995849':'Введені дані',
'754897480':'Рік першої реєстрації',
'-1834106204':'Поточний пробіг',
'404797827':'Переглянути сертифікат',
'1358780725':'Створити сертифікат',
'-952450705':'Я перевірив і підтверджую інформацію вище',
'-1601808583':'<span classname="green">Готово!</span> Було знайдено таку інформацію:',
'-1763095911':'Є можливість видати сертифікат Cebia REPORT, який оцінює такі категорії:',
'761362384':'Зразок ЗВІТУ',
'-397155268':'ПЕРЕГЛЯНУТИ ЗВІТ',
'1375027269':'Перевірте автомобіль',
'2055286638':'в більш ніж',
'-1668243810':'Зміцнюється',
'1141836815':'Стаємо частиною міжнародної інвестиційної групи EAG Holding. У результаті наші бази даних значно розширюються, включаючи записи з усієї Європи, особливо Німеччини, Австрії та Данії.',
'-903271711':'Ми запускаємо онлайн-інструмент для визначення ціни вживаних автомобілів. Завдяки нашій обширній базі даних про вживані автомобілі цей інструмент є високоточним і затребуваний найбільшими страховими та лізинговими компаніями.',
'-1262829232':'Трансформація ринку вживаних автомобілів',
'1367974718':'У зв’язку зі зростанням інтересу до вибору автомобіля онлайн ми починаємо співпрацювати з найбільшими порталами оголошень у Центральній Європі. Результати наших перевірок стають ознакою якості в сегменті вживаних автомобілів.',
'-300528762':'Вхід у світ онлайн',
'-899322976':'Наші послуги тепер легко доступні кожному, не виходячи з дому. Шахрайство з вживаними автомобілями досягло свого піку, і ми боремося з ним усіма силами.',
'-4875574':'Розширення наших послуг',
'1166185728':'Ми додаємо фізичну перевірку ідентифікаторів транспортних засобів (VIN), і наша база даних розширюється не по годинах. Ми співпрацюємо зі страховими компаніями, державними установами та поліцією.',
'-1619922514':'Виїзд за кордон',
'-844900279':'Торгівля викраденими автомобілями поширюється в багатьох інших країнах, ніж у Чехії. Тому ми створили міжнародну базу даних викрадених автомобілів, щоб допомогти нам виявити справжнє походження вживаних автомобілів.',
'598423318':'Троє однокурсників по університету встановлюють Cebia. Початкова мета компанії – бути в ІТ-секторі, незабаром була замінена інтересом до безпеки транспортних засобів.',
'-1828699417':'Викуп/Неплатоспроможність',
'4000011':'Безкоштовна перевірка документів',
'1479506336':'Історія пошуку',
'-1478881101':'Історія перевірки автомобіля',
'1115668272':'Історія викупу/банкротства',
'691760517':'Історія перевірки документів',
'-614781938':'Обсяг і вартість звіту про історію автомобіля можуть відрізнятися від автомобіля до автомобіля залежно від обсягу інформації, яку ми маємо про автомобіль.',
'702191442':'Назад',
'-1034048918':'Перевірка VIN',
'-1214567423':'Номер для посилань',
'427849990':'Створити сертифікат',
'-1718377395':'Помилка читання ідентифікатора звіту.',
'877365836':'Не вдалося створити URL-адресу платежу.',
'-1082791212':'Недійсний код знижки',
'-255296664':'Ідентифікатор звіту надає доступ до звіту протягом 30 днів після покупки.',
'456125545':'ТОВ "Дата Авто" повідомляє своїх клієнтів, що надана інформація була введена в систему партнерами оператора без можливості перевірки її достовірності. Вся інформація носить виключно інформаційний характер. Якщо стан транспортного засобу значно суперечить наданій тут інформації, ми рекомендуємо звернутися до відповідного експерта, щоб перевірити точний стан автомобіля.',
'-1172808350':'Попросіть продавця надати вам VIN-код, а потім перевірте автомобіль.',
'-2079080642':'Попросіть продавця надати вам 17-значний VIN-код, а потім перевірте автомобіль.',
'-585052469':'Купуєте вживаний автомобіль? Перевірте його історію, дані про пробіг чи пошкодження, ввівши його VIN-код!',
'76811920':'ЗРАЗОК результатів перевірки автомобіля',
'1050846059':'Тим не менш, ми все одно рекомендуємо вам фізично перевірити свій автомобіль, в ідеалі кваліфікованим механіком або в гаражі на ваш вибір. Такий огляд може чітко виявити або виключити попередні пошкодження.',
'-1068264553':'Завжди перевіряйте дату виробництва, заявлену виробником автомобіля. У свідоцтві про реєстрацію транспортного засобу часто вказують лише дату першої реєстрації. Ці дві дати можуть відрізнятися від року виробництва на 2 роки, але навіть розбіжність у 6 рок',
'756232271':'Записів про вік або походження транспортного засобу не знайдено. Якщо автомобіль надходить з-за кордону, завжди вимагайте та ретельно перевіряйте оригінальну документацію на транспортний засіб (наприклад, сервісну книжку або свідоцтво про реєстрацію транспортного засобу).',
'-643660678':'Знайдено один або кілька службових записів.',
'1533967406':'Ціна звіту про історію автомобіля різна. Це залежить від обсягу та діапазону відомостей про історію, які ми маємо для певного автомобіля. Ви можете дізнатися обсяг доступної інформації та відповідну ціну конкретного звіту про історію автомобіля, ввівши VIN-код автомобіля.',
'-1289463237':'Зазвичай звіт формується відразу після оплати. Послуга доступна цілодобово та повністю автоматизована.',
'1304585346':'Після того, як ваш платіж буде оброблено, система Car History автоматично надішле вам електронний лист на вказану вами електронну адресу. В електронному листі ви знайдете ідентифікатор звіту про історію автомобіля який надасть вам доступ до оплаченої інформації разом із спрощеним податковим документом.',
'-661494819':'Помилка завантаження',
'-1681989289':'Купуючи звіт про історію автомобіля, ви отримуєте знижку на наступну покупку. Введіть свій ідентифікатор звіту в полі коду знижки під час наступної покупки. Знижка діє 90 днів після покупки.',
'681093117':'Ви отримали код знижки при продовженні послуги FLEX на наступний рік. Щоб скористатися знижкою, введіть номер коду знижки, який ви отримали на податковій квитанції FLEX, у поле "Введіть номер купона".',
'-1551909822':'Ви отримали код знижки на безкоштовну перевірку автомобіля. Ви можете скористатися знижкою, ввівши свій ідентифікатор звіту в полі коду знижки під час наступної покупки.',
'-103162544':'Після обробки платежу ми надішлемо вам посилання на звіт про історію автомобіля разом із спрощеним податковим документом на вказану нижче електронну адресу.',
'-591911074':'Після обробки платежу ми надішлемо вам посилання на звіт про історію автомобіля разом із спрощеним податковим документом на вказану нижче електронну адресу.',
'706315962':'Якщо вам потрібен рахунок із платіжною інформацією компанії (тобто документ із назвою компанії, адресою, реєстраційним номером та ідентифікаційним номером платника ПДВ), заповніть цю інформацію нижче.',
'500915765':'Коли ви розмістите замовлення, ви будете перенаправлені на платіжний шлюз iPay.ua, щоб здійснити безпечний платіж карткою. Якщо ваш платіж буде успішним, ви будете автоматично перенаправлені назад до системи історії автомобіля, щоб переглянути звіт про істо',
'-676823504':'Якщо ви виберете цей спосіб оплати, ви будете перенаправлені на платіжний шлюз, де ви виберете свою банківську установу, з якої хочете здійснити платіж.',
'-764670786':'Якщо ваш банк пропонує миттєвий онлайн-переказ, ми перенаправимо вас до онлайн-банкінгу, де ви матимете попередньо заповнене платіжне доручення.',
'2062466434':'Якщо ми негайно отримаємо платіж від банку, ми автоматично перенаправить вас до звіту про історію автомобіля. В іншому випадку зачекайте, поки ви отримаєте електронний лист.',
'734607756':'Після того, як ваш платіж буде оброблено, система Car History автоматично надішле вам електронний лист на вказану вами електронну адресу. В електронному листі ви знайдете ідентифікатор звіту про історію автомобіля який надасть вам доступ до оплаченої інформації разом із спрощеним податковим документом.',
'1715999355':'Google Pay – це швидкий і простий спосіб оплачувати будь-де через Google, не вводячи платіжні дані. Ви можете оплатити будь-якою карткою, збереженою в Google Pay. Однак зауважте, що для використання цієї платіжної служби ваш банк має підтримувати Google Pay. Якщо ваш платіж пройде успішно, вас буде автоматично перенаправлено назад до звіту про історію автомобіля, щоб переглянути детальну інформацію про автомобіль. Водночас система Car History надішле вам автоматичне повідомлення на вказану вами адресу електронної пошти, що містить спрощений податковий документ та ідентифікатор звіту про історію автомобіля, який ви можете використати для доступу до звіту про історію автомобіля пізніше.',
'790632208':'Apple Pay — ?? ?????? ??????, ???? ???????? ?????? ?? ????????? ??????, ?????????? ? Apple Wallet. ????????????: ?? ??? ????? ??????????? ??? ?????? ??????, ????? ????????????? ?????????, ?? ???????????? ??? ???? Apple Pay. ???? ??? ?????? ????? Apple Pay ?????? ???????, ??? ???? ??????????? ?????????????? ????? ?? ????? ??? ??????? ??????????, ?? ?? ??????? ??????????? ???????? ??????? ??????????. ? ??? ?? ??? ??????? Car History ??????????? ??????? ??? ??????????? ???? ?? ????????? ?????????? ?????????? ? ??????????????? ?????, ???? ?? ?????? ??????????????? ??? ???????????? ????? Car History ???????.',
'-1402859324':'Якщо ваш банк пропонує лише так званий офлайн-переказ, ми створимо інструкції для банківського переказу, потім ви перекажете кошти у своєму банку, і коли сума буде зарахована на наш банківський рахунок, ми надішлемо вам електронний лист із доступом до системи Car History та підтвердження оплати.',
'347099860':'Це може зайняти до 2 робочих днів (але зазвичай воно готове протягом 24 годин).',
'-43783036':'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
'-802148009':'Ваучер Car History можна оплатити особисто готівкою в головному офісі компанії Cebia, spol. s r.o., BB Centrum - Alpha building, Vyskočilova 1461/2a, 140 00 Praha 4 в робочий час з понеділка по п\'ятницю з 8:00 до 16:30. При собі необхідно мати VIN-код транспортного засобу, що підлягає перевірці.',
'1985201267':'Найбільша в Європі база даних вживаних авто в Європі',
'-1590601891':'Звіт про історію автомобіля може не містити записів про всі пошкодження транспортного засобу, деякі збитки можуть не повідомлятися страховикам або дані можуть бути недоступними.',
'-1465948017':'',
'1553997983':'',
'-124714580':'Ви можете оплатити онлайн за допомогою кредитної картки або через Google Pay та Apple Pay. Платежі обробляються захищеним платіжним шлюзом.',
'-753863533':'СЕРТИФІКАТ ПЕРЕВІРКИ ТРАНСПОРТНОГО ЗАСОБУ - огляд доступної інформації',
'1441255407':'Категорія',
'1549893453':'Варіант перевірки',
'1547427535':'ТАК',
'508023484':'Перевірка дати реєстрації автомобіля',
'2076692599':'Фізичний огляд',
'-1521980547':'Перевірка пробігу',
'-1009985044':'ТАК - на основі',
'1747355819':'інформація',
'1639206028':'Перевірка походження автомобіля',
'-1524110425':'Контроль сплати ЕКО податку',
'-1961089879':'Перевірка ПДВ',
'1732127987':'Перевірте наявність серйозних пошкоджень',
'-198755848':'Перевірка реєстрації',
'-1790774232':'Перевірка пошкоджень від повені',
'-1781437228':'Перевірка типу двигуна',
'-1344389293':'Перевірка ідентифікаторів',
'-499181915':'Фото',
'-687939409':'Інформацію було взято з записів про стан транспортних засобів, придбаних Cebia або мережею її підрядників.',
'-43022982':'Дата надання послуги:',
'-136414993':'Статус автомобіля:',
'2023782231':'Транспортний засіб пройшов фізичний огляд на його ідентифікатори (штампування VIN, заводські етикетки тощо). Інформацію було взято з протоколів обслуговування VINTEST, проведених компанією Cebia або її підрядниками.',
'758499555':'Стан ідентифікаторів:',
'488100027':'НАЗАД',
'-1281249872':'ПРОДОВЖУЙТЕ',
'-141562572':'СТВОРИТИ',
'-40297285':'Ви можете отримати динаміку цін і огляд даних для автомобіля, який ви перевіряєте.',
'-1742933347':'Номер оголошення',
'-2099386182':'Розпродаж',
'1560408371':'Ціна продажу',
'-280814568':'Переглянути талон перевірки',
'2075274708':'Дата',
'-1248991647':'Україна',
'-1779298552':'Швеція',
'-18328562':'Латвія',
'1764744897':'Литва',
'-1273046880':'Румунія',
'1252567719':'Болгарія',
'445626359':'Греція',
'-868498706':'Словенія',
'-1528448787':'Угорщина',
'-387469480':'Словакія',
'25300212':'Австрія',
'1771002523':'Італія',
'69077775':'Данія',
'647944028':'Польща',
'1160986873':'Німеччина',
'897084544':'Франція',
'2056598840':'Іспанія',
'264100206':'Росія',
'-1609348689':'Політика файлів cookie',
'-365994139':'Завантажити ще',
'807848789':'Мільйони задоволених клієнтів',
'1677683955':'Cebia має понад <strong>2000 партнерів</strong> по всій Європі. Ці партнери надають нам дані про транспортні засоби з усього світу.',
'652553309':'Чи знали ви?',
'1453750886':'Готово.',
'1498262202':'доступний',
'1514779988':'Огляд автомобіля спеціалістом-механіком',
'1058658544':'Ваучери на знижку Cebia',
'-2035347536':'0 записів',
'1050102897':'Детальна специфікація обладнання, надана виробником автомобіля, включає все базову та додаткову комплектацію. Перевірка може виявити модифікації автомобіля, наприклад зміну розташування керма з правої сторони на ліву',
'2145745364':'Записи одометра',
'-1636264801':'опис',
'-1488190529':'Номер запису',
'64594583':'Профінансований автомобіль',
'-1441353378':'Фінансування відсутнє',
'-2101084179':'Січень',
'-1066567335':'Лютий',
'-1761670105':'Березень',
'104663035':'Квітень',
'1397259452':'Травень',
'-642974117':'Червень',
'613657227':'Липень',
'-2044487450':'Серпень',
'1921417285':'Вересень',
'-1672709282':'Жовтень',
'-678062097':'Листопад',
'1302554433':'Грудень',
'1268839568':'У базах даних служби не знайдено записів. Попросіть виписку з журналу обслуговування (бажано з електронного журналу обслуговування) автомобіля.',
'-112660439':'Zde si vytvoříte Osvědčení <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, které můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále – <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Використовувався як таксі',
'-1350474522':'Наші бази даних не містять записів пробігу автомобіля.',
'1040913417':'Ми рекомендуємо вам перевірити інші можливі джерела записів одометра, включаючи сервісну книжку автомобіля. Також рекомендуємо запитати історію обслуговування в авторизованій майстерні. Тоді, оглядаючи автомобіль, зверніть увагу на знос, особливо на детал',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'Електронна пошта',
'-982011601':'США',
'786789662':'Купуйте пакети зі знижкою',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Хочете придбати автомобіль для своєї компанії або переглянути інші транспортні засоби? Скористайтеся нашими',
'845754511':'пакети зі знижками!',
'-614796774':'Чехія',
'1011350398':'Понеділок - п\'ятниця: 8:00-16:30 ',
'-68730396':'на сьогодні:',
'544810671':'НІ - не зареєстрований',
'-1670731287':'Зареєстрований в Чехії як:',
'1597705322':'Стандартна комплектація',
'16575694':'транспортний засіб:',
'-846486931':'записи',
'-1615816320':'запис',
'1415114012':'записи',
'1802294807':'фото',
'1122888753':'фото',
'53347610':'<a rel="noreferrer noopener" target="_blank" classname="Link" href="https://www.cebia.cz/pruvodce?id=158&do=downloadFile">особисті дані</a>',
'1802705032':'<a classname="Link Link--lightBlue" href="/detailArticle/de-ya-mozu-znajti-vin-kod" target="_blank" rel="noreferrer noopener">Більше інформації</a>',
'883512613':'Історія автомобіля Cebia - Онлайн перевірка автомобіля',
'-1965309318':'Перевірте історію транспортного засобу онлайн в найбільшій базі даних записів про вживані транспортні засоби в Європі. Перевірка пошкоджень і пробігу, історія обслуговування, фотографії та багато іншого.',
'-1871315210':'Методи оплати',
'-1803227447':'Виберіть відповідний спосіб оплати.',
'-1249181167':'Новини - Історія автомобіля',
'-838385662':'У нашому блозі ви можете знайти важливу інформацію про перевірку та купівлю вживаних автомобілів, уникнення шахрайства та багато іншого.',
'1154868985':'FAQ - Історія автомобіля',
'908158246':'Ознайомтеся із запитаннями, які найчастіше задають наші клієнти про історію автомобіля Cebia. Якщо у вас є ще запитання, зв’яжіться з нами.',
'-633966577':'Більше про Історію автомобіля',
'1171158673':'Дізнайтеся більше про Cebia Car History, її базу даних і чому її використовують для перевірки історії транспортних засобів мільйони клієнтів по всій Європі.',
'-1244282008':'Політика файлів cookie',
'-792206035':'Цей сайт використовує різні типи файлів cookie. Деякі файли cookie розміщуються сторонніми службами. Ви можете будь-коли відкликати свою згоду на їх використання.',
'1489309005':'Вільний доступ – в межах, визначених договором',
'-470675791':'Я перевірив і підтверджую інформацію вище',
'2108896817':'СКАСУВАТИ ЦЮ ЗНИЖКУ',
'6892736':'Код знижки більше не діє',
'-1199844274':'Код недійсний!',
'-552424253':'Вхід до платного звіту оплачується згідно договору',
'167769730':'Оплата готівкою',
'1768026018':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
'820786512':'Код прийнято, ціну перераховано',
'-279227794':'Перевірка року випуску',
'1470281039':'Перевірка лізингу/кредиту в Чехії',
'60776637':'Ідентифікатор звіту',
'529459564':'Введіть свій ідентифікатор звіту',
'-861194224':'Огляд автомобіля',
'2076412240':'новий',
'360601996':'Ваш ідентифікатор звіту більше не дійсний.',
'1762078183':'Матеріал',
'822990672':'Натиснувши цю кнопку, ви використовуєте 1 свій безкоштовний запит відповідно до ліміту безкоштовних запитів, зазначеного у вашому договорі',
'532528312':'вживаний',
'-319106355':'Транспортний засіб',
'935795728':'Приклад звіту',
'-916000765':'Звіт про історію автомобіля',
'-1721948126':'Зразок звіту про історію автомобіля - перевірте історію транспортного засобу онлайн у найбільшій базі даних вживаних авто в Європі',
'329882651':'При натисканні цієї кнопки з вас буде стягнено плату за відображення детальної інформації згідно з чинним договором.',
'-494157101':'Рахунок',
'-465994559':'Фотографії',
'857656094':'Виробничий код:',
'-2005319583':'Модель транспортного засобу:',
'339937023':'Рік першої реєстрації відповідно до свідоцтва про реєстрацію:',
'-441771827':'Завантаження даних',
'-161761801':'даних не знайдено',
'1229398238':'ПОПЕРЕДЖЕННЯ: СЕРТИФІКАТ Cebia не можна створити. Транспортний засіб зареєстрований як викрадений',
'358386818':'Вік автомобіля:',
'-701699977':'Не вдалося створити ЗВІТ Cebia - номер VIN не вдалося однозначно ідентифікувати. Звірте VIN з документами на автомобіль або зверніться до служби підтримки',
'-1359814132':'наступне фото',
'340541220':'Збільшити',
'2096545526':'Зменшити',
'-158382161':'Попереднє зображення',
'-749344895':'Знижка',
'-1755324145':'Модель транспортного засобу',
'1747292041':'Smart code',
'1093009871':'Розумна комунікація для сучасних автоцентрів',
'-421925379':'Хочете розповісти про те, що ваші транспортні засоби перевірені?',
'-303554559':'Встановіть Smart-код на кожен автомобіль, і клієнт сам отримає результат перевірки.',
'5128661':'Дуже просто. Ви роздруковуєте Cebia Smart Code (формат А5) і розміщуєте його за лобовим склом автомобіля, який продаєте.',
'1608809132':'Потім клієнт може використовувати свій мобільний телефон, щоб отримати основну інформацію про автомобіль за допомогою OR-коду та переглянути графік пробігу.',
'-703122978':'Інша інформація (повний звіт) може бути запитана безпосередньо у вас.',
'-566386963':'Будь ласка, заповніть цю форму, і ми зв\'яжемося з вами.',
'-1639125181':'Надіслати',
'76421921':'Обов\'язково',
'-2097738269':'Після зчитування QR-коду клієнт побачить на своєму мобільному телефоні «базовий дамп історії транспортного засобу»',
'-80023088':'Хочете надрукувати смарт-коди для своїх автомобілів? Зверніться до нашого відділу продажів:',
'2094660384':'Петро Кала, керівник відділу продажів',
'361077673':'Або заповніть форму, і ми зв\'яжемося з вами:',
'1277961418':'Ви дійсно хочете згенерувати Smart Code?',
'-2133968142':'У мене є код знижки',
'-1922950659':'Спосіб оплати',
'-2072875261':'Транспортний засіб який перевіряється',
'783887791':'замо́влення',
'-1499237034':'Безплатно',
'-1854208328':'Застосувати промокод на знижку',
'-793343279':'Подарунок',
'-1393236672':'КОСОВО',
'1342791550':'Історія пробігу',
'2063498881':' Додаткове обладнання',
'-152116623':'QR - розумний код',
'-956949153':'Вартість ремонту',
'-552950132':'інформація',
'30639535':'інформація',
'-1164317619':'ні',
'1402944789':'Будь ласка, введіть необхідну інформацію',
'-1812102598':'ПОПЕРЕДЖЕННЯ: Не вдалося перевірити одометр. Категорія матиме результат «відсутність даних».',
'666398003':'Увага!',
'771644825':'Введений VIN може бути неправильним!',
'-1131044763':'Більше інформації?',
'-2054470344':' Введений VIN може бути неправильним!',
'-1369269346':'У введеному VIN виявлено можливу помилку. Перевірте, чи збігається введений VIN з номером VIN, вибитим на транспортному засобі або в свідоцтві про реєстрацію',
'-1625517200':'Де знайти VIN?',
'1171142400':'Платіж не вдався. Будь ласка, спробуйте ще раз.',
'-1192484737':'Ви не можете продовжити, не погодившись із Правилами та Умовами ',
'-247455599':'Будь ласка, введіть ідентифікатор вашої компанії.',
'-1944712721':'Мінімальна кількість символів - 12',
'1664270996':'Держава',
'-862797995':'Стан авто під час публікації оголошення. Перегляньте, будь ласка, прикріплене фото',
'-1997376096':'Словаччина',
'-1344951568':'Реєстр транспортних засобів',
'-644036535':'Бази даних',
'-426412387':'Національний реєстр транспортних засобів',
'1736003883':'Дані технічного сертифікату',
'1610817679':'Для автомобіля доступні записи одометра. У звіті про історію автомобіля ми розкриємо доступні показання одометра за вказаний вище період часу. Записи можна використовувати, щоб визначити, чи точний пробіг, чи одометр автомобіля підроблено.',
'1669009738':'Запитуваний транспортний засіб було рекламовано або задокументовано. У звіті про історію автомобіля ви можете переглянути деталі реклами, показання одометра, фотографії автомобіля та, якщо є, рекламовану ціну. Зверніть увагу, що кількість інформації може відрізнятися від одного запису оголошення до іншого.',
'1518701163':'Ми знаємо вік автомобіля та/або його походження. У звіті про історію автомобіля ви можете перевірити дату виробництва транспортного засобу, дату першої реєстрації, дату реєстрації автомобіля в Чехії та інформацію про країну, для якої автомобіль був спочатку виготовлений. Будь ласка, зверніть увагу, що обсяг інформації у звіті про історію автомобіля може відрізнятися від автомобіля до автомобіля.',
'1816609203':'Коли йдеться про транскордонні продажі автомобілів, рік випуску часто підтасовують!',
'-900812009':'Викрадений автомобіль може бути конфіскований поліцією без будь-якої компенсації!',
'-976941031':'Перевірка таксі недоступна для цього автомобіля.',
'444450829':'до',
'982955181':'12 реєстрів',
'-1739651968':'Перевірка безпеки транспортного засобу',
'-167461646':'Перевірка історії автомобіля',
'-1365221853':'Максимальна кількість символів - 14.',
'1302766369':'Оцінка транспортного засобу не проводилась. Оцінку можна зробити протягом терміну дії звіту, ввівши дані про транспортний засіб (дату першої реєстрації та поточні показники одометра).',
'1881868699':'Закупівля для бізнесу',
'631216697':'Будь ласка, напишіть свою електронну адресу.',
'945011940':'Ваші дані',
'-401861769':'Контактний e-mail',
'1441058305':'Якщо ви заповните номер ПДВ, ми спробуємо заповнити решту даних з офіційної бази даних.',
'-1181757195':'Після оплати на вказану електронну адресу ми надішлемо вам посилання для перегляду інформації про історію автомобіля та спрощений податковий документ.',
'-1768425519':'Якщо ви бажаєте, щоб ваші платіжні дані (компанія, адреса, номер платника ПДВ) були заповнені в спрощеному податковому документі, введіть їх у поля нижче. Якщо ви заповните номер ПДВ, ми спробуємо заповнити решту даних з офіційної бази даних.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Сертифікат Cebia надає оцінку перевірці одометра, перевірці бази даних викрадених транспортних засобів, зобов\'язань лізингової компанії, перевірці VIN-коду та року випуску. Загальний рейтинг виражається кількістю зірок (0-5) та словесною оцінкою.',
'308350422':'Усього:',
'-24797068':'Ви маєте право на знижку, якщо:',
'-1323263000':'Якщо у вас є передплачена послуга FLEX, скористайтеся кодом, вказаним у податковій квитанції.',
'-724069837':'У вас є код знижки, який дає право на безкоштовну покупку.',
'-127346860':'У вас є код на знижку',
'-47884841':'без ПДВ',
'881014685':'Разом без ПДВ',
'-15294371':'Непідтверджений номер ПДВ',
'1286068311':'Офіційна база даних платників ПДВ не підтвердила Ваш номер ПДВ. Тому ми не можемо автоматично заповнити ваші дані. Будь ласка, перевірте свій номер ПДВ або заповніть інформацію про свою компанію вручну.',
'-172351568':'База даних не відповідає',
'1839952435':'Офіційна база платників ПДВ не відповідає. Будь ласка, заповніть дані вашої компанії вручну.',
'1678298800':'Зразок звіту',
'-654354054':'Обсяг доступної інформації варіюється від автомобіля до автомобіля',
'-1069736045':'Європейські <label classname="color-orange">веб-сайти вживаних автомобілів</label>',
'1068440731':'до <label classname="color-orange">12 регістрів</label>',
'-534710218':'Походження та вік транспортного засобу',
'1267127794':'Усі перевірки, зазначені на цій сторінці, можна провести для вказаного транспортного засобу на основі VIN-коду. Результати перевірок будуть доступні в платному звіті. Обсяг інформації, що міститься в оплаченому звіті, може відрізнятися для кожного автомобіля.',
'-186613120':'У Чехії транспорт не використовувався як таксі',
'1880784559':'Доступно при введенні реєстраційного номеру транспортного засобу',
'2085931506':'<label classname="color-orange">доступний</label>',
'-1765351165':'понад <label classname="color-orange">200 мільйонів записів</label> ',
'1815202337':'дані з <label classname="color-orange">ЄС та США</label>',
'-555727222':'дані з <label classname="color-orange">18 країн</label>',
'-1798142062':'Графік записів одометра',
'-1828887347':'VIN (ідентифікаційний номер транспортного засобу):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Щоб перевірити, чи використовувався автомобіль як таксі в Словаччині, введіть його реєстраційний номер на <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Перевірити VIN зараз',
'-1620583517':'Історія',
'239060530':'Історія перевірки VIN-коду',
'1122350112':'Огляд історії всіх запитів за VIN-кодом, що перевіряється, починаючи з дати активації звіту.',
'-1288575114':'Не перевірено',
'-14589513':'Клієнт',
'-1170545783':'Підтверджено вами',
'452068758':'Підрядний партнер Cebia',
'-1710315139':'Звіт про історію автомобіля містить знайдені записи у базах даних. Найчастіше це інформація про стан одометра (пробіг), факти ДТП чи пошкодження транспортного засобу, дати та деталі технічних оглядів і візитів на СТО, фотографії машини і навіть минулі оголошення продажу. Більш детальну інформацію ви можете знайти в <a href="/payed/detail/exampleUA" target="_blank">зразку звіту</a>. ',
'1607430837':'Огляд історії',
'-293598683':'Дата зняття з реєстрації',
'-1790772829':'Дата першої реєстрації транспортного засобу',
'743843679':'Дата реєстрації транспортного засобу',
'1370884754':'Зареєстровано як',
'644449918':'Колір транспортного засобу при реєстрації',
'-583571734':'Дата виготовлення:',
'-1219924366':'Вік транспортного засобу',
'1595420674':'Виготовляється разом з обладнанням',
'1151043140':'Час між датою виготовлення та датою 1-ї реєстрації',
'708774908':'Албанія',
'1637995084':'Північна Македонія',
'1001888760':'Тотальний економічний крах *',
'1034299871':'Вимога про відшкодування пошкодження - загальні економічні втрати*',
'1209738229':'Вимога про відшкодування пошкодження',
'913822268':'Примітка',
'584620341':'',
'927312018':'Вул. Сирецька, буд. 38',
'-1213415008':'04073 м. Київ',
'1766887079':'42340177',
'963828375':'',
'-163538958':'ТОВ "Дата Авто"',
'-1996707559':'Здійснюючи покупку, я погоджуюсь із <a href="https://www.cebia.cz/pruvodce?id=157&amp;do=downloadFile " target="_blank" class="Link Link--lightBlue">загальними положеннями та умовами</a>, а також погоджуюся з <a href="https://www.cebia.cz/pruvodce?id=158&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">Політикою конфіденційності та захисту персональних даних</a>.',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Дата 1-ї реєстрації відповідно до заявки на реєстрацію',
'280331577':'Час між датою виготовлення та датою 1-ї реєстрації',
'1163480388':'У доступних базах даних немає записів.',
'-1319845523':'Виявлено розрахунок пошкодження, створений страховою компанією або автосервісом, деталі якого (місця пошкодження) ми не знаємо. Зверніть на це увагу при огляді транспортного засобу.',
'779148402':'Страхова компанія повідомила про ДТП, подробиць якої (місця пошкодження) ми не знаємо. Зверніть на це увагу при огляді транспортного засобу.',
'1115915387':'Безкоштовна доставка',
'-1556562078':'Знижка на перевірку по всій Чехії від Automato.cz',
'-556554618':'Не перевірено',
'-1464599260':'Робота',
'-592361226':'Живопис',
'1085310093':'Тип контролю:',
'1984850101':'Дата перевірки',
'-1502399543':'Переглянути протокол (чеською мовою)',
'-1961590845':'База даних Міністерства транспорту наразі недоступна, будь ласка, повторіть спробу пізніше.',
'-1065211891':'Протокол не доступний',
'1708745339':'Протокол не доступний',
'1087254174':'Пройдіть повну перевірку',
'2096754301':'Базова перевірка автомобіля на сьогоднішній день:',
'926095479':'Що таке базова перевірка автомобіля?',
'-254140743':'Базовий скринінг включає наступні перевірки:',
'1362304971':'Для повної перевірки історії транспортного засобу ми рекомендуємо придбати Повну перевірку транспортного засобу, яка також може включати <b>перевірку пошкоджень, історію оголошень, фотографії транспортного засобу, історію обслуговування</b> та багато іншого.',
'-267677252':'Базовий огляд автомобіля',
'-70564196':'- 10 ЄВРО',
'1350105297':'Ви задоволені результатом перевірки історії автомобіля? Будемо раді отримати Ваш відгук.',
'1914051118':'Відгуки',
'1786612140':'Нам шкода, що перевірка історії транспортного засобу не виправдала ваших очікувань. Будь ласка, повідомте нам, що ми повинні покращити наступного разу.',
'-1265184280':'Не вдалося завантажити. Спробуйте ще раз.',
'-669850784':'Максимальна кількість символів - 2000.',
'1801814710':'<strong classname="color-green">Дякую тобі!</strong> Ваш відгук надіслано, і він допоможе нам покращити наші послуги.',
'-883106932':'дані з більш ніж <label classname="color-orange">32 країн</label>',
'1408314893':'Cebia збирає записи про транспортні засоби з понад 32 країн. Записи найчастіше надходять з офіційних реєстрів, автосалонів, поліцейських баз даних, баз даних пошкоджених автомобілів, страхових компаній або порталів оголошень.',
'-1412306493':'Так. Кожен транспортний засіб перевіряється за базами даних із більш ніж 32 країн (включно з Німеччиною, Данією та США). Після введення VIN ви можете побачити, які записи були знайдені в нашій базі даних і як датовані ці записи. Ми надаємо всю цю інформацію в нашому безкоштовному зведені доступної інформації.',
'1798507708':'У цьому випадку ми не знайшли записів про пошкодження транспортного засобу, що перевіряється, у наших базах даних, що містять понад 200 мільйонів записів про пошкодження з 32 європейських країн і США. Цей факт суттєво підвищує ймовірність того, що автомобіль, який перевіряється, раніше не був у ДТП.',
'568601301':'Записи про транспортні засоби з понад <strong>32 країн</strong>',
'899127599':'32 країн',
'-1456368295':'Історія автомобіля з 32+ країн',
'1333238000':'30 000 000+ перевірених машини',
'426416063':'Дані з 32 країн',
'-1549476292':'Наша система перевіряє кожен введений VIN номер із базою даних пошкоджених автомобілів? Наша база даних містить записи про понад 200 мільйонів пошкоджених автомобілів з 32 країн. Більшість цих записів (62%) походять із Франції та Німеччини.',
'-924882043':'Один або кілька записів про пошкодження транспортного засобу були знайдені в наших базах даних пошкоджених транспортних засобів, які збирають дані з понад 32 країн і приблизно 200 мільйонів записів про пошкодження.',
'-1366090835':'У базах даних 200 мільйонів позовів про відшкодування шкоди з понад 32 країн не було знайдено жодних записів про пошкодження автомобіля, який розслідується.',
'931273814':'Дані історії автомобіля з 32+ країн',
'-334327155':'Ми перевіримо транспортний засіб за базами даних пошкоджених транспортних засобів із понад 32 європейських країн і Сполучених Штатів, що містять понад 200 мільйонів записів про пошкодження. У звіті про історію автомобіля ви дізнаєтесь, чи зареєстровано транспортний засіб як пошкоджений у минулому. Також може бути включена детальна інформація, наприклад дата пошкодження, стан одометра, ступінь пошкодження, вартість ремонту або фотографії пошкоджень.',
'2078676994':'Візити в автосервіс',
'503491296':'OK',
'1704037376':'Скасувати',
'-922638497':'Перевірено',
'-2077712313':'до 12 регістрів',
'-855779034':'Перевірка вже проведена',
'131879595':'Перевірка автомобіля <strong classname="ml-5 mr-5">за окрему плату</strong> згідно договору',
'-2034839108':'PDF',
'2049708688':'Записів немає',
'1916268628':'Нідерланди',
'-1425396841':'Про нас',
'-1521903902':'Ідентифікатор протоколу',
'1485582239':'Неправильний формат електронної пошти.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'км',
'1296400336':'O platbě',
'1275361495':'Ви отримали код знижки на безкоштовний техогляд автомобіля. Ви можете скористатися знижкою, ввівши свій ідентифікатор звіту в полі коду знижки під час наступної покупки.',
'-675647872':'У всіх вищезазначених випадках ви вводите ідентифікатор звіту у відповідне поле на <a href="https://ua.cebia.com/">головній сторінці</a> та отримуєте негайний доступ до придбаної інформації, до якої можете повернутися в будь-який час протягом 30 днів.',
'1080548025':'ПОПЕРЕДЖЕННЯ: це може бути <strong>неправильний VIN</strong>. Перевірте VIN або <a href="mailto:rokvy@cebia.cz?subject=Інформаційна підтримка системи-ATR ідентифікація&amp;body=VIN: {0}">Зверніться до служби підтримки</a>',
'-439637411':'ПОПЕРЕДЖЕННЯ: Неможливо створити сертифікат Cebia. Не знайдено рік виробництва.',
'-43065820':'Записів про вік або походження транспортного засобу не знайдено. Якщо автомобіль надходить з-за кордону, завжди вимагайте та ретельно перевіряйте оригінальну документацію на транспортний засіб (наприклад, сервісну книжку або свідоцтво про реєстрацію транспортного засобу).',
'-1362534546':'Якщо транспортний засіб рекламувався або перевірявся в минулому, ми покажемо інформацію та фотодокументацію.',
'431007496':'Запис',
'-1525511285':'Реєстраційні дані транспортного засобу отримані від національних органів і відображають статус, коли транспортний засіб було вперше зареєстровано в країні.',
'1151204976':'VIN (ідентифікаційний номер автомобіля) — це унікальний 17-значний код, який однозначно та чітко ідентифікує кожен автомобіль. Він штампується безпосередньо на кузові транспортного засобу і всі записи про авто в базах даних зберігаються під цим номером.',
'205732919':'VIN – це 17-значний код, який однозначно та однозначно ідентифікує кожен автомобіль.',
'1905716471':'*) Визначення повної економічної шкоди може відрізнятися від однієї країни ЄС до іншої, а також від однієї страхової компанії до іншої в межах однієї країни. Навіть якщо дане пошкодження оцінюється як повне, це не обов’язково означає, що транспортний засіб непридатний для експлуатації або навіть не підлягає ремонту. Повний економічний збиток — це пошкодження транспортного засобу, якщо, згідно з умовами страхової компанії, ремонт транспортного засобу є невигідним (=сума ремонту транспортного засобу близька або більша за вартість транспортного засобу на момент збиток) або оцінюється як такий згідно з умовами страхового полісу.',
'-199845800':'Номер повинен містити рівно 10 символів.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'<a href="/detailArticle/vin-identifikacijnij-nomer-transportnogo-zasobu" target="_blank">VIN</a> (<a href="/detailArticle/vin-identifikacijnij-nomer-transportnogo-zasobu" target="_blank">ідентифікаційний номер автомобіля</a>) – це унікальний 17-значний код, який однозначно та чітко ідентифікує кожен автомобіль. Він штампується безпосередньо на кузові транспортного засобу, і всі записи транспортного засобу зберігаються під цим номером.',
'1682180910':'<p>В доступних базах даних ми не маємо записів про пошкодження перевірюваного транспортного засобу. Введений вами VIN може бути некоректним.</p><p>Попередження: Система Car History може не мати доступу до інформації про всі пошкодження транспортного засобу, деякі пошкодження не повідомляються страховим компаніям або недоступні під час онлайн перевірки.</p>',
'-1276466490':'Вказаний VIN може бути некоректним',
'-1892907546':'Неможливо знайти історичні записи для цього VIN.',
'1843204209':'Також перевірте',
'-716345220':'Перевірте інший VIN',
'1091884080':'Ми рекомендуємо перевірити VIN',
'-287497170':'Перевірка застав',
'1289833880':'Заставу знайдено',
'466639297':'Заставу знайдено',
'-577812113':'ID застави',
'231435384':'Перевірка заставних прав наразі недоступна. Спробуйте оновити сторінку через кілька хвилин.',
'-941220732':'Транспортний засіб є предметом одного або кількох заставних прав в зазначених державах, і існує можливість його конфіскації у разі можливого виконавчого провадження особи, зазначеної в реєстрі застав. Придбання транспортного засобу з заставним правом може бути дуже ризикованим. Дані ми отримуємо з офіційних реєстрів застав даної країни.',
'2016489332':'Результатом запиту є встановлення того, чи транспортний засіб не є об\'єктом заставного права в згаданих державах і, отже, немає ризику його конфіскації у випадку можливого виконавчого провадження проти поточного власника. Дані ми отримуємо з офіційних реєстрів застав даної країни.',
'1736313716':'Přílohy k článku',
'1021804797':'Безкоштовний звіт про історію автомобіля в межах, визначених договором',
'749816957':'Можна перевірити',
'981194702':'Kontrola exekucí',
'-1417536272':'Перевірка права застави',
'-323399576':'На звіт можна поскаржитися. Якщо ви підозрюєте, що до Звіту включено невірну інформацію, зверніться до нашого відділу обслуговування клієнтів за адресою <a href="mailto: info.ua@cebia.com?subject=Запит&amp;cc=support@dataauto.org">info.ua@cebia.com</a>.',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Číslo smlouvy (je-li k dispozici)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Працював як таксі в році',
'-137639801':'Ідентифікатор звіту (Претензії та скарги)',
'-1619027976':'Примітка. Можливо, транспортний засіб не завжди використовувався як таксі.',
'-1010997259':'Для бізнесу',
'503941074':'Детальна інформація та дані про перевірене авто будуть доступні після оплати послуги.',
'1088246106':'Звіт про історію автомобіля перевірить:',
'-963915529':'Приховані збитки',
'-1603961223':'<label classname="color-orange">доступний</label>',
'-1940014640':'Важлива інформація про автомобіль',
'1785662376':'Služby',
'1913348655':'Бажаєте перевірити більше автомобілів за більш низьку ціну? Отримайте доступ для підприємців і <b>зекономте до 50%</b> від вартості перевірки. Будь ласка, надайте нам свої контактні дані, і ми зв\'яжемося з вами з індивідуальною пропозицією.',
'1723999193':'Доступно після оплати',
'1593405846':'Pojištění vozidla',
'670508858':'Для однієї покупки можна використати лише один код знижки. Коди знижок не можна додавати або комбінувати. Знижка поширюється лише на Звіт про історію автомобіля.',
'1693716169':'Спосіб оплати',
'-503153747':'Звіт про історію автомобіля',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'ЗНИЖКА 35 % на наступну верифікацію',
'-1870036897':'Ви вже проходили техогляд і, ввівши номер звіту (вказаний в отриманому електронному листі) протягом 90 днів з моменту покупки, ви отримаєте знижку на наступний техогляд.',
'163946327':'З кожною покупкою Car History Report ви отримаєте знижку 35 % на наступну покупку Car History Report. Під час наступної покупки ви можете застосувати знижку, ввівши свій попередній ідентифікатор звіту про історію автомобіля в текстове поле з написом «Купон на знижку», розташоване в розділі «Оплата».',
'858336732':'Знижка 50% на наступну верифікацію',
'214251926':'Купуючи перевірку, ви автоматично отримуєте знижку на наступну перевірку будь-якого іншого транспортного засобу. Знижка діє протягом 30 днів з моменту покупки.',
'1317784962':'Знижка 10% на наступну верифікацію',
'641443144':'Знижка 35% на наступну верифікацію',
'-1318469146':'Знижка 30% на наступну верифікацію',
'1939643950':'Знижка 40% на наступну верифікацію',
'-1376459678':'Ще один чек <br> <b>зі знижкою!</b>',
'-937620931':'Дані транспортного засобу',
'514768619':'Перевіряємо справжність VIN',
'-916328171':'Перевіряємо ідентичність транспортного засобу',
'1481465091':'Перевіряємо обладнання автомобіля',
'-276782700':'Шукаємо в базах даних пошкоджених автомобілів',
'1544438189':'Перевіряємо записи страхових компаній',
'2021669548':'Шукаємо в історії аукціонних порталів',
'2022162632':'Перевіряємо записи з авторизованих сервісних центрів',
'1083617557':'Перевіряємо записи з несанкціоновані сервісних центрів',
'993080480':'Шукаємо викрадені автомобілі в поліцейських базах даних',
'-1240510013':'Шукаємо викрадені автомобілі в приватних базах даних',
'-1027936662':'Перевіряємо вік автомобіля',
'1299172359':'Перевіряємо походження транспортного засобу',
'413685839':'Шукаємо електронні сервісні книжки',
'-473666121':'Пошук у державних реєстрах транспортних засобів',
'-254920359':'Перевіряємо державні бази даних автомобілів таксі',
'-811021673':'Перевірка європейського згадати події',
'1923526539':'Перевіряємо безпеку автомобіля',
'-1424413327':'Перевіряємо бази даних пробігу',
'734665361':'Перевіряємо записи лізингових компаній',
'684288091':'Шукаємо записи станцій технічного огляду',
'-1633964825':'Ми шукаємо записи зі станцій вимірювання викидів',
'-354982402':'Шукаємо в історії рекламних порталів',
'255365524':'Шукаємо в база даних дилерів нових автомобілів',
'936372740':'Перевіряємо бази даних дилерів вживаних автомобілів',
'-1491634158':'Перевіряємо записи про орендовані авто',
'-1425645764':'Операція може тривати кілька хвилин',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Створюємо звіт',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Поідлитися звітом',
'-2142411619':'Щоб поділитися звітом про історію автомобіля, скопіюйте посилання нижче та надішліть його тим, з ким ви хочете поділитися звітом.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Ми рекомендуємо',
'-1025377658':'пункт',
'-909166524':'пункти',
'-1998523642':'пунктів',
'-613016004':'Пропозиції зі знижкою',
'1083497926':'Інші послуги',
'2019112977':'Знижка 35% на наступну верифікацію',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Наступний звіт',
'-901212011':'за',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Перевіряємо фотографії, про виконання буде повідомлено на електронну пошту.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Попередження:</span> Інформація про всі пошкодження транспортних засобів може бути недоступною в системі, деякі збитки не повідомляються страховим компаніям або недоступні в рамках онлайн-перевірки. Тому ми рекомендуємо ретельно перевірити автомобіль, в ідеалі за допомогою кваліфікованого механіка або в сервісному центрі за вашим вибором. Така перевірка може виявити додаткові пошкодження або виключити їх.',
'-1943079155':'<span class="warning">Примітка:</span> Якщо фотографії не відповідають транспортному засобу, який перевіряється, повідомте нас електронною поштою <a href="mailto:support@dataauto.org">support@dataauto.org</a>.',
'707486310':'<span class="warning">Примітка:</span> У системі можуть бути доступні не всі записи про обслуговування автомобіля. Рекомендуємо звірити записи із сервісною книжкою або звернутися до відповідного авторизованого сервісу бренду із запитом на виписку з цифрової сервісної книжки автомобіля.',
'-1997337578':'Огляд еволюції одометра автомобіля містить значення, отримані з доступних інформаційних систем партнерів по співпраці. За розвитком графіка ви можете самі судити, є підозра на маніпуляції зі станом одометра чи ні.',
'-282194273':'Рекомендація',
'-1564285314':'Не всі записи стану одометра з історії експлуатації автомобіля можуть бути доступні в системі.',
'993789697':'Якщо транспортний засіб не записує всю історію пробігу з часом, ми <b>рекомендуємо</b> також перевірити записи одометра в сервісній книжці або віддати автомобіль на перевірку в авторизований сервісний центр. Ми рекомендуємо цю процедуру особливо для транспортних засобів, у яких проміжок часу між показаннями одометра перевищує два роки.',
'2129711518':'Це доступна інформація про основні параметри, стандартне і, можливо, додаткове обладнання автомобіля з заводу. Модифікації автомобіля, такі як зміна сторони керма, можна виявити за допомогою перевірки обладнання.',
'-593531916':'<span class="warning">Попередження:</span> У деяких випадках повне обладнання автомобіля може бути недоступним у системі.',
};

const TRANSLATIONS_AL = {
'1901555939':'VIN',
'-971921755':'Prodhuesi i veturës',
'1891129765':'Modeli',
'691501523':'Lloji',
'-1026019663':'Lloji i veturës',
'274952045':'Karburanti',
'-348535418':'Fuqia',
'1000727131':'Kapaciteti i motorit',
'-440181428':'Data e prodhimit',
'841597003':'Shpërndaje',
'-762872471':'Shiko',
'-1615835216':'Linku',
'-466013687':'Mbyll',
'-1655267775':'Kopjo linkun',
'-1448849529':'A mund ta shikoj historinë e makinës edhe nëse nuk e posedoj numrin e shasisë se automjetit (VIN)?',
'-1310016716':'Kontrolli i automjetit',
'1410745983':'online',
'-1803530129':'Gjenero raportin',
'934563996':'Rezultatet e raportit',
'-1102433484':'Raport shembull',
'-1242381959':'Historia e veturës',
'417677035':'Kontrollo nëse vetura është pjesë e ndonjë hipoteke/kredie',
'-115937358':'Statusi i veturës',
'457834942':'Pyetje të shpeshta',
'226942208':'Çka është VIN?',
'1739046820':'Sa kushton raporti i historisë së veturës?',
'16204687':'Si mund të paguaj?',
'-1607701055':'Si të kemi qasje në raportin e historisë së veturës?',
'-1969713941':'Si mund të bëj ankesë rreth historisë së raportit?',
'1598751038':'Ku mund ta gjej ID-në e raportit?',
'-1206763576':'Më shumë informacione',
'-729900752':'Pagesa',
'671435147':'Shkruaj kodin e kuponit të zbritjes',
'-2004947080':'Kodi i zbritjes',
'-1083354004':'RILLOGARIT',
'-1589008349':'Emri',
'-704955254':'Mbiemri',
'-1631818401':'Adresë',
'1006101298':'Qyteti',
'1063186093':'Shteti',
'287700219':'Kodi postar',
'-1411201334':'ID e taksave',
'513366821':'ID e TVSH-së',
'-102071178':'Emri i kompanisë',
'-1882309597':'Numri i biznesit',
'-1515081676':'Numri i biznesit',
'1440993804':'Email kontaktues',
'-1405758046':'Numri i telefonit',
'1366947192':'Numri juaj kontaktues',
'-490851561':'Përfito 100 CZK zbritje në raportin tjetër',
'-108743580':'€',
'786987358':'Blej',
'-1887658751':'Raporti i historisë së veturës është gati',
'-1018049108':'Rezultatet janë të gatshme brenda 60 minutave',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Transfer bankar',
'-1663277':'Rezultatet do të jenë gati brenda 48 orëve',
'1626540224':'Paguaj me para në dorë',
'72162446':'Kontrollo historinë e veturës',
'757687806':'Lista e informacioneve të disponueshme falas',
'1954205527':'Ku mund ta gjej VIN?',
'1603459473':'Shkruaj ID-në e raportit',
'2038366987':'Shiko rezultatin',
'-374549211':'Ku mund ta gjej ID-në e raportit?',
'1262814082':'Shkruaj VIN',
'-15600132':'Data e regjistrimit të parë',
'1535680605':'ID e raportit:',
'-1091217450':'ID e raportit vlen deri më:',
'-1264594150':'Data e blerjes së raportit:',
'-701611594':'Na vjen keq, kjo veturë nuk mund të kontrollohet.',
'160166931':'Pronari aktual nuk dëshiron që të shfaqen të dhënat e makinës.',
'-351255422':'Reklama qe po shihni nuk shfaq numrin VIN të automjetit.',
'1704462566':'Numri i plotë VIN nuk shfaqet në reklamë të makinës.',
'366367808':'Sa zgjat përpunimi i raportit historik të veturës?',
'2136729030':'VIN:',
'400669011':'Zbritja aplikohet në rast se:',
'-219050562':'Blog',
'1449193326':'Për ne',
'1097675154':'Kontakti',
'2062322678':'Më të rejat',
'-1263831198':'Historiku i kompanisë',
'1166836894':'Partnerët tanë',
'-1532347183':'Për media',
'717698781':'Vende të lira pune',
'1620687461':'Automjeti figuron si i vjedhur. Çertifikata nuk mund të krijohet.',
'1365729306':'Numri i çertifikatës:',
'-89669431':'Viti i regjistrimit të parë',
'2130038984':'Kilometrazhi (km)',
'2089870291':'Vetura kontrollohet nga aplikacioni Carolina',
'1333652846':'Shkarko pa pagesë',
'1347577444':'VIN duhet të përmbaj 17 karaktere',
'622759132':'Linku është kopjuar',
'1714373031':'Vetura që kontrollohet mund t\'i nënshtrohet:',
'1227286459':'Janë gjetur raste të tërheqjes nga tregu',
'-477335598':'Tërheqje nga tregu',
'-343698081':'Informatat në vijim nuk u gjendën gjatë kontrollimit të veturës',
'-1536285860':'Nuk janë gjetur raste të veturës të tërhequr nga tregu',
'-2052297753':'Data e shpalljes',
'1522685090':'Detaje',
'444715065':'Më shumë informata',
'-394230090':'Ju lusim të konfirmoni nëse informatat e shënuara janë të sakta.',
'1224828864':'Vlerë e pasaktë - data e regjistrimit të parë',
'-470093943':'Ju lusim të shënoni vitin e regjistrimit të parë',
'1035831822':'Formati i kilometrazhit nuk është i saktë',
'-1161922611':'Ju lusim të shënoni kilometrazhin',
'-1383225585':'Shënoni vitin e regjistrimit të parë',
'1111232857':'Viti i regjistrimit të automjetit të parë',
'-1998715395':'Shënoni statusin e distancës së kaluar (odometrit)',
'-1962337257':'Distanca e kaluar',
'-1888253249':'Të dhënat teknike rreth modelit',
'-339477539':'- 300 Kč',
'917746804':'Nuk mund të ishte më e thjeshtë:',
'1405508217':'Këshillimi i parë është falas',
'127414665':'Si mund të aplikohet zbritja?',
'-2081078087':'VYBRAT TECHNIKA',
'1899967331':'Numri maksimal i karaktereve është 20',
'1699452695':'Numri i kodit bankar nuk është i saktë',
'1812875983':'Shënoni numrin e llogarisë bankare',
'-670727524':'Ju lusim të shënoni ID-në e policës së sigurimit',
'1884544037':'ePojisteni.cz',
'138266606':'Vlen deri më:',
'1881823014':'Rreth rimburësimit:',
'-1548724023':'Automjeti duhet të jetë i siguruar së paku 30 ditë',
'1331666705':'Ruajtja dështoi, provoni përsëri.',
'-1393210532':'Numri i llogarisë',
'439576396':'Numri i llogarisë tuaj',
'773379637':'Numri i kontratës së sigurimit',
'-1281605763':'Uplatnit vrácení peněz',
'-1670752753':'Sleva na prověření v zahraničí',
'1496813111':'- 550',
'-856872708':'Çmimi bazë',
'616158592':'ZBRITJE',
'-581170701':'Çmimi pas zbritjes',
'-437309148':'duke përfshirë TVSH',
'-1731075532':'Video',
'-189892310':'Porosia',
'98918066':'Si të porosisim:',
'-1944610133':'Apliko zbritjen për raportin tjetër',
'-621660650':'Numri i kuponit të zbritjes:',
'-1845841489':'Apliko zbritjen për një automjet tjetër',
'1171588656':'Zbritja nga siguria e automjetit',
'1440663595':'- 850',
'-1503110723':'Sleva na fyzickou prověrku',
'-675817237':'- 925',
'986037818':'Aksidente të gjetura',
'873964955':'Data',
'790922325':'Kalkulimi i kostos së riparimit',
'-1825735064':'Lloji i sigurimit',
'486919475':'Vendet e regjistruara të dëmtimit',
'1685506455':'Vetura është në hipotekë apo kredi',
'2039551191':'Vetura nuk është pjesë e ndonjë hipoteke/kredie',
'-570695392':'bazuar në të dhënat që janë aktualisht në dispozicion',
'-765744228':'Reklamë',
'659651744':'Data e reklamimit',
'-695638522':'Kilometrazhi i reklamuar',
'772986188':'Çmimi i reklamuar',
'419477574':'Të dhënat rreth origjinës dhe vitit të prodhimit të automjetit',
'-109775782':'Data e prodhimit sipas prodhuesit',
'-218677523':'Regjistrimi i parë',
'-340115089':'Regjistrimi i parë në Republikën Çeke',
'1192563560':'Regjistrimi i parë në Sllovaki',
'1650211924':'Prodhuar për tregun',
'1812132824':'Ana e drejtimit të veturës',
'-1229728494':'Materiali (listë)',
'-1147664160':'Ndërhyrjet (listë)',
'708828581':'Përdoret si automjet taksi',
'284182756':'Automjeti është i raportuar si i vjedhur',
'1244175337':'Nuk është i raportuar si i vjedhur',
'791649880':'Burimi',
'705052471':'Në',
'1239149764':'Kontrolli i identifikuesve',
'-799257304':'Modeli:',
'-1964821919':'Data e regjistrimit të parë:',
'-1530361098':'Çmimi i veturës së re',
'68348546':'Çmimi aktual në treg',
'763547310':'Data e regjistrimit të parë',
'1968050624':'Ruaj',
'-1300982647':'Lista e të dhënave',
'397739850':'Kontrolli i ngjyrës së veturës',
'479427038':'Disponueshëm',
'2038192723':'Kontrolli i sigurisë së veturës',
'-757741002':'Përshkrimi teknik i automjetit',
'278755414':'Udhëzime për identifikim të veturës',
'1529692191':'Ngjyra e veturës',
'-1171491320':'Identifikimi i veturës',
'1549116932':'Lista e të dhënave të automjetit',
'-1985480381':'Të tjera',
'-1354462940':'Kontrollo:',
'-690036295':'Data e regjistrimit:',
'1506895356':'Siguria e veturës:',
'-749715579':'Kodi shënjues:',
'-329154043':'Ngjyra e veturës:',
'1441236976':'Të dhëna në lidhje me shënjuesin në xham',
'729768522':'Shënjues në xham',
'1812345820':'JO',
'847948448':'A jeni i interesuar për të siguruar automjetin tuaj?',
'-1899070354':'Përshkrimi teknik fillestar',
'-953303':'Përshkrimi i detajuar teknik',
'1237026086':'Vlerë e pasaktë - lloji i veturës',
'-259360107':'Ju lusim të shënoni llojin e automjetit',
'-1906046398':'Numri minimal i karaktereve është 1',
'-69524395':'Numri maksimal i karaktereve është 30',
'1663292305':'Ju lusim të shënoni prodhuesin e veturës',
'814923836':'Ju lusim të shënoni modelin e veturës',
'-1985695287':'Ju lutemi shënoni detajet e destinacionit',
'244480988':'Prodhuesi',
'1602844398':'Identifikim i detajuar',
'-1749998805':'Zgjedh veturën',
'-1162182506':'Lloji i veturës',
'1104651533':'Çertifikata e regjistrimit të automjetit - VIN',
'-1898506589':'xhami i përparmë i automjetit - VIN',
'216861745':'Ju lusim të konfirmoni të dhënat e shënuara',
'-1328097963':'Numri maksimal i karaktereve është 9',
'1678639740':'Ju lusim të shkruani numrin tuaj të telefonit',
'1303052228':'Ju lusim të shkruani emrin tuaj',
'1792286505':'Numri maksimal i karaktereve është 128',
'2122707327':'Ju lusim të shkruani mbiemrin tuaj',
'-938825602':'Numri maksimal i karaktereve është 64',
'1683031395':'Ju lusim të shkruani emrin e rrugës dhe numrin e shtëpisë.',
'661065202':'Ju lusim të shkruani vendin',
'-83271898':'Ju lusim të shkruani kodin postar',
'1018067916':'Numri maksimal i karaktereve është 5',
'12673416':'Formati i kodit postar nuk është i saktë',
'154576238':'Formati i ID-së së raportit është i pasaktë',
'-581020701':'lëshohet nga prodhuesi i veturës, sa herë që shëndeti ose mjedisi rrezikohet. Në të shumtën e rasteve, këto shkaktohen nga defektet gjatë prodhimit, i cili vërehet pasi vetura funksionon për një kohë. Si rregull, prodhuesi duhet t\'i riparojë këto defekte të veturës pa pagesë. Për më shumë informacione, ju lutem vizitoni uebfaqe më poshtë, e cila është përpiluar në bashkëpunim me sistemi informues RAPEX në EU. <a href="https://car-recalls.eu/" target="_blank">car-recalls.eu</a>',
'1969671182':'Të gjeturat e mëposhtme janë renditur sipas modelit, prodhuesit dhe vitit të prodhimit. Nuk janë të renditura nga numri VIN i veturës. Prandaj, kontrolloni gjithmonë të gjeturat për të parë nëse përputhen me veturën të cilën jeni duke e kontrolluar. Gjithashtu, kontaktoni shitësin apo servisin e autorizuar për t\'u siguruar nëse vetura ka pasur ndonjë defekt.',
'-2076502822':'Vendimet për tërheqje nga tregu lëshohen nga prodhuesi i veturës sa herë që vetura paraqet një rrezik të konsiderueshëm për shëndetin, sigurinë ose mjedisin. Si rregull, prodhuesi duhet t\'i riparojë këto defekte pa pagesë. Për më shumë informacione, ju lutemi vizitoni uebfaqen e mëposhtme <a href="https://car-recalls.eu/" target="_blank"> Car-recalls.eu</a> në bashkëpunim me sistemin informativ të BE-së RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Uzavřete pojistku prostřednictvím portálu <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> a získejte zpět peníze za prověření vozidla.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'5180415':'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'-312241960':'*Çdokush mund të shtoj të dhëna në lidhje me vjedhje të veturave në bazën private të të dhënave.',
'1225691915':'Bazuar në të dhënat tona, automjeti ka qenë ose është  <span class="warning">b ende duke u përdorur si taksi </span>. Kontrolloni funksionimin dhe kilometrazhin e makinës me kujdes.',
'-742868638':'E-mail adresa juaj',
'2033866416':'Online me kartë',
'138706621':'Transfer online',
'-1634274740':'Shikoni çertifikatën e raportit',
'1047356790':'Ju lusim të shënoni vitin e regjistrimit të parë',
'-39394372':'Reklama të makinave me % më të ulët të përputhshmërisë',
'-862383013':'Shpallje për shitjen e automjeteve të ngjashme',
'-233652021':'Historia e veturës',
'271847434':'Çertifikata Cebia',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'Při objednání kontroly vozu na Automato.cz vložte do určeného pole v košíku slevový kód <b>AUTOTRACER</b> a sleva vám bude automaticky odečtena z ceny.',
'-1554923364':'(cesta technika zdarma)',
'288042578':'Ju lusim të shënoni emrin e një kompanie',
'-1335200216':'Vlerë e pasaktë - ID e kompanisë.',
'1693391088':'Formati i emailit nuk është i saktë',
'-766469985':'Ju lusim të shënoni emailin tuaj',
'-1397806656':'Pajtohem me procesimin e të dhënave personale',
'-189484387':'Abonohu',
'1095553640':'Të gjitha pyetjet',
'1531150438':'Nuk e gjetët përgjigjen për pyetjen tuaj?',
'-469136467':'Na kontaktoni',
'-461584700':'Para se të bleni',
'400498991':'Politika e përdorimit të Cookies',
'1329764956':'Çka është VIN?',
'-394767958':'Blej',
'181431424':'Pas blerjes',
'1649241229':'VIN shenohet direkt në veturë ose mund të gjendet në librezen e regjistrimit të veturës dhe dokumentet e tjera të veturës. Nëse dëshironi të kontrolloni një veturë, pyesni shitësin e veturës për numrin VIN (shasisë).',
'1774694482':'Nga vijnë të dhënat e veturave?',
'-1122721284':'A e bën Auto-tracer kontrollimin e veturave edhe jashtë vendit?',
'437878122':'Çfarë informata mund të gjej rreth veturës?',
'850725814':'Informatat aktuale ndryshojnë varësisht nga historia dhe viti i veturës. Pasi ta shënoni numrin VIN, ne menjëherë ju tregojmë se cilat informata mund t\'i sigurojmë rreth veturës në raport <a href="/payed/detail/exampleAL">raport</a>.',
'-656971513':'Si duket një raport historik i veturës?',
'966688141':'Kostoja e raportit varet nga disponueshmëria e informacioneve të veturës që kontrollohet. Pasi të shënoni numrin VIN, ju mund të shihni informatat e disponueshme rreth veturës dhe koston e raportit të historisë.',
'764893782':'Ju mund të paguani online përmes terminalit te sigurt nga ProCredit Bank.',
'726713260':'Sa zgjat marrja e raportit?',
'1478989945':'Raporti gjenerohet menjëherë pas konfirmimit të pagesës. Shërbimi është funksional gjatë gjithë kohës dhe i gjithë procesi është automatik.',
'1681038954':'A mund t\'i shënojë të dhënat e kompanisë gjatë procesimit të pagesës?',
'696259005':'Gjatë plotësimit të të dhënave tuaja, mund të shënoni të dhënat e kompanisë tuaj. Sidoqoftë, pas pagesës lëshohet një dokumet tatimor. Nëse dëshironi të pranoni faturë, ju lusim të na konkatoni në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>.',
'-1555365601':'Raporti i historisë së veturës gjenerohet menjëherë pasi të pranohet pagesa. Përmes e-mailit të cilin e keni shënuar gjatë plotësimit të të dhënave personale, ju do të pranoni ID-në e raportit dhe një link. Historia e raportit të veturës mund të kontrollohet online brenda 30 ditëve pasi të gjenerohet raporti.',
'1984400663':'Pagesa nuk ishte e suksesshme, si të vazhdoj?',
'-956265022':'Nëse për ndonjë arsye pagesa nuk është kryer, kthehuni një hap prapa dhe provoni përsëri. Nëse pagesa është kryer, por ende nuk keni marrë një email konfirmues, ju lusim të na kontaktoni në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>.',
'-246543694':'Do të jetë i disponueshëm për 30 ditë pas gjenerimit. Ju mund të shikoni raportin duke e klikuar linkun të cilin e keni pranuar në emailin e shënuar gjatë kërkesës për raport.',
'592240026':'Nuk e kam pranuar emailin konfirmues ende, çfarë duhet të bëj?',
'339130199':'Nëse pas përfundimit të pagesës nuk pranoni një email të gjeneruar automatikisht, ju lusim të na kontaktoni në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>. Po ashtu, ju lusim që të shkruani ID-në raportit apo numrin VIN në email.eu.',
'1681612939':'Kam shënuar email adresën e gabuar, si mund ta ndryshoj?',
'-818807180':'Mos u shqetësoni. Na shkruani në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a> duke përfshirë e-mailin e saktë dhe ID-në e raportit dhe pastaj do të pranoni një link për qasje në e-mailin e ri.',
'-40057886':'Nëse dyshoni se në raport ka informacione të pasakta, ju lusim të kontaktoni Shërbimin ndaj Klientëve në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>. ',
'-755697752':'A mund të lëshohet një faturë?',
'1950759546':'Bazuar në koston e verifikimit, automatikisht gjenerohet vetëm një dokumet  tatimor. Ju lusim të na kontaktoni në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a> për të pranuar faturë. Për të përshpejtuar procedurën, ju lusim të shënoni numrin e kuponit të cilin e keni pranuar në e-mail.',
'-1960272511':'Shkruani numrin VIN (shasisë)',
'-371538970':'Ku mund ta gjej numrin VIN?',
'-1956291670':'Si funksionon?',
'1850076653':'Hapi i parë',
'-1260091754':'Të dhënat e disponueshme',
'1279291471':'Hapi i dytë',
'-811487544':'Pagesa',
'777550298':'Hapi i tretë',
'-1534039143':'Auto-tracer',
'495126992':'Hapi i katërt',
'1916048998':'Kontrolloni numrin VIN',
'-580693003':'Kontaktet',
'-760821457':'A keni nevojë për këshilla?',
'1432374431':'Kontakto',
'1838884148':'Zyret e Cebia',
'1665302400':'Zyret kryesore të kompanisë',
'1196114687':'Çekia',
'-95958777':'Informata rreth faturimit',
'1808758025':'Numri i telefonit',
'275235071':'Mund të na kontaktoni çdo ditë pune',
'353260003':'Hëne - Premte: 8:00 - 16:30 CET',
'822817400':'Kontrolloni historinë e automjetit',
'-1317542970':'Shërbim i besueshëm',
'-1884218466':'Për secilën veturë, ne kontrollojmë',
'1736624969':'Kilometrazhin',
'2137107000':'Dëmtimet e kaluara',
'647263705':'Veturat e vjedhura',
'-441819024':'Historinë e servisimit',
'982206269':'Informacione të publikuara',
'1753341741':'Përdorimin si taksi',
'-366535509':'Ekspertët e veturave përdorin shërbimet e Auto-tracer.',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'Keni dëgjuar për ne nga...',
'654946563':'karaktere',
'-1398891951':'Kilometrazhi',
'1425691903':'33% të veturave të reklamuara në tregun Çek nuk kanë kilometrat e sakta!',
'1803799674':'Viti dhe origjina e veturës',
'1389120536':'Si mosha, ashtu edhe origjina e veturave të importuara nga jashtë, shpesh falsifikohen!!',
'-2068933994':'Kontrolli për vjedhje',
'-1184837319':'Kontrolli për hipotekë/kredi',
'-1877453535':'Kontroll i aksidenteve',
'64494271':'20% e makinave të përdorura e të importuara janë të shkatërruara!',
'1051433840':'Kontrolloni nëse vetura ka qenë e reklamuar si e aksidentuar!',
'1076835371':'Vlerësimi i automjetit',
'-1805177459':'Raste të tërheqjes nga tregu',
'957124168':'Numri i pronarëve në Çeki',
'-1705145830':'Detajet e veturës',
'1890293527':'Kontroll nëse vetura ka qenë taksi',
'-13439485':'Ju lusim të shënoni emrin',
'-1147931879':'Ju lusim të shënoni mbiemrin',
'2144560301':'Numri i telefonit nuk është i saktë',
'-1642977586':'Ju lusim të shënoni numrin tuaj të telefonit',
'-1249914076':'Formati i emailit nuk është i saktë',
'-2038223065':'Ju lusim të shënoni mesazhin tuaj',
'810453022':'E-mail',
'-1217737116':'Mesazhi',
'-664538889':'Pajtohem me procesimin e',
'1322823680':'Dërgo mesazhin',
'56891982':'Numri maksimal i karaktereve është 16.',
'277834303':'Ju falenderojmë!',
'552239414':'Mesazhi është dërguar',
'1851370681':'Do të ju kontaktojmë së shpejti',
'1952981238':'Kujdes: ',
'342579190':'Blej raportin',
'1586626737':'Vazhdo',
'399846737':'Në pritje',
'556543048':'Informatat në vazhdim mund të kontrollohen:',
'560876430':'Fotot dhe reklamat',
'-320410471':'Nuk është në dispozicion',
'1185850210':'Asnjë e dhënë rreth aksidenteve nuk është gjetur',
'-903608283':'Raporti historik i automjetit',
'718416430':'Informata rreth ID-së së raportit historik të automjetit',
'668096075':'Shënoni ID-në e raportit për kyçje të përsëritura:',
'-1690204814':'E vlefshme deri:',
'-840281270':'Marshi',
'-1901926256':'Të dhënat bazë të automjetit',
'-1550051512':'Lloji i karrocerisë',
'-1043441574':'KUJDES:',
'-709435856':'Përvojat e klientëve',
'-1149927862':'Opinionet rreth raportit Auto-tracer',
'456577623':'Gjatë kontrollimit është gjetur se vetura ka qenë e aksidentuar dhe odometri është kthyer. Ju faleminderit!',
'329190632':'Shërbimi ka përmbushur pritshmëritë, janë gjetur dëmtime rreth automjetit. Ju faleminderit!',
'1450297818':'Kontrollo për vjedhje në Republikën Çeke dhe Sllovaki',
'-1594408882':'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
'341709606':'Dëshironi të siguroheni që makina është teknikisht në rregull? Zgjedh një teknik të Autobezabov, i cili së bashku me ju do të inspektojë makinën direkt në pikën e shitjes dhe do t\'ju këshillojë rreth blerjes.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Povinné ručení nebo havarijní pojištění musí být sjednané prostřednictvím portálu ePojisteni.cz do 30 dní od zaplacení prověření vozidla v systému AUTOTRACER',
'-355614707':'Oferta vlen për veturat e sigururara, automjetet dhe kamionët komercial (oferta nuk vlenë për motoçikletat e siguruara, automjetet që përdoren për rekreacion (ATVs), triçikletat ose rimorkiot dhe gjysëmrimorkiot).',
'-134420730':'Nevztahuje se na pojištění uzavřené prostřednictvím portálu ePojisteni.cz před zakoupením prověření vozidla v systému AUTOTRACER',
'1195365944':'Po uzavření pojištění vyplňte číslo účtu a číslo uzavřené pojistné smlouvy a částka Vám bude automaticky zaslaná na Vámi uvedený účet v žádosti po splnění podmínek nejdéle však do 60 kalendářních dnů od zaslání žádosti.',
'-1622287889':'Kërkesa juaj është dërguar. Ne do t\'ju kontaktojmë së shpejti.',
'1069770504':'Nëse automjeti i përzgjedhur është importuar individualisht në Republikën Çeke, ne ju rekomandojmë shërbimin tonë PROVIN i cili verifikon origjinën e huaj.',
'-121060108':'Ne do të kontrollojmë regjistrimin e veturave në vendin e origjinës, nëse është çregjistruar siç duhet, nëse është nxjerrë jashtë shërbimit për shkak të një aksidenti,  është regjistruar si i vjedhur në atë vend dhe shumë të tjera.',
'-1158490787':'Zbritja mund të aplikohet brenda 30 ditëve nga blerja e raportit të historisë së veturës dhe nuk mund të kombinohet me zbritje të tjera.',
'-1471409910':'Dëshironi të siguroheni se automjeti që keni zgjedhur nuk është rimodeluar në mënyrë të paligjshme? Ne ju rekomandojmë të përdorni servisin e identifikimit të automjetit VINTEST.',
'1555104762':'Servisi VINTEST u ofron klientëve të tij informacione 100% të garantuara rreth historisë së automjetit që bazohet në inspektimin fizik të tij. Ne kryejmë një inspektim fizik të detajuar të automjetit duke përdorur metodat më të reja dixhitale të diagnostifikimit. VINTEST verifikon që automjeti ka të gjithë identifikuesit e tij origjinal (VIN, etiketat, saldimet e trupit) dhe se nuk dyshohet të jetë ndryshuar ilegalisht.',
'735500953':'Mund të zbulohet nëse vetura është pjesë e hipotekës (kredisë apo lizingut) me kompanitë e listuara. Të dhënat merren direkt nga sistemet e informacionit të çdo kompanie.',
'-1173444543':'Kompani hipotekare apo kreditore',
'-976513658':'*Car-sharing = veturë me qira, ku një person jep me qira veturën e tij/saj për një periudhë të shkurtër kohore (car-sharing mund të nënkuptojë gjithashtu shfrytëzimin e veturës midis pronarit dhe huamarrësit afatshkurtër).',
'-1479754603':'Sipas të dhënave tona, vetura nuk është përdorur si taksi.',
'-467405909':'Të dhënat e automjetit që përdoret si taksi',
'1930957191':'Informacionet e dhëna përdoren vetëm për qëllime informative. Carvago by Gjirafa L.L.C nuk mban përgjegjësi për pasojat që mund të shkaktohen me publikimin e këtyre të dhënave.',
'-1895346741':'Llogaritësi ynë i vlerës, do të vlerësojë çmimin e tregut të çdo automjeti të përdorur. Ne e vlerësojmë këtë çmim bazuar në listat e automjeteve të përdorura në Republikën Çeke dhe jashtë saj, si dhe çmimet aktuale. Algoritmi i vlerësimit funksionon me modele llogaritëse të bazuara në monitorimin afatgjatë të tregut, por nuk merr parasysh gjendjen fizike të automjetit. Ju mund ta përdorni këtë llogaritës për të kontrolluar që çmimi i makinës që po vlerësoni është i njëjtë me çmimin e listuar.',
'-985858180':'Në raste të rralla, çmimi i automjetit mund të mos jetë i përcaktueshëm. Kjo pasi nuk ka të dhëna të mjaftueshme për një vlerësim të kualifikuar të çmimit të automjetit.',
'1915347014':'Ne do t\'ju listojmë automjete të ngjashme, që gjenden aktualisht në treg dhe në disa raste edhe çmimet e atyre të shitura (ne përdorim numrin VIN për të krahasuar specifikat e sakta të automjeteve). Duke përdorur lista automjetesh të ngjashme, ju mund të krahasoni nëse çmimi i automjetit që po kontrolloni përputhet me automjetet në treg. Ne ju kursejmë kohën që ju kaloni duke kërkuar dhe bërë krahasime në ueb.',
'-767763886':'Nuk u gjet asnjë e dhënë rreth reklamimit të veturës.',
'797541609':'Kontrolloni në çfarë gjendje dhe në çfarë kilometrazhi është reklamuar vetura në të kaluarën. Nëse vetura është reklamuar më parë me kilometrazh më të lartë sesa tani, odometri mund të jetë kthyer.',
'-1902352540':'Kam kontrolluar dhe konfirmoj të dhënat më lartë.',
'-2008975653':'Nuk u gjet asnjë e dhënë rreth ngjyrës së deklaruar nga prodhuesi apo nga regjistrimi i parë i automjetit.',
'7150984':'Verifikoni nëse ngjyra aktuale e veturës përputhet me ngjyrën e deklaruar nga prodhuesi ose ngjyrën e dokumentuar pas regjistrimit të parë në Republikën Çeke (ose Sllovaki). Nëse ngjyra është ndryshe, vetura mund të jetë ringjyrosur, aksidentuar ose vjedhur.',
'-1664139465':'Lista e pajisjeve nuk është e disponueshme. Ju rekomandojmë që të kontrolloni fizikisht funksionalitetin dhe origjinalitetin e pajisjeve në automjet.',
'1681595153':'Si pjesë e kontrollit, ne do t\'ju tregojmë nëse vetura përmban shënjues në xham dhe nëse është i regjistruar në sistemin OCIS. Nëse po, ne do t\'ju ofrojmë kodin e shënuar dhe datën e regjistrimit.',
'571758489':'Vetura në të kaluarën ka pasur shënjues në xham dhe është regjistruar në bazën e të dhënave ndërkombëtare OCIS.',
'855264535':'Nëse kodi unik mungon në disa ose të gjitha xhamat e veturës, vetura mund të jetë e aksidentuar, e riparuar ose e vjedhur.',
'388233970':'Informacion rreth shënjimit të xhamit:',
'1615022749':'Nuk kemi mundur të gjejmë parametrat teknik të automjetit. Megjithatë, këto parametra mund t\'i gjeni në librezën e automjetit Pjesa II ose mund t\'i kërkoni nga prodhuesi.',
'704641294':'Shënoni detajet e veturës dhe merrni udhëzime se si të kontrolloni identifikuesit e veturave. Udhëzimet zbatohen përgjithësisht për çdo veturë me markën dhe modelin që keni specifikuar. ',
'-621659477':'Bazuar në VIN-in e shënuar, ne mund të plotësojmë automatikisht detajet e veturës.  Nëse detajet e plotësuara paraprakisht nuk përputhen me detajet aktuale, ju lutemi fshini dhe shënoni ato manualisht.',
'152333450':'Nuk u gjetën të dhëna rreth dëmeve, çfarë nënkupton kjo?',
'780709713':'Për sa kohë do të jetë i disponueshëm raporti?',
'830704335':'Shikoni cilat informacione shfaqen rreth veturës tuaj:',
'1850331238':'Shërbim i shkëlqyeshëm. Auto-tracer zbuloi ndërhyrje në odometër edhe në një automjet demo.',
'1705245752':'Doja të blija një veturë për të cilën shitësit pretenduan se nuk ishte e aksidentuar. Auto-tracer zbuloi se ishte e aksidentuar disa herë.',
'739619867':'Një shërbim fantastik, falë tij nuk blemë një veturë të aksidentuar. Faleminderit!',
'-2071079400':'Shërbimi juaj zbuloi se makina që zgjodha kishte dy aksidente me dëme të mëdha që shitësi nuk i deklaroi. Falë tij, nuk e bleva automjetin. Faleminderit.',
'21202350':'Faleminderit për këtë shërbim. Nëse nuk do të ishte Auto-tracer, do të kisha humbur 4000 € në një makinë. Sipas pronarit, makina ishte vetëm pak e thyer përpara. Auto-tracer zbuloi se ishte aksidentuar dy herë, me dëmtime të mëdha.',
'1343185270':'Kontrolli i historisë së makinës më ndihmoi të merrja vendimin për të blerë.',
'2083029914':'Auto-tracer zbuloi dëme të mëdha që shitësi nuk i përmendi.',
'1933958141':'U gjet një përplasje e vogël + kontrolli i odometrit nga data e regjistrimit.',
'1900495866':'Falë aplikacionit, zbulova se diçka nuk shkonte me makinën. Sigurisht, tregtari dhe shitësi nuk më thanë asgjë për këtë. Faleminderit!',
'-1668804606':'Gjatë kontrollit Auto-tracer gjeta mangësi serioze të makinës që shitësi nuk mi tregoi, p.sh., kthimi i odometrit, automjeti ishte i aksidentuar.',
'-273876487':'Auto-tracer zbuloi mashtrime rreth odometrit. Shitësi ka deklaruar se makina kishte bërë 155,000 km, ndërsa në realitet kishte bërë 250,000 km...',
'911857027':'Shkruani ID-në e raportit në mënyrë që të shihni historinë e raportit të makinës tuaj',
'1167593586':'Ju do të pranoni një ID të raportit pasi të blini raportin e historisë së veturës, pra pasi të paguani për pakon përkatëse.',
'-2136315768':'Nese pagesa bëhet me kartelë, GooglePay apo ApplePay,  ju do të pranoni një raport përmes e-mailit dhe vazhdimisht mund të shikoni raportin e historisë së veturës.',
'868918064':'Nëse paguani me transfer bankar, do të pranoni një e-mail me ID-në e raportit të pagesës, menjëherë pasi të hollat të transferohen në llogarinë bankare të Cebias.',
'1572179326':'ID e raportit pritohet nga ana jonë, nëse ju paguani me para në dorë në zyret qendrore të Cebia, spol. s r.o.,',
'-1322783148':'Vendndodhja e numrit VIN ndryshon nga marka dhe modeli i veturës. Por si rregull, VIN mund të gjendet gjithmonë në librezën e veturës dhe shpesh gjendet në xhamin e përparmë.',
'700484420':'VIN është numri unik i identifikimit të çdo veture, i cili mund të gjendet në dokumentet e veturës ose direkt në veturë.',
'-851712281':'Ne do të gjurmojmë të gjitha të dhënat e disponueshme për veturën që po kontrolloni dhe do t\'ju shfaqim listën e këtyre të dhënave. Ju gjithmonë do ta dini se çfarë po bleni.',
'-1983041378':'Ju mund të paguani online në terminal të sigurt nga ProCredit Bank ose me transfer bankar.',
'-1809684191':'Auto-tracer i veturës është i disponueshëm menjëherë pas pagesës. Sigurisht, ne do t\'ju dërgojmë atë edhe në email.',
'541894049':'Do të mësoni evolucionin e kilometrazhit të automjetit gjatë kontrollimit. Në një grafik të thjeshtë, mund të shihni se si leximet e odometrit kanë evoluar me kalimin e kohës dhe të zbuloni çdo rikthim të mundshëm të kilometrave. Vëmendje: më shumë se 40% e automjeteve të përdorura kanë pasur kilometra të kthyer!',
'643692561':'Ju mund të shikoni të dhënat e dëmeve të kaluara për automjetin në fjalë. Informacionet rreth kostos së dëmit dhe shtrirjes së dëmit janë në dispozicion në seksionin e detajeve. Kujdes: mos harroni të kontrolloni gjendjen teknike të automjetit dhe dëmtimet e tjera të tij. Për shembull, nëse ka pasur ndonjë aksident të rëndë.',
'878444309':'Nëse automjeti është reklamuar në të kaluarën, ne do t\'ju tregojmë reklamën dhe dokumentacionin fotografik për automjetin.',
'185243225':'Do të gjeni informacione të detajuara dhe një pasqyrë të punës së shërbimit të kryer, si pjesë e riparimit ose servisimit të zakonshëm të automjetit tuaj. Kujdes: krahasoni të dhënat në librin e servisit dhe kërkoni më shumë informacion për historinë e automjetit.',
'1036319803':'Ne do të verifikojmë vitin e prodhimit, i cili mund të ketë një ndikim të rëndësishëm në çmimin aktual dhe të ardhshëm të automjetit. Viti i prodhimit nuk tregohet më në librezën e automjetit. Vetëm data e regjistrimit të parë tani është e shënuar në librezë. Këto dy data mund të ndryshojnë deri në 2 vjet nga viti i prodhimit, por edhe mospërputhja 6-vjeçare nuk përjashtohet. Në fakt, ripërtëritja e automjetit për një vit mund të nënkuptojë një humbje prej disa dhjetëra mijëra CZK në çmimin e automjetit. Për shumicën e automjeteve, do të mësoni gjithashtu datën e regjistrimit të parë dhe vendin për të cilin automjeti është prodhuar fillimisht.',
'-437053085':'Ne do e bëjmë kontrollimin e  automjetit bazuar në të dhënat rreth automjeteve të vjedhura. Këto janë kryesisht të dhëna evropiane të mbledhura nga burime publike dhe private. Një listë e vendeve dhe të dhënave të disponueshme, mund të gjendet në raportin e historisë së veturave.',
'494784690':'Ne do ta kontrollojmë automjetin bazuar në të dhënat financiare të kompanive, kompanive të makinave me qira dhe agjentëve. Në raportin e historisë së veturës, do të mësoni nëse makina është e pjesë e hipotekës apo kredisë.',
'2074346923':'Ne do të kontrollojmë automjetin në bazë të të dhënave të taksive. Në raportin e historisë së veturës, do të mësoni nëse automjeti është përdorur si taksi. ',
'-976680067':'Ne do t\'ju shfaqim një listë të rasteve të tërheqjes së veturës nga tregu që mund të aplikohen për automjetin që po kontrolloni.',
'1665109686':'Përshkrimi teknik do t\'ju mundësojë të kontrolloni parametrat e rëndësishëm mbi bazën e të cilave është miratuar për përdorim versioni i modelit të llojit të automjetit. Krahasoni këto parametra me informacionet në librezë të automjetit.',
'-719963267':'Ne do të shfaqim një listë të pajisjeve të automjetit sipas specifikave të prodhimit. Ju mund të krahasoni pajisjet aktuale të automjetit me ato të specifikuara nga prodhuesi. Ju lutemi për kujdes: nëse, për shembull, prodhuesi ka listuar timonin në anën e djathtë, tapiceri lëkure ose tavan diellor në specifikat e automjetit dhe automjeti juaj ka timonin në anën e majtë, tapiceri pëlhure ose pa tavan - ndoshta nuk është i njëjti automjet, por mund të jetë një automjet i vjedhur dhe i rimodeluar! Nëse ka ndonjë mospërputhje, duhet t\'i kushtoni vëmendje ekzaminimit të mëtejshëm fizik të automjetit për të parandaluar një blerje të gabuar të një automjeti që mund të jetë produkt i veprimtarisë kriminale.',
'-1106584159':'Automjeti juaj është i pajisur me spërkatje ose gravurë të dritareve, ose automjeti mund të ketë pjesë të gravuara. Ne do t\'ju ofrojmë kodin e gravimit dhe datën e regjistrimit të automjetit.',
'1830950672':'Ne do t\'ju tregojmë ngjyrën e regjistruar nga prodhuesi i automjetit dhe ngjyrën pas regjistrimit në Republikën Çeke ose pas regjistrimit në Sllovaki (nëse e kemi këtë informacion në dispozicion).',
'1835889470':'Duke u përpunuar',
'1020539397':'Operatori i historisë së veturave - Carvago by Gjirafa LLC dëshiron të informojë përdoruesit se informacionet  janë vendosur në sistem nga partnerët, pa asnjë mundësi për të verifikuar saktësinë e tyre. Të gjitha informacionet janë vetëm për qëllime informative.',
'2054100346':'Ne nuk garantojmë në saktësinë e rezultateve të raportit të historisë së veturës.',
'-167783152':'Ky raport u krijua nga një pyetësor në sistemin e historisë së veturave, që operohet nga Carvago by Gjirafa LLC sipas kushteve të përgjithshme të vlefshme për përdorimin e historikut. Përdoruesi është i autorizuar të përdorë informacionin e dhënë në tërësi, vetëm për përdorimin e tij/saj. Ndalohet shpërndarja ose modifikimi i mëtejshëm i raportit pa pëlqimin e operatorit.',
'-1697979879':'Cebia CEO, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Cebia CEO',
'759051798':'Gjirafa & Cebia',
'-31539157':'Vizioni i Gjirafës është të përdorë teknologjinë për të shfrytëzuar potencialin e zhvillimit të ekonomisë në rajon dhe krijimin e shërbimeve softuerike të sofistikuara me zbatueshmëri globale. Me një mision për të përmirësuar cilësinë e jetës së njerëzve, Gjirafa është e apasionuar pas krijimit të produkteve dhe ofrimit të shërbimeve që bëjnë ndryshimin. Per te mësuar më shumë reth Gjirafa vizitoni <a href="https://about.gjirafa.com">about.gjirafa.com</a>.<br><br>Nga ana tjetër, Cebia, një kompani Çeke e themeluar në vitin 1991, e cila fokusohet në inspektimin, sigurimin dhe verifikimin online te historisë së veturave, me mbi 1 miliard të dhëna të veturave, është databaza më e madhe e këtij lloji në Europën Qendrore. Për të mësuar më shumë reth Cebia vizitoni <a href="https://www.cebia.cz">www.cebia.cz</a>. ',
'-1821588851':'Kërkesa dhe ankesa',
'-276118588':'Kërkesat dhe ankesat janë një burim i vlefshëm sugjerimesh dhe na ndihmojnë të përmirësojmë shërbimet tona. Ju lutemi na kontaktoni duke përdorur formularin e kontaktit ose me email: <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>.',
'-1446517877':'Përgjigjen rreth ankesës do ta merrni brenda 14 ditëve.',
'708234375':'Na kontaktoni këtu ose përmes emailit  <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>',
'1474175101':'Kushtet e përdorimit',
'1343500875':'Politikat e privatësisë',
'-2083968164':'Vlerësimi online i veturave',
'907588970':'Themelimi i kompanisë',
'-770165824':'<strong>30 vite</strong> përvojë në kontrollimin e veturave të përdorura',
'-1138482741':'<strong>Në më shumë se 1,000,000</strong> vetura të kontrolluara çdo vit',
'154007106':'Më shumë se <strong>750,000</strong> klientë të kënaqur',
'177137909':'Me <strong>auto-tracer by Gjirafa</strong>, ju merrni raportin më të plotë dhe më të besueshëm të historisë së veturave të disponueshme në treg.',
'723486619':'Duke u përpunuar...',
'742852638':'Periudha:',
'787763184':'Periudha kohore:',
'-685036969':'Tregimi:',
'290784658':'Nuk është i disponushëm:',
'412892373':'Nuk është gjetur asnjë e dhënë për makinën në këtë kategori.',
'1853282090':'I disponueshëm:',
'-249038921':'XY të dhëna:',
'805146013':'Ne kemi gjetur XY të dhëna për automjetin në këtë kategori. Ju mund të përdorni këto të dhëna nëse paguani për shërbimin.',
'242118549':'Kushtet e përdorimit',
'521725542':'Shikoni kushtet e përgjithshme për përdorimin e historikut të veturës.',
'-571686819':'këtu',
'639498968':'Pagesa për kontrollimin e raportit ose çertifikatës së historikut të veturës do të tarifohet sipas kushteve të kontratës suaj me Cebia.',
'-1705426324':'Ju mund të kontrolloni online raportin e historikut të veturës tuaj brenda 30 ditëve pas kontrollimit të parë.',
'529403448':'Me rëndësi:',
'-821000576':'Cebia, dëshiron të informojë klientët e saj se informacionet janë vendosur në sistem nga partnerët e operatorit pa mundësinë e verifikimit të saktësisë së tyre. Të gjitha informacionet janë vetëm për qëllime informative. Nëse gjendja e automjetit nuk përputhet me informacionet e dhëna këtu, ju rekomandojmë të kontaktoni një ekspert përkatës për të verifikuar gjendjen e saktë të automjetit.',
'-1138137280':'Politika jonë për mbrojtjen dhe përpunimin e të dhënave personale.',
'125063053':'Shikoni politikën tonë të përpunimit të të dhënave personale për CebiaNET dhe historikut të veturës.',
'-608864551':'Ne kemi gjurmuar informacionin e automjeteve në këtë kategori. Ju mund të shikoni informacionin në raportin e historikut së veturës.',
'-1879995849':'Të dhënat e shënuara',
'754897480':'Viti i regjistrimit të parë',
'-1834106204':'Kilometrazha e tanishme',
'404797827':'Shiko çertifikatën',
'1358780725':'Gjenero çertifikatën',
'-952450705':'Kam kontrolluar dhe konfirmoj të dhënat e më sipërme',
'-1601808583':'<span className="green">Përfundoi! </span>Të dhënat e gjetura:',
'-1763095911':'Mund të lëshohet një çertifikatë e raportit Cebia, që vlerëson kategoritë e mëposhtme:',
'761362384':'Draft Raport',
'-397155268':'Shiko raportin',
'1375027269':'Kontrollo automjetin',
'2055286638':'në më shumë se',
'-1668243810':'Po rritemi',
'1141836815':'Jemi pjesë e grupit ndërkombëtar të investimeve EAG Holding. Si rezultat, bazat tona të të dhënave po zgjerohen ndjeshëm duke përfshirë regjistrime nga e gjithë Evropa - veçanërisht Gjermania, Austria dhe Danimarka.',
'-903271711':'Kemi lansuar modulin online i cili përcakton çmimin e veturave të përdorura. Falë databazëss së të dhënave të veturave të përdorura, ky modul është shumë i saktë dhe i kërkueshëm nga kompanitë e sigurimeve dhe lizingut.',
'-1262829232':'Transformimi i tregut për veturat e përdorura',
'1367974718':'Me rritjen e interesimit për blerje të veturave online, kemi filluar të punojmë me portalet më të mëdha të shpalljeve në Europën Qëndrore. Rezulltatet e kontrolleve tona të historisë janë shenjë dalluese e cilësisë në sektorin e veturave të përdorura.',
'-300528762':'Po bëhemi pjesë e botës virtuale',
'-899322976':'Shërbimet tona tani janë lehtësisht të qasshme për këdo. Mashtrimi me vetura të përdorura është në rritje dhe ne po e luftojmë me të gjitha forcat.',
'-4875574':'Shërbimet tona po zgjerohen',
'1166185728':'Kemi shtuar verifikimin fizik të identifikuesve të veturave (VIN) në shërbimet tona dhe baza jonë e të dhënave është duke u zgjeruar me hapa të mëdhenj. Ne bashkëpunojmë më kompani të sigurimeve, agjenci qeveritare dhe policinë.',
'-1619922514':'Përtej kufijve',
'-844900279':'Tregtia e veturave të vjedhura është më e përhapur në vende të tjera sesa në Republiken Çeke. Prandaj ne krijuam një bazë të dhënave ndërkombëtare të veturave të vjedhura për të na ndihmuar të zbulojmë origjinën e vërtetë.',
'598423318':'Cebia u krijua nga tre shokë universiteti me synimin fillestar për të qenë pjesë e sektorit të IT-së, por që u zëvendësua shpejt me një interes për sigurinë e veturave.',
'-1828699417':'Dështimi/ Falimentimi',
'4000011':'Kontroll i dokumeteve pa pagesë',
'1479506336':'Historia e kërkimit',
'-1478881101':'Historia e verifikimit të automjetit',
'1115668272':'Dështimi/Historia e falimentimit',
'691760517':'Historia e verifikimit të dokumentit',
'-614781938':'Shtrirja dhe kostoja e raportit të historisë së veturës mund të ndryshoj nga vetura në veturë varësisht nga sasia e informatave që kemi në dispozicion për këtë veturë.',
'702191442':'Prapa',
'-1034048918':'Kontroll i VIN',
'-1214567423':'Numri i referencës',
'427849990':'Krijo çertifikatë',
'-1718377395':'Ka ndodhur një gabim gjatë leximit të ID-së së raportit',
'877365836':'Ka ndodhur një gabim gjatë gjenerimit të URL-së për pagesë',
'-1082791212':'Kodi i zbritjes nuk është i vlefshëm',
'-255296664':'ID e raportit ju lejon që të mund të shihni raportin tuaj deri në 30 ditë pasi që keni bërë pagesën.',
'456125545':'Auto-tracer, dëshiron të informojë klientët e saj se informacionet e dhëna janë vendosur në sistem nga partnerët e operatorit pa mundësinë e verifikimit të saktësisë së tyre. Të gjitha informacionet janë vetëm për qëllime informative.',
'-1172808350':'Kërkoni nga shitësi kodin VIN dhe më pas kontrolloni automjetin.',
'-2079080642':'Kërkoni nga shitësit t\'ju japë kodin VIN 17-shifror dhe më pas kontrolloni automjetin.',
'-585052469':'Doni të bleni një automjet të përdorur? Kontrolloni historinë e tij, kilometrazhin ose të dhënat rreth aksidenteve duke shkruar numrin VIN!',
'76811920':'Shembull i një raporti të kontrollës së veturës',
'1050846059':'Megjithatë, ne ju rekomandojmë që vetura juaj të inspektohet fizikisht nga një mekanik i kualifikuar ose në një garazh servisi. Një inspektim i tillë, mund të zbulojë qartë ose të përjashtojë aksidentet e mëparshme.',
'-1068264553':'Kontrolloni gjithmonë datën e prodhimit të deklaruar nga prodhuesi i automjetit. Në librezë është shënuar vetëm data e regjistrimit të parë. Këto dy data mund të ndryshojnë deri në 2 vjet nga viti i prodhimit, por edhe mospërputhja 6 vjeçare nuk përjashtohet.',
'756232271':'Nuk janë gjetur të dhëna për vjetërsinë apo origjinën e automjetit. Nëse automjeti vjen nga jashtë, gjithmonë kërkoni dhe kontrolloni me kujdes dokumentacionin origjinal për automjetin (p.sh. librin e servisit ose librezën e automjetit).',
'-643660678':'Një ose më shumë e dhënë rreth servisimit është gjetur.',
'1533967406':'Çmimi i raportit të historisë së veturës dallon, varësisht nga sasia e informacioneve të historisë që kemi në dispozicion për një veturë të caktuar. Ju mund të shihni informacionet që ne i posedojmë dhe çmimin përkatës të një raporti specifik duke shkruar numrin VIN. Në përgjithësi, çmimi i raportit fillon nga 11.99€.',
'-1289463237':'Raporti zakonisht krijohet menjëherë pas pagesës. Shërbimi është i disponueshëm 24/7 dhe është plotësisht i automatizuar.',
'1304585346':'Pasi të jetë përfunduar pagesa, sistemi do t\'ju dërgojë një e-mail automatik në e-mailin që keni shënuar. Në e-mail, do të gjeni ID-në e raportit (që do t\'ju mundësojë të keni qasje në raport) së bashku me një faturë.',
'-661494819':'Ka ndodhur një gabim gjatë këtij përpunimi',
'-1681989289':'Me aplikimin për raportin e historisë së makinës, ju përfitoni një zbritje prej 100 CZK në blerjen tuaj të radhës. Shkruani ID-në e raportit në fushën e kodit të zbritjes, gjatë blerjes së radhës. Zbritja është e vlefshme për 90 ditë pas aplikimit për raportin e parë.',
'681093117':'Ju keni pranuar një kupon zbritje, për rinovimin e shërbimit tuaj FLEX për një vit tjetër. Për të aplikuar zbritjen, vendosni numrin e kodit të zbritjes që keni pranuar në faturën tuaj tatimore FLEX në fushën "Shkruaj numrin e kuponit".',
'-1551909822':'Ju keni pranuar një kupon për një kontroll falas të automjetit. Ju mund ta shfrytëzoni zbritjen duke shënuar ID-në e raportit në fushën e kodit të zbritjes, në aplikimin e radhës.',
'-103162544':'Pasi që pagesa të procesohet, ne do t\'ju dërgojmë një link të raportit të makinës së bashku me një dokument të thjeshtuar tatimor në email adresën e mëposhtme.',
'-591911074':'Pasi që pagesa të procesohet, ne do t\'ju dërgojmë një link të raportit të makinës së bashku me një dokument të thjeshtuar tatimor në email adresën e mëposhtme.',
'706315962':'Nëse ju duhet faturë me të dhënat tuaja biznesore (p.sh., ku përfshihet emri i kompanisë, adresa, numri i regjistrimit dhe numri identifikues i TVSH-së), duhet të plotësoni fushat më poshtë.',
'500915765':'Kur të bëni porosinë, do të ridrejtoheni te terminali i pagesave ProCredit Bank, për të bërë një pagesë të sigurt me kartë. Nëse pagesa juaj është e suksesshme, atëherë do të ridrejtoheni automatikisht përsëri në sistemin e historisë së makinës, për të parë raportin. Në të njëjtën kohë, ne do t\'ju dërgojmë një email në adresën që jepni, që përmban një faturë tatimore dhe një ID të raportit të historisë.',
'-676823504':'Nëse zgjedhni këtë mënyrë pagese, do të ridrejtoheni në terminalin e pagesave ProCredit Bank, ku zgjedhni institucionin tuaj bankar nga i cili dëshironi të bëni pagesën.',
'-764670786':'Nëse banka juaj ofron një transferim të menjëhershëm në internet, ne do t\'ju ridrejtojmë në bankën tuaj online ku do të keni një urdhër pagese të para-plotësuar.',
'2062466434':'Nëse pranojmë pagesën nga banka menjëherë, ne do t\'ju ridrejtojmë automatikisht te raporti i historisë së makinës. Përndryshe, ju lutemi të prisni derisa të pranoni emailin.',
'734607756':'Pasi të ketë përfunduar pagesa, sistemi do t\'ju dërgojë një email automatik në emailin që keni dhënë. Në email, do të gjeni ID-në e raportit të historisë (= që do t\'ju mundësojë të keni qasje në informacionet e paguar) së bashku me një dokument tatimor.',
'1715999355':'Google Pay është një mënyrë e shpejtë dhe e lehtë për të paguar kudo përmes Google, pa shkruar detajet e pagesës. Mund të paguani duke përdorur çdo kartë të ruajtur në Google Pay. Megjithatë, keni parasysh se për të përdorur këtë shërbim pagese, banka juaj duhet të mbështesë Google Pay. Nëse pagesa juaj me Google Pay është e suksesshme, automatikisht do të ridrejtoheni te raporti i historisë së makinës, ku do të mund të shikoni historikun e detajuar të automjetit. Në të njëjtën kohë, sistemi do t\'ju dërgojë automatikisht një e-mail me një dokument tatimor dhe një ID, të cilin mund ta përdorni për ta kontrolluar raportin më vonë.',
'790632208':'Apple Pay është një mënyrë pagese, që e bën më të lehtë pagesën duke përdorur një kartë të ruajtur në Apple Wallet. Sqarim: Jo të gjitha bankat e mbështesin këtë mënyrë pagese, kontrolloni nëse banka juaj përdor Apple Pay përpara përdorimit. Nëse pagesa juaj me Apple Pay është e suksesshme, automatikisht do të ridrejtoheni te raporti i historisë së makinës, ku do të mund të shikoni historikun e detajuar të automjetit. Në të njëjtën kohë, sistemi do t\'ju dërgojë automatikisht një e-mail me një dokument tatimor dhe një ID, të cilin mund ta përdorni për ta kontrolluar raportin më vonë.',
'-1402859324':'Në rast se banka juaj ofron vetëm të ashtuquajturin transfer offline, ne do të ju dërgojmë udhëzimet për transferin bankar, më pas do t\'i transferoni fondet në bankën tuaj dhe pasi shuma të kreditohet në llogarinë tonë bankare, ne do t\'ju dërgojmë një email me qasje në sistemin e historisë së makinës dhe një vërtetim pagese.',
'347099860':'Gjenerimi mund të zgjas deri në 2 ditë pune (zakonisht është gati brenda 24 orë).',
'-43783036':'Raporti i makinës do të jetë i disponueshëm te zyret e Cebia',
'-802148009':'Pagesën për raportin e makinës tuaj mund ta bëni edhe te zyret e Cebia gjatë ditëve të punës nga 8:00-16:30. Gjatë pagesës është e domosdoshme që ta keni kodin VIN me vete.',
'1985201267':'Databaza e veturave të përdorura më e madhja ne Europë.',
'-1590601891':'<span class="color-red">Paralajmërim:</span> Raporti mund të mos përmbaj të gjitha aksidentet e veturës, disa prej tyre mund të mos jenë të raportuar te kompanitë e sigurimeve ose mund të mos jenë të disponueshme.',
'-1465948017':'<strong>Sistemi OCIS-</strong> bën shënjimin e dritareve të automjetit. Siguri e thjeshtë, efikase dhe me kosto efektive.<a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/en/services/glass-sandblasting\' target=\'_blank\'>Për më shumë, klikoni këtu</a>.',
'1553997983':'<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html\' target=\'_blank\'>Více zde.</a>',
'-124714580':'Pagesa mund të bëhet online në terminal të sigurt nga ProCredit Bank.',
'-753863533':'Çertifikata e kontrollimit të automjetit - pasqyrë e informatave në dispozicion',
'1441255407':'Kategoria',
'1549893453':'Kontrolloni opsionet',
'1547427535':'Po',
'508023484':'Kontrolli i datës së regjistrimit të automjetit',
'2076692599':'Inspektim fizik',
'-1521980547':'Kontroll i kilometrazhit',
'-1009985044':'Po - bazuar në',
'1747355819':'Të dhëna',
'1639206028':'Kontrolli i origjinës së automjetit',
'-1524110425':'Kontrolli i pagesës së taksës së ambientit',
'-1961089879':'Kontrolloni TVSH',
'1732127987':'Kontrolloni për dëmtime të rënda',
'-198755848':'Kontrolloni regjiistrimin',
'-1790774232':'Kontrolli i dëmeve nga përmbytjet',
'-1781437228':'Kontroll i llojit të motorit',
'-1344389293':'Kontrolli i identifikuesve',
'-499181915':'Foto',
'-687939409':'Informata është marrë nga të dhënat e gjendjes së automjetit në Cebia ose nga rrjeti i kontraktorëve. ',
'-43022982':'Data e servisimit:',
'-136414993':'Gjendja e automjetit:',
'2023782231':'Automjeti është kontrolluar fizikisht për identifikuesit e tij (vulosja e VIN, etiketa e prodhimit, etj.). Informacioni është marrë nga të dhënat e shërbimit VINTEST të kryera nga Cebia ose kontraktorët e saj.',
'758499555':'Gjendja e identifikuesve:',
'488100027':'Prapa',
'-1281249872':'Vazhdo',
'-141562572':'Krijo',
'-40297285':'Ju mund të merrni një përmbledhje të çmimeve dhe të dhënave për automjetin që po kontrolloni.',
'-1742933347':'Numri i shpalljes',
'-2099386182':'Zbritje',
'1560408371':'Çmimi me zbritje',
'-280814568':'Shiko çertifikatën e inspektimit të makinës',
'2075274708':'Data',
'-1248991647':'Ukrainë',
'-1779298552':'Suedi',
'-18328562':'Latvi',
'1764744897':'Lituani',
'-1273046880':'Romani',
'1252567719':'Bullgari',
'445626359':'Greqi',
'-868498706':'Slloveni',
'-1528448787':'Hungari',
'-387469480':'Sllovaki',
'25300212':'Austri',
'1771002523':'Itali',
'69077775':'Danimarkë',
'647944028':'Poloni',
'1160986873':'Gjermani',
'897084544':'Francë',
'2056598840':'Spanjë',
'264100206':'Rusi',
'-1609348689':'Kushtet e përdorimit të Cookies',
'-365994139':'Shfaq më shumë',
'807848789':'Miliona klientë të kënaqur',
'1677683955':'Auto-tracer ka më shumë se <strong>2 000 partnerë</strong> në të gjithë Evropën, të cilët na ofrojnë të dhëna për makina nga e gjithë bota.',
'652553309':'A e keni ditur?',
'1453750886':'Përfundoi!',
'1498262202':'Në dispozicion',
'1514779988':'Inspektimi i automjetit nga tekniku',
'1058658544':'Kuponat e zbritjes nga Cebia',
'-2035347536':'0 regjistrime',
'1050102897':'Specifikimi i detajuar i pajisjeve të ofruara nga prodhuesi i automjetit përfshin të gjitha pajisjet bazë dhe opsionale. Një kontroll i pajisjeve mund të zbulojë ndryshime rreth automjetit si p.sh ndryshimi i anës së timonit.',
'2145745364':'Të dhënat e odometit',
'-1636264801':'Përshkrimi',
'-1488190529':'Numri i regjistrimit',
'64594583':'Automjeti është pjesë e hipotekës/kredisë',
'-1441353378':'Nuk është pjesë e hipotekës/kredisë',
'-2101084179':'janar',
'-1066567335':'shkurt',
'-1761670105':'mars',
'104663035':'prill',
'1397259452':'maj',
'-642974117':'qershor',
'613657227':'korrik',
'-2044487450':'gusht',
'1921417285':'shtator',
'-1672709282':'tetor',
'-678062097':'nëntor',
'1302554433':'dhjetor',
'1268839568':'Nuk u gjet asnjë rezultat në bazat e të dhënave të servisit. Kërkoni një ekstrakt të librit të servisit (mundësisht nga libri elektronik i servisit) të veturës.',
'-112660439':'Këtu mund të krijoni një  <a target="_blank" href="https://www.cebia.cz/cebia-report"> raport Cebia</a>, të cilin mund t\'ua ofroni atyre që janë të interesuar për makinën tuaj dhe ta rrisni besueshmërinë gjatë shitjes ose reklamimit në portalin më të madh të reklamave <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Është përdorur si taksi',
'-1350474522':'Të dhënat tona nuk përmbajnë asnjë regjistrim kilometrazhi për veturën.',
'1040913417':'Ne ju rekomandojmë të kontrolloni burime të tjera të regjistrimeve të kilometrazhit, duke përfshirë librin e servisit të veturës. Rekomandohet gjithashtu që të kërkoni historikun në një servis të autorizuar. Më pas, kur kontrolloni veturën, kushtoni vëmendje aksidenteve, veçanërisht në pjesët që mund të japin të dhëna për kilometrazhin aktual.',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'E-mail',
'-982011601':'SHBA',
'786789662':'Bleni pako me zbritje',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Chcete nakoupit na firmu nebo prověřit více vozidel? Využijte naše',
'845754511':'zvýhodněné balíčky!',
'-614796774':'Çekia',
'1011350398':'Hëne-Premte: 8:00 - 16:30 CET',
'-68730396':'Deri në datë:',
'544810671':'Jo-I paregjistruar',
'-1670731287':'Regjistruar në Çeki si:',
'1597705322':'Pajisje standarde',
'16575694':'Automjeti:',
'-846486931':'të dhëna',
'-1615816320':'të dhëna',
'1415114012':'të dhëna',
'1802294807':'fotografi',
'1122888753':'fotografi',
'53347610':'<a rel="noreferrer noopener" target="_blank" classname="Link" href="https://www.cebia.cz/pruvodce?id=204&do=downloadFile">të dhënave personale</a>',
'1802705032':'<a classname="Link Link--lightBlue" href="/detailArticle/where-do-i-find-the-vin-code" target="_blank" rel="noreferrer noopener">Më shumë informacione</a>',
'883512613':'Raporti i historisë Auto-tracer - Kontrolloni automjetin online',
'-1965309318':'Kontrolloni online historinë e automjetit në bazën më të madhe të të dhënave të automjeteve të përdorura në Evropë. Kontroll i dëmtimit dhe kilometrazhit, historia e shërbimit, fotot dhe shumë informata të tjera.',
'-1871315210':'Mënyrat e pagesës',
'-1803227447':'Ju lutem zgjedhni mënyrën e duhur të pagesës',
'-1249181167':'Lajmërime - Historia e veturës',
'-838385662':'Ju mund të gjeni informacione të rëndësishme në lidhje me kontrollin dhe blerjen e makinave të përdorura, shmangien e mashtrimeve dhe shumë informata të tjera në blogun tonë.',
'1154868985':'Pyetje të shpeshta - Historiku i automjetit',
'908158246':'Shikoni pyetjet më të shpeshta në lidhje me historinë e makinave Cebia nga klientët tanë. Nëse keni ndonjë pyetje tjetër, mos ngurroni të na kontaktoni.',
'-633966577':'Rreth historisë së veturës',
'1171158673':'Mësoni më shumë rreth sistemit Auto-tracer, bazën e të dhënave dhe pse përdoret për të kontrolluar historinë e automjeteve nga miliona klientë në të gjithë Evropën.',
'-1244282008':'Politika e Cookies',
'-792206035':'Kjo faqe përdor lloje të ndryshme të Cookies. Disa Cookies vendosen nga shërbimet e palëve të treta. Ju mund të mos pranoni përdorimin e tyre në çdo kohë.',
'1489309005':'Qasje pa pagesë - sipas kushteve të përcaktuara në kontratë',
'-470675791':'Kam kontrolluar dhe konfirmoj informatat më lartë.',
'2108896817':'Anuloni këtë zbritje',
'6892736':'Ky kod i zbritjes nuk është më valid',
'-1199844274':'Kodi nuk është valid!',
'-552424253':'Hyrja në raportin e paguar tarifohet sipas kontratës',
'167769730':'Pagesa me para në dorë',
'1768026018':'Raporti i ID pritohet nga ana jonë, nëse ju paguani me para në dorë në zyret qendrore të Cebia, spol. s r.o.,',
'820786512':'Kodi është aplikuar dhe çmimi është rillogaritur',
'-279227794':'Kontrollo vitin e prodhimit',
'1470281039':'Kontrollo për lizing/kredi në Republikën Çeke',
'60776637':'Shkruaj ID-në e raportit',
'529459564':'Ju lutem shkruani ID-në e raportit tuaj',
'-861194224':'Inspektimi i automjetit',
'2076412240':'E re',
'360601996':'ID e raportit tuaj nuk është më valide',
'1762078183':'Materiali',
'822990672':'Duke klikuar këtë buton, ju shfrytëzoni mundësinë për të bërë 1 pyetje falas, nga totali i pyetjeve i cili është caktuar sipas kontratës.',
'532528312':'i përdorur',
'-319106355':'Automjeti',
'935795728':'Raport shembull',
'-916000765':'Raporti i historisë së makinës',
'-1721948126':'Shembull i raportit të historisë së makinës - kontrolloni historinë e automjetit në internet në bazën më të madhe të të dhënave të automjeteve të përdorura në Evropë.',
'329882651':'Duke shtypur këtë buton, ju do të faturoheni për paraqitjen e informacioneve të detajuara sipas kontratës efektive.',
'-494157101':'Fatura',
'-465994559':'Imazhet',
'857656094':'Kodi i prodhimit:',
'-2005319583':'Modeli i automjetit:',
'339937023':'Viti i regjistrimit të parë sipas çertifikatës teknike:',
'-441771827':'Duke ngarkuar të dhënat',
'-161761801':'nuk u gjetën të dhëna',
'1229398238':'PARALAJMËRIM: Çertifikata Cebia nuk mund të krijohet. Automjeti është regjistruar si <strong className="bolder">i vjedhur</strong>.',
'358386818':'Mosha e makinës:',
'-701699977':'Raporti Cebia nuk mund të krijohet përshkak se numri VIN nuk mund të gjindet. Kontrolloni VIN kundrejt dokumenteve të automjetit ose kontaktoni shërbimin ndaj klientit.',
'-1359814132':'Foto e radhës',
'340541220':'Zmadhoni',
'2096545526':'Zvogëloni',
'-158382161':'Foto e mëparshme',
'-749344895':'Zbritje',
'-1755324145':'Modeli',
'1747292041':'Smart code',
'1093009871':'Chytrá komunikace pro moderní autocentra',
'-421925379':'Chcete u svých vozidel propagovat, že jsou prověřená?',
'-303554559':'Vendosni një kod Smart në çdo automjet dhe klienti do të shkarkojë vetë raportin Auto-tracer.',
'5128661':'Úplně jednoduše. Cebia Smart kód vytisknete v systému AUTOTRACER (ve formátu A5) a umístíte za sklem prodávaného vozidla.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Informacione të tjera (një deklaratë e plotë nga sistemi AUTO-TRACER) më pas mund të kërkohen drejtpërdrejt nga ju.',
'-566386963':'Ju lutemi plotësoni këtë formular dhe ne do t\'ju përgjigjemi.',
'-1639125181':'Dërgo',
'76421921':'Artikuj të detyrueshëm',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Máte zájem tisknout u vašich vozidel Smart kódy? Kontaktujte naše obchodní oddělení:',
'2094660384':'Petr Kala, vedoucí obchodního oddělení',
'361077673':'Ose plotësoni formularin dhe ne do t\'ju kontaktojmë:',
'1277961418':'Opravdu chcete vygenerovat Smart kód?',
'-2133968142':'Unë kam një kod zbritje',
'-1922950659':'Mënyra e pagesës',
'-2072875261':'Vetura është duke u kontrolluar',
'783887791':'Porosia',
'-1499237034':'Falas',
'-1854208328':'Aplikoni kodin e zbritjes',
'-793343279':'Dhuratë',
'-1393236672':'Kosovë',
'1342791550':'Historia e kilometrazhit',
'2063498881':'Pajisjet opsionale',
'-152116623':'QR – Smart kód',
'-956949153':'Kostoja e riparimit',
'-552950132':'të dhëna',
'30639535':'të dhëna',
'-1164317619':'Jo',
'1402944789':'Ju lutemi shkruani informacionet e kërkuara',
'-1812102598':'PARALAJMËRIM: Odometri nuk mund të kontrollohet. Kategoria do të ketë rezultatin “mungesë të dhënash”.',
'666398003':'Paralajmërim!',
'771644825':'VIN-i i shënuar mund të jetë i pasaktë!',
'-1131044763':'Më shumë informata?',
'-2054470344':'Numri VIN i shënuar mund të jetë i pasaktë!',
'-1369269346':'Një gabim i mundshëm është gjetur në numrin VIN të shënuar. Ju lutemi kontrolloni nëse VIN-i i shënuar përputhet me numrin VIN të stampuar në veturë ose në çertifikatën teknike.',
'-1625517200':'Ku mund ta gjeni VIN-in?',
'1171142400':'Pagesa dështoi. Ju lutemi, provoni përsëri.',
'-1192484737':'Nuk mund të vazhdoni pa pranuar Termet dhe Kushtet',
'-247455599':'Ju lutemi shkruani ID-në e kompanisë tuaj',
'-1944712721':'Numri minimal i karaktereve është 12',
'1664270996':'Shteti',
'-862797995':'Gjendja e veturës siç është regjistruar kur është reklamuar. Ju lutemi shikoni dokumentacionin fotografik të bashkangjitur për dëmtimet e mundshme.',
'-1997376096':'Sllovaki',
'-1344951568':'Regjistri i veturave',
'-644036535':'Baza e të dhënave',
'-426412387':'Regjistri Kombëtar i Veturave',
'1736003883':'Të dhënat e çertifikatës teknike',
'1610817679':'Të dhënat e odometrit janë të disponueshme për veturën. Në raportin e Historisë së Veturës ne do të zbulojmë leximet e disponueshme të odometrit për periudhën kohore të specifikuar më sipër. Të dhënat mund të përdoren për të përcaktuar nëse kilometrazhi është i saktë ose nëse odometri i veturës është manipuluar.',
'1669009738':'Vetura e kërkuar është reklamuar ose foto dokumentuar. Në raportin e Historisë së Veturës, mund të shikoni detajet e reklamave, leximet e odometrit, fotot e veturës dhe, nëse është e aplikueshme, çmimin e reklamuar. Ju lutemi vini re se sasia e informacionit mund të ndryshojë nga një regjistrim reklamash në tjetrin.',
'1518701163':'Ne e dimë moshën e veturës dhe/ose origjinën e tij. Në raportin e Historisë së Veturës mund të kontrolloni datën e prodhimit të veturës, datën e regjistrimit të parë, datën e regjistrimit të veturës në Republikën Çeke dhe informacionin në lidhje me vendin për të cilin vetura është prodhuar fillimisht. Ju lutemi vini re se shtrirja e informacionit në raportin e Historisë së Veturës mund të ndryshojë nga vetura në veturë.',
'1816609203':'Kur bëhet fjalë për shitje te veturave jasht vendit, viti i prodhimit shpesh modifikohet!',
'-900812009':'Një veturë e vjedhur mund të konfiskohet nga policia pa asnjë dëmshpërblim!',
'-976941031':'Kontrolla për taksi nuk ofrohet për këtë veturë.',
'444450829':'deri',
'982955181':'12 regjistra',
'-1739651968':'Kontroll i sigurisë së veturës',
'-167461646':'Kontrolli i historisë së veturës',
'-1365221853':'Numri maksimal i karaktereve është 14',
'1302766369':'Vlerësimi i mjetit nuk është kryer. Vlerësimi mund të bëhet gjatë periudhës së vlefshmërisë së kuponit duke futur të dhënat e mjetit (datën e regjistrimit të parë dhe statusin aktual të shpejtësimatësit) në faqen tonë të internetit pas futjes së ID-së së Raportit.',
'1881868699':'Blerje për biznes',
'631216697':'Ju lutemi shkruani emailin tuaj.',
'945011940':'Të dhënat tuaja',
'-401861769':'Email',
'1441058305':'Nëse plotësoni numrin e TVSH-së apo numrin Fiskal, ne do të përpiqemi të plotësojmë të dhënat e mbetura nga databaza zyrtare.',
'-1181757195':'Ne do t\'ju dërgojmë një link për të parë informacionin rreth historisë së veturës suaj dhe një dokument tatimor në emailin e shënuar pas pagesës.',
'-1768425519':'Nëse dëshironi të plotësoni të dhënat tuaja të faturimit (kompania, adresa, numri i TVSH-së) në dokumentin tatimor, ju lutemi t\'i vendosni ato në fushat më poshtë. Nëse plotësoni numrin fiskal, ne do të përpiqemi të plotësojmë të dhënat e mbetura nga databaza zyrtare.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Cebia REPORT ofron një vlerësim të kontrolleve të gjendjes së shpejtësimatësit, kontrolleve të bazës së të dhënave të automjeteve të vjedhura, detyrimeve ndaj kompanive të qiradhënies, VIN dhe kontrolleve të vitit të prodhimit të automjeteve. Vlerësimi i përgjithshëm shprehet si një numër yjesh (0-5) dhe një vlerësim verbal.',
'308350422':'Totali me TVSH:',
'-24797068':'Ju keni të drejtë për një zbritje nëse:',
'-1323263000':'Ju keni një shërbim FLEX me parapagesë me ne dhe do të përdorni kodin e treguar në dokumentin tatimor.',
'-724069837':'Ju keni një kupon zbritje që ju jep të drejtën për një blerje falas.',
'-127346860':'Ju keni një kupon nga një prej partnerëve tanë.',
'-47884841':'pa TVSH',
'881014685':'Totali pa TVSH',
'-15294371':'Numër i pakonfirmuar i TVSH-së',
'1286068311':'Baza e të dhënave zyrtare e paguesve të TVSH-së nuk e ka konfirmuar numrin tuaj të TVSH-së. Prandaj, ne nuk mund të plotësojmë automatikisht të dhënat tuaja. Ju lutemi kontrolloni numrin tuaj të TVSH-së ose plotësoni manualisht detajet e kompanisë suaj.',
'-172351568':'Baza e të dhënave nuk është duke funksionuar',
'1839952435':'Baza zyrtare e të dhënave të paguesve të TVSH-së nuk është duke funksionuar. Ju lutemi plotësoni manualisht detajet e kompanisë suaj.',
'1678298800':'Raport shembull',
'-654354054':'Sasia e informacioneve në dispozicion ndryshon nga vetura në veturë',
'-1069736045':'portale evropiane  <label classname="color-orange">të reklamave</label>',
'1068440731':'deri <label classname="color-orange">12 regjistra</label>',
'-534710218':'Origjina dhe mosha e veturës',
'1267127794':'Të gjitha kontrollet e listuara në këtë faqe mund të kryhen për veturën e specifikuar bazuar në kodin VIN. Rezultatet e kontrolleve do të jenë të disponueshme në raportin e paguar. Sasia e informacionit që përmban raporti mund të ndryshoj për çdo veturë.',
'-186613120':'Nuk është përdorur si taksi në Republikën Çeke',
'1880784559':'Ofrohet duke vendosur numrin e regjistrimit të veturës',
'2085931506':'<label classname="color-orange">Në dispozicion</label>',
'-1765351165':'më shumë se <label classname="color-orange">200 milionë të dhëna</label>',
'1815202337':'të dhëna nga <label classname="color-orange">BE dhe SHBA</label>',
'-555727222':'të dhëna nga  <label classname="color-orange">18 vende</label>',
'-1798142062':'Tabela e të dhënave të odometrit',
'-1828887347':'VIN (numri identifikues i veturës):',
'1181611011':'Ky listim është vetëm për informacion. Informacioni i dhënë rregullohet nga "Kushtet e përgjithshme për përdorimin e sistemit Cebia REPORT".',
'945789861':'Për të kontrolluar nëse vetura përdorej si taksi në Sllovaki, shkruani numrin e tij të regjistrimit në <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br>',
'-765158573':'Kontrolloni kodin VIN tani',
'-1620583517':'Historia',
'239060530':'Historia e kontrollit VIN',
'1122350112':'Një përmbledhje e historisë së të gjitha pyetjeve në lidhje me VIN-të e verifikuara që nga data e aktivizimit të kuponit.',
'-1288575114':'Nuk është kontrolluar',
'-14589513':'Klienti',
'-1170545783':'Verifikuar nga ju',
'452068758':'Partner kontraktual i Cebia',
'-1710315139':'Raporti i hisorisë së veturës përmban të dhëna rreth historisë së veturës. Në të shumtën e rasteve, të dhënat janë nga odometri, dëmtimet e veturave, kontrollimi teknik, fotografitë apo të dhëna nga reklamat apo shpalljet rreth veturës. Më shumë informata mund të gjeni në <a href="/payed/detail/exampleAL">raport</a>. ',
'1607430837':'Pasqyra e historisë',
'-293598683':'Data e çregjistrimit të automjetit',
'-1790772829':'Data e regjistrimit të parë',
'743843679':'Data e regjistrimit',
'1370884754':'Regjistruar si',
'644449918':'Ngjyra e veturës në regjistrim',
'-583571734':'Data e prodhimit:',
'-1219924366':'Vjetërsia e veturës',
'1595420674':'Vetura më këto pajisje',
'1151043140':'Koha midis datës së prodhimit dhe datës së regjistrimit të parë',
'708774908':'Shqipëria',
'1637995084':'Maqedonia e Veriut',
'1001888760':'Dëmit total ekonomik *',
'1034299871':'Ngjarja e siguruar – dëmit total ekonomik*',
'1209738229':'Ngjarja e siguruar',
'913822268':'Shënim',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Magjistralja Prishtinë Ferizaj KM6',
'-1213415008':'10500 Graçanicë, Kosovë',
'1766887079':'812112887',
'963828375':'330585789',
'-163538958':'Carvago by Gjirafa LLC',
'-1996707559':'Duke përfunduar blerjen, unë pranoj <a href="https://www.cebia.cz/pruvodce?id=203&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">termat dhe kushtet</a> dhe mbaj parasysh <a href="https://www.cebia.cz/pruvodce?id=204&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">parimet e përpunimit të të dhënave personale</a>.',
'-983289066':'Bashkëpunim me Gjirafa',
'-1915066431':'Ne kemi zgjeruar ofrimin e shërbimeve tona në Kosovë, Shqipëri dhe Maqedoninë e Veriut në bashkëpunim me Gjirafa.',
'-427869571':'Data e regjistrimit të parë të dhënë kur regjistroheni',
'280331577':'Koha midis datës së prodhimit dhe datës së regjistrimit të parë',
'1163480388':'Nuk ka të dhëna të disponueshme në bazat e të dhënave të disponueshme.',
'-1319845523':'Kemi regjistruar një përllogaritje dëmi të krijuar nga një kompani sigurimesh apo një servise makinash, për të cilën nuk dihet detaji (vendi i dëmtimit). Ju lutemi kushtojini vëmendje inspektimit të automjetit.',
'779148402':'Kemi regjistruar një ngjarje të raportuar nga kompania e sigurimit, për të cilën nuk dimë detaje (vendin e dëmtimit). Ju lutemi kushtojini vëmendje inspektimit të automjetit.',
'1115915387':'Transporti falas',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'I pa verifikuar',
'-1464599260':'Ndërhyrjet',
'-592361226':'Lyerja',
'1085310093':'Lloji i kontrollit:',
'1984850101':'Data e kontrollit',
'-1502399543':'Shiko regjistrin',
'-1961590845':'Baza e të dhënave është aktualisht e padisponueshme, ju lutemi provoni përsëri më vonë.',
'-1065211891':'Protokolli nuk është i disponueshëm',
'1708745339':'Protokolli nuk është i disponueshëm',
'1087254174':'Merrni raportin e plotë',
'2096754301':'Raporti bazë nga:',
'926095479':'Çfarë është raporti bazë i automjetit?',
'-254140743':'Raporti bazë përmban kontrollet e mëposhtme:',
'1362304971':'Për të kontrolluar të gjithë historinë e automjetit, ju rekomandojmë të blini Kontrollin e plotë të automjetit, i cili mund të përfshijë gjithashtu <b>kontrollin e dëmtimit, historinë e reklamave, fotot e automjetit, historinë e shërbimit</b> dhe shumë më tepër.',
'-267677252':'Inspektimi bazë i automjetit',
'-70564196':'- 10 EUR',
'1350105297':'A jeni i kënaqur me rezultatin e kontrollit të historisë së automjetit? Ne do ta vlerësonim vlerësimin tuaj.',
'1914051118':'Rishikimi',
'1786612140':'Na vjen keq që kontrolli i historikut të automjetit nuk i përmbushi pritjet tuaja. Ju lutemi na tregoni se çfarë duhet të përmirësojmë për herën tjetër.',
'-1265184280':'Dërgimi dështoi. Ju lutemi provoni përsëri.',
'-669850784':'Numri maksimal i karaktereve është 2000.',
'1801814710':'<strong classname="color-green">Faleminderit!</strong> Rishikimi juaj është dorëzuar dhe do të na ndihmojë të përmirësojmë më tej shërbimet tona.',
'-883106932':'të dhëna nga më shumë se <label classname="color-orange">32 vende</label>',
'1408314893':'Auto-tracer by Gjirafa mbledh të dhëna për vetura nga rreth 32 shtete. Të dhënat merren nga regjistrat zyrtarë, shitësit e veturave, baza e të dhënave të policisë, të dhënat e veturave të dëmtuara, kompanitë e sigurimeve apo njoftime nga portale të ndryshme.',
'-1412306493':'Po. Çdo veturë kontrollohet në databazën e më shumë se 32 shteteve (përfshirë Gjermaninë, Danimarkën dhe SHBA-në). Pasi të keni shënuar VIN-in, mund të shihni se çfarë të dhënash janë gjetur në databazën tonë dhe në cfarë date janë këto regjistrime.',
'1798507708':'Në këtë rast, ne nuk kemi gjetur të dhëna për dëmtimin e veturës që po kontrollohet në databazat tona që përmbajnë mbi 200 milion të dhëna rreth aksidenteve nga 32 vende Europiane dhe SHBA-të. Ky fakt, rrit ndjeshëm mundësinë që vetura e cila po kontrollohet të mos jetë e aksidentuar në të kaluarën.',
'568601301':'Të dhëna për vetura nga më shumë se <strong>32 shtete</strong>',
'899127599':'32 shtete',
'-1456368295':'Historia e veturës nga 32+ shtete',
'1333238000':'Mbi 30 milion vetura të kontrolluara',
'426416063':'Të dhëna prej 32 shteteve',
'-1549476292':'A kontrollon sistemi jonë çdo VIN që shkruani në bazë të të dhënave të automjeteve të dëmtuara? Baza jonë e të dhënave përmban të dhënat e mbi 200 milionë automjeteve të dëmtuara nga 32 vende. Shumica e këtyre të dhënave (62%) vijnë nga Franca dhe Gjermania. ',
'-924882043':'Në bazën e të dhënave, që përmban të dhëna nga më shumë se 32 vende dhe afërsisht 200 milionë regjistrime rreth dëmtimeve, u gjet një ose më shumë se një rast aksidenti të veturës.',
'-1366090835':'Asnjë të dhënë për aksidente të veturës të kontrolluar nuk u gjet në bazën e të dhënave, e cila përmban 200 milionë deklarime rreth aksidenteve, nga më shumë se 32 vende.',
'931273814':'Historia e veturës nga 32+ shtete',
'-334327155':'Ne do ta kontrollojmë veturën në bazën e të dhënave të veturave të dëmtuara nga më shumë se 32 vende europiane dhe Shtetet e Bashkuara, duke përfshirë më shumë se 200 milionë të dhëna të aksidenteve. Në raportin e Historisë së Veturës do të gjeni nëse vetura është regjistruar si e dëmtuar në të kaluarën. Mund të përfshihen gjithashtu informacione të hollësishme si data e dëmtimit, statusi i odometrit, shkala e dëmtimit, kostoja e riparimit ose fotografitë e dëmtimit.',
'2078676994':'Kontrollet e shërbimit',
'503491296':'OK',
'1704037376':'Anulo',
'-922638497':'kontrolluar',
'-2077712313':'deri në 12 regjistra',
'-855779034':'Kontrolli është <strong classname="ml-5">kryer</strong>',
'131879595':'Kontroll automjeti <strong classname="ml-5 mr-5">me pagesë</strong> sipas kontratës',
'-2034839108':'PDF',
'2049708688':'Nuk ka të dhëna',
'1916268628':'Holanda',
'-1425396841':'Për ne',
'-1521903902':'Protocol ID',
'1485582239':'Formati i gabuar i emailit',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'Rreth pagesës',
'1275361495':'Ju keni pranuar një kupon për një kontroll falas të automjetit. Ju mund ta shfrytëzoni zbritjen duke shënuar ID-në e raportit në fushën e kodit të zbritjes, në aplikimin e radhës.',
'-675647872':'Në të gjitha rastet e mësipërme, më pas do të futni ID-në e Raportit në fushën përkatëse në <a href="/">faqen kryesore</a> dhe menjëherë do të keni akses në informacionin e blerë, gjë që do të keni mundësi ta ktheni në çdo kohë brenda 30 ditëve.',
'1080548025':'PARALAJMËRIM: Ky mund të jetë një <strong>VIN i gabuar</strong>. Kontrollo VIN-in ose <a href="mailto:rokvy@cebia.cz?subject=Mbështetje informacioni për identifikimin e sistemit ATR&amp;body=VIN: {0}">Kontakto mbështetjen</a>.',
'-439637411':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Nenalezen rok výroby. ',
'-43065820':'Nuk janë gjetur të dhëna për vjetërsinë apo origjinën e automjetit. Nëse automjeti vjen nga jashtë, gjithmonë kërkoni dhe kontrolloni me kujdes dokumentacionin origjinal për automjetin (p.sh. librin e servisit ose librezën e automjetit).',
'-1362534546':'Nëse automjeti është reklamuar ose inspektuar në të kaluarën, ne do të shfaqim informacione dhe dokumentacion fotografik.',
'431007496':'Regjistro',
'-1525511285':'Të dhënat e regjistrimit të veturës merren nga autoritetet kombëtare dhe pasqyrojnë statusin kur vetura është regjistruar për herë të parë në vend.',
'1151204976':'VIN (Numri i identifikimit të veturës) është një kod unik 17-shifror që identifikon në mënyrë të qartë dhe të dallueshme çdo veturë. Duke deshifruar VIN-in, ju mund të mësoni specifikat e veturës dhe të kontrolloni historinë e tij. Kontrolloni nëse VIN i stampuar në veturë dhe ai i vendosur fizikisht janë identik me atë që gjendet në librezë. Dallimet e rëndësishme midis të dyjave mund të tregojnë një ndryshim të mëparshëm (shpesh të paligjshëm) të identitetit të veturës.',
'205732919':'VIN është një kod 17-shifror që identifikon në mënyrë unike dhe pa dyshime çdo veturë.',
'1905716471':'*) Përkufizimi i <strong>Dëmit total ekonomik</strong> mund të ndryshojë nga një vend në tjetrin të BE-së, por edhe nga një kompani sigurimi në tjetrën brenda të njëjtit vend. Edhe nëse një dëm i caktuar vlerësohet si dëm total, kjo nuk do të thotë domosdoshmërisht se vetura është i papërshtatshëm për rrugë apo edhe riparim. Humbje ekonomike është dëmtimi i një veture ku sipas kushteve të kompanive të sigurimit, është joekonomike të riparohet vetura (= shuma për të riparuar veturën është afër ose më e madhe se vlera e veturës në kohën e dëmit) ose vlerësohet si i tillë sipas kushteve të policës së sigurimit.',
'-199845800':'Numri duhet të përmbaj 10 karaktere.',
'1657920051':'Numri maksimal i karaktereve është 18.',
'-1030611916':'Numri maksimal i karaktereve është 6.',
'1439047896':'VIN (Numri i identifikimit të veturës) është një kod unik 17-shifror që identifikon në mënyrë të qartë dhe të dallueshme çdo veturë. Duke deshifruar VIN-in, ju mund të mësoni specifikat e veturës dhe të kontrolloni historinë e saj. Kontrolloni nëse VIN i stampuar në veturë dhe ai i vendosur fizikisht janë identik me atë që gjendet në librezë. Dallimet e rëndësishme midis të dyjave mund të tregojnë një ndryshim të mëparshëm (shpesh të paligjshëm) të identitetit të veturës.',
'1682180910':'<p>Në bazat e të dhënave të disponueshme, nuk kemi të dhëna për dëmtimin e automjetit të verifikuar. Numri i identifikimit të makinës (VIN) që keni dhënë mund të jetë i pasaktë.</p><p>Njoftim: Sistemi Auto-tracer nuk mund të ketë në dispozicion informacione për të gjitha dëmet e automjeteve, disa dëme nuk raportohen tek kompanitë e sigurimeve ose nuk janë të disponueshme në kuadër të verifikimit në internet.</p>',
'-1276466490':'VIN-i i dhënë mund të jetë i pasaktë',
'-1892907546':'Asnjë regjistrim historik nuk mund të gjendet për këtë VIN.',
'1843204209':'Kontrolloni megjithatë',
'-716345220':'Kontrolloni një VIN tjetër',
'1091884080':'Kemi këshilluar të kontrolloni VIN-in',
'-287497170':'Kontrolli i pronësisë',
'1289833880':'Statusi i pronësisë u gjet',
'466639297':'Statusi i pronësisë nuk u gjet',
'-577812113':'ID e zbatimit',
'231435384':'Kontrolli i të drejtave të ngarkimit nuk është momentalisht në dispozicion. Provoni të rifreskoni faqen pas disa minutash.',
'-941220732':'Automjeti është subjekt i një ose më shumë të drejtave të hipotekës në shtetet e përmendura dhe ekziston mundësia e sekuestrimit të tij në rastin e një procesi ekzekutiv të personit të përmendur në regjistrin e hipotekave. Blerja e një automjeti me të drejtën e hipotekës mund të jetë shumë e rrezikshme. Të dhënat i marrim nga regjistrat zyrtarë të hipotekave të vendit të caktuar.',
'2016489332':'Rezultati i kërkesës është të konstatohet nëse mjeti nuk është subjekt i ndonjë ngarkese në shtetet e përmendura dhe, kështu, nuk ka rrezik se ai do të konfiskohet në rast se ka procedurë ekzekutive potenciale kundër pronarit aktual. Të dhënat i marrim nga regjistrat zyrtarë të ngarkesave të vendit të përcaktuar.',
'1736313716':'Bashkangjitur ne artikullin',
'1021804797':'Raporti falas i historisë së veturës brenda rregullave të përcaktuar në kontratë.',
'749816957':'Mund të kontrollohet',
'981194702':'Kontrolli i konfiskimeve',
'-1417536272':'Kontrolli i pronësisë',
'-323399576':'Nëse dyshoni se në raport janë përfshirë informacione të pasakta, ju lutemi kontaktoni shërbimin ndaj klientit në <a href="mailto:autotracer@gjirafa.com">autotracer@gjirafa.com</a>.',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Pyetja është për servisin',
'1845072895':'ID e raportit (është e nevojshme për një kërkesë)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Shiko raportin',
'1269795688':'Základní prověření',
'1521773717':'Punonte si taksi në',
'-137639801':'Report ID (tregoni në rast ankese)',
'-1619027976':'Shënim: Mjeti mund të mos jetë përdorur si taksi gjatë gjithë kohës.',
'-1010997259':'Për biznesmenët',
'503941074':'Informacioni dhe të dhënat e detajuara për veturën e kontrolluar do të jenë në dispozicion pas pagesës.',
'1088246106':'Për veturën e cekur, ne kontrollojmë:',
'-963915529':'Dëmtimi i mjetit',
'-1603961223':'<label classname="color-orange">Në dispozicion</label>',
'-1940014640':'Të dhënat teknike',
'1785662376':'Servisimet',
'1913348655':'Dëshironi të verifikoni më shumë makinave në një çmim më të ulët? Merrni qasje për biznesin dhe <b>shpëtoni deri në 50 %</b> nga çmimi i verifikimit. Ju lutemi na jepni informacionin tuaj kontaktues dhe ne do t\'ju kontaktojmë me një ofertë të personalizuar.',
'1723999193':'Në dispozicion pas pagesës',
'1593405846':'Pojištění vozidla',
'670508858':'Për blerje mund të përdoret vetëm një kod zbritje. Kodet e zbritjes nuk mund të shtohen ose kombinohen. Zbritja vlen vetëm për Auto-tracer.',
'1693716169':'Mënyra e pagesës',
'-503153747':'Auto-tracer',
'-1127924333':'Základní výpis',
'-946840675':'Rishikimi i reklamës së mëparshme',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'50% SLEVA na další prověření',
'-1870036897':'Ju keni kontrolluar tashmë një automjet dhe duke futur ID-në e Raportit (të dhënë në emailin që keni marrë) brenda 30 ditëve nga blerja, përfitoni një zbritje për kontrollin e radhës.',
'163946327':'Për çdo aplikim rreth raportit të historisë së veturës ju do të pranoni një zbritje në vlerë 20 % për raportin tjetër. Gjatë aplikimit për raportin tjetër, ju mund ta aplikoni zbritjen duke shënuar ID-në e raportit paraprak në kutinë "Kuponi i zbritjes" i cili gjendet te "Pagesa".',
'858336732':'50% zbritje në arkën e radhës',
'214251926':'Me blerjen e një kontrolli, ju automatikisht përfitoni një zbritje për kontrollin e radhës të çdo automjeti tjetër. Zbritja vlen për 30 ditë nga blerja.',
'1317784962':'10% zbritje në arkën e radhës',
'641443144':'20% zbritje në arkën e radhës',
'-1318469146':'30% zbritje në arkën e radhës',
'1939643950':'40% zbritje në arkën e radhës',
'-1376459678':'Një tjetër kontroll <br> <b>me zbritje!</b>',
'-937620931':'Të dhënat e automjetit',
'514768619':'Ne verifikojmë vërtetësinë e VIN',
'-916328171':'Jemi duke verifikuar identitetin e mjetit',
'1481465091':'Ne kontrollojmë pajisjet e automjetit',
'-276782700':'Ne kërkojmë bazën e të dhënave të automjeteve të dëmtuara',
'1544438189':'Ne kontrollojmë të dhënat nga kompanitë e sigurimeve',
'2021669548':'Ne kërkojmë historinë e portaleve të ankandeve',
'2022162632':'Ne kontrollojmë të dhënat nga shërbimet e autorizuara',
'1083617557':'Ne kontrollojmë të dhënat nga shërbimet e paautorizuara',
'993080480':'Ne kërkojmë bazat e të dhënave të policisë për automjetet e vjedhura',
'-1240510013':'Ne kërkojmë bazat e të dhënave private të automjeteve të vjedhura',
'-1027936662':'Ne kontrollojmë vjetërsinë e automjetit',
'1299172359':'Ne kontrollojmë origjinën e automjetit',
'413685839':'Kerkojme libra sherbimi elektronik',
'-473666121':'Ne kërkojmë regjistrat shtetërorë të automjeteve',
'-254920359':'Ne kërkojmë bazat e të dhënave shtetërore të automjeteve taksi',
'-811021673':'Ne kontrollojmë kujtimet evropiane',
'1923526539':'Ne kontrollojmë sigurinë e automjetit',
'-1424413327':'Ne kontrollojmë të dhënat e të dhënave të kilometrazhit',
'734665361':'Ne kontrollojmë të dhënat nga kompanitë e lizingut',
'684288091':'Ne kërkojmë të dhënat nga stacionet e kontrollit teknik',
'-1633964825':'Ne kërkojmë të dhënat nga stacionet e matjes së emetimeve',
'-354982402':'Ne kërkojmë historinë e portaleve të reklamave',
'255365524':'Ne kërkojmë bazën e të dhënave të tregtarëve të automjeteve të reja',
'936372740':'Ne kërkojmë bazat e të dhënave të tregtarëve të automjeteve të përdorura',
'-1491634158':'Ne kontrollojmë të dhënat nga kompanitë e makinave me qira',
'-1425645764':'Operacioni mund të zgjasë disa minuta',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Ne krijojmë një raport',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Shpërndaj raportin',
'-2142411619':'Për të shpërndarë raportin e historikut, kopjoni linkun më poshtë.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Ne rekomandojme',
'-1025377658':'artikull',
'-909166524':'artikuj',
'-1998523642':'artikuj',
'-613016004':'Oferta me zbritje',
'1083497926':'Shërbime të tjera',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Raporti i historisë së makinës',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Raporti i radhës',
'-901212011':'për',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Jemi duke kontrolluar fotot, për ekzekutimin do të njoftoheni me e-mail.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Paralajmërim:</span> Informacioni për të gjitha dëmtimet e automjeteve mund të mos jetë i disponueshëm në sistem, disa dëme nuk raportohen te kompanitë e sigurimit ose nuk disponohen si pjesë e një kontrolli në internet. Prandaj, ne rekomandojmë një inspektim të kujdesshëm të automjetit, në mënyrë ideale me ndihmën e një mekaniku të kualifikuar ose në qendrën e shërbimit të zgjedhjes suaj. Një inspektim i tillë mund të zbulojë dëme shtesë ose ta përjashtojë atë.',
'-1943079155':'<span class="warning">Shënim:</span> Nëse fotot nuk korrespondojnë me automjetin që po kontrollohet, ju lutemi na informoni me email <a href="mailto:info.al@cebia.com">info.al@cebia.com</a>.',
'707486310':'<span class="warning">Shënim:</span> Jo të gjitha të dhënat e shërbimit të automjetit mund të jenë të disponueshme në sistem. Ne rekomandojmë krahasimin e të dhënave me librin e shërbimit ose kontaktimin e shërbimit përkatës të autorizuar të markës me një kërkesë për një ekstrakt nga libri dixhital i shërbimit të automjetit.',
'-1997337578':'Pasqyra e një evolucioni të odometrit të automjetit përmban vlerat e marra nga sistemet e informacionit të disponueshëm të partnerëve bashkëpunues. Sipas zhvillimit të grafikut, mund të gjykoni vetë nëse ekziston dyshimi për manipulim të statusit të odometrit apo jo.',
'-282194273':'Rekomandim',
'-1564285314':'Jo të gjitha të dhënat e gjendjes së odometrit nga historia e funksionimit të automjetit mund të jenë të disponueshme në sistem.',
'993789697':'Nëse automjeti nuk e regjistron vazhdimisht të gjithë historinë e kilometrazhit me kalimin e kohës, ne <b>rekomandojmë</b> të kontrolloni edhe të dhënat e odometrit në librin e shërbimit, ose ta kontrolloni automjetin në një garazh të autorizuar. Ne e rekomandojmë këtë procedurë veçanërisht për automjetet ku ka një periudhë kohore më shumë se dy vjet midis leximeve të odometrit.',
'2129711518':'Ky është informacion i disponueshëm për parametrat bazë, pajisjet standarde dhe ndoshta shtesë të automjetit nga fabrika. Modifikimet e automjetit si ndryshimi i anës së timonit mund të zbulohen duke kontrolluar pajisjet.',
'-593531916':'<span class="warning">Paralajmërim:</span> Në disa raste, pajisjet e plota të automjetit mund të mos jenë të disponueshme në sistem.',
};

const TRANSLATIONS_IT = {
'1901555939':'TELAIO',
'-971921755':'Marchio di fabbrica',
'1891129765':'Modello',
'691501523':'Tipo di carrozzeria',
'-1026019663':'Tipo di veicolo',
'274952045':'Carburante',
'-348535418':'Potenza',
'1000727131':'Volume',
'-440181428':'Data di produzione',
'841597003':'Condividi',
'-762872471':'Visualizza',
'-1615835216':'Collegamento',
'-466013687':'Chiudi',
'-1655267775':'Copia link',
'-1448849529':'È possibile controllare l\'auto senza conoscere il TELAIO?',
'-1310016716':'Verifica del veicolo ',
'1410745983':'online',
'-1803530129':'Vygenerovat Cebia REPORT',
'934563996':'Risultato del controllo',
'-1102433484':'Esempio di Report',
'-1242381959':'Storia del veicolo',
'417677035':'Controllo amministrativo del veicolo',
'-115937358':'Condizioni del veicolo',
'457834942':'Domande frequenti',
'226942208':'Che cos\'è il TELAIO?',
'1739046820':'Quanto costa il controllo?',
'16204687':'Come posso pagare?',
'-1607701055':'Come si visualizza il Report?',
'-1969713941':'È possibile presentare un reclamo sul Report?',
'1598751038':'Dove posso trovare l\'ID del Report',
'-1206763576':'Ulteriori informazioni',
'-729900752':'Pagamento online',
'671435147':'Inserire il numero del codice sconto',
'-2004947080':'Inserire Report ID',
'-1083354004':'RICALCOLO',
'-1589008349':'Nome',
'-704955254':'Cognome',
'-1631818401':'Via e numero civico',
'1006101298':'Città',
'1063186093':'Paese',
'287700219':'CAP',
'-1411201334':'Numero di identificazione fiscale',
'513366821':'Numero di identificazione fiscale',
'-102071178':'Nome della società',
'-1882309597':'Numero di identificazione della società',
'-1515081676':'ID AZIENDA:',
'1440993804':'Email',
'-1405758046':'Telefono',
'1366947192':'Numero di telefono',
'-490851561':'20 % di sconto sul prossimo acquisto',
'-108743580':'€',
'786987358':'Acquista',
'-1887658751':'I risultati sono disponibili subito dopo il pagamento',
'-1018049108':'I risultati sono solitamente disponibili entro 60 minuti',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Bonifico bancario',
'-1663277':'I risultati saranno disponibili entro 48 ore',
'1626540224':'V hotovosti v sídle Cebia',
'72162446':'Controllo del veicolo',
'757687806':'Panoramica delle informazioni gratuite',
'1954205527':'Dove posso trovare il numero di TELAIO?',
'1603459473':'Inserire Report ID',
'2038366987':'Visualizza il Report',
'-374549211':'Dove posso trovare l\'ID del Report?',
'1262814082':'Inserire TELAIO',
'-15600132':'Data della prima immatricolazione',
'1535680605':'Report ID:',
'-1091217450':'Validità del Report fino a:',
'-1264594150':'Data di attivazione del Report:',
'-701611594':'Siamo spiacenti, purtroppo il veicolo non può essere verificato.',
'160166931':'L\'attuale proprietario del veicolo non desidera visualizzare i dettagli del veicolo.',
'-351255422':'L\'annuncio che state visualizzando non riporta il TELAIO del veicolo.',
'1704462566':'Il TELAIO completo non è riportato nell\'annuncio del veicolo.',
'366367808':'Quanto tempo è necessario per ricevere un Report?',
'2136729030':'TELAIO:',
'400669011':'Il cliente ha diritto a uno sconto se:',
'-219050562':'Blog',
'1449193326':'Chi siamo',
'1097675154':'Contatti',
'2062322678':'Notizie',
'-1263831198':'Storia dell\'azienda',
'1166836894':'I nostri partner',
'-1532347183':'Media',
'717698781':'Carriera',
'1620687461':'Vozidlo je evidované jako odcizené. Cebia REPORT nelze vytvořit',
'1365729306':'Číslo CR:',
'-89669431':'Anno della prima immatricolazione:',
'2130038984':'Stato del contachilometri:',
'2089870291':'Auto pod kontrolou s aplikací carolina',
'1333652846':'Stáhnout zdarma',
'1347577444':'La lunghezza del TELAIO deve essere di 17 caratteri',
'622759132':'Link copiato',
'1714373031':'L\'auto da verificare può essere soggetta a',
'1227286459':'Campagne di richiamo trovate',
'-477335598':'Campagna di richiamo',
'-343698081':'Nel veicolo controllato non è stata trovata',
'-1536285860':'Nessuna campagna di richiamo',
'-2052297753':'Data di segnalazione',
'1522685090':'Dettaglio',
'444715065':'Più informazioni',
'-394230090':'L\'utente deve accettare i dati inseriti.',
'1224828864':'Valore errato per l\'anno di prima immatricolazione.',
'-470093943':'Inserire l\'anno della prima immatricolazione.',
'1035831822':'Stato del contachilometri non corretto.',
'-1161922611':'Inserire lo stato del contachilometri.',
'-1383225585':'Inserire l\'anno di prima immatricolazione',
'1111232857':'Anno della prima immatricolazione',
'-1998715395':'Inserire lo stato del contachilometri',
'-1962337257':'Stato del contachilometri',
'-1888253249':'Specifiche del modello',
'-339477539':'- 300 Kč',
'917746804':'Non potrebbe essere più semplice:',
'1405508217':'Předběžná konzultace ZDARMA',
'127414665':'Come applicare lo sconto?',
'-2081078087':'VYBRAT TECHNIKA',
'1899967331':'Il numero massimo di caratteri deve essere 20.',
'1699452695':'Chybný kód banky',
'1812875983':'Prosím zadejte položku Číslo účtu.',
'-670727524':'Prosím zadejte položku Číslo pojistné smlouvy.',
'1884544037':'ePojisteni.cz',
'138266606':'Validità fino a',
'1881823014':'Podmínky pro vrácení peněz:',
'-1548724023':'Vozidlo musí být pojištěno minimálně 30 dní',
'1331666705':'Errore durante il salvataggio. Ripetere l\'azione.',
'-1393210532':'Číslo účtu',
'439576396':'Vaše číslo účtu',
'773379637':'Číslo pojistné smlouvy',
'-1281605763':'Uplatnit vrácení peněz',
'-1670752753':'Sleva na prověření v zahraničí',
'1496813111':'- 550',
'-856872708':'Prezzo base',
'616158592':'SCONTO',
'-581170701':'Prezzo dopo lo sconto',
'-437309148':'IVA inclusa',
'-1731075532':'Video',
'-189892310':'Ordinare',
'98918066':'Procedura di ordinazione:',
'-1944610133':'Sconto sul prossimo acquisto',
'-621660650':'Numero del codice sconto:',
'-1845841489':'Scoprire la prossima auto in sconto',
'1171588656':'Sleva na zabezpečení vozidla',
'1440663595':'- 850',
'-1503110723':'Sleva na fyzickou prověrku',
'-675817237':'- 925',
'986037818':'Rilevato danno',
'873964955':'Data',
'790922325':'Calcolo dei costi di riparazione',
'-1825735064':'Tipo di evento',
'486919475':'Parti danneggiate',
'1685506455':'Il veicolo è finanziato',
'2039551191':'Finanziamento non trovato',
'-570695392':'nelle banche dati attualmente disponibili',
'-765744228':'Pubblicità',
'659651744':'Data dell\'inserzione',
'-695638522':'Chilometraggio riportato nell\'inserzione',
'772986188':'Prezzo riportato nell\'inserzione',
'419477574':'Record dell\'età e dell\'origine del veicolo',
'-109775782':'Data di produzione secondo il produttore',
'-218677523':'Prima immatricolazione',
'-340115089':'Prima immatricolazione in Repubblica Ceca',
'1192563560':'Prima immatricolazione in Slovacchia',
'1650211924':'Realizzato per il mercato',
'1812132824':'Lato del guidatore',
'-1229728494':'Materiale (elenco)',
'-1147664160':'Lavoro (elenco)',
'708828581':'Ha operato come taxi',
'284182756':'Il veicolo è registrato come rubato',
'1244175337':'Non registrato come rubato',
'791649880':'Fonte',
'705052471':'In data',
'1239149764':'Controllare gli identificatori',
'-799257304':'Modello:',
'-1964821919':'Data della prima immatricolazione:',
'-1530361098':'Prezzo di un veicolo nuovo',
'68348546':'Prezzo di mercato attuale',
'763547310':'Data della prima immatricolazione',
'1968050624':'Salvare',
'-1300982647':'Elenco delle dotazioni per produttore',
'397739850':'Controllo del colore del veicolo',
'479427038':'Disponibile',
'2038192723':'Controllo di sicurezza del veicolo',
'-757741002':'Descrizione tecnica del veicolo',
'278755414':'Istruzioni per l\'identificazione dell\'auto',
'1529692191':'Colore del veicolo',
'-1171491320':'Identificazione del veicolo',
'1549116932':'Elenco delle dotazioni del veicolo',
'-1985480381':'Altro',
'-1354462940':'CONTROLLARE:',
'-690036295':'Data di immatricolazione:',
'1506895356':'Tipo di sicurezza del veicolo:',
'-749715579':'Codice di marcatura:',
'-329154043':'Colore del veicolo:',
'1441236976':'Informazioni sulla marcatura dei vetri',
'729768522':'Marcatura dei vetri',
'1812345820':'NO',
'847948448':'MÁTE ZÁJEM O ZABEZPEČENÍ VOZIDLA?',
'-1899070354':'Descrizione tecnica - base',
'-953303':'Descrizione tecnica - estesa',
'1237026086':'Valore errato per Tipo di veicolo.',
'-259360107':'Inserire il tipo di veicolo.',
'-1906046398':'Il numero minimo di caratteri deve essere 1.',
'-69524395':'Il numero massimo di caratteri deve essere 30.',
'1663292305':'Compilare il campo Marchio.',
'814923836':'Compilare il campo Modello.',
'-1985695287':'Compilare il campo Definizione dettagliata',
'244480988':'Marchio',
'1602844398':'Definizione dettagliata',
'-1749998805':'Selezionare un veicolo',
'-1162182506':'Tipo di veicolo',
'1104651533':'carta di circolazione - numero di TELAIO',
'-1898506589':'parabrezza auto - numero di TELAIO',
'216861745':'Si prega di riconfermare i dati inseriti!',
'-1328097963':'Il numero massimo di caratteri è 9.',
'1678639740':'Compilare il campo Numero di telefono.',
'1303052228':'Compilare il campo Nome.',
'1792286505':'Il numero massimo di caratteri è 128.',
'2122707327':'Compilare il campo cognome.',
'-938825602':'Il numero massimo di caratteri è 64.',
'1683031395':'Compilare il campo Via e numero.',
'661065202':'Compilare il campo città.',
'-83271898':'Compilare il campo codice postale.',
'1018067916':'Il numero massimo di caratteri è 5.',
'12673416':'Il codice postale ha un formato sbagliato.',
'154576238':'Il Report ID ha un formato errato.',
'-581020701':'è dichiarato obbligatorio per legge dal costruttore del veicolo ogni volta che la salute, la sicurezza e l\'ambiente sono a rischio. In genere si tratta di difetti di fabbricazione del veicolo che si manifestano solo durante la guida e che il costruttore provvede a correggere gratuitamente. Le informazioni sono tratte dal sito web <a href="https://car-recalls.eu/" target="_blank">Car-Recalls.eu</a> con l\'aiuto di RAPEX.',
'1969671182':'Queste azioni di richiamo sono state generate <strong>in base al modello, alla marca e all\'anno del veicolo controllato</strong>, non in base al suo TELAIO. Controllare sempre i dettagli dell\'azione per verificare se si applica anche al veicolo che si sta controllando. Allo stesso tempo, verificare presso il concessionario o un\'officina autorizzata che il veicolo sia stato sottoposto alle campagne di richiamo applicabili. ',
'-2076502822':'Una campagna di richiamo viene dichiarata dal produttore del veicolo su base obbligatoria se il veicolo presenta un difetto di fabbricazione che mette in pericolo la salute, la sicurezza o l\'ambiente.  Nell\'ambito della campagna di richiamo, il guasto viene eliminato gratuitamente presso un centro di assistenza autorizzato. I dati sono forniti da <a href="https://car-recalls.eu/" target="_blank">Car-Recalls.eu</a> con l\'aiuto di RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Uzavřete pojistku prostřednictvím portálu <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> a získejte zpět peníze za prověření vozidla.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'5180415':'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'-312241960':'* I database privati di solito consentono a chiunque di inserire informazioni sul furto.',
'1225691915':'Dai database disponibili è emerso che il veicolo <span class="warning">era o è tuttora gestito come taxi</span>. Controllate accuratamente lo stato tecnico dell\'auto. ',
'-742868638':'Il tuo indirizzo e-mail',
'2033866416':'Pagamento con carta di credito online',
'138706621':'Trasferimento online',
'-1634274740':'Zobrazit Cebia REPORT',
'1047356790':'Inserire la data della prima immatricolazione.',
'-39394372':'Pubblicità di veicoli con una concordanza minore',
'-862383013':'Annunci di veicoli simili',
'-233652021':'STORIA DEL VEICOLO',
'271847434':'Cebia REPORT',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'Při objednání kontroly vozu na Automato.cz vložte do určeného pole v košíku slevový kód <b>AUTOTRACER</b> a sleva vám bude automaticky odečtena z ceny.',
'-1554923364':'(cesta technika zdarma)',
'288042578':'Inserire il nome della società.',
'-1335200216':'Valore n° identificativo errato.',
'1693391088':'Il formato dell\'e-mail non è corretto!',
'-766469985':'Compilare il campo e-mail',
'-1397806656':'Consenso al trattamento dei dati personali',
'-189484387':'Iscriversi',
'1095553640':'Tutte le domande',
'1531150438':'Non avete trovato la risposta?',
'-469136467':'Contatti',
'-461584700':'Prima dell\'acquisto',
'400498991':'Informativa sui cookie',
'1329764956':'Che cos\'è un numero di TELAIO?',
'-394767958':'Acquisto',
'181431424':'Dopo il pagamento',
'1649241229':'Il numero di TELAIO è impresso direttamente sul veicolo, oppure si trova nella carta di circolazione e in altri documenti del veicolo. Se si desidera controllare un\'auto venduta su Internet, chiedere il numero di TELAIO del venditore.',
'1774694482':'Da dove provengono i record dei veicoli?',
'-1122721284':'Cebia controlla anche i veicoli all\'estero?',
'437878122':'Quali informazioni posso trovare sul veicolo?',
'850725814':'La quantità di informazioni varia per ogni veicolo a seconda dell\'età e della storia. È possibile scoprire gratuitamente l\'entità delle informazioni sul veicolo che si intende controllare subito dopo aver inserito il numero di TELAIO nella panoramica delle informazioni trovate.',
'-656971513':'Che aspetto ha il report?',
'966688141':'Il costo del controllo del veicolo dipende dalla quantità e dalla portata delle informazioni disponibili sul veicolo da controllare. Sarà possibile conoscere le informazioni sul veicolo in oggetto e il prezzo del controllo subito dopo aver inserito il numero di TELAIO del veicolo nella panoramica delle informazioni trovate.',
'764893782':'È possibile pagare online o tramite Google Pay e Apple Pay. I pagamenti sono gestiti dal gateway di pagamento sicuro GoPay.',
'726713260':'Fra quanto tempo riceverò il report?',
'1478989945':'Il report viene generato immediatamente dopo la ricezione del pagamento. Il servizio funziona continuamente e l\'intero processo è automatico. ',
'1681038954':'Posso inserire i dati della mia azienda al momento del checkout?',
'696259005':'Quando si compilano i dati, è possibile inserire i dati identificativi dell\'azienda. Tuttavia, dopo il pagamento viene generata solo una ricevuta fiscale semplificata. Per avere una fattura completa, scrivere all\'indirizzo <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>.',
'-1555365601':'Il report viene generato automaticamente subito dopo la ricezione del pagamento. Un link al report e al report ID verrà inviato anche all\'indirizzo e-mail inserito al momento dell\'acquisto. Il report può essere visualizzato per 30 giorni dopo la sua generazione.',
'1984400663':'Il pagamento non è andato a buon fine, che fare?',
'-956265022':'Se per qualche motivo il pagamento non è andato a buon fine, tornare indietro di un passo ed effettuare nuovamente il pagamento. Se l\'addebito sul conto è stato effettuato ma non si è ancora ricevuta un\'e-mail relativa al pagamento, si prega di contattarci all\'indirizzo <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>.',
'-246543694':'Il report è disponibile per 30 giorni dalla sua generazione. È possibile visualizzarlo utilizzando il link inviato all\'indirizzo e-mail inserito al momento dell\'acquisto.',
'592240026':'Non ho ricevuto alcuna e-mail, cosa posso fare?',
'339130199':'Un\'e-mail viene generata automaticamente dopo il pagamento; se non è presente nella propria casella di posta, preghiamo di contattarci all\'indirizzo <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>. Includere il report ID ed eventualmente i propri dati di contatto.',
'1681612939':'Ho inserito l\'email sbagliata, e ora?',
'-818807180':'Niente paura. Si prega di inviare un\'e-mail con l\'indirizzo corretto a <a href="mailto:info.it@cebia.com">info.it@cebia.com</a> e di includere il report ID che trovate nel report. Invieremo quindi gli accessi all\'e-mail corretta.',
'-40057886':'Il report può essere oggetto di reclamo. Se si sospetta che il report contenga informazioni non corrette, si prega di contattare il nostro Servizio Clienti all\'indirizzo <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>. Per ulteriori informazioni sul reclamo, fare clic <a href="/about#complaints" target="_blank">qui</a>.',
'-755697752':'È possibile emettere una fattura?',
'1950759546':'A causa del prezzo del servizio, viene generata automaticamente solo una ricevuta fiscale semplificata. Per la fattura completa, rivolgersi all\'indirizzo <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>. Per accelerare i tempi, si prega di includere il report ID nell\'e-mail ricevuta dopo l\'acquisto.',
'-1960272511':'Inserire il TELAIO',
'-371538970':'Dove posso trovare il TELAIO?',
'-1956291670':'Come funziona?',
'1850076653':'Passo 1',
'-1260091754':'Dati disponibili',
'1279291471':'Passo 2',
'-811487544':'Pagamento',
'777550298':'Passo 3',
'-1534039143':'Car History Report',
'495126992':'Passo 4',
'1916048998':'Verifica',
'-580693003':'Contatti',
'-760821457':'Serve un consiglio?',
'1432374431':'Modulo di contatto',
'1838884148':'Reception Cebia',
'1665302400':'Sede centrale dell\'azienda',
'1196114687':'Repubblica Ceca',
'-95958777':'Dati di fatturazione',
'1808758025':'Telefono',
'275235071':'Siamo disponibili',
'353260003':'Lun. - Ven. 8:00 - 16:30',
'822817400':'Verifica della storia del veicolo',
'-1317542970':'Verificato dai clienti',
'-1884218466':'Per ogni auto, controlliamo',
'1736624969':'Chilometraggio',
'2137107000':'Cronologia dei danni',
'647263705':'Furti',
'-441819024':'Cronologia delle riparazioni',
'982206269':'Registri di vendita',
'1753341741':'Utilizzo come taxi',
'-366535509':'I professionisti utilizzano i servizi Cebia',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'Sapevate che...',
'654946563':'caratteri',
'-1398891951':'Controllo del chilometraggio',
'1425691903':'Il 33% dei veicoli usati ha un contachilometri truccato!',
'1803799674':'Verifica dell\'età e della provenienza',
'1389120536':'Con le auto usate provenienti dall\'estero, l\'età dell\'auto e la sua origine sono spesso falsificate!',
'-2068933994':'Controllo dei furti',
'-1184837319':'Controllo dei finanziamenti',
'-1877453535':'Controllo dei danni',
'64494271':'Il 20% delle auto usate provenienti dall\'estero ha subito danni!',
'1051433840':'Controllare se l\'auto è stata pubblicizzata come incidentata!',
'1076835371':'Valutazione del veicolo',
'-1805177459':'Campagna di richiamo',
'957124168':'Počet majitelů v ČR',
'-1705145830':'Informazioni sul veicolo',
'1890293527':'Controllo taxi',
'-13439485':'Compilare il campo Nome.',
'-1147931879':'Compilare il campo Cognome.',
'2144560301':'Telefono non inserito in maniera corretta',
'-1642977586':'Compilare il campo Telefono.',
'-1249914076':'L\'e-mail non ha il formato corretto',
'-2038223065':'Compilare il campo Messaggio.',
'810453022':'E-mail',
'-1217737116':'Messaggio',
'-664538889':'Acconsento al trattamento',
'1322823680':'Invia il messaggio',
'56891982':'Il numero massimo di caratteri è 16.',
'277834303':'Grazie',
'552239414':'Il messaggio è stato inviato con successo.',
'1851370681':'Vi contatteremo presto',
'1952981238':'Avvertenze',
'342579190':'Acquistare un controllo',
'1586626737':'Continua',
'399846737':'Caricamento dei record',
'556543048':'È possibile verificare le seguenti informazioni:',
'560876430':'Foto e annunci',
'-320410471':'Non disponibile',
'1185850210':'Non sono stati rilevati danni',
'-903608283':'Estratto della storia del veicolo',
'718416430':'Informazioni sul report',
'668096075':'Report ID per accessi successivi',
'-1690204814':'Valido fino:',
'-840281270':'Cambio',
'-1901926256':'Dati di base del veicolo',
'-1550051512':'Tipo di Carrozzeria',
'-1043441574':'AVVERTENZE:',
'-709435856':'Esperienza del cliente',
'-1149927862':'Scoprite cosa dicono i clienti dei nostri controlli',
'456577623':'Ho trovato un\'auto che aveva subito incidenti e con contachilometri truccato. Grazie!',
'329190632':'Hanno soddisfatto le aspettative, riscontrando alcuni danni al veicolo. Grazie!',
'1450297818':'Kontrola odcizení v ČR a SR',
'-1594408882':'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
'341709606':'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Povinné ručení nebo havarijní pojištění musí být sjednané prostřednictvím portálu ePojisteni.cz do 30 dní od zaplacení prověření vozidla v systému AUTOTRACER',
'-355614707':'Nabídka se týká pojištění osobních, užitkových a nákladních automobilů (nevztahuje se na pojištění motocyklů, čtyřkolek, tříkolek nebo přívěsných vozíků a návěsů',
'-134420730':'Nevztahuje se na pojištění uzavřené prostřednictvím portálu ePojisteni.cz před zakoupením prověření vozidla v systému AUTOTRACER',
'1195365944':'Po uzavření pojištění vyplňte číslo účtu a číslo uzavřené pojistné smlouvy a částka Vám bude automaticky zaslaná na Vámi uvedený účet v žádosti po splnění podmínek nejdéle však do 60 kalendářních dnů od zaslání žádosti.',
'-1622287889':'Grazie, la richiesta è stata inviata. Il nostro personale vi contatterà al più tardi il giorno lavorativo successivo.',
'1069770504':'Pokud je Vámi vybrané vozidlo individuálně dovezené do ČR, doporučujeme Vám využít ještě službu prověření původu vozidla v zahraničí PROVIN.',
'-121060108':'Prověříme registraci vozidla v zemi původu, zda bylo řádně odhlášeno, zda nebylo vozidlo vyřazeno z provozu z důvodu jeho totální havárie, zda vozidlo není evidováno v daném státě jako odcizené a další skutečnosti.',
'-1158490787':'Lo sconto può essere utilizzato entro 30 giorni dall\'acquisto del report e non è cumulabile con altri sconti.',
'-1471409910':'Chcete mít jistotu, že Vámi vybrané vozidlo není nelegálně předělané?  Doporučujeme Vám využít službu komplexního prověření původnosti identifikátorů vozidla VINTEST.',
'1555104762':'Služba VINTEST poskytuje klientům 100% garanci výsledku fyzického prověření vozidla. Na vozidle provedeme detailní fyzickou expertízu s využitím nejmodernější digitální diagnostiky. Cílem této služby je ověřit, jestli vozidlo má všechny identifikátory (VIN, štítky, svary karoserie) originální a není podezření, že vozidlo, nebo část vozidla pochází z trestné činnosti.',
'735500953':'A seguito dell\'indagine, è possibile scoprire se il veicolo è soggetto a finanziamento (sotto forma di leasing o credito), fermo o prestito presso le società elencate. Riceviamo i dati direttamente dai sistemi informativi delle singole aziende.',
'-1173444543':'Società di leasing e di credito',
'-976513658':'*Carsharing = noleggio a breve termine di un\'auto direttamente dal proprietario (o car sharing tra il proprietario e il noleggiatore a breve termine).',
'-1479754603':'Dai database disponibili è emerso che il veicolo non era e non è attualmente in uso come taxi.',
'-467405909':'Registri di esercizio del veicolo come taxi',
'1930957191':'Le informazioni fornite sono solo a scopo informativo. Cebia non sarà responsabile per i danni derivanti dall\'utilizzo dei dati comunicati.',
'-1895346741':'Sdělíme vám obvyklou tržní cenu vozidla, pomocí které si ověříte, zda cena oceňovaného vozu odpovídá nabídkové ceně inzerce. Do výpočtu ceny je zahrnuto mimo jiné sledování inzerce ojetých vozidel v ČR i zahraničí včetně sledování cen skutečných realizovaných prodejů. Oceňovací algoritmus pracuje s výpočtovými modely postavenými na dlouhodobém monitoringu trhu, ale není schopen zohlednit fyzický stav vozidla.',
'-985858180':'V ojedinělých případech se může stát, že cenu vozidla nelze určit. Důvodem jsou nedostatečná data pro kvalifikované ocenění vozidla.',
'1915347014':'Zobrazíme vám inzeráty obdobných vozidel, která jsou aktuálně nabízena na trhu, a v některých případech také ceny skutečně prodaných obdobných vozidel (pomocí VIN porovnáváme přesnou specifikaci vozidel). Pomocí inzerce podobných vozidel si můžete porovnat, zda cena ověřovaného vozidla odpovídá podobným vozidlům na trhu. Ušetříme vám spoustu času stráveného vyhledáváním a porovnáváním podobných inzerátů na webu.',
'-767763886':'Non sono state trovate registri di vendita del veicolo.',
'797541609':'Verifica in quali condizioni e con quale chilometraggio l\'auto è stata messa in vendita in passato: se l\'auto è stata messa in vendita con un chilometraggio più alto di quello attuale, il contachilometri potrebbe essere stato manomesso.',
'-1902352540':'Conferma dei dati inseriti. Una volta salvati, non possono essere modificati.',
'-2008975653':'Non sono stati trovati dati relativi al colore del veicolo registrati dal costruttore o al momento della prima immatricolazione.',
'7150984':'Verificare che il colore attuale del veicolo corrisponda a quello dichiarato dal costruttore o a quello registrato al momento della prima immatricolazione. Se il colore è diverso, potrebbe trattarsi di un veicolo riverniciato, ma anche di un veicolo incidentato o rubato.',
'-1664139465':'L\'elenco delle attrezzature non è disponibile. Si consiglia di verificare la funzionalità e l\'originalità delle dotazioni del veicolo.',
'1681595153':'Il risultato del controllo fornisce i dati sul fatto che il veicolo sia assicurato mediante marcatura dei vetri e registrato nel Sistema OCIS. In tal caso, forniremo il codice della marcatura e la data di immatricolazione.',
'571758489':'Il veicolo è stato sottoposto a marcatura dei vetri in passato ed è registrato nel database internazionale OCIS.',
'855264535':'Se il codice univoco manca da alcuni o da tutti i finestrini, il veicolo potrebbe aver subito incidenti, modifiche o furti.',
'388233970':'Informazioni sulla marcatura del vetro:',
'1615022749':'Non è stato possibile reperire i parametri tecnici del veicolo. Tuttavia, possono essere reperiti nella carta di circolazione completa del veicolo o possono essere richiesti al costruttore del veicolo.',
'704641294':'Una volta selezionato il veicolo, l\'applicazione fornisce le istruzioni per un controllo di base degli identificativi. Le istruzioni si applicano in generale alla marca e al modello di veicolo specificati. Per l\'esempio è stato scelto un veicolo a caso.',
'-621659477':'La selezione del veicolo può già essere parzialmente precompilata in base al TELAIO inserito. Se la selezione del veicolo precompilato non corrisponde al veicolo che si sta controllando, modificare manualmente la selezione.',
'152333450':'Non sono stati trovati danni, cosa significa?',
'780709713':'Per quanto tempo sarà disponibile il Report?',
'830704335':'Scopri quali informazioni sono disponibili per la tua auto:',
'1850331238':'Ottimo servizio, vale la pena acquistarlo. Ho avuto la conferma che il contachilometri era manomesso anche sul veicolo di dimostrazione.',
'1705245752':'Volevo comprare un’auto che secondo il venditore non aveva subìto incidenti: ne aveva parecchi.',
'739619867':'Assolutamente perfetto, non abbiamo comprato un\'auto scassata per un sacco di soldi, grazie!',
'-2071079400':'Mi avevano proposto un\'auto dove non era indicato che aveva subito due incidenti con danni gravi e qui l\'ho scoperto prima dell\'acquisto. Grazie!',
'21202350':'Ottimo programma. Stavo per buttare 4.000 euro. Secondo il proprietario, l\'auto aveva subito un solo incidente leggero sul lato anteriore. Secondo il programma ne aveva subiti due molto gravi.',
'1343185270':'La verifica mi ha rassicurato e mi ha aiutato a prendere una decisione sull\'acquisto.',
'2083029914':'C\'erano danni ingenti di cui il venditore non aveva parlato.',
'1933958141':'Ha riscontrato una collisione minore + il controllo del tachimetro dalla data di immatricolazione.',
'1900495866':'Grazie all\'app, ho scoperto che c\'era qualcosa che non andava. Naturalmente il venditore non mi ha detto nulla a riguardo e probabilmente anche il concessionario preferiva farsi i fatti suoi. Grazie!',
'-1668804606':'Durante l\'ispezione ho riscontrato problemi gravi che il venditore mi ha nascosto, ad esempio lo stato del contachilometri, ma anche che il veicolo aveva subito diversi incidenti.',
'-273876487':'Ho scoperto che avevano truccato il chilometraggio. Il venditore dichiarava 155.000 km e in realtà sono 250.000...',
'911857027':'Per visualizzare il report, inserire l\'ID',
'1167593586':'Il Report ID si ottiene acquistando il Car History Report.',
'-2136315768':'Pagando con carta di credito, GooglePay o ApplePay, vedrete immediatamente le informazioni sull\'acquisto e riceverete il Report ID via e-mail.',
'868918064':'In caso di pagamento tramite bonifico bancario, riceverete il Report ID via e-mail non appena i fondi saranno accreditati sul conto bancario Cebia.',
'1572179326':'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
'-1322783148':'La posizione del TELAIO varia per ogni marca e modello di veicolo. Tuttavia, si trova sempre sulla carta di circolazione, sul libretto e spesso sotto il parabrezza.',
'700484420':'Il TELAIO è il numero identificativo unico di ogni auto. Si trova nei documenti del veicolo o direttamente sul veicolo.',
'-851712281':'Rintracceremo tutti i documenti disponibili dell\'auto e li mostreremo nella loro interezza. Saprai sempre cosa stai comprando.',
'-1983041378':'È possibile pagare tramite il gateway sicuro GoPay con carta di credito o bonifico bancario.',
'-1809684191':'Il Car History Report è disponibile subito dopo il pagamento. Riceverai un link di collegamento direttamente sulla tua email.',
'541894049':'È possibile visualizzare i valori del contachilometri per un determinato periodo. Un semplice grafico mostra l\'evoluzione del contachilometri nel tempo e rivela eventuali manomissioni.  ',
'643692561':'È possibile visualizzare le informazioni sui danni registrati. Le informazioni sull\'entità del danno e sulla sua portata possono essere incluse nei dettagli. Attenzione: non dimenticare di verificare contemporaneamente le condizioni tecniche del veicolo ed eventuali danni precedenti. Se, ad esempio, si tratta di un danno completo.',
'878444309':'Se il veicolo è stato messo in vendita in passato, vi mostreremo l\'annuncio e la documentazione fotografica del veicolo.',
'185243225':'Saranno incluse informazioni dettagliate e una panoramica degli interventi di assistenza effettuati nell\'ambito della riparazione o della manutenzione ordinaria del veicolo.  Attenzione: confrontare i record con il libretto di manutenzione e chiedete maggiori informazioni sulla cronologia delle riparazioni del veicolo.',
'1036319803':'Nous vérifierons pour vous l\'année de production réelle, ce qui pourrait avoir un impact significatif sur le prix actuel et futur du véhicule.',
'-437053085':'Verificheremo il veicolo nei database dei veicoli rubati attualmente disponibili. Si tratta per lo più di banche dati europee provenienti da fonti statali e non statali. ',
'494784690':'Verificheremo il veicolo nei database delle società finanziarie, delle società di autonoleggio e dei banchi di fermo. Il report a pagamento può essere utilizzato per verificare se l\'auto non è gravata da finanziamenti, prestiti o fermo.',
'2074346923':'Verifica dei database dei veicoli gestiti come servizio taxi. Nella sezione a pagamento è possibile sapere se l\'auto è stata o è tuttora utilizzata come taxi.',
'-976680067':'Mostreremo un elenco di campagne di richiamo che potrebbero essere correlate al veicolo che state controllando.',
'1665109686':'La descrizione tecnica consentirà di verificare i parametri importanti in base ai quali la versione del modello del tipo di veicolo è stata omologata per l\'uso.   Questi parametri vanno confrontati con le informazioni riportate sulla carta di circolazione.',
'-719963267':'Vi mostreremo l\'elenco degli equipaggiamenti del veicolo secondo le specifiche della sua produzione. Potrete quindi confrontare l\'attuale dotazione del veicolo con quella indicata dal produttore.',
'-1106584159':'Il veicolo è stato protetto sabbiando o incidendo i finestrini, oppure il veicolo potrebbe essere contrassegnato con parti della carrozzeria. Forniremo il codice della marcatura e la data di immatricolazione.',
'1830950672':'Vi mostreremo il colore registrato dal costruttore del veicolo e il colore specificato al momento dell\'immatricolazione del veicolo.',
'1835889470':'Caricamento dati...',
'1020539397':'Il gestore dell\'applicazione Car History - la società Cebia, spol. s r.o., informa gli utenti del sistema che le informazioni fornite nel sistema sono state fornite dai partner del gestore senza la possibilità di verificarne l\'accuratezza. Tutte le informazioni sono esclusivamente a scopo informativo.',
'2054100346':'Il Car History Report non è coperto da garanzie.',
'-167783152':'Il report è stato creato dal servizio Car History gestito da Cebia, spol. s r.o. in base alle Condizioni generali valide per l\'utilizzo del servizio Car History. L\'utente ha il diritto di utilizzare le informazioni fornite nella loro interezza solo per uso personale. Ne è vietata l\'ulteriore distribuzione o modifica senza il consenso dell\'operatore.',
'-1697979879':'Il direttore di Cebia, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Direttore di Cebia',
'759051798':'Cebia',
'-31539157':'Siamo un\'azienda ceca fondata nel 1991 a Praga. I nostri servizi di screening, sicurezza e identificazione dei veicoli sono utilizzati da clienti in 9 Paesi europei. I nostri clienti sono automobilisti comuni, compagnie di assicurazione, concessionari d\'auto, istituzioni governative, forze di polizia ed esperti forensi. Nel corso della nostra storia, abbiamo scoperto decine di migliaia di truffe di auto usate e aiutato milioni di clienti in tutta Europa ad acquistare un\'auto.',
'-1821588851':'Reclami e lamentele',
'-276118588':'I reclami sono una fonte preziosa di suggerimenti per migliorare i nostri servizi. Contattateci utilizzando il modulo o all\'indirizzo: <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>',
'-1446517877':'Il reclamo sarà trattato entro 14 giorni dalla ricezione e informeremo circa l\'esito.',
'708234375':'Contattateci tramite il modulo di contatto o via e-mail <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>.',
'1474175101':'Condizioni generali di servizio',
'1343500875':'Informativa sulla privacy',
'-2083968164':'Sviluppo di uno strumento proprietario per la valutazione dei veicoli',
'907588970':'Crea una società a Praga',
'-770165824':'<strong>30 anni di esperienza</strong> in controlli auto',
'-1138482741':'<strong>Oltre 1.000.000</strong> di auto controllate ogni anno',
'154007106':'<strong>750.000+</strong> clienti soddisfatti',
'177137909':'Il database di Cebia contiene oltre 1 miliardo di record relativi a veicoli usati provenienti da più di 32 Paesi. Questi includono i dati relativi al chilometraggio, i danni al veicolo, le foto del veicolo e i documenti di servizio.',
'723486619':'Caricamento dei dati in corso',
'742852638':'Periodo:',
'787763184':'periodo:',
'-685036969':'Spiegazione:',
'290784658':'Non disponibile:',
'412892373':'Non siamo riusciti a trovare informazioni sul veicolo in questa categoria.',
'1853282090':'Disponibile',
'-249038921':'XY record:',
'805146013':'Sul veicolo in questa categoria abbiamo rintracciato XY record, che sono visualizzabili nella sezione a pagamento',
'242118549':'Condizioni generali',
'521725542':'Visualizza i termini e le condizioni generali per l\'utilizzo di Car History',
'-571686819':'qui',
'639498968':'Il pagamento per la visualizzazione delle informazioni dettagliate nella sezione Car History Report verrà addebitato secondo le condizioni stabilite nel contratto con Cebia.',
'-1705426324':'È possibile visualizzare informazioni dettagliate per 1 TELAIO per 30 giorni dalla prima visualizzazione di quel TELAIO, si paga una sola volta.',
'529403448':'Avviso importante',
'-821000576':'Cebia, spol. s r.o. informa gli utenti del servizio che le informazioni disponibile sono fornite dai partner del gestore senza la possibilità di verificarne l\'accuratezza. Tutte le informazioni sono esclusivamente a scopo informativo. Nel caso in cui le condizioni del veicolo siano significativamente incoerenti con le informazioni qui fornite, si consiglia di contattare un esperto appropriato per verificare le effettive condizioni del veicolo.',
'-1138137280':'Informazioni sul trattamento dei dati personali',
'125063053':'Visualizza informazioni sul trattamento dei dati personali ',
'-608864551':'Sul veicolo in questa categoria abbiamo rintracciato informazioni, che sono visualizzabili nella sezione a pagamento',
'-1879995849':'Dati di uscita inseriti',
'754897480':'ANNO DELLA PRIMA IMMATRICOLAZIONE',
'-1834106204':'STATO ATTUALE DEL CONTACHILOMETRI',
'404797827':'ZOBRAZIT CEBIA REPORT',
'1358780725':'GENERARE REPORT',
'-952450705':'Ho controllato e confermo le informazioni fornite',
'-1601808583':'<span className="green">Fatto!</span> È possibile verificare le seguenti informazioni per il veicolo specificato:  ',
'-1763095911':'Lze vystavit Cebia REPORT hodnotící následující kategorie:',
'761362384':'Ukázka REPORTU',
'-397155268':'VISUALIZZA IL REPORT',
'1375027269':'CONTROLLO DEL VEICOLO',
'2055286638':'più di',
'-1668243810':'Siamo più forti',
'1141836815':'Stiamo entrando a far parte della holding ceca EAG. Grazie a ciò, i nostri database sono stati notevolmente ampliati con dati provenienti da tutta Europa, in particolare da Germania, Austria e Danimarca.',
'-903271711':'Stiamo lanciando uno strumento online per determinare il prezzo dei veicoli usati. Grazie al nostro ampio database con dati relativi ad auto usate, è altamente preciso e arriva rapidamente alle più grandi compagnie di assicurazione e di leasing.',
'-1262829232':'Stiamo cambiando il mercato delle auto usate',
'1367974718':'Con il crescente interesse per la selezione di auto online, stiamo iniziando a collaborare con i maggiori portali pubblicitari dell\'Europa centrale. I risultati del nostro controllo storico diventano il segno distintivo di un\'auto usata di qualità.',
'-300528762':'Ingresso nel mondo online',
'-899322976':'I nostri servizi sono ora facilmente accessibili a tutti, comodamente da casa. Le frodi sulle auto usate sono al culmine e noi le stiamo combattendo con tutte le nostre forze.',
'-4875574':'Espansione dei servizi',
'1166185728':'Stiamo aggiungendo ai nostri servizi i controlli del TELAIO e il nostro database si sta espandendo a passi da gigante. Collaboriamo con le compagnie di assicurazione, le autorità statali e la polizia.',
'-1619922514':'Ingresso all\'estero',
'-844900279':'Il traffico di veicoli rubati è in aumento in tutta Europa. Ecco perché stiamo creando un database internazionale di veicoli rubati per aiutarci a scoprire la vera origine delle auto usate.',
'598423318':'Tre compagni di università fondano un\'azienda chiamata Cebia. La loro intenzione iniziale era di entrare nel settore informatico, ma è stata presto sostituita dall\'interesse per la sicurezza dei veicoli.',
'-1828699417':'Insolvenza',
'4000011':'Verifica gratuita dei documenti',
'1479506336':'Cronologia della ricerca',
'-1478881101':'Cronologia delle verifiche del veicolo',
'1115668272':'Cronologia insolvenze',
'691760517':'Cronologia delle verifiche dei documenti',
'-614781938':'La portata e il costo della verifica possono variare per ogni veicolo, a seconda della quantità di informazioni disponibili.',
'702191442':'Indietro',
'-1034048918':'Controllo del TELAIO',
'-1214567423':'Numero di riferimento',
'427849990':'Vytvořit CERTIFIKÁT kontroly vozu',
'-1718377395':'Errore di caricamento.',
'877365836':'Impossibile generare l\'url di pagamento.',
'-1082791212':'Valore errato ',
'-255296664':'Il report ID consente di visualizzare il report per 30 giorni dopo l\'attivazione.',
'456125545':'Il gestore dell\'applicazione Car History - la società Cebia, spol. s r.o., informa gli utenti che le informazioni fornite sono state fornite dai partner del  senza la possibilità di verificarne l\'accuratezza. Tutte le informazioni sono esclusivamente a scopo informativo.',
'-1172808350':'Contattare il concessionario per ottenere il numero di telaio del veicolo e verificare il veicolo.',
'-2079080642':'Contattare il concessionario per ottenere il TELAIO a 17 cifre e verificare il veicolo.',
'-585052469':'State acquistando un\'auto usata? Scoprite tutto su di essa! In particolare, lo stato del contachilometri, l\'anno di fabbricazione o la registrazione dei danni in base al numero di TELAIO.',
'76811920':'ESEMPIO DI REPORT di un veicolo a caso.',
'1050846059':'Tuttavia, consigliamo di far controllare attentamente il vostro veicolo, possibilmente da un meccanico qualificato o presso un\'officina di vostra scelta. Un\'ispezione di questo tipo può chiaramente rivelare o escludere danni precedenti.',
'-1068264553':'Controllare sempre la data di fabbricazione dichiarata dal costruttore del veicolo. Le carte di circolazione riportano solo la data di prima immatricolazione, che può differire notevolmente dalla data di produzione (ad esempio, fino a due anni). Il veicolo può quindi essere spacciato per più recente, il che ha un impatto significativo sul suo prezzo di mercato.',
'756232271':'Non sono state trovate informazioni sull\'età o sull\'origine del veicolo. Se il veicolo proviene dall\'estero, chiedere e controllare sempre l\'originale della carta di circolazione estera. ',
'-643660678':'Sono stati trovati i registri degli interventi di assistenza eseguiti in passato sul veicolo nell\'ambito delle riparazioni o della manutenzione ordinaria.',
'1533967406':'Il costo del controllo del veicolo dipende dalla quantità e dalla portata delle informazioni disponibili. È possibile conoscere l\'entità delle informazioni (e, quindi, il prezzo) subito dopo aver inserito il TELAIO del veicolo.',
'-1289463237':'Il Report sarà disponibile pochi minuti dopo aver effettuato il pagamento. Il servizio è sempre attivo e l\'intero processo è totalmente automatizzato.',
'1304585346':'Il Report sarà generato automaticamente subito dopo la ricezione del pagamento. Nell\'e-mail inviata dopo il pagamento è presente un link. L\'e-mail contiene anche il Report ID che consente di visualizzare il Report per 30 giorni.',
'-661494819':'Errore nel caricamento dei dati.',
'-1681989289':'avete già acquistato Report che vi dà diritto a uno sconto di 20 % su questo acquisto. Per applicare lo sconto, inserire il Report ID ricevuto via e-mail nel campo "Inserisci il codice sconto".',
'681093117':'získal/a jste slevový kód při prodloužení služby FLEX na další rok. Slevu uplatníte zadáním čísla slevového kódu, který jste obdržel/a na daňovém dokladu k platbě služby FLEX, do pole „Zadejte číslo kuponu“.',
'-1551909822':'avete un codice sconto che vi dà diritto a un acquisto gratuito. Applicare inserendo il report ID nel campo dello sconto.',
'-103162544':'Le informazioni necessarie per visualizzare il report e la ricevuta fiscale semplificata saranno inviati all\'indirizzo e-mail fornito dopo il pagamento.',
'-591911074':'Le informazioni necessarie per visualizzare il report e la ricevuta fiscale semplificata saranno inviati al numero di telefono fornito dopo il pagamento.',
'706315962':'Oltre alle informazioni necessarie per visualizzare il Report, inviamo sempre una ricevuta fiscale semplificata. Se si desidera che i propri dati di fatturazione (azienda, indirizzo, partita IVA) siano riportati sulla ricevuta fiscale, inserirli qui sotto.',
'500915765':'Al momento dell\'ordine, sarà avviato il reindirizzo al gateway di pagamento GoPay per effettuare un pagamento sicuro con carta di credito. Dopo il pagamento, un nuovo redirect condurrà al risultato della verifica, che sarà inviato per e-mail insieme alla ricevuta fiscale.',
'-676823504':'Se si sceglie questo metodo di pagamento, si verrà reindirizzati al gateway di pagamento GoPay, dove si dovrà selezionare l\'istituto bancario dal quale si desidera effettuare il pagamento.',
'-764670786':'Se la banca offre il bonifico online, sarà avviato il reindirizzo all\'online banking con l\'ordine di pagamento precompilato, basterà effettuare il pagamento per il servizio.',
'2062466434':'Se riceviamo immediatamente il pagamento dalla banca, reindirizzeremo automaticamente alla parte a pagamento del servizio Car History. Altrimenti, sarà necessario attendere un\'e-mail.',
'734607756':'Dopo l\'accredito del pagamento sul nostro conto, invieremo al vostro indirizzo e-mail un link al report e una ricevuta fiscale.',
'1715999355':'Google Pay è un modo semplice e veloce per pagare senza dover inserire i dati di pagamento. Per utilizzare questo servizio di pagamento, anche la vostra banca deve supportarlo. Dopo aver effettuato il pagamento, invieremo al vostro indirizzo e-mail un link al Report e una ricevuta fiscale.',
'790632208':'Apple Pay è un metodo di pagamento che consente di pagare facilmente utilizzando una carta memorizzata in Apple Wallet. Per utilizzare questo servizio di pagamento, anche la vostra banca deve supportarlo. Dopo aver effettuato il pagamento, invieremo al vostro indirizzo e-mail un link al Report e una ricevuta fiscale.',
'-1402859324':'Nel caso in cui la banca offra solo un cosiddetto trasferimento offline, genereremo le istruzioni per il bonifico bancario, i fondi andranno trasferiti alla banca e non appena l\'importo sarà accreditato sul nostro conto bancario, invieremo l\'e-mail con un link al Report e un documento relativo al pagamento effettuato.',
'347099860':'Questa operazione può richiedere fino a 2 giorni lavorativi (ma in genere 24 ore).',
'-43783036':'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
'-802148009':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla.',
'1985201267':'Il più grande database europeo di dati sulle auto',
'-1590601891':'<span class=\'warning\'>Attenzione:</span> Il servizio Car History potrebbe non avere informazioni su tutti i danni subiti dal veicolo, alcuni danni non sono segnalati agli assicuratori o non sono disponibili nella scansione online. ',
'-1465948017':'<strong>Systém OCIS –</strong> značení skel vozidla. Jednoduché, efektivní a cenově výhodné zabezpečení vozidla. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html\' target=\'_blank\'>Více zde.</a>',
'1553997983':'<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html\' target=\'_blank\'>Více zde.</a>',
'-124714580':'Pagamento online con carta di credito, Google Pay o Apple Pay. ',
'-753863533':'CERTIFICATO DI ISPEZIONE DEL VEICOLO - panoramica delle informazioni disponibili',
'1441255407':'Categoria',
'1549893453':'Possibilità di controllo',
'1547427535':'SÌ',
'508023484':'Controllo della data di immatricolazione',
'2076692599':'Controllo fisico',
'-1521980547':'Controllo dello stato del contachilometri',
'-1009985044':'SÌ - sulla base di',
'1747355819':'dati',
'1639206028':'Controllo dell\'origine del veicolo',
'-1524110425':'Verifica del pagamento dell\'imposta ECO',
'-1961089879':'Controllo dell\'IVA',
'1732127987':'Controllo degli incidenti gravi',
'-198755848':'Controllo della immatricolazione',
'-1790774232':'Controllo di danni da alluvione',
'-1781437228':'Controllo del tipo di motore',
'-1344389293':'Controllo degli identificativi dell\'auto',
'-499181915':'Foto',
'-687939409':'Le informazioni sono state ricavate dai registri dei servizi di documentazione sulle condizioni dei veicoli acquistati da Cebia o dalla sua rete di partner.',
'-43022982':'Data del servizio:',
'-136414993':'Condizioni del veicolo:',
'2023782231':'Il veicolo è stato controllato fisicamente per verificarne gli elementi identificativi (impressione del TELAIO(VIN), etichette di produzione, ecc.). Le informazioni sono state ricavate dai registri di servizio VINTEST eseguiti da Cebia o dai suoi partner.',
'758499555':'Stato degli identificatori:',
'488100027':'INDIETRO',
'-1281249872':'CONTINUA',
'-141562572':'GENEROVA',
'-40297285':'In questo modo è possibile avere una panoramica del prezzo e dell\'andamento dei dati del veicolo oggetto di verifica.',
'-1742933347':'Numero di inserzione',
'-2099386182':'Vendita',
'1560408371':'Prezzo di vendita',
'-280814568':'VISUALIZZARE IL CERTIFICATO DI ISPEZIONE DEL VEICOLO',
'2075274708':'Data di acquisizione',
'-1248991647':'Ucraina',
'-1779298552':'Svezia',
'-18328562':'Lettonia',
'1764744897':'Lituania',
'-1273046880':'Romania',
'1252567719':'Bulgaria',
'445626359':'Grecia',
'-868498706':'Slovenia',
'-1528448787':'Ungheria',
'-387469480':'Slovacchia',
'25300212':'Austria',
'1771002523':'Italia',
'69077775':'Danimarca',
'647944028':'Polonia',
'1160986873':'Germania',
'897084544':'Francia',
'2056598840':'Spagna',
'264100206':'Russia',
'-1609348689':'Informativa sui cookie',
'-365994139':'Carica altro',
'807848789':'Milioni di clienti soddisfatti',
'1677683955':'Cebia ha più di <strong>2.000 partner</strong> in tutta Europa che ci forniscono i dati dei veicoli di tutto il mondo.',
'652553309':'Sapevate che...',
'1453750886':'Fatto!',
'1498262202':'Disponibile',
'1514779988':'Ispezione dell\'auto da parte di un tecnico',
'1058658544':'Buoni sconto Cebia',
'-2035347536':'0 record',
'1050102897':'Le specifiche dettagliate della dotazione fornite dal costruttore del veicolo comprendono tutti gli equipaggiamenti di base e opzionali. Controllando la dotazione, è possibile rilevare le modifiche apportate all\'auto, come ad esempio un cambiamento nel lato dello sterzo.',
'2145745364':'Stampa dei dati registrati del contachilometri',
'-1636264801':'Descrizione',
'-1488190529':'Numero del record',
'64594583':'Veicolo finanziato',
'-1441353378':'Senza finanziamenti',
'-2101084179':'Gennaio',
'-1066567335':'Febbraio',
'-1761670105':'Marzo',
'104663035':'Aprile',
'1397259452':'Maggio',
'-642974117':'Giugno',
'613657227':'Luglio',
'-2044487450':'Agosto',
'1921417285':'Settembre',
'-1672709282':'Ottobre',
'-678062097':'Novembre',
'1302554433':'Dicembre',
'1268839568':'Non sono stati trovati record nei database delle manutenzioni. Richiedere il libretto di manutenzione (preferibilmente un libretto di manutenzione elettronico). L\'autenticità degli interventi di assistenza può essere verificata direttamente presso l\'officina in cui sono stati eseguiti secondo il libretto di manutenzione.',
'-112660439':'Zde si vytvoříte Osvědčení <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, které můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále – <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Record trovato',
'-1350474522':'Nei database disponibili non è registrato alcuno stato del contachilometri per il veicolo controllato.',
'1040913417':'Si consiglia di controllare altre possibili fonti di registrazione dei dati del contachilometri, come il libretto di manutenzione o di richiedere la cronologia degli interventi di assistenza presso un\'officina autorizzata. ',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'Email',
'-982011601':'Stati Uniti',
'786789662':'Nákup zvýhodněných balíčků',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Chcete nakoupit na firmu nebo prověřit více vozidel? Využijte naše',
'845754511':'zvýhodněné balíčky!',
'-614796774':'Repubblica Ceca',
'1011350398':'Lun-Ven: 8:00 - 16:30',
'-68730396':'In data:',
'544810671':'NO - non registrato',
'-1670731287':'Registrato in Repubblica Ceca come:',
'1597705322':'Equipaggiamento standard',
'16575694':'Veicolo:',
'-846486931':'record',
'-1615816320':'record',
'1415114012':'Record',
'1802294807':'foto',
'1122888753':'foto',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pruvodce?id=161&do=downloadFile">dati personali</a>',
'1802705032':'<a classname="Link Link--lightBlue" href="/detailArticle/dove-posso-trovare-il-numero-di-telaio" target="_blank" rel="noreferrer noopener">Ulteriori informazioni</a>',
'883512613':'Controllo del TELAIO - Verifica online del veicolo',
'-1965309318':'Controllate la storia dell\'auto online nel più grande database di auto usate in Europa. Ispezione dei danni, storia dei KM, cronologia delle riparazioni, foto e altro ancora.',
'-1871315210':'Scelta del metodo di pagamento ',
'-1803227447':'Selezionare il metodo di pagamento appropriato.',
'-1249181167':'Notizie - Car History',
'-838385662':'Notizie dal settore di controllo e d acquisto di veicoli usati. Imparare a non cadere nelle truffe.',
'1154868985':'Domande frequenti - Verifica online del veicolo',
'908158246':'Quali sono le domande più frequenti dei clienti sulla verifica online dei veicoli di Cebia? In caso di ulteriori domande, saremo lieti di rispondere.',
'-633966577':'Chi siamo',
'1171158673':'Scoprite Cebia, la sua storia e perché gode della fiducia di milioni di clienti in tutta Europa.',
'-1244282008':'Informativa sui cookie',
'-792206035':'Questo sito web utilizza diversi cookie. Alcuni cookie sono inseriti da servizi di terze parti. L\'utente può revocare il proprio consenso al loro utilizzo in qualsiasi momento.',
'1489309005':'Ingresso gratuito entro i limiti del contratto',
'-470675791':'Ho controllato e confermo le informazioni fornite',
'2108896817':'ANNULLARE LO SCONTO SPECIFICATO',
'6892736':'Lo sconto è stato annullato',
'-1199844274':'Il codice non è valido!',
'-552424253':'La tariffa per l\'ingresso nella sezione a pagamento viene addebitata in base al contratto',
'167769730':'Platba hotově',
'1768026018':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
'820786512':'Il codice è stato accettato, il prezzo è stato ricalcolato',
'-279227794':'Controllo dell\'anno di produzione',
'1470281039':'Controllo dei contratti di locazione/credito in Repubblica Ceca',
'60776637':'Report ID',
'529459564':'Compilare il campo Report ID.',
'-861194224':'Ispezione del veicolo',
'2076412240':'Nuovo',
'360601996':'Il Report è scaduto.',
'1762078183':'Materiale',
'822990672':'Premendo questo pulsante, si estrae 1 richiesta dal limite delle richieste gratuite in base al contratto valido.',
'532528312':'u',
'-319106355':'Veicolo',
'935795728':'Esempio di Report',
'-916000765':'Car History Report',
'-1721948126':'Esempio di report Controllate la storia dell\'auto online nel più grande database di auto usate in Europa. ',
'329882651':'Premendo questo pulsante verrà addebitato il prezzo per la visualizzazione delle informazioni dettagliate in base al contratto in vigore.',
'-494157101':'Ricevuta fiscale',
'-465994559':'Foto',
'857656094':'Marchio di fabbrica:',
'-2005319583':'Modello di veicolo:',
'339937023':'Anno di prima immatricolazione secondo la carta di circolazione:',
'-441771827':'Caricamento dati',
'-161761801':'periodo non verificato',
'1229398238':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Vozidlo je evidované jako <strong className="bolder">odcizené</strong>.',
'358386818':'Età del veicolo:',
'-701699977':'Cebia REPORT nelze vytvořit - VIN nebylo možné jednoznačně identifikovat. </br> Zkontrolujte VIN podle dokladů od vozidla, případně <a href="mailto:autotracer@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN:{0}">Kontaktujte podporu</a>.',
'-1359814132':'Immagine successiva',
'340541220':'Ingrandire',
'2096545526':'Rimpicciolire',
'-158382161':'Immagine precedente',
'-749344895':'Ottenete uno sconto',
'-1755324145':'Modello di veicolo',
'1747292041':'Smart code',
'1093009871':'Chytrá komunikace pro moderní autocentra',
'-421925379':'Chcete u svých vozidel propagovat, že jsou prověřená?',
'-303554559':'Dejte na každé vozidlo Smart kód a zákazník si sám načte výsledek jeho prověření.',
'5128661':'Úplně jednoduše. Cebia Smart kód vytisknete v systému AUTOTRACER (ve formátu A5) a umístíte za sklem prodávaného vozidla.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Ostatní informace (kompletní výpis ze systému AUTOTRACER) si pak může vyžádat přímo od Vás.',
'-566386963':'Compilare questo breve modulo e noi risponderemo.',
'-1639125181':'Inviare',
'76421921':'Campi obbligatori',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Máte zájem tisknout u vašich vozidel Smart kódy? Kontaktujte naše obchodní oddělení:',
'2094660384':'Petr Kala, vedoucí obchodního oddělení',
'361077673':'Oppure compilate il modulo e noi risponderemo:',
'1277961418':'Opravdu chcete vygenerovat Smart kód?',
'-2133968142':'Ho un codice sconto',
'-1922950659':'Metodo di pagamento',
'-2072875261':'Veicolo finanziato',
'783887791':'Ordine',
'-1499237034':'gratis',
'-1854208328':'Inserisci il codice sconto',
'-793343279':'Promo',
'-1393236672':'Kosovo',
'1342791550':'Cronologia del chilometraggio',
'2063498881':'Equipaggiamento opzionale',
'-152116623':'QR – Smart kód',
'-956949153':'Importo del danno',
'-552950132':'dato',
'30639535':'dati',
'-1164317619':'No',
'1402944789':'Inserire i dati obbligatori',
'-1812102598':'VAROVÁNÍ:Nelze provést <strong>kontrolu stavu tachometru</strong>. Kategorie bude mít výsledek - nedostatek informací.',
'666398003':'Attenzione!',
'771644825':'Il TELAIO inserito potrebbe essere errato!',
'-1131044763':'Maggiori informazioni?',
'-2054470344':'Il VIN del veicolo inserito potrebbe essere errato!',
'-1369269346':'È stato rilevato un possibile errore nel numero TELAIO inserito. Verificare che il TELAIO inserito corrisponda al numero TELAIO impresso sul veicolo o sulla carta di circolazione.',
'-1625517200':'Dove cercare il TELAIO?',
'1171142400':'Il pagamento non è stato effettuato. Si prega di ripetere il pagamento.',
'-1192484737':'Senza accettare i Termini e le Condizioni, non è possibile procedere.',
'-247455599':'Inserire il numero identificativo dell\'azienda.',
'-1944712721':'Il numero minimo di caratteri è 12.',
'1664270996':'Stato',
'-862797995':'Condizioni del veicolo registrate al momento della vendita. Si prega di fare riferimento alla documentazione fotografica allegata per verificare eventuali danni.',
'-1997376096':'Repubblica Slovacca',
'-1344951568':'Registro dei veicoli',
'-644036535':'Database',
'-426412387':'Registro di Stato dei veicoli',
'1736003883':'Dati della carta di circolazione',
'1610817679':'Sono disponibili i dati del contachilometri del veicolo. Nella sezione a pagamento saranno riportate le letture del contachilometri per il periodo che elenchiamo per questa categoria. I registri possono essere utilizzati per determinare se il chilometraggio è reale o se il contachilometri dell\'auto è stato manomesso.',
'1669009738':'Il veicolo oggetto del controllo è stato messo in vendita o fotografato in passato. Nella sezione a pagamento è possibile visualizzare i dettagli degli annunci, la lettura del contachilometri, le foto dell\'auto e, se del caso, il prezzo di vendita (la quantità di informazioni può variare da un record all\'altro). ',
'1518701163':'Abbiamo informazioni sull\'età o sull\'origine dell\'auto. Nella sezione a pagamento è possibile verificare la data di fabbricazione del veicolo, la data di entrata in servizio o il Paese per il quale il veicolo è stato prodotto. La quantità di informazioni nella sezione a pagamento può variare da vettura a vettura.',
'1816609203':'L\'anno di fabbricazione viene spesso falsificato per far apparire l\'auto più recente e quindi più costosa!',
'-900812009':'Un\'auto rubata può essere confiscata dalla polizia senza alcun risarcimento!',
'-976941031':'Questo TELAIO non è disponibile per il controllo nei database dei veicoli gestiti come taxi.',
'444450829':'fino a',
'982955181':'12 registri',
'-1739651968':'Controllo di sicurezza',
'-167461646':'Controllo della storia del veicolo',
'-1365221853':'Il numero massimo di caratteri è 14.',
'1302766369':'Non è stata effettuata alcuna valutazione del veicolo.',
'1881868699':'Acquisto come azienda',
'631216697':'Inserire l\'indirizzo e-mail.',
'945011940':'I tuoi dati',
'-401861769':'E-mail di contatto',
'1441058305':'Se inserite il numero di partita IVA, cercheremo di inserire i dati rimanenti dal database ufficiale.',
'-1181757195':'Dopo il pagamento, invieremo all\'indirizzo e-mail indicato un link al Car History Report e una ricevuta fiscale semplificata.',
'-1768425519':'Se si desidera che i dati di fatturazione (Azienda, indirizzo, partita IVA) siano riportati sulla fattura, inserirli qui sotto. Se inserisci solo il numero di partita IVA, cercheremo di inserire i dati rimanenti, recuperandoli dal database ufficiale.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Cebia REPORT poskytuje hodnocení kontroly stavu tachometru, kontroly v databázi odcizených vozidel, závazků u leasingových společností, kontroly správnosti VIN a roku výroby vozidla. Celkové hodnocení je vyjádřeno počtem hvězdiček (0–5) a slovním hodnocením.',
'308350422':'Totale IVA inclusa',
'-24797068':'È possibile applicare lo sconto se si dispone di:',
'-1323263000':'Máte u nás předplacenou službu FLEX a použijete kód uvedený na daňovém dokladu.',
'-724069837':'un codice sconto per un acquisto gratuito.',
'-127346860':'un codice sconto di uno dei nostri partner.',
'-47884841':'IVA esclusa',
'881014685':'Totale IVA esclusa',
'-15294371':'Numero di partita IVA non confermato',
'1286068311':'Il database ufficiale dei contribuenti IVA non ha confermato il numero di partita IVA inserito. Non possiamo quindi completare automaticamente i dati. Verificare il numero di partita IVA o inserire manualmente i dati della propria azienda.',
'-172351568':'Il database non risponde',
'1839952435':'Il database ufficiale dei contribuenti IVA non risponde. Inserire manualmente i dati della propria azienda.',
'1678298800':'Esempio di Report',
'-654354054':'La quantità di informazioni disponibili varia da veicolo a veicolo.',
'-1069736045':'<label className="color-orange">Portali pubblicitari</label> europei ',
'1068440731':'Fino a <label classname="color-orange">12 registri</label>',
'-534710218':'Origine ed età del veicolo',
'1267127794':'Tutti i controlli elencati in questa pagina possono essere eseguiti per il veicolo specificato in base al numero di TELAIO. I risultati dei controlli saranno disponibili in un Report a pagamento. La quantità di informazioni contenute nel Report a pagamento può variare da veicolo a veicolo.',
'-186613120':'Non è stato utilizzato come taxi in Repubblica Ceca.',
'1880784559':'Disponibile inserendo il numero di targa del veicolo',
'2085931506':'<label classname="color-orange">Disponibile</label>',
'-1765351165':'Oltre <label className="color-orange">200 milioni di dati registrati</label>',
'1815202337':'Dati <label className="color-orange">da UE e USA</label>',
'-555727222':'Dati <label classname="color-orange">da 18 Paesi</label>',
'-1798142062':'Grafico delle registrazioni di stato del contachilometri',
'-1828887347':'TELAIO (numero di identificazione del veicolo):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Il controllo dei taxi in Slovacchia può essere effettuato inserendo il numero di targa del veicolo sul sito <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>.',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Controllare ora il numero di TELAIO',
'-1620583517':'Cronologia',
'239060530':'Cronologia dei controlli del TELAIO',
'1122350112':'Una panoramica della cronologia di tutti i controlli del TELAIO in corso di verifica.',
'-1288575114':'Non è stato studiato',
'-14589513':'Cliente',
'-1170545783':'Verificato da voi',
'452068758':'Partner contrattuale Cebia',
'-1710315139':'Il report contiene i record trovati nella storia del veicolo. I più comuni sono i record dei contachilometri, i dati sui danni del veicolo, i record delle verifiche tecniche, le fotografie del veicolo e i registri di vendita del veicolo. Per saperne di più, consultare <a href="/payed/detail/exampleIT" target="_blank">Esempio di Report</a>. ',
'1607430837':'Panoramica della cronologia',
'-293598683':'Data di cancellazione',
'-1790772829':'Data della prima immatricolazione del veicolo',
'743843679':'Immatricolazione del veicolo',
'1370884754':'Registrato come',
'644449918':'Colore dell\'auto al momento dell\'immatricolazione',
'-583571734':'Data di fabbricazione del veicolo:',
'-1219924366':'Età del veicolo',
'1595420674':'Prodotto con in dotazione',
'1151043140':'Tempo di produzione dopo la prima registrazione',
'708774908':'Albania',
'1637995084':'Macedonia del Nord',
'1001888760':'Danno economico totale *',
'1034299871':'Evento assicurativo - danno economico totale*',
'1209738229':'Evento assicurativo',
'913822268':'Nota',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praga 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'Convalidando l\'ordine, accetto <a href="https://www.cebia.cz/pruvodce?id=160&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">le condizioni generali</a> del servizio, comprendo <a href="https://www.cebia.cz/pruvodce?id=161&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">la politica di trattamento dei dati personali</a> e mi impegno a effettuare il pagamento.',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Data del primo registro indicata durante la registrazione',
'280331577':'Doba od výroby po 1. přihlášení',
'1163480388':'V dostupných databázích nejsou k dispozici žádné záznamy.',
'-1319845523':'Per il veicolo, registriamo il calcolo del danno creato dalla compagnia assicurativa o dall\'autofficina, di cui non conosciamo il dettaglio (luogo del danno). Si prega di prestare attenzione all\'ispezione del veicolo.',
'779148402':'Per il veicolo registriamo un evento denunciato dalla compagnia assicurativa di cui non conosciamo i dettagli (luogo del danno). Si prega di prestare attenzione all\'ispezione del veicolo.',
'1115915387':'Doprava zdarma',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Neověřeno',
'-1464599260':'Práce',
'-592361226':'Lakování',
'1085310093':'Tipo di controllo:',
'1984850101':'Data di controllo ',
'-1502399543':'Visualizza il protocollo',
'-1961590845':'Databáze Ministerstva dopravy je momentálně nedostupná, zkuste to prosím později.',
'-1065211891':'Protokol není dostupný',
'1708745339':'K této prohlídce není detail protokolu dostupný.',
'1087254174':'Získat kompletní prověření',
'2096754301':'Základní prověření vozidla ke dni:',
'926095479':'Co znamená základní prověření vozidla?',
'-254140743':'Základní prověření obsahuje tyto kontroly:',
'1362304971':'Pro prověření celé historie vozidla doporučujeme zakoupit Kompletní prověření vozidla, které navíc může obsahovat <b>kontrolu poškození, historii inzerce, fotografie vozidla, servisní historii</b> a mnoho dalšího.',
'-267677252':'Základní prověření vozidla',
'-70564196':'- 10 EUR',
'1350105297':'Sei soddisfatto del risultato del controllo storico del veicolo? Saremo felici di ricevere un tua valutazione.',
'1914051118':'Valutazione',
'1786612140':'Siamo spiacenti che il controllo della storia del veicolo non abbia soddisfatto le vostre aspettative. Per favore, scriveteci cosa dovremmo migliorare la prossima volta.',
'-1265184280':'Errore di invio. Ripetere l\'azione.',
'-669850784':'Maximální počet znaků je 2000.',
'1801814710':'<strong classname="color-green">Grazie!</strong> La tua valutazione è stata inviata e ci aiuterà a migliorare i nostri servizi.',
'-883106932':'I registri provengono da <label classname="color-orange">32 Paesi</label>',
'1408314893':'Cebia raccoglie i dati dei veicoli di oltre 32 Paesi. Il più delle volte i dati provengono da registri ufficiali, officine, banche dati della polizia, banche dati di veicoli danneggiati, compagnie di assicurazione o portali pubblicitari.',
'-1412306493':'Sì. Ogni veicolo viene controllato con database di registrazioni provenienti da oltre 32 Paesi (tra cui Germania, Danimarca e Stati Uniti). Dopo aver inserito il numero di TELAIO, è possibile scoprire quali record sono stati trovati e di quale periodo nel Report informativo gratuito.',
'1798507708':'In questo caso, nei nostri database di oltre 32 Paesi, che comprendono 200 milioni di persone e gli Stati Uniti, non abbiamo trovato alcuna traccia di danni al veicolo controllato. Ciò aumenta notevolmente la probabilità che l\'auto da verificare non abbia subito incidenti in passato.',
'568601301':'Storia del veicolo da oltre <strong>32 Paesi</strong>',
'899127599':'32 Paesi',
'-1456368295':'Storia del veicolo da oltre 32 Paesi',
'1333238000':'Oltre 30.000.000 di auto controllate',
'426416063':'I registri provengono da 32 Paesi',
'-1549476292':'Cebia controlla ogni auto nel suo database di veicoli danneggiati con oltre 200 milioni di registrazioni da 32 Paesi? La maggior parte dei registri (62%) proviene da Francia e Germania.',
'-924882043':'Nei database dei veicoli danneggiati di oltre 32 Paesi, che comprendono 200 milioni di registrazioni di danni, <span class="color-red">sono stati rilevati</span> uno o più danni al veicolo oggetto della verifica. Ecco i dettagli:',
'-1366090835':'Nei database dei veicoli danneggiati di oltre 32 Paesi, che comprendono 200 milioni di registrazioni di danni, <span class="light-green">non è stato trovato</span> alcun danno al veicolo oggetto della verifica. ',
'931273814':'Storia dell\'auto da oltre 32 Paesi',
'-334327155':'<p>Verificheremo il veicolo nei database dei veicoli danneggiati di oltre 32 Paesi con oltre 200 milioni di record di danni.</p><p>Nella sezione a pagamento è possibile scoprire se il veicolo è registrato come danneggiato in questi database. Alle registrazioni dei danni trovate possono essere allegate informazioni dettagliate come la data dell\'incidente, la lettura del contachilometri, l\'entità del danno, l\'importo del danno o le foto dell\'auto.</p>',
'2078676994':'Documenti di servizio',
'503491296':'OK',
'1704037376':'Annulla',
'-922638497':'verificato',
'-2077712313':'fino a 12 record',
'-855779034':'Prověření již bylo <strong className="ml-5">provedeno</strong>',
'131879595':'Verifica <strong classname="ml-5 mr-5">a pagamento </strong> secondo il contratto',
'-2034839108':'PDF',
'2049708688':'Nessuna registrazione',
'1916268628':'Olanda',
'-1425396841':'Cebia',
'-1521903902':'Numero di protocollo',
'1485582239':'Formato e-mail errato.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Avete un codice sconto che vi dà diritto a un acquisto gratuito. Applicare inserendo il "Report ID" nel campo dello sconto.',
'-675647872':'In tutti questi casi, l\'utente inserisce il proprio Report ID nell\'apposito campo della <a href="/" target="_blank">it.cebia.com</a> e ottiene l\'accesso immediato alle informazioni acquistate, a cui può tornare in qualsiasi momento entro 30 giorni. ',
'1080548025':'ATTENZIONE: Questo potrebbe essere un <strong>TELAIO sbagliato</strong>. Controllare il TELAIO o <a href="mailto:rokvy@cebia.cz?subject=Information-system-support-ATR-identification&amp;body=VIN/TELAIO: {0}">contattare l\'assistenza</a>.',
'-439637411':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Nenalezen rok výroby. <a href=\'mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR rokvy&body=VIN:',
'-43065820':'Nebyly nalezeny žádné záznamy o stáří či původu vozidla. Pochází-li vozidlo ze zahraničí, vždy si vyžádejte a pečlivě zkontrolujte originální dokumentaci k vozidlu (např. servisní knihu nebo technický průkaz).<br/><br/>Pokud chcete mít jistotu, že vůz pochází z deklarované země a není odcizený, můžete využít službu prověření původu v zahraničí <a href="https://www.cebia.cz/sluzby/provin" target="_blank" class="Link Link--lightBlue">PROVIN</a>.',
'-1362534546':'Pokud bylo vozidlo v minulosti inzerováno nebo provedena prohlídka, zobrazíme informace a fotodokumentaci.',
'431007496':'Záznam',
'-1525511285':'I dati della carta di circolazione provengono dalle autorità nazionali e riflettono lo stato al momento della prima immatricolazione del veicolo nel paese.',
'1151204976':'Il TELAIO (numero di identificazione del veicolo) è un codice unico di 17 cifre che identifica in modo unico e univoco il veicolo. È stampato direttamente sulla carrozzeria del veicolo e tutti i documenti del veicolo sono registrati con questo codice.',
'205732919':'Il TELAIO è il numero identificativo del veicolo, un codice unico di 17 cifre che identifica in modo unico e univoco il veicolo.',
'1905716471':'*) La definizione del <strong>Danno economico totale</strong> può variare tra diversi stati e compagnie di assicurazione. Anche se il danno viene valutato in questo modo, ciò non significa necessariamente che il veicolo sia inutilizzabile o irriparabile. Infatti, questo rappresenta un danno al veicolo il cui ripristino è considerato non economicamente conveniente secondo le condizioni dell\'assicurazione (cioè il costo del ripristino si avvicina o supera il valore del veicolo al momento del sinistro). In ogni caso, raccomandiamo un\'ispezione dettagliata del veicolo.',
'-199845800':'Il numero di caratteri deve essere 10.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'Il <a href="/detailArticle/dove-posso-trovare-il-codice-vin" target="_blank">TELAIO</a> è il <a href="/detailArticle/dove-posso-trovare-il-codice-vin" target="_blank">numero identificativo del veicolo</a>. Si tratta di un codice univoco composto da 17 lettere e numeri che i produttori assegnano a ogni veicolo prodotto. Il TELAIO si trova di solito nella parte bassa del parabrezza (leggibile dall’esterno) o direttamente sulla carta di circolazione.',
'1682180910':'<p>Nei database disponibili, non abbiamo registrazioni dei danni al veicolo verificato. Il numero TELAIO che avete inserito potrebbe essere errato.</p><p>Avvertenza: Il sistema Car History potrebbe non avere accesso a tutte le informazioni sui danni al veicolo, alcuni danni potrebbero non essere segnalati alle compagnie di assicurazione o potrebbero non essere disponibili durante la verifica online.</p>',
'-1276466490':'La TELAIO fornita potrebbe essere errata',
'-1892907546':'Non è stato possibile trovare documenti storici per questo TELAIO.',
'1843204209':'Continua',
'-716345220':'Verificare un altro TELAIO',
'1091884080':'Consigliamo di verificare il TELAIO',
'-287497170':'Controllo dei fermi amministrativi',
'1289833880':'Fermo trovato',
'466639297':'Fermo non trovato',
'-577812113':'Numero di fascicolo',
'231435384':'La verifica dei diritti di pegno non è attualmente disponibile. Si prega di provare a ricaricare la pagina dopo alcuni minuti.',
'-941220732':'Il veicolo è oggetto di uno o più diritti di pegno negli Stati menzionati e esiste la possibilità di sequestrarlo in caso di eventuale procedura esecutiva della persona indicata nel registro dei pegni. L\'acquisto di un veicolo con un diritto di pegno può essere molto rischioso. I dati sono ottenuti dai registri ufficiali dei pegni del paese in questione.',
'2016489332':'Ecco il risultato della verifica se il veicolo non è oggetto di un fermo amministrativo negli Stati menzionati e, quindi, non c\'è rischio di confisca in caso di procedura esecutiva potenziale nei confronti del proprietario attuale. I dati sono ottenuti dai registri ufficiali dei Paesi elencati.',
'1736313716':'Přílohy k článku',
'1021804797':'Verifica <strong>gratuita</strong> entro i limiti del contratto',
'749816957':'Può essere controllato',
'981194702':'Controllo dei pignoramenti',
'-1417536272':'Controllo del privilegio',
'-323399576':'Sì, certo che è possibile. Se si sospetta che il risultato contenga informazioni errate, si prega di contattare il nostro servizio clienti: <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>.',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Číslo smlouvy (je-li k dispozici)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Operativo come taxi nel',
'-137639801':'Report ID (compilare in caso di reclamo)',
'-1619027976':'Nota: il veicolo potrebbe non essere stato utilizzato come taxi per l\'intero periodo.',
'-1010997259':'Per imprenditori',
'503941074':'Informazioni dettagliate e dati sul veicolo verificato saranno disponibili dopo il pagamento del servizio.',
'1088246106':'La panoramica Car History include:',
'-963915529':'Danni nascosti del veicolo',
'-1603961223':'<label classname="color-orange">Disponibile</label>',
'-1940014640':'Informazioni importanti sul veicolo',
'1785662376':'Služby',
'1913348655':'Volete controllare più veicoli a un prezzo più basso? Ottenete l\'accesso per le imprese e <b>risparmiate fino al 50%</b> sui costi di verifica. Vi preghiamo di fornirci i vostri dati di contatto, e vi contatteremo con un\'offerta personalizzata.',
'1723999193':'Disponibile dopo il pagamento',
'1593405846':'Pojištění vozidla',
'670508858':'È possibile utilizzare un solo codice sconto per ogni ordine. I buoni sconto non possono essere aggiunti o combinati tra loro. Lo sconto vale solo per Car History Report.',
'1693716169':'Metodo di pagamento',
'-503153747':'Car History Report',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'SCONTO 20 % per la prossima verifica',
'-1870036897':'Se avete già fatto ispezionare un\'auto da noi, inserendo il numero di coupon (fornito nell\'e-mail ricevuta) entro 30 giorni dall\'acquisto riceverete uno sconto sulla prossima ispezione.',
'163946327':'Acquistando un controllo del veicolo si ottiene uno sconto di 50% sul prossimo acquisto. È possibile riscattare lo sconto inserendo il proprio Report ID nel campo del codice sconto nella sezione "Pagamento" del prossimo acquisto.',
'858336732':'50% di sconto sulla verifica successiva',
'214251926':'Acquistando un controllo, riceverete automaticamente uno sconto sul successivo controllo per qualsiasi altro veicolo. Lo sconto è valido per 30 giorni dalla data di acquisto.',
'1317784962':'10% di sconto sulla verifica successiva',
'641443144':'20% di sconto sulla verifica successiva',
'-1318469146':'30% di sconto sulla verifica successiva',
'1939643950':'40% di sconto sulla verifica successiva',
'-1376459678':'Un altro controllo <br> <b>con uno sconto!</b>',
'-937620931':'Dati del veicolo',
'514768619':'Verifichiamo l\'autenticità del VIN',
'-916328171':'Verifichiamo l\'identità del veicolo',
'1481465091':'Controlliamo l\'equipaggiamento del veicolo',
'-276782700':'Cerchiamo nei database dei veicoli danneggiati',
'1544438189':'Controlliamo i registri delle compagnie assicurative',
'2021669548':'Cerchiamo la storia dei portali di aste',
'2022162632':'Controlliamo i registri dei centri di assistenza autorizzati',
'1083617557':'Controlliamo i registri dei centri di assistenza non autorizzati',
'993080480':'Cerchiamo nei database della polizia i veicoli rubati',
'-1240510013':'Cerchiamo nei database privati i veicoli rubati',
'-1027936662':'Verifichiamo l\'età del veicolo',
'1299172359':'Verifichiamo l\'origine del veicolo',
'413685839':'Cerchiamo libri di servizio elettronici',
'-473666121':'Cerchiamo registri automobilistici statali',
'-254920359':'Cerchiamo nei database statali dei veicoli taxi',
'-811021673':'Verifica degli eventi di convocazione europei',
'1923526539':'Controlliamo la sicurezza del veicolo',
'-1424413327':'Controlliamo i database dei record di chilometraggio',
'734665361':'Controlliamo i registri delle società di leasing',
'684288091':'Cerchiamo documenti dalle stazioni di controllo tecnico',
'-1633964825':'Cerchiamo i dati delle stazioni di misurazione delle emissioni',
'-354982402':'Cerchiamo la storia dei portali pubblicitari',
'255365524':'Cerchiamo nel database dei rivenditori di veicoli nuovi',
'936372740':'Effettuiamo ricerche nei database dei concessionari di veicoli usati',
'-1491634158':'Controlliamo i registri delle società di autonoleggio',
'-1425645764':'L\'operazione potrebbe richiedere diversi minuti',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Creiamo un rapporto',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Condividi l\'elenco',
'-2142411619':'Per condividere il Report, copiare il link qui sotto e inviarlo a chiunque con cui si desideri condividerlo.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Noi raccomandiamo',
'-1025377658':'articolo',
'-909166524':'articoli',
'-1998523642':'articoli',
'-613016004':'Offerte scontate',
'1083497926':'Altri servizi',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Prossimo rapporto',
'-901212011':'per',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Selezionare una delle opzioni.',
'1917667238':'Sì',
'-1946020034':'Non so',
'-1737499797':'Il veicolo è o è stato immatricolato in Italia?',
'-1292409486':'Stiamo controllando le foto, sarete informati dell\'esecuzione tramite e-mail.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Attenzione:</span> le informazioni su tutti i danni al veicolo potrebbero non essere disponibili nel sistema, alcuni danni non vengono segnalati alle compagnie assicurative o non sono disponibili come parte di un controllo online. Pertanto, consigliamo un\'attenta ispezione del veicolo, idealmente con l\'aiuto di un meccanico qualificato o presso il centro di assistenza di tua scelta. Tale ispezione potrebbe rivelare danni aggiuntivi o escluderli.',
'-1943079155':'<span class="warning">Nota:</span> se le foto non corrispondono al veicolo in esame, vi preghiamo di segnalarcelo via e-mail all\'indirizzo <a href="mailto:info.it@cebia.com">info.it@cebia.com</a>.',
'707486310':'<span class="warning">Nota:</span> non tutti i registri di manutenzione del veicolo potrebbero essere disponibili nel sistema. Si consiglia di confrontare i registri con il libretto di manutenzione o di contattare il servizio autorizzato pertinente del marchio con una richiesta di un estratto dal libretto di manutenzione digitale del veicolo.',
'-1997337578':'La panoramica di un\'evoluzione del contachilometri del veicolo contiene valori ottenuti dai sistemi informativi disponibili dei partner cooperanti. In base allo sviluppo del grafico, puoi giudicare da solo se c\'è un sospetto di manipolazione dello stato del contachilometri o meno.',
'-282194273':'Raccomandazione',
'-1564285314':'È possibile che non tutti i dati relativi allo stato del contachilometri relativi alla cronologia di utilizzo del veicolo siano disponibili nel sistema.',
'993789697':'Se il veicolo non registra in modo continuativo l\'intero chilometraggio nel tempo, <b>consigliamo</b> di verificare anche i registri del contachilometri presenti sul libretto tagliandi, oppure di far controllare il veicolo presso un\'officina autorizzata. Raccomandiamo questa procedura soprattutto per i veicoli in cui trascorrono più di due anni tra le letture del contachilometri.',
'2129711518':'Si tratta di informazioni disponibili sui parametri di base, standard e possibilmente sulle dotazioni aggiuntive del veicolo dalla fabbrica. Le modifiche al veicolo, come la modifica del lato del volante, possono essere rilevate controllando le dotazioni.',
'-593531916':'<span class="warning">Avvertenza:</span> In alcuni casi, l\'equipaggiamento completo del veicolo potrebbe non essere disponibile nel sistema.',
};

const TRANSLATIONS_FR = {
'1901555939':'Code VIN',
'-971921755':'Marque du fabriquant',
'1891129765':'Modèle',
'691501523':'Type de carrosserie',
'-1026019663':'Type de véhicule',
'274952045':'Carburant',
'-348535418':'Puissance',
'1000727131':'Cylindrée du moteur',
'-440181428':'Date de fabrication',
'841597003':'Partager',
'-762872471':'Afficher',
'-1615835216':'Lien',
'-466013687':'Fermer',
'-1655267775':'Copiez le lien',
'-1448849529':'Puis-je vérifier la voiture même sans connaître le code VIN ?',
'-1310016716':'Inspection du véhicule',
'1410745983':'en ligne',
'-1803530129':'Vygenerovat Cebia REPORT',
'934563996':'Résultat de la vérification ',
'-1102433484':'Présentation du rapport',
'-1242381959':'Historique du véhicule',
'417677035':'Contrôle des enregistrements du véhicule',
'-115937358':'État du véhicule',
'457834942':'FAQ',
'226942208':'Qu\'est-ce qu\'un code VIN ?',
'1739046820':'Combien coûte la vérification ?',
'16204687':'Comment puis-je payer ?',
'-1607701055':'Comment afficher le rapport ?',
'-1969713941':'Peut-on porter réclamation au sujet du rapport ?',
'1598751038':'Où puis-je trouver le rapport ID&nbsp;?',
'-1206763576':'Plus d\'informations',
'-729900752':'Paiement en ligne',
'671435147':'Entrez le numéro du code de réduction',
'-2004947080':'Entrez le rapport ID',
'-1083354004':'RECALCULER',
'-1589008349':'Nom',
'-704955254':'Nom de famille',
'-1631818401':'Rue et numéro de rue',
'1006101298':'Ville',
'1063186093':'Pays',
'287700219':'Code postal',
'-1411201334':'Numéro d\'identification fiscale',
'513366821':'N° TVA',
'-102071178':'Nom de l\'entreprise',
'-1882309597':'SIRET',
'-1515081676':'SIRET',
'1440993804':'Email du contact',
'-1405758046':'Numéro de téléphone du contact',
'1366947192':'Votre numéro de téléphone',
'-490851561':'20 % de réduction sur votre prochain achat',
'-108743580':'CZK',
'786987358':'Acheter',
'-1887658751':'Les résultats sont disponibles immédiatement après le paiement',
'-1018049108':'Les résultats sont généralement disponibles dans les 60 minutes',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'virement',
'-1663277':'Les résultats seront disponibles sous 48 heures',
'1626540224':'V hotovosti v sídle Cebia',
'72162446':'Inspecter le véhicule',
'757687806':'Aperçu des informations gratuites',
'1954205527':'Où puis-je trouver le code VIN ?',
'1603459473':'Accès après paiement',
'2038366987':'Voir le rapport',
'-374549211':'Où puis-je trouver le rapport ID&nbsp;?',
'1262814082':'Saisissez le code VIN',
'-15600132':'Date de la 1ère inscription',
'1535680605':'Rapport ID&nbsp;:',
'-1091217450':'Validité du rapport jusqu\'au&nbsp;:',
'-1264594150':'Date d\'activation du rapport&nbsp;:',
'-701611594':'Désolé, le véhicule ne peut pas être inspecté.',
'160166931':'Le propriétaire actuel du véhicule ne souhaite pas que les données du véhicule soient affichées.',
'-351255422':'L\'annonce que vous consultez ne correspond pas au code VIN du véhicule répertorié.',
'1704462566':'Le code VIN complet n\'est pas répertorié dans l\'annonce du véhicule.',
'366367808':'Combien de temps faut-il pour recevoir le rapport ?',
'2136729030':'Code VIN :',
'400669011':'Vous avez droit à une remise si :',
'-219050562':'Blog',
'1449193326':'À propos de nous',
'1097675154':'Contact',
'2062322678':'Actualités',
'-1263831198':'Histoire de l\'entreprise',
'1166836894':'Nos partenaires',
'-1532347183':'Section médias',
'717698781':'Carrière',
'1620687461':'Vozidlo je evidované jako odcizené. Cebia REPORT nelze vytvořit',
'1365729306':'Číslo CR:',
'-89669431':'Année de la première immatriculation :',
'2130038984':'Etat du compteur kilométrique :',
'2089870291':'Auto pod kontrolou s aplikací carolina',
'1333652846':'Stáhnout zdarma',
'1347577444':'La longueur du code VIN doit être de 17 caractères',
'622759132':'Lien copié',
'1714373031':'Ils peuvent s\'appliquer à la voiture inspectée',
'1227286459':'Ordre de rappel trouvé',
'-477335598':'ORDRE DE RAPPEL',
'-343698081':'Rien n\'a été trouvé lors de la vérification de la voiture ',
'-1536285860':'Aucun ordre de rappel',
'-2052297753':'Date de l\'avis',
'1522685090':'Detail',
'444715065':'Plus d\'informations',
'-394230090':'Vous devez confirmer les données saisies.',
'1224828864':'Année de la première immatriculation incorrecte.',
'-470093943':'Veuillez entrer l\'année de la première immatriculation.',
'1035831822':'État du compteur kilométrique incorrect.',
'-1161922611':'Veuillez entrer l\'état du compteur kilométrique.',
'-1383225585':'Entrez l\'année de la première immatriculation',
'1111232857':'Année de première immatriculation',
'-1998715395':'Entrez l\'état du compteur kilométrique',
'-1962337257':'Kilométrage',
'-1888253249':'Spécifications du modèle de voiture',
'-339477539':'- 300 Kč',
'917746804':'Rien de plus simple :',
'1405508217':'Předběžná konzultace ZDARMA',
'127414665':'Comment appliquer la remise ?',
'-2081078087':'VYBRAT TECHNIKA',
'1899967331':'Le nombre maximum de caractères doit être de 20.',
'1699452695':'Chybný kód banky',
'1812875983':'Prosím zadejte položku Číslo účtu.',
'-670727524':'Prosím zadejte položku Číslo pojistné smlouvy.',
'1884544037':'ePojisteni.cz',
'138266606':'Valable jusqu\'au',
'1881823014':'Podmínky pro vrácení peněz:',
'-1548724023':'Vozidlo musí být pojištěno minimálně 30 dní',
'1331666705':'Erreur de sauvegarde. Recommencer.',
'-1393210532':'Číslo účtu',
'439576396':'Vaše číslo účtu',
'773379637':'Číslo pojistné smlouvy',
'-1281605763':'Uplatnit vrácení peněz',
'-1670752753':'Sleva na prověření v zahraničí',
'1496813111':'- 550',
'-856872708':'Prix de base',
'616158592':'RABAIS',
'-581170701':'Prix après remise',
'-437309148':'avec TVA',
'-1731075532':'Video',
'-189892310':'Commander',
'98918066':'Procédure de commande :',
'-1944610133':'Remise sur un prochain achat',
'-621660650':'Numéro du code de réduction :',
'-1845841489':'Découvrez une autre voiture avec une réduction',
'1171588656':'Sleva na zabezpečení vozidla',
'1440663595':'- 850',
'-1503110723':'Sleva na fyzickou prověrku',
'-675817237':'- 925',
'986037818':'Dommages trouvés',
'873964955':'Date',
'790922325':'Calcul des frais de réparation',
'-1825735064':'Type d\'événement',
'486919475':'Lieux de dommages enregistrés',
'1685506455':'La voiture est financée',
'2039551191':'Aucun financement trouvé',
'-570695392':'dans les bases de données actuellement disponibles',
'-765744228':'Annonces',
'659651744':'Date de publication de l\'annonce',
'-695638522':'Kilométrage indiqué',
'772986188':'Prix indiqué',
'419477574':'Origine et âge du véhicule connus',
'-109775782':'Date de fabrication selon le fabricant',
'-218677523':'Première immatriculation',
'-340115089':'Première immatriculation en République tchèque',
'1192563560':'Première inscription en Slovaquie',
'1650211924':'Fabriqué pour le marché ',
'1812132824':'Côté direction ',
'-1229728494':'Matériaux (liste)',
'-1147664160':'Travail (liste)',
'708828581':'Exploité comme taxi',
'284182756':'Le véhicule est enregistré comme volé',
'1244175337':'Non enregistré comme volé',
'791649880':'Source',
'705052471':'Jour',
'1239149764':'Vérification des identifiants',
'-799257304':'Modèle:',
'-1964821919':'Date de première immatriculation :',
'-1530361098':'Prix du véhicule neuf',
'68348546':'Prix actuel du marché',
'763547310':'Date de première immatriculation :',
'1968050624':'Enregistrer',
'-1300982647':'Liste des équipements selon le fabricant',
'397739850':'Vérification de la couleur du véhicule',
'479427038':'Disponible',
'2038192723':'Contrôle de sécurité du véhicule',
'-757741002':'Fiche technique du véhicule',
'278755414':'Instructions pour l\'identification de la voiture',
'1529692191':'Couleur du véhicule',
'-1171491320':'Identification du véhicule',
'1549116932':'Inventaire des équipements du véhicule',
'-1985480381':'Autre',
'-1354462940':'VÉRIFIEZ :',
'-690036295':'Date d\'inscription:',
'1506895356':'Type de sécurité du véhicule :',
'-749715579':'Code d\'identification :',
'-329154043':'Couleur du véhicule :',
'1441236976':'Informations relatives au marquage des vitres',
'729768522':'Marquages sur les vitres',
'1812345820':'NON',
'847948448':'MÁTE ZÁJEM O ZABEZPEČENÍ VOZIDLA?',
'-1899070354':'Description technique - de base',
'-953303':'Description technique - étendue',
'1237026086':'Valeur incorrecte du Type de véhicule.',
'-259360107':'Veuillez entrer le Type de véhicule.',
'-1906046398':'Le nombre minimum de caractères doit être 1.',
'-69524395':'Le nombre maximum de caractères doit être de 30.',
'1663292305':'Veuillez saisir la marque.',
'814923836':'Veuillez saisir le modèle.',
'-1985695287':'Veuillez saisir les Détails de la désignation.',
'244480988':'Marque',
'1602844398':'Désignation détaillée',
'-1749998805':'Sélectionnez un véhicule',
'-1162182506':'Type de véhicule',
'1104651533':'certificat d\'immatriculation - code vin',
'-1898506589':'pare-brise de la voiture - code vin',
'216861745':'Veuillez confirmer les données saisies !',
'-1328097963':'Le nombre maximum de caractères est de 9.',
'1678639740':'Veuillez saisir le numéro de téléphone.',
'1303052228':'Veuillez entrer le nom.',
'1792286505':'Le nombre maximum de caractères est de 128.',
'2122707327':'Veuillez entrer le nom de famille.',
'-938825602':'Le nombre maximum de caractères est de 64.',
'1683031395':'Veuillez entrer la rue et le numéro de rue.',
'661065202':'Veuillez entrer la ville.',
'-83271898':'Veuillez saisir un code postal.',
'1018067916':'Le nombre maximum de caractères est de 5.',
'12673416':'Le code postal est dans un format incorrect.',
'154576238':'Le rapport ID est dans un format incorrect.',
'-581020701':'est déclaré par le constructeur du véhicule conformément à la loi chaque fois que la santé, la sécurité et l\'environnement sont en danger. En règle générale, ils concernent des défauts de fabrication du véhicule, qui n\'apparaissent que lors de son fonctionnement, et de cette manière, le constructeur assure leur rectification gratuitement. Les informations sont tirées du site <a href="https://car-recalls.eu/" target="_blank">Car-Recalls.eu</a> à l\'aide du système RAPEX.',
'1969671182':'La liste des rappels répertoriés a été générée <strong>en fonction du modèle, de la marque et de l\'année de fabrication du véhicule testé</strong>, et non en fonction de son code VIN. Par conséquent, vérifiez toujours dans les détails de l\'événement s\'il concerne également le véhicule contrôlé. Dans le même temps, vérifiez auprès du vendeur du véhicule ou auprès d\'un centre de service agréé si le véhicule a fait l\'objet d\'un rappel effectif.',
'-2076502822':'Un rappel est émis par le constructeur du véhicule, comme l\'exige la loi, si le véhicule présente un défaut de fabrication qui menace la santé, la sécurité ou l\'environnement. Dans le cadre du rappel, le défaut est corrigé gratuitement dans un centre de service agréé. Les données sont fournies par le portail <a href="https://car-recalls.eu/" target="_blank">Car-Recalls.eu</a> à l\'aide du système RAPEX.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Uzavřete pojistku prostřednictvím portálu <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> a získejte zpět peníze za prověření vozidla.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'5180415':'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'-312241960':'* Les bases de données privées permettent généralement à quiconque de saisir des informations sur le vol.',
'1225691915':'Dans les bases de données disponibles, il a été constaté que le véhicule <span class="warning"> était ou est encore exploité comme taxi</span>. Vérifiez soigneusement l\'état technique de la voiture.',
'-742868638':'Votre adresse e-mail',
'2033866416':'Paiement en ligne par carte',
'138706621':'Transfert bancaire en ligne',
'-1634274740':'Zobrazit Cebia REPORT',
'1047356790':'Veuillez entrer la date de la première immatriculation.',
'-39394372':'Annonces de véhicules avec moins de correspondances',
'-862383013':'Annonce de véhicules similaires',
'-233652021':'HISTORIQUE DU VÉHICULE',
'271847434':'Cebia REPORT',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'Při objednání kontroly vozu na Automato.cz vložte do určeného pole v košíku slevový kód <b>AUTOTRACER</b> a sleva vám bude automaticky odečtena z ceny.',
'-1554923364':'(cesta technika zdarma)',
'288042578':'Veuillez saisir le nom de l\'entreprise.',
'-1335200216':'Valeur d\'identification personnelle incorrecte.',
'1693391088':'L\'e-mail est dans un mauvais format !',
'-766469985':'Veuillez saisir un e-mail',
'-1397806656':'Consentement au traitement des données personnelles',
'-189484387':'S\'abonner',
'1095553640':'Toutes les questions',
'1531150438':'Vous n\'avez pas trouvé votre réponse ?',
'-469136467':'Contactez-nous',
'-461584700':'Avant d\'acheter',
'400498991':'Politique d\'utilisation des cookies',
'1329764956':'Qu\'est-ce qu\'un code VIN ?',
'-394767958':'Achat',
'181431424':'Après le paiement',
'1649241229':'Le code VIN est apposé directement sur le véhicule, ou vous pouvez le trouver dans le certificat d\'immatriculation et d\'autres documents du véhicule. Si vous souhaitez vérifier une voiture en vente sur Internet, demandez-en le code VIN au vendeur.',
'1774694482':'D\'où viennent les enregistrements de véhicules ?',
'-1122721284':'Cebia inspecte-t-il également les véhicules à l\'étranger ?',
'437878122':'Quelles informations sur le véhicule me seront-elles fournies ?',
'850725814':'La gamme d\'informations varie pour chaque véhicule en fonction de son âge et de son historique. Vous découvrirez gratuitement une gamme d\'informations sur le véhicule que vous pourrez vérifier immédiatement après avoir saisi le code VIN dans l\'aperçu des informations trouvées.',
'-656971513':'À quoi ressemble le rapport ?',
'966688141':'Le prix de l\'inspection du véhicule dépend de la quantité et de l\'étendue des informations disponibles sur le véhicule inspecté. Vous découvrirez une gamme d\'informations sur le véhicule soumis et le prix du contrôle apparaîtra immédiatement après avoir saisi le code VIN du véhicule dans l\'aperçu des informations trouvées.',
'764893782':'Vous pouvez payer en ligne ou via Google Pay et Apple Pay. Les paiements sont effectués par la passerelle de paiement sécurisée GoPay.',
'726713260':'Combien de temps cela prendra-t-il avant que je reçoive le rapport ?',
'1478989945':'Le rapport est généré immédiatement après réception du paiement. Le service fonctionne 24 heures sur 24 et l\'ensemble du processus est automatique.',
'1681038954':'Puis-je entrer les détails de l\'entreprise lors du paiement ?',
'696259005':'Lors du remplissage de vos données, vous pouvez entrer les données d\'identification de votre entreprise. Cependant, seul un reçu fiscal simplifié est généré après paiement. Pour recevoir une facture, contactez-nous à <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>.',
'-1555365601':'Le rapport est généré automatiquement immédiatement après réception du paiement. Le lien vers le rapport et le rapport ID seront également envoyés à l\'adresse e-mail saisie lors de l\'achat. Le rapport peut être affiché jusqu\'à 30 jours après sa création.',
'1984400663':'Le paiement n\'a pas abouti, comment faire ?',
'-956265022':'Si le paiement n\'a pas abouti pour une raison quelconque, revenez en arrière et effectuez à nouveau le paiement. Si une somme a été prélevée sur votre compte, mais que vous n\'avez toujours pas reçu d\'e-mail concernant le paiement, contactez-nous à l\'adresse <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>.',
'-246543694':'Le rapport est disponible pendant 30 jours après sa génération. Vous pouvez le consulter en utilisant le lien envoyé à l\'adresse e-mail saisie lors de l\'achat.',
'592240026':'Je n\'ai pas reçu d\'e-mail, quoi de neuf ?',
'339130199':'L\'e-mail après paiement est généré automatiquement, s\'il n\'est pas parvenu à votre boîte de réception, contactez-nous à <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>. Dans le message, veuillez inclure le rapport ID qui se trouve dans le rapport, ou vos coordonnées.',
'1681612939':'J\'ai saisi une adresse e-mail erronée, comment faire ?',
'-818807180':'Il ne se passe rien. Veuillez nous écrire l\'adresse correcte dans un e-mail à <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a> et inclure dans votre message le rapport ID visible sur le rapport. Nous vous enverrons alors l\'accès à l\'adresse e-mail correcte.',
'-40057886':'Le rapport peut être contesté. Si vous pensez que le rapport contient des informations incorrectes, veuillez contacter notre service clientèle à l\'adresse <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>. Vous pouvez trouver plus d\'informations sur la comment déposer une réclamation <a href="/about#complaints" target="_blank">ici</a>.',
'-755697752':'Puis-je recevoir une facture ?',
'1950759546':'En raison du prix du service, seul un reçu fiscal simplifié est généré automatiquement. Pour l\'envoi d\'une facture, contactez-nous à <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>. Pour accélérer le traitement, veuillez inclure le rapport ID mentionné dans l\'e-mail que vous avez reçu après l\'achat.',
'-1960272511':'Entrez le code VIN',
'-371538970':'Où puis-je trouver le code VIN ?',
'-1956291670':'Comment ça marche ?',
'1850076653':'1ère étape',
'-1260091754':'Données disponibles',
'1279291471':'2ème étape',
'-811487544':'Paiement',
'777550298':'3ème étape',
'-1534039143':'Car History Report',
'495126992':'4ème étape',
'1916048998':'Vérification',
'-580693003':'Contacts',
'-760821457':'Avez-vous besoin de conseils ?',
'1432374431':'Formulaire de contact',
'1838884148':'Réception Cébia',
'1665302400':'Siège central',
'1196114687':'République tchèque',
'-95958777':'Données de facturation',
'1808758025':'Téléphone',
'275235071':'Nous sommes disponibles',
'353260003':'Lun - Ven 8:00 - 16:30',
'822817400':'Vérifier l\'historique du véhicule',
'-1317542970':'Certifié par les clients',
'-1884218466':'Nous vérifions chaque voiture',
'1736624969':'Kilométrage',
'2137107000':'Historique des dégâts',
'647263705':'Véhicule volé',
'-441819024':'Historique d\'entretien',
'982206269':'Historique d\'annonces',
'1753341741':'Utilisation comme taxi',
'-366535509':'Les professionnels utilisent aussi les services de Cebia',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo coopératif',
'1734653310':'Vous êtes arrivé ici par...',
'654946563':'Marques',
'-1398891951':'Vérification du kilométrage',
'1425691903':'33% des véhicules d\'occasion ont un compteur kilométrique falsifié !',
'1803799674':'Vérification de l\'âge et de l\'origine',
'1389120536':'Avec les voitures d\'occasion en provenance de l\'étranger, l\'âge et l\'origine de la voiture sont souvent falsifiés !',
'-2068933994':'Lutte contre le vol',
'-1184837319':'Contrôle de financement',
'-1877453535':'Contrôle des dommages',
'64494271':'20% des voitures d\'occasion importées de l\'étranger le sont après un accident !',
'1051433840':'Vérifiez que la voiture n\'a pas été mise en vente comme accidentée !',
'1076835371':'Estimation du véhicule',
'-1805177459':'Ordre de rappel',
'957124168':'Počet majitelů v ČR',
'-1705145830':'Informations sur le véhicule',
'1890293527':'Contrôle de l\'exploitation comme taxi',
'-13439485':'Veuillez entrer le prénom',
'-1147931879':'Veuillez entrer le nom de famille',
'2144560301':'Numéro de Téléphone invalide',
'-1642977586':'Veuillez saisir le téléphone',
'-1249914076':'L\'adresse e-mail ne respecte pas le format',
'-2038223065':'Veuillez saisir un message',
'810453022':'E-mail',
'-1217737116':'Message',
'-664538889':'J\'accepte le traitement des',
'1322823680':'Envoyer un message',
'56891982':'Le nombre maximum de caractères est de 16.',
'277834303':'Merci',
'552239414':'Votre message a bien été envoyé.',
'1851370681':'Nous vous recontacterons dans les meilleurs délais.',
'1952981238':'Avertissement',
'342579190':'Acheter la vérification',
'1586626737':'Continuer',
'399846737':'Chargement des enregistrements',
'556543048':'Les informations suivantes peuvent être vérifiées :',
'560876430':'Photos et annonces',
'-320410471':'Non disponible',
'1185850210':'Aucun enregistrement de dommage trouvé',
'-903608283':'Extrait de l\'historique du véhicule',
'718416430':'Informations sur le rapport',
'668096075':'Rapport ID pour saisie multiple&nbsp;:',
'-1690204814':'Valable jusqu\'au:',
'-840281270':'Transmission',
'-1901926256':'Données de base sur le véhicule',
'-1550051512':'Type de carrosserie',
'-1043441574':'AVERTISSEMENT:',
'-709435856':'Expérience client',
'-1149927862':'Découvrez ce que les clients pensent de notre service',
'456577623':'Un accident et un compteur falsifié ont été trouvés. Merci.',
'329190632':'A répondu aux attentes, a constaté des dommages au véhicule. Merci.',
'1450297818':'Kontrola odcizení v ČR a SR',
'-1594408882':'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
'341709606':'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Povinné ručení nebo havarijní pojištění musí být sjednané prostřednictvím portálu ePojisteni.cz do 30 dní od zaplacení prověření vozidla v systému AUTOTRACER',
'-355614707':'Nabídka se týká pojištění osobních, užitkových a nákladních automobilů (nevztahuje se na pojištění motocyklů, čtyřkolek, tříkolek nebo přívěsných vozíků a návěsů',
'-134420730':'Nevztahuje se na pojištění uzavřené prostřednictvím portálu ePojisteni.cz před zakoupením prověření vozidla v systému AUTOTRACER',
'1195365944':'Po uzavření pojištění vyplňte číslo účtu a číslo uzavřené pojistné smlouvy a částka Vám bude automaticky zaslaná na Vámi uvedený účet v žádosti po splnění podmínek nejdéle však do 60 kalendářních dnů od zaslání žádosti.',
'-1622287889':'Merci, la demande a été envoyée. Notre personnel vous contactera au plus tard lors du jour ouvrable suivant.',
'1069770504':'Pokud je Vámi vybrané vozidlo individuálně dovezené do ČR, doporučujeme Vám využít ještě službu prověření původu vozidla v zahraničí PROVIN.',
'-121060108':'Prověříme registraci vozidla v zemi původu, zda bylo řádně odhlášeno, zda nebylo vozidlo vyřazeno z provozu z důvodu jeho totální havárie, zda vozidlo není evidováno v daném státě jako odcizené a další skutečnosti.',
'-1158490787':'La remise peut être utilisée dans les 30 jours suivant l\'achat du rapport et ne peut pas être combinée à d\'autres remises.',
'-1471409910':'Chcete mít jistotu, že Vámi vybrané vozidlo není nelegálně předělané?  Doporučujeme Vám využít službu komplexního prověření původnosti identifikátorů vozidla VINTEST.',
'1555104762':'Služba VINTEST poskytuje klientům 100% garanci výsledku fyzického prověření vozidla. Na vozidle provedeme detailní fyzickou expertízu s využitím nejmodernější digitální diagnostiky. Cílem této služby je ověřit, jestli vozidlo má všechny identifikátory (VIN, štítky, svary karoserie) originální a není podezření, že vozidlo, nebo část vozidla pochází z trestné činnosti.',
'735500953':'Les résultats de la requête permettent de savoir si le véhicule fait l\'objet d\'un financement (sous forme de leasing ou de crédit), d\'un gage ou d\'un prêt auprès des entreprises répertoriées. Les données sont obtenues directement à partir des systèmes d\'information de chaque entreprise.',
'-1173444543':'Sociétés de leasing et de crédit',
'-976513658':'*Autopartage = location de voiture à court terme directement auprès du propriétaire (ou covoiturage entre son propriétaire et une personne intéressée par un prêt à court terme).',
'-1479754603':'Il a été constaté dans les bases de données disponibles que le véhicule n\'a pas été et n\'est actuellement pas utilisé comme taxi.',
'-467405909':'Registres de l\'exploitation du véhicule en tant que taxi',
'1930957191':'Les données fournies le sont à titre informatif seulement. Cebia n\'est pas responsable des dommages subis en lien avec l\'utilisation des données communiquées.',
'-1895346741':'Sdělíme vám obvyklou tržní cenu vozidla, pomocí které si ověříte, zda cena oceňovaného vozu odpovídá nabídkové ceně inzerce. Do výpočtu ceny je zahrnuto mimo jiné sledování inzerce ojetých vozidel v ČR i zahraničí včetně sledování cen skutečných realizovaných prodejů. Oceňovací algoritmus pracuje s výpočtovými modely postavenými na dlouhodobém monitoringu trhu, ale není schopen zohlednit fyzický stav vozidla.',
'-985858180':'V ojedinělých případech se může stát, že cenu vozidla nelze určit. Důvodem jsou nedostatečná data pro kvalifikované ocenění vozidla.',
'1915347014':'Zobrazíme vám inzeráty obdobných vozidel, která jsou aktuálně nabízena na trhu, a v některých případech také ceny skutečně prodaných obdobných vozidel (pomocí VIN porovnáváme přesnou specifikaci vozidel). Pomocí inzerce podobných vozidel si můžete porovnat, zda cena ověřovaného vozidla odpovídá podobným vozidlům na trhu. Ušetříme vám spoustu času stráveného vyhledáváním a porovnáváním podobných inzerátů na webu.',
'-767763886':'Aucun enregistrement d\'annonce à propos du véhicule trouvé.',
'797541609':'Vérifiez l\'état et le kilométrage de la voiture mise en vente dans le passé. Si la voiture a été mise en vente avec un kilométrage plus élevé que celui qu\'elle a actuellement, le compteur kilométrique a peut-être été illégalement falsifié.',
'-1902352540':'Approbation des données saisies. Une fois enregistrés, ils ne peuvent plus être modifiés.',
'-2008975653':'Aucun enregistrement n\'a été trouvé à propos de la couleur du véhicule immatriculé de la part du constructeur du véhicule ni à la date à laquelle le véhicule a été immatriculé pour la première fois.',
'7150984':'Vérifiez si la couleur actuelle du véhicule correspond à la couleur déclarée par le constructeur ou à la couleur enregistrée lors de la première immatriculation du véhicule. Si la couleur est différente, il peut s\'agir d\'un véhicule repeint, mais aussi accidenté ou volé.',
'-1664139465':'La liste d\'équipement du véhicule n\'est pas disponible. Nous recommandons de vérifier la fonctionnalité et l\'originalité de l\'équipement directement à l\'intérieur du véhicule.',
'1681595153':'Le résultat du contrôle est une information indiquant si le véhicule que vous contrôlez est sécurisé sous la forme de marquages sur le verre et enregistré dans le système OCIS. Si c\'est le cas, nous vous indiquerons le code marqué et la date d\'enregistrement.',
'571758489':'Le véhicule a eu des marquage sur le verre dans le passé et est enregistré dans la base de données internationale du système OCIS.',
'855264535':'Si le code unique est absent de certaines ou de toutes les vitres du véhicule, il peut s\'agir d\'un véhicule accidenté, modifié ou volé.',
'388233970':'Informations sur le marquage des vitres :',
'1615022749':'Les paramètres techniques du véhicule n\'ont pas pu être identifiés. Cependant, vous pouvez les trouver sur le certificat d\'immatriculation (carte grise) du véhicule ou vous pouvez les demander au constructeur du véhicule.',
'704641294':'Après avoir sélectionné un véhicule, l\'application vous fournira des instructions pour une vérification de base des identifiants. Les instructions s\'appliquent généralement à la marque et au modèle spécifiés du véhicule. Un véhicule choisi au hasard a été choisi pour la démonstration.',
'-621659477':'La sélection de véhicules peut déjà être partiellement pré-remplie, en fonction du code VIN saisi. Si la sélection de véhicule préremplie ne correspond pas au véhicule que vous vérifiez, modifiez la sélection manuellement.',
'152333450':'Aucun enregistrement de dommage trouvé, qu\'est-ce que cela signifie ?',
'780709713':'Dans combien de temps le rapport sera-t-il disponible&nbsp;?',
'830704335':'Découvrez quelles informations sont disponibles pour votre voiture :',
'1850331238':'Excellent service, cela vaut la peine d\'y recourir. J\'ai découvert une falsification du compteur kilométrique même dans le véhicule de démonstration.',
'1705245752':'Je voulais acheter une voiture dont on m\'a dit qu\'elle n\'était pas accidentée. Or elle avait été accidentée plusieurs fois.',
'739619867':'Service absolument parfait, nous n\'avons finalement pas acheté une voiture, qui avait été accidentée et coûtait très cher, merci !',
'-2071079400':'J\'avais choisi une voiture, le vendeur n\'avait pas dit qu\'elle avait subi deux accidents graves et je l\'ai découvert ici avant de l\'acheter. Merci.',
'21202350':'Merci pour ce service. J\'ai failli perdre 90 000 euros dans une voiture. D\'après le propriétaire, la voiture n\'était que légèrement cabossée à l\'avant. D\'après le rapport, elle a été accidentée deux fois avec des dégâts importants.',
'1343185270':'La vérification m\'a rassuré et m\'a aidé à décider d\'acheter.',
'2083029914':'Il y a eu d\'importants dégâts dont le vendeur ne m\'a pas parlé.',
'1933958141':'Collision mineure détectée + vérification du compteur à partir de la date d\'immatriculation.',
'1900495866':'Grâce à ce service, j\'ai découvert que quelque chose n\'allait pas avec la voiture. Bien sûr, le vendeur ne m\'en a rien dit, et le vendeur a probablement aussi gardé le silence sur le reste. Merci.',
'-1668804606':'Lors de l\'inspection, j\'ai découvert de graves défauts que le vendeur m\'avait cachés, par exemple l\'état du compteur kilométrique, le fait que le véhicule ait été cambriolé dans le passé.',
'-273876487':'J\'ai découvert que le véhicule a effectué beaucoup de kilomètres en plus. Le vendeur annonçait 155 000 km, mais en réalité elle avait 250 000 km...',
'911857027':'Pour afficher le rapport, saisissez le rapport ID',
'1167593586':'Vous obtenez un rapport ID en achetant un Car History Report',
'-2136315768':'Si vous payez par carte de crédit, GooglePay ou ApplePay, les informations achetées seront affichées immédiatement et vous recevrez également un Rapport ID par e-mail',
'868918064':'Si vous effectuez le paiement par virement bancaire, vous recevrez le rapport ID par e-mail immédiatement après que le transfert aura été crédité sur le compte bancaire de Cebia',
'1572179326':'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
'-1322783148':'L\'emplacement du code VIN est différent pour chaque marque et modèle de véhicule. Mais vous pouvez toujours le trouver sur les certificats d\'immatriculation (cartes grises) et souvent sous le pare-brise.',
'700484420':'Le code VIN est le numéro de série unique de chaque voiture. Vous pouvez le trouver sur les papiers de la voiture ou marqué directement dessus.',
'-851712281':'Nous recherchons tous les enregistrements disponibles pour la voiture dont vous avez besoin et nous vous soumettons l\'ensemble des documents. Vous savez toujours ce que vous achetez.',
'-1983041378':'Vous pouvez payer via le portail de paiement sécurisée GoPay par carte ou virement bancaire.',
'-1809684191':'Le Car History Report est disponible immédiatement après le paiement. Vous recevrez également  par e-mail un lien pour l\'effectuer.',
'541894049':'Vous connaîtrez l\'état du compteur kilométrique pour la période donnée. Dans un graphique simple à interpréter, vous pourrez voir comment l\'état du compteur a évolué au fil du temps et vous pourrez détecter une éventuelle falsification.',
'643692561':'Vous verrez des informations sur les dommages enregistrés au véhicule. Des informations détaillées sur le montant et l\'étendue des dommages peuvent être jointes. Attention : n\'oubliez pas de vérifier en même temps l\'état technique du véhicule et ses dommages antérieurs. Si, par exemple, le véhicule n\'a pas été déclaré non-réparable.',
'878444309':'Si le véhicule a déjà fait l\'objet d\'une annonce, nous vous montrerons l\'annonce et la documentation photographique du véhicule.',
'185243225':'Vous y trouverez des informations détaillées et un aperçu des opérations de service effectuées dans le cadre des réparations ou de l\'entretien courant du véhicule. Attention : comparer les relevés avec le carnet d\'entretien et demander plus d\'informations sur l\'historique d\'entretien du véhicule.',
'1036319803':'Verificheremo per voi l\'anno di produzione effettivo, il che potrebbe avere un impatto significativo sul prezzo attuale e futuro del veicolo.',
'-437053085':'Nous vérifierons le véhicule dans les bases de données de véhicules volés actuellement disponibles. Il s\'agit principalement de bases de données européennes provenant de sources étatiques et non étatiques.',
'494784690':'Nous vérifierons le véhicule dans les bases de données de sociétés financières, de sociétés de location ou de prêts de voitures. Dans le rapport payant, vous pourrez vérifier si la voiture n\'est pas en cours de financement, de prêt ou de gage.',
'2074346923':'Vérification dans les bases de données des véhicules exploités en tant que service de taxi. Dans la section payante, vous saurez si la voiture a été ou est toujours utilisée comme taxi.',
'-976680067':'Nous vous montrerons une liste des rappels qui pourraient affecter le véhicule en cours d\'inspection.',
'1665109686':'Selon la description technique, vous pourrez vérifier les paramètres importants sur la base desquels la version du modèle du type de véhicule donné a été approuvée pour sa mise en service. Comparez ces paramètres avec les données indiquées sur le certificat d\'immatriculation du véhicule.',
'-719963267':'Nous vous afficherons la liste des équipements du véhicule selon les spécifications de sa fabrication. Vous pourrez ainsi comparer l\'équipement actuel du véhicule avec celui indiqué par le fabricant.',
'-1106584159':'Votre véhicule est protégé par gravage des vitres, ou des éléments de carrosserie peuvent avoir été marqués sur le véhicule. Nous vous informerons du code marqué et de la date d\'enregistrement.',
'1830950672':'Nous vous montrerons la couleur enregistrée par le constructeur du véhicule et la couleur spécifiée lors de l\'immatriculation du véhicule.',
'1835889470':'Chargement des données...',
'1020539397':'L\'exploitant de l\'application Car History - la société Cebia, spol. s ro, informe les utilisateurs du système que les informations fournies ont été transmises au système par les partenaires de l\'opérateur sans possibilité de vérifier leur exactitude. Toutes les informations le sont à titre informatif uniquement.',
'2054100346':'Le Car History Report n\'est pas soumis à garantie.',
'-167783152':'Le rapport a été créé par le service Car History, qui est exploité par Cebia, spol. s ro conformément aux conditions générales applicables pour l\'utilisation du Car History Service. L\'utilisateur est autorisé à utiliser les informations fournies dans leur intégralité uniquement pour son propre usage. Leur distribution ou modification ultérieure sans le consentement de l\'opérateur est interdite.',
'-1697979879':'Directeur de Cebia, Ing. Martin Pajer',
'-893067318':'<strong>Ing. Martin Pajer</strong> Directeur de Cebia',
'759051798':'Société Cébia',
'-31539157':'Nous sommes une société tchèque fondée en 1991 à Prague. Nos services dans le domaine du contrôle, de la sécurité et de l\'identification des véhicules sont utilisés par des clients dans 9 pays européens. Nos clients sont des automobilistes ordinaires, des compagnies d\'assurance, des concessionnaires automobiles, des institutions gouvernementales, des forces de police et des experts judiciaires. Au cours de notre histoire, nous avons découvert des dizaines de milliers de fraudes aux voitures d\'occasion et aidé des millions de clients à travers l\'Europe à acheter une voiture.',
'-1821588851':'Réclamations et plaintes',
'-276118588':'Les réclamations sont une ressource précieuse pour nous aider à améliorer nos services. Veuillez nous contacter via le formulaire de contact ou à : <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>',
'-1446517877':'Nous traiterons la réclamation au plus tard 14 jours après sa réception et nous vous informerons de son issue.',
'708234375':'Contactez-nous via le formulaire de contact ou par e-mail à <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>',
'1474175101':'Conditions générales de service',
'1343500875':'Politique de confidentialité',
'-2083968164':'Développement de son propre outil d\'évaluation de véhicules',
'907588970':'Fondation de l\'entreprise à Prague',
'-770165824':'<strong>30 ans d\'expérience</strong> dans l\'inspection automobile',
'-1138482741':'<strong>Plus de 1 000 000</strong> de voitures contrôlées chaque année',
'154007106':'<strong>750 000+</strong> clients satisfaits',
'177137909':'La base de données Cebia contient plus d\'un milliard d\'enregistrements de véhicules d\'occasion provenant de plus de 32 pays. Il s\'agit principalement de relevés kilométriques, de dommages au véhicule, de photos du véhicule et de relevés de carnets d\'entretien.',
'723486619':'Chargement des données',
'742852638':'Période:',
'787763184':'période:',
'-685036969':'Explications :',
'290784658':'Non disponible:',
'412892373':'Nous n\'avons trouvé aucune information sur le véhicule dans la catégorie indiquée',
'1853282090':'Disponible:',
'-249038921':'Enregistrements XY :',
'805146013':'Nous avons trouvé dans la catégorie donnée des enregistrements XY pour le véhicule, que vous trouverez dans la section payante',
'242118549':'termes et conditions',
'521725542':'Consulter les conditions générales d\'utilisation du Car History Service',
'-571686819':'ici',
'639498968':'Le paiement pour la consultation des informations détaillées dans la section Car History Report vous sera facturé selon les conditions stipulées dans le contrat avec Cebia.',
'-1705426324':'Vous pouvez afficher des informations détaillées pour 1 code VIN pendant 30 jours à compter de la première fois que vous consultez ce code VIN et vous ne payez qu\'une seule fois.',
'529403448':'Avertissement important',
'-821000576':'Cebia, spol. s.r.o. informe les utilisateurs du service que les informations fournies ont été transmises par les partenaires de l\'opérateur sans possibilité de vérifier leur exactitude. Toutes les informations le sont à titre informatif seulement. Dans le cas où l\'état du véhicule contredirait de manière significative les informations fournies ici, nous vous recommandons de contacter un expert compétent afin de vérifier l\'état réel du véhicule.',
'-1138137280':'Informations sur le traitement des données personnelles',
'125063053':'Afficher des informations sur le traitement des données personnelles',
'-608864551':'Nous avons trouvé des informations sur le véhicule dans la catégorie donnée, que vous pouvez trouver dans la section payante',
'-1879995849':'Données d\'entrée saisies',
'754897480':'ANNEE DE LA PREMIERE IMMATRICULATION',
'-1834106204':'ÉTAT ACTUEL DU COMPTEUR KILOMETRIQUE',
'404797827':'ZOBRAZIT CEBIA REPORT',
'1358780725':'GÉNÉRER UN RAPPORT',
'-952450705':'J\'ai examiné et confirmé les informations fournies',
'-1601808583':'<span className="green">Fait!</span> Les informations suivantes peuvent être vérifiées pour le véhicule spécifié:',
'-1763095911':'Lze vystavit Cebia REPORT hodnotící následující kategorie:',
'761362384':'Ukázka REPORTU',
'-397155268':'VOIR LE RAPPORT',
'1375027269':'VÉRIFIER LE VÉHICULE',
'2055286638':'En savoir plus',
'-1668243810':'Nous sommes plus forts',
'1141836815':'Nous faisons partie du holding tchèque EAG. Grâce à cela, notre base de données est considérablement élargie pour inclure des enregistrements de toute l\'Europe - en particulier d\'Allemagne, d\'Autriche et du Danemark.',
'-903271711':'Nous lançons un outil en ligne pour déterminer le juste prix des véhicules d\'occasion. Grâce à notre vaste base de données de véhicules d\'occasion, cet outil est extrêmement précis et parcourt rapidement les données des plus grandes compagnies d\'assurance et de crédit.',
'-1262829232':'Nous changeons le marché des voitures d\'occasion',
'1367974718':'Avec l\'intérêt croissant pour l\'achat d\'une voiture en ligne, nous commençons à coopérer avec les plus grands sites d\'annonces d\'Europe centrale. Les résultats de notre vérification d\'historique deviennent un gage de la qualité des voitures d\'occasions.',
'-300528762':'Entrer dans le monde en ligne',
'-899322976':'Nos services sont maintenant facilement accessibles à tous depuis chez nous. La fraude aux voitures d\'occasion est à son plus haut niveau et nous la combattons de toutes nos forces.',
'-4875574':'Nous élargissons les services',
'1166185728':'Nous ajoutons la vérification physique de l\'identifité des véhicules (VIN) à nos services et notre base de données grandit à pas de géant. Nous coopérons avec les compagnies d\'assurance, les autorités de l\'État et la police.',
'-1619922514':'Entrée à l\'étranger',
'-844900279':'Le commerce de véhicules volés est en plein essor, et pas seulement en République tchèque. Nous établissons donc une base de données internationale des véhicules volés, qui nous aide à révéler la véritable origine des voitures d\'occasion.',
'598423318':'Trois amis de promotion universitaire ont fondé la société Cebia. L\'intention initiale de faire des affaires dans l\'informatique a rapidement été remplacée par un intérêt pour la sécurisation des véhicules.',
'-1828699417':'Saisie/insolvabilité',
'4000011':'Vérification gratuite des documents',
'1479506336':'Historique des recherches',
'-1478881101':'Historique d\'inspection du véhicule',
'1115668272':'Antécédents de saisie/insolvabilité',
'691760517':'Historique de vérification des documents',
'-614781938':'L\'étendue et le coût de l\'inspection peuvent varier pour chaque véhicule en fonction de la quantité d\'informations disponibles.',
'702191442':'Retour en arrière',
'-1034048918':'Vérification du code VIN',
'-1214567423':'Numéro de réference',
'427849990':'Vytvořit CERTIFIKÁT kontroly vozu',
'-1718377395':'Erreur de chargement.',
'877365836':'Impossible de générer l\'URL de paiement.',
'-1082791212':'Valeur invalide',
'-255296664':'Le rapport ID permet de consulter le rapport plusieurs fois jusqu\'à 30&nbsp;jours après son activation.',
'456125545':'L\'opérateur du service Car History – Cebia, spol. s.r.o. informe l\'utilisateur que les informations fournies ont été fournies par les partenaires de l\'opérateur sans possibilité de vérifier leur exactitude. Toutes les informations le sont à titre informatif seulement.',
'-1172808350':'Contactez le concessionnaire pour vous indiquer le code VIN du véhicule, puis inspectez le véhicule.',
'-2079080642':'Contactez le concessionnaire pour vous fournir le code VIN à 17 chiffres, puis inspectez le véhicule.',
'-585052469':'Vous achetez une voiture d\'occasion ? Découvrez tout ce qu\'il faut savoir à propos de celui-ci ! En particulier, l\'état du compteur kilométrique, l\'année de fabrication ou les enregistrements de dommages à partir du code VIN.',
'76811920':'EXEMPLE de rapport de véhicule aléatoire.',
'1050846059':'Néanmoins, nous recommandons une inspection approfondie du véhicule, dans l\'idéal par un mécanicien qualifié ou dans le centre d\'entretien de votre choix. Une telle inspection peut sans doute révéler ou exclure des dommages antérieurs.',
'-1068264553':'Vérifiez toujours la date de fabrication déclarée par le constructeur du véhicule. Sur le certificat d\'immatriculation, seule la date de la première immatriculation est indiquée, qui peut cependant différer sensiblement de la date de fabrication (par exemple, de deux ans). Le véhicule peut ainsi être présenté comme plus récent, ce qui a un effet significatif sur son prix de marché.',
'756232271':'Aucun enregistrement n\'a été trouvé sur l\'âge ou l\'origine du véhicule. Si le véhicule vient de l\'étranger, demandez toujours et vérifiez soigneusement l\'original du permis technique étranger.',
'-643660678':'Des enregistrements ont été trouvés d\'opérations d\'entretien qui ont été effectuées sur le véhicule dans le passé dans le cadre de ses réparations ou de son entretien de routine.',
'1533967406':'Le prix d\'une inspection de véhicule dépend de la quantité et de l\'étendue des informations disponibles sur le véhicule inspecté. Vous connaîtrez la gamme d\'informations (et donc le prix) immédiatement après avoir saisi le code VIN du véhicule.',
'-1289463237':'Le rapport est généré quelques minutes après réception du paiement. Le service fonctionne 24 heures sur 24 et le processus est entièrement automatique.',
'1304585346':'Le rapport est généré automatiquement immédiatement après réception du paiement. Vous pouvez trouver le lien vers celui-ci dans l\'e-mail que vous recevez après le paiement. Vous recevrez également un rapport ID dans l\'e-mail pour une consultation multiple du rapport pendant une période de 30&nbsp;jours.',
'-661494819':'Erreur lors du chargement des données.',
'-1681989289':'vous avez déjà acheté le rapport, qui vous donne droit à une remise de 20 % sur cet achat. Appliquez la réduction en saisissant le rapport ID que vous avez reçu par e-mail dans le champ "Entrez le rapport ID".',
'681093117':'získal/a jste slevový kód při prodloužení služby FLEX na další rok. Slevu uplatníte zadáním čísla slevového kódu, který jste obdržel/a na daňovém dokladu k platbě služby FLEX, do pole „Zadejte číslo kuponu“.',
'-1551909822':'vous disposez d\'un code de réduction qui vous donne droit à un achat gratuit. Vous postulez en entrant le Report ID dans le champ de la réduction.',
'-103162544':'Après avoir effectué le paiement, les informations nécessaires pour visualiser le rapport et un reçu fiscal seront envoyés à l\'adresse e-mail fournie.',
'-591911074':'Après avoir effectué le paiement, vous recevrez les informations nécessaires pour afficher le rapport et un reçu fiscal au numéro de téléphone indiqué.',
'706315962':'En plus des informations nécessaires à l\'affichage du rapport, vous recevrez toujours une reçu fiscal. Si vous souhaitez que vos informations de facturation (société, adresse, numéro d\'identification, numéro de TVA) soient renseignées sur le facture, veuillez les saisir ci-dessous.',
'500915765':'Lors de la commande, vous serez redirigé vers la passerelle de paiement GoPay, où vous pourrez effectuer un paiement sécurisé par carte de crédit. Après le paiement, vous serez redirigé vers le résultat de la vérification, qui sera également envoyé à votre adresse e-mail avec le reçu fiscal.',
'-676823504':'Si vous choisissez ce mode de paiement, vous serez redirigé vers la passerelle de paiement GoPay, où vous pourrez sélectionner la banque à partir de laquelle vous souhaitez effectuer le paiement.',
'-764670786':'Si votre banque propose un virement dit en ligne, nous vous redirigerons vers votre banque en ligne, où vous disposerez d\'un ordre de paiement pré-rempli pour faciliter le paiement.',
'2062466434':'Si nous recevons immédiatement le paiement de la banque, nous vous redirigerons automatiquement vers la partie payante du service Car History. Sinon, veuillez attendre l\'e-mail.',
'734607756':'Après que le paiement a été crédité sur notre compte, nous vous enverrons un lien vers le rapport et la facture à votre adresse e-mail.',
'1715999355':'Google Pay est un moyen simple et rapide de payer sans saisir de détails de paiement. Pour utiliser ce service de paiement, il doit également être pris en charge par votre banque. Après avoir effectué le paiement, nous vous enverrons un lien vers le rapport et le reçu fiscal à votre adresse e-mail.',
'790632208':'Apple Pay est une méthode de paiement qui permet des paiements faciles à l\'aide d\'une carte enregistrée dans Apple Wallet. Pour utiliser ce service de paiement, il doit également être pris en charge par votre banque. Après avoir effectué le paiement, nous vous enverrons un lien vers le rapport et la facture à votre adresse e-mail.',
'-1402859324':'Dans le cas où votre banque ne propose qu\'un virement hors plateforme, nous générerons des instructions pour le virement bancaire, vous transférerez alors les fonds vers votre banque et dès que le montant sera crédité sur notre compte bancaire, nous vous enverrons un email avec un lien vers le rapport et un document sur le paiement effectué.',
'347099860':'Cette opération peut prendre jusqu\'à 2 jours ouvrables (mais généralement 24 heures).',
'-43783036':'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
'-802148009':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla.',
'1985201267':'La plus grande base de données de données automobiles d\'Europe',
'-1590601891':'<span class=\'warning\'>Attention :</span> Le service Car History peut ne pas disposer d\'informations sur tous les dommages causés au véhicule, certains dommages ne sont pas signalés aux compagnies d\'assurance ou ne sont donc pas disponibles dans le cadre d\'une vérification en ligne. ',
'-1465948017':'<strong>Systém OCIS –</strong> značení skel vozidla. Jednoduché, efektivní a cenově výhodné zabezpečení vozidla. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html\' target=\'_blank\'>Více zde.</a>',
'1553997983':'<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html\' target=\'_blank\'>Více zde.</a>',
'-124714580':'Paiement en ligne par carte de crédit ou via Google Pay ou Apple Pay.',
'-753863533':'CERTIFICAT D\'INSPECTION DU VÉHICULE - aperçu des informations disponibles',
'1441255407':'Catégorie',
'1549893453':'Possibilité d\'inspection',
'1547427535':'OUI',
'508023484':'Vérification de la date d\'inscription',
'2076692599':'Examen physique',
'-1521980547':'Vérification de l\'état du compteur kilométrique',
'-1009985044':'OUI - sur la base de',
'1747355819':'données',
'1639206028':'Vérification de l\'origine de la voiture',
'-1524110425':'Vérification du paiement de la taxe ECO',
'-1961089879':'Contrôle TVA',
'1732127987':'Contrôle d\'accident grave',
'-198755848':'Vérification de l\'immatriculation',
'-1790774232':'Contrôle des dommages causés par les inondations',
'-1781437228':'Vérification du type de moteur',
'-1344389293':'Vérification des identifiants du véhicule',
'-499181915':'Photo',
'-687939409':'Les informations ont été extraites des registres des services de documentation de l\'état des véhicules obtenus auprès de Cebia ou dans le réseau de ses partenaires contractuels.',
'-43022982':'Date de service :',
'-136414993':'État du véhicule :',
'2023782231':'Un contrôle physique des identifiants du véhicule (marquage du code VIN, plaques de production, etc.) a été effectué sur le véhicule. Les informations sont extraites des registres de la prestation VINTEST réalisée par Cebia ou ses partenaires contractuels.',
'758499555':'Statut des identifiants :',
'488100027':'Retour en arrière',
'-1281249872':'SUIVANT',
'-141562572':'GÉNÉRER',
'-40297285':'De cette façon, vous pouvez obtenir un aperçu de l\'évolution du prix et des données du véhicule vérifié.',
'-1742933347':'Numéro d\'annonce',
'-2099386182':'Vente',
'1560408371':'Prix de vente',
'-280814568':'VOIR LE CERTIFICAT D\'INSPECTION DU VÉHICULE',
'2075274708':'Date d\'acquisition',
'-1248991647':'Ukraine',
'-1779298552':'Suède',
'-18328562':'Lettonie',
'1764744897':'Lituanie',
'-1273046880':'Roumanie',
'1252567719':'Bulgarie',
'445626359':'Grèce',
'-868498706':'Slovénie',
'-1528448787':'Hongrie',
'-387469480':'Slovaquie',
'25300212':'Autriche',
'1771002523':'Italie',
'69077775':'Danemark',
'647944028':'Pologne',
'1160986873':'Allemagne',
'897084544':'France',
'2056598840':'Espagne',
'264100206':'Russie',
'-1609348689':'Politique de traitement des cookies',
'-365994139':'En savoir plus',
'807848789':'Des millions de clients satisfaits',
'1677683955':'Cebia compte plus de <strong>2 000 partenaires</strong> de toute l\'Europe qui nous fournissent des fiches de véhicules du monde entier.',
'652553309':'Vous savez que ...',
'1453750886':'Fait!',
'1498262202':'disponible',
'1514779988':'Inspection du véhicule par un technicien',
'1058658544':'Bons de réduction Cebia',
'-2035347536':'0 enregistrement(s)',
'1050102897':'La spécification détaillée de l\'équipement du véhicule fournie par son fabricant contient tous les éléments d\'équipement de base et options. En vérifiant l\'équipement, les modifications apportées à la voiture peuvent être détectées, comme le changement de côté du volant.',
'2145745364':'Liste des enregistrements de compteur kilométrique',
'-1636264801':'Description',
'-1488190529':'Numéro d\'enregistrement',
'64594583':'Véhicule financé',
'-1441353378':'Pas de financement',
'-2101084179':'Janvier',
'-1066567335':'Février',
'-1761670105':'Mars',
'104663035':'Avril',
'1397259452':'Mai',
'-642974117':'Juin',
'613657227':'Juillet',
'-2044487450':'Août',
'1921417285':'Septembre',
'-1672709282':'Octobre',
'-678062097':'Novembre',
'1302554433':'Décembre',
'1268839568':'Aucun enregistrement n\'a été trouvé dans la base de données du service. Demander un extrait du carnet d\'entretien du véhicule (de préférence du carnet d\'entretien électronique). L\'authenticité des opérations d\'entretien peut être vérifiée directement au garage où celles-ci ont été effectuées selon le carnet d\'entretien.',
'-112660439':'Zde si vytvoříte Osvědčení <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, které můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále – <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Enregistrement trouvé',
'-1350474522':'Il n\'y a aucun enregistrement du compteur kilométrique du véhicule inspecté dans les bases de données disponibles.',
'1040913417':'Nous vous recommandons de vérifier d\'autres sources possibles d\'enregistrements de compteur kilométrique, telles que le carnet d\'entretien ou en vous renseignant sur l\'historique d\'entretien auprès d\'un centre de service agréé.',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'E-mail',
'-982011601':'États-Unis',
'786789662':'Nákup zvýhodněných balíčků',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Chcete nakoupit na firmu nebo prověřit více vozidel? Využijte naše',
'845754511':'zvýhodněné balíčky!',
'-614796774':'Tchéquie',
'1011350398':'Lun-ven: 8h00 - 16h30',
'-68730396':'Jour :',
'544810671':'NON - non enregistré',
'-1670731287':'Enregistré en République tchèque sous le nom de :',
'1597705322':'Équipement standard',
'16575694':'Véhicule:',
'-846486931':'enregistrements',
'-1615816320':'enregistrement',
'1415114012':'enregistrements',
'1802294807':'photographies',
'1122888753':'photographie',
'53347610':'<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pruvodce?id=163&do=downloadFile">données personnelles</a>',
'1802705032':'<a className="Link Link--lightBlue" href="/detailArticle/ou-puis-je-trouver-le-code-vin" target="_blank" rel="noreferrer noopener">Plus d\'information</a>',
'883512613':'Contrôle du code VIN - Vérification du véhicule en ligne',
'-1965309318':'Consultez l\'historique de votre voiture en ligne dans la plus grande base de données d\'enregistrements de véhicules d\'occasion en Europe. Inspection des dommages, historique kilométrique, historique de service, photos et plus encore.',
'-1871315210':' Choisir un mode de paiement',
'-1803227447':'Veuillez sélectionner le mode de paiement approprié.',
'-1249181167':'Actualités - Car History',
'-838385662':'Nouvelles tendances dans le domaine de l\'inspection et de l\'achat de véhicules d\'occasion. Lisez comment détecter les fraudeurs.',
'1154868985':'Foire aux questions - Inspection de véhicule en ligne',
'908158246':'Consultez les questions les plus fréquemment posées par les clients sur l\'inspection automobile en ligne de Cebia. Si vous avez une autre question, nous nous ferons un plaisir d\'y répondre.',
'-633966577':'À propos de nous',
'1171158673':'Découvrez Cebia, son histoire et découvrez pourquoi des millions de clients à travers l\'Europe lui font confiance.',
'-1244282008':'Politique de cookies',
'-792206035':'Ce site utilise divers cookies. Certains cookies sont fournis par des services tiers. Vous pouvez retirer votre consentement à leur utilisation à tout moment.',
'1489309005':'Entrer dans la partie gratuite dans la limite du contrat',
'-470675791':'Je comprends et confirme les informations fournies.',
'2108896817':'ANNULER LA REMISE SPÉCIFIÉE',
'6892736':'La réduction a été annulée',
'-1199844274':'Le code n\'est pas valide !',
'-552424253':'Des frais sont facturés pour la saisie de la partie payante conformément au contrat',
'167769730':'Platba hotově',
'1768026018':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
'820786512':'Code accepté, prix recalculé',
'-279227794':'Vérifier l\'année de fabrication',
'1470281039':'Contrôle du crédit/prêt en République tchèque',
'60776637':'Rapport ID',
'529459564':'Veuillez saisir le rapport ID.',
'-861194224':'Inspection de véhicule',
'2076412240':'nouveau',
'360601996':'La validité du rapport a expiré.',
'1762078183':'Matériel',
'822990672':'En appuyant sur ce bouton, vous utiliserez 1 question dans la limite des questions gratuites selon le contrat en vigueur.',
'532528312':'Usagé',
'-319106355':'Véhicule',
'935795728':'Exemple de rapport',
'-916000765':'Car History Report',
'-1721948126':'Exemple de rapport. Consultez l\'historique de votre voiture en ligne dans la plus grande base de données d\'enregistrements de véhicules d\'occasion en Europe.',
'329882651':'En appuyant sur ce bouton, le prix de l\'affichage des informations détaillées vous sera facturé conformément au contrat en vigueur.',
'-494157101':'Reçu fiscal',
'-465994559':'Photo',
'857656094':'Marque:',
'-2005319583':'Modèle de véhicule:',
'339937023':'Année de première immatriculation selon le certificat d\'immatriculation :',
'-441771827':'Nous chargeons les données',
'-161761801':'période non vérifiée',
'1229398238':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Vozidlo je evidované jako <strong className="bolder">odcizené</strong>.',
'358386818':'Âge du véhicule :',
'-701699977':'Cebia REPORT nelze vytvořit - VIN nebylo možné jednoznačně identifikovat. </br> Zkontrolujte VIN podle dokladů od vozidla, případně <a href="mailto:autotracer@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN:{0}">Kontaktujte podporu</a>.',
'-1359814132':'Image suivante',
'340541220':'Agrandir',
'2096545526':'Zoom arrière',
'-158382161':'Image précédente',
'-749344895':'Vous bénéficiez d\'une réduction',
'-1755324145':'Modèle de véhicule',
'1747292041':'Smart code',
'1093009871':'Chytrá komunikace pro moderní autocentra',
'-421925379':'Chcete u svých vozidel propagovat, že jsou prověřená?',
'-303554559':'Dejte na každé vozidlo Smart kód a zákazník si sám načte výsledek jeho prověření.',
'5128661':'Úplně jednoduše. Cebia Smart kód vytisknete v systému AUTOTRACER (ve formátu A5) a umístíte za sklem prodávaného vozidla.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Ostatní informace (kompletní výpis ze systému AUTOTRACER) si pak může vyžádat přímo od Vás.',
'-566386963':'Veuillez remplir ce court formulaire et nous vous répondrons.',
'-1639125181':'Envoyer',
'76421921':'Champs obligatoires',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Máte zájem tisknout u vašich vozidel Smart kódy? Kontaktujte naše obchodní oddělení:',
'2094660384':'Petr Kala, vedoucí obchodního oddělení',
'361077673':'Ou remplissez le formulaire et nous vous contacterons :',
'1277961418':'Opravdu chcete vygenerovat Smart kód?',
'-2133968142':'J\'ai un code de réduction',
'-1922950659':'Mode de paiement',
'-2072875261':'Véhicule en cours d\'inspection',
'783887791':'Commande',
'-1499237034':'gratuit',
'-1854208328':'Appliquer le code de réduction',
'-793343279':'cadeau',
'-1393236672':'Kosovo',
'1342791550':'Historique de kilométrage',
'2063498881':'Équipement optionnel',
'-152116623':'QR – Smart kód',
'-956949153':'Montant des dommages',
'-552950132':'donnée',
'30639535':'données',
'-1164317619':'Non',
'1402944789':'Veuillez saisir les informations requises',
'-1812102598':'VAROVÁNÍ:Nelze provést <strong>kontrolu stavu tachometru</strong>. Kategorie bude mít výsledek - nedostatek informací.',
'666398003':'Avertissement!',
'771644825':'Le code VIN saisi peut être incorrect !',
'-1131044763':'Plus d\'information?',
'-2054470344':'Le code VIN du véhicule saisi est peut-être erroné !',
'-1369269346':'Une erreur possible a été détectée dans le numéro VIN saisi. Veuillez vérifier si le code VIN saisi correspond à celui marqué sur le véhicule ou inscrit sur la carte grise.',
'-1625517200':'Où chercher un VIN?',
'1171142400':'Le paiement n\'a pas abouti. Veuillez renouveler le paiement.',
'-1192484737':'Vous ne pouvez pas continuer sans accepter les conditions générales',
'-247455599':'Veuillez entrer le SIRET.',
'-1944712721':'Le nombre minimum de caractères est de 12.',
'1664270996':'État',
'-862797995':'État du véhicule enregistré au moment de sa vente. Veuillez consulter les photos ci-jointes pour vérifier l\'absence de dommages.',
'-1997376096':'République slovaque',
'-1344951568':'Fichier des immatriculations',
'-644036535':'Base de données',
'-426412387':'Registre national des véhicules',
'1736003883':'Données du certificat d\'immatriculation',
'1610817679':'Les enregistrements du compteur kilométrique sont disponibles pour le véhicule. Dans la section payante, vous trouverez les relevés du compteur kilométrique pour la période que nous indiquons pour cette catégorie. Les relevés peuvent être utilisés pour déterminer si compteur de la voiture a été falsifié.',
'1669009738':' Le véhicule contrôlé a fait l\'objet d\'une annonce ou a été photographié dans le passé. Dans la section payante, vous pouvez voir les détails des annonces, l\'état du compteur de vitesse, des photos de la voiture et éventuellement le prix annoncé (la gamme d\'informations peut différer pour les enregistrements individuels).',
'1518701163':'Nous avons des enregistrements de l\'âge ou de l\'origine de la voiture. Dans la partie payante, vous pouvez vérifier la date de production du véhicule, la date de sa mise en circulation ou encore pour quel pays la voiture a été fabriquée. La gamme d\'informations dans la section payante peut différer pour les voitures individuelles.',
'1816609203':'L\'année de fabrication est souvent falsifiée pour faire paraître la voiture plus récente, et donc plus chère !',
'-900812009':'Une voiture volée peut être confisquée à l\'acheteur par la police sans aucune indemnité !',
'-976941031':'Pour ce code VIN, aucune vérification dans les bases de données des véhicules exploités comme taxis n\'est disponible.',
'444450829':'jusqu\'à',
'982955181':'12 registres',
'-1739651968':'Vérification de sécurité',
'-167461646':'Vérification de l\'historique du véhicule',
'-1365221853':'Le nombre maximum de caractères est de 14.',
'1302766369':'Aucune évaluation du véhicule n\'a été effectuée.',
'1881868699':'J\'achète pour une entreprise',
'631216697':'Veuillez saisir votre e-mail.',
'945011940':'Vos données',
'-401861769':'Email de contact',
'1441058305':'Si vous indiquez votre numéro de TVA, nous essaierons de compléter les autres données à partir de la base de données officielle.',
'-1181757195':'Après le paiement, nous vous enverrons un lien vers le Car History Report et un reçu fiscal simplifié à l\'adresse e-mail indiquée.',
'-1768425519':'Si vous souhaitez que vos informations de facturation (société, adresse, numéro d\'entreprise, numéro de TVA) soient renseignées sur le document reçu simplifié, veuillez les saisir dans les champs ci-dessous. Si vous remplissez le numéro de TVA, nous essaierons d\'ajouter le reste des données de la base de données officielle.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Cebia REPORT poskytuje hodnocení kontroly stavu tachometru, kontroly v databázi odcizených vozidel, závazků u leasingových společností, kontroly správnosti VIN a roku výroby vozidla. Celkové hodnocení je vyjádřeno počtem hvězdiček (0–5) a slovním hodnocením.',
'308350422':'Total avec TVA :',
'-24797068':'Vous pouvez appliquer la remise si :',
'-1323263000':'Máte u nás předplacenou službu FLEX a použijete kód uvedený na daňovém dokladu.',
'-724069837':'Vous possédez un code de réduction pour un achat gratuit.',
'-127346860':'Vous disposez d\'un code de réduction chez l\'un de nos partenaires.',
'-47884841':'sans TVA',
'881014685':'Total hors TVA',
'-15294371':'Numéro de TVA non confirmé',
'1286068311':'La base de données officielle de la TVA n\'a pas confirmé votre numéro de TVA. Nous ne pouvons donc pas compléter automatiquement vos données. Veuillez vérifier votre numéro de TVA ou compléter manuellement les données de votre entreprise.',
'-172351568':'Ne correspond pas à la base de données',
'1839952435':'La base de données officielle des assujettis à la TVA ne répond pas. Veuillez saisir les informations de votre entreprise manuellement.',
'1678298800':'Exemple de rapport',
'-654354054':'La quantité d\'informations disponibles est différente pour chaque véhicule',
'-1069736045':'<label className="color-orange">Portails publicitaires</label> européens ',
'1068440731':'jusqu\'à <label classname="color-orange">12 registres</label>',
'-534710218':'L\'origine et l\'âge du véhicule',
'1267127794':'Tous les contrôles répertoriés sur cette page peuvent être effectués pour le véhicule spécifié en fonction du code VIN. Les résultats des contrôles seront disponibles dans le rapport payant. La quantité d\'informations contenues dans le rapport payant peut différer pour chaque véhicule.',
'-186613120':'N\'a pas été exploité comme taxi en République tchèque',
'1880784559':'Disponible après saisie du numéro d\'immatriculation du véhicule',
'2085931506':'<label className="color-orange">disponibles</label>',
'-1765351165':'plus <label className="color-orange">de 200 millions d\'enregistrements</label>',
'1815202337':'données <label className="color-orange">UE et États-Unis</label>',
'-555727222':'données <label className="color-orange">de 18 pays</label>',
'-1798142062':'Graphique des enregistrements des états du compteur',
'-1828887347':'VIN (numéro d\'identification du véhicule) :',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Le contrôle des taxis en Slovaquie peut être effectué en saisissant le numéro d\'immatriculation du véhicule sur le site <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>.',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Vérifiez le code VIN maintenant',
'-1620583517':'Historique',
'239060530':'Historique de vérification du code VIN',
'1122350112':'Aperçu de l\'historique de toutes les demandes de renseignements sur le VIN vérifié.',
'-1288575114':'N\'a pas été vérifié',
'-14589513':'Client',
'-1170545783':'Vérifié par vous',
'452068758':'Partenaire contractuel de Cebia',
'-1710315139':'Le rapport contient les enregistrements trouvés dans l\'historique du véhicule. Le plus souvent, il s\'agit d\'enregistrements de compteurs kilométriques, de données sur les dommages causés aux véhicules, d\'enregistrements de contrôles techniques, de photos de véhicules ou d\'enregistrements d\'annonces de véhicules. Vous pouvez en savoir plus dans <a href="/payed/detail/exampleFR" target="_blank">l\'exemple de rapport</a>. ',
'1607430837':'Aperçu de l\'historique',
'-293598683':'Date de retrait',
'-1790772829':'Date de 1ère immatriculation du véhicule',
'743843679':'Date d\'immatriculation du véhicule',
'1370884754':'Immatriculé en tant que',
'644449918':'Couleur de la voiture à l\'immatriculation',
'-583571734':'Date de fabrication du véhicule :',
'-1219924366':'Âge du véhicule ',
'1595420674':'Fabriqué avec l\'équipement ',
'1151043140':'Délai entre la production et la 1ère immatriculation',
'708774908':'Albánie',
'1637995084':'Macédoine du Nord',
'1001888760':'Véhicule déclaré non-réparable *',
'1034299871':'Calcul d\'indemnisation - véhicule déclaré non-réparable **',
'1209738229':'Sinistre assuré',
'913822268':'Remarque',
'584620341':'Brumlovka, budova Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Prague 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s.r.o.',
'-1996707559':'En validant la commande, j\'accepte <a href="https://www.cebia.cz/pruvodce?id=162&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue"> les conditions générales</a> de service et je comprends <a href="https://www.cebia.cz/pruvodce?id=163&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">la politique de traitement des données personnelles</a>. ',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Date de la première immatriculation indiquée lors de l\'inscription',
'280331577':'Doba od výroby po 1. přihlášení',
'1163480388':'V dostupných databázích nejsou k dispozici žádné záznamy.',
'-1319845523':'Pour le véhicule, nous enregistrons le calcul des dommages créé par la compagnie d\'assurance ou l\'atelier de réparation automobile, dont nous ne connaissons pas le détail (lieu du dommage). Veuillez prêter attention à l\'inspection du véhicule.',
'779148402':'Pour le véhicule, nous enregistrons un événement déclaré par la compagnie d\'assurance dont nous ne connaissons pas les détails (lieu du dommage). Veuillez prêter attention à l\'inspection du véhicule.',
'1115915387':'Doprava zdarma',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Neověřeno',
'-1464599260':'Práce',
'-592361226':'Lakování',
'1085310093':'Type de contrôle :',
'1984850101':'Date de contrôle ',
'-1502399543':'Afficher le protocole',
'-1961590845':'Databáze Ministerstva dopravy je momentálně nedostupná, zkuste to prosím později.',
'-1065211891':'Protokol není dostupný',
'1708745339':'K této prohlídce není detail protokolu dostupný.',
'1087254174':'Získat kompletní prověření',
'2096754301':'Základní prověření vozidla ke dni:',
'926095479':'Co znamená základní prověření vozidla?',
'-254140743':'Základní prověření obsahuje tyto kontroly:',
'1362304971':'Pro prověření celé historie vozidla doporučujeme zakoupit Kompletní prověření vozidla, které navíc může obsahovat <b>kontrolu poškození, historii inzerce, fotografie vozidla, servisní historii</b> a mnoho dalšího.',
'-267677252':'Základní prověření vozidla',
'-70564196':'- 10 EUR',
'1350105297':'Êtes-vous satisfait du résultat de la vérification de l\'historique du véhicule ? Nous serons heureux de votre note.',
'1914051118':'Évaluation',
'1786612140':'Nous sommes désolés que la vérification de l\'historique du véhicule n\'ait pas répondu à vos attentes. Veuillez nous écrire ce que nous devrions améliorer la prochaine fois.',
'-1265184280':'Erreur d\'envoi. Répétez l\'action.',
'-669850784':'Maximální počet znaků je 2000.',
'1801814710':'<strong classname="color-green">Merci!</strong> Votre évaluation a été envoyée et nous aidera à améliorer nos services.',
'-883106932':'Des dossiers de <label classname="color-orange">plus de 32 pays</label>',
'1408314893':'Cebia collecte les dossiers des véhicules de plus de 32 pays. Les enregistrements proviennent le plus souvent de registres officiels, d\'ateliers de réparation automobile, de bases de données policières, de bases de données de véhicules accidentés, de compagnies d\'assurance ou de plateformes d\'annonces.',
'-1412306493':'Oui. Chaque véhicule est vérifié dans des bases de données d\'enregistrements de plus de 32 pays (dont l\'Allemagne, le Danemark et les États-Unis). Après avoir saisi le code VIN, vous découvrirez gratuitement quels enregistrements et à partir de quelle période ils ont été trouvés dans l\'aperçu des informations trouvées.',
'1798507708':'Dans ce cas, nous n\'avons trouvé aucun enregistrement de dommages au véhicule inspecté dans nos bases de données de plus de 32 pays totalisant 200 millions et des États-Unis. Ce fait augmente considérablement la probabilité que la voiture inspectée n\'ait pas été impliquée dans un accident dans le passé.',
'568601301':'Historique de voitures de <strong>plus de 32 pays</strong>',
'899127599':'32 pays',
'-1456368295':'Historique des véhicules de plus de 32 pays',
'1333238000':'Plus de 30 000 000 de voitures testées',
'426416063':'Les enregistrements proviennent de 32 pays',
'-1549476292':'Cebia vérifie-t-il chaque voiture dans une base de données de véhicules endommagés avec plus de 200 millions d\'enregistrements provenant de 32 pays ? La plupart des enregistrements (62 %) proviennent de France et d\'Allemagne.',
'-924882043':'Dans les bases de données de voitures endommagées de plus de 32 pays contenant 200 millions d\'enregistrements d\'événements de dommages, un ou plusieurs enregistrements de dommages au véhicule inspecté <span class="color-red">ont été trouvés</span>. ',
'-1366090835':'Dans les bases de données de voitures endommagées de plus de 32 pays contenant 200 millions d\'enregistrements d\'événements de dommages, aucun enregistrement de dommages au véhicule inspecté <span class="light-green">n\'a été trouvé</span>. ',
'931273814':'Historique de voitures de plus de 32 pays',
'-334327155':'<p>Nous vérifierons le véhicule dans des bases de données de voitures endommagées de plus de 32 pays contenant plus de 200 millions d\'enregistrements d\'événements de dommages.</p><p>Dans la partie payante, vous découvrirez si le véhicule a déjà été enregistré comme endommagé dans ces bases de données dans son historique. Des informations détaillées telles que la date de l\'événement, l\'état du compteur kilométrique, l\'étendue des dommages, le montant des dommages ou une photo de la voiture peuvent être jointes aux enregistrements des dommages trouvés.</p>',
'2078676994':'Relevés de carnets d\'entretien',
'503491296':'OK',
'1704037376':'Annuler',
'-922638497':'vérifié',
'-2077712313':'jusqu\'à 12 enregistrements',
'-855779034':'Prověření již bylo <strong className="ml-5">provedeno</strong>',
'131879595':'Vérification <strong classname="ml-5 mr-5">moyennant des frais</strong> selon le contrat ',
'-2034839108':'PDF',
'2049708688':'Sans enregistrements',
'1916268628':'Pays-Bas',
'-1425396841':'Cebia',
'-1521903902':'Numéro de protocole ',
'1485582239':'Format d\'e-mail incorrect.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Vous disposez d\'un code de réduction qui vous donne droit à un achat gratuit. Vous postulez en entrant le "Rapport ID" dans le champ de la réduction.',
'-675647872':'Dans tous les cas ci-dessus, vous entrez ensuite le rapport ID dans le champ approprié sur la <a href="/" target="_blank">fr.cebia.com</a> et vous obtenez un accès immédiat aux informations achetées, auxquelles vous pouvez revenir à tout moment dans les 30 jours. ',
'1080548025':'VAROVÁNÍ: Může se jednat o <strong>chybné VIN</strong>. Proveďte kontrolu VIN, případně <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN: {0}">Kontaktujte podporu</a>',
'-439637411':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Nenalezen rok výroby. <a href=\'mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR rokvy&body=VIN:',
'-43065820':'Nebyly nalezeny žádné záznamy o stáří či původu vozidla. Pochází-li vozidlo ze zahraničí, vždy si vyžádejte a pečlivě zkontrolujte originální dokumentaci k vozidlu (např. servisní knihu nebo technický průkaz).<br/><br/>Pokud chcete mít jistotu, že vůz pochází z deklarované země a není odcizený, můžete využít službu prověření původu v zahraničí <a href="https://www.cebia.cz/sluzby/provin" target="_blank" class="Link Link--lightBlue">PROVIN</a>.',
'-1362534546':'Pokud bylo vozidlo v minulosti inzerováno nebo provedena prohlídka, zobrazíme informace a fotodokumentaci.',
'431007496':'Záznam',
'-1525511285':'Nous tirons les données du certificat d\'immatriculation des institutions publiques et celles-ci donnent l\'état au moment de la première immatriculation du véhicule dans le pays donné.',
'1151204976':'Le code VIN (numéro d\'identification du véhicule) est un code unique à 17 chiffres qui permet d\'identifier le véhicule de manière unique. Il est apposé directement sur la carrosserie du véhicule et tous les dossiers du véhicule sont enregistrés sous ce code.',
'205732919':'Le code VIN (numéro d\'identification du véhicule) est un code unique à 17 chiffres qui permet d\'identifier le véhicule de manière unique.',
'1905716471':'*) La définition du <strong>Véhicule déclaré non-réparable</strong> peut varier d\'un pays à l\'autre et d\'une compagnie d\'assurance à l\'autre. Même si le dommage est évalué de cette manière, cela ne signifie pas nécessairement que le véhicule est inutilisable ou irréparable. En fait, cela représente un dommage sur le véhicule dont la réparation est jugée économiquement non viable selon les conditions de l\'assurance (c\'est-à-dire que le coût de la réparation se rapproche ou dépasse la valeur du véhicule au moment du sinistre). Dans tous les cas, nous recommandons une inspection détaillée du véhicule.',
'-199845800':'Le nombre de caractères doit être de 10.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'<a href="/detailArticle/ou-puis-je-trouver-le-code-vin" target="_blank">VIN</a> est une abréviation de l\'anglais <a href="/detailArticle/ou-puis-je-trouver-le-code-vin" target="_blank">Vehicled Identification Number</a> (numéro d\'identification du véhicule). Il s\'agit d\'un code unique composé de 17 lettres et chiffres, que les constructeurs attribuent à chaque véhicule fabriqué. Le code VIN peut se trouver sur différentes parties du véhicule ou sur ses documents.',
'1682180910':'<p>Dans les bases de données disponibles, nous n\'avons pas d\'enregistrements sur les dommages du véhicule vérifié. Le numéro VIN que vous avez saisi pourrait être incorrect.</p><p>Avertissement : Le système Car History peut ne pas avoir accès à toutes les informations concernant les dommages du véhicule. Certaines détériorations ne sont pas signalées aux compagnies d\'assurance ou ne sont pas disponibles lors de la vérification en ligne.</p>',
'-1276466490':'Le VIN fourni pourrait être incorrect',
'-1892907546':'Aucun enregistrement d\'historique ne peut être trouvé pour ce VIN.',
'1843204209':'Continuer',
'-716345220':'Vérifier un autre VIN',
'1091884080':'Nous vous recommandons de vérifier le VIN',
'-287497170':'Vérification des droits de nantissement',
'1289833880':'Droit de nantissement trouvé',
'466639297':'Droit de nantissement non trouvé.',
'-577812113':'Numéro de dossier',
'231435384':'La vérification des droits de gage n\'est pas actuellement disponible. Veuillez essayer de recharger la page après quelques minutes.',
'-941220732':'Le véhicule est l\'objet d\'un ou de plusieurs droits de gage dans les États mentionnés et il existe la possibilité de le saisir en cas de procédure d\'exécution éventuelle de la personne mentionnée dans le registre des gages. L\'achat d\'un véhicule avec un droit de gage peut être très risqué. Les données sont obtenues à partir des registres officiels des gages du pays concerné.',
'2016489332':'Le résultat de la requête consiste à déterminer si le véhicule n\'est pas l\'objet d\'un droit de gage dans les États mentionnés et qu\'il n\'y a donc aucun risque de saisie en cas de procédure d\'exécution potentielle à l\'encontre du propriétaire actuel. Les données sont obtenues à partir des registres officiels des gages du pays concerné.',
'1736313716':'Přílohy k článku',
'1021804797':'Inspection <strong>gratuite</strong> dans les limites des termes du contrat',
'749816957':'Peut être vérifié',
'981194702':'Kontrola exekucí',
'-1417536272':'Vérification de privilège',
'-323399576':'Oui, vous pouvez. Si vous pensez que le résultat contient des données erronées, veuillez contacter notre service client : <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Číslo smlouvy (je-li k dispozici)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Exploité comme taxi en',
'-137639801':'Rapport ID (à remplir en cas de réclamation)',
'-1619027976':'Note : Le véhicule peut ne pas avoir été exploité en tant que taxi pendant toute la période.',
'-1010997259':'Pour les entrepreneurs',
'503941074':'Les informations détaillées et les données sur le véhicule vérifié seront disponibles après le paiement du service.',
'1088246106':'L\'examen de l\'historique de la voiture vérifiera:',
'-963915529':'Les dommages du véhicule cachés',
'-1603961223':'<label classname="color-orange">disponibles</label>',
'-1940014640':'Les informations importantes sur le véhicule',
'1785662376':'Služby',
'1913348655':'Voulez-vous vérifier plus de véhicules à un prix plus bas ? Obtenez un accès pour les entreprises et <b>économisez jusqu\'à 50 %</b> sur les coûts de vérification. Veuillez nous fournir vos coordonnées, et nous vous contacterons avec une offre personnalisée.',
'1723999193':'Disponible après paiement',
'1593405846':'Pojištění vozidla',
'670508858':'Un seul code de réduction peut être utilisé par commande. Les coupons de réduction ne peuvent pas être ajoutés ou combinés entre eux. La réduction s\'applique uniquement à Car History Report.',
'1693716169':'Mode de paiement',
'-503153747':'Car History Report',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'20 % DE RÉDUCTION sur la prochaine vérification',
'-1870036897':'Vous avez déjà fait contrôler une voiture par nos soins et, en saisissant le numéro de coupon (indiqué dans l\'e-mail que vous avez reçu) dans les 30 jours suivant l\'achat, vous bénéficierez d\'une réduction sur le prochain contrôle.',
'163946327':'En achetant une inspection de véhicule, vous bénéficiez d\'une remise de 50 % sur votre prochain achat. Vous appliquez la réduction en saisissant le rapport ID dans le champ permettant de saisir le code de réduction dans la section "Paiement" lors de votre prochain achat.',
'858336732':'50% de réduction sur la prochaine vérification',
'214251926':'En achetant une vérification d\'antécédents, vous bénéficierez automatiquement d\'une réduction sur la prochaine vérification d\'antécédents pour tout autre véhicule. La réduction est valable pendant 30 jours à compter de la date d\'achat.',
'1317784962':'10% de réduction sur la prochaine vérification',
'641443144':'20% de réduction sur la prochaine vérification',
'-1318469146':'30% de réduction sur la prochaine vérification',
'1939643950':'40% de réduction sur la prochaine vérification',
'-1376459678':'Plus vérification <br> <b>à prix réduit!</b>',
'-937620931':'Données du véhicule',
'514768619':'Nous vérifions l\'authenticité du VIN',
'-916328171':'Nous vérifions l\'identité du véhicule',
'1481465091':'Nous vérifions l\'équipement du véhicule',
'-276782700':'Nous recherchons dans les bases de données des véhicules endommagés',
'1544438189':'Nous vérifions les dossiers des compagnies d\'assurance',
'2021669548':'Recherche dans l\'histoire des portails de vente aux enchères',
'2022162632':'Nous vérifions les enregistrements des services autorisés',
'1083617557':'Nous vérifions les enregistrements des services non autorisés',
'993080480':'Nous recherchons des véhicules volés dans les bases de données de la police',
'-1240510013':'Nous recherchons des véhicules volés dans des bases de données privées',
'-1027936662':'Nous vérifions l\'âge du véhicule',
'1299172359':'Nous vérifions l\'origine du véhicule',
'413685839':'Nous recherchons des livres de service électroniques',
'-473666121':'Recherche dans les registres nationaux des véhicules',
'-254920359':'Nous effectuons des recherches dans les bases de données des États sur les véhicules de taxi',
'-811021673':'Vérification des ordre de rappel',
'1923526539':'Nous vérifions la sécurité du véhicule',
'-1424413327':'Nous vérifions les bases de données d\'enregistrements kilométriques',
'734665361':'Nous vérifions les dossiers des sociétés de crédit-bail',
'684288091':'Nous effectuons des recherches dans les registres des stations de contrôle technique',
'-1633964825':'Nous recherchons les enregistrements des stations de mesure des émissions',
'-354982402':'Recherche dans l\'histoire des portails publicitaires',
'255365524':'Nous vérifions les bases de données des concessionnaires de voitures neuves',
'936372740':'Nous vérifions les bases de données des concessionnaires de véhicules d\'occasion',
'-1491634158':'Nous vérifions les dossiers des voitures de location',
'-1425645764':'L\'opération peut durer plusieurs minutes',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Nous créons un rapport',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Partager l\'annonce',
'-2142411619':'Pour partager le rapport, copiez le lien ci-dessous et envoyez-le à toute personne avec qui vous souhaitez partager le rapport.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Nous recommandons',
'-1025377658':'article',
'-909166524':'articles',
'-1998523642':'articles',
'-613016004':'Offres réduites',
'1083497926':'Autres services',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Prochain rapport',
'-901212011':'pour',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Nous vérifions les photos, vous serez informé de l\'exécution par e-mail.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Attention :</span> les informations sur tous les dommages du véhicule peuvent ne pas être disponibles dans le système, certains dommages ne sont pas signalés aux compagnies d\'assurance ou ne sont pas disponibles dans le cadre d\'un contrôle en ligne. Nous recommandons donc une inspection minutieuse du véhicule, idéalement avec l\'aide d\'un mécanicien qualifié ou dans le centre de service de votre choix. Une telle inspection peut révéler des dommages supplémentaires ou les exclure.',
'-1943079155':'<span class="warning">Remarque&nbsp;:</span> si les photos ne correspondent pas au véhicule contrôlé, veuillez nous en informer par email <a href="mailto:info.fr@cebia.com">info.fr@cebia.com</a>.',
'707486310':'<span class="warning">Remarque :</span> il est possible que tous les dossiers d\'entretien du véhicule ne soient pas disponibles dans le système. Nous vous recommandons de comparer les dossiers avec le carnet d\'entretien ou de contacter le service agréé compétent de la marque pour demander un extrait du carnet d\'entretien numérique du véhicule.',
'-1997337578':'L\'aperçu de l\'évolution du compteur kilométrique du véhicule contient des valeurs obtenues à partir des systèmes d\'information disponibles des partenaires coopérants. Selon l\'évolution du graphique, vous pouvez juger par vous-même s\'il existe ou non une suspicion de manipulation de l\'état du compteur kilométrique.',
'-282194273':'Recommandation',
'-1564285314':'Il se peut que tous les enregistrements de l\'état du compteur kilométrique provenant de l\'historique de fonctionnement du véhicule ne soient pas disponibles dans le système.',
'993789697':'Si le véhicule n\'enregistre pas en permanence l\'intégralité de l\'historique du kilométrage au fil du temps, nous <b>recommandons</b> de vérifier également les enregistrements du compteur kilométrique dans le carnet d\'entretien ou de faire vérifier le véhicule dans un garage agréé. Nous recommandons cette procédure en particulier pour les véhicules où il y a un intervalle de plus de deux ans entre les lectures du compteur kilométrique.',
'2129711518':'Il s\'agit d\'informations sur les paramètres de base, les équipements standard et éventuellement supplémentaires du véhicule en usine. Des modifications du véhicule telles que le changement du côté du volant peuvent être détectées en vérifiant l\'équipement.',
'-593531916':'<span class="warning">Attention :</span> Dans certains cas, l\'équipement complet du véhicule peut ne pas être disponible dans le système.',
};

const TRANSLATIONS_NL = {
'1901555939':'VIN',
'-971921755':'Fabrieksmerk',
'1891129765':'Model',
'691501523':'Type carrosserie',
'-1026019663':'Type voertuig',
'274952045':'Brandstof',
'-348535418':'Vermogen',
'1000727131':'Inhoud',
'-440181428':'Bouwdatum',
'841597003':'Mededelen',
'-762872471':'Tonen',
'-1615835216':'Link',
'-466013687':'Sluiten',
'-1655267775':'Link kopiëren',
'-1448849529':'Kan ik een auto ook zonder VIN checken?',
'-1310016716':'Voertuigverificatie',
'1410745983':'online',
'-1803530129':'Vygenerovat Cebia REPORT',
'934563996':'Verificatieresultaat',
'-1102433484':'Rapportvoorbeeld',
'-1242381959':'Voertuiggeschiedenis',
'417677035':'Controle van voertuigverplichtingen',
'-115937358':'Toestand van het voertuig',
'457834942':'Meest gestelde vragen',
'226942208':'Wat is een VIN?',
'1739046820':'Hoeveel kost een verificatie?',
'16204687':'Hoe kan ik betalen?',
'-1607701055':'Hoe kan ik het Rapport bekijken?',
'-1969713941':'Kan ik een klacht indienen over het rapport?',
'1598751038':'Waar vind ik het Report ID',
'-1206763576':'Meer informatie',
'-729900752':'Online betaling',
'671435147':'Voer kortingscode in, alstublieft.',
'-2004947080':'Voer Report ID in, alstublieft',
'-1083354004':'HERREKENEN',
'-1589008349':'Naam',
'-704955254':'Achternaam',
'-1631818401':'straat en nummer',
'1006101298':'stad',
'1063186093':'Land',
'287700219':'Postcode',
'-1411201334':'BTW nummer',
'513366821':'BTW nummer',
'-102071178':'Naam bedrijf',
'-1882309597':'Bedrijfsidentificatienummer',
'-1515081676':'KVK-nummer van het bedrijf',
'1440993804':'Contact e-mailadres',
'-1405758046':'Contact telefoonnummer',
'1366947192':'Uw telefoonnummer',
'-490851561':'Korting 100 CZK bij volgende aankoop',
'-108743580':'CZK',
'786987358':'Kopen',
'-1887658751':'Resultaten zijn direct na betaling beschikbaar',
'-1018049108':'Resultaten zijn meestal binnen 60 minuten beschikbaar',
'630102694':'Google Pay',
'-1698989463':'Apple Pay',
'-663138552':'Bankoverschrijving',
'-1663277':'Resultaten zijn binnen 48 uur beschikbaar',
'1626540224':'V hotovosti v sídle Cebia',
'72162446':'Check het voertuig',
'757687806':'Overzicht gratis informatie',
'1954205527':'Waar vind ik de VIN-code?',
'1603459473':'Ingang na het betalen',
'2038366987':'Rapport tonen',
'-374549211':'Waar vind ik het Report ID?',
'1262814082':'Invoeren van VIN',
'-15600132':'Datum 1ste registratie ',
'1535680605':'Report ID:',
'-1091217450':'Rapport is geldig tot:',
'-1264594150':'Datum Rapport activatie:',
'-701611594':'Het spijt ons, maar het voertuig kan niet geverifieerd worden.',
'160166931':'De huidige eigenaar van het voertuig wenst niet dat de voertuiggegevens getoond worden.',
'-351255422':'Advertentie, die u bekijkt, bevat geen VIN van het voertuig.',
'1704462566':'In de advertentie wordt niet het volledige VIN vermeld.',
'366367808':'Hoelang duurt het totdat ik het Rapport krijg?',
'2136729030':'VIN:',
'400669011':'U hebt recht op korting in geval dat:',
'-219050562':'Blog',
'1449193326':'Over ons',
'1097675154':'Contact',
'2062322678':'Het Nieuws',
'-1263831198':'Bedrijfsgeschiedenis',
'1166836894':'Onze partners',
'-1532347183':'Voor media',
'717698781':'Criteria',
'1620687461':'Vozidlo je evidované jako odcizené. Cebia REPORT nelze vytvořit',
'1365729306':'Číslo CR:',
'-89669431':'Jaar van eerste registratie:',
'2130038984':'Kilometerstand:',
'1347577444':'Het VIN moet 17 tekens lang zijn.',
'622759132':'Link gekopieerd',
'1714373031':'Volgende dingen kunnen de gecheckte auto betreffen',
'1227286459':'Gevonden terugroepactie',
'-477335598':'TERUGROEPACTIE',
'-343698081':'In betrekking met de gecheckte auto werden niet gevonden',
'-1536285860':'Geen terugroepactie',
'-2052297753':'Datum van aankondiging',
'1522685090':'Detail',
'444715065':'Meer informatie',
'-394230090':'U moet de ingevoerde gegevens bevestigen.',
'1224828864':'Onjuiste waarde van jaar van eerste registratie.',
'-470093943':'Voeg het jaar van eerste registratie in, alstublieft.',
'1035831822':'Onjuiste kilometerstand waarde.',
'-1161922611':'Voeg stand van kilometerteller in, alstublieft.',
'-1383225585':'Voeg jaar van eerste registratie in',
'1111232857':'Jaar van eerste registratie',
'-1998715395':'Voeg kilometerstand in',
'-1962337257':'Kilometerstand',
'-1888253249':'Model specificatie',
'-339477539':'- 300 Kč',
'917746804':'Gemakkelijker kan het niet:',
'1405508217':'Předběžná konzultace ZDARMA',
'127414665':'Hoe past u de korting toe?',
'-2081078087':'VYBRAT TECHNIKA',
'1899967331':'Het maximum aantal tekens moet 20 zijn.',
'1699452695':'Chybný kód banky',
'1812875983':'Prosím zadejte položku Číslo účtu.',
'-670727524':'Prosím zadejte položku Číslo pojistné smlouvy.',
'1884544037':'ePojisteni.cz',
'138266606':'Geldig tot',
'1881823014':'Podmínky pro vrácení peněz:',
'-1548724023':'Vozidlo musí být pojištěno minimálně 30 dní',
'1331666705':'Fout bij het opslaan. Herhaal actie.',
'-1393210532':'Číslo účtu',
'439576396':'Vaše číslo účtu',
'773379637':'Číslo pojistné smlouvy',
'-1281605763':'Uplatnit vrácení peněz',
'-1670752753':'Sleva na prověření v zahraničí',
'1496813111':'- 550',
'-856872708':'Basisprijs',
'616158592':'KORTING',
'-581170701':'Prijs na korting',
'-437309148':'met BTW',
'-1731075532':'Video',
'-189892310':'Bestellen',
'98918066':'Bestelprocedure:',
'-1944610133':'Korting bij volgende aankoop',
'-621660650':'Kortingscode:',
'-1845841489':'Controleer volgende auto met korting',
'1171588656':'Sleva na zabezpečení vozidla',
'1440663595':'- 850',
'-1503110723':'Sleva na fyzickou prověrku',
'-675817237':'- 925',
'986037818':'Schade gevonden',
'873964955':'Datum',
'790922325':'Berekening reparatiekosten',
'-1825735064':'Type gebeurtenis',
'486919475':'Geregistreerde beschadigde plaatsen',
'1685506455':'De auto is gefinancierd',
'2039551191':'Financiering niet gevonden',
'-570695392':'in momenteel beschikbare databases',
'-765744228':'Advertenties',
'659651744':'Datum advertentie',
'-695638522':'Geadverteerde kilometerstand',
'772986188':'Geadverteerde prijs',
'419477574':'Leeftijd en herkomstgegevens van het voertuig',
'-109775782':'Bouwdatum volgens de fabrikant',
'-218677523':'Eerste registratie',
'-340115089':'Eerste registratie in Tsjechië',
'1192563560':'Eerste registratie in Slowakije',
'1650211924':'Geproduceerd voor de markt',
'1812132824':'Partij van procedure',
'-1229728494':'Materiaal (lijst)',
'-1147664160':'Werkzaamheden (lijst)',
'708828581':'Gebruikt als taxi',
'284182756':'Het voertuig staat geregistreerd als gestolen',
'1244175337':'Het voertuig staat niet geregistreerd als gestolen',
'791649880':'Bron',
'705052471':'Op de dag',
'1239149764':'Controle van identificatiegegevens',
'-799257304':'Model:',
'-1964821919':'Datum van eerste registratie:',
'-1530361098':'Prijs nieuw voertuig',
'68348546':'Huidige marktprijs',
'763547310':'Datum van eerste registratie',
'1968050624':'Opslaan',
'-1300982647':'Uitrustingslijs volgens de fabrikant',
'397739850':'Controle van voertuigkleur',
'479427038':'Ter beschikking',
'2038192723':'Controle van voertuigveiligheid',
'-757741002':'Technische voertuigbeschrijving',
'278755414':'Instructies voor voertuigidentificatie',
'1529692191':'Kleur van voertuig',
'-1171491320':'Voertuigidentificatie',
'1549116932':'Uitrustingslijst voertuig',
'-1985480381':'Overige',
'-1354462940':'CONTROLEER:',
'-690036295':'Datum van registratie:',
'1506895356':'Type van voertuigveiligheid:',
'-749715579':'Markeringscode:',
'-329154043':'Kleur van voertuig:',
'1441236976':'Gegevens over de uitgevoerde glasmarkering',
'729768522':'Glasmarkering',
'1812345820':'NEE',
'847948448':'MÁTE ZÁJEM O ZABEZPEČENÍ VOZIDLA?',
'-1899070354':'Technische beschrijving - basis',
'-953303':'Technische beschrijving - uitgebreid',
'1237026086':'Onjuiste waarde Voertuigtype.',
'-259360107':'Voer Voertuigtype in, alstublieft',
'-1906046398':'Het minimum aantal tekens moet 1 zijn.',
'-69524395':'Het maximum aantal tekens moet 30 zijn.',
'1663292305':'Voer Merk in, alstublieft.',
'814923836':'Voer Model in, alstublieft.',
'-1985695287':'Voer Detail in, alstublieft.',
'244480988':'Merk',
'1602844398':'Gedetailleerde bepaling',
'-1749998805':'Kies voertuig',
'-1162182506':'Voertuigtype',
'1104651533':'Kentekenbewijs - VIN nummer',
'-1898506589':'auto voorruit - VIN nummer',
'216861745':'Bevestig de ingevoerde gegevens, alstublieft!',
'-1328097963':'Het maximum aantal tekens moet 9 zijn.',
'1678639740':'Voer Telefoon in, alstublieft.',
'1303052228':'Voer Naam in, alstublieft.',
'1792286505':'Het maximum aantal tekens moet 128 zijn.',
'2122707327':'Voer Achternaam in, alstublieft.',
'-938825602':'Het maximum aantal tekens moet 64 zijn.',
'1683031395':'Voer Straat en nummer in, alstublieft.',
'661065202':'Voer Stad in, alstublieft.',
'-83271898':'Voer Postcode in, alstublieft.',
'1018067916':'Het maximum aantal tekens moet 5 zijn.',
'12673416':'Postcode is in onjuiste formaat.',
'154576238':'Report ID is in onjuiste formaat.',
'-581020701':'wordt door de voertuigfabrikant afgekondigd zoals vereist door de wet wanneer de gezondheid, de veiligheid en het milieu in gevaar zijn. In de regel hebben ze betrekking op fabricagefouten van het voertuig, die pas tijdens het gebruik zichtbaar worden, en op deze manier zorgt de fabrikant voor een gratis reparatie. De informatie wordt met behulp van het RAPEX-systeem van de website Recalls.eu gehaald.',
'1969671182':'De vermelde terugroepacties zijn gegenereerd op basis van het model, het merk en het bouwjaar van het geverifieerde voertuig en niet op basis van het VIN. Controleer daarom altijd in details van de gebeurtenis of deze ook betrekking heeft op het gecontroleerde voertuig. Controleer tegelijkertijd bij de verkoper van het voertuig of bij een erkend servicecentrum of het voertuig een geldige terugroepactie heeft ondergaan.',
'-2076502822':'Een terugroepactie wordt door de voertuigfabrikant uitgevoerd, zoals vereist door de wet, als het voertuig een fabricagefout heeft die de gezondheid, veiligheid of het milieu in gevaar brengt. In het kader van de terugroepactie wordt het defect gratis verwijderd bij een erkend servicecentrum. De gegevens worden geleverd door het Recalls.eu portaal met behulp van het RAPEX-systeem.',
'1854708237':'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
'-1531415911':'Uzavřete pojistku prostřednictvím portálu <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> a získejte zpět peníze za prověření vozidla.',
'1014002660':'Ve spolupráci se společností <a href="https://www.epojisteni.cz/poptavka?pid=2226" target="_blank">ePojisteni.cz</a> Vám přinášíme možnost získat prověření vozidla zdarma.',
'607518250':'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'5180415':'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
'-312241960':'* In privédatabases kan iedereen doorgaans informatie over diefstal invoeren.',
'1225691915':'Uit de beschikbare databases is gebleken dat het voertuig als taxi werd of nog steeds wordt gebruikt. Controleer grondig de technische staat van de auto.',
'-742868638':'Uw e-mailadres',
'2033866416':'Online met betalingskaart',
'138706621':'Online overschrijving',
'-1634274740':'Zobrazit Cebia REPORT',
'1047356790':'Voer de datum van eerste registratie in, alstublieft.',
'-39394372':'Advertenties van voertuigen die minder aan de eisen voldoen',
'-862383013':'Advertenties van gelijkaardige voertuigen',
'-233652021':'VOERTUIGGESCHIEDENIS',
'271847434':'Cebia REPORT',
'1918868575':'Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje fyzickou kontrolu 170 bodů na voze včetně diagnostiky či měření tloušťky laku. Získáte on-line report všech nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše <b>do 48 hodin</b> po objednání.',
'724746244':'Při objednání kontroly vozu na Automato.cz vložte do určeného pole v košíku slevový kód <b>AUTOTRACER</b> a sleva vám bude automaticky odečtena z ceny.',
'-1554923364':'(cesta technika zdarma)',
'288042578':'Voer Naam van bedrijf in, alstublieft.',
'-1335200216':'Onjuiste waarde ID.',
'1693391088':'E-mail is in onjuiste formaat!',
'-766469985':'Voer E-mail in, alstublieft.',
'-1397806656':'Instemming met verwerking van persoonlijke gegevens',
'-189484387':'Abonneren',
'1095553640':'Alle vragen',
'1531150438':'Geen antwoord gevonden?',
'-469136467':'Neem contact met ons op',
'-461584700':'Voor aankoop',
'400498991':'Cookiebeleid',
'1329764956':'Wat is een VIN nummer?',
'-394767958':'Aankoop',
'181431424':'Na betaling',
'1649241229':'De VIN nummer wordt rechtstreeks op het voertuig gestempeld of is op het kentekenbewijs en andere voertuigdocumenten te vinden. Als u een auto wilt controleren die op internet wordt geadverteerd, vraag de dealer dan om de VIN nummer.',
'1774694482':'Waar komen voertuiggegevens vandaan?',
'-1122721284':'Controleert Cebia ook voertuigen in het buitenland?',
'437878122':'Welke informatie over het voertuig zal ik te weten komen?',
'850725814':'De hoeveelheid informatie varieert van voertuig tot voertuig, afhankelijk van de leeftijd en geschiedenis van het voertuig. U kunt de omvang van de informatie over het door u gecontroleerde voertuig direct na het invoeren van de VIN nummer gratis bekijken in het overzicht van de gevonden informatie.',
'-656971513':'Hoe ziet het Rapport eruit?',
'966688141':'De kosten van een voertuigcheck hangen af van de hoeveelheid en de omvang van de informatie die beschikbaar is over het voertuig dat gecheckt wordt. U komt de omvang van de informatie over het door u gecheckte voertuig en de prijs voor de verificatie direct na het invoeren van de VIN nummer van het voertuig gratis te weten in het overzicht van de gevonden informatie.',
'764893782':'U kunt online of via Google Pay en Apple Pay betalen. Betalingen worden afgehandeld door de beveiligde betalingsgateway GoPay.',
'726713260':'Hoelang duurt het voordat ik het Rapport ontvang?',
'1478989945':'Het Rapport wordt onmiddellijk na ontvangst van de betaling gegenereerd. De dienst werkt de klok rond en het hele proces verloopt automatisch. ',
'1681038954':'Kan ik mijn bedrijfsgegevens invoeren bij een betaling?',
'696259005':'U kunt de identificatiegegevens van uw bedrijf invoeren wanneer u uw gegevens invult. Er wordt echter alleen een vereenvoudigd belastingdocument gegenereerd na betaling. Om een factuur te krijgen, kunt u contact met ons opnemen via <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a>.',
'-1555365601':'Het Rapport wordt onmiddellijk na ontvangst van de betaling gegenereerd. Er wordt ook een link naar het Rapport en de Report ID naar het bij aankoop ingevoerde e-mailadres gestuurd. Het Rapport kan tot 30 dagen nadat het is gegenereerd worden bekeken.',
'1984400663':'De betaling is niet gelukt, wat nu?',
'-956265022':'Als de betaling om de een of andere reden niet gelukt is, ga dan een stap terug en doe de betaling opnieuw. Als uw rekening is gedebiteerd maar u hebt nog steeds geen e-mail over de betaling ontvangen, neem dan contact met ons op via <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a>.',
'-246543694':'Het Rapport kan tot 30 dagen nadat het is gegenereerd worden bekeken. U kunt het Rapport bekijken via de link die naar het bij aankoop ingevoerde e-mailadres is gestuurd.',
'592240026':'Ik heb geen e-mail ontvangen, wat nu?',
'339130199':'Na betaling wordt automatisch een e-mail gegenereerd. Als u deze e-mail niet heeft ontvangen, neem dan contact met ons op via <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a>. Vermeld in het bericht de Report ID die u in het Rapport kunt vinden of uw contactgegevens.',
'1681612939':'Ik heb een verkeerde e-mailadres ingevoerd, wat nu?',
'-818807180':'Niets aan de hand. Stuur een e-mail met het juiste adres naar <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a> en vermeld de Report ID uit het Rapport in het bericht. Wij sturen u dan de toegangsgegevens naar het juiste e-mailadres.',
'-40057886':'Over het Rapport kan een klacht worden ingediend. Als u vermoedt dat er onjuiste informatie in het Rapport is opgenomen, neem dan contact op met onze klantenservice op <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a>. Klik hier voor meer informatie over de klacht.',
'-755697752':'Kan er een factuur uitgereikt worden?',
'1950759546':'Vanwege de prijs van de dienst wordt er automatisch alleen een vereenvoudigd belastingdocument gegenereerd. Om een factuur te krijgen, gelieve met ons contact via <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a> op te nemen. Om het proces te versnellen, gelieve ook de Report ID, opgenomen in de e-mail, die u na uw aankoop hebt ontvangen, te vermelden.',
'-1960272511':'Voer het VIN in',
'-371538970':'Waar vind ik het VIN?',
'-1956291670':'Hoe werk het?',
'1850076653':'stap 1',
'-1260091754':'Beschikbare gegevens',
'1279291471':'stap 2',
'-811487544':'Betaling',
'777550298':'stap 3',
'-1534039143':'Rapport',
'495126992':'stap 4',
'1916048998':'Verifiëren',
'-580693003':'Contacten',
'-760821457':'Hulp nodig?',
'1432374431':'Contactformulier',
'1838884148':'Cebia receptie',
'1665302400':'Hoofdvestiging',
'1196114687':'Tsjechië',
'-95958777':'Factuurgegevens',
'1808758025':'Telefoonnummer',
'275235071':'We zijn beschikbaar',
'353260003':'Ma - Vrij 8:00– 16:30',
'822817400':'Voertuiggeschiedenis controleren',
'-1317542970':'Geverifieerd door klanten',
'-1884218466':'Bij elke voertuig verifiëren wij',
'1736624969':'Kilometerstand',
'2137107000':'Schadegeschiedenis',
'647263705':'Diefstal van voertuig',
'-441819024':'Onderhoudsgeschiedenis',
'982206269':'Advertenties',
'1753341741':'Gebruik als taxi',
'-366535509':'Professionals gebruiken Cebia diensten',
'-1435496559':'Logo Generali Česká spořitelna',
'1847411491':'Logo Kooperativa',
'1734653310':'U kent van...',
'654946563':'tekens',
'-1398891951':'Controle van kilometerstand geschiedenis',
'1425691903':'33 % van tweedehandsauto\'s heeft een verdraaide kilometertellerstand!',
'1803799674':'Controle van leeftijd en herkomst',
'1389120536':'De leeftijd en herkomst van tweedehandsauto\'s uit het buitenland worden vaak vervalst!',
'-2068933994':'Controle op diefstal',
'-1184837319':'Controle van financiering',
'-1877453535':'Controle op schade',
'64494271':'20% van de tweedehandsauto\'s uit het buitenland zijn verongelukt!',
'1051433840':'Check of de auto niet als schadeauto werd geadverteerd!',
'1076835371':'Taxatie van het voertuig',
'-1805177459':'Terugroepactie',
'-1705145830':'Informatie over voertuig',
'1890293527':'Controle taxi',
'-13439485':'Voer Naam in, alstublieft.',
'-1147931879':'Voer Achternaam in, alstublieft.',
'2144560301':'Onjuiste waarde Telefoon',
'-1642977586':'Voer Telefoon in, alstublieft.',
'-1249914076':'E-mail is in onjuiste formaat',
'-2038223065':'Voer Bericht in, alstublieft.',
'810453022':'E-mail',
'-1217737116':'Bericht',
'-664538889':'Ik stem in met verwerking',
'1322823680':'Bericht zenden',
'56891982':'Het maximum aantal tekens is 16.',
'277834303':'Dank u',
'552239414':'Uw bericht is succesvol verzonden!',
'1851370681':'We gaan straks contact met u opnemen.',
'1952981238':'Waarschuwing',
'342579190':'Verificatie kopen',
'1586626737':'Doorgaan',
'399846737':'We laden records op',
'556543048':'Volgende informatie kan gecheckt worden:',
'560876430':'Foto\'s en advertenties',
'-320410471':'Niet ter beschikking',
'1185850210':'Geen schaderegistraties gevonden',
'-903608283':'Uittreksel van voertuiggeschiedenis',
'718416430':'Informatie over het Rapport',
'668096075':'Report ID voor herhaalde ingang:',
'-1690204814':'Geldig tot:',
'-840281270':'Versnellingsbak',
'-1901926256':'Basisgegevens over het voertuig',
'-1550051512':'Type carrosserie',
'-1043441574':'WAARSCHUWING:',
'-709435856':'Klantenervaring',
'-1149927862':'Lees wat mensen zeggen over onze verificatie',
'456577623':'schadeauto en gemanipuleerde kilometerteller ontdekt. Dank u.',
'329190632':'Voldeed aan de verwachtingen, schade aan voertuig gevonden. Dank u.',
'-1594408882':'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
'341709606':'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
'382487941':'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
'-28646667':'Povinné ručení nebo havarijní pojištění musí být sjednané prostřednictvím portálu ePojisteni.cz do 30 dní od zaplacení prověření vozidla v systému AUTOTRACER',
'-355614707':'Nabídka se týká pojištění osobních, užitkových a nákladních automobilů (nevztahuje se na pojištění motocyklů, čtyřkolek, tříkolek nebo přívěsných vozíků a návěsů',
'-134420730':'Nevztahuje se na pojištění uzavřené prostřednictvím portálu ePojisteni.cz před zakoupením prověření vozidla v systému AUTOTRACER',
'1195365944':'Po uzavření pojištění vyplňte číslo účtu a číslo uzavřené pojistné smlouvy a částka Vám bude automaticky zaslaná na Vámi uvedený účet v žádosti po splnění podmínek nejdéle však do 60 kalendářních dnů od zaslání žádosti.',
'-1622287889':'Dank u, de aanvraag is verzonden. Onze medewerkers nemen uiterlijk de volgende werkdag contact met u op.',
'1069770504':'Pokud je Vámi vybrané vozidlo individuálně dovezené do ČR, doporučujeme Vám využít ještě službu prověření původu vozidla v zahraničí PROVIN.',
'-121060108':'Prověříme registraci vozidla v zemi původu, zda bylo řádně odhlášeno, zda nebylo vozidlo vyřazeno z provozu z důvodu jeho totální havárie, zda vozidlo není evidováno v daném státě jako odcizené a další skutečnosti.',
'-1158490787':'De korting kan binnen 30 dagen na aankoop van het Rapport worden toegepast en kan niet met andere kortingen worden gecombineerd.',
'-1471409910':'Chcete mít jistotu, že Vámi vybrané vozidlo není nelegálně předělané?  Doporučujeme Vám využít službu komplexního prověření původnosti identifikátorů vozidla VINTEST.',
'1555104762':'Služba VINTEST poskytuje klientům 100% garanci výsledku fyzického prověření vozidla. Na vozidle provedeme detailní fyzickou expertízu s využitím nejmodernější digitální diagnostiky. Cílem této služby je ověřit, jestli vozidlo má všechny identifikátory (VIN, štítky, svary karoserie) originální a není podezření, že vozidlo, nebo část vozidla pochází z trestné činnosti.',
'735500953':'Het resultaat van de zoekopdracht is dat het voertuig niet onderhevig is aan financiering (in de vorm van lease of lening), verpanding en lening bij de vermelde bedrijven. Wij verkrijgen de gegevens rechtstreeks van de informatiesystemen van elk bedrijf.',
'-1173444543':'Leasing- en kredietmaatschappijen',
'-976513658':'*Carsharing = kortetermijnhuur van een auto rechtstreeks van de eigenaar (of delen van auto tussen de eigenaar en de kortetermijnlener).',
'-1479754603':'Uit de beschikbare databases is gebleken dat het voertuig niet als taxi werd of wordt gebruikt.',
'-467405909':'Gegevens over het gebruik van het voertuig als taxi',
'1930957191':'De verstrekte informatie dient uitsluitend ter informatie. Cebia is niet aansprakelijk voor schade ontstaan in verband met het gebruik van de verstrekte gegevens.',
'-1895346741':'Sdělíme vám obvyklou tržní cenu vozidla, pomocí které si ověříte, zda cena oceňovaného vozu odpovídá nabídkové ceně inzerce. Do výpočtu ceny je zahrnuto mimo jiné sledování inzerce ojetých vozidel v ČR i zahraničí včetně sledování cen skutečných realizovaných prodejů. Oceňovací algoritmus pracuje s výpočtovými modely postavenými na dlouhodobém monitoringu trhu, ale není schopen zohlednit fyzický stav vozidla.',
'-985858180':'V ojedinělých případech se může stát, že cenu vozidla nelze určit. Důvodem jsou nedostatečná data pro kvalifikované ocenění vozidla.',
'1915347014':'Zobrazíme vám inzeráty obdobných vozidel, která jsou aktuálně nabízena na trhu, a v některých případech také ceny skutečně prodaných obdobných vozidel (pomocí VIN porovnáváme přesnou specifikaci vozidel). Pomocí inzerce podobných vozidel si můžete porovnat, zda cena ověřovaného vozidla odpovídá podobným vozidlům na trhu. Ušetříme vám spoustu času stráveného vyhledáváním a porovnáváním podobných inzerátů na webu.',
'-767763886':'Er zijn geen gegevens over voertuigadvertering gevonden.',
'797541609':'Controleer in welke staat en met welke kilometerstand de auto in het verleden is geadverteerd. Als de auto geadverteerd werd met een hogere kilometerstand dan nu het geval is, is het mogelijk dat er met de kilometerstand geknoeid werd.',
'-1902352540':'Verificatie van de ingevoerde gegevens. Eenmaal opgeslagen, kunnen ze niet meer gewijzigd worden.',
'-2008975653':'Er zijn geen gegevens gevonden over de kleur van het voertuig, geregistreerd bij de fabrikant of bij eerste registratie van het voertuig.',
'7150984':'Controleer of de huidige kleur van het voertuig overeenkomt met de kleur die door de fabrikant is opgegeven of met de kleur die geregistreerd is bij de eerste registratie van het voertuig. Als de kleur verschilt, is het voertuig mogelijk overgespoten, verongelukt of gestolen.',
'-1664139465':'De uitrustingslijst van het voertuig is niet beschikbaar. Wij raden u aan om de functionaliteit en originaliteit van de uitrusting direct in het voertuig te controleren.',
'1681595153':'Het resultaat van de controle is informatie over of het door u gecheckte voertuig beveiligd is door middel van een glasmarkering en geregistreerd is in het OCIS-systeem. Zo ja, dan krijgt u van ons de gemarkeerde code en de datum van registratie.',
'571758489':'Het voertuig is in het verleden van een glasmarkering voorzien en staat geregistreerd in de internationale OCIS-database.',
'855264535':'Als de unieke code op sommige of alle ruiten van het voertuig ontbreekt, kan het voertuig verongelukt, omgebouwd of gestolen zijn.',
'388233970':'Gegevens over de uitgevoerde glasmarkering:',
'1615022749':'De technische specificaties van het voertuig konden niet worden gevonden. U kunt ze echter vinden in het  technische gegevensblad van het voertuig of u kunt ze opvragen bij de fabrikant van het voertuig.',
'704641294':'Nadat u het voertuig hebt geselecteerd, geeft de toepassing u instructies voor een basiscontrole van de identificatiegegevens. De instructies gelden in het algemeen voor het opgegeven merk en model van het voertuig. Voor de demonstratie is een willekeurig gekozen voertuig gekozen.',
'-621659477':'De voertuigselectie kan al gedeeltelijk vooraf ingevuld zijn op basis van de ingevoerde VIN. Als de vooraf ingevulde voertuigselectie niet overeenkomt met het voertuig dat u controleert, bewerkt u de selectie handmatig.',
'152333450':'Geen schaderecords gevonden, wat betekent dit?',
'780709713':'Hoelang blijft de Rapport beschikbaar?',
'830704335':'Zoek uit welke informatie beschikbaar is voor uw auto:',
'1850331238':'Geweldige service, de moeite waard. Ik ontdekte ook het geknoei met de kilometerstand van de showauto.',
'1705245752':'Ik wilde een auto kopen waarvan ze zeiden dat er geen ongeluk mee was gebeurd. Het was meerdere keren verongelukt.',
'739619867':'Absoluut perfect ding, wij hebben geen verongelukte auto voor veel geld gekocht, bedankt!',
'-2071079400':'Ik koos een auto waar niet aangegeven was dat hij twee ongelukken met veel schade had gehad en hier ontdekte ik dat voordat ik hem kocht. Dank u.',
'21202350':'Dank u voor deze programma. Ik was bijna 90 duizend voor een auto kwijt. Volgens de eigenaar is de auto slechts licht aangetast aan de voorkant. Volgens het programma is hij twee keer verongelukt met grote schade.',
'1343185270':'Verificatie stelde me gerust en hielp me beslissen om te kopen.',
'2083029914':'Er was grote schade waarover de dealer mij niet had verteld.',
'1933958141':'Een kleine aanrijding gedetecteerd + kilometerteller controle vanaf de registratiedatum.',
'1900495866':'Dankzij de app kwam ik erachter dat er iets mis was met de auto. Natuurlijk vertelde de dealer me er niets over en de verkoper zweeg waarschijnlijk ook. Dank u.',
'-1668804606':'Tijdens de inspectie vond ik ernstige gebreken die de verkoper voor mij verborgen had gehouden, zoals de kilometertellerstand, het verongelukte voertuig in het verleden.',
'-273876487':'Ik ontdekte dat het voertuig een gemanipuleerde kilometertellerstand had. De verkoper beweert 155.000 km en in werkelijkheid is het iets van 250.000 km...',
'911857027':'Voer het Report ID in om het Rapport te bekijken',
'1167593586':'U kunt het Report ID krijgen door het Car History Report te kopen',
'-2136315768':'Als u met creditcard, GooglePay of ApplePay betaalt, ziet u onmiddellijk de gekochte informatie en tegelijkertijd ontvangt u het Report ID per e-mail.',
'868918064':'Als u per bankoverschrijving betaalt, ontvangt u het Report ID per e-mail zodra het geld op  Cebia-bankrekening is bijgeschreven.',
'1572179326':'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
'-1322783148':'De locatie van het VIN verschilt per merk en model voertuig. Het is echter altijd te vinden op kentekenbewijs en op technische gegevensblad en vaak bijvoorbeeld onder de voorruit.',
'700484420':'VIN is het unieke nummer van elke auto. Het kan in de voertuigdocumenten of direct op het voertuig gevonden worden.',
'-851712281':'Wij sporen alle beschikbare records op voor de auto die u checkt en laten u de omvang ervan zien. U weet altijd wat u koopt.',
'-1983041378':'U kunt betalen via de beveiligde GoPay betalingsgateway per kaart of bankoverschrijving.',
'-1809684191':'Het Rapport is direct na betaling beschikbaar. Er wordt ook een link naar uw e-mail gestuurd.',
'541894049':'U zal de waarden van kilometertellerstand voor de betreffende periode te weten komen. Een eenvoudige grafiek laat u zien hoe de kilometertellerstand zich in de loop van de tijd heeft ontwikkeld en zo zal u eventuele manipulatie ontdekken.  ',
'643692561':'U kunt informatie zien over de geregistreerde schade op het voertuig. Er kan in de details informatie over het schadebedrag en de omvang van de schade toegevoegd worden. Let op: vergeet niet om tegelijkertijd de technische staat van het voertuig en de eerdere schade te controleren. Bijvoorbeeld of het een total loss was.',
'878444309':'Als het voertuig in het verleden geadverteerd werd, zullen wij u de advertentie en fotodocumentatie van het voertuig laten zien.',
'185243225':'U vindt gedetailleerde informatie en een overzicht van de onderhoudshandelingen, uitgevoerd als onderdeel van de reparaties of het routineonderhoud van het voertuig.  Let op: vergelijk de gegevens met het onderhoudsboekje en vraag om meer informatie over de onderhoudsgeschiedenis van het voertuig.',
'1036319803':'Wij verifiëren voor u het daadwerkelijke bouwjaar, wat een aanzienlijke impact kan hebben op de huidige en toekomstige prijs van het voertuig. Het bouwjaar wordt niet meer vermeld in de technische documenten. Enkel de datum van de 1e inschrijving wordt ingevuld. Dit kan echter maximaal 2 jaar afwijken van het bouwjaar, maar 6 jaar is geen uitzondering. Tegelijkertijd kan een jaar verjongen enkele tienduizenden kronen in de prijs van het voertuig betekenen, die u extra betaalt.   <br>Bij de meeste voertuigen vindt u ook de datum van eerste registratie en voor welk land het voertuig oorspronkelijk is geproduceerd.',
'-437053085':'Wij zullen het voertuig checken in de momenteel beschikbare databases van gestolen voertuigen. Dit zijn voornamelijk Europese databases van staats- en andere bronnen. ',
'494784690':'We vergelijken het voertuig met databases van financieringsmaatschappijen, autoverhuurbedrijven en pandhuizen. Het betaalde Rapport kan gebruikt worden om te checken of de auto niet bezwaard is met financiering, een lening of een pandrecht.',
'2074346923':'Check in de databases van voertuigen die als taxidienst worden geëxploiteerd. In het betaalde gedeelte kunt u nagaan of de auto als taxi werd of nog steeds wordt gebruikt.',
'-976680067':'Wij tonen u een lijst met terugroepacties die betrekking kunnen hebben op het gecheckte voertuig.',
'1665109686':'U kunt zelf de belangrijke parameters checken op basis waarvan de modelversie van het voertuigtype werd goedgekeurd voor exploitatie.   Vergelijk deze parameters met de gegevens op het kentekenbewijs van het voertuig.',
'-719963267':'Wij laten u een lijst zien van de uitrusting van het voertuig volgens de specificaties van de productie. Zo kunt u de huidige uitrusting van het voertuig vergelijken met de specificaties van de fabrikant. Let op: als de fabrikant bijvoorbeeld het stuur rechts, lederen bekleding of een schuifdak in de voertuigparameters vermeldt en uw voertuig een stuur links, stoffen bekleding of geen schuifdak heeft, is het waarschijnlijk niet hetzelfde voertuig, maar mogelijk wel een gestolen en omgebouwd voertuig!   In het geval van een discrepantie moet u extra aandacht besteden aan verdere fysieke inspectie van het voertuig om te voorkomen dat u een slechte aankoop doet van een voertuig die mogelijk het gevolg is van criminele activiteiten.',
'-1106584159':'Uw voertuig is beveiligd door zandstralen of glasetsen, of er kan een markering op carrosseriedelen aangebracht worden. Wij delen u mede de gemarkeerde code en de datum van registratie.',
'1830950672':'Wij tonen u de kleur die door de voertuigfabrikant geregistreerd is en de kleur die op de voertuigregistratie vermeld staat.',
'1835889470':'We laden gegevens op...',
'1020539397':'De operator van de toepassing Car History - het bedrijf Cebia, spol. s r.o., informeert de gebruikers van het systeem dat de verstreken informatie  afkomstig is van partners van de operator zonder de mogelijkheid om de nauwkeurigheid ervan te verifiëren. Alle informatie heeft uitsluitend informatieve karakter.',
'2054100346':'Er is geen garantie op het Car History Report.',
'-167783152':'Het Rapport werd door de dienst Car History opgemaakt, die wordt beheerd door Cebia, spol. s r.o. in overeenstemming met de Algemene Voorwaarden voor het gebruik van de dienst Car History. De gebruiker mag de verstrekte informatie in zijn geheel alleen voor eigen gebruik gebruiken. Verdere distributie of wijziging zonder toestemming van de operator is verboden.',
'-1697979879':'Directeur van Cebia, Ing. Martin Pajer',
'-893067318':'Ing. Martin Pajer Directeur van de vennootschap Cebia',
'759051798':'Vennootschap Cebia',
'-31539157':'Wij zijn een Tsjechisch bedrijf dat in 1991 in Praag is opgericht. Onze diensten op het gebied van voertuigscreening, beveiliging en identificatie worden door klanten in 9 Europese landen gebruikt. Onze klanten zijn gewone automobilisten, verzekeringsmaatschappijen, autodealers, overheidsinstellingen, politiediensten en beëdigde deskundigen. In onze geschiedenis hebben wij tienduizenden fraudegevallen met gebruikte voertuigen opgespoord en miljoenen klanten in heel Europa geholpen met hun autoaankopen.',
'-1821588851':'Claims en klachten',
'-276118588':'Claims en klachten zijn voor ons een waardevolle bron van suggesties om onze service te verbeteren. Neem contact met ons op via het contactformulier of per e-mail: autotracer@cebia.com',
'-1446517877':'Wij zullen uw klacht binnen 14 dagen na ontvangst behandelen en u op de hoogte stellen van het resultaat.',
'708234375':'Neem contact met ons op via het contactformulier of door een e-mail te sturen naar <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a>',
'1474175101':'Algemene voorwaarden van dienst',
'1343500875':'Privacybeleid',
'-2083968164':'Ontwikkeling van een eigen taxatietool voor voertuigen',
'907588970':'Oprichting van de vennootschap te Praag',
'-770165824':'30 jaar ervaring in autoverificatie',
'-1138482741':'1.000.000+ auto\'s elk jaar gecheckt',
'154007106':'750.000+ tevreden klanten',
'177137909':'De Cebia database bevat meer dan 1 miljard records van gebruikte voertuigen uit meer dan 32 landen. Deze omvatten kilometerstanden, voertuigschade, voertuigfoto\'s en onderhoudsgegevens.',
'723486619':'Ik laad de gegevens op',
'742852638':'Periode:',
'787763184':'periode:',
'-685036969':'Notities:',
'290784658':'Niet ter beschikking:',
'412892373':'Geen informatie gevonden voor dit voertuig in deze categorie',
'1853282090':'Ter beschikking:',
'-249038921':'XY records:',
'805146013':'We hebben XY-records gevonden voor het voertuig in deze categorie, die u kunt vinden in het betaalde gedeelte',
'242118549':'Algemene Voorwaarden',
'521725542':'Het tonen van Algemene Voorwaarden voor het gebruik van de dienst Car History',
'-571686819':'hier',
'639498968':'De betaling voor het weergeven van gedetailleerde informatie in Car History wordt aan u gecrediteerd volgens de voorwaarden die zijn vastgelegd in het contract met Cebia.',
'-1705426324':'U kunt gedetailleerde informatie voor 1 VIN gedurende 30 dagen bekijken vanaf de eerste keer dat u dat VIN bekijkt en u betaalt maar één keer.',
'529403448':'Belangrijke waarschuwing',
'-821000576':'Cebia, spol. s r.o. wil de gebruikers van de dienst ervan op de hoogte stellen dat de verstrekte informatie door de partners van de operator is verstrekt zonder de mogelijkheid om de nauwkeurigheid ervan te controleren. Alle informatie dient uitsluitend ter informatie. In het geval dat de toestand van het voertuig in belangrijke mate in strijd is met de hierin verstrekte informatie, raden wij u aan contact op te nemen met een geschikte expert om de werkelijke staat van het voertuig te verifiëren.',
'-1138137280':'Informatie over de verwerking van persoonlijke gegevens',
'125063053':'Het tonen van informatie over de verwerking van persoonlijke gegevens ',
'-608864551':'We hebben informatie gevonden voor het voertuig in deze categorie, die u kunt vinden in het betaalde gedeelte',
'-1879995849':'Ingevoerde gegevens',
'754897480':'JAAR VAN EERSTE REGISTRATIE',
'-1834106204':'HUIDIGE KILOMETERTELLERSTAND',
'404797827':'ZOBRAZIT CEBIA REPORT',
'1358780725':'RAPPORT GENEREREN',
'-952450705':'Ik heb de verstrekte informatie gecontroleerd en ik bevestig het',
'-1601808583':'Klaar! Voor het opgegeven voertuig kan de volgende informatie gecheckt worden: ',
'-1763095911':'Lze vystavit Cebia REPORT hodnotící následující kategorie:',
'761362384':'Ukázka REPORTU',
'-397155268':'RAPPORT BEKIJKEN',
'1375027269':'VOERTUIG CHECKEN',
'2055286638':'meer dan',
'-1668243810':'We zijn sterker',
'1141836815':'We worden onderdeel van de Tsjechische holding EAG. Als gevolg daarvan worden onze databases aanzienlijk uitgebreid met gegevens uit heel Europa - met name Duitsland, Oostenrijk en Denemarken.',
'-903271711':'We lanceren een online tool voor het bepalen van de prijs van tweedehands voertuigen. Dankzij onze uitgebreide database met gegevens van tweedehandsauto\'s is deze zeer nauwkeurig en vindt deze snel zijn weg naar de grootste verzekerings- en leasemaatschappijen.',
'-1262829232':'Wij veranderen de tweedehandsvoertuigenmarkt',
'1367974718':'Met de groeiende belangstelling voor het kiezen van auto online beginnen we samen te werken met de grootste advertentieportalen in Centraal-Europa. De resultaten van onze geschiedenischeck worden het keurmerk voor de kwaliteit van een tweedehands auto.',
'-300528762':'stap in de online wereld',
'-899322976':'Onze diensten zijn nu gemakkelijk toegankelijk voor iedereen vanuit het comfort van hun eigen huis. Oplichting met tweedehandsauto\'s is op zijn hoogtepunt en wij bestrijden deze uit alle macht.',
'-4875574':'We breiden onze diensten uit',
'1166185728':'We voegen het controleren van het fysieke voertuigidentificatienummer (VIN) toe aan onze diensten en onze database breidt zich met sprongen uit. Wij werken samen met verzekeringsmaatschappijen, overheidsinstanties en de politie.',
'-1619922514':'Intrede in het buitenland',
'-844900279':'Niet alleen in Tsjechië groeit de handel in gestolen voertuigen. Daarom zijn we bezig met het opzetten van een internationale database van gestolen voertuigen die ons help om de ware herkomst van gebruikte auto\'s te achterhalen.',
'598423318':'Drie medestudenten van de universiteit richtten de vennootschap Cebia op. De oorspronkelijke bedoeling om in de IT-business te gaan, werd al snel vervangen door een interesse in de beveiliging van voertuigen.',
'-1828699417':'Inbeslagnames/Insolventie',
'4000011':'Gratis documentenverificatie',
'1479506336':'Zoekgeschiedenis',
'-1478881101':'Geschiedenis van voertuigverificatie',
'1115668272':'Geschiedenis van Inbeslagnames/Insolventie',
'691760517':'Geschiedenis van documentenverificatie',
'-614781938':'De omvang en kosten van een verificatie kunnen per voertuig variëren, afhankelijk van de hoeveelheid beschikbare informatie.',
'702191442':'Terug',
'-1034048918':'Controle van VIN ',
'-1214567423':'Referentienummer',
'427849990':'Vytvořit CERTIFIKÁT kontroly vozu',
'-1718377395':'Fout bij het opladen.',
'877365836':'Het is niet gelukt om een url voor het betalen te genereren.',
'-1082791212':'Onjuiste waarde ',
'-255296664':'Met het Report ID kunt u het Rapport gedurende 30 dagen na de activatie herhaaldelijk bekijken.',
'456125545':'De operator van de dienst Car History - de vennootschap Cebia, spol. s.r.o. informeert gebruikers dat de vermelde informatie door partners van de operator verstrekt werd zonder de mogelijkheid om de nauwkeurigheid ervan te verifiëren. Alle informatie dient uitsluitend ter informatie.',
'-1172808350':'Neem contact op met de dealer om het VIN van het voertuig te krijgen en check het voertuig vervolgens.',
'-2079080642':'Neem contact op met de dealer om het 17-cijferige VIN van het voertuig te krijgen en check het voertuig vervolgens.',
'-585052469':'Koopt u een tweedehandsauto? Zoek er alles over uit! Vooral de kilometertellerstand, het bouwjaar of schadegegevens volgens VIN-code.',
'76811920':'VOORBEELD van Rapport van een toevallig voertuig.',
'1050846059':'Wij raden nog steeds een zorgvuldige inspectie van uw voertuig aan, idealiter met de hulp van een gekwalificeerde monteur of bij een garage van uw keuze. Een dergelijke inspectie kan eerdere schade met zekerheid opsporen of uitsluiten.',
'-1068264553':'Controleer altijd de bouwdatum die door de autofabrikant is opgegeven. De kentekenbewijzen vermelden alleen de datum van eerste registratie, die aanzienlijk kan afwijken van de bouwdatum (bijvoorbeeld tot twee jaar). Hierdoor kan het voertuig als jonger aangeboden worden, wat een aanzienlijk effect heeft op de marktprijs.',
'756232271':'Er zijn geen gegevens over de leeftijd of herkomst van het voertuig gevonden. Als het voertuig uit het buitenland komt, controleer dan altijd zorgvuldig het originele buitenlandse kentekenbewijs. Als u er zeker van wilt zijn dat het voertuig afkomstig is uit het opgegeven land en dat het niet gestolen is, kunt u gebruikmaken van de dienst voor herkomstverificatie in het buitenland <a class="Link Link--lightBlue" href="https://www.cebia.cz/sluzby/provin" target="_blank">PROVIN</a>.',
'-643660678':'Er zijn verslagen gevonden van onderhoudswerkzaamheden die in het verleden aan het voertuig zijn uitgevoerd als onderdeel van reparaties of routineonderhoud.',
'1533967406':'De kosten van een voertuigcheck hangen af van de hoeveelheid en de omvang van de informatie die beschikbaar is over het voertuig dat gecheckt wordt. U komt de omvang van de informatie (en dus ook de prijs) direct na het invoeren van het VIN van het voertuig te weten.',
'-1289463237':'Het Rapport wordt onmiddellijk na ontvangst van de betaling gegenereerd. De dienst werkt de klok rond en het hele proces verloopt automatisch.',
'1304585346':'Het Rapport wordt onmiddellijk na ontvangst van de betaling gegenereerd. U vindt een link naar het Rapport in de e-mail die u na de betaling ontvangt. In de e-mail ontvangt u ook een Report ID waarmee u het Rapport 30 dagen lang herhaaldelijk kunt bekijken.',
'-661494819':'Fout bij het laden van de gegevens.',
'-1681989289':'u hebt al een rapport gekocht, waardoor u recht hebt op een korting van 100 CZK op deze aankoop. U kunt de korting gebruiken door het Report ID dat u per e-mail hebt ontvangen in te voeren in het veld "Voer Report ID in".',
'681093117':'získal/a jste slevový kód při prodloužení služby FLEX na další rok. Slevu uplatníte zadáním čísla slevového kódu, který jste obdržel/a na daňovém dokladu k platbě služby FLEX, do pole „Zadejte číslo kuponu“.',
'-103162544':'De informatie die nodig is om het Rapport te bekijken en een vereenvoudigd belastingbewijs, zullen na betaling naar het opgegeven e-mailadres gestuurd worden.',
'-591911074':'De informatie die nodig is om het Rapport te bekijken en een vereenvoudigd belastingbewijs, zullen na betaling naar het opgegeven e-mailadres gestuurd worden.',
'706315962':'U ontvangt altijd een vereenvoudigd belastingbewijs samen met de informatie die nodig is om het Rapport te bekijken. Als u uw factuurgegevens (bedrijfsnaam, adres, identificatienummer, fiskale identificatienummer) op het belastingdocument ingevuld wilt hebben, vul deze dan hieronder in.',
'500915765':'Wanneer u een bestelling plaatst, wordt u doorgestuurd naar de GoPay betalingsgateway om een veilige betaling met creditcard uit te voeren. Na de betaling wordt u doorgestuurd naar het resultaat van de verificatie, dat ook naar u wordt gemaild, samen met het belastingbewijs.',
'-676823504':'Als u voor deze betaalmethode kiest, wordt u doorgestuurd naar de GoPay-betaalgateway waar u uw bankinstelling selecteert van waaruit u de betaling wilt uitvoeren.',
'-764670786':'Als uw bank een zogenaamde online overschrijving biedt, wordt u doorgestuurd naar uw online bankieren, waar u een vooraf ingevulde betalingsopdracht te zien krijgt en de betaling uitvoert.',
'2062466434':'Als wij de betaling van uw bank onmiddellijk ontvangen, sturen wij u automatisch door naar het betaalde gedeelte van de dienst Car History. Wacht anders tot u een e-mail ontvangt.',
'734607756':'Zodra de betaling op onze rekening is bijgeschreven, sturen wij een link naar het Rapport en het belastingbewijs naar uw e-mailadres.',
'1715999355':'Google Pay is een snelle en gemakkelijke manier om te betalen zonder dat u uw betalingsgegevens hoeft in te voeren. Om deze betaaldienst te gebruiken, moet uw bank dit ook ondersteunen. Nadat de betaling is verricht, sturen wij een link naar het Rapport en het belastingbewijs naar uw e-mailadres.',
'790632208':'Apple Pay is een betaalmethode waarmee u eenvoudig kunt betalen met een kaart, opgeslagen in Apple Wallet. Om deze betaaldienst te gebruiken, moet uw bank dit ook ondersteunen. Nadat de betaling is verricht, sturen wij een link naar het Rapport en het belastingbewijs naar uw e-mailadres.',
'-1402859324':'Als uw bank alleen offline overschrijving biedt, zullen wij overschrijvingsinstructies voor u genereren,  vervolgens doet u bij uw bank een geldoverschrijving en zodra het bedrag op onze bankrekening is bijgeschreven, sturen wij u een e-mail met een link naar het Rapport en met het betalingsbewijs.',
'347099860':'Deze transactie kan tot 2 werkdagen duren (maar meestal 24 uur).',
'-43783036':'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
'-802148009':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla.',
'1985201267':'De grootste database met autogegevens in Europa',
'-1590601891':'De dienst Car History heeft mogelijk niet alle informatie over autoschade ter beschikking, sommige schade wordt niet aan verzekeraars gemeld of is niet beschikbaar als onderdeel van de online controle.',
'-1465948017':'<strong>Systém OCIS –</strong> značení skel vozidla. Jednoduché, efektivní a cenově výhodné zabezpečení vozidla. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html\' target=\'_blank\'>Více zde.</a>',
'1553997983':'<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style=\'color: #013475; font-weight: bold;\' href=\'https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html\' target=\'_blank\'>Více zde.</a>',
'-124714580':'U kunt online betalen met een creditcard of via Google Pay of Apple Pay. ',
'-753863533':'CERTIFICAAT VOERTUIGVERIFICATIE - overzicht van beschikbare informatie',
'1441255407':'Categorie',
'1549893453':'Verificatiemogelijkheid',
'1547427535':'JA',
'508023484':'Verificatie van registratiedatum',
'2076692599':'Fysieke inspectie',
'-1521980547':'Controle van de kilometertellerstand',
'-1009985044':'JA - op basis',
'1747355819':'gegevens',
'1639206028':'Controle voertuigherkomst',
'-1524110425':'Controle betaling ECO belasting',
'-1961089879':'Controle van BTW',
'1732127987':'Controle ernstige ongeluk',
'-198755848':'Controle registratie',
'-1790774232':'Controle op beschadiging door overstroming',
'-1781437228':'Controle motor type',
'-1344389293':'Controle voertuig identificatiegegevens',
'-499181915':'Foto',
'-687939409':'De informatie is ontleend de registers van diensten voor het documenteren van voertuigconditie van Cebia of van haar aannemers.',
'-43022982':'Datum uitvoeren van de dienst:',
'-136414993':'staat van het voertuig:',
'2023782231':'Het voertuig is fysiek gecontroleerd op zijn identificatiekenmerken (VIN-stempel, productieplaatje, enz.). De informatie is afkomstig uit het register van de dienst VINTEST die door Cebia of haar partners wordt bijgehouden.',
'758499555':'staat van de identificatiekenmerken:',
'488100027':'TERUG',
'-1281249872':'DOORGAAN',
'-141562572':'GENEREREN',
'-40297285':'U kunt zo een overzicht krijgen van de prijs- en gegevensontwikkeling van het gecheckte voertuig.',
'-1742933347':'Advertentienummer',
'-2099386182':'Verkoop',
'1560408371':'Verkoopprijs',
'-280814568':'HET VERIFICATIECERTIFICAAT VAN HET VOERTUIG TONEN',
'2075274708':'Datum van aankoop',
'-1248991647':'Oekraine',
'-1779298552':'Zweden',
'-18328562':'Letland',
'1764744897':'Litouwen',
'-1273046880':'Roemenië',
'1252567719':'Bulgarije',
'445626359':'Griekenland',
'-868498706':'slovenië',
'-1528448787':'Hongarije',
'-387469480':'Slowakije',
'25300212':'Oostenrijk',
'1771002523':'Italië',
'69077775':'Denemarken',
'647944028':'Polen',
'1160986873':'Duitsland',
'897084544':'Frankrijk',
'2056598840':'spanje',
'264100206':'Rusland',
'-1609348689':'Cookiebeleid',
'-365994139':'Volgende opladen',
'807848789':'Miljoenen tevreden klanten',
'1677683955':'Cebia heeft meer dan 2 000 partners in heel Europa die ons voorzien van voertuiggegevens van over de hele wereld.',
'652553309':'Weet u dat ...',
'1453750886':'Klaar!',
'1498262202':'<label classname="color-orange">ter beschikking</label>',
'1514779988':'Voertuiginspectie door technicus',
'1058658544':'Cebia kortingscode',
'-2035347536':'0 records',
'1050102897':'De gedetailleerde uitrustingsspecificatie van de autofabrikant omvat alle basis- en optionele uitrusting. Bij een controle van de uitrusting kunnen wijzigingen aan de auto ontdekt worden, zoals een wijziging van de stuurkant.',
'2145745364':'Uitschrift van kilometerteller records',
'-1636264801':'Beschrijving',
'-1488190529':'Record nummer',
'64594583':'Gefinancierd voertuig',
'-1441353378':'Zonder financiering',
'-2101084179':'januari',
'-1066567335':'februari',
'-1761670105':'maart',
'104663035':'april',
'1397259452':'mei',
'-642974117':'juni',
'613657227':'juli',
'-2044487450':'augustus',
'1921417285':'september',
'-1672709282':'oktober',
'-678062097':'november',
'1302554433':'december',
'1268839568':'Er zijn geen gegevens gevonden in de databases met onderhoudswerkzaamheden. Vraag een uittreksel uit het onderhoudsboekje (bij voorkeur uit een elektronisch onderhoudsboekje) van het voertuig. U kunt de echtheid van de onderhoudswerkzaamheden direct bij de garage waar ze volgens het onderhoudsboekje zijn uitgevoerd, controleren.',
'-112660439':'Zde si vytvoříte Osvědčení <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, které můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále – <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
'-1157869217':'Record niet gevonden',
'-1350474522':'In de beschikbare databases zijn er geen kilometertellerstanden van het gecheckte voertuig.',
'1040913417':'Wij raden u aan om andere mogelijke bronnen van kilometertellerstanden te controleren, zoals het onderhoudsboekje of door de onderhoudsgeschiedenis bij een erkende garage op te vragen. ',
'1010039483':'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
'-622228786':'E-mail',
'-982011601':'Verenigde Staten',
'786789662':'Nákup zvýhodněných balíčků',
'737703701':'Potřebujete prověřit více vozidel? Pro firmy i soukromé osoby nabízíme balíčky za zvýhodněné ceny: 10 prověření za 5 000 Kč, 20 prověření za 10 000 Kč a 50 prověření za 20 000 Kč. Máte-li o jeden z balíčků zájem, zadejte prosím níže své kontaktní údaje a do zprávy uveďte balíček, o který máte zájem. My se vám obratem ozveme. ',
'-174399420':'Chcete nakoupit na firmu nebo prověřit více vozidel? Využijte naše',
'845754511':'zvýhodněné balíčky!',
'-614796774':'Tsjechië',
'1011350398':'Ma - Vri: 8:00 - 16:30',
'-68730396':'Op de dag:',
'544810671':'NEE - niet geregistreerd',
'-1670731287':'In Tsjechië ingeschreven als:',
'1597705322':'standaarduitrusting',
'16575694':'Voertuig:',
'-846486931':'records',
'-1615816320':'record',
'1415114012':'records',
'1802294807':'foto\'s',
'1122888753':'foto',
'53347610':'persoonlijke gegevens',
'1802705032':'<a classname="Link Link--lightBlue" href="/detailArticle/waar-vind-ik-vin-code" target="_blank" rel="noreferrer noopener">Meer informatie</a>',
'883512613':'VIN-verificatie- Online voertuigverificatie',
'-1965309318':'Controleer de autogeschiedenis online in de grootste database met gegevens van tweedehands voertuigen in Europa. Controleer op schade, kilometerstandgeschiedenis, onderhoudshistorie, foto\'s en meer.',
'-1871315210':'Betalingsmethode kiezen ',
'-1803227447':'selecteer een geschikte betaalmethode, alstublieft.',
'-1249181167':'Nieuws - Car History',
'-838385662':'Nieuws over de verificatie en kopen van tweedehands voertuigen. Leer hoe u niet in oplichtingstrucs trapt.',
'1154868985':'Veelgestelde vragen - Online voertuigverificatie',
'908158246':'Bekijk de meest gestelde vragen van klanten over Cebia\'s online voertuigverificatie. Als u een andere vraag hebt, gaan we het graag beantwoorden.',
'-633966577':'Over ons',
'1171158673':'Leer Cebia kennen, haar geschiedenis en ontdek waarom miljoenen klanten in heel Europa op Cebia vertrouwen.',
'-1244282008':'Cookiebeleid',
'-792206035':'Deze website gebruikt verschillende cookie\'s. Sommige cookie\'s worden door derden geplaatst. U kunt uw toestemming voor het gebruik ervan te allen tijde intrekken.',
'1489309005':'Gratis ingang binnen de limiet van het contract',
'-470675791':'Ik heb de verstrekte informatie gecontroleerd en ik bevestig het.',
'2108896817':'ANNULEER DE INGEVOERDE KORTING',
'6892736':'Korting is geannuleerd',
'-1199844274':'Code is niet geldig!',
'-552424253':'Toegang tot het betaalde gedeelte wordt in rekening gebracht conform het contract',
'167769730':'Platba hotově',
'1768026018':'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
'820786512':'Code is geaccepteerd, prijs is herrekend',
'-279227794':'Controle bouwjaar',
'1470281039':'Controle  leasing/lening in Tsjechië',
'60776637':'Report ID',
'529459564':'Voer Report ID in, alstublieft.',
'-861194224':'Voertuiginspectie',
'2076412240':'nieuw',
'360601996':'Het Rapport is verlopen.',
'1762078183':'Materiaal',
'822990672':'Door op deze knop te drukken, gebruikt u 1x een aanvraag uit de gratis aanvraaglimiet volgens het geldige contract.',
'532528312':'tweedehands',
'-319106355':'Voertuig',
'935795728':'Rapportvoorbeeld',
'-916000765':'Car History Report',
'-1721948126':'Rapportvoorbeeld. Controleer de autogeschiedenis online in de grootste database met gegevens van tweedehands voertuigen in Europa. ',
'329882651':'Als u op deze knop drukt, wordt de prijs voor het weergeven van gedetailleerde informatie volgens het huidige contract in rekening gebracht.',
'-494157101':'Belastingsbewijs',
'-465994559':'Foto',
'857656094':'Fabrieksmerk:',
'-2005319583':'Model voertuig:',
'339937023':'Jaar van eerste registratie volgens het kentekenbewijs:',
'-441771827':'We laden data op',
'-161761801':'niet-gecheckte periode',
'1229398238':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Vozidlo je evidované jako <strong className="bolder">odcizené</strong>.',
'358386818':'Leeftijd voertuig:',
'-701699977':'Cebia REPORT nelze vytvořit - VIN nebylo možné jednoznačně identifikovat. </br> Zkontrolujte VIN podle dokladů od vozidla, případně <a href="mailto:autotracer@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN:{0}">Kontaktujte podporu</a>.',
'-1359814132':'Andere afbeelding',
'340541220':'Inzoomen',
'2096545526':'Uitzoomen',
'-158382161':'Vorige afbeelding',
'-749344895':'U krijgt een korting',
'-1755324145':'Model voertuig',
'1747292041':'Smart kód',
'1093009871':'Chytrá komunikace pro moderní autocentra',
'-421925379':'Chcete u svých vozidel propagovat, že jsou prověřená?',
'-303554559':'Dejte na každé vozidlo Smart kód a zákazník si sám načte výsledek jeho prověření.',
'5128661':'Úplně jednoduše. Cebia Smart kód vytisknete v systému AUTOTRACER (ve formátu A5) a umístíte za sklem prodávaného vozidla.',
'1608809132':'Zákazník si pak sám pomocí mobilního telefonu přes OR kód načte základní informace o vozidle a zobrazí si graf s nájezdem kilometrů.',
'-703122978':'Ostatní informace (kompletní výpis ze systému AUTOTRACER) si pak může vyžádat přímo od Vás.',
'-566386963':'Vul dit korte formulier in en wij nemen contact met u op.',
'-1639125181':'Verzenden',
'76421921':'Verplichte posten',
'-2097738269':'Po načtení  QR kódu se zákazníkovi v jeho mobilním telefonu zobrazí „základní výpis informací z historie vozidla“',
'-80023088':'Máte zájem tisknout u vašich vozidel Smart kódy? Kontaktujte naše obchodní oddělení:',
'2094660384':'Petr Kala, vedoucí obchodního oddělení',
'361077673':'Of vul het formulier in en wij nemen contact met u op:',
'1277961418':'Opravdu chcete vygenerovat Smart kód?',
'-2133968142':'Ik heb een kortingscode',
'-1922950659':'Betalingswijze',
'-2072875261':'Gecheckte voertuig',
'783887791':'Bestelling',
'-1499237034':'gratis',
'-1854208328':'Kortingscode gebruiken',
'-793343279':'cadeau',
'-1393236672':'Kosovo',
'1342791550':'Kilometerstand geschiedenis',
'2063498881':'Optionele uitrusting',
'-152116623':'QR – Smart kód',
'-956949153':'De hoogte van de schade',
'-552950132':'gegeven',
'30639535':'gegevens',
'-1164317619':'Nee',
'1402944789':'Gelieve de verplichte gegevens in te vullen',
'-1812102598':'VAROVÁNÍ:Nelze provést <strong>kontrolu stavu tachometru</strong>. Kategorie bude mít výsledek - nedostatek informací.',
'666398003':'Waarschuwing!',
'771644825':'Het ingevoerde VIN kan onjuist zijn!',
'-1131044763':'Meer informatie nodig?',
'-1369269346':'Er is mogelijk een fout ontdekt in het ingevoerde VIN. Controleer of het ingevoerde VIN overeenkomt met het VIN dat op het voertuig of op het kentekenbewijs staat.',
'-1625517200':'Waar vind ik het VIN?',
'1171142400':'De betaling werd niet verricht. Herhaal de betaling, alstublieft.',
'-1192484737':'Zonder akkoord te gaan met de Algemene voorwaarden, kunt u niet verdergaan.',
'-247455599':'Voer uw KVK-nummer van het bedrijf in, alstublieft.',
'-1944712721':'Het minimum aantal tekens moet 12 zijn.',
'1664270996':'staat',
'-862797995':'De staat van het voertuig zoals vastgelegd bij de verkoop. Zie de bijgevoegde fotodocumentatie voor eventuele schade.',
'-1997376096':'Slowakije',
'-1344951568':'Voertuigregister',
'-644036535':'Database',
'-426412387':'Nationaal Voertuigregister',
'1736003883':'Gegevens van kentekenbewijs',
'1610817679':'Voor het voertuig zijn kilometertellergegevens beschikbaar. In het betaalde gedeelte vindt u de kilometertellerstanden voor de periode die we voor deze categorie weergeven. De gegevens kunnen gebruikt worden om te bepalen of de kilometertellerstand juist is of dat er met de kilometerteller van het voertuig geknoeid is.',
'1669009738':'Het te controleren voertuig is in het verleden geadverteerd of gefotografeerd geweest. In het betaalde gedeelte kunt u details van de advertenties, de kilometertellerstand, foto\'s van de auto en mogelijk de geadverteerde prijs bekijken (de hoeveelheid informatie kan per record verschillen). ',
'1518701163':'We hebben gegevens beschikbaar over de leeftijd of herkomst van de auto. In het betaalde gedeelte kunt u de bouwdatum van het voertuig verifiëren, de datum waarop het in gebruik werd genomen of voor welk land de auto werd geproduceerd. De omvang van de informatie in het betaalde gedeelte kan per auto verschillen.',
'1816609203':'Het bouwjaar wordt vaak vervalst om de auto jonger en dus duurder te laten lijken!',
'-900812009':'Een gestolen auto kan door de politie in beslag worden genomen zonder enige vergoeding!',
'-976941031':'Voor dit VIN is er geen controle in de databases van voertuigen, gebruikt als taxi.',
'444450829':'tot',
'982955181':'12 registers',
'-1739651968':'Controle van beveiliging',
'-167461646':'Voertuiggeschiedenis verificatie',
'-1365221853':'Het maximum aantal tekens is 14.',
'1302766369':'Er is geen taxatie van het voertuig uitgevoerd.',
'1881868699':'Ik ben aan het kopen voor een bedrijf',
'631216697':'Voer uw E-mail in, alstublieft.',
'945011940':'Uw gegevens',
'-401861769':'Contact e-mail',
'1441058305':'Als u uw BTW-nummer invult, proberen wij de overige gegevens uit de officiële database in te vullen.',
'-1181757195':'Wij sturen u na betaling een link naar het Car History Report en een vereenvoudigd belastingdocument naar het opgegeven e-mailadres.',
'-1768425519':'Als u uw factuurgegevens (bedrijfsnaam, adres, identificatienummer, fiscale identificatienummer) op het vereenvoudigd belastingdocument ingevuld wilt hebben, vul deze dan in het vakje hieronder in. Als u uw BTW-nummer invult, proberen wij de overige gegevens uit de officiële database in te vullen.',
'-1805899111':'Cebia REPORT ',
'1643595293':'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
'1985240700':'Cebia REPORT poskytuje hodnocení kontroly stavu tachometru, kontroly v databázi odcizených vozidel, závazků u leasingových společností, kontroly správnosti VIN a roku výroby vozidla. Celkové hodnocení je vyjádřeno počtem hvězdiček (0–5) a slovním hodnocením.',
'308350422':'Totaal met BTW inbegrepen:',
'-24797068':'U kunt de korting toepassen als:',
'-1323263000':'Máte u nás předplacenou službu FLEX a použijete kód uvedený na daňovém dokladu.',
'-724069837':'U een kortingscode heeft voor een gratis aankoop.',
'-127346860':'U een kortingscode van een van onze partners heeft.',
'-47884841':'zonder BTW',
'881014685':'Totaal zonder BTW',
'-15294371':'Niet-geverifieerde fiscale identificatienummer',
'1286068311':'De officiële database van BTW-plichtigen heeft uw belastingsidentificatie-nummer niet bevestigd. Daarom kunnen wij uw gegevens niet automatisch aanvullen. Controleer het ingevoerde belastingsidentificatie-nummer of vul uw bedrijfsgegevens handmatig in.',
'-172351568':'Database antwoordt niet',
'1839952435':'De officiële database van BTW-plichtigen reageert niet. Vul uw bedrijfsgegevens handmatig in.',
'1678298800':'Voorbeeld Rapport',
'-654354054':'De hoeveelheid beschikbare informatie varieert van voertuig tot voertuig',
'-1069736045':'Europese <label classname="color-orange">advertentieportalen</label>',
'1068440731':'tot <label classname="color-orange">12 registers</label>',
'-534710218':'Herkomst en leeftijd van voertuig',
'1267127794':'Voor het betreffende voertuig kunnen op basis van de VIN nummer alle controles op deze pagina uitgevoerd worden. De resultaten van de controles zijn beschikbaar in een betaald Rapport. De hoeveelheid informatie in het betaalde Rapport kan per voertuig verschillen.',
'-186613120':'Het voertuig werd niet als taxi in Tsjechië gebruikt',
'1880784559':'Beschikbaar door het kenteken van het voertuig in te voeren',
'2085931506':'<label classname="color-orange">ter beschikking</label>',
'-1765351165':'meer dan <label classname="color-orange">200 miljoenen records</label>',
'1815202337':'data van <label classname="color-orange">EU en USA</label>',
'-555727222':'data van <label classname="color-orange">18 landen</label>',
'-1798142062':'Grafiek van records van kilometertellerstanden',
'-1828887347':'VIN (Voertuig Identificatie Nummer):',
'1181611011':'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
'945789861':'Taxi controleren in Slowakije kan door het kenteken van het voertuig op www.jiscd.sk in te voeren',
'-1878151778':'<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
'-765158573':'Check nu de VIN nummer',
'-1620583517':'Geschiedenis',
'239060530':'Geschiedenis van VIN verificatie',
'1122350112':'Overzicht van de geschiedenis van alle vragen over het gecheckte VIN.',
'-1288575114':'Niet gecontroleerd',
'-14589513':'Klant',
'-1170545783':'Geverifieerd door u',
'452068758':'Partner Cebia',
'-1710315139':'Het Rapport bevat de gevonden gegevens uit de voertuiggeschiedenis. Meestal zijn dit gegevens over kilometerstanden, gegevens over voertuigschade, inspectiegegevens, voertuigfoto\'s of gegevens over voertuigreclame. U kunt meer te weten komen door te kijken in het voorbeeldrapport.',
'1607430837':'Geschiedenisoverzicht',
'-293598683':'Datum van uitschrijving',
'-1790772829':'Datum 1ste registratie van voertuig',
'743843679':'Registratiedatum van voertuig',
'1370884754':'Geregistreerd als',
'644449918':'Kleur voertuig bij registratie',
'-583571734':'Bouwdatum voertuig:',
'-1219924366':'Leeftijd voertuig',
'1595420674':'Gefabriceerd met uitrusting',
'1151043140':'Tijd vanaf bouwdatum tot 1ste registratie',
'708774908':'Albanië',
'1637995084':'Noord-Macedonië',
'1001888760':'Economische total loss *',
'1034299871':'Verzekeringsgebeurtenis - economisch total loss*',
'1209738229':'Verzekeringsgebeurtenis',
'913822268':'Opmerking',
'584620341':'Brumlovka, gebouw Alpha',
'927312018':'Vyskočilova 1461/2a',
'-1213415008':'140 00 Praha 4',
'1766887079':'18628443',
'963828375':'CZ18628443',
'-163538958':'Cebia, spol. s r.o.',
'-1996707559':'Door de bestelling af te ronden, ga ik akkoord met de <a href="https://www.cebia.cz/pruvodce?id=197&amp;do=downloadFile" target="_blank" class="Link Link--lightBlue">algemene voorwaarden</a> en neem ik kennis van de <a href="https://www.cebia.cz/pruvodce?id=198&do=downloadFile" target="_blank" class="Link Link--lightBlue">principes van de verwerking van persoonsgegevens</a>.',
'-983289066':'Spolupráce se společností Gjirafa',
'-1915066431':'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
'-427869571':'Datum 1. registrace uvedené při přihlášení',
'280331577':'Doba od výroby po 1. přihlášení',
'1163480388':'V dostupných databázích nejsou k dispozici žádné záznamy.',
'-1319845523':'Voor het voertuig registreren wij de schadeberekening die is opgesteld door de verzekeringsmaatschappij of autoreparatiewerkplaats, waarvan wij het detail (plaats van de schade) niet kennen. Let op de voertuigkeuring.',
'779148402':'Voor het voertuig registreren wij een door de verzekeringsmaatschappij gemelde gebeurtenis waarvan wij de details niet kennen (plaats van de schade). Let op de voertuigkeuring.',
'1115915387':'Doprava zdarma',
'-1556562078':'Sleva na kontrolu po celé ČR od Automato.cz',
'-556554618':'Neověřeno',
'-1464599260':'Práce',
'-592361226':'Lakování',
'1085310093':'Soort cheque:',
'1984850101':'Datum van controle',
'-1502399543':'Bekijk logboek',
'-1961590845':'Databáze Ministerstva dopravy je momentálně nedostupná, zkuste to prosím později.',
'-1065211891':'Protokol není dostupný',
'1708745339':'K této prohlídce není detail protokolu dostupný.',
'1087254174':'Získat kompletní prověření',
'2096754301':'Základní prověření vozidla ke dni:',
'926095479':'Co znamená základní prověření vozidla?',
'-254140743':'Základní prověření obsahuje tyto kontroly:',
'1362304971':'Pro prověření celé historie vozidla doporučujeme zakoupit Kompletní prověření vozidla, které navíc může obsahovat <b>kontrolu poškození, historii inzerce, fotografie vozidla, servisní historii</b> a mnoho dalšího.',
'-267677252':'Základní prověření vozidla',
'-70564196':'- 10 EUR',
'1350105297':'Bent u tevreden met het resultaat van de voertuighistoriecontrole? We zullen blij zijn met uw beoordeling.',
'1914051118':'Evaluatie',
'1786612140':'Het spijt ons dat de controle van de voertuighistorie niet aan uw verwachtingen voldeed. Vertel ons alstublieft wat we de volgende keer moeten verbeteren.',
'-1265184280':'Chyba odeslání. Opakujte akci.',
'-669850784':'Maximální počet znaků je 2000.',
'1801814710':'<strong classname="color-green">Bedankt!</strong> Uw beoordeling is ingediend en zal ons helpen onze services verder te verbeteren.',
'-883106932':'gegevens uit meer dan <label classname="color-orange">32 landen</label>',
'1408314893':'Cebia verzameld voertuiggegevens uit meer dan 32 landen. Gegevens zijn meestal afkomstig van officiële registers, garages, politiedatabases, databases van beschadigde voertuigen, verzekeringsmaatschappijen of advertentieportals.',
'-1412306493':'Ja. Elk voertuig wordt gecontroleerd in databases met gegevens uit meer dan 32 landen (waaronder Duitsland, Denemarken en de VS). Na het invoeren van het VIN-nummer ziet u in het overzicht van gevonden informatie gratis welke records er zijn gevonden en voor welke periode.',
'1798507708':'In dit geval hebben we geen gegevens over schade aan het gecheckte voertuig in onze databases van meer dan 32 landen (in totaal 200 miljoen) en de VS gevonden. Dit feit vergroot aanzienlijk de kans dat de te checken auto in het verleden niet bij een ongeval betrokken is geweest.',
'568601301':'Voertuiggegevens uit meer dan <strong>32 landen</strong>',
'899127599':'32 landen',
'-1456368295':'Geschiedenis van voertuigen van 32+ landen',
'1333238000':'30 000 000+ geverifieerde voertuigen',
'426416063':'Records komen van 32 landen',
'-1549476292':'Cebia checkt elke auto in een database van schadevoertuigen met meer dan 200 miljoen gegevens uit 32 landen? De meeste records (62%) komen uit Frankrijk en Duitsland.',
'-924882043':'In de databases van schadeauto\'s uit meer dan 32 landen, die 200 miljoen gegevens over schadegebeurtenissen bevatten, zijn één of meer gegevens over schade aan het gecheckte voertuig gevonden.',
'-1366090835':'In de databases van schadeauto\'s uit meer dan 32 landen, die 200 miljoen gegevens over schadegebeurtenissen bevatten, zijn geen gegevens gevonden over schade aan het gecheckte voertuig.',
'931273814':'Geschiedenis van voertuigen van 32+ landen',
'-334327155':'We controleren het voertuig in databases met schadeauto\'s uit meer dan 32 landen met meer dan 200 miljoen records over schadegebeurtenissen.<p></p><p>In het betaalde gedeelte komt u te weten of het voertuig in deze databases in zijn geschiedenis als schadevoertuig werd geregistreerd. Gedetailleerde schadeinformatie zoals de datum van de gebeurtenis, de kilometertellerstand, de omvang van de schade, de hoogte van de schade of een foto van de auto kunnen bij de gevonden schaderegistratie worden gevoegd.</p>',
'2078676994':'Servicegegevens',
'503491296':'OK',
'1704037376':'Annuleren',
'-922638497':'gecontroleerd',
'-2077712313':'tot 12 registers',
'-855779034':'Verificatie is al <strong classname="ml-5">gedaan</strong>',
'131879595':'Verificatie <strong classname=“ml-5 mr-5”>voor een vergoeding</strong> volgens het contract',
'-2034839108':'PDF',
'2049708688':'Geen gegevens',
'1916268628':'Nederland',
'-1425396841':'Cebia',
'-1521903902':'Protocolnummer',
'1485582239':'Nesprávný formát Email-u.',
'-1125016542':'Logo West Auto Hub',
'884632831':'Logo Institute UA',
'1943930267':'km',
'1296400336':'O platbě',
'1275361495':'Vlastníte slevový poukaz AUTOTRACER, který Vás opravňuje k nákupu kuponu zdarma. Uplatníte zadáním čísla kuponu AUTOTRACER do pole „Zadejte číslo kuponu“.',
'-675647872':'In alle deze gevallen voert u vervolgens de Report ID in het relevante vakje op de homepage <a href="https://www.auto-tracer.cz/">auto-tracer.com</a> in en onmiddellijk krijgt u toegang tot de gekochte informatie, waarnaar u binnen 30 dagen op elk gewenst moment kunt terugkeren ',
'1080548025':'VAROVÁNÍ: Může se jednat o <strong>chybné VIN</strong>. Proveďte kontrolu VIN, případně <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN: {0}">kontaktujte podporu.</a>',
'-439637411':'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Nenalezen rok výroby. <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR rokvy&body=VIN: {0}">kontaktujte podporu.</a>.',
'-43065820':'Nebyly nalezeny žádné záznamy o stáří či původu vozidla. Pochází-li vozidlo ze zahraničí, vždy si vyžádejte a pečlivě zkontrolujte originální dokumentaci k vozidlu (např. servisní knihu nebo technický průkaz).<br/><br/>Pokud chcete mít jistotu, že vůz pochází z deklarované země a není odcizený, můžete využít službu prověření původu v zahraničí <a href="https://www.cebia.cz/sluzby/provin" target="_blank" class="Link Link--lightBlue">PROVIN</a>.',
'-1362534546':'Pokud bylo vozidlo v minulosti inzerováno nebo provedena prohlídka, zobrazíme informace a fotodokumentaci.',
'431007496':'Záznam',
'-1525511285':'We halen gegevens uit de technische licentie van staatsinstellingen en geven de staat weer op het moment van de eerste registratie van het voertuig in het betreffende land.',
'1151204976':'VIN (Vehicle Identification Number) is een unieke 17-cijferige code die een voertuig duidelijk en uniek identificeert. Het wordt rechtstreeks op de carrosserie van het voertuig gestempeld en alle voertuiggegevens worden onder deze code geregistreerd. Meer over de VIN-code vindt u <a href="/detailArticle/where-do-i-find-the-vin-code" target="_blank">hier</a>.',
'205732919':'Het VIN is een 17-cijferige code die het voertuig duidelijk en uniek identificeert.',
'1905716471':'*) De definitie van <strong>Economische totale schade</strong> kan variëren per staat en per verzekeringsmaatschappij. <strong>Zelfs als de schade op deze manier wordt beoordeeld, betekent dit niet noodzakelijkerwijs dat het voertuig onbestuurbaar of niet meer te repareren was, of hoger is dan de waarde van het voertuig op het moment van de schade). In ieder geval raden wij een gedetailleerde inspectie van het voertuig aan.',
'-199845800':'Počet znaků musí být 10.',
'1657920051':'Maximální počet znaků je 18.',
'-1030611916':'Maximální počet znaků je 6.',
'1439047896':'VIN is een afkorting van Vehicle Identification Number (Voertuigidentificatienummer). Dit is een unieke 17-cijferige code bestaande uit letters en cijfers, die fabrikanten aan elk geproduceerd voertuig toekennen. Het VIN is te vinden op verschillende onderdelen van het voertuig of in de documenten. Meer informatie over het VIN vindt u <a href="https://nl.zkontroluj.cz/detailArticle/where-do-i-find-the-vin-code" target="_blank">hier</a>.',
'1682180910':'<p>V dostupných databázích nemáme k dispozici záznamy o poškození prověřovaného vozidla. Vámi zadané VIN může být chybné.</p><p>Upozornění: Systém AUTOTRACER nemusí mít k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné.</p>',
'-1276466490':'Het VIN dat u heeft ingevoerd, is mogelijk onjuist',
'-1892907546':'Er zijn geen historiegegevens gevonden voor dit VIN.',
'1843204209':'Nog steeds controleren',
'-716345220':'Controleer ander VIN',
'1091884080':'Wij raden u aan het chassisnummer te controleren',
'-287497170':'Retentierecht',
'1289833880':'Retentierecht gevonden',
'466639297':'Retentierecht niet gevonden',
'-577812113':'Spisová značka',
'231435384':'Retentierecht-controle is momenteel niet beschikbaar. Probeer de pagina na een paar minuten opnieuw te laden.',
'-941220732':'Het voertuig is het voorwerp van een of meer retentierechten in de genoemde staten en er bestaat een mogelijkheid dat het in beslag wordt genomen in een eventuele executieprocedure tegen de persoon die in het retentieregister staat vermeld. Het kopen van een voertuig met een pandrecht kan zeer riskant zijn. Wij verkrijgen de gegevens uit de officiële hypotheekregisters van het betreffende land.',
'2016489332':'Het resultaat van het onderzoek is om na te gaan of het voertuig in de genoemde staten niet onder een retentierecht valt en daarom niet het gevaar loopt in beslag te worden genomen in het geval van een executieprocedure tegen de huidige eigenaar. Wij verkrijgen de gegevens uit de officiële hypotheekregisters van het betreffende land.',
'1736313716':'Přílohy k článku',
'1021804797':'Verificatie <strong classname=“ml-5 mr-5”>gratis</strong> binnen de limiet van het contract',
'749816957':'Lze prověřit',
'981194702':'Kontrola exekucí',
'-1417536272':'Inspectie van pandrechten',
'-323399576':'Over het Rapport kan een klacht worden ingediend. Als u vermoedt dat er onjuiste informatie in het Rapport is opgenomen, neem dan contact op met onze klantenservice op <a href="mailto: info.nl@cebia.com">info.nl@cebia.com</a>.',
'-417560373':'Cebia Foto / VINFOTO',
'-643068354':'Dotaz se týká služby',
'1845072895':'Číslo smlouvy (je-li k dispozici)',
'818778283':'Číslo smlouvy',
'-870724887':'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
'-960989865':'Zobrazit výpis',
'1269795688':'Základní prověření',
'1521773717':'Geëxploiteerd als taxi in',
'-137639801':'Report ID (invullen bij klacht)',
'-1619027976':'Let op: Het voertuig mocht in de aangegeven periode slechts gedurende een bepaalde periode als taxi worden ingezet.',
'-1010997259':'Voor ondernemers',
'503941074':'Gedetailleerde informatie en gegevens over het geïnspecteerde voertuig zijn beschikbaar na betaling voor de service.',
'1088246106':'Voor het opgegeven voertuig controleren wij:',
'-963915529':'Schade aan voertuig',
'-1603961223':'<label classname="color-orange">ter beschikking</label>',
'-1940014640':'Technische data',
'1785662376':'Služby',
'1913348655':'Wilt u meer voertuigen tegen een lagere prijs inspecteren? Krijg zakelijke toegang en <b>Bespaar tot 50%</b> op de screeningprijs. Laat ons uw contactgegevens achter en wij nemen contact met u op met een offerte op maat.',
'1723999193':'Beschikbaar na betaling',
'1593405846':'Pojištění vozidla',
'670508858':'Er kan slechts één kortingsbon per bestelling worden gebruikt. Kortingsbonnen kunnen niet met elkaar worden toegevoegd of gecombineerd. De korting geldt alleen voor de dienst Voertuighistoriecontrole.',
'1693716169':'Betaalmethode',
'-503153747':'Informatie weergeven',
'-1127924333':'Základní výpis',
'-946840675':'Kontrola předchozí inzerce',
'495890769':'Kategorie je dostupná pouze v kompletním prověření',
'2071045760':'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
'438159823':'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
'698074592':'Získejte kompletní prověření se slevou',
'-1347227017':'Lze zkontrolovat záznamy o stáří a původu vozu. Kompletní prověření může obsahovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Pomocí těchto informací lze snadno zkontrolovat, zda vozidlo skutečně pochází ze země, kterou deklaruje prodejce.',
'1239291972':'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
'1463592294':'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
'1982475919':'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
'1201671594':'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
'855265460':'50% SLEVA na další prověření',
'-1870036897':'Jste si již u nás nějaký vůz prověřoval/a, a zadáním čísla kuponu (uveden v obdrženém emailu) do 30 dnů od nákupu získáváte 50% slevu na další prověření.',
'163946327':'Tegelijkertijd krijgt u ook recht op een korting 50% op uw volgende voertuigverificatie. De korting kunt u inwisselen door bij uw volgende aankoop de bovenstaande Report ID in het „<u>kortingscodevakje</u>“ bij uw volgende aankoop in te voeren.',
'858336732':'50% korting op uw volgende bestelling',
'214251926':'Door een keuring aan te schaffen, krijgt u automatisch korting bij de volgende keuring van een ander voertuig. De korting is 30 dagen geldig vanaf de aankoop.',
'1317784962':'Sleva 10 % na další prověření',
'641443144':'20% korting op uw volgende bestelling',
'-1318469146':'Sleva 30 % na další prověření',
'1939643950':'Sleva 40 % na další prověření',
'-1376459678':'Nog een cheque <br> <b>met korting!</b>',
'-937620931':'Voertuiggegevens',
'514768619':'Wij verifiëren de authenticiteit van de VIN-code',
'-916328171':'Wij verifiëren de identiteit van het voertuig',
'1481465091':'We controleren de uitrusting van het voertuig',
'-276782700':'We doorzoeken databases van beschadigde voertuigen',
'1544438189':'We controleren de gegevens van verzekeringsmaatschappijen',
'2021669548':'Zoeken in de geschiedenis van veilingportalen',
'2022162632':'We controleren gegevens van erkende servicecentra',
'1083617557':'We controleren de gegevens van niet-geautoriseerde diensten',
'993080480':'We doorzoeken politiedatabases voor gestolen voertuigen',
'-1240510013':'We doorzoeken particuliere databases voor gestolen voertuigen',
'-1027936662':'We controleren de leeftijd van het voertuig',
'1299172359':'We controleren de herkomst van het voertuig',
'413685839':'We zoeken elektronische serviceboeken',
'-473666121':'Voertuigregisters doorzoeken',
'-254920359':'We doorzoeken de databases van staatstaxivoertuigen',
'-811021673':'Europese convocatie-evenementen controleren',
'1923526539':'We controleren de veiligheid van het voertuig',
'-1424413327':'We controleren databases met kilometerstanden',
'734665361':'We controleren gegevens van leasemaatschappijen',
'684288091':'We doorzoeken de registers van technische inspectiestations',
'-1633964825':'We doorzoeken records van emissiemeetstations',
'-354982402':'Zoeken in de geschiedenis van reclameportals',
'255365524':'We doorzoeken de databases van nieuwe autodealers',
'936372740':'We doorzoeken databases van dealers van tweedehands auto\'s',
'-1491634158':'We controleren de gegevens van huurauto\'s',
'-1425645764':'De operatie kan enkele minuten duren',
'1220599811':'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
'-1744852741':'Wij maken een rapport',
'-2075433615':'Prodej vašeho stávajícího vozidla',
'-1213602701':'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
'-1734648295':'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
'634888560':'Chci prodat auto',
'-1246075427':'Chci koupit auto',
'885789811':'Sdílet prověření',
'-1027095167':'Kompletní prověření',
'457573591':'Uitschrift delen',
'-2142411619':'Om te delen kopieert u de onderstaande link en stuurt u deze naar iedereen met wie u de verificatie wilt delen.',
'-343899855':'Vyberte prosím, kterou verzi prověření chcete sdílet.',
'-2056420236':'Pro inzerci vozidla při prodeji',
'972374988':'Sdílet základní prověření',
'468810525':'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
'-2024479655':'Zobrazit základní prověření',
'100549150':'<b>Kód kuponu:</b>',
'1874886501':'Kopírovat kód',
'-2117366411':'Kód kuponu zkopírován',
'1855925079':'Wij adviseren',
'-1025377658':'artikel',
'-909166524':'artikelen',
'-1998523642':'artikelen',
'-613016004':'Kortingsaanbiedingen',
'1083497926':'Andere diensten',
'2019112977':'Sleva 35 % na další prověření',
'-710729758':'Car History Report',
'52918997':'<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
'893308548':'<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
'1903758389':'Volgende rapport',
'-901212011':'voor',
'-609214575':'- 400 Kč',
'-37660866':'Službu Automato neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://automato.cz/" target="_blank">www.automato.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://automato.cz/kontakt/" target="_blank">kontaktní formulář</a>.',
'1912219698':'Prosím vyberte jednu z možností.',
'1917667238':'Ano',
'-1946020034':'Nevím',
'-1737499797':'Je nebo bylo vozidlo registrováno v Česku?',
'-1292409486':'Wij controleren de foto\'s, u wordt per e-mail op de hoogte gehouden van de uitvoering.',
'905321947':'Logo Bavaria Direct',
'-894750124':'Logo Helvetia',
'-1377090517':'Logo VWE',
'1831998464':'Allianz',
'1321577929':'Logo Das Welt Auto',
'1206389679':'<span class="warning">Waarschuwing:</span> Informatie over alle voertuigschades is mogelijk niet beschikbaar in het systeem, sommige schades worden niet gemeld bij verzekeringsmaatschappijen of zijn niet beschikbaar als onderdeel van een online controle. Wij raden daarom aan om het voertuig zorgvuldig te inspecteren, idealiter met de hulp van een gekwalificeerde monteur of bij het servicecentrum van uw keuze. Een dergelijke inspectie kan extra schade aan het licht brengen of deze uitsluiten.',
'-1943079155':'<span class="warning">Let op:</span> Als de foto\'s niet overeenkomen met het voertuig dat wordt gecontroleerd, laat het ons dan weten via e-mail <a href="mailto:info.nl@cebia.com">info.nl@cebia.com</a>.',
'707486310':'<span class="warning">Let op:</span> Mogelijk zijn niet alle voertuigonderhoudsgegevens beschikbaar in het systeem. Wij raden u aan de gegevens te vergelijken met het onderhoudsboekje of contact op te nemen met de relevante geautoriseerde service van het merk met een verzoek om een ​​uittreksel uit het digitale onderhoudsboekje van het voertuig.',
'-1997337578':'Het overzicht van een evolutie van de kilometerteller van het voertuig bevat waarden verkregen uit de beschikbare informatiesystemen van samenwerkende partners. Volgens de ontwikkeling van de grafiek kunt u zelf beoordelen of er een vermoeden is van manipulatie van de kilometertellerstatus of niet.',
'-282194273':'Aanbeveling',
'-1564285314':'Mogelijk zijn niet alle kilometerstandgegevens uit de gebruiksgeschiedenis van het voertuig beschikbaar in het systeem.',
'993789697':'Als het voertuig in de loop van de tijd niet continu de volledige kilometerhistorie registreert, <b>raden wij aan</b> ook de kilometertellergegevens in het onderhoudsboekje te controleren, of het voertuig te laten nakijken bij een erkende garage. Wij raden deze procedure vooral aan voor voertuigen waarbij er een periode van meer dan twee jaar tussen de kilometerstanden zit.',
'2129711518':'Dit is beschikbare informatie over de basisparameters, standaard en eventuele extra uitrusting van het voertuig van de fabriek. Voertuigmodificaties zoals het veranderen van de zijkant van het stuurwiel kunnen worden gedetecteerd door de uitrusting te controleren.',
'-593531916':'<span class="warning">Waarschuwing:</span> In sommige gevallen is de volledige uitrusting van het voertuig mogelijk niet beschikbaar in het systeem.',
};

module.exports = {
TRANSLATIONS_CZ: TRANSLATIONS_CZ,
TRANSLATIONS_DE: TRANSLATIONS_DE,
TRANSLATIONS_RO: TRANSLATIONS_RO,
TRANSLATIONS_SK: TRANSLATIONS_SK,
TRANSLATIONS_US: TRANSLATIONS_US,
TRANSLATIONS_UA: TRANSLATIONS_UA,
TRANSLATIONS_AL: TRANSLATIONS_AL,
TRANSLATIONS_IT: TRANSLATIONS_IT,
TRANSLATIONS_FR: TRANSLATIONS_FR,
TRANSLATIONS_NL: TRANSLATIONS_NL,
};
