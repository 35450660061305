import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getData } from '../../busLog/dashBoardBL'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { PageHeader } from '../../components/PageHeader'
import { PriceDiscount } from '../../components/PriceDiscount'
import { PulseDot } from '../../components/PulseDot'
import { useAppContext } from '../../contexts/AppContext'
import { useSignalRConnection } from '../../contexts/SignalR/SignalRHook'
import { SignalRState } from '../../contexts/SignalR/SignalRSetting'
import { AtrSection } from '../../enums/AtrSectionEnum'
import { ReferenceType } from '../../enums/ReferenceType'
import { SignalRUrlType } from '../../enums/SignalRUrlEnum'
import { DashBoardJsonResult } from '../../models/dashBoardJsonResult'
import { Translate } from '../../translations/CebiaTranslator'
import { i18n } from '../../translations/i18n'
import { getSection } from '../../utils/arrayExtensions'
import { redirectDotazLang } from '../../utils/redirect'
import './index.styl'
import { SectionDashboard } from './sections/SectionDashboard'
import { SectionDashboardNew } from './sections/SectionDashboardNew'
import { SectionHighlights } from './sections/SectionHighlights'
import { SectionMap } from './sections/SectionMap'
import { SectionSeparator } from './sections/SectionSeparator'
import { SectionSlider } from './sections/SectionSlider'
import TechnicakImg from '../../assets/img/technicak.webp'
import TechnicakAlImg from '../../assets/img/technicak_al.webp'
import TechnicakUkImg from '../../assets/img/technicak_uk.webp'
import TechnicakITImg from '../../assets/img/technicak_it.webp'
import TechnicakFRImg from '../../assets/img/technicak_fr.webp'
import TechnicakSKImg from '../../assets/img/technicak_sk.webp'
import TechnicakROImg from '../../assets/img/technicak_ro.webp'
import TechnicakNLImg from '../../assets/img/technicak_nl.webp'
import { useSearchParams } from 'react-router-dom'
import { GoogleWidgetDashboard } from '../../components/GoogleWidgetDashboard'

export function findVinUrl() {
    let findVinUrl = 'https://www.cebia.cz/pruvodce/kde-najdu-vin-kod'
    switch (i18n.language) {
        case 'sk':
            findVinUrl = 'https://sk.zkontroluj.cz/detailArticle/kde-najdem-vin-kod'
            break
        case 'en':
            findVinUrl = 'https://en.zkontroluj.cz/detailArticle/where-do-i-find-the-vin-code'
            break
        case 'de':
            findVinUrl = 'https://de.zkontroluj.cz/detailArticle/wo-finde-ich-den-vin-code'
            break
        case 'ro':
            findVinUrl = 'https://ro.zkontroluj.cz/detailArticle/unde-pot-gasi-numarul-vin'
            break
        case 'ua':
        case 'uk':
            findVinUrl = 'https://ua.zkontroluj.cz/detailArticle/de-ya-mozu-znajti-vin-kod'
            break
        case 'it':
            findVinUrl = 'https://it.zkontroluj.cz/detailArticle/dove-posso-trovare-il-codice-vin'
            break
        case 'fr':
            findVinUrl = 'https://fr.zkontroluj.cz/detailArticle/ou-puis-je-trouver-le-code-vin'
            break
    }
    return findVinUrl
}

export function getTechnickaImg() {
    switch (i18n.language) {
        case 'al':
            return TechnicakAlImg
        case 'uk':
            return TechnicakUkImg
        case 'it':
            return TechnicakITImg
        case 'fr':
            return TechnicakFRImg
        case 'sk':
            return TechnicakSKImg
        case 'ro':
            return TechnicakROImg
        case 'nl':
            return TechnicakNLImg
        default:
            return TechnicakImg
    }
}

export const PageDetailDashboard = (): ReactElement => {
    const tran = new Translate()
    const appContext = useAppContext()
    const [searchParams] = useSearchParams()
    const utm_campaign = searchParams.get('utm_campaign') ?? ''
    const { id } = useParams()
    const {
        openConnection,
        closeConnection,
        connectionId,
        bufferedData,
        hubState,
        canCloseConnection,
    } = useSignalRConnection({
        url: SignalRUrlType.DashBoard,
    })
    const { retVal, refetch, isSuccess } = getData(id!, connectionId)
    const [priceDashboardInfo, setPriceDashboardInfo] = useState<DashBoardJsonResult | null>(null)
    useEffect(() => {
        if (hubState === SignalRState.HubCreated) {
            if ((connectionId?.length ?? 0) <= 0) {
                openConnection()
            } else {
                if (
                    (id?.length ?? 0) > 0 &&
                    (appContext.appModel?.validateResponseModel?.carMake?.length ?? 0) <= 0
                ) {
                    refetch()
                }
            }
        }
    }, [hubState, connectionId])

    useEffect(() => {
        if (canCloseConnection(bufferedData)) {
            closeConnection()
            const priceModel = getSection<DashBoardJsonResult>(bufferedData, AtrSection.CouponPrice)
            appContext.appModel.priceDashboardInfo = {
                ...appContext.appModel.priceDashboardInfo,
                ...priceModel,
            }
            appContext.appModel.priceDashboardInfo.isLoading = false
            setPriceDashboardInfo(appContext.appModel.priceDashboardInfo)
        }
    }, [bufferedData])

    useEffect(() => {
        if (isSuccess && retVal && retVal.isValid === 1) {
            redirectDotazLang(retVal!.countryId)
        }
    }, [isSuccess, retVal?.isValid])

    return (
        <div className="PageDetailDashboard">
            <div>
                <Header showLanguage={false} referenceType={ReferenceType.Default} />
                <PriceDiscount tran={tran}></PriceDiscount>
                <PageHeader>
                    <h1 className="Beta color-darkBlue">
                        {tran.GetText('Prověření vozidla')} <PulseDot className="ml-10 mr-10" />{' '}
                        {tran.GetText('online')}
                    </h1>
                </PageHeader>
                {i18n.language === 'cz' ||
                i18n.language === 'en' ||
                i18n.language === 'uk' ||
                i18n.language === 'de' ? (
                    <SectionDashboard id={id ?? appContext.appModel.viewId} />
                ) : (
                    <SectionDashboardNew id={id ?? appContext.appModel.viewId} />
                )}
                <SectionHighlights key={`${i18n.language}_highlights`} />
                {/* <SectionSeparator /> */}
                {i18n.language !== 'al' ? (
                    <GoogleWidgetDashboard
                        key={`${i18n.language}_googleWidget`}
                        showTitle={false}
                    />
                ) : (
                    <SectionSlider />
                )}
                <SectionSeparator />
                <SectionMap />
                <Footer />
            </div>
        </div>
    )
}
