import { ErrorMessage } from 'formik'
import { ReactElement, useEffect, useState } from 'react'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Translate } from '../../../../translations/CebiaTranslator'
import './index.styl'
import { PaymentReqModel } from '../../../../models/paymentReqModel'
import { getCouponInfo } from '../../../../busLog/paymentBL'
import { BubbleOpener } from '../../../../components/BubbleOpener'
import { useModal, Modal } from '../../../../components/Modal'
import { Title } from '../../../../components/Title'
import { IsValidEnum } from '../../../../enums/IsValidEnum'
import { LanguageType } from '../../../../enums/LanguageType'
import { getPaymentPrice } from '../SectionHeader'
import { SectionSeparator } from '../../../homepage/sections/SectionSeparator'
import { SectionNoContainerSeparator } from '../../../homepage/sections/SectionNoContainerSeparator'
import { useAppContext } from '../../../../contexts/AppContext'

type Props = {
    paymentReq: PaymentReqModel
    updatePaymentState: (newPaymentState: PaymentReqModel) => void
}

export const SectionFooter = ({ paymentReq, updatePaymentState }: Props): ReactElement => {
    const tran = new Translate()
    const appContext = useAppContext()
    const { open: openc } = useModal('paymentSaleCoupon')
    const {
        open: openDiscount,
        close: closeDiscount,
        active: isActive,
    } = useModal('paymentDiscount')
    const [couponValue, setCouponValue] = useState(paymentReq.saleCoupon)
    const { couponData, couponRefetch, couponIsLoading, couponError } = getCouponInfo(paymentReq)
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (couponError) {
            setMessage(tran.GetText('Kód není platný!'))
        } else if (couponData && couponData.isValid === IsValidEnum.NotValid) {
            const payment = { ...paymentReq }
            if (!payment.reloaded) {
                let valueOfCoupon = couponValue
                setMessage(couponData?.message ?? '')
                if (couponData.isValid) {
                    setCouponValue(paymentReq.saleCoupon)
                    payment.saleCoupon = paymentReq.saleCoupon
                }

                if (paymentReq.discnoutCaption?.length ?? 0 > 0) {
                    payment.discnoutCaption = paymentReq.discnoutCaption
                    // pokud jdu s kampane nastavim saleCoupon at to server správně pri refetch coupon spočítá
                    if (payment?.sale?.utm_campaign?.length ?? 0 > 0) {
                        payment.saleCoupon = valueOfCoupon = payment.sale.utm_campaign ?? ''
                        payment.isSale = true
                        setCouponValue(payment.sale.utm_campaign ?? '')
                        updatePaymentState(payment)
                        setMessage('')
                        return
                    }
                } else {
                    payment.saleCoupon = ''
                    setCouponValue('')
                    payment.discnoutCaption = paymentReq.discnoutCaption = undefined
                }
                payment.isSale = paymentReq.isSale = false
                if (payment.sale) payment.sale.b_discount = false
                if (couponData.totalPrice > 0) payment.price = couponData.totalPrice
                if (couponData.totalPrice > 0) payment.standardPrice = couponData.standardPrice
                if (couponData.isValid) {
                    payment.saleCoupon = valueOfCoupon
                }

                if (couponData.totalPrice > 0)
                    payment.standardPriceWithoutVat = couponData.standardPriceWithoutVat
                if (couponData.totalPrice > 0)
                    payment.priceWithoutVat = couponData.totalPriceWithoutVat
            } else {
                payment.isSale = paymentReq.sale.b_discount ?? false
                payment.reloaded = false
                if (couponData.isValid) {
                    payment.saleCoupon = paymentReq.saleCoupon
                    setCouponValue(paymentReq.saleCoupon)
                } else {
                    payment.saleCoupon = ''
                    setCouponValue('')
                }
            }
            updatePaymentState(payment)
        } else {
            if (couponData && couponData.isValid == IsValidEnum.IsValid) {
                setMessage('')
                const payment = { ...paymentReq }
                payment.price = couponData.totalPrice
                payment.standardPrice = couponData.standardPrice
                payment.isSale = couponData.isValid == IsValidEnum.IsValid
                // if (payment.reloaded) {
                //     setCouponValue('')
                //     payment.saleCoupon = paymentReq.saleCoupon = ''
                // }
                payment.discnoutCaption = payment.isSale
                    ? couponData.saleCouponDescription
                    : undefined
                payment.standardPriceWithoutVat = couponData.standardPriceWithoutVat
                payment.priceWithoutVat = couponData.totalPriceWithoutVat
                payment.reloaded = false
                closeDiscount()
                updatePaymentState(payment)
            }
        }
    }, [couponData, couponData?.clientId])

    useEffect(() => {
        if (
            paymentReq.saleCoupon ||
            paymentReq.reloaded === true ||
            paymentReq.validatedByVies == 0
        ) {
            setTimeout(() => {
                couponRefetch()
            }, 500)
        }
    }, [paymentReq.validatedByVies, paymentReq.saleCoupon])

    const onDiscount = (isSale: boolean) => {
        if ((paymentReq?.viewId?.length ?? 0) > 0) {
            const payment = { ...paymentReq }
            //pokud je sale, tak se jedna o zruseni, sleva vsak muze byt už ze serveru, ale tam neni saleCoupon
            if (isSale && paymentReq.saleCoupon.length > 0) {
                setCouponValue('')
                payment.saleCoupon = paymentReq.saleCoupon = ''
                payment.discnoutCaption = paymentReq.discnoutCaption = undefined
                payment.isSale = paymentReq.isSale = false
                couponRefetch()
            } else {
                payment.isSale = paymentReq.isSale = true
                payment.saleCoupon = paymentReq.saleCoupon = couponValue
                setCouponValue(couponValue)
                couponRefetch()
            }
            updatePaymentState(payment)
        }
    }

    return (
        <Section variant="bare" className="SectionPaymentFooter">
            <div className="Container pl0">
                <div className="ToggleItemAtr-inner">
                    <div className="Grid">
                        <div className="pt-20 w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex">
                            <div className="text-inline">
                                <BubbleOpener className="contactModal" onClickHandler={openc} />
                                <a className="discountLink" onClick={() => openDiscount()}>
                                    {tran.GetText('Uplatnit slevový kupon')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <Container className="discount Grid">
                        <div className="w-12/12 sm:w-6/12 smw:w-6/12 md:w-6/12 flex alignCenter">
                            {tran.GetText('Prověření historie vozidla')}
                        </div>
                        <SectionNoContainerSeparator className="mb-20 mt-20 smw:hidden" />

                        <div className="w-12/12 sm:w-6/12 smw:w-6/12 md:w-6/12 flex flexColumnEnd">
                            {paymentReq.isSale ||
                            paymentReq.discnoutCaption ||
                            (paymentReq.price != paymentReq.standardPrice &&
                                !paymentReq.isSale &&
                                paymentReq.validatedByVies === 0) ||
                            (paymentReq.standardPrice != paymentReq.price &&
                                paymentReq.validatedByVies === 0) ? (
                                <>
                                    <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex flexEnd">
                                        <div className="Price Price-cross">
                                            {getPaymentPrice(paymentReq, tran, false, false)}
                                        </div>
                                        <div className="Price">
                                            {getPaymentPrice(paymentReq, tran, false, true)}
                                        </div>
                                    </div>
                                    <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex flexEnd">
                                        {paymentReq.discnoutCaption ? (
                                            <>
                                                <span className="color-redSale bold">
                                                    {paymentReq.discnoutCaption}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="capitalize">
                                                {/* {tran.GetText('SLEVA')} */}
                                            </span>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex flexEnd">
                                    <div className="Price">
                                        {getPaymentPrice(paymentReq, tran, false, true)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Container>

                    <div className="Grid m30">
                        {paymentReq?.price >= 0 && (
                            <>
                                <div className="pl-50 w-4/12 sm:w-4/12 smw:w-4/12 md:w-4/12 priceInfo flex">
                                    {paymentReq.vatDeduction &&
                                    paymentReq.validatedByVies === 1 &&
                                    paymentReq.invoiceOpened &&
                                    paymentReq.invoiceDic.length > 0 &&
                                    paymentReq.invoiceCompanyName.length > 0
                                        ? tran.GetText('Celkem bez DPH')
                                        : tran.GetText('Celkem s DPH:')}
                                </div>
                                <div className="pr-50 w-8/12 sm:w-8/12 smw:w-8/12 md:w-8/12 flex price">
                                    {getPaymentPrice(paymentReq, tran, false, true)}
                                </div>
                            </>
                        )}
                    </div>

                    <SectionSeparator />
                    <div className="Grid">
                        <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-8/12 flex agreement centerItems">
                            <Checkbox
                                className=""
                                variant="green"
                                aria-label="Souhlas se zpracováním osobních údajů"
                                name="isGdpr"
                                id="isGdpr"
                            >
                                <label htmlFor="isGdpr">
                                    {tran.GetHtml(
                                        'Dokončením objednávky souhlasím s <a href="https://www.cebia.cz/files/vseob_podm_atr.pdf" target="_blank" class="Link Link--lightBlue">obchodními podmínkami</a> a beru na vědomí <a href="https://www.cebia.cz/o-spolecnosti/ochrana-osobnich-udaju.html" target="_blank" class="Link Link--lightBlue">zásady zpracování osobních údajů</a>.',
                                    )}
                                </label>
                                <ErrorMessage
                                    name="isGdpr"
                                    component="div"
                                    className="InputError"
                                />
                            </Checkbox>
                        </div>
                        <div className="pr-30 w-12/12 sm:w-12/12 smw:w-12/12 md:w-4/12 flex centerEnd">
                            <div className="submitBlock">
                                <Button className="Button--block" as="button" type="submit">
                                    {tran.GetText('Koupit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal id="paymentSaleCoupon">
                <Container className="PaymentCouponSection">
                    <p className="CouponCaption">
                        {tran.GetText('Slevu můžete uplatnit pokud:')}
                        <br />
                    </p>
                    <div className="SectionPageHeader-highlights">
                        <ul>
                            {paymentReq.countryId === LanguageType.CZ && (
                                <>
                                    <li>
                                        {tran.GetHtml(
                                            'Jste si již u nás nějaký vůz prověřoval/a, a zadáním čísla kuponu (uveden v obdrženém emailu) do 30 dnů od nákupu získáváte 50% slevu na další prověření.',
                                        )}
                                    </li>
                                    <li>
                                        {tran.GetHtml(
                                            'Máte u nás předplacenou službu FLEX a použijete kód uvedený na daňovém dokladu.',
                                        )}
                                    </li>
                                    <li>
                                        {tran.GetHtml(
                                            'Vlastníte slevový poukaz, který Vás opravňuje k nákupu kuponu zdarma.',
                                        )}
                                    </li>
                                    <li>
                                        {tran.GetHtml(
                                            'Máte poukaz od některého z našich partnerů.',
                                        )}
                                    </li>
                                </>
                            )}
                            <li>
                                {tran.GetHtml(
                                    'Vlastníte slevový poukaz AUTOTRACER, který Vás opravňuje k nákupu kuponu zdarma. Uplatníte zadáním čísla kuponu AUTOTRACER do pole „Zadejte číslo kuponu“.',
                                )}
                            </li>
                        </ul>
                    </div>
                </Container>
            </Modal>
            <Modal id="paymentDiscount">
                <Container className="PaymentCouponSaleSection">
                    <Title as="header">
                        <h2 className="color-darkBlue"> {tran.GetText('Zadejte číslo kuponu')}</h2>
                    </Title>
                </Container>
                <SectionSeparator className="pb-15" />
                <Container className="PaymentCouponSaleSection">
                    <p className="pb-30">
                        {tran.GetText(
                            'V rámci jedné objednávky je možné uplatnit vždy pouze jeden slevový kupon. Slevové kupony nelze sčítat ani vzájemně kombinovat. Sleva se vztahuje pouze na službu prověření historie vozidla.',
                        )}
                    </p>
                </Container>
                <Container className="PaymentCouponSaleSection">
                    {paymentReq.isSale && paymentReq.saleCoupon.length > 0 ? (
                        <p className="text-center">
                            <label className="Label color-redSale bold" htmlFor="saleCoupon">
                                {paymentReq.discnoutCaption}
                            </label>
                        </p>
                    ) : (
                        <>
                            <div className="FormItem w260">
                                <div className="w260">
                                    <input
                                        id="saleCoupon"
                                        name="saleCoupon"
                                        className="Input is-empty saleCouponText"
                                        type="text"
                                        placeholder={tran.GetText('Zadejte číslo slevového kuponu')}
                                        maxLength={16}
                                        onChange={val => {
                                            setCouponValue(val.target.value)
                                        }}
                                        value={couponValue}
                                        disabled={
                                            paymentReq.isSale && paymentReq.saleCoupon.length > 0
                                        }
                                        onKeyUp={e => {
                                            if (e.key === 'Enter' && couponValue.length > 0) {
                                                onDiscount(paymentReq.isSale)
                                            }
                                        }}
                                    />
                                    <div className="InputError">{message}</div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="text-center">
                        <Button
                            as="button"
                            variantColor={
                                paymentReq.isSale && paymentReq.saleCoupon.length > 0
                                    ? 'blue'
                                    : 'gray'
                            }
                            type="button"
                            variantSize="small"
                            onClick={() => {
                                onDiscount(paymentReq.isSale)
                            }}
                        >
                            {paymentReq.isSale && paymentReq.saleCoupon.length > 0
                                ? tran.GetText('ZRUŠIT ZADANOU SLEVU')
                                : tran.GetText('PŘEPOČÍTAT')}
                        </Button>
                    </div>
                </Container>
            </Modal>
        </Section>
    )
}
