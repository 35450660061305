import { ReactElement } from 'react'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'

export const SectionCarInsurance = (): ReactElement => {
    return (
        <>
            <div className="sectionCouponSales">
                <div className="Grid gmb-5">
                    <ToggleOpenerGeneralVoucher
                        href="#saleCarInsurance"
                        titleLeft="Výhodné pojištění na RIXO.cz"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="saleCarInsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Porovnejte si nabídky povinného ručení nebo havarijního pojištění
                                vozidla od nejlepších českých pojišťoven a vyberte si tu
                                nejvýhodnější.
                            </p>
                            <p className="text-justify">
                                Na{' '}
                                <a
                                    href="https://www.rixo.cz/pojisteni-vozidel/?a_box=kn3ptbnj&a_cam=1"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    RIXO.cz
                                </a>{' '}
                                stačí zadat SPZ vozidla a technické údaje o vozidle se doplní sami.
                                Vy už jen doplníte pár informací o sobě, abyste nepřišli o bonusy.
                                Během několika minut získáte kompletní přehled nabídek a pojistku si
                                můžete rovnou sjednat online.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.rixo.cz/pojisteni-vozidel/?a_box=kn3ptbnj&a_cam=1"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Spočítat cenu pojištění</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#ePojisteni"
                        titleLeft="Srovnání povinného ručení od ePojisteni.cz"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="ePojisteni">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Sjednejte si nejvýhodnější povinné ručení na trhu do 2 minut a zcela
                                online. Zadejte svoji SPZ, porovnejte si nabídky pojišťoven a
                                ušetřete až 50 %! Zelenou kartu Vám zašleme na e-mail.
                            </p>

                            <div className="ButtonList mb-10">
                                <a
                                    href="https://espolupracecz.go2cloud.org/aff_c?offer_id=2&aff_id=24182"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Srovnat povinné ručení</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#Extendedwarrantyinsurance"
                        titleLeft="Pojištění prodloužená záruka pro vaše vozidlo"
                        titleRight="-10 %"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="Extendedwarrantyinsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Představujeme vám DEFEND Car Protect - revoluční řešení v oblasti
                                pojištění, které přináší klid a bezpečí do vašeho automobilového
                                života. DEFEND Car Protect je zde, aby vám zajistil bezstarostnou
                                jízdu a ochránil vaši investici před nepředvídanými náklady na
                                opravy. S širokou škálou krytých součástek, které jsou srovnatelné
                                se zárukou výrobce, a kritérii, která jsou uzpůsobena vašim
                                potřebám, DEFEND Car Protect je vaším nejlepším spojencem na
                                cestách. Nečekejte, až vás nečekaná porucha překvapí a zasáhne do
                                vašeho rozpočtu. Zabezpečte si klidnou mysl a bezstarostnou jízdu
                                díky DEFEND Car Protect.<br></br>
                                Sjednání je snadné a rychlé, ať už je vaše vozidlo ještě v záruce
                                nebo již má za sebou pár kilometrů a je do 10 let stáří.
                            </p>
                            <p className="text-justify">
                                S exkluzivní slevou 10 % při zadání kódu <b>CEBIA1</b>, je ochrana
                                vašeho vozidla dostupnější než kdy předtím.<br></br> Slevu je možné
                                uplatnit do 10 dnů od nákupu prověření vozidla a nelze ji kombinovat
                                s dalšími slevami.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.defend-online.eu/cs/kontaktni-formular?cebia"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Více informací</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#TechnicalInspectionInsurance"
                        titleLeft="Pojištění technické kontroly"
                        button="Detail"
                        titleRight="-10 %"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="TechnicalInspectionInsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Přemýšlíte o koupi staršího vozu nebo již takový vůz vlastníte? Pak
                                jistě víte, že jedním z klíčových momentů v životě každého
                                automobilu je technická kontrola a měření emisí. Tyto momenty mohou
                                být pro majitele starších vozidel plné napětí a nejistoty. Co když
                                na poslední chvíli najdete na automobilu závadu, která zapříčiní, že
                                s vozem neprojdete příští kontrolou? Opravy mohou být finančně
                                náročné a ne vždy na ně máte okamžitě dostatečné finanční rezervy.
                                <br></br>
                                Přinášíme řešení, které vám pomůže tyto obavy odstranit: Pojištění
                                příští technické kontroly DEFEND Technical Control STK. S pojištěním
                                DEFEND INSURANCE budete mít jistotu, že případné náklady na opravu
                                vadných součástek nebudete muset hradit z vlastní kapsy.
                            </p>
                            <p className="text-justify">
                                Pokud si sjednáte pojištění DEFEND Technical Control nyní, získáte
                                exkluzivní 10% slevu s kódem <b>CEBIA1</b>.<br></br> Slevu je možné
                                uplatnit do 10 dnů od nákupu prověření vozidla a nelze ji kombinovat
                                s dalšími slevami.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.defend-online.eu/cs/produkty/stk?ac=CEBIA1#product-calculator-anchor"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Sjednat pojištění</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#GapCarMotorbikeInsurance"
                        titleLeft="Pojištění Gap auta nebo motorky"
                        button="Detail"
                        titleRight="-10 %"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="GapCarMotorbikeInsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Představte si tuto situaci: právě jste si pořídili vytoužené nové a
                                ojeté auto nebo motorku, užíváte si ten pocit svobody na silnici,
                                ale pak se něco nepříjemného stane. Vaše vozidlo je totálně
                                poškozeno nebo odcizeno. Havarijní pojištění vám v těchto případech
                                vyplatí pouze aktuální tržní hodnotu vozidla, která může být o
                                desítky tisíc nižší, než kolik jste původně zaplatili. To je
                                finanční rána, na kterou nejsou mnozí připraveni.<br></br>
                                Víte, že existuje způsob, jak tuto finanční ztrátu eliminovat?
                                Pojištění proti ztrátě hodnoty DEFEND Gap MAX+XS pokryje právě
                                rozdíl mezi pořizovací cenou vašeho vozidla a jeho tržní hodnotou v
                                okamžiku pojistné události, což vám umožní vrátit se do situace před
                                škodou bez jakýchkoli finančních ztrát.<br></br>Chraňte svou
                                investici do nového auta nebo motorky s DEFEND Gap MAX+XS. Nenechte
                                se zaskočit nepříjemnou situací a zajistěte si klid a ochranu,
                                kterou si zasloužíte.
                            </p>
                            <p>
                                Sleva 10 % na DEFEND Gap MAX+XS s kódem <b>CEBIA1</b>.<br></br>
                                Slevu je možné uplatnit do 10 dnů od nákupu prověření vozidla a
                                nelze ji kombinovat s dalšími slevami.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.defend-online.eu/cs/produkty/gap-max-xs?ac=CEBIA1#product-calculator-anchor"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Sjednat pojištění</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>
                </div>
            </div>
        </>
    )
}
