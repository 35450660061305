import './index.styl'
import { ReactElement, useState } from 'react'
import { AtrGallery } from '../../../../components/AtrGallery'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralAdvert } from '../../../../components/ToggleOpenerGeneralAdvert'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import {
    getPhotosUrlArray,
    PhotosDescriptionItem,
} from '../../../../models/DetailPayedResults/Photos/photosControlModel'
import { Translate, _ } from '../../../../translations/CebiaTranslator'
import moment from 'moment'
import { AtrCarValue } from '../../../../components/AtrCarValue'
import { SectionVinFoto } from './VinFotoSections/vinFotoSection'
import { SectionVinTest } from './VinFotoSections/vinTestSection'
import NoImage from '../../../../assets/img/NoImage.svg'

export type VinSectionProps = {
    item: PhotosDescriptionItem
    tran: Translate
}

export const SectionPhoto = ({ sourceData }: DetailPayedProps): ReactElement => {
    const tran = new Translate()

    const showTitle = (item: PhotosDescriptionItem) => {
        if ((item?.subCaption?.length ?? 0) > 0) {
            return `${item.subCaption}`
        }

        if (item.advertPrice > 0 && item.advertPrice !== item.advertKm)
            return `${tran.GetNumber(item.advertPrice)} ${item.currency}`

        return ''
    }

    if ((sourceData?.photoControlResult?.photos?.length ?? 0) <= 0) {
        return (
            <p>
                {tran.GetText(
                    'Nebyly nalezeny žádné záznamy o inzerci vozidla v ČR ani zahraničí.',
                )}
            </p>
        )
    }

    return (
        <>
            <p className="text-justify">
                {tran.GetText(
                    'Zkontrolujte v jakém stavu a s jakým počtem najetých kilometrů byl vůz v minulosti inzerován. Pokud byl vůz inzerován s vyšším stavem tachometru, než má nyní, mohlo být s tachometrem nezákonně manipulováno.',
                )}
            </p>
            <div className="Grid gmb-5">
                {(sourceData?.photoControlResult?.photos?.length ?? 0) > 0 &&
                    sourceData!.photoControlResult!.photos!.map((item, index) => (
                        <div className="Grid-cell" key={`togItemKey_${index}`}>
                            <ToggleOpenerGeneralAdvert
                                href={`#togPhotosContentId_${index}`}
                                image={{
                                    src:
                                        (item.advertImageUrls?.length ?? 0) > 0
                                            ? item.advertImageUrls![0]
                                            : (item.screenShotUrl?.length ?? 0) > 0
                                            ? item.screenShotUrl
                                            : NoImage,
                                    alt: '',
                                }}
                                id={item.clientId}
                                mileage={
                                    item.advertKm > 0
                                        ? `${tran.GetNumber(item.advertKm)} ${tran.GetText('km')}`
                                        : ''
                                }
                                date={moment(item.advertDate).format('DD. MM. YYYY')}
                                title={showTitle(item)}
                                isOpened={false}
                                showArrowDown={true}
                                button={
                                    item.advertMatchType !== 7 &&
                                    item.advertMatchType !== 8 &&
                                    item.advertMatchType !== 1
                                        ? tran.GetText('Inzerce')
                                        : tran.GetText('Foto')
                                }
                            />
                            <ToggleItemGeneral id={`togPhotosContentId_${index}`}>
                                {item.advertMatchType !== 7 && item.advertMatchType !== 8 && (
                                    <article className="Grid gap-20 gmb-15 mb-10">
                                        <div className="Grid-cell smw:w-1/3">
                                            {item.advertDate && (
                                                <AtrCarValue
                                                    className="photoToggler"
                                                    title={tran.GetText('Datum pořízení inzerátu')}
                                                    price={moment(item.advertDate).format(
                                                        'DD. MM. YYYY',
                                                    )}
                                                />
                                            )}
                                        </div>
                                        <div className="Grid-cell smw:w-1/3">
                                            {item.advertKm > 0 && (
                                                <AtrCarValue
                                                    className="photoToggler"
                                                    title={tran.GetText('Inzerovaný nájezd')}
                                                    price={`${tran.GetNumber(
                                                        item.advertKm,
                                                    )} ${tran.GetText('km')}`}
                                                />
                                            )}
                                        </div>
                                        <div className="Grid-cell smw:w-1/3">
                                            {item.advertPrice > 0 &&
                                                item.advertPrice !== item.advertKm && (
                                                    <AtrCarValue
                                                        className="photoToggler"
                                                        title={tran.GetText('Inzerovaná cena')}
                                                        price={`${tran.GetNumber(
                                                            item.advertPrice,
                                                        )} ${item.currency}`}
                                                    />
                                                )}
                                        </div>
                                    </article>
                                )}
                                <SectionVinFoto item={item} tran={tran} />
                                <SectionVinTest item={item} tran={tran} />
                                {((item.advertImageUrls?.length ?? 0) > 0 ||
                                    (item?.screenShotUrl?.length ?? 0) > 0) && (
                                    <AtrGallery
                                        mainImage={
                                            (item.screenShotUrl?.length ?? 0) > 0
                                                ? {
                                                      src: item.screenShotUrl,
                                                      alt: '',
                                                  }
                                                : undefined
                                        }
                                        images={getPhotosUrlArray(item.advertImageUrls)}
                                        id="pht"
                                        setFullScreenProp={false}
                                    />
                                )}
                            </ToggleItemGeneral>
                        </div>
                    ))}
                {(sourceData?.photoControlResult?.photos?.length ?? 0) > 0 && (
                    <p className="text-justify">
                        {tran.GetHtml(
                            "<span class='warning'>Poznámka:</span> Pokud fotografie neodpovídají prověřovanému vozidlu, prosím informujte nás na email <a href='mailto:autotracer@cebia.cz'>autotracer@cebia.cz</a>.",
                        )}
                    </p>
                )}
            </div>
        </>
    )
}
