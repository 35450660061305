import './index.styl'
import { Formik, Form, ErrorMessage } from 'formik'
import { ReactElement, useEffect, useState } from 'react'
import { FormItem } from '../FormItem'
import { Input } from '../Input'
import { LoadArea } from '../LoadArea'
import * as Yup from 'yup'
import { generateReport } from '../../busLog/cebiaReportBL'
import { Checkbox } from '../Checkbox'
import { PayedStateModel } from '../../models/payedResponseModel'
import { CebiaReportControlData } from '../../models/DetailPayedResults/CebiaReport/cebiaReportControlModel'
import { Button } from '../Button'
import { Translate } from '../../translations/CebiaTranslator'
import { PayedRegEsa } from '../PayedRegEsa'
import { PayedJsonResult } from '../../models/DetailPayedResults/payedJsonResult'
import { confirm } from 'react-confirm-box'
import { options } from '../../components/ConfirmBox'
import { Select } from '../Select'
import { Navigate, useNavigate } from 'react-router'

export type PayedRegInfoForm = {
    carinfo: PayedStateModel | undefined
    tachos: PayedJsonResult | undefined
}

export const PayedRegInfoForm = ({ carinfo, tachos }: PayedRegInfoForm): ReactElement => {
    const initialValues = {
        firsYearRegistration: carinfo?.firstRegistration
            ? new Date(carinfo?.firstRegistration).getFullYear()
            : undefined,
        tacho: carinfo?.kmAge ?? 0 > 0 ? carinfo?.kmAge : undefined,
        refNumber: undefined,
        checkedControl: false,
        carModel:
            (carinfo?.carModel?.length ?? 0) > 0
                ? carinfo!.carModel.indexOf('|') > 0
                    ? carinfo!.carModel.split('|')[0]
                    : carinfo!.carModel
                : '',
    }
    const tran = new Translate()
    const navigate = useNavigate()
    const [isEsaReport, setIsEsaReport] = useState(false)
    const [isEsaReportCreating, setEsaReportCreating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [submitConfirmed, setSubmitConfirmed] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isGenerated, setIsGenerated] = useState(false)
    const [cebiaReport, setcebiaReport] = useState<CebiaReportControlData>()
    const validationSchema = () => {
        return Yup.object({
            firsYearRegistration: Yup.number()
                .min(1900, 'Nesprávna hodnota rok první registrace.')
                .max(new Date().getFullYear(), 'Nesprávna hodnota rok první registrace.')
                .required('Prosím zadejte položku První rok registrace'),
            tacho: Yup.number()
                .min(1, 'Nesprávna hodnota stav tachometru.')
                .max(999999, 'Nesprávna hodnota stav tachometru.')
                .required('Prosím zadejte položku Stav tachometru.'),
            checkedControl: Yup.boolean().oneOf([true], 'Musíte odsouhlasit zadané údaje.'),
        })
    }

    const refNumberScheme = () => {
        return validationSchema().concat(
            Yup.object({
                refNumber: Yup.string().required('Prosím zadejte položku Referenční číslo.'),
            }),
        )
    }
    function getLabelText() {
        let result = ''
        if (carinfo) {
            switch (carinfo.crLimitOk) {
                case 1:
                    result = tran.GetText(
                        'Za vstup do placené části je účtován poplatek dle smlouvy',
                    )
                    break
                case 2:
                    result = tran.GetText('Vstup zdarma v rámci limitu dle smlouvy')
                    break
            }
            if (carinfo.crStatus === 1) result = ''
        }
        return result
    }
    useEffect(() => {
        if (carinfo?.atrCebiaReport?.dotaz && carinfo.crStatus === 2) {
            const cebiaReport = new CebiaReportControlData()
            cebiaReport.firstRegistrationYear = carinfo?.atrCebiaReport?.dotaz.rok_reg
            cebiaReport.km = carinfo?.atrCebiaReport?.dotaz.stav_tach
            cebiaReport.requestId = carinfo?.atrCebiaReport?.dotaz.crypto_id
            cebiaReport.apcRefNumber = carinfo.inApcRefNumber
            setcebiaReport(cebiaReport)
        }
        setIsEsaReport(carinfo?.apc.toLowerCase().trim() === '320a')
    }, [carinfo])

    return (
        <div id="sectionCertKontrolVozu">
            {cebiaReport && (
                <div className="Grid pl-50 pr-50">
                    {
                        //AutoEsa 320a 001a pro test
                        isEsaReport && !isEsaReport && carinfo!.cebiaReportEsa?.certEsaId === -1 && (
                            <div className="Grid-cell smw:w-full md:w-full lg:w-full text-center justify-center">
                                <strong>{tran.GetText('Zadané vstupní údaje')}</strong>
                            </div>
                        )
                    }

                    <div className="blue-box-medium flex">
                        <div className="Grid-cell smw:w-6/12 md:w-6/12 lg:w-6/12 justify-center">
                            <span>{tran.GetText('ROK PRVNÍ REGISTRACE')}</span>
                            <br></br>
                            <span>{cebiaReport.firstRegistrationYear}</span>
                        </div>
                        <div className="Grid-cell smw:w-6/12 md:w-6/12 lg:w-6/12 justify-center">
                            <span>{tran.GetText('AKTUÁLNÍ STAV TACHOMETRU')}</span>
                            <br></br>
                            <span>
                                {tran.GetNumber(cebiaReport.km ?? 0)} {tran.GetText('km')}
                            </span>
                        </div>
                        {/* {AutoEsa 320a 001a pro test} */}
                        {isEsaReport && (
                            <div className="Grid-cell smw:w-6/12 md:w-6/12 lg:w-6/12 justify-center">
                                <span>{tran.GetText('Referenční číslo')}</span>
                                <br></br>
                                <span>{cebiaReport.apcRefNumber ?? carinfo!.inApcRefNumber}</span>
                            </div>
                        )}
                    </div>
                    {/* AutoEsa 320a 001a pro test */}
                    {isEsaReport ? (
                        <>
                            {carinfo?.cebiaReportEsa && carinfo.cebiaReportEsa.certEsaId > 0 ? (
                                <>
                                    <PayedRegEsa
                                        tachosResult={tachos}
                                        cebiaReportEsa={carinfo?.cebiaReportEsa}
                                    ></PayedRegEsa>
                                </>
                            ) : (
                                <></>
                            )}
                            {carinfo?.cebiaReportEsa &&
                            carinfo.cebiaReportEsa.certEsaId === -1 &&
                            isEsaReport &&
                            isEsaReportCreating ? (
                                <>
                                    <PayedRegEsa
                                        changeIsEsaReport={() => {
                                            setIsEsaReport(false)
                                        }}
                                        tachosResult={tachos}
                                        cebiaReportEsa={carinfo?.cebiaReportEsa}
                                        changeIsEsaReportCreating={setEsaReportCreating}
                                    ></PayedRegEsa>
                                </>
                            ) : (
                                <>
                                    {' '}
                                    {carinfo?.cebiaReportEsa &&
                                    carinfo.cebiaReportEsa.certEsaId === -1 &&
                                    isEsaReport &&
                                    !isEsaReportCreating ? (
                                        <div className="Grid-cell smw:w-full md:w-full lg:w-full justify-center mt-10">
                                            <Button
                                                as="button"
                                                variantColor="green"
                                                onClick={() => {
                                                    setIsEsaReport(true)
                                                    setEsaReportCreating(true)
                                                    const topY = document
                                                        .getElementById('sectionCertKontrolVozu')
                                                        ?.getBoundingClientRect().y
                                                    window.scrollTo(0, (topY ?? 0) + 100)
                                                }}
                                            >
                                                {tran.GetText('Vytvořit CERTIFIKÁT kontroly vozu')}
                                            </Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <div className="Grid-cell smw:w-full md:w-full lg:w-full justify-center mt-10">
                            <Button
                                as="button"
                                variantColor="green"
                                onClick={() => {
                                    window.open(
                                        `${process.env.PUBLIC_URL}/Print/CebiaReport?requestId=${cebiaReport.requestId}`,
                                    )
                                }}
                            >
                                {tran.GetText('ZOBRAZIT CEBIA REPORT')}
                            </Button>
                        </div>
                    )}
                </div>
            )}
            <div>
                {!cebiaReport &&
                    carinfo?.crStatus !== 2 &&
                    (carinfo?.crLimitOk === 1 || carinfo?.crLimitOk === 2) && (
                        <>
                            <div className="blue-box-medium">
                                <h3 className="text-center pb-20">
                                    {tran.GetText('Zadejte prosím povinné údaje')}
                                </h3>
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize={true}
                                    validationSchema={
                                        isEsaReport ? refNumberScheme : validationSchema
                                    }
                                    onSubmit={values => {
                                        if (submitConfirmed && !isLoading) {
                                            setIsLoading(true)
                                            generateReport(
                                                values.firsYearRegistration ?? 0,
                                                values.tacho ?? 0,
                                                carinfo ?? new PayedStateModel(),
                                                values.carModel,
                                                values.refNumber,
                                            ).then(x => {
                                                if (x) {
                                                    setcebiaReport(x)
                                                    if (
                                                        x.cebiaReportNumber
                                                            .toLowerCase()
                                                            .trim()
                                                            .indexOf('320a') > 0
                                                    )
                                                        setIsEsaReport(true)
                                                    //carinfo.cebiaReportEsa?.crLogId = cebiaReport.
                                                    setIsGenerated(true)
                                                    navigate(0)
                                                } else
                                                    setErrorMessage(
                                                        'Chyba generování. Opakujte akci.',
                                                    )
                                                setIsLoading(false)
                                            })
                                        }
                                    }}
                                >
                                    <Form className="ajax">
                                        <div className="mb-20">
                                            <div className="Grid gap-30 gmb-20 ">
                                                <FormItem className="FormItem smw:w-6/12 md:w-4/12 lg:w-4/12">
                                                    <Input
                                                        id="CebiafirsYearRegistration"
                                                        name="firsYearRegistration"
                                                        type="number"
                                                        placeholder="První rok registrace"
                                                        label="První rok registrace"
                                                    />
                                                </FormItem>
                                                <FormItem className="FormItem smw:w-6/12 md:w-4/12 lg:w-4/12">
                                                    <Input
                                                        id="Cebiatacho"
                                                        name="tacho"
                                                        type="number"
                                                        placeholder="Stav tachometru"
                                                        label="Stav tachometru"
                                                    />
                                                </FormItem>
                                                {isEsaReport && (
                                                    <FormItem className="FormItem smw:w-6/12 md:w-4/12 lg:w-4/12">
                                                        <Input
                                                            id="CebiaApRefNumber"
                                                            name="refNumber"
                                                            type="text"
                                                            placeholder="Referenční číslo"
                                                            label="Referenční číslo"
                                                        />
                                                    </FormItem>
                                                )}
                                                {carinfo.carModel.indexOf('|') > 0 && (
                                                    <FormItem className="FormItem smw:w-6/12 md:w-4/12 lg:w-4/12">
                                                        <Select
                                                            id="carModel"
                                                            name="carModel"
                                                            label={tran.GetText('Model vozidla')}
                                                        >
                                                            {carinfo.carModel
                                                                .split('|')!
                                                                .map((item, index) => (
                                                                    <option
                                                                        key={`model_${index}`}
                                                                        value={item}
                                                                    >
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                        </Select>
                                                    </FormItem>
                                                )}
                                                <FormItem className="FormItem smw:w-12/12 md:w-12/12 lg:w-12/12">
                                                    <Checkbox
                                                        className=""
                                                        variant="green"
                                                        aria-label="Zkontroloval jsem a potvrzuji uvedené informace"
                                                        name="checkedControl"
                                                        id="CebiacheckedControl"
                                                    >
                                                        <label htmlFor="CebiacheckedControl">
                                                            {tran.GetText(
                                                                'Zkontroloval jsem a potvrzuji uvedené informace',
                                                            )}
                                                        </label>{' '}
                                                    </Checkbox>
                                                    <ErrorMessage
                                                        name="checkedControl"
                                                        component="div"
                                                        className="InputError"
                                                    />
                                                </FormItem>
                                                <FormItem className="PayedRegInfoForm-formItem--button smw:w-12/12 md:w-4/12 lg:w-4/12">
                                                    <button
                                                        type="submit"
                                                        onClick={async event => {
                                                            if (!submitConfirmed) {
                                                                let message = ''
                                                                if (
                                                                    carinfo.atrStatus === 1 ||
                                                                    carinfo.crLimitOk === 1
                                                                )
                                                                    message = tran.GetText(
                                                                        'Stisknutím tohoto tlačítka Vám bude naúčtována cena za zobrazení detailních informací dle platné smlouvy o APC.',
                                                                    )
                                                                if (
                                                                    (carinfo.atrStatus === 2 ||
                                                                        carinfo.crLimitOk === 2) &&
                                                                    carinfo.isCoupon !== 1
                                                                )
                                                                    message = tran.GetText(
                                                                        'Stisknutím tohoto tlačítka čerpáte 1x dotaz z limitu pro dotazy zdarma dle platné smlouvy o APC.',
                                                                    )

                                                                let result = true
                                                                if ((message?.length ?? 0) > 0) {
                                                                    window.scrollTo(0, 0)
                                                                    result = await confirm(
                                                                        message,
                                                                        options,
                                                                    )
                                                                }

                                                                if (!result) {
                                                                    return
                                                                } else {
                                                                    setSubmitConfirmed(true)
                                                                    const t =
                                                                        event.target as HTMLButtonElement
                                                                    t.click()
                                                                }
                                                            }
                                                        }}
                                                        className="Button PayedRegInfoForm-button"
                                                        aria-label="VYGENEROVAT REPORT"
                                                        value="VYGENEROVAT REPORT"
                                                    >
                                                        <span className="Button-content">
                                                            {tran.GetText('VYGENEROVAT REPORT')}
                                                        </span>
                                                    </button>
                                                    <span>{getLabelText()}</span>
                                                </FormItem>
                                            </div>
                                        </div>
                                        <LoadArea
                                            hideBackGround={true}
                                            loader={{ variant: 'bars', size: 'big' }}
                                            align="center"
                                            isLoading={isLoading}
                                        />
                                    </Form>
                                </Formik>
                            </div>
                        </>
                    )}
            </div>
            {carinfo?.accessMessage && (
                <div className="flex-wrap-center">
                    <div className="warning-row">
                        <span className="Icon Icon--alert pr-10"></span>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: carinfo?.accessMessage,
                            }}
                        ></div>
                    </div>
                </div>
            )}
        </div>
    )
}
