import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { AtrOverviewCar, StickyTypeMessage } from '../../../../components/AtrOverviewCar'
import { PayedStateModel } from '../../../../models/payedResponseModel'
import { SendEcommerce } from '../../../../utils/eCommerceSend'
import { DynamicTitleText } from '../../../../components/TitleMeta'
import { SectionShape } from '../../../../components/SectionShape'
import { SmartCodeType } from '../../../../enums/smartCodeType'
import { CouponType } from '../../../../enums/CouponType'
import { PayedJsonResult } from '../../../../models/DetailPayedResults/payedJsonResult'
import { getHistory } from '../../../../busLog/payedBL'
import { SendAffil } from '../../../../components/UrlRouter/AffilAdd'
import classNames from 'classnames'

type Props = {
    model: PayedStateModel | undefined
    payedBaseModel?: PayedJsonResult[] | undefined
    stickyMessages?: StickyTypeMessage[]
    showActionButtons: boolean
    variant: 'default' | 'full'
}

export const SectionOverview = ({
    model,
    payedBaseModel,
    stickyMessages,
    showActionButtons,
    variant,
}: Props): ReactElement => {
    SendAffil(model)
    SendEcommerce(model)
    const [showHistory, setHistory] = useState(false)
    DynamicTitleText(`${model?.carMake} ${model?.carModel}`, (model?.carMake?.length ?? 0) > 0)
    useEffect(() => {
        if (
            model &&
            (model.apc?.length ?? 0) > 0 &&
            model.historieProverovaniAPC?.find(x => x === model.apc) !== undefined &&
            model.dotazTypId > 1
        ) {
            setHistory(true)
        }
    }, [model])
    function getFirstDateReg() {
        if (!model?.firstRegistration && payedBaseModel?.find(x => x.sectionId == 30) != null) {
            return payedBaseModel?.find(x => x.sectionId == 30)?.rokvyControlResult
                ?.firstRegistration
        } else {
            return model?.firstRegistration
        }
        return undefined
    }

    const className = classNames('SectionOverview', {
        [`SectionOverview--${variant}`]: variant,
    })

    return (
        <Section variantPend="appended" className={className}>
            <Container variant="medium">
                <SectionShape variant={'rightCustom'} />
                {model && (
                    <AtrOverviewCar
                        isLoading={model.isValid !== 1}
                        vin={model?.carVin ?? ''}
                        logo={{
                            src: model?.imageData
                                ? `data:image/png;base64, ${model.imageData}`
                                : '',
                            alt: '',
                        }}
                        brand={model?.carMake ?? ''}
                        model={model?.carModel ?? ''}
                        type={model?.typ ?? ''}
                        category={model?.druh ?? ''}
                        fuel={model?.fuel ?? ''}
                        enginePower={model?.kw ?? ''}
                        engineCapacity={model?.ccm ?? ''}
                        dateManufacturing={model?.productYear}
                        showDateManufacturingType={model.showProductDate}
                        dateFirstReg={getFirstDateReg()}
                        gearBox={model?.gearBox}
                        couponCode={model?.couponCode ?? ''}
                        couponDateExpiry={model?.couponValidity}
                        controlDigits={model?.controlDigits ?? ''}
                        couponDateActivation={model?.couponActivation}
                        stickyRightTypes={stickyMessages}
                        showActionButtons={showActionButtons}
                        printDoc={model?.printDoc}
                        showInvoiceButton={
                            (model?.couponType === CouponType.WebCustomer ?? false) &&
                            ((model?.buyedByCompany === 0 ?? true) ||
                                [43, 202, 157].includes(model.countryId)) &&
                            model.price > 0
                        }
                        smartCodeActivation={model?.smartCodeActivation}
                        smartCodeType={model?.smartCodeType ?? SmartCodeType.None}
                        smartCodeDoc={model?.smartCodeDoc}
                        showHistoryButton={showHistory}
                        couponApc={model.apc}
                        viewId={model.viewId}
                        isFull={model.isFull}
                    />
                )}
            </Container>
        </Section>
    )
}
