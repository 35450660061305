import { ReactElement, useEffect } from 'react'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'

export type UrlDefinitions = {
    pathName: string
    search: string
}

export function getUrlAllowedDefinitions(): UrlDefinitions[] {
    return [
        {
            pathName: '/payed/detail/example',
            search: '',
        },
        {
            pathName: '/smartcode/example',
            search: '',
        },
    ]
}

export function getUrlDefinitions(): UrlDefinitions[] {
    return [
        {
            pathName: '/',
            search: 's_coupon',
        },
        {
            pathName: '/smartcode/',
            search: '',
        },
        {
            pathName: '/payment/overview',
            search: 'nl_id_dotaz',
        },
        {
            pathName: '/',
            search: '?',
        },
        {
            pathName: '/log/',
            search: '',
        },
        {
            pathName: '/payment/',
            search: '',
        },
        {
            pathName: '/payedreg/',
            search: '',
        },
        {
            pathName: '/cr/',
            search: '',
        },
        {
            pathName: '/payed/',
            search: '',
        },
        {
            pathName: '/carinfo/',
            search: '',
        },
        {
            pathName: '/smartcode/',
            search: '',
        },
        {
            pathName: '/articles/',
            search: '',
        },
        {
            pathName: '/unsupported-browser.html',
            search: '',
        },
    ]
}

export const RobotsProtectRouting = (): ReactElement => {
    const navigate = useNavigate()

    useEffect(() => {
        ResolveMetaTagRobots()
    }, [navigate])

    const ResolveMetaTagRobots = () => {
        const location = window.location
        const urls = getUrlDefinitions()
        const urlsAllowed = getUrlAllowedDefinitions()
        let remove = true
        urls.forEach(element => {
            if (
                (location.pathname.toLowerCase() == element.pathName &&
                    element.search.length > 0 &&
                    location.search.toLowerCase().includes(element.search)) ||
                (location.pathname.toLowerCase().includes(element.pathName) &&
                    element.search.length == 0)
            )
                remove = false
        })
        urlsAllowed.forEach(element => {
            if (
                (location.pathname.toLowerCase() == element.pathName &&
                    element.search.length > 0 &&
                    location.search.toLowerCase().includes(element.search)) ||
                (location.pathname.toLowerCase().includes(element.pathName) &&
                    element.search.length == 0)
            )
                remove = true
        })

        if (process.env.REACT_APP_DOMAIN?.indexOf('zkontroluj.cz', 0) !== -1) remove = false
        if (process.env.REACT_APP_AL_DOMAIN?.indexOf('zkontrolujsi.cz', 0) !== -1) remove = false

        if (!remove) {
            if (document.getElementsByName('robots').length === 0) {
                const metaRobots = document.createElement('meta')
                metaRobots.name = 'robots'
                metaRobots.content = 'noindex'
                document.head.appendChild(metaRobots)
            }
            if (document.getElementsByName('googlebot').length === 0) {
                const googlebot = document.createElement('meta')
                googlebot.name = 'googlebot'
                googlebot.content = 'noindex'
                document.head.appendChild(googlebot)
            }
        } else {
            const robots = document.getElementsByName('robots')
            robots.forEach(element => {
                element.remove()
            })
            const googlebot = document.getElementsByName('googlebot')
            googlebot.forEach(element => {
                element.remove()
            })
        }
    }
    return (
        <>
            <Outlet />
        </>
    )
}
