import './index.styl'
import { ReactElement } from 'react'
import { VisualIcon, VisualIconProps } from '../VisualIcon'
import classNames from 'classnames'
import { LoadArea } from '../LoadArea'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import { BubbleOpener } from '../BubbleOpener'
import { Modal, useModal } from '../Modal'
import { Container } from '../Container'
import { Title } from '../Title'
import { transcode } from 'buffer'
import { Button } from '../Button'
import { domToReact } from 'html-react-parser'

export type DashboardDescriptionItem = {
    Id: number
    DescriptionP?: any
    DescriptionN?: any
    WarningP?: any
    WarningN?: any
}

export function getDashboardDesc(tran: Translate): DashboardDescriptionItem[] {
    return [
        {
            Id: 1,
            DescriptionP: tran.GetHtml(
                'K vozidlu jsou dostupné záznamy tachometru. V placené části naleznete stavy tachometru za období, které uvádíme u této kategorie. Pomocí záznamů lze určit, zda najeté kilometry odpovídají skutečnosti nebo bylo s tachometrem vozu manipulováno.',
            ),

            DescriptionN: tran.GetHtml(
                'V dostupných databázích nejsou k dispozici záznamy tachometru prověřovaného vozidla.',
            ),
            WarningP: tran.GetHtml('33 % ojetin na českém trhu má stočený tachometr!'),
        },
        {
            Id: 2,
            DescriptionP: tran.GetHtml(
                '<p>Vozidlo prověříme v databázích poškozených vozů z více než 32 zemí čítající přes 200 milionů záznamů škodních událostí.</p><p>V placené části se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.</p>',
            ),
            DescriptionN: tran.GetHtml(
                '<p>V dostupných databázích nemáme k dispozici záznamy o poškození prověřovaného vozidla. Vámi zadané VIN může být chybné.</p><p>Upozornění: Systém AUTOTRACER nemusí mít k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné.</p>',
            ),
            WarningP: tran.GetHtml('20 % ojetin ze zahraničí je po havárii!'),
            // WarningN: tran.GetHtml('20 % ojetin ze zahraničí je po havárii!'),
        },

        {
            Id: 3,
            DescriptionP: tran.GetHtml(
                'Prověřované vozidlo bylo v minulosti inzerováno či fotografováno. V placené části si lze prohlédnout detaily inzerátů, stav tachometru, fotografie vozu a případně i inzerovanou cenu (rozsah informací se může u jednotlivých záznamů lišit). Záznamy mohou pocházet z ČR i zahraničí.',
            ),
            DescriptionN: tran.GetHtml(
                'Nebyly nalezeny žádné záznamy o inzerci vozidla v ČR ani zahraničí.',
            ),
            WarningP: tran.GetHtml('Zkontrolujte, zda vůz nebyl inzerován jako havarovaný!'),
        },
        {
            Id: 4,
            DescriptionP: tran.GetHtml(
                'Máme k dispozici záznamy o stáří nebo původu vozu. V placené části lze zkontrolovat datum výroby vozidla, datum uvedení do provozu, datum přihlášení vozu v ČR či pro jakou zemi byl vůz vyroben. Rozsah informací v placené části se může u jednotlivých vozů lišit.',
            ),
            DescriptionN: tran.GetHtml(
                'Nebyly nalezeny žádné záznamy o stáří či původu vozidla. Pochází-li vozidlo ze zahraničí, vždy si vyžádejte a pečlivě zkontrolujte originální dokumentaci k vozidlu (např. servisní knihu nebo technický průkaz).<br/><br/>Pokud chcete mít jistotu, že vůz pochází z deklarované země a není odcizený, můžete využít službu prověření původu v zahraničí <a href="https://www.cebia.cz/sluzby/provin" target="_blank" class="Link Link--lightBlue">PROVIN</a>.',
            ),
            WarningP: tran.GetHtml(
                'Rok výroby je často falšován, aby se vůz jevil jako mladší a tedy i dražší!',
            ),
            WarningN: tran.GetHtml(
                'U ojetin ze zahraničí je stáří vozu a jeho původ často falšován!',
            ),
        },
        {
            Id: 5,
            DescriptionP: tran.GetHtml(
                'Vozidlo prověříme v aktuálně dostupných databázích odcizených vozidel.<br />Jedná se převážně o evropské databáze ze státních i nestátních zdrojů. Seznam dostupných zemí a zdrojů najdete v placené části.',
            ),

            WarningP: tran.GetHtml(
                'Odcizený vůz může být kupujícímu zabaven policií bez jakékoliv náhrady!',
            ),
        },
        {
            Id: 6,
            DescriptionP: tran.GetHtml(
                'Prověření v databázích vozidel provozovaných jako taxislužba. V placené části se dozvíte, zda vůz byl a nebo stále je provozován jako taxi.',
            ),
            DescriptionN: tran.GetHtml(
                'U tohoto VIN není kontrola v databázích vozidel provozovaných jako taxi k dispozici.',
            ),
        },
    ]
}

type Props = {
    variant: 'main' | 'simple'
    title: string
    textStatus?: string
    textLabel?: string
    textDate?: string
    textSimple?: string
    isLoading?: boolean
    isDisabled?: boolean
    visualIcon: VisualIconProps
    isVisible: boolean
    bubbleOpener?: boolean
    descriptionId?: number
    descriptionPositiv?: boolean
}

export const AtrCategoryCard = ({
    variant,
    title,
    textStatus = 'XXXX X XXXXXXXX',
    textLabel = '',
    textSimple = '',
    textDate = 'XX/XXXX - XX/XXXXX',
    isLoading = false,
    isDisabled = false,
    visualIcon,
    isVisible = true,
    bubbleOpener = false,
    descriptionId,
    descriptionPositiv,
}: Props): ReactElement => {
    const className = classNames('AtrCategoryCard', {
        [`AtrCategoryCard--${variant}`]: variant,
        [`is-disabled`]: isDisabled,
    })
    const {
        open: openModal,
        close: closeModal,
        active: isActive,
    } = useModal(`AtrCategoryCardInfoModal_${descriptionId}`)

    const tran = new Translate()

    if (!isVisible) return <></>

    if (variant === 'simple') {
        return (
            <>
                <div className="AtrCategoryCardList-item">
                    <article className={className}>
                        <VisualIcon className="AtrCategoryCard-visualIcon" {...visualIcon} />
                        <div className="box-column">
                            <span className="AtrCategoryCard-title">{title}</span>
                            <span className="AtrCategoryCard-icon Icon Icon--check"></span>
                            <span className="AtrCategoryCard-status mt-10 font-bold">
                                {textSimple}
                            </span>
                        </div>
                    </article>
                </div>
            </>
        )
    } else {
        return (
            <article
                className={className}
                onClick={() => {
                    if (!isActive) openModal()
                }}
            >
                {descriptionId && (
                    <Modal
                        className="AtrCategoryCardInfoModal"
                        id={`AtrCategoryCardInfoModal_${descriptionId}`}
                    >
                        <Container>
                            <div className="Grid">
                                <div className="Grid-cell md:w-12/12">
                                    <Title as="header" variantAlign="left">
                                        <VisualIcon
                                            className="AtrCategoryCard-visualIcon fl"
                                            {...visualIcon}
                                        />
                                        <h2 className="title fl mt-20 ml-30">{title}</h2>
                                    </Title>
                                </div>
                                <div className="Grid-cell md:w-12/12 mt-20">
                                    <div className="description">
                                        {descriptionPositiv && (
                                            <div className="mt-20">
                                                {
                                                    getDashboardDesc(tran).find(
                                                        x => x.Id == descriptionId,
                                                    )?.DescriptionP
                                                }
                                                {getDashboardDesc(tran).find(
                                                    x => x.Id == descriptionId,
                                                )?.WarningP && (
                                                    <p className="mt-20">
                                                        <span className="Icon Icon--alert color-red"></span>
                                                        <label className="color-red ml-10">
                                                            {
                                                                getDashboardDesc(tran).find(
                                                                    x => x.Id == descriptionId,
                                                                )?.WarningP
                                                            }
                                                        </label>
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        {!descriptionPositiv && (
                                            <div>
                                                {
                                                    getDashboardDesc(tran).find(
                                                        x => x.Id == descriptionId,
                                                    )?.DescriptionN
                                                }
                                                {getDashboardDesc(tran).find(
                                                    x => x.Id == descriptionId,
                                                )?.WarningN && (
                                                    <p className="mt-20">
                                                        <span className="Icon Icon--alert color-red"></span>
                                                        <label className="color-red ml-10">
                                                            {
                                                                getDashboardDesc(tran).find(
                                                                    x => x.Id == descriptionId,
                                                                )?.WarningN
                                                            }
                                                        </label>
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="Grid-cell md:w-12/12 mt-20 button">
                                    <Button
                                        as="button"
                                        variantColor="blue"
                                        variantSize="small"
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    >
                                        {tran.GetText('Zavřít')}
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </Modal>
                )}

                <span className="AtrCategoryCard-title font-lg">
                    {title}
                    {bubbleOpener && (
                        <span className="AtrCategoryCard-icon Icon Icon--check"></span>
                    )}
                </span>
                <div className="AtrCategoryCard-info">
                    <VisualIcon {...visualIcon} />

                    <LoadArea
                        isLoading={isLoading}
                        text={tran.GetText('Načítám data')}
                        align="centerRight"
                    >
                        <div>
                            <span className="AtrCategoryCard-status font-lg lg:font-xl font-bold">
                                {textStatus}
                            </span>
                            <span className="AtrCategoryCard-date font-sm font-bold">
                                {!isDisabled && textDate && (
                                    <>
                                        {textLabel} <span className="color-blue">{textDate}</span>
                                    </>
                                )}
                            </span>
                        </div>
                    </LoadArea>
                </div>
            </article>
        )
    }
}
