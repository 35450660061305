import { ReactElement } from 'react'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { OtherControlData } from '../../../../models/DetailPayedResults/OthersCebia/otherControlModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'
import { SectionCheckVehicleByPerson } from '../SectionCheckVehicleByPerson'
import { SectionCarInsurance } from '../SectionCarInsurance'
import { SectionCarAukce } from '../SectionCarAukce'
import './index.styl'
import i18next from 'i18next'

export type CouponSalesProps = {
    sourceData: OtherControlData
    tran: Translate
}

export const SectionDiscountOffers = ({ sourceData }: DetailPayedProps): ReactElement => {
    const tran = new Translate()
    return (
        <>
            <div className="sectionDiscountOffers">
                <div className="Grid gmb-5">
                    <>
                        <h3>{tran.GetText('Kontrola vozu technikem')}</h3>
                        <SectionCheckVehicleByPerson sourceData={sourceData} />
                        {i18next.language === 'cz' && (
                            <>
                                <h3>{tran.GetText('Pojištění vozidla')}</h3>
                                <SectionCarInsurance />
                                <h3>{tran.GetText('Ostatní služby')}</h3>
                                <SectionCarAukce />
                            </>
                        )}
                    </>
                </div>
            </div>
        </>
    )
}
