import './index.styl'
import { ReactElement, ReactNode, useEffect, useRef, MouseEventHandler } from 'react'
import classNames, { Argument } from 'classnames'
import { Portal } from '../Portal'
import { useModal } from './hooks'
export * from './hooks'

export type ModalProps = {
    loading?: boolean
    active?: boolean
    className?: Argument
    children?: ReactNode
    id?: string
    requestClose?: () => void
}

let uuid = 0

export const Modal = ({
    active,
    loading,
    className: _className,
    children,
    id,
    requestClose,
}: ModalProps): ReactElement<HTMLDivElement> => {
    const idRef = useRef(id || `modal-${uuid++}`)
    const { active: isActive, close } = useModal(idRef.current, {
        active: Boolean(active),
    })

    const modalActive = requestClose ? active : isActive
    const modalClose = requestClose || close

    const contentRef = useRef(null)

    const handleModalClick: MouseEventHandler = event => {
        if (contentRef.current && (event.target as Node).contains(contentRef.current)) {
            // modalClose()
        }
    }

    const handleClose: MouseEventHandler = () => {
        modalClose()
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!modalActive) {
                return
            }

            if (event.key !== undefined && event.key.toLowerCase() === 'escape') {
                modalClose()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [modalActive])

    const className = classNames(
        'Modal',
        {
            'is-visible': modalActive,
            'is-active': modalActive,
            'is-loading': loading,
        },
        _className,
    )

    return (
        <Portal id="modals">
            <div className={className} onClick={handleModalClick} id={idRef.current}>
                <div className="Modal-background"></div>
                <div className="Modal-layer">
                    <div className="Modal-inner" ref={contentRef}>
                        <div className="Modal-content">{children}</div>
                        <button type="button" className="Modal-close" onClick={handleClose}>
                            Zavřít
                        </button>
                    </div>
                </div>
            </div>
        </Portal>
    )
}
